export default function (state = {
  searchZip: '',
  radiusList: [],
  pharmacySearchList: [],
  selectedPharmacy: false,
}, action) {
  switch (action.type) {
    case 'SET_PHARMACIES':
      return { ...state, pharmacySearchList: action.payload };
    case 'SET_RADII':
      return { ...state, radiusList: action.payload };
    case 'SET_SEARCH_ZIP':
      return { ...state, searchZip: action.payload };
    case 'SET_SELECTED_PHARMACY':
      return {
        ...state,
        selectedPharmacy: (action.payload ? Number(action.payload) : false),
      };
    case 'CLEAR_PHARMACIES':
      return {
        ...state,
        searchZip: '',
        radiusList: [],
        pharmacySearchList: [],
        selectedPharmacy: false,
      };
    case 'CLEAR_PHARMACY_LIST':
      return {
        ...state,
        pharmacySearchList: [],
        selectedPharmacy: false,
      };
    default:
      return state;
  }
}
