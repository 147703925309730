import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormLabel from './FormLabel';
import FieldError from './FieldError';

function DateInput({
  disabled,
  label,
  name,
  placeholder,
  required,
  size,
  handleChange,
  errMsg,
  customContainerClasses,
}) {
  const dispatch = useDispatch();
  const sizeClass = (size === 'sm') ? 'col-md-6' : 'col-md-3';
  const hasError = required && true;
  const value = useSelector(store => store.shopper[name]);

  const classes = ['form-control', (hasError && 'is-invalid')].join(' ');

  useEffect(() => {}, []);

  function defaultHandleChange(e) {
    const val = e.target.value;
    dispatch({ type: 'UPDATE_SHOPPER', payload: { [name]: val } });
  }

  return (
    <div className={['mt-3', (customContainerClasses || sizeClass)].join(' ')}>
      <FormLabel name={name} label={label} required={required} />
      <input
        type="date"
        className={classes}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange || defaultHandleChange}
        value={value}
      />
      <FieldError show={hasError} msg={errMsg} />
    </div>
  );
}

export default DateInput;
