import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ExportLink() {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate('/export')}
      className="btn btn-link p-0 ms-1"
    >
      export your data
    </button>
  );
}

function ExportDataAlert({ className }) {
  const { exportDataAlert } = useSelector(store => store.theme.features);

  const alertClassesWarning = 'd-flex align-items-center py-1 px-3 border border-warning bg-warning-bg shadow-sm r-1';

  return exportDataAlert ? (
    <div role="alert" className={`mb-0 ${alertClassesWarning} ${className}`}>
      Quoting and enrollment on this platform is sunsetting. Please <ExportLink />.
    </div>
  ) : null;
}

export default ExportDataAlert;
