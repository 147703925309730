/* eslint-disable react/jsx-props-no-multi-spaces */
import React from 'react';
import { connect } from 'react-redux';
import ReactPagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';

// Constants
import {
  AWAIT_STATE,
  DEFAULT_STATE,
  FAIL_STATE,
} from '../../../constants';
import { scrollToTop } from '../../../utils/mixin';
// Components
import Doctor from './Doctor';
import LoaderComponent from '../../common/LoaderComponent';

class Results extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { activePage: 1, itemsCountPerPage: 10 };
  }

  componentDidUpdate(prevProps) {
    if (this.props.doctorSearchList !== prevProps.doctorSearchList) {
      this.setState({ activePage: 1 });
    }
  }

  render() {
    const { activePage, itemsCountPerPage } = this.state;
    const {
      doctorList,
      doctorListLoaded,
      doctorSearchList,
      errorMessage,
    } = this.props;

    let modalBody;
    if (doctorListLoaded === DEFAULT_STATE) {
      modalBody = (
        <div className="d-flex" />
      );
    } else if (doctorListLoaded === AWAIT_STATE) {
      modalBody = (
        <div id="doctor-search-loader">
          <LoaderComponent />
        </div>
      );
    } else if (doctorListLoaded === FAIL_STATE) {
      modalBody = (
        <div id="doctor-search-loader">
          <i style={{ alignSelf: 'center' }} className="text-danger">
            {errorMessage || 'No results found. Try increasing the search radius, changing the zip code, or retyping doctor name.'}
          </i>
        </div>
      );
    } else if (doctorSearchList && doctorSearchList.length > 0) {
      let firstIdx = '0';
      let lastIdx = '0';
      let totalItemsCountString = '0';
      const totalItemsCount = doctorSearchList.length;

      firstIdx = ((activePage - 1) * itemsCountPerPage);
      lastIdx = Math.min(firstIdx + itemsCountPerPage, totalItemsCount);
      totalItemsCountString = totalItemsCount;

      const paginatedList = [
        doctorSearchList.slice(firstIdx, lastIdx).map((doctor) => {
          let md = doctor; // md = merged doctor
          let selected = false;
          doctorList.forEach((d) => {
            if (d.npi === doctor.npi
                  && d.address === doctor.address
                  && d.group === doctor.group
                  && d.ipa === doctor.ipa
                  && d.hospital === doctor.hospital) {
              md = { ...doctor, ...d };
              selected = true;
            }
          });
          return (
            <Doctor
              key={`${md.npi}-${md.address}-${md.group}-${md.ipa}-${md.hospital}`}
              doctor={md}
              selected={selected}
            />
          );
        }),
      ];
      const handlChangePagination = (pageNum) => {
        this.setState({ activePage: pageNum });
        scrollToTop('#result_label', 100);
      };
      modalBody = (
        <>
          <div id="result_label" className="m-3" style={{ fontSize: '12px', fontFamily: 'Helvetica Neue, Helvetica' }}>
            <span>
              {`${firstIdx + 1} - ${lastIdx} of ${totalItemsCountString}`}
            </span>
          </div>
          {paginatedList}
          <ReactPagination
            activePage={activePage}
            innerClass="pagination justify-content-center mb-0"
            itemClass="page-item"
            itemsCountPerPage={itemsCountPerPage}
            onChange={(pageNum) => handlChangePagination(pageNum)}
            linkClass="page-link"
            pageRangeDisplayed={5}
            totalItemsCount={totalItemsCount}

            // Visible components
            firstPageText={<FontAwesomeIcon icon={faChevronDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faChevronDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </>
      );
    } else if (doctorSearchList && doctorSearchList.length === 0) {
      modalBody = (
        <div id="doctor-search-loader">
          <i style={{ alignSelf: 'center' }}>Looks like there were no results.</i>
        </div>
      );
    }
    return modalBody;
  }
}

function mapStateToProps(store) {
  return {
    doctorSearchList: store.doctor.doctorSearchList,
    doctorList: store.shopper.doctorList,
    doctorListLoaded: store.loaders.doctorListLoaded,
    errorMessage: store.doctor.errorMessage,
  };
}

export default connect(mapStateToProps)(Results);
