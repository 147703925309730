import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import { withRouterProps } from '../../../utils/hocs';

import StandardModal from '../../common/StandardModal';

class QuickQuoteWarningModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleWarningContinue = this.handleWarningContinue.bind(this);
  }

  handleWarningContinue() {
    const { dispatch, navigate } = this.props;
    dispatch({ type: 'CLEAR_SHOPPER' });
    dispatch({ type: 'CLEAR_PHARMACIES' });
    dispatch({ type: 'HIDE_MODAL', payload: 'quickQuoteWarningModal' });
    navigate(this.props.quickQuoteWarningRouteLink);
  }

  render() {
    return (
      <StandardModal
        isOpen={this.props.quickQuoteWarningModal}
        closeModalAction={() => this.props.dispatch({ type: 'HIDE_MODAL', payload: 'quickQuoteWarningModal' })}
        title="Warning!"
      >
        <div className="px-3">
          {`You are in a QuickQuote and your shopper's information will not be saved until
            you add a Lead Id. Please click "Cancel"
            and then the "Save Shopper" button on the header to save your shopper
            information. Otherwise, click "Continue" and the shopper's info will
            NOT be saved.`}
        </div>
        <div className="px-3 pb-3 text-right">
          <Button onClick={this.handleWarningContinue}>Continue</Button>
          <Button className="ms-1" onClick={() => this.props.dispatch({ type: 'HIDE_MODAL', payload: 'quickQuoteWarningModal' })}>
            Cancel
          </Button>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    quickQuoteWarningModal: store.modal.quickQuoteWarningModal,
    quickQuoteWarningRouteLink: store.modal.quickQuoteWarningRouteLink,
  };
}

export default connect(mapStateToProps)(withRouterProps(QuickQuoteWarningModal));
