import React from 'react';
import { Button, Container } from 'reactstrap';
import { connect } from 'react-redux';
import StandardModal from '../common/StandardModal';

class StatePermissionModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    this.props.dispatch({ type: 'SET_INVALID_ZIP_CODE', payload: false });
  }

  render() {
    return (
      <StandardModal
        isOpen={this.props.invalidZipModal}
        closeModalAction={this.close}
        title="Warning!"
      >
        <Container className="pt-3">
          <div className="px-3 pb-3">
            There has been an update to the ZIP Code and it is no longer valid. Please enter a valid ZIP Code to continue.
          </div>
          <div className="px-3 pb-3 text-right">
            <Button className="ms-1" onClick={this.close}>Close</Button>
          </div>
        </Container>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    invalidZipModal: store.modal.invalidZipModal,
  };
}

export default connect(mapStateToProps)(StatePermissionModal);
