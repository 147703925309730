/* eslint-disable max-len */
import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { store } from '../store';

// Utilities
import { replaceAllHtmlElementsWithString } from './general';

export function firstCharUpper(str) {
  return str ? `${str.slice(0, 1).toUpperCase()}${str.slice(1, str.length).toLowerCase()}` : '';
}

export function translateBenefitName(name) {
  // Translated name
  let tn = name || '';

  // Rewording certain benefit names
  const translations = {
    'Maximum out-of-pocket member responsibility (does not include prescription drugs)': 'Maximum out-of-pocket limit',
  };
  if (translations[name]) {
    tn = translations[name];
  }

  return tn;
}

export function formatBenefits(plan) {
  const formattedBenefits = {};
  const currentState = store.getState();

  // Benefits we never want to show
  // (their element keys, look at replaceAllHtmlElementsWithString)
  let benefitsToHide = [
    'optionalsupplementalbenefits',
    'transportation',
    'wellnessprograms(e.g.,fitness,nursinghotline)',
  ];

  if (currentState.theme.benefit_suppression) {
    benefitsToHide = [
      ...benefitsToHide,
      ...currentState.theme.benefit_suppression.map((benefit) => benefit.toLowerCase().replace(/\s/g, '')),
    ];
  }

  const planType = plan.PlanTypeDesc || plan.planType;

  const sortedBenefits = plan.PlanBenefitDetails || [];

  // Austin's sort logic
  sortedBenefits.sort((e1, e2) => {
    if (Number(e1.SortOrder) === Number(e2.SortOrder)) {
      if (Number(e1.CategoryID) === Number(e2.CategoryID)) {
        if (Number(e1.ServiceID) === Number(e2.ServiceID)) {
          return Number(e1.NetworkSort) - Number(e2.NetworkSort);
        }
        return Number(e1.ServiceID) - Number(e2.ServiceID);
      }
      return Number(e1.CategoryID) - Number(e2.CategoryID);
    }
    return Number(e1.SortOrder) - Number(e2.SortOrder);
  });

  sortedBenefits.forEach((benefit) => {
    let benefitName = benefit.CategoryName || '';
    benefitName = translateBenefitName(benefitName);
    benefitName = HTMLReactParser(benefitName); // Turn it into a component handling bold/italics
    const cleanedBenefitName = replaceAllHtmlElementsWithString(benefit.CategoryName, '');
    const benefitId = benefit.CategoryID;
    const benefitNote = benefit.Note;

    if (benefitsToHide.indexOf(cleanedBenefitName) !== -1) {
      // Do not show excluded benefits
    } else if ((benefit.NetworkDesc === '' && benefit.Costshare === undefined) || planType === 'PDP') {
      // If DR has no info for MA/MAPD plans, it is not covered. PDP has no benefits.
      const benefitDesc = planType === 'PDP' ? 'N/A' : 'Not covered';

      formattedBenefits[benefit.CategoryName] = {
        component: [{ comp: benefitDesc, id: benefitDesc }],
        benefitName,
        benefitId,
        notes: [],
        hover: benefitDesc,
      };
    } else {
      // Main Formatting Functionality
      // Row Values
      const translatedServiceName = benefit.ServiceName || '';
      const translatedCostshare = benefit.Costshare || '';

      // Row Hovers
      let hover = '';
      const doSpaceRemoval = false;
      const doLowercase = false;
      // NetworkDesc/ServiceName - typically one or other
      if (benefit.NetworkDesc) {
        hover += `${replaceAllHtmlElementsWithString(benefit.NetworkDesc, '\n', doSpaceRemoval, doLowercase)}: `;
      }
      if (benefit.ServiceName) {
        hover += `${replaceAllHtmlElementsWithString(translatedServiceName, '\n', doSpaceRemoval, doLowercase)}: `;
      }
      hover += replaceAllHtmlElementsWithString(translatedCostshare, '\n', doSpaceRemoval, doLowercase);

      // Allows us to display all Services for a given Category
      const benefitKey = benefit.CategoryName;
      let addBreakTags = HTMLReactParser(translatedCostshare);
      // only convert \n to <br /> for max oopc, skilled nursing, hospital services
      if (['Maximum Out-of-Pocket', 'Skilled nursing facility', 'Hospital services'].includes(benefitKey)) {
        addBreakTags = HTMLReactParser(translatedCostshare.replaceAll('\n', '<br/>'));
      }
      const benefitComponent = (
        <div style={{ lineHeight: 'auto' }}>
          {HTMLReactParser(benefit.NetworkDesc || '')}{benefit.NetworkDesc ? ': ' : null}
          {HTMLReactParser(translatedServiceName)}{translatedServiceName ? ': ' : null}
          {/* benefit description text replace \n with <br /> */}
          {addBreakTags}
        </div>
      );

      // Create initial row component
      if (!(benefitKey in formattedBenefits)) {
        formattedBenefits[benefitKey] = {
          component: [{
            comp: benefitComponent,
            sortOrder: benefit.SortOrder,
            id: `${benefit.ServiceName}-${benefit.NetworkDesc}-${translatedCostshare}`,
          }],
          benefitName,
          benefitId,
          benefitNotes: [benefitNote],
          sortOrder: benefit.SortOrder,
          networkSort: benefit.NetworkSort,
          hover: [hover],
        };
      } else {
        // Append to the list of benefits for a given benefitKey
        formattedBenefits[benefitKey].component.push({
          comp: benefitComponent,
          sortOrder: benefit.SortOrder,
          id: `${benefit.ServiceName}-${benefit.NetworkDesc}-${translatedCostshare}`,
        });
        formattedBenefits[benefitKey].hover.push(`\n${hover}`);
        formattedBenefits[benefitKey].benefitNotes.push(benefitNote);
      }
    }
  });

  // Ensure list is re-sorted based on sortOrder, but these are just the row keys
  const benefitKeys = Object.keys(formattedBenefits);
  benefitKeys.sort((e1, e2) => {
    const k1 = Number.parseFloat(formattedBenefits[e1].sortOrder);
    const k2 = Number.parseFloat(formattedBenefits[e2].sortOrder);
    return k1 - k2;
  });
  const formattedBenefitsList = benefitKeys.map((key) => (
    {
      component: formattedBenefits[key].component,
      benefitName: formattedBenefits[key].benefitName,
      benefitId: formattedBenefits[key].benefitId,
      benefitNotes: formattedBenefits[key].benefitNotes,
      hover: formattedBenefits[key].hover,
      sortOrder: formattedBenefits[key].sortOrder,
    }
  ));
  return formattedBenefitsList;
}

export function combineBenefits(benefitsArray) {
  // benefitsArray should an array of arrays, e.g. [ [...], [...], [...] ]
  //
  // NOTE(santeyio): This is some kind of crazy gymnastics to create a
  // key map of all existing benefits between all (up to 3) selected plans
  // for compare as well as the html for displaying each benefit. It's tricky
  // because both the names and the benefits themselves can contain html.
  //
  // Basically we're doing two things, 1) Creating an array of unique benefits
  // in order to map these to the display and 2) keying any benefits shared across
  // plans the same way so that they can be displayed when we map through
  // the array of unique benefits.
  // -------------------------------------------------------------------------

  const titleMap = {}; // use an object so we don't create multiple entries for the same key
  const returnArray = benefitsArray.map((benefits) => {
    const rfmBenefits = {};
    benefits.forEach((benefit) => {
      rfmBenefits[benefit.name] = {
        name: benefit.name,
        text: benefit.text,
      };
      titleMap[benefit.name] = benefit.name;
    });
    return rfmBenefits;
  });
  // turn our object with unique keys into an array
  const uniqueBenefitsArray = Object.keys(titleMap).map((title) => ({ titleKey: String(title), titleHtml: titleMap[title] }));
  return {
    uniqueBenefitsArray,
    rfmBenefits: returnArray,
  };
}

export function splitPlanName(planName, returnJSX = true) {
  const parts = planName.split(' ');
  if (parts.length > 3) {
    const splitIndex = Math.round(parts.length / 2);
    let line1 = parts.slice(0, splitIndex);
    let line2 = parts.slice(splitIndex);
    line1 = line1.join(' ');
    line2 = line2.join(' ');
    if (returnJSX) {
      return HTMLReactParser(`${line1}<br/>${line2}`);
    }
    return `${line1}\n${line2}`;
  }
  return planName;
}

export function formatDateToDayString(date) {
  const createddate = new Date(date);
  const day = createddate.getDay();
  const month = createddate.getMonth();
  const d = createddate.getDate();
  const hour = createddate.getHours();
  const minute = createddate.getMinutes();

  const dayData = ['Su', 'Mo', 'Tu', 'Wed', 'Th', 'Fr', 'Sa'];
  const monthData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${dayData[day]}, ${monthData[month]} ${d}, ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
}

export function formatDateToWeekString(date) {
  const day = new Date(date).getDay();
  const dayData = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  return `Weekly on ${dayData[day]}`;
}

export function fmtDocSpecialties(specialties, key) {
  if (!specialties || !specialties.length) {
    return '--';
  }
  const el = [];
  specialties.forEach((s, i) => {
    let text = s;
    if (specialties.length > 1 && i < (specialties.length - 1)) {
      text += ',';
      el.push(<div key={`${key}-${text}`}>{text}</div>);
    } else {
      el.push(s);
    }
  });
  return <div key={`${key}-specialties`}>{el}</div>;
}
