/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';

function SearchInput({
  setShowSearchResults,
  setSelectSignal,
  selectSignal,
  locationClient,
  searchString,
  setSearchString,
  setSearchResults,
  disabled,
  disableUpdate,
  awsConfig,
}) {
  const [ searching, setSearching ] = useState(false);
  const { apiKey, placesName } = awsConfig;

  async function handleSearch(val) {
    setSearching(true);
    const params = {
      IndexName: placesName,
      FilterCategories: [ 'MunicipalityType' ],
      Key: apiKey,
      FilterCountries: [ 'USA' ],
      Language: 'en',
      MaxResults: 10,
      Text: val,
    };

    try {
      if (val.length > 0) {
        const results = await locationClient.send(new SearchPlaceIndexForSuggestionsCommand(params));
        if (Array.isArray(results.Results)) {
          setSearchResults(results.Results);
          // if the search string is changing because one of the results was clicked
          // then the 'selectSignal' should be true and we should not display the
          // search results box.
          if (selectSignal) {
            setShowSearchResults(false);

          // otherwise the string in the input has changed and we should display the search
          // results
          } else {
            setShowSearchResults(true);
          }
        }
      } else { // if search string is ''
        setShowSearchResults(false);
      }
    } catch (e) {
      console.log('error... ', e);
    }
    setSearching(false);
  }

  function handleChange(e) {
    const { value } = e.target;
    setSelectSignal(false);
    setSearchString(value);
  }

  useEffect(() => {
    const timeoutID = setTimeout(async () => {
      // we should only do a search if a place has not already been selected.
      // `disableUpdate` is set to true when a place is first selected. After
      // a place has been selected for the first time it cannot be changed,
      // only deleted. The radius can still be edited.
      if (!disableUpdate) await handleSearch(searchString);
    }, 500);
    return () => clearTimeout(timeoutID);
  }, [searchString]);

  return (
    <div style={{ position: 'relative' }}>
      <input
        onFocus={() => setShowSearchResults(true)}
        className={`form-control ${disabled && 'text-muted'}`}
        value={searchString}
        placeholder="Search..."
        onChange={(e) => handleChange(e)}
        autoComplete="new-password"
        disabled={disabled || disableUpdate}
      />
      {searching && (
        <div
          role="status"
          className="spinner-border spinner-border-sm text-muted"
          style={{
            position: 'absolute',
            top: '11px',
            right: '10px',
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </div>
  );
}

export default SearchInput;
