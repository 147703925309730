/* eslint-disable arrow-body-style */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { toDateString } from '../../../common/utils/prettify';

// Actions
import { addCallNote, loadCallNotes } from '../../../actions/shopper';

// Hooks
import useScroll from '../../../hooks/useScroll';

// Components
import NoteCard from '../../common/NoteCard';

const Notes = ({ shopper }) => {
  const dispatch = useDispatch();
  // state used to combine shopper.callNotes and items stored in shopper.data
  const [notes, setNotes] = useState([]);

  const hasNotes = (shopper?.callNotes?.length || shopper?.data?.leads);
  const [open, setOpen] = useState(hasNotes);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters ] = useState({ callNotes: true, enrollmentEvents: true });
  const [adding, setAdding] = useState(false);
  const [text, setText] = useState('');

  // Ref used for horizontally-scrollable div
  const ref = useRef();
  const scroll = useScroll(ref, { left: true });

  const handleSave = () => {
    if (text) {
      setLoading(true);
      addCallNote(shopper.username, text)
        .then(() => {
          loadCallNotes(shopper.username)
            .then((callNotes) => {
              setAdding(false);
              setLoading(false);
              setText('');
              dispatch({ type: 'SET_SHOPPER_CALL_NOTES', payload: callNotes });
            });
        }).catch((err) => {
          console.log('err', err);
        });
    }
  };

  const handleCancel = () => {
    setText('');
    setAdding(false);
    if (!notes.length) setOpen(false);
  };

  useEffect(() => {
    // create note elements from RGE + shopper call notes
    let noteCt = 0;
    setNotes(() => {
      const newNotes = [
        ...(shopper && shopper.data && shopper.data?.leads
          ? Object.keys(shopper.data.leads).map((k) => ({
            source: 'Lead',
            title: k,
            created: toDateString(shopper.created, { longform: true }),
            text: shopper.data.leads[k],
          })) : []),
        ...(shopper && shopper.data && shopper.data?.plans ? [{
          source: 'Lead',
          title: 'Plan Information',
          created: toDateString(shopper.created, { longform: true }),
          text: shopper.data.plans,
        }] : []),
        ...(shopper && shopper.callNotes
          ? shopper.callNotes.map((n) => {
            const manualEnrollmentEvents = ['Shopper logged in'];
            const isEnrollmentEvent = (manualEnrollmentEvents.indexOf(n.text) !== -1) || n.type === 'quote';
            // Filters
            let shouldDisplay = true;
            if (!isEnrollmentEvent && !filters.callNotes) {
              shouldDisplay = false;
            } else if (isEnrollmentEvent && !filters.enrollmentEvents) {
              shouldDisplay = false;
            }
            noteCt += 1;
            return {
              source: isEnrollmentEvent ? 'Quote' : 'Self-created',
              title: isEnrollmentEvent ? 'Enrollment Event' : 'Call Note',
              created: toDateString(n.created, { longform: true }),
              text: n.text,
              editable: true,
              style: { display: shouldDisplay ? '' : 'none' },
              key: noteCt,
            };
          }) : []),
      ];
      if (newNotes.length) setOpen(true);
      return newNotes;
    });
  }, [ shopper, filters ]);

  return (
    <div
      className="mt-3 tel-box shadow-sm overflow-hidden animation-open position-relative"
      style={{
        height: (open || adding) ? '370px' : '60px',
        maxHeight: (open || adding) ? '370px' : '60px',
        transitionDuration: '0.5s',
      }}
    >
      <div
        className="d-flex align-items-center mb-2 position-relative w-100"
      >
        <div
          className="d-flex fs-4 fw-bold ps-3 pe-3 pb-3 pt-3 flex-grow-1"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setOpen(!open);
            setAdding(false);
          }}
          tabIndex={0}
          role="button"
        >
          <div className="ms-0 me-auto">Notes ({notes.length})</div>
          {notes.length
            ? <FontAwesomeIcon icon={open ? faAngleDown : faAngleUp} className="ms-auto me-0 fs-2" />
            : null}
        </div>
        <div className="d-flex me-4 ms-auto">
          <div
            className={`me-0 ms-1 fs-4 text-decoration-underline user-select-none ${shopper.username ? 'text-blue' : 'text-grey-500'}`}
            tabIndex={0}
            role="button"
            onClick={() => {
              setAdding(true);
              setOpen(true);
              ref.current.scrollLeft = 0;
            }}
          >
            Add note +
          </div>
        </div>
      </div>
      <div
        className="position-absolute text-muted border border-grey bg-white p-1 ps-2 pe-2 ms-3 shadow-sm fade"
        style={{
          left: '0px',
          top: '50%',
          fontSize: '32px',
          zIndex: '10',
          opacity: (open && scroll > 0) ? '1' : '0',
        }}
        role="button"
        tabIndex={0}
        onClick={() => {
          ref.current.scrollLeft -= 225;
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
      <div className="d-flex text-align-center ms-3">
        <span className="fs-5 me-3">Filters</span>
        <button
          className={`btn border-grey-500 p-0 ps-3 pe-3 ${filters.callNotes ? 'btn-grey-400' : 'btn-outline-grey-400'}`}
          type="button"
          tabIndex={0}
          onClick={() => {
            setFilters(f => {
              return {
                ...f,
                callNotes: !f.callNotes,
              };
            });
          }}
        >
          <FontAwesomeIcon icon={filters.callNotes ? faCheck : faTimes} className="me-2" />
          Call Notes
        </button>
        <button
          className={`btn border-grey-500 p-0 ps-3 pe-3 ms-2 ${filters.enrollmentEvents ? 'btn-grey-400' : 'btn-outline-grey-400'}`}
          type="button"
          tabIndex={0}
          onClick={() => {
            setFilters(f => {
              return {
                ...f,
                enrollmentEvents: !f.enrollmentEvents,
              };
            });
          }}
        >
          <FontAwesomeIcon icon={filters.enrollmentEvents ? faCheck : faTimes} className="me-2" />
          Enrollment Events
        </button>
      </div>
      <div
        className="d-flex h-100 ps-2"
        style={{ overflowX: 'scroll', overflowY: 'hidden' }}
        ref={ref}
      >
        {
          adding
            ? (
              <NoteCard
                adding
                loading={loading}
                note={{ text }}
                className="m-2"
                style={{ minWidth: '250px', maxWidth: '250px', maxHeight: '250px' }}
                onChange={e => setText(e.target.value)}
                onSave={() => handleSave()}
                onCancel={() => handleCancel()}
              />
            ) : null
        }
        {notes.map((n) => {
          // had to do this logic to recreate the elems..
          // because the useRefs weren't getting reset for the new order
          return (
            <NoteCard
              note={n}
              key={`${n.title}-${n.source}-${n.created}-${n.key}`}
              className="m-2"
              style={{ minWidth: '250px', maxWidth: '250px', maxHeight: '250px', ...n.style }}
            />
          );
        })}
      </div>
      <div
        className="position-absolute text-muted border border-grey bg-white p-1 ps-2 pe-2 me-3 shadow-sm fade"
        style={{
          right: '0px',
          top: '50%',
          fontSize: '32px',
          opacity: (open && ref.current && scroll <= (250 + ref.current.lastChild?.offsetLeft - ref.current.offsetWidth)) ? '1' : '0',
          // pointerEvents: 'none',
        }}
        role="button"
        tabIndex={0}
        onClick={() => {
          ref.current.scrollLeft += 225;
        }}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    </div>
  );
};

export default Notes;
