import React from 'react';
import { connect } from 'react-redux';

// Actions
import {
  getPackages,
  getDirectGenericDosage,
  getDrugDetails,
} from '../../../actions/drug';

class Dosages extends React.PureComponent {
  setSelectedDosage = (event) => {
    const { dispatch, dosageList } = this.props;
    const dosageID = event.target.value;
    const dosage = dosageList.find((d) => Number(d.DosageID) === Number(dosageID));
    dispatch({ type: 'CLEAR_PACKAGES' });
    if (dosage) {
      // if there are packages for this dosage, fetch them
      if (dosage.ContainPackage) {
        getPackages(dosage.DrugID, dosage.DosageID);
      }
      // if there is a direct generic available for this dosage, fetch it
      if (dosage.IsDirectGenericAvailable) {
        getDirectGenericDosage(dosage.DrugID, dosage.RelatedDosageID)
          .then((d) => getDrugDetails(d.DrugID)
            .then((drugDetails) => dispatch({ type: 'SET_GENERIC_DRUG', payload: drugDetails })));
      }
      dispatch({ type: 'SET_SELECTED_DOSAGE', payload: dosage });
    }
  };

  render() {
    const {
      selectedDosage,
      dosageList,
    } = this.props;

    return (
      <>
        <select
          id="rx-select-dosage-input"
          className="form-control h-100"
          value={selectedDosage.DosageID}
          onChange={(e) => this.setSelectedDosage(e)}
        >
          {dosageList.map((dosage) => (
            <option
              key={`dosage-option-${dosage.DosageID}`}
              value={dosage.DosageID}
            >
              {dosage.Label}
            </option>
          ))}
        </select>
        <i
          className="fa fa-chevron-down"
          style={{
            fontSize: '15px',
            marginLeft: '-40px',
            marginTop: '20px',
            paddingLeft: '4px',
            position: 'relative',
            pointerEvents: 'none',
            left: '15px',
            backgroundColor: 'white',
            bottom: '1px',
          }}
        />
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    dosageList: store.drug.dosageList,
    selectedDosage: store.drug.selectedDosage,
  };
}

export default connect(mapStateToProps)(Dosages);
