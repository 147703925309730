import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getPlansAvailable } from '../../../../actions/shopper';
import TextInput from './TextInput';

function PlansAvailable() {
  const dispatch = useDispatch();
  const { county } = useSelector(store => store.shopper);

  useEffect(() => {
    if (county) {
      getPlansAvailable(county).then(data => {
        const plansExist = data.length ? 'Yes' : 'No';
        dispatch({ type: 'UPDATE_SHOPPER', payload: { plansAvailable: plansExist } });
      });
    }
  }, [county]);

  return (
    <TextInput
      disabled
      label="Plans available"
      name="plansAvailable"
      onUndefinedValue="--"
      cols="6"
    />
  );
}

export default PlansAvailable;
