/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../../../constants';

// Actions
import { getPharmacies } from '../../../actions/pharmacy';

// Components
import SearchTable from './SearchTable';
import Search from './Search';
import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';
import LoaderComponent from '../../common/LoaderComponent';

class AddPharmacy extends React.PureComponent {
  componentDidMount = () => {
    const {
      zip,
      county,
      dispatch,
    } = this.props;
    dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: AWAIT_STATE });
    getPharmacies(zip, county)
      .then(() => dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: SUCCESS_STATE }))
      .catch(() => dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: FAIL_STATE }));
  }

  render() {
    const {
      doctorsFeature,
      navigate,
      pharmaciesLoaded,
      username,
    } = this.props;
    return (
      <NavPage
        activeLink="/rx"
        showShopperInfobar={username}
      >
        <div className="rx rx-add">
          <div className="mmb-header">
            Rx
          </div>
          <div className="mmb-search">
            <div className="" style={{ minHeight: '90px', display: 'flex', maxWidth: '1000px' }}>
              <div className="d-flex flex-column">
                <div className="mt-1 ms-2">
                  <div className="fw-bold mb-3" style={{ fontSize: '18px' }}>
                      Add a pharmacy
                  </div>
                </div>
                <div>
                  <p>Add a pharmacy for more accurate medication price estimates.</p>
                </div>
                <div>
                  <Search />
                </div>
              </div>
            </div>
              <div
                className="d-flex position-relative"
                style={{ maxWidth: '1000px' }}
              >
                {
                  pharmaciesLoaded === AWAIT_STATE
                    ? <LoaderComponent />
                    : <SearchTable />
                }
              </div>
          </div>
          <FooterNav
            planOnClick={() => navigate('/plans/list')}
            nextOnClick={() => {
              if (doctorsFeature) {
                navigate('/doctors');
              } else {
                navigate('/plans');
              }
            }}
            backOnClick={() => navigate('/rx/list')}
            backButtonText='Back to Rx'
          />
        </div>
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    zip: store.shopper.zipcode,
    county: store.shopper.county,
    username: store.shopper.username,
    pharmaciesLoaded: store.loaders.pharmaciesLoaded,
    doctorsFeature: store.theme.features.doctors,
  };
}

export default connect(mapStateToProps)(withRouterProps(AddPharmacy));
