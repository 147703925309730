import axios from 'axios';
import { store } from '../store';
import { default401 } from './auth';

import {
  AWAIT_STATE,
  SUCCESS_STATE,
  FAIL_STATE,
  DEFAULT_STATE,
} from '../constants';

const coverageType = {
  MAPD: 'RX and Medical',
  MA: 'Medical',
  PDP: 'RX',
};

export const getPlan = async (planId) => {
  const token = localStorage.getItem('SessionToken');

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/${planId}/`,
    headers: { Authorization: `Token ${token}` },
  });
};

function mapMedicareMetaData(planData, planMetaData) {
  return planData.map((plan) => {
    const savedMeta = planMetaData.filter((p) => p.plan_id === planData.PlanID);
    let meta = {
      expanded: true,
      saved: false,
      detailsLoaded: DEFAULT_STATE,
    };
    if (savedMeta.length) { [meta] = savedMeta; }
    return {
      plan,
      meta,
      coverageType: coverageType[plan.PlanTypeDesc],
      planID: plan.PlanID,
      planType: plan.PlanTypeDesc,
      medicalType: plan.MedicalPlanTypeDesc,
      planName: plan.PlanName,
      monthlyPremium: plan.TotalPlanPremium,
      drugsCovered: plan.CoveredDrugCount,
      MOOP: plan.MOOPFromBenefit,
      deductible: plan.DrugDeductibleLimit,
      doctorCopay: plan.DoctorVisitCopay,
      specialistCopay: plan.SpecialistCopay,
      starRating: plan.OverallStarRating,
      cpsID: `${plan.ExternalContractID}-${plan.ExternalPlanID}-${Number(plan.ExternalSegmentID)}`,
      carrier: plan.CarrierName,
      carrierID: plan.CarrierID,
      org: plan.OrganizationName,
      orgID: plan.OrganizationID,
      brand: plan.BrandName,
      premiumRank: plan.PremiumRank,
      premiumRankVsMarket: plan.PremiumRankVsMarket,
    };
  });
}

function constructMedicareData(shopper, planID = false) {
  // if planID is set then we'll load the details for that specific plan.
  //   Otherwise load the whole plan list for the shopper.
  const sendPharmacies = shopper.pharmacyList.map((pharmacy) => ({
    NPI: pharmacy.npi,
  }));
  const sendDrugs = shopper.drugList.map((drug) => ({
    DosageID: drug.dosageID,
    UserQuantity: drug.userQuantity,
    QTY: drug.quantity,
    SelectedPackage: drug.packageID,
    PackageID: drug.packageID,
    DaysSupply: drug.frequency,
  }));
  const data = {
    CountyFIPS: shopper.county,
    CountyName: shopper.countyName,
    Zipcode: shopper.zipcode,
    Pharmacies: sendPharmacies,
    Dosages: sendDrugs,
    EffectiveDate: shopper.effectiveDate,
    SubsidyLevel: Number(shopper.subsidyLevel),
    SubsidyPercent: Number(shopper.subsidyPercent),
  };
  if (sendPharmacies[0] && sendPharmacies[0].NPI === 99999999) data.IsMailOrder = true;
  if (planID) {
    data.AdditionalPlanDataReturn = [0, 1, 2, 3, 4, 5];
    data.Plans = [{ PlanID: planID }];
  }
  return JSON.stringify(data);
}

export function mapCarriers(planData) {
  const { planListFilterType = 'carrier' } = store.getState().theme.features;
  const carriers = {
    PDP: [],
    MAPD: [],
    MA: [],
  };
  planData.forEach(plan => {
    if (plan.planType === 'MAPD' && !carriers.MAPD.includes(plan[planListFilterType])) {
      carriers.MAPD.push(plan[planListFilterType]);
    }
    if (plan.planType === 'MA' && !carriers.MA.includes(plan[planListFilterType])) {
      carriers.MA.push(plan[planListFilterType]);
    }
    if (plan.planType === 'PDP' && !carriers.PDP.includes(plan[planListFilterType])) {
      carriers.PDP.push(plan[planListFilterType]);
    }
  });
  carriers.MAPD.sort();
  carriers.MAPD = carriers.MAPD.map((carrier) => [carrier, true]);
  carriers.MA.sort();
  carriers.MA = carriers.MA.map((carrier) => [carrier, true]);
  carriers.PDP.sort();
  carriers.PDP = carriers.PDP.map((carrier) => [carrier, true]);
  return carriers;
}

export function loadMedicarePlans(shopper, savedMetaData = []) {
  return new Promise((resolve, reject) => {
    const data = constructMedicareData(shopper);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/summary/`,
      data,
      signal: shopper.signal,
    }).then((res) => {
      const withMetaData = mapMedicareMetaData(res.data.PlanInfo, savedMetaData);
      resolve(withMetaData);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function loadMedicareDetailsDocuments(countyfips, planId) {
  return axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/documents/${planId}/${countyfips}/`,
  }).then((response) => {
    store.dispatch({
      type: 'SET_MEDICARE_DOCUMENTS',
      payload: {
        documents: response.data,
        planId,
      },
    });
  });
}

export function loadMedicareDetails(shopper, planID) {
  return new Promise((resolve, reject) => {
    store.dispatch({
      type: 'SET_PLAN_META',
      payload: {
        PlanID: planID,
        meta: { detailsLoaded: AWAIT_STATE },
      },
    });
    const data = constructMedicareData(shopper, planID);
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/summary/`,
      data,
    }).then((res) => {
      if (res.data.PlanInfo[0]) {
        loadMedicareDetailsDocuments(shopper.county, planID);
        store.dispatch({ type: 'UPDATE_PLAN_DETAILS', payload: res.data.PlanInfo[0] });
        store.dispatch({
          type: 'SET_PLAN_META',
          payload: {
            PlanID: planID,
            meta: { detailsLoaded: SUCCESS_STATE },
          },
        });
        resolve(res.data);
      } else {
        store.dispatch({
          type: 'SET_PLAN_META',
          payload: {
            PlanID: planID,
            meta: { detailsLoaded: FAIL_STATE },
          },
        });
        reject();
      }
    }).catch((err) => {
      store.dispatch({
        type: 'SET_PLAN_META',
        payload: {
          PlanID: planID,
          meta: { detailsLoaded: FAIL_STATE },
        },
      });
      reject(err);
    });
  });
}

const mapMedigapMetaData = (planData, planMetaData, availableCarriers) => planData.map((plan) => {
  const savedMeta = planMetaData.filter((p) => p.plan_id === planData.PlanID);
  let meta = { expanded: true, saved: false };
  if (savedMeta.length) {
    [meta] = savedMeta;
  }
  let select = false;
  if (plan.planTypeCode === 20) {
    select = true;
  }

  let formattedPlanName = plan.planName;
  if (plan.ratingClassDesc) {
    formattedPlanName = `${plan.planName} ${plan.ratingClassDesc}`;
  }
  if (plan.planName.endsWith('_BASE')) {
    formattedPlanName = plan.planName.replace('_BASE', ' Medigap');
  }

  return {
    plan,
    meta,
    select,
    planName: formattedPlanName,
    monthlyPremium: plan.monthlyRate,
    rating: plan.ratingClassDesc,
    carrier: availableCarriers[plan.leadID],
    planType: 'MEDIGAP',
  };
});

function loadMedigapPlans(shopper, savedMetaData = [], availableCarriers) {
  return new Promise((resolve, reject) => {
    const shopperData = {
      state: shopper.state,
      zipcode: shopper.zipcode,
      county: shopper.county,
      effective_date: shopper.effectiveDate,
      birthdate: shopper.dob,
      gender: shopper.gender,
      smoker: shopper.tobacco,
    };
    store.dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: AWAIT_STATE });
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/medigap/tmp/`,
      data: shopperData,
    }).then((res) => {
      const withMetaData = mapMedigapMetaData(res.data, savedMetaData, availableCarriers);
      store.dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: SUCCESS_STATE });
      store.dispatch({ type: 'SET_MEDIGAP_PLAN_LIST', payload: withMetaData });
      resolve();
    }).catch((err) => {
      store.dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: FAIL_STATE });
      reject(err);
    });
  });
}

export function loadMedigapData(shopper, availableCarriers) {
  // TODO(santeyio): implement a loadMetaData
  const savedMetaData = [];
  return loadMedigapPlans(shopper, savedMetaData, availableCarriers);
}

export function handleComparePlanState(planIDParam, compareListParam, setOnly = false) {
  const planID = Number(planIDParam); // make sure planID is a number
  let compareList = [...compareListParam];
  compareList = compareList.filter(Boolean);
  return new Promise((resolve, reject) => {
    // if the setOnly param is set we won't try to remove the plan
    if (planID !== 0) {
      if (setOnly) {
        if (!compareList.includes(planID) && compareList.length < 3) {
          compareList.unshift(planID);
          store.dispatch({ type: 'UPDATE_COMPARE_LIST', payload: compareList });
          store.dispatch({ type: 'TOGGLE_PLAN_COMPARE', payload: planID });
          resolve(compareList);
        } else if (!compareList.includes(planID) && compareList.length >= 3) {
          compareList.pop();
          compareList.unshift(planID);
          store.dispatch({ type: 'UPDATE_COMPARE_LIST', payload: compareList });
          store.dispatch({ type: 'TOGGLE_PLAN_COMPARE', payload: planID });
          resolve(compareList);
        }
      // if the planID is already in teh compare list, we need to remove it
      } else if (compareList.includes(planID)) {
        compareList.splice(compareList.indexOf(planID), 1);
        store.dispatch({ type: 'UPDATE_COMPARE_LIST', payload: compareList });
        store.dispatch({ type: 'TOGGLE_PLAN_COMPARE', payload: planID });
        resolve(compareList);
      // if we don't have an index and we're not removing it and the list is smaller than 3
      // ... then add it
      } else if (compareList.length < 3) {
        compareList.push(planID);
        store.dispatch({ type: 'UPDATE_COMPARE_LIST', payload: compareList });
        store.dispatch({ type: 'TOGGLE_PLAN_COMPARE', payload: planID });
        resolve(compareList);
      // if we don't have an index and we're not removing it and the list is NOT smaller than 3
      // ... then we can't add it. Return an error.
      } else if (compareList.length > 2) {
        reject();
      }
    } else {
      store.dispatch({ type: 'UPDATE_COMPARE_LIST', payload: compareList });
      store.dispatch({ type: 'TOGGLE_PLAN_COMPARE', payload: planID });
    }
  });
}

export function handleMedigapComparePlanState(planName, compareListParam, setOnly = false) {
  let compareList = [...compareListParam];
  compareList = compareList.filter(Boolean);
  return new Promise((resolve, reject) => {
    // if the setOnly param is set we won't try to remove the plan
    if (planName) {
      if (setOnly) {
        if (!compareList.includes(planName) && compareList.length < 3) {
          compareList.unshift(planName);
          store.dispatch({ type: 'UPDATE_MEDIGAP_COMPARE_LIST', payload: compareList });
          resolve(compareList);
        } else if (!compareList.includes(planName) && compareList.length >= 3) {
          compareList.pop();
          compareList.unshift(planName);
          store.dispatch({ type: 'UPDATE_MEDIGAP_COMPARE_LIST', payload: compareList });
          resolve(compareList);
        }
      // if the planID is already in teh compare list, we need to remove it
      } else if (compareList.includes(planName)) {
        compareList.splice(compareList.indexOf(planName), 1);
        store.dispatch({ type: 'UPDATE_MEDIGAP_COMPARE_LIST', payload: compareList });
        resolve(compareList);
      // if we don't have an index and we're not removing it and the list is smaller than 3
      // ... then add it
      } else if (compareList.length < 3) {
        compareList.push(planName);
        store.dispatch({ type: 'UPDATE_MEDIGAP_COMPARE_LIST', payload: compareList });
        resolve(compareList);
      // if we don't have an index and we're not removing it and the list is NOT smaller than 3
      // ... then we can't add it. Return an error.
      } else if (compareList.length > 2) {
        reject();
      }
    } else {
      store.dispatch({ type: 'UPDATE_MEDIGAP_COMPARE_LIST', payload: compareList });
    }
  });
}

export const loadExtraBenefitsKey = () => axios({
  url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/benefit/`,
  params: { site: process.env.REACT_APP_HOSTNAME || window.location.hostname },
}).then(({ data }) => {
  store.dispatch({ type: 'LOAD_EXTRA_BENEFITS_KEY', payload: data });
}).catch(default401);

export function getStandalonePlan(cpsID, planYear) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plan/${cpsID}/${planYear}/`,
    headers: { Authorization: `Token ${token}` },
  }).then(({ data }) => mapMedicareMetaData(data, []));
}
