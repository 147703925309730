import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../../../../constants';

// Actions
import { loadMedicarePlans } from '../../../../actions/plan';

/* eslint-disable */
let TIMEOUT_ID;

function HiddenRefreshButton() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { shopper } = useSelector(store => store);

  const visibleStyles = {
    position: 'relative',
    width: '170px',
    height: '40px',
    zIndex: 9,
    bottom: '0px',
    transition: 'all 600ms ease',
  };
  const hiddenStyles = {
    position: 'relative',
    width: '170px',
    height: '40px',
    bottom: '-40px',
    zIndex: 9,
    transition: 'all 600ms ease',
  }

  function changeVisibility(visible) {
    if (visible) {
      TIMEOUT_ID = setTimeout(() => {
        setVisible(true);
      }, 1500);
    } else {
      clearInterval(TIMEOUT_ID);
      setVisible(false);
    }
  }

  function handleReload() {
    setLoading(true);
    dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: AWAIT_STATE });    
    loadMedicarePlans(shopper)
      .then((data) => {
        setLoading(false);
        dispatch({ type: 'SET_MEDICARE_PLAN_LIST', payload: data });
        dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: SUCCESS_STATE });
      }).catch((err) => {
        setLoading(false);
        dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: FAIL_STATE });
      });
  }

  return (
    <div
      onMouseEnter={() => changeVisibility(true)}
      onMouseLeave={() => changeVisibility(false)}
      style={{
        position: 'fixed',
        width: '170px',
        height: '40px',
        right: '0px',
        top: '20%',
        zIndex: 9,
        transform: 'rotate(-0.25turn)',
        transformOrigin: 'bottom right',
      }}
    >
      <button
        type="button"
        className="btn btn-secondary"
        style={(visible || loading) ? visibleStyles : hiddenStyles}
        onClick={handleReload}
      >
        <b>{loading ? 'Loading...' : 'Refresh Plan Data'}</b>
      </button>
    </div>
  );
}

export default HiddenRefreshButton;
