const initialState = {
  planIds: [],
  zipcode: null,
  visible: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_COMPARE_FOOTER': {
      return { ...state, visible: action.payload };
    }
    case 'TOGGLE_COMPARE_FOOTER': {
      return { ...state, visible: !state.visible };
    }
    case 'ADD_PLAN_COMPARE': {
      const { planId, zipcode } = action.payload;
      const newPlanIds = state.planIds;
      if (newPlanIds.length < 3 && planId) {
        newPlanIds.push(planId);
      }
      return { ...state, planIds: [...newPlanIds], zipcode, visible: true };
    }
    case 'REMOVE_PLAN_COMPARE': {
      const { planId } = action.payload;
      const newPlanIds = state.planIds;
      const idxToRemove = newPlanIds.indexOf(planId);
      if (idxToRemove !== -1) {
        newPlanIds.splice(idxToRemove, 1);
      }
      return { ...state, planIds: [...newPlanIds] };
    }
    case 'CLEAR_COMPARE_PLANS': {
      return { ...initialState, planIds: [], visible: state.visible };
    }
    default:
      return state;
  }
}
