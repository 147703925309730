import moment from 'moment';

const initialState = {
  threeWayAccept: false,
  callData: {},
  callDetailsData: {},
  loading: false,
  showInstructions: true,
  sortTerm: '',
  sortInverse: false,
  stCallID: '',
  stCallStartDate: moment().startOf('day').subtract(1, 'year').toISOString(),
  stCallEndDate: moment().endOf('day').toISOString(),
  stClientFirstName: '',
  stClientLastName: '',
  stClientPhone: '',
  tempNPN: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_THREE_WAY_FIELD_VALUE':
      return { ...state, [action.field]: action.payload };
    case 'CLEAR_CALL_DATA':
      return { ...state, callData: {} };
    case 'SET_CALL_BOOK':
      return {
        ...state,
        callData: {
          ...state.callData,
          Items: action.payload,
        },
      };
    case 'SET_CALL_DETAILS_DATA':
      return { ...state, callDetailsData: action.payload };
    case 'SET_THREE_WAY_SORT_TERM':
      return { ...state, sortTerm: action.payload };
    case 'SET_THREE_WAY_SORT_INVERSE':
      return { ...state, sortInverse: action.payload };
    case 'SET_THREE_WAY_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_SEARCH_TERM':
      return { ...state, [action.name]: action.value };
    case 'CLEAR_SEARCH_TERM':
      return {
        ...state,
        stCallID: '',
        stCallStartDate: moment().startOf('day').subtract(1, 'year').toISOString(),
        stCallEndDate: moment().endOf('day').toISOString(),
        stClientFirstName: '',
        stClientLastName: '',
        stClientPhone: '',
      };
    case 'CLEAR_THREE_WAY':
      return { ...initialState, showInstructions: state.showInstructions };
    default:
      return state;
  }
}
