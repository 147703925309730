import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Components
import FooterNav from '../../nav/footernav';
import Search from './DrugSearch';
import AddButton from './AddButton';
import NavPage from '../../nav/NavPage';
import BrandDrugButton from './BrandDrugButton';
import GenericDrugButton from './GenericDrugButton';
import DosageLayout from './DosageLayout';

class AddDrugs extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_SELECTED_DRUG' });
  }

  render() {
    const {
      displayBrandDrug,
      navigate,
      selectedDrug,
    } = this.props;

    return (
      <NavPage
        activeLink="/rx"
        showShopperInfobar
      >
        <div className="rx rx-add">
          <div className="mmb-header">
            Rx
          </div>
          <div className="mmb-search">
            <Search />
            <div className="mt-5 mb-5 ms-2">
              {`The most common dosage, frequency, and quantity are preselected.
              Please change the selection if client's dosage, frequency, or
              quantity are different.`}
            </div>
            <div className="row ms-2">
              {
                selectedDrug
                  ? (
                    <>
                      <div className="col-2 d-flex">
                        <b>Brand vs. generic*</b>
                      </div>
                      <div className="col-3 d-flex">
                        <b>Dosage</b>
                      </div>
                      <div className="col-1 d-flex">
                        <b>Quantity</b>
                      </div>
                      <div className="col-2 d-flex">
                        <b>Frequency</b>
                      </div>
                      <div className="col-3 d-flex">
                        <b>Package</b>
                      </div>
                    </>
                  ) : null
              }
            </div>
            <div className="row mt-3">
              <div className="col-2 d-flex justify-content-center">
                <BrandDrugButton />
              </div>
              {
                (displayBrandDrug && selectedDrug)
                  ? <DosageLayout />
                  : null
              }
            </div>
            <div className="row mt-3">
              <div className="col-2 d-flex justify-content-center">
                <GenericDrugButton />
              </div>
              {
                (!displayBrandDrug && selectedDrug)
                  ? <DosageLayout />
                  : null
              }
            </div>
            <div className="row mt-5">
              <div className="col-2 pb-5">
                {
                  selectedDrug
                    ? <AddButton />
                    : null
                }
              </div>
            </div>
            <div className="row ms-2">
              {
                selectedDrug
                  && `*Generic medications are a lower cost option compared to brand-name
                      medications, and they are the same when it comes to active ingredient,
                      quality, dosage, strength, and routine of administration.`
              }
            </div>
          </div>
        </div>
        <FooterNav
          planOnClick={() => navigate('/plans/list')}
          nextOnClick={() => navigate('/rx/add/pharmacy')}
          backButtonText="Back to Rx"
          backOnClick={() => navigate('/rx/list')}
        />
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    brandDrug: store.drug.brandDrug,
    displayBrandDrug: store.drug.displayBrandDrug,
    genericDrug: store.drug.genericDrug,
    selectedDrug: store.drug.selectedDrug,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(AddDrugs));
