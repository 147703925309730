const initialState = {
  anocCode: '',
  callPermission: false,
  callNotes: [],
  cities: [],
  city: '',
  counties: [],
  county: '',
  countyName: '',
  created: '',
  currentPlan: '',
  dob: '',
  doctorList: [],
  drugList: [],
  eDateMonth: '',
  eDateYear: '',
  effectiveDate: '',
  eligibilityData: {},
  email: '',
  emailPermission: false,
  firstName: '',
  gender: null,
  genderRequired: false,
  homePhone: '',
  invalidZipErr: false,
  lastName: '',
  lastNameRequired: false,
  lastUpdated: '',
  leadID: '',
  leadSource: '',
  leadType: '',
  medicareID: '',
  mobilePhone: '',
  pharmacyList: [],
  plansAvailability: '--',
  planYear: '',
  quotes: [],
  quotesCount: 0,
  recommendation: {},
  scrapeID: '',
  soaList: [],
  state: '',
  status: '',
  subsidyLevel: '4',
  subsidyPercent: '0',
  subsidyPercentageUnknown: false,
  subsidyQuestion: 'no_extra_help',
  textPermission: false,
  tobacco: null,
  tobaccoRequired: false,
  username: '',
  weather: {},
  zipcode: '',
  validation: Object(),
  errors: Object(),
  schema: Object(),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_PLAN_AVAILABILITY':
      return { ...state, plansAvailability: action.payload ? 'Yes' : 'No' };
    case 'SET_EFFECTIVE_DATE':
      return { ...state, effectiveDate: action.payload };
    case 'SET_SHOPPER_WEATHER':
      return {
        ...state,
        weather: action.payload,
      };
    case 'ADD_SHOPPER_QUOTE':
      return {
        ...state,
        quotesCount: state.quotesCount + 1,
        quotes: [action.payload, ...state.quotes],
      };
    case 'SET_SHOPPER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SHOPPER_CALL_NOTES':
      return {
        ...state,
        callNotes: action.payload,
      };
    case 'SET_SHOPPER_DRUGS':
      return {
        ...state,
        drugList: action.payload,
      };
    case 'SET_SHOPPER_PHARMACIES':
      return {
        ...state,
        pharmacyList: action.payload,
      };
    case 'SET_SHOPPER_DOCTORS':
      return {
        ...state,
        doctorList: action.payload,
      };
    case 'SET_SHOPPER_QUOTES':
      return {
        ...state,
        quotes: action.payload,
        quotesCount: action.payload.length,
      };
    case 'SET_SHOPPER_SOAS':
      return {
        ...state,
        soaList: action.payload,
      };
    case 'UPDATE_SHOPPER_KEY':
      return {
        ...state,
        [action.payload.key]: action.payload.val,
      };
    case 'UPDATE_SHOPPER':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE_CURRENT_PLAN':
      return {
        ...state,
        recommendation: {
          ...state.recommendation,
          curContractID: action.payload,
        },
      };
    case 'UPDATE_SHOPPER_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'UPDATE_SHOPPER_VALIDATION':
      return {
        ...state,
        validation: {
          ...state.validation,
          ...action.payload,
        },
      };
    case 'UPDATE_MEDCAB_DOCTOR':
      return {
        ...state,
        doctorList: state.doctorList.map((doc) => {
          const updateDoc = action.payload;
          if (doc.id === updateDoc.id) {
            return updateDoc;
          }
          return doc;
        }),
      };
    case 'ADD_DRUG_TO_MEDCAB':
      return {
        ...state,
        drugList: [...state.drugList, action.payload.drug],
      };
    case 'ADD_PHARMACY_TO_MEDCAB':
      return {
        ...state,
        pharmacyList: [...state.pharmacyList, action.payload],
      };
    case 'ADD_DOCTOR_TO_MEDCAB':
      return {
        ...state,
        doctorList: [...state.doctorList, action.payload],
      };
    case 'ADD_SOA':
      return {
        ...state,
        soaList: [...state.soaList, action.payload],
      };
    case 'DELETE_PHARMACY_FROM_MEDCAB':
      return {
        ...state,
        pharmacyList: state.pharmacyList.filter((pharmacy) => pharmacy.npi !== action.payload),
      };
    case 'DELETE_DOCTOR_FROM_MEDCAB':
      return {
        ...state,
        doctorList: state.doctorList.filter((doctor) => doctor.id !== action.payload),
      };
    case 'DELETE_DRUG_FROM_MEDCAB': {
      if (action.payload.dosageID) {
        return {
          ...state,
          drugList: state.drugList.filter((drug) => (drug.dosageID !== action.payload.dosageID) && (drug.packageID !== action.payload.packageID)),
        };
      }
      return {
        ...state,
        drugList: state.drugList.filter((drug) => drug.id !== action.payload.id),
      };
    }
    case 'EDIT_DRUG_IN_MEDCAB':
      return {
        ...state,
        drugList: state.drugList.map((drug, index) => {
          if (index === action.payload.index) {
            return action.payload.drug;
          }
          return drug;
        }),
      };
    case 'ADD_DOCTOR_PLANS':
      return {
        ...state,
        doctorList: state.doctorList.map((doctor) => {
          if (doctor.id === action.payload.npi) {
            return { ...doctor, plans: action.payload.plans };
          }
          return doctor;
        }),
      };
    case 'SET_ELIGIBILITY_DATA':
      return {
        ...state,
        scrapeID: action.payload.data.id,
        eligibilityData: { ...action.payload.data },
      };
    case 'SET_INVALID_ZIP_ERR':
      return { ...state, invalidZipErr: true };
    case 'CLEAR_INVALID_ZIP_ERR':
      return { ...state, invalidZipErr: false };
    case 'CLEAR_SHOPPER':
      return initialState;
    default:
      return state;
  }
}
