/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useOutsideClick } from '../hooks';

function ModalHeader({
  closeModalAction,
  closeIcon,
  headerClasses,
  h2Classes,
  title,
}) {
  return (
    <div className={`p-3 ${headerClasses}`}>
      <h4 className={h2Classes}>
        {title}
        {closeIcon
          ? (
            <FontAwesomeIcon
              role="button"
              className="float-end"
              tabIndex={0}
              icon={faTimes}
              onClick={closeModalAction}
            />
          ) : null}
      </h4>
    </div>
  );
}

function SlideoutModal({
  // allowOverlayClickClose = true,
  children,
  closeModalAction = () => {},
  closeIcon = true,
  headerClasses = '',
  h2Classes = 'fw-bold mb-0',
  hideHeader = false,
  id,
  isOpen = false,
  // modalClasses = '',
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  style,
  title,
  width = '70%',
  slideInFrom = 'right', // 'right' or 'left'
}) {
  const ref = useRef();
  const [ bgVisibility, setBgVisibility ] = useState(isOpen ? 'visible' : 'hidden');
  const [ bgOpacity, setBgOpacity ] = useState(isOpen ? '1' : '0');
  const [ transitionStyles, setTransitionStyles ] = useState({ [slideInFrom]: isOpen ? '0px' : '-1000px' });

  const backgroundStyles = {
    top: 0,
    zIndex: 9999,
    position: 'fixed',
    width: '100%',
    height: '100%',
    opacity: bgOpacity,
    visibility: bgVisibility,
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: 'all 0.4s ease-out',
  };

  useEffect(() => {
    if (isOpen) {
      setBgOpacity('1');
      setBgVisibility('visible');
      setTimeout(() => setTransitionStyles({ [slideInFrom]: '0px' }), 200);
    } else {
      setTimeout(() => setBgOpacity('0'), 200);
      setTimeout(() => setBgVisibility('hidden'), 200);
      setTransitionStyles({ [slideInFrom]: '-1000px' });
    }
  }, [isOpen, bgOpacity, bgVisibility, slideInFrom]);

  function closeModalWrapper() {
    if (isOpen) closeModalAction();
  }

  useOutsideClick(ref, closeModalWrapper);

  return (
    <div style={backgroundStyles}>
      <div
        ref={ref}
        id={id}
        style={{
          position: 'fixed',
          top: 0,
          visibility: isOpen ? 'visible' : 'hidden',
          display: 'block',
          background: 'white',
          width,
          height: '100%',
          overflow: 'auto',
          transition: 'all 0.4s ease-out',
          ...transitionStyles,
          ...style,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            paddingTop: paddingTop || padding || '10px',
            paddingBottom: paddingBottom || padding || '10px',
            paddingRight: paddingRight || padding || '20px',
            paddingLeft: paddingLeft || padding || '20px',
          }}
        >
          {!hideHeader && (
            <ModalHeader
              closeModalAction={closeModalAction}
              closeIcon={closeIcon}
              headerClasses={headerClasses}
              h2Classes={h2Classes}
              title={title}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default SlideoutModal;
