import React from 'react';
import { connect } from 'react-redux';

// Actions
import { createShopper, updateEligibilityScrapeWithShopper } from '../../../actions/shopper';
import { saveQuickQuoteDrugs } from '../../../actions/drug';
import { saveQuickQuotePharmacies } from '../../../actions/pharmacy';

// Components
import StandardModal from '../../common/StandardModal';
// import { Disposition }  from '../../shopper/components';
/* these are leftover imports from pre-refactoring of shopper details page
import NameInput from '../../shopper/inputs/NameInput';
import DisabledInput from '../../shopper/inputs/DisabledInput';
*/

// Utilities
import { showPopup } from '../../../utils/popup';
import { validateShopperInput } from '../../../utils/general';

class SaveShopperModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleSaveShopper = this.handleSaveShopper.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleChange(event) {
    const {
      dispatch,
    } = this.props;

    const key = event.target.name;
    const val = event.target.value;

    dispatch({
      type: 'UPDATE_SHOPPER_KEY',
      payload: { val, key },
    });
  }

  handleValidation() {
    const {
      shopper,
    } = this.props;

    const status = validateShopperInput(shopper);

    if (status === '') {
      this.handleSaveShopper();
    } else {
      showPopup('Error', `${status} Input Value is Invalid.`);
    }
  }

  handleSaveShopper() {
    const {
      dispatch,
      shopper,
    } = this.props;

    if (shopper.leadID !== '' && shopper.firstName !== '') {
      createShopper(shopper, true).then((data) => {
        dispatch({ type: 'UPDATE_SHOPPER', payload: data });
        saveQuickQuoteDrugs(shopper.drugList, shopper.username);
        saveQuickQuotePharmacies(shopper.pharmacyList, shopper.username);
        if (shopper.scrapeID) {
          updateEligibilityScrapeWithShopper(shopper.username, shopper.scrapeID);
        }
        dispatch({ type: 'HIDE_MODAL', payload: 'saveShopperModal' });
      }).catch(() => {
        showPopup('Error', 'There was a problem saving the Shopper.');
      });
    } else {
      showPopup('Error', 'Fill out a Lead ID and First Name');
    }
  }

  closeModal() {
    const {
      dispatch,
    } = this.props;

    dispatch({ type: 'HIDE_MODAL', payload: 'saveShopperModal' });
    dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'leadID', val: '' } });
    dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'firstName', val: '' } });
  }

  render() {
    const {
      saveShopperModal,
      // shopper,
    } = this.props;

    return (
      <StandardModal
        isOpen={saveShopperModal}
        closeModalAction={this.closeModal}
        title="Save Shopper & Medicine Cabinet"
        width="40%"
      >
        <div className="px-3">
          {/* this is odd... why is this a disposition field but changing leadID?
          <Disposition
            title="Lead ID:"
            name="leadID"
            placeholder="e.g. ABC123"
            width="100%"
            value={shopper.leadID}
            onChange={this.handleChange}
            required
          />
          */}
          {/*
          <NameInput
            title="First Name:"
            name="firstName"
            placeholder="e.g. John"
            width="100%"
            value={shopper.firstName}
            onChange={this.handleChange}
            required
          />
          <NameInput
            title="Last Name:"
            name="lastName"
            placeholder="e.g. Doe"
            width="100%"
            value={shopper.lastName}
            onChange={this.handleChange}
          />
          <DisabledInput
            title="Lead Type:"
            name="leadType"
            placeholder="e.g. Purchased, ANOC"
            onChange={this.handleChange}
            value={shopper.leadType}
            width="100%"
          />
          <DisabledInput
            title="Lead Source:"
            name="leadSource"
            placeholder="e.g. Vendor Name"
            value={shopper.leadSource}
            onChange={this.handleChange}
            width="100%"
          />
          */}
        </div>
        <div className="px-3 pb-3 text-right">
          <button
            className="btn btn-primary"
            onClick={this.handleValidation}
            type="button"
          >
            Save
          </button>
          <button
            className="btn btn-secondary ms-1"
            onClick={this.closeModal}
            type="button"
          >
            Close
          </button>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    saveShopperModal: store.modal.saveShopperModal,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(SaveShopperModal);
