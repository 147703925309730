/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Components
import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';
import TableRow from './TableRow';
import SelectedPharmacy from './SelectedPharmacy';
import DeleteDrugModal from './DeleteDrugModal';

class ListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      doctorsFeature,
      drugList,
      navigate,
      selectedPharmacy,
      shopper,
    } = this.props;

    return (
      <NavPage
        activeLink="/rx"
        showShopperInfobar={shopper.username}
      >
        <div className="rx">
          <div className="mmb-header" style={{ minHeight: '90px' }}>
            <div className="d-flex flex-column">
              Rx
              <button
                type="button"
                className="btn btn-primary mb-0 mt-auto fw-bold align-self-start mt-4"
                onClick={() => navigate('/rx/add/drug')}
              >
                Add medications
              </button>
            </div>
            <div className="me-0 ms-auto pharmacy-box">
              <SelectedPharmacy button/>
            </div>
          </div>
          <div className="rx-list">
            <table className="table table-striped drug-list-table">
              <thead style={{ whiteSpace: 'nowrap' }}>
                <tr>
                  <th>Medication name</th>
                  <th>Brand vs generic</th>
                  <th>Dosage</th>
                  <th>Quantity</th>
                  <th>Supply length</th>
                  <th>Package</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {drugList.length
                  ? drugList.map(drug => (
                    <TableRow
                      key={`${drug.id}-${drug.dosageID}`}
                      drug={drug}
                    />
                  )) : (
                    <tr style={{ height: '200px', userSelect: 'none' }}>
                      <td colSpan="12" className="text-center align-middle"><i>Looks like no medications have been added yet.</i></td>
                    </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterNav
          planOnClick={() => navigate('/plans/list')}
          nextOnClick={() => {
            if (selectedPharmacy) {
              if (doctorsFeature) {
                navigate('/doctors');            
              } else {
                navigate('/plans');
              }
            } else {
              navigate('/rx/add/pharmacy')
            }
          }}
        />
        <DeleteDrugModal />
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    drugList: store.shopper.drugList,
    shopper: store.shopper,
    pharmacyList: store.shopper.pharmacyList,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    doctorsFeature: store.theme.features.doctors,
  };
}

export default connect(mapStateToProps)(withRouterProps(ListView));
