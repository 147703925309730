/* eslint-disable object-curly-newline */
import React from 'react';
import { connect } from 'react-redux';

import LoaderButton from '../../common/LoaderButton';
import BackToClientsButton from './BackToClients';

// utils
import { telephonyChecks } from '../../../utils/nav';

class FooterNav extends React.PureComponent {
  render() {
    const {
      confirmationModal,
      planLoading,
      nextLoading,
      planOnClick,
      nextOnClick,
      backOnClick,
      backButtonText,
      footerStyle,
      noPlanLink,
      noNextLink,
      hideLeftButton = false,
      hideRightButton = false,
      leftButtonText,
      rightButtonText,
      telephonyFeature,
      telephonyDebug,
      callStatus,
      meetType,
      disableNext,
    } = this.props;

    const nextDisabled = disableNext || telephonyChecks({ telephonyFeature, telephonyDebug, meetType, callStatus });

    return (
      <div
        className="footer-nav"
        style={footerStyle}
      >
        <div className="d-flex">
          <div className="me-auto">
            <BackToClientsButton
              confirmationModal={confirmationModal}
              backOnClick={backOnClick}
              backButtonText={backButtonText}
            />
          </div>
          <div>
            {!hideLeftButton && (
              <LoaderButton
                className="me-4"
                color="outline-secondary"
                style={{
                  paddingRight: '30px',
                  paddingLeft: '30px',
                  display: noPlanLink ? 'none' : '',
                }}
                text={leftButtonText}
                loading={planLoading}
                onClick={planOnClick}
              />
            )}
            {!hideRightButton && (
              <LoaderButton
                color="primary"
                style={{
                  paddingRight: '25px',
                  paddingLeft: '25px',
                  fontWeight: '700',
                  display: noNextLink ? 'none' : '',
                }}
                text={rightButtonText}
                loading={nextLoading}
                onClick={nextOnClick}
                disabled={nextDisabled}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

FooterNav.defaultProps = {
  leftButtonText: 'Plan List',
  rightButtonText: 'Next',
};

function mapStateToProps(store) {
  return {
    telephonyFeature: store.theme.features.telephony,
    telephonyDebug: store.theme.features.telephonyDebug,
    callStatus: store.telephony.status,
    meetType: store.telephony.meetType,
  };
}

export default connect(mapStateToProps)(FooterNav);
