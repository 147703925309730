import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithTracker from '../common/WithTracker';

// Components
import RouterLogic from './RouterLogic';
import DashboardView from './dashboard';
import LeadView from './lead';
import LoginView from './login';
import ErrorView from './error';

export default function () {
  return (
    <WithTracker>
      <Routes>
        <Route path="" element={<RouterLogic />} />
        <Route path="lead" element={<LeadView />} />
        <Route path="dashboard" element={<DashboardView />} />
        <Route path="login" element={<LoginView />} />
        <Route path="error/:errorType" element={<ErrorView />} />
        <Route path="error" element={<ErrorView />} />
      </Routes>
    </WithTracker>
  );
}
