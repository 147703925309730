/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
// Lifted from enrollment-portal utils/form
// https://github.com/mymedicarebot/enrollment-portal/blob/dev/src/utils/form.js
import moment from 'moment';
import { store } from '../../store';
import { updateHRA } from '../../actions/hra';

// Carriers
import formatUnitedhealthcare from './formatUnitedhealthcare';

// Just a wrapper to pass in a object of { carrier, ... }
export function formatCarrierHRA(d) {
  const carrierMap = {
    unitedhealthcare: (f) => formatUnitedhealthcare(f),
  };
  const { carrier } = d;
  try {
    return carrierMap[carrier.toLowerCase()](d);
  } catch (err) {
    console.error(err);
    return `HRA formatting not specified for ${carrier.toLowerCase()}`;
  }
}

export function getPageFromURL(form = [], url) {
  const urlParts = url.split('/');
  let returnPage;
  form.forEach((section) => {
    if (section.route === `/${urlParts.at(-2)}`) {
      section.pages.forEach(page => {
        if (page.route === `/${urlParts.at(-1)}`) {
          returnPage = page;
        }
      });
    }
  });
  return returnPage;
}

// HRA form / site_config functions
export function getAllRoutes(form = [], baseRoute = '') {
  const urls = [];
  // Enrollment portal has more route nesting than required at this time
  const formRoute = `${baseRoute}`;
  form.forEach(section => {
    const sectionRoute = section.route;
    section.pages.forEach(page => {
      urls.push(`${formRoute}${sectionRoute}${page.route}`);
    });
  });
  return urls;
}

export function getRoutes(location) {
  // takes the location object returned from react-router-dom useLocation
  const l = location.pathname.split('/');
  return {
    currentSection: `/${l[1]}`,
    currentPage: `/${l[2]}`,
  };
}

export function getSection(location) {
  // takes the location object returned from react-router-dom useLocation
  const { form } = store.getState().hra;
  const { currentSection } = getRoutes(location);
  return form.find(section => section.route === currentSection);
}

export function getField(form = [], name) {
  let rfield = false;
  form.forEach(section => {
    section.pages.forEach(page => {
      page.fields.forEach(field => {
        if (field.name === name) {
          rfield = field;
        }
      });
    });
  });
  return rfield;
}

export function getFieldKey(form = [], name, key) {
  const field = getField(form, name);
  return field[key];
}

export function getAllFields(form = []) {
  const fields = [];
  form.forEach(section => {
    section.pages.forEach(page => {
      page.fields.forEach(field => fields.push(field));
    });
  });
  return fields;
}

export function getOptions(form = [], name) {
  // returns either `false` or an array in the shape
  // [
  //   {label: 'field label', value: 'value'},
  //   {label: 'field label', value: 'value'},
  //   &etc...
  // ]
  let options = false;
  const field = getField(form, name);
  if (field.options) {
    options = field.options;
  }
  return options;
}

// Used in hra/components/input, which then call updateHRA themselves
let saveTimer = null;
export function handleChange({
  value,
  valuesArray,
  name,
  onChangeCallback = (newValue) => {},
}) {
  if (Array.isArray(valuesArray)) {
    store.dispatch({ type: 'SET_HRA_FIELD_VALUE', name, value: valuesArray });
  } else {
    store.dispatch({ type: 'SET_HRA_FIELD_VALUE', name, value });
  }
  onChangeCallback(value);

  window.clearInterval(saveTimer);
  const { hra } = store.getState();
  saveTimer = setTimeout(() => updateHRA(hra), 3000);
}

export function fmtHRA(h, options) {
  options = {
    post: false,
    ...options,
  };
  let fmt = {};
  if (options.post) {
    fmt = {
      created: h.created,
      effective_date: h.effectiveDate,
      enrollment: h.enrollmentID, // note - HRA objects do not have enrollment_id, just enrollment
      fields: h.fields,
      quote_id: h.quoteID,
      submitted: h.submitted,
      submitted_flag: h.submittedFlag,
      submit_date: h.submitDate,
      updated: h.updated,
      source: h.source,
    };
  } else {
    fmt = {
      created: h.created,
      effectiveDate: h.effective_date,
      enrollmentID: h.enrollment,
      fields: h.fields,
      quoteID: h.quote_id,
      submitted: h.submitted,
      submitDate: h.submit_date,
      submittedFlag: h.submitted_flag,
      update: h.updated,
      source: h.source,
    };
  }
  return fmt;
}

export function getHRAConfigName(carrier = '') {
  const formattedCarrierName = carrier.toLowerCase().replaceAll(' ', '-');
  let configName = `hra_form_agent_${formattedCarrierName}_json`;
  if (formattedCarrierName === 'unitedhealthcare') {
    configName = 'hra_form_agent_unitedhealthcare_version2_json';
  }
  return configName;
}

export function getHRADeadline(quote = {}) {
  const { configs = [] } = store.getState().hra;
  const { enrollment = {}, carrier } = quote;

  const configName = getHRAConfigName(carrier);
  const config = configName in configs ? configs[configName] : {};
  const {
    timeToSubmit = 0,
    timeUnit = 'days',
  } = config;
  let deadline = moment(enrollment.submit_date).add(timeToSubmit, timeUnit);
  if (timeUnit === 'days') {
    deadline = deadline.endOf('day');
  }
  return deadline;
}

// NOTE(joeysapp): timeUnit is days or hours, hours being a strict time, days being eod.
function inHRASubmissionTime(quote = {}) {
  const deadline = getHRADeadline(quote);
  const now = moment.tz(moment(), moment.tz.guess());
  return deadline > now;
}

export function canViewHRA(quote = {}) {
  let canView = true;
  const {
    features: { hra: hraFeature },
  } = store.getState().theme;
  if (!hraFeature) canView = false;

  const {
    enrollment = {},
    carrier,
    planYear,
    hra,
  } = quote;
  // NOTE(santeyio): sometimes quote.hra can be set as null which can't be destructured,
  // so we need to catch that case here
  const { submit_date: hraSubmitDate } = (hra || {});
  const {
    submitted_flag: submitted,
    submit_date: submitDate,
  } = enrollment;
  if (!submitted) canView = false;
  if (!inHRASubmissionTime(quote)) canView = false;
  if (hraSubmitDate) canView = true;
  // if (submitted) canView = true;

  const { configs = {} } = store.getState().hra;
  const configName = getHRAConfigName(carrier);
  const config = configName in configs ? configs[configName] : {};
  if (!configs[configName]) canView = false;
  if (config.disabled) canView = false;

  return canView;
}
