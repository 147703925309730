import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { formatStatus } from '../../../utils/general';
import { withRouterProps } from '../../../utils/hocs';

// Actions
import { pushTrackingEvent } from '../../../actions/tracking';

class MobileShopper extends React.PureComponent {
  validateLeadNavigation(username) {
    const {
      dispatch,
      navigate,
    } = this.props;
    dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: true });
    dispatch({ type: 'SET_LEAD_UN', payload: username });
    navigate('/mobile/lead');
  }

  render() {
    const {
      agentUsername,
      firstName,
      lastName,
      status,
      mobilePhone,
      homePhone,
      username,
    } = this.props.shopper;

    const isNew = status === 10;
    const nameStyle = {
      lineHeight: isNew ? '52px' : '20px',
    };

    const newBadgeElement = (
      <div className="mobile-leads-list-new-badge">
        NEW
      </div>
    );

    return (
      <tr
        className="mobile-table-row"
      >
        <div
          role="gridcell"
          tabIndex={0}
          className="mobile-table-row-element shadow"
          onClick={() => this.validateLeadNavigation(username)}
        >
          <td
            role="gridcell"
            className="align-middle"
            id="shopper-id"
            style={{ marginTop: !isNew ? '9px' : '0px', flexDirection: 'column' }}
          >
            <div
              className="mobile-font-reg"
              style={{
                textTransform: 'capitalize',
                lineHeight: '52px',
                paddingLeft: '19px',
                ...nameStyle,
              }}
            >
              <b>{firstName} {lastName}</b> {isNew && newBadgeElement}
            </div>
            {!isNew && (
            <div
              className="mobile-font-it"
              style={{
                paddingLeft: '19px',
                whiteSpace: 'nowrap',
                width: '66vw',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {formatStatus(status)}
            </div>
            )}
          </td>

          <td
            className="align-middle"
            id="shopper-phone"
            style={{
              padding: '0px',
              position: 'absolute',
              right: '0px',
            }}
          >
            <a
              className="btn btn-mobile btn-primary btn-list-telephone"
              type="button"
              href={`tel:${mobilePhone || homePhone}`}
              onClick={() => pushTrackingEvent('sms_dashboard_link_call_button_click', agentUsername, username)}
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                style={{ height: '100%' }}
              />
            </a>
          </td>
        </div>
      </tr>
    );
  }
}

function mapStateToProps(store) {
  return {
    agentUsername: store.mobile.agentUsername,
  };
}

export default connect(mapStateToProps)(withRouterProps(MobileShopper));
