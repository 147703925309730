import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Utilities
import { calculateProviderCoverage } from '../../../../utils/general';

// Components
import DoctorPopover from '../../../doctors/popover';
import useOutsideClick from '../../../../common/hooks/useOutsideClick';

function Doctors({
  plan,
  style,
  spanClassName = 'doctors-in-network',
  showHighlightBorder = true,
  showFullText = true,
}) {
  const navigate = useNavigate();
  const [ pct, setPct ] = useState(false); // pct = provider coverage tooltip
  const { doctorList } = useSelector(store => store.shopper);

  const providerCoverageRef = useRef(null);
  useOutsideClick(providerCoverageRef, setPct);

  const externalDivClassName = 'plancard-col-pricing-doctors';

  // Located on planlist/recommended and planlist cards themselves,
  // we only want the background on the latter
  const internalSpanClassName = `${spanClassName} ${showFullText ? 'plancard-doctors' : 'recommended-doctors'}`;

  return (
    <div
      className={externalDivClassName}
      style={{
        border: pct && showHighlightBorder ? '1px solid var(--bs-blue)' : '1px solid transparent',
        boxShadow: pct && showHighlightBorder ? '0 0.125rem 0.25rem rgb(0 0 0 / 32%)' : '',
        cursor: 'pointer',
        userSelect: 'none',
        ...style,
      }}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (doctorList.length <= 0) {
          navigate('/doctors');
        }
      }}
    >
      <span
        className="plancard-col-pricing-cell-top fw-bold pb-3"
        style={{ display: showFullText ? 'inline-block' : 'none' }}
      >
        {doctorList.length > 0 ? 'Doctors in-network' : 'No doctors added'}
      </span>
      {
        doctorList.length > 0
          ? (
            <>
              <span
                className={internalSpanClassName}
                onClick={() => setPct(!pct)}
                role="button"
                tabIndex={0}
                style={{ fontWeight: '700' }}
              >
                {calculateProviderCoverage(doctorList, plan.cpsID)}
              </span>
              <div
                className="provider-coverage-popover-container"
                role="tooltip"
                style={{ display: pct ? 'inline-block' : 'none' }}
                ref={providerCoverageRef}
              >
                <div
                  className="provider-coverage-popover-exit"
                  onClick={() => setPct(false)}
                  role="button"
                  tabIndex={0}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
                <DoctorPopover
                  planCPS={plan.cpsID}
                  planName={plan.planName}
                />
              </div>
            </>
          )
          : (
            <span
              className={`mmb-link ${internalSpanClassName}`}
              onClick={() => navigate('/doctors')}
              role="button"
              tabIndex={0}
              title="Add doctors"
              style={{ fontWeight: '700' }}
              // note(joeysapp): We want this as a data-url, shown
              // in the bottom-left of the browser window.
              // title={`${window.location.origin}/doctors/`}
            >
              Add doctors
            </span>
          )
        }
    </div>
  );
}

export default Doctors;
