import React from 'react';

const MmbRadio = ({
  value,
  controlId,
  name,
  label,
  onChange,
  disabled = false,
  className,
  checked = false,
}) => (
  <div className={`mb-2 ${className}`}>
    <input
      id={controlId}
      name={name}
      disabled={disabled}
      type="radio"
      onChange={onChange}
      className="form-check-input"
      value={value}
      checked={checked || value}
    />
    <label htmlFor={controlId} className="form-label">&nbsp; {label}  </label>
  </div>
);


export default MmbRadio;
