import axios from 'axios';

export default function getGACode() {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/tracking/`,
  })
    .then((data) => data.data.tracking_code)
    .catch(() => '');
}
