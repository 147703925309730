export default [
  {
    name: 'Plan Type',
    key: 'PlanTypeDesc',
    format: 'str',
  },
  {
    name: 'Doctors Covered',
    key: 'DoctorsCovered',
    format: 'str',
  },
  {
    name: 'Medical Coverage',
    key: 'MedicalCoverage',
    format: 'bool',
  },
  {
    name: 'Rx Coverage',
    key: 'HasRXCoverage',
    format: 'bool',
  },
  {
    name: 'Mail Order Pharmacy',
    key: 'ContainMailOrder',
    format: 'bool',
  },
  {
    name: 'Insulin Savings Program',
    key: 'InsulinSavingsProgram',
    format: 'bool',
  },
  {
    name: 'Selected Pharmacy',
    key: 'PharmacyNetworkDesc',
    format: 'str',
    pharmacyDependent: true,
  },
  {
    name: 'Est Annual Rx OOPC',
    key: 'EstimatedAnnualRXOOPC',
    format: 'dollar',
    pharmacyDependent: true,
  },
  {
    name: 'Total Annual OOPC',
    key: 'TotalAnnualOOPC',
    format: 'dollar',
    pharmacyDependent: true,
  },
  {
    name: 'Star Rating',
    key: 'OverallRating',
    format: 'star',
  },
  {
    name: 'Total Monthly Premium',
    key: 'TotalPlanPremium',
    format: 'dollar',
  },
  {
    name: 'Annual Prescription Drug Deductible',
    key: 'DrugDeductibleLimit',
    format: 'dollar',
  },
  {
    name: 'Initial Coverage Limit',
    key: 'InitialCoverageLimit',
    format: 'dollar',
    onUndefined: 'Not Applicable',
  },
  {
    name: 'Additional Gap Coverage',
    key: 'CoverageGapTiers',
    format: 'tier',
  },
  {
    name: 'True Out of Pocket Cost Threshold',
    key: 'CatastrophicCoverageLimit',
    format: 'dollar',
  },
  {
    name: 'Medical Plan Type',
    key: 'MedicalPlanTypeDesc',
    format: 'str',
  },

  // {
  //   name: 'Extra Benefits',
  //   key: 'AnthemExtras',
  //   format: 'bool',
  //   options: ['Not Available', 'Available'],
  // },
  {
    name: 'Medicare Part B Premium Reduction',
    key: 'PartBPremiumReductionDesc',
    format: 'str',
  },
];
