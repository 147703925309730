import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCirclePlay,
  faDownload,
  faFileLines,
  faPenCircle,
  // faCircleXmark,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleRight } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { Collapse } from 'reactstrap';

// utils
import { formatNumToPhone } from '../../utils/format-tel';
import forceDownload from './force-download';

// Components
import NavPage from '../nav/NavPage';
import { updateAgent } from '../../actions/agent';
import { Spinner, Table } from '../../common/components';
import Pagination from './Pagination';
import TableFilter from './TableFilter';
import { loadThreeWayCalls, updateThreeWayCall } from '../../actions/three_way';
import { showPopup } from '../../utils/popup';

function NPNDisplay() {
  const dispatch = useDispatch();
  const { npn, npnLinked, npnCallRecording } = useSelector(store => store.agent);

  function handleChange(event) {
    dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'tempNPN', payload: event.target.value });
  }

  if (npnCallRecording) {
    return (
      <div style={{ maxWidth: '8rem' }}>
        <input className="form-control" type="text" disabled value={npnCallRecording} />
      </div>
    );
  }
  if (npn && npnLinked) {
    return (
      <>
        <i className="ms-2">
          Select which NPN will be associated with your 3-way calling / recording account. This selection is permanent.
        </i>
        <div className="form-check mt-2 ms-2">
          <input
            type="radio"
            className="form-check-input"
            name="npn"
            id="npn_agent"
            value={npn}
            onChange={(e) => handleChange(e)}
          />
          <label
            className="form-check-label"
            htmlFor="npn_agent"
          >
            {npn}
          </label>
        </div>
        <div className="form-check ms-2">
          <input
            type="radio"
            className="form-check-input"
            name="npn"
            id="npn_linked"
            value={npnLinked}
            onChange={(e) => handleChange(e)}
          />
          <label
            className="form-check-label"
            htmlFor="npn_linked"
          >
            {npnLinked}
          </label>
        </div>
      </>
    );
  }
  return (
    <div style={{ maxWidth: '8rem' }}>
      <input className="form-control" type="text" disabled value={npn || npnLinked} />
    </div>
  );
}


function ThreeWay() {
  const dispatch = useDispatch();
  const [editRow, setEditRow] = useState({});
  const [audioRow, setAudioRow] = useState({});
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const {
    threeWayCallEntryPhone = 'xxx-xxx-xxxx',
    threeWayExploreMoreLink,
    threeWayDemoLink,
  } = useSelector(store => store.theme);
  const {
    npn,
    npnLinked,
    npnCallRecording,
    callRecording,
  } = useSelector(store => store.agent);
  const {
    callData = {},
    tempNPN,
    showInstructions,
    sortTerm,
    sortInverse,
  } = useSelector(store => store.threeWay);
  const { Items: callBook = [] } = callData;


  useEffect(() => {
    dispatch({ type: 'CLEAR_THREE_WAY' });
    loadThreeWayCalls().then(config => {
      dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
    });
    // updateAgent({ npn_call_recording: '', call_recording: false }); // can be used for testing
  }, []);

  function handleSort(key, direction) {
    let callBookCopy = [ ...callBook ];
    if (key === 'call_duration' || key === 'shopper_phone') {
      if (direction === 'asc') {
        callBookCopy = callBookCopy.sort((a, b) => {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else if (direction === 'desc') {
        callBookCopy = callBookCopy.sort((a, b) => {
          if (b[key] < a[key]) return -1;
          if (b[key] > a[key]) return 1;
          return 0;
        });
      }
    } else if (key === 'call_start') {
      if (direction === 'asc') {
        callBookCopy = callBookCopy.sort((a, b) => new Date(a[key]) - new Date(b[key]));
      } else if (direction === 'desc') {
        callBookCopy = callBookCopy.sort((a, b) => new Date(b[key]) - new Date(a[key]));
      }
    } else if (direction === 'asc') {
      callBookCopy = callBookCopy.sort((a, b) => a[key].localeCompare(b[key]));
    } else if (direction === 'desc') {
      callBookCopy = callBookCopy.sort((a, b) => b[key].localeCompare(a[key]));
    }
    dispatch({ type: 'SET_CALL_BOOK', payload: callBookCopy });
  }

  function handleAccept() {
    setAcceptLoading(true);
    updateAgent({ npn_call_recording: tempNPN || npn || npnLinked })
      .then((data) => dispatch({ type: 'LOAD_AGENT', payload: data }))
      .catch((err) => {
        console.error(err);
        showPopup('Error', 'The network request to accept the 3-way calling terms failed.', 'error');
      })
      .finally(() => {
        setAcceptLoading(false);
      });
  }

  function handleToggle(checked) {
    setToggleLoading(true);
    updateAgent({ call_recording: checked })
      .then((data) => dispatch({ type: 'LOAD_AGENT', payload: data }))
      .catch((err) => {
        console.error(err);
        showPopup('Error', 'The network request to turn on/off 3-way calling failed.', 'error');
      })
      .finally(() => {
        setToggleLoading(false);
      });
  }

  function handleCallDetails(data) {
    dispatch({ type: 'SHOW_MODAL', payload: 'callDetails' });
    dispatch({ type: 'SET_CALL_DETAILS_DATA', payload: data });
  }

  function handleEdit(event) {
    const { id, value } = event.target;
    const editRowCopy = { ...editRow };
    editRowCopy[id] = value;
    setEditRow(editRowCopy);
  }

  function handleSave() {
    updateThreeWayCall(editRow)
      .then(() => {
        dispatch({ type: 'CLEAR_THREE_WAY' });
        loadThreeWayCalls().then(config => {
          dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
        });
      });
    setEditRow({});
  }

  // cell formatting
  const formatFirstNameCell = (data) => {
    if (data.call_id === editRow.call_id) {
      return (
        <input
          type="text"
          id="shopper_first_name"
          className="form-control"
          value={editRow.shopper_first_name}
          onChange={(e) => handleEdit(e)}
        />
      );
    }
    return data.shopper_first_name;
  };
  const formatLastNameCell = (data) => {
    if (data.call_id === editRow.call_id) {
      return (
        <input
          type="text"
          id="shopper_last_name"
          className="form-control"
          value={editRow.shopper_last_name}
          onChange={(e) => handleEdit(e)}
        />
      );
    }
    return data.shopper_last_name;
  };
  const formatPhoneCell = (data) => {
    if (data.call_id === editRow.call_id) {
      return (
        <input
          type="text"
          id="shopper_phone"
          className="form-control"
          value={editRow.shopper_phone}
          onChange={(e) => handleEdit(e)}
        />
      );
    }
    return formatNumToPhone(data.shopper_phone);
  };
  const formatTimeCell = (time) => `${moment(time).format('M/D/YY')} at ${moment(time).format('h:mma')}`;
  const formatDurationCell = (duration) => moment.utc(duration * 1000).format('HH:mm:ss');
  const formatCallToolsCell = (data) => {
    if (data.call_id === editRow.call_id) {
      return (
        <>
          <button
            type="button"
            className="btn btn-success mx-1"
            onClick={() => handleSave()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-danger mx-1"
            onClick={() => setEditRow({})}
          >
            Cancel
          </button>
        </>
      );
    }
    if (data.call_id === audioRow.call_id) {
      return (
        <div className="d-flex justify-content-center">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls>
            <source src={data.recording_url} />
          </audio>
          <button
            type="button"
            className="btn ms-1"
            onClick={() => setAudioRow({})}
          >
            <FontAwesomeIcon icon={faCircleXmark} color="#d65656" size="xl" />
          </button>
        </div>
      );
    }
    return (
      <>
        <button
          type="button"
          className="btn"
          title="Call details"
          onClick={() => handleCallDetails(data)}
        >
          <FontAwesomeIcon
            icon={faCircleInfo}
            size="lg"
            color="#5b67ba"
          />
        </button>
        <button
          type="button"
          className="btn call-tools"
          title="Play call audio"
          disabled={!data.recording_url}
          onClick={() => setAudioRow(data)}
        >
          <FontAwesomeIcon
            icon={faCirclePlay}
            size="lg"
            color={!data.recording_url ? '#707070' : '#5b67ba'}
          />
        </button>
        <button
          type="button"
          className="btn call-tools"
          title="Download call audio"
          disabled={!data.recording_url || !data.recording_s3_key}
          onClick={() => forceDownload(data.recording_url, data.recording_s3_key)}
        >
          <FontAwesomeIcon
            icon={faDownload}
            size="lg"
            color={!data.recording_url || !data.recording_s3_key ? '#707070' : '#5b67ba'}
          />
        </button>
        <button
          type="button"
          className="btn call-tools"
          title="Download call transcript"
          disabled={!data.transcript_url || !data.transcript_s3_key}
          onClick={() => forceDownload(data.transcript_url, data.transcript_s3_key)}
        >
          <FontAwesomeIcon
            icon={faFileLines}
            size="lg"
            color={!data.transcript_url || !data.transcript_s3_key ? '#707070' : '#5b67ba'}
          />
        </button>
        <button
          type="button"
          className="btn"
          title="Edit client name or phone number"
          onClick={() => setEditRow(data)}
        >
          <FontAwesomeIcon
            icon={faPenCircle}
            size="lg"
            color="#5b67ba"
          />
        </button>
      </>
    );
  };

  const schema = {
    cols: [
      {
        header: 'Client first name',
        passAllData: true,
        sortKey: 'shopper_first_name',
        alphaSortIcon: true,
        formatter: formatFirstNameCell,
      },
      {
        header: 'Client Last name',
        passAllData: true,
        sortKey: 'shopper_last_name',
        alphaSortIcon: true,
        formatter: formatLastNameCell,
      },
      {
        header: 'Client phone number',
        formatter: formatPhoneCell,
        passAllData: true,
        sortKey: 'shopper_phone',
      },
      {
        header: 'Date Time',
        field: 'call_start',
        sortKey: 'call_start',
        formatter: formatTimeCell,
      },
      {
        header: 'Duration',
        formatter: formatDurationCell,
        field: 'call_duration',
        sortKey: 'call_duration',
      },
      {
        header: 'Call Tools',
        passAllData: true,
        formatter: formatCallToolsCell,
      },
    ],
    sortClickCallback: (key, direction) => handleSort(key, direction),
    sortKeySetter: (sk) => dispatch({ type: 'SET_THREE_WAY_SORT_TERM', payload: sk }),
    sortKeyGetter: () => sortTerm,
    sortDirectionSetter: (sd) => dispatch({ type: 'SET_THREE_WAY_SORT_INVERSE', payload: sd }),
    sortDirectionGetter: () => sortInverse,
  };

  return (
    <NavPage
      activeLink="/3-way-calls"
    >
      <div className="row">
        <div className="col ms-3">
          <h3><b>3-way Calling and Recording</b></h3>
        </div>
        <div className="col-auto ms-auto">
          {threeWayExploreMoreLink && (
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => window.open(threeWayExploreMoreLink)}
            >
              Explore more
            </button>
          )}
          {threeWayDemoLink && (
            <button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => window.open(threeWayDemoLink)}
            >
              Video Tutorial
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-start px-4">
        <div className="row">
          <div className="col">
            <Collapse isOpen={!callRecording}>
              <h5>NPN</h5>
              <div className="mb-2">
                {"Your NPN is your PIN. You'll be asked for this PIN when you use the 3-way calling / recording on your phone."}
              </div>
              <NPNDisplay />
              <Collapse isOpen={!npnCallRecording} className="mt-3">
                <h5>Terms of Use</h5>
                <p>
                  I attest by opt-in to the 3-way calling and recording;
                  I agree to make and receive calls, record, and monitor for training, quality control,
                  analytics, and other lawful purposes. I will comply with the legal and regulatory guidelines,
                  including but not limited to CMS Medicare marketing guidelines.
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={(npn && npnLinked ? !tempNPN : null) || acceptLoading}
                  onClick={() => handleAccept()}
                >
                  Accept
                </button>
                {acceptLoading && (
                  <Spinner className="ms-2" size="20" />
                )}
              </Collapse>
              <hr style={{ width: '100%' }} />
            </Collapse>
            <div className="row">
              <div className="col-auto">
                <Switch
                  onColor="#002E47"
                  checked={callRecording}
                  disabled={!npnCallRecording || toggleLoading}
                  onChange={(checked) => handleToggle(checked)}
                />
                <span className="ms-3 fs-4" style={{ verticalAlign: '.5rem' }}>
                  {callRecording ? '3-way calling and recording' : 'Enable 3-way calling and recording using your phone. Anywhere. Anytime.'}
                  {toggleLoading && (
                    <Spinner className="ms-2" size="20" />
                  )}
                </span>
              </div>
              {callRecording && (
                <div className="col-auto ms-auto me-1">
                  <span className="fs-4">{`NPN / PIN: ${npnCallRecording}`}</span>
                </div>
              )}
            </div>
            <hr style={{ width: '97vw' }} />
            <button
              type="button"
              className="btn btn-link"
              style={{ marginLeft: '-1rem' }}
              onClick={() => dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'showInstructions', payload: !showInstructions })}
            >
              <FontAwesomeIcon
                fixedWidth
                icon={faChevronCircleRight}
                rotation={showInstructions ? 90 : null}
                size="lg"
                className="me-1"
                style={{ transition: 'all .3s ease' }}
              />
              Instructions
            </button>
            <Collapse
              isOpen={showInstructions}
              className="col-6 p-1"
              style={{
                border: 'solid 1px var(--bs-darker-grey)',
                backgroundColor: 'var(--bs-bdlight)',
              }}
            >
              <p>
                Use 3-way calling to record your client meeting using your own phone. This solution complies with CMS regulatory guidelines
                and allows you to record calls using our HIPAA and CMS compliant tool.  Calls are recorded in full and saved to your account
                for future retrieval.  You can initiate 3-way call recording before or after you connect with your client in 3 easy steps.
              </p>
              <ol className="mb-0">
                <li><b>Initiate the 3-way call</b></li>
                <ol type="a">
                  <li>If you are already on the phone with your client, put your client on hold and dial {threeWayCallEntryPhone}.</li>
                  <li>You can also begin recording before you call your client. Dial {threeWayCallEntryPhone} to get started.</li>
                </ol>
                <li>
                  <b>Follow the prompt and enter your NPN, your client’s name and your client’s phone number. </b>
                  This information is used so you can retrieve the call recording later.
                </li>
                <li><b>Connect the call</b></li>
                <ol type="a">
                  <li>
                    If you are already on the phone with your client the call, using your 3-way call function on your phone simply “merge” the calls.
                    Once connected to your client, please inform your client that the call is being recorded.
                  </li>
                  <li>
                    If you need to call your client, simply put the 3-way Recording system on hold and dial your client.
                    Once your client is connected, you can “merge” the call. Once connected to your client, please inform your client that the call
                    is being recorded.
                  </li>
                </ol>
              </ol>
              <br />
              <i>
                You are responsible for keeping track of all 3-way call recordings with your clients,
                especially if you intend to capture a voice scope or submit an enrollment.
              </i>
              {/* <hr /> */}
            </Collapse>
            {callRecording && (
              <>
                <h5 className="mt-3">Agent Call Records</h5>
                <TableFilter />
                <Table
                  schema={schema}
                  data={callBook}
                  className="table table-bordered text-center three-way"
                />
                <div className="d-flex justify-content-center">
                  <Pagination />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </NavPage>
  );
}

export default ThreeWay;
