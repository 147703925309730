import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import { createBaseShopper } from '../../../actions/shopper';
import { showPopup } from '../../../utils/popup';

// components
import LoaderButton from '../../common/LoaderButton';

function CreateBaseShopper({ setValue }) {
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector(store => store.shopper);
  const [loading, setLoading] = useState(false);

  const createShopper = () => {
    setLoading(true);
    createBaseShopper({ firstName, lastName })
      .then(({ username }) => {
        dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'username', val: username } });

        // setValue is passed in from form.jsx -> index.jsx -> CreateBaseShopper.jsx
        setValue('firstName', firstName);
        setValue('lastName', lastName);

        setLoading(false);

        showPopup('Success', 'Shopper record created!');
        window.location = `/shopper/${username}`;
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const namesAreValid = () => {
    const re = /^[\w.' ]{1,50}$/;
    if (re.test(firstName) && re.test(lastName)) {
      return true;
    }
    return false;
  };

  return (
    <div className="row mt-3">
      <div className="col-md-4">
        <label htmlFor="telephony-first-name" className="form-label">
          First name
        </label>
        <input
          id="telephony-first-name"
          className="form-control"
          value={firstName}
          onChange={(e) => (
            dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'firstName', val: e.target.value } })
          )}
        />
      </div>
      <div className="col-md-4">
        <label htmlFor="telephony-last-name" className="form-label">
          Last name
        </label>
        <input
          id="telephony-last-name"
          className="form-control"
          value={lastName}
          onChange={(e) => (
            dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'lastName', val: e.target.value } })
          )}
        />
      </div>
      <div className="d-flex col-md-4 align-items-end">
        <LoaderButton
          className="btn btn-primary fw-bold px-3"
          color="primary"
          loading={loading}
          text="Next"
          onClick={createShopper}
          disabled={!namesAreValid()}
        />
      </div>
    </div>
  );
}

export default CreateBaseShopper;
