/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

// Actions
import { loadEnrollment } from '../../../actions/enrollment';

// Components
import PrintableEnrollment from '../../../common/views/PrintableEnrollment';

function ExportEnrollment() {
  const [enrollment, setEnrollment] = useState({ });
  const { agent } = useSelector(store => store);
  const { quotes } = useSelector(store => store.shopper);
  const { quoteID } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [childLoaded, setChildLoaded] = useState(false);
  useEffect(() => {
    if (loaded && childLoaded) {
      window.print();
    }
  }, [loaded, childLoaded]);

  // Scenario 1: coming from /dashboard when the shopper is not loaded in
  // Scenario 2: coming from /quotes when the shopper is already loaded in
  useEffect(() => {
    const selectedQuote = quotes.filter(q => q.quoteID === quoteID)[0];
    if (selectedQuote) {
      setEnrollment(selectedQuote.enrollment);
    } else {
      loadEnrollment({ quoteID })
        .then((data) => {
          setEnrollment(data);
          setLoaded(true);
        });
    }
  }, []);

  function caps(string = '') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Setting filename
  useEffect(() => {
    if (enrollment && Object.keys(enrollment).length) {
      const { confirmation_number, submit_date, fields = {} } = enrollment;
      const { first_name, last_name, plan = {} } = fields;
      const { PlanName } = plan;
      const fullName = `${caps(first_name)} ${caps(last_name)}`;
      const fileName = `Enrollment #${confirmation_number} in ${PlanName} for ${fullName} submitted at ${moment(submit_date).format('YYYY-MM-DD')}`;
      document.title = fileName;
    }
  }, [enrollment]);

  return (
    <div
      className={[
        'w-100 h-100',
        'd-flex justify-content-center',
      ].join(' ')}
    >
      <PrintableEnrollment enrollment={enrollment} agent={agent} setLoaded={setChildLoaded} />
    </div>
  );
}
export default ExportEnrollment;
