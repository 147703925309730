import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function WithTracker({ children, options = {} }) {
  const location = useLocation();
  const trackPage = (page) => {
    ReactGA.set({ page, ...options }, ['tracker1', 'tracker2']);
    ReactGA.pageview(page, ['tracker1', 'tracker2']);
  };
  useEffect(() => trackPage(location.pathname), [location]);

  return children;
}

export default WithTracker;
