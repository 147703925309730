import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../../../constants';
import { handleOnlyNumberZip } from '../../../utils/mixin';
// Actions
import { getPharmacies } from '../../../actions/pharmacy';

function Search() {
  const dispatch = useDispatch();
  const {
    pharmacy: {
      radiusList,
      searchZip,
    },
    shopper: {
      county,
      zipcode,
    },
  } = useSelector(store => store);
  const [ error, setError ] = useState(false);
  const [ selectedRadius, setSelectedRadius ] = useState('');

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_ZIP', payload: zipcode });
  }, []);

  function handleZip(event) {
    const zip = event.target.value;

    dispatch({ type: 'SET_SEARCH_ZIP', payload: zip });

    if (zip.length < 5) {
      setError('Please enter a valid 5 digit zip code');
      return false;
    }
    setError(false);
    dispatch({ type: 'CLEAR_PHARMACY_LIST' });
    dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: AWAIT_STATE });
    getPharmacies(zip, county, selectedRadius)
      .then((res) => {
        dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: SUCCESS_STATE });
        setError(res.length ? false : 'Please enter a valid zip code.');
      })
      .catch(() => {
        dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: FAIL_STATE });
        setError('Please enter a valid zip code.');
      });
    return true;
  }

  function handleRadius(event) {
    const radius = event.target.value;
    setSelectedRadius(radius);
    dispatch({ type: 'CLEAR_PHARMACY_LIST' });
    dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: AWAIT_STATE });
    getPharmacies(searchZip, county, radius)
      .then(() => dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: SUCCESS_STATE }))
      .catch(() => dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: FAIL_STATE }));
  }

  return (
    <div key="form" className="p-3">
      <form className="d-flex align-items-center" onSubmit={(e) => e.preventDefault()}>
        Pharmacies for zip code
        <input
          id="pharmacy-search-input"
          className="form-control mx-3"
          onInput={(e) => handleZip(e)}
          onKeyDown={(e) => handleOnlyNumberZip(e)}
          value={searchZip}
          type="text"
          style={{ width: '75px' }}
        />
        within
        <select
          id="pharmacy-search-select"
          className="form-control mx-3"
          onChange={(e) => handleRadius(e)}
          type="select"
          selected={selectedRadius}
          style={{ width: '100px', cursor: 'pointer' }}
        >
          {radiusList.map((radius) => (
            <option key={radius} value={radius}>
              {radius.toFixed(2)}
            </option>
          ))}
        </select>
        <i className="fa fa-chevron-down me-4" style={{ fontSize: '15px', marginLeft: '-40px', pointerEvents: 'none' }} />
        miles.
      </form>
      <div className="invalid-feedback d-block">{error}</div>
    </div>
  );
}

export default Search;
