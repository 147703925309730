import axios from 'axios';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
// import { default401 } from './auth';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';
// eslint-disable-next-line import/no-cycle
import { getAgent } from './agent';


// Timeout used to track when the current session token will expire.
let sessionTimeout;

/**
 * Refresh the current session for active users. This will keep the session
 * alive for users that are generating Redux actions.
 */
export function refreshSession() {
  const token = localStorage.getItem('SessionToken');

  return axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/refresh/`,
    method: 'post',
    headers: {
      Authorization: `Token ${token}`,
    },
    data: {
      token,
    },
  }).then((res) => {
    if (res.status === 201) {
      // Update the cached session data.
      localStorage.setItem('SessionToken', res.data.token);
      store.dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: new Date() });

      // Calculate the lifespan of the new token.
      const lifespan = moment(res.data.created).add(res.data.lifespan, 's').diff();

      // Log the refresh.
      const hours = (lifespan / 1000 / 60 / 60).toFixed(1);
      // eslint-disable-next-line
      console.info(`[Session Refresh OK] New lifespan set to ${hours} hours.`);

      // Refresh the session timeout.
      window.clearTimeout(sessionTimeout);
      sessionTimeout = setTimeout(() => {
        store.dispatch({ type: 'SHOW_SESSION_EXPIRED_MODAL' });
      }, lifespan);
    }
  });
}

export function checkIfLoggedIn() {
  const token = localStorage.getItem('SessionToken');

  if (!token) {
    // Don't bother hitting the api if no token is set.
    return Promise.reject(new Error('No valid token available.'));
  }

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/check-if-logged-in/`,
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      site: process.env.REACT_APP_HOSTNAME || window.location.hostname,
    },
  });
}

/**
 * Validate the client's version against the current version marker. The version marker
 * is a timestamp generated on every aws codebuild build via the buildspec.
 */
export function validateClientVersion() {
  return new Promise(() => {
    axios({
      url: '/version.json',
      params: { cb: Date.now() }, // Cache-buster.
    }).then(res => {
      if (res.status === 200) {
        const clientVersion = store.getState().session.version;
        const latestVersion = res.data.version;

        if (latestVersion !== clientVersion) {
          store.dispatch({ type: 'SET_MODAL', payload: { staleClientModal: true } });
          store.dispatch({ type: 'UPDATE_LATEST_VERSION', payload: latestVersion });
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(`[Client Validation ERR] ${res.status}: ${res.statusText}`);
      }
    });
  });
}

export function validateAgentLastUpdate() {
  const token = localStorage.getItem('SessionToken');
  const currentState = store.getState();
  const { username } = currentState.agent;
  const { agentLastUpdate } = currentState.session;
  if (username && token) {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/agent/last-update/`,
      headers: { Authorization: `Token ${token}` },
    }).then((data) => {
      /* eslint-disable camelcase */
      const { last_update } = data.data;
      if (last_update !== agentLastUpdate) {
        store.dispatch({ type: 'SET_AGENT_LAST_UPDATE', payload: last_update });
        getAgent();
      }
    }).catch(() => console.log('default401 used to be here...')); // default401);
  }
}
