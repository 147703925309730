import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isRequired, getValidClass, validateField } from '../../utils';

import FormLabel from './FormLabel';
import FieldError from './FieldError';

function Select({
  disabled,
  label,
  name,
  cols = '3',
  handleChange,
  options = [],
  disabledVal,
  customContainerClasses,
  containerMargin,
}) {
  const dispatch = useDispatch();
  const sizeClass = `col-md-${cols}`;
  const value = useSelector(store => store.shopper[name]);

  const { shopper = {} } = useSelector(store => store);
  const { schema = {}, validation = {} } = useSelector(store => store.shopper);
  const required = isRequired({ name, schema, currentValues: shopper });
  const validClass = getValidClass({ name, schema, currentValues: shopper, validation });

  const classes = ['form-control form-select', validClass].join(' ');

  function defaultHandleChange(e, callback) {
    const v = e.target.value;

    if (callback) {
      callback(e);
    } else {
      const val = e.target.value;
      dispatch({ type: 'UPDATE_SHOPPER', payload: { [name]: val } });
    }
    validateField({ name, schema, currentValues: shopper, newValue: v });
  }

  const cm = containerMargin || 'mt-3';

  return (
    <div className={[cm, (customContainerClasses || sizeClass)].join(' ')}>
      <FormLabel name={name} label={label} required={required} />
      <select
        id={`shopper-select-${name}`}
        className={classes}
        name={name}
        disabled={disabled}
        onChange={(e) => defaultHandleChange(e, handleChange)}
        value={disabledVal || value}
        onBlur={() => validateField({ name, schema, currentValues: shopper, newValue: value })}
      >
        {options.map(option => (
          <option
            value={option.value}
            disabled={option.disabled}
            key={option.value}
          >
            {option.desc}
          </option>
        ))}
        {disabledVal && (<option>{disabledVal}</option>)}
      </select>
      <FieldError name={name} />
    </div>
  );
}

export default Select;
