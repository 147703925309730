/* eslint-disable */
import React from 'react';

const LoaderComponent = ({
  text = 'Loading...',
  modal = false,
  backdrop = false,

  // For now, assume all old use of LoaderComponent w/ redux
  // loaders won't fade in/out, and are conditionall shown
  loading = true,

  style = {},
  offsetX = '0px',
  offsetY = '0px',
}) => {
  let containerStyle = {
    marginLeft: offsetX,
    marginTop: offsetY,
    ...style,
  };
  if (modal) {
    containerStyle = {
      zIndex: '1000',
      backgroundColor: 'white',
      width: '360px',
      height: '180px',
      ...containerStyle,
    };
  }

  return (
    <div
      className={`${modal ? 'mmb-modal-custom' : ''} fade ${loading ? '' : 'pe-none'}`}
      style={{ opacity: loading ? 1 : 0 }}
    >
      <div
        className={`loader-component ${modal ? 'shadow-lg' : ''}`}
        id="loader-component"
        style={containerStyle}
      >
        <div
          className="loading-indicator-spinner"
          style={{ height: '36px', width: '36px' }}
        />
        <span className="pt-2">{text}</span>
      </div>
    </div>
  );
};

export default LoaderComponent;
