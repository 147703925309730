const initialState = {
  selectedOSB: {},
  selectedDiscounts: [],
  selectedRiders: [],
  // for quick-enroll
  tab: 'send', // send | history
  method: 'email', // email | sms
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_OSB':
      return { ...state, selectedOSB: action.payload };
    case 'ADD_DISCOUNT':
      return { ...state, selectedDiscounts: [...state.selectedDiscounts, action.payload] };
    case 'ADD_RIDER':
      return { ...state, selectedRiders: [...state.selectedRiders, action.payload] };
    case 'REMOVE_DISCOUNT': {
      const newDiscounts = [...state.selectedDiscounts];

      let indexToDelete;
      newDiscounts.forEach((discount, index) => {
        if (discount.discountName === action.payload.discountName) {
          indexToDelete = index;
        }
      });

      newDiscounts.splice(indexToDelete, 1);
      return { ...state, selectedDiscounts: newDiscounts };
    }
    case 'CLEAR_DISCOUNTS':
      return { ...state, selectedDiscounts: [] };
    case 'REMOVE_RIDER': {
      const newRiders = [...state.selectedRiders];

      let indexToDelete;
      newRiders.forEach((rider, index) => {
        if (rider.riderName === action.payload.riderName) {
          indexToDelete = index;
        }
      });

      newRiders.splice(indexToDelete, 1);
      return { ...state, selectedRiders: newRiders };
    }
    case 'SET_ENROLLMENT_TAB':
      return { ...state, tab: action.payload };
    case 'SET_ENROLLMENT_METHOD':
      return { ...state, method: action.payload };
    default:
      return state;
  }
}
