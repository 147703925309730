import React from 'react';
import { connect } from 'react-redux';
import { getDosages } from '../../../actions/drug';
import { AWAIT_STATE, FAIL_STATE, SUCCESS_STATE } from '../../../constants';

class BrandDrugButton extends React.PureComponent {
  render() {
    const {
      dispatch,
      selectedDrug,
      brandDrug,
      displayBrandDrug,
    } = this.props;

    if (!selectedDrug) {
      return null;
    }

    if (!brandDrug) {
      return <i>No brand available</i>;
    }

    let classes = 'btn-brand-v-generic';

    if (displayBrandDrug) {
      classes = 'btn-brand-v-generic-active';
    }

    return (
      <button
        type="button"
        className={`btn btn-block w-100 ${classes}`}
        onClick={() => {
          dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: AWAIT_STATE });
          dispatch({ type: 'DISPLAY_BRAND_DRUG', payload: true });
          dispatch({ type: 'SET_SELECTED_DRUG', payload: brandDrug });
          getDosages(brandDrug.DrugID)
            .then(() => dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: SUCCESS_STATE }))
            .catch(() => dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: FAIL_STATE }));
        }}
      >
        <div>
          { brandDrug.DrugName || 'Brand Drug' }
        </div>
        <div style={{ marginTop: '-10px' }}>
          <i className="small text-muted">(brand)</i>
        </div>
      </button>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedDrug: store.drug.selectedDrug,
    brandDrug: store.drug.brandDrug,
    displayBrandDrug: store.drug.displayBrandDrug,
  };
}

export default connect(mapStateToProps)(BrandDrugButton);
