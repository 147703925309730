/* eslint-disable import/no-cycle */
import axios from 'axios';

import { fmtHRA, getAllFields, getHRAConfigName } from '../utils/hra';

export function submitHRA(hra) {
  const data = fmtHRA(hra, { post: true, submitted: true });
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'post',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/hra/`,
    data,
  }).then((res) => res.data);
}

export function updateHRA(hra) {
  const token = localStorage.getItem('SessionToken');
  const { quoteID } = hra;
  const data = fmtHRA(hra, { post: true });
  return axios({
    method: 'put',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/hra/${quoteID}/`,
    data,
  }).then((res) => {
    const h = res.data ? fmtHRA(res.data) : null;
    return h;
  }).catch(err => {
    const { response } = err;
    return response.data;
  });
}

export function createHRA(quoteID, form, source = 'internal') {
  const token = localStorage.getItem('SessionToken');
  const fields = getAllFields(form);
  const emptyFields = {};
  fields.forEach(f => {
    emptyFields[f.name] = f.defaultValue;
  });
  return axios({
    method: 'post',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/hra/`,
    data: {
      quote_id: quoteID,
      fields: emptyFields,
      source,
    },
  }).then((res) => {
    const h = res.data ? fmtHRA(res.data) : null;
    return h;
  });
}

export function getHRA(quoteID) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/hra/${quoteID}/`,
  }).then((res) => {
    const h = res.data ? fmtHRA(res.data) : null;
    return h;
  });
}

export function getHRAConfig(carrier) {
  const token = localStorage.getItem('SessionToken');
  const configName = getHRAConfigName(carrier);
  return axios({
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/name/`,
    params: {
      name: configName,
      access: 'public',
      site: 'ALL_SITES_SITE',
    },
  }).then((res) => res.data);
}
