import { store } from '../store';

export default (ciData) => {
  // -------------------------------------------------------------
  // NOTE(santeyio): this is a hack for the demo environment.
  //  it does a search and replace via a config in the theme
  //  on the carrier level CI data. The theme config is expected
  //  to have the following format:
  //
  //  "ci_replace_hack": {
  //    "regex": "<thing to replace; e.g. Anthem>",
  //    "replace_with": "<demo carrier name; e.g. YourHealth>"
  //  }
  // -------------------------------------------------------------

  const ciReplaceHack = store.getState().theme.ci_replace_hack;
  if (ciReplaceHack) {
    const regex = new RegExp(ciReplaceHack.regex, 'gi');
    const replaceWith = ciReplaceHack.replace_with;
    return ciData.map((a) => ({
      ...a,
      company: a.company.replace(regex, replaceWith),
      rows: a.rows.map((b) => (
        b.map((c) => {
          if (typeof c === 'string') {
            return c.replace(regex, replaceWith);
          }
          return c;
        })
      )),
    }));
  }
  return ciData;
};
