/* eslint-disable import/prefer-default-export,import/no-cycle */
import { agentLogout } from './agent';

export const default401 = (error) => {
  if (error.response && error.response.status === 401) {
    agentLogout();
  } else {
    throw error;
  }
};
