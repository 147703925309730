import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

function GuideButton({ className, open }) {
  return (
    <div
      className={`navbar-button navbar-help me-0 ms-auto ${className}`}
      role="button"
      onClick={open}
      tabIndex={0}
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        size="lg"
      />
    </div>
  );
}

const mapDispatchToProps = {
  open: () => ({ type: 'OPEN_GUIDE' }),
};

export default connect(null, mapDispatchToProps)(GuideButton);
