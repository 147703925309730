import React from 'react';
import { connect } from 'react-redux';

class Radius extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { val: 3, desc: '3 miles' },
        { val: 5, desc: '5 miles' },
        { val: 10, desc: '10 miles' },
        { val: 15, desc: '15 miles' },
        { val: 20, desc: '20 miles' },
        { val: 30, desc: '30 miles' },
        { val: 40, desc: '40 miles' },
        { val: 50, desc: '50 miles' },
        { val: 75, desc: '75 miles' },
        { val: 100, desc: '100 miles' },
      ],
    };
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    const val = Number(event.target.value);
    dispatch({ type: 'SET_DOCTORS_RADIUS', payload: val });
  };

  render() {
    const { selectedRadius } = this.props;
    return (
      <div className="d-flex align-items-center">
        within
        <select
          id="doctor-select-radius-input"
          className="form-control ms-3 me-3"
          style={{ width: '125px' }}
          onChange={(e) => this.handleChange(e)}
          value={selectedRadius}
        >
          {this.state.options.map((o) => (
            <option key={o.val} value={o.val}>{o.desc}</option>
          ))}
        </select>
        <i className="fa fa-chevron-down me-3" style={{ fontSize: '15px', marginLeft: '-38px', pointerEvents: 'none' }} />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedRadius: store.doctor.selectedRadius || 15,
  };
}

export default connect(mapStateToProps)(Radius);
