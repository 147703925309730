import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// Utilities
import { showPopup } from '../../utils/popup';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionDescription: '',
      errorSubmitted: false,
      hasError: false,
      otherDetails: '',
      pageDescription: '',
    };
  }

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  handleChange = (event) => {
    const { id, value } = event.target;
    this.setState({ [id]: value });
  };

  handleErrorSubmit = (event) => {
    event.preventDefault();

    const {
      actionDescription,
      otherDetails,
      pageDescription,
    } = this.state;

    if (
      pageDescription === ''
      && actionDescription === ''
      && otherDetails === ''
    ) {
      showPopup('Info', 'Information should be provided to submit error handling.');
    } else {
      this.setState({
        errorSubmitted: true,
      });
    }
  };

  renderErrorForm() {
    const {
      actionDescription,
      errorSubmitted,
      otherDetails,
      pageDescription,
    } = this.state;

    if (errorSubmitted) {
      return (
        <div className="text-center text-muted pt-1">
          Your input has been submitted. Thanks!
        </div>
      );
    }
    return (
      <form>
        <div className="form-group col-10 mx-auto">
          <label className="w-100" htmlFor="pageDescription">
            What page were you on when you encountered this error?
            <input
              className="form-control mt-2"
              id="pageDescription"
              onChange={this.handleChange}
              placeholder="Page description"
              type="text"
              value={pageDescription}
            />
          </label>
        </div>
        <div className="form-group col-10 mx-auto pt-4">
          <label className="w-100" htmlFor="actionDescription">
            What action did you try to take when you encountered this error?
            (Did you try to save a drug, go to the plan details page, etc.)
            <input
              className="form-control mt-2"
              id="actionDescription"
              onChange={this.handleChange}
              placeholder="Action description"
              type="text"
              value={actionDescription}
            />
          </label>
        </div>
        <div className="form-group col-10 mx-auto pt-4">
          <label className="w-100" htmlFor="otherDetails">
            Any other details you think are important
            <textarea
              className="form-control mt-2"
              id="otherDetails"
              onChange={this.handleChange}
              placeholder="Other details"
              value={otherDetails}
            />
          </label>
        </div>
        <button
          className="btn btn-primary mb-3 fw-bold mt-3"
          onClick={this.handleErrorSubmit}
          type="button"
          style={{ width: '125px' }}
        >
          Submit
        </button>
      </form>
    );
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      hasError,
    } = this.state;

    if (hasError) {
      return (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-8 text-center">
              <div className="h6 text-uppercase text-muted mt-4">
                Error
              </div>

              <div className="h2 display-5">
                Something went wrong
                <br />
                <span role="img" aria-label="sad emoji">😭</span>
              </div>

              <p className="text-muted col-10 mx-auto">
                Please try refreshing the page. If you continue to get this error,
                try following the link below to your dashboard or logging in again.
              </p>
              <div>
                <a
                  className="btn btn-primary me-4 fw-bold"
                  href="/dashboard"
                  type="button"
                  style={{ width: '125px' }}
                >
                  Dashboard
                </a>
                <a
                  className="btn btn-primary fw-bold"
                  href="/"
                  type="button"
                  style={{ width: '125px' }}
                >
                  Login
                </a>
              </div>
              <hr />
              {this.renderErrorForm()}
            </div>
          </div>

          <ToastContainer
            position="top-center"
            autoClose={2800}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover={false}
          />
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  error: PropTypes.shape({
    error_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  /* eslint-disable react/forbid-prop-types */
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(store) {
  return {
    error: store.error,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary);
