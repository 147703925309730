/* eslint-disable no-unused-vars */
import { DateTime } from 'luxon';
import Papa from 'papaparse';
import {
  SHOPPER_CSV_SCHEMA,
  DRUG_CSV_SCHEMA,
  PROVIDER_CSV_SCHEMA,
  ENROLLMENT_CSV_SCHEMA,
} from './csvSchemas';
import { SUBSIDY_OPTIONS_DEFAULT } from '../../constants';


function jsonToCSV(data) {
  const encodedData = Papa.unparse(data, {
    quotes: true, // quote every cell
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ',',
    header: true,
    newline: '\r\n',
  });
  return encodedData;
}

function formatValue(value, format, leadStatusOptions, raceOptions, ethnicityOptions) {
  if (format === 'str') {
    return String((value || ''));
  } else if (format === 'str.caps') {
    return String((value || '').toUpperCase());
  } else if (format === 'int') {
    return Number(value);
  } else if (format === 'date') {
    const d = DateTime.fromISO(value);
    if (d.isValid) return d.toLocaleString(DateTime.DATE_SHORT);
    return '';
  } else if (format === 'notes') {
    const concat = (value || []).map(n => (
      `${DateTime.fromISO(n.created).toLocaleString(DateTime.DATETIME_SHORT)} ${n.note_type || ''} "${n.text}"`
    )).join(' | ');
    return concat;
  } else if (format === 'subsidy') {
    const options = {};
    SUBSIDY_OPTIONS_DEFAULT['2024'].forEach(opt => {
      options[opt.value] = opt.desc;
    });
    if (value) return options[value];
    return '';
  } else if (format === 'lead_status') {
    const status = (leadStatusOptions || []).find(o => o.value === value);
    if (status) return status.description;
    return '';
  } else if (format === 'lead_id_from_quote_id') {
    const s = value.split('.');
    return s[0];
  } else if (format === 'specialties') {
    return (value || []).join(', ');
  } else if (format === 'race') {
    const output = [];
    let values = (value || []);
    if (!(value instanceof Array) && (value instanceof Object)) {
      values = Object.entries(value)
        .filter(e => e[1])
        .map(e => e[0]);
    }
    values.forEach(v => {
      const { label = '' } = (raceOptions.find(o => String(o.value) === String(v)) || {});
      output.push(label);
    });
    return output.join(', ');
  } else if (format === 'ethnicity') {
    const output = [];
    let values = (value || []);
    if (!(value instanceof Array) && (value instanceof Object)) {
      values = Object.entries(value)
        .filter(e => e[1])
        .map(e => e[0]);
    }
    values.forEach(v => {
      const { label = '' } = (ethnicityOptions.find(o => String(o.value) === String(v)) || {});
      output.push(label);
    });
    return output.join(', ');
  }
  return '';
}

function getValue(path, obj) {
  const chunks = path.split('.');
  if (chunks.length === 1) {
    const val = obj[path];
    return val;
  } else if (chunks.length === 2 && chunks[0] === 'pharmacy') {
    if ((obj.pharmacy || []).length === 1) {
      return obj.pharmacy[0][chunks[1]];
    }
    return '';
  } else if (chunks.length === 2 && chunks[0] === 'shopper') {
    if ((obj.shopper || {})[chunks[1]]) {
      return obj.shopper[chunks[1]];
    }
    return '';
  } else if (chunks.length === 3 && chunks[1] === 'quote') {
    if ((obj.fields.quote || {})[chunks[2]]) {
      return obj.fields.quote[chunks[2]];
    }
    return '';
  } else if (chunks.length === 2 && chunks[0] === 'fields') {
    if ((obj.fields || {})[chunks[1]]) {
      return obj.fields[chunks[1]];
    }
    return '';
  }
  return '';
}

function toCSV({
  items,
  schema,
  leadStatusOptions,
  raceOptions,
  ethnicityOptions,
}) {
  const output = [];
  // add header row
  output.push(schema.map(r => r.header));

  // format data into rows
  items.forEach(obj => {
    const row = schema.map((o) => {
      const value = getValue(o.path, obj);
      return formatValue(value, o.format, leadStatusOptions, raceOptions, ethnicityOptions);
    });
    output.push(row);
  });

  return output;
}

function formatShopperCSV(shoppers, leadStatusOptions) {
  return toCSV({
    items: shoppers,
    schema: SHOPPER_CSV_SCHEMA,
    leadStatusOptions,
  });
}

function formatDrugCSV(drugs) {
  return toCSV({
    items: drugs,
    schema: DRUG_CSV_SCHEMA,
  });
}

function formatProviderCSV(providers) {
  return toCSV({
    items: providers,
    schema: PROVIDER_CSV_SCHEMA,
  });
}

function formatEnrollmentCSV(enrollments, leadStatusOptions, raceOptions, ethnicityOptions) {
  return toCSV({
    items: enrollments,
    schema: ENROLLMENT_CSV_SCHEMA,
    leadStatusOptions,
    raceOptions,
    ethnicityOptions,
  });
}

export {
  jsonToCSV,
  formatShopperCSV,
  formatDrugCSV,
  formatProviderCSV,
  formatEnrollmentCSV,
};
