import React from 'react';
import { useSelector } from 'react-redux';


// Components
import SelectButton from './SelectButton';

function SearchTable() {
  const {
    shopper: { pharmacyList = [] },
    pharmacy: {
      selectedPharmacy,
      pharmacySearchList = [],
    },
  } = useSelector(store => store);

  const text = (
    <>
      <tr className="pharmacy-list-table-row selected">
        {pharmacyList.length ? (
          <>
            <td className="ps-4">{pharmacyList[0].name}</td>
            <td>{pharmacyList[0].address1}</td>
            <td>{pharmacyList[0].city}, {pharmacyList[0].state}</td>
            <td>
              <div style={{ marginRight: '1.5rem', textAlign: 'right' }}><b>Selected Pharmacy</b></div>
            </td>
          </>
        ) : (
          <>
            <td className="ps-4">
              <i><b>No pharmacy selected</b></i>
            </td>
            <td />
            <td />
            <td />
          </>
        )}
      </tr>
      <tr className="table-divider">
        <td />
      </tr>
    </>
  );

  return (
    <table className="table pharmacy-list-table">
      <thead>
        <tr>
          <th className="ps-4">Pharmacy name</th>
          <th>Address</th>
          <th>City, state</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {text}
        {pharmacySearchList && pharmacySearchList.length > 0
          ? (
            <>
              <tr className="pharmacy-list-table-row">
                <td className="ps-4">MAIL ORDER PHARMACY</td>
                <td />
                <td />
                <td>
                  <SelectButton
                    pharmacy={{
                      npi: 99999999,
                      name: 'MAIL ORDER',
                      address1: 'AUTO',
                      city: 'AUTO',
                      state: 'AUTO',
                      zip: 'AUTO',
                    }}
                    selected={selectedPharmacy === 99999999}
                  />
                </td>
              </tr>
              <tr className="table-divider">
                <td />
              </tr>
              {
                pharmacySearchList.map((pharmacy) => {
                  let selected = false;
                  pharmacyList.forEach((p) => {
                    if (p.npi === pharmacy.npi) {
                      selected = true;
                    }
                  });
                  return (
                    <tr className={`pharmacy-list-table-row ${selected ? 'selected' : ''}`} key={pharmacy.npi}>
                      <td className="ps-4">{pharmacy.name}</td>
                      <td>{pharmacy.address1}</td>
                      <td>{pharmacy.city}, {pharmacy.state}</td>
                      <td>
                        <SelectButton pharmacy={pharmacy} selected={selected} />
                      </td>
                    </tr>
                  );
                })
              }
            </>
          )
          : (
            <tr style={{ height: '200px', userSelect: 'none' }}>
              <td colSpan="12" className="text-center align-middle"><i>Looks like there are no pharmacies in the area.</i></td>
            </tr>
          )}
      </tbody>
    </table>
  );
}

export default SearchTable;
