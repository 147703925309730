import React from 'react';
import { connect } from 'react-redux';

class NavPopover extends React.PureComponent {
  componentDidMount() {
    const { dispatch, popoverName } = this.props;
    dispatch({ type: 'HIDE_POPOVER', payload: popoverName });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    const {
      dispatch,
      popoverName,
      parentRef,
    } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (parentRef && !parentRef.contains(event.target)) {
        dispatch({ type: 'HIDE_POPOVER', payload: popoverName });
      }
    }
  };

  render() {
    const {
      children,
      show,
      style,
    } = this.props;

    if (show) {
      return (
        <div
          className="navbar-drug-popover"
          style={{
            width: '350px',
            right: '0px',
            top: '50px',
            padding: '1rem',
            ...style,
          }}
          ref={this.setWrapperRef}
        >
          {children}
        </div>
      );
    }
    return null;
  }
}

NavPopover.defaultProps = {
  show: true,
  style: {},
  popoverName: '',
  parentRef: null,
};

function mapStateToProps(store) {
  return {
    somevar: store.somevar,
  };
}

export default connect(mapStateToProps)(NavPopover);
