
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';

// Components
import SavedQuote from '../components/SavedQuote';
import LoaderComponent from '../../common/LoaderComponent';
import LoaderButton from '../../common/LoaderButton';
import FormRadio from '../../common/FormRadio';
import Alert from '../../common/Alert';

import SendEmail from '../../soa/send/Email';
import SendSMS from '../../soa/send/SMS';

// Actions + Utils
import { sendQuote } from '../../../actions/quote';
import { loadConfig } from '../../../actions/config';
import { cap } from '../../../common/utils/prettify';
import { showPopup } from '../../../utils/popup';
import { getTPMODisclaimerText } from '../../../common/utils/tpmo';
import { isFieldValid } from '../../soa/send/VoiceSOA';

function ShareQuotePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    agent,
    shopper,
  } = useSelector(store => store);
  const {
    agency: agentAgency,
    carrierPermissions,
  } = agent;
  const {
    county: countyFips,
    dob,
    effectiveDate,
    email,
    firstName,
    lastName,
    mobilePhone,
    homePhone,
    username,
    quotes,
  } = shopper;
  const {
    errors = {},
    shareQuoteID,
    values = {},
  } = useSelector(store => store.quote);
  const agentName = `${agent.firstName} ${agent.lastName}`;
  const [emailText, setEmailText] = useState({});
  const [smsText, setSmsText] = useState('');
  const [tpmoDisclaimerText, setTpmoDisclaimerText] = useState('');

  const [loading, setLoading] = useState(false);
  const [opening, setOpening] = useState(false);
  const [quote, setQuote] = useState({});

  useEffect(() => {
    setQuote(quotes.filter((q) => q.quoteID === shareQuoteID)[0]);
    const planYear = moment(effectiveDate).year();
    getTPMODisclaimerText({ carrierPermissions, planYear, countyFips })
      .then(text => setTpmoDisclaimerText(text));
  }, [quotes]);

  useEffect(() => {
    // UX so the content doesn't flash onto screen; hopefully indicates you have not shared a quote yet

    setTimeout(() => {
      setOpening(false);
    }, 250);

    dispatch({ type: 'CLEAR_QUOTE_ERRORS' });
    dispatch({
      type: 'SET_QUOTE_VALUES',
      payload: {
        method: 'email', // email | sms
        send_email: email,
        send_greeting: `Hello ${cap(firstName)}${lastName ? ` ${cap(lastName)}` : ''},`,
        send_closing: 'Thank you,',
        send_signature: agentName ? `${cap(agentName)}` : '',
        send_sms_phone: mobilePhone || homePhone,
        send_sms_greeting: `Hi ${cap(firstName)}, this is ${cap(agentName)} from ${cap(agentAgency.name)}.`,
      },
    });

    loadConfig('pinpoint_quote_email_text_json').then(({ data }) => setEmailText(data));
    loadConfig('pinpoint_quote_sms_text_json').then(({ data }) => setSmsText(data));
  }, []);

  function isValid(field, value) {
    if (!value) {
      dispatch({ type: 'SET_QUOTE_ERROR', field, payload: 'This field is required' });
      return false;
    }
    if (field === 'send_email'
      && !isFieldValid({
        // eslint-disable-next-line no-useless-escape
        validationRegex: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        value,
      })
    ) {
      dispatch({ type: 'SET_QUOTE_ERROR', field, payload: 'Please enter a valid email address' });
      return false;
    }
    if (field === 'send_sms_phone'
      && !isFieldValid({ validationRegex: '^[0-9]{10}$', value })
    ) {
      dispatch({ type: 'SET_QUOTE_ERROR', field, payload: 'Please enter a valid phone number' });
      return false;
    }
    dispatch({ type: 'SET_QUOTE_ERROR', field, payload: '' });
    return true;
  }

  function handleChange({ target: { name, value } }) {
    dispatch({ type: 'SET_QUOTE_VALUE', field: name, payload: value });
    isValid(name, value);
  }

  function validToSubmit() {
    if (values.method === 'email') {
      const emailValid = isValid('send_email', values.send_email);
      const emailGreetValid = isValid('send_greeting', values.send_greeting);
      const closingValid = isValid('send_closing', values.send_closing);
      const signatureValid = isValid('send_signature', values.send_signature);
      if (!emailValid || !emailGreetValid || !closingValid || !signatureValid) return false;
    } else if (values.method === 'sms') {
      const phoneValid = isValid('send_sms_phone', values.send_sms_phone);
      const smsGreetValid = isValid('send_sms_greeting', values.send_sms_greeting);
      if (!phoneValid || !smsGreetValid) return false;
    }
    return true;
  }

  function handleSubmit() {
    if (!validToSubmit()) return;
    setLoading(true);
    sendQuote(values.method, {
      email: values.method === 'email' ? values.send_email : undefined,
      greeting: values.method === 'email' ? values.send_greeting : values.send_sms_greeting,
      closing: values.send_closing,
      signature: values.send_signature,
      quote_id: shareQuoteID,
      shopper_username: username,
      phone: values.method === 'sms' ? values.send_sms_phone : undefined,
      tpmo_disclaimer: (values.method === 'email') ? tpmoDisclaimerText : undefined,
    })
      .then(() => {
        setLoading(false);
        navigate('/quotes');
        setTimeout(() => { showPopup('Success', 'Quote successfully sent!'); }, 100);
      })
      .catch(() => {
        showPopup('Error', 'There was a problem sharing the quote.');
      }).finally(() => {
        setLoading(false);
      });
  }

  const canQuote = dob && (homePhone || mobilePhone);

  return (
    <NavPage activeLink="/plans" showShopperInfobar>
      <div className="mmb-container" id="enrollment">
        <div className="mmb-header d-flex align-items-center justify-content-between" style={{ height: '38px' }}>
          Share Quote
        </div>
      </div>
      <div className="mmb-content enroll-quotes-container">
        {!quote
          ? <LoaderComponent />
          : <SavedQuote quote={quote} key={quote.quoteID} sharing />}
        {!canQuote
          ? (
            <Alert>
              <div className="d-flex flex-column">
                {'Phone and Date of birth are required when sharing a quote with a client. They will use this information to verify their identity.'}
                <div
                  className="text-link"
                  role="button"
                  onClick={() => navigate(`/shopper/${username}`)}
                  tabIndex={0}
                >
                  You can add that information on the Client info page.
                </div>
              </div>
            </Alert>
          )
          : null}
        {/* Three columns (Delivery method, inputs, preview */}
        <div
          className="d-flex overflow-hidden t-open"
          style={{
            maxHeight: opening ? '0px' : '1000px',
            // eslint-disable-next-line no-nested-ternary
            opacity: opening ? '0' : canQuote ? '1' : '0.2',
            transition: 'all 1.5s ease',
          }}
        >
          {/* Delivery method */}
          <div className="d-flex flex-column ps-3" style={{ whiteSpace: 'nowrap' }}>
            <FormRadio
              controlId="method_email"
              label="Email"
              name="shareQuote"
              onChange={() => dispatch({ type: 'SET_QUOTE_VALUE', field: 'method', payload: 'email' })}
              value={values.method === 'email'}
            />
            <FormRadio
              controlId="method_sms"
              label="Text (SMS)"
              name="shareQuote"
              onChange={() => dispatch({ type: 'SET_QUOTE_VALUE', field: 'method', payload: 'sms' })}
              value={values.method === 'sms'}
            />
          </div>
          {/* Inputs */}
          <div className="d-flex flex-column ms-5" style={{ whiteSpace: 'nowrap' }}>
            {
                values.method === 'email'
                  ? (
                    <SendEmail
                      handleChange={(e) => handleChange(e)}
                      errors={errors}
                      values={values}
                    />
                  )
                  : (
                    <SendSMS
                      handleChange={(e) => handleChange(e)}
                      errors={errors}
                      values={values}
                    />
                  )
              }
            <LoaderButton
              className="fw-bold mt-3 mb-1"
              style={{ maxWidth: '120px' }}
              loading={loading}
              loadingText="Sending..."
              onClick={() => handleSubmit()}
              text="Send"
              disabled={!dob || (!homePhone && !mobilePhone)}
            />
            {!dob
              ? (
                <div className="fs-6 text-danger user-select-none" alt="Necessary for verification purposes.">
                  Please fill out date of birth.
                </div>
              ) : null}
            {!homePhone && !mobilePhone
              ? (
                <div className="fs-6 text-danger user-select-none" alt="Necessary for verification purposes.">
                  Please fill out a phone number.
                </div>
              ) : null}
          </div>
          <div className="d-flex flex-column ms-5">
            <div className="mb-3 fw-bold">Preview {values.method === 'sms' ? 'text' : 'email'}:</div>
            <div>
              {
              values.method === 'email' && (
                <div style={{ maxWidth: '700px', wordBreak: 'break-word' }}>
                  <p><span className="greeting-color">{values.send_greeting} </span></p>
                  <p>{emailText.block_1} {emailText.block_2} {emailText.block_3}</p>
                  <p className="text-blue text-decoration-underline">{emailText.block_4}</p>
                  <p>{emailText.block_5}</p>
                  <p>{emailText.block_6} <span className="font-monospace fs-6 fw-bold"> {'<code>'}</span></p>
                  <p>{emailText.block_7}</p>
                  <p>
                    <span className="closing-color">{values.send_closing}</span><br />
                    <span className="signature-color">{values.send_signature}</span><br />
                    <span>{agentAgency.name}</span>
                  </p>
                  <p>{emailText.material_id}</p>
                  <p>{emailText.block_9}</p>
                  <p className="text-blue text-decoration-underline">{emailText.block_10}</p>
                  <p className="fst-italic">{tpmoDisclaimerText}</p>
                </div>
              )
            }
              {
              values.method !== 'email' && (
                <div>
                  <p style={{ maxWidth: '300px', wordBreak: 'break-word' }}>
                    <span className="greeting-color">{values.send_sms_greeting}</span>
                    <span> {smsText.block_1} <span className="text-blue text-decoration-underline">{'<link>'}</span></span><br /><br />
                    <span>{smsText.block_2} <span className="font-monospace fs-6 fw-bold"> {'<code>'}</span></span><br />
                    <span>{smsText.material_id}</span>
                  </p>
                </div>
              )
            }
            </div>
          </div>
        </div>
      </div>
      <FooterNav
        backOnClick={() => navigate('/quotes')}
        backButtonText="Back to quotes"
        noPlanLink
        noNextLink
      />
    </NavPage>
  );
}

export default ShareQuotePage;
