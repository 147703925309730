import moment from 'moment';

import { getLongestString } from '../general';

// eslint-disable-next-line
export default ({ carrier, quote, fields, shopper }) => {
  const { enrollment } = quote;
  const eFields = enrollment.fields;

  function padCPS(cpsID) {
    const res = cpsID.split('-');
    res[2] = res[2].padStart(3, '0');
    return res.join('-');
  }

  // e.g. turn field 1 -> 5.33, '' -> ''
  function mapField(fieldKey, map, onNone = '') {
    return map[fieldKey] || onNone;
  }

  function ynb(field) {
    return mapField(field, { true: '1', false: '2', '': '', undefined: '' });
  }

  function formatTransport(field) {
    if (field) return '1';
    return '';
  }

  function formatMeetType(type) {
    if (type === 'telephonic' || type === 'telephonicThreeWay') {
      return 'Telephonic';
    } else if (type === 'inPerson' || !type) {
      return 'Face to Face Community';
    }
    return 'ERROR';
  }

  function calculateHelpTotal() {
    /*
     * "I don’t need help" option should not appear on the assessment, but answer still needs to be recorded
     * Q24_10A-Q24_10I if member answers ‘yes’ to any then Q24_10J should = 2
     * Q24_10A-Q24_10I If all blank then Q24_10J = blank
     */
    /* eslint-disable no-multi-spaces */
    const answers = [
      fields.help_bath,     // HA042
      fields.help_eat,      // HA043
      fields.help_dress,    // HA044
      fields.help_groom,    // HA045
      fields.help_finance,  // HA046
      fields.help_move,     // HA047
      fields.help_meds,     // HA048
      fields.help_transfer, // HA049
      fields.help_restroom, // HA050
    ];
    if (answers.includes(true)) return '2';
    if (answers.includes(false)) return '1';
    return '';
  }

  // Sorted in order as viewed on the frontend, then sorted by HA001 -> HA048
  let fmt = [
    // UHC manually-set fields
    { col: 'HA001', val: eFields?.medicare_id || '' },
    { col: 'HA002', val: eFields?.last_name?.replaceAll('-', ' ').replace(/[^a-zA-Z ]/gi, '') },
    { col: 'HA003', val: eFields?.first_name?.replaceAll('-', ' ').replace(/[^a-zA-Z ]/gi, '') },
    { col: 'HA004', val: '' },
    { col: 'HA005', val: eFields?.gender === 'male' ? 'M' : 'F' },
    { col: 'HA006', val: eFields?.mobile_phone || getLongestString([shopper.mobilePhone, shopper.homePhone]) },
    { col: 'HA007', val: eFields?.birthdate ? moment(eFields.birthdate).format('YYYYMMDD') : '' },
    { col: 'HA008', val: padCPS(quote.cpsID) },
    { col: 'HA009', val: moment().format('YYYYMMDD') },
    { col: 'HA010', val: formatMeetType(eFields.meetType) },
    { col: 'HA011', val: eFields?.ispoa ? '2' : '1' },

    // Form fields (user true/false value or left as 'Choose not to answer', '')
    { col: 'HA012', val: fields.armed_forces || '' },
    { col: 'HA013', val: ynb(fields.has_copd) },
    { col: 'HA014', val: ynb(fields.has_cancer) },
    { col: 'HA015', val: ynb(fields.has_diabetes) },
    { col: 'HA016', val: ynb(fields.has_kidney_failure) },
    { col: 'HA017', val: ynb(fields.has_heart_misc) },
    { col: 'HA018', val: ynb(fields.has_heart_failure) },
    { col: 'HA019', val: ynb(fields.has_hbp) },
    { col: 'HA020', val: ynb(fields.has_mental) },
    { col: 'HA021', val: ynb(fields.has_obesity) },
    { col: 'HA022', val: ynb(fields.has_stroke) },
    { col: 'HA023', val: ynb(fields.has_other) },
    { col: 'HA024', val: '' },
    { col: 'HA025', val: fields.lack_of_food },
    { col: 'HA026', val: formatTransport(fields.transport_yes) },
    { col: 'HA027', val: formatTransport(fields.transport_yes_2) },
    { col: 'HA028', val: formatTransport(fields.transport_no) },
    { col: 'HA029', val: formatTransport(fields.transport_no_answer) },
    { col: 'HA030', val: fields.living_situation },
    { col: 'HA031', val: fields.no_clothing },
    { col: 'HA032', val: fields.no_employment },
    { col: 'HA033', val: fields.no_internet },
    { col: 'HA034', val: fields.no_medicine },
    { col: 'HA035', val: fields.no_phone },
    { col: 'HA036', val: fields.no_social },
    { col: 'HA037', val: fields.no_utilities },
    { col: 'HA038', val: '' },
    { col: 'HA039', val: fields.tech_capable },
    { col: 'HA040', val: fields.drug_count || '' },
    { col: 'HA041', val: fields.hospital || '' },
    { col: 'HA042', val: ynb(fields.help_bath) },
    { col: 'HA043', val: ynb(fields.help_eat) },
    { col: 'HA044', val: ynb(fields.help_dress) },
    { col: 'HA045', val: ynb(fields.help_groom) },
    { col: 'HA046', val: ynb(fields.help_finance) },
    { col: 'HA047', val: ynb(fields.help_move) },
    { col: 'HA048', val: ynb(fields.help_meds) },
    { col: 'HA049', val: ynb(fields.help_transfer) },
    { col: 'HA050', val: ynb(fields.help_restroom) },
    // This value is automatically set to TRUE/1 if they select all NO above. Default as ''.
    { col: 'HA051', val: calculateHelpTotal() },
    { col: 'HA052', val: fields.satisfied_help || '' },
    { col: 'HA053', val: fields.spouse_helps },
    { col: 'HA054', val: fields.guard_helps },
    { col: 'HA055', val: fields.family_helps },
    { col: 'HA056', val: fields.friend_helps },
    { col: 'HA057', val: fields.caregiver_helps },
    { col: 'HA058', val: fields.other_helps },
    { col: 'HA059', val: fields.dont_need_help },
    { col: 'HA060', val: ynb(fields.provide_care) },
    { col: 'HA061', val: ynb(fields.falls) },
    // Below maps UHC "scores", but that is done on their end with our response
    // { col: 'HA021', val: mapField(fields.hospital, { '1':'0', '2':'2.066', '3':'4.197', '4':'5.132' }) },
    { col: 'HA062', val: fields.pain_scale || '' },
    { col: 'HA063', val: ynb(fields.memory) },
    { col: 'HA064', val: fields.disinterest || '' },
    { col: 'HA065', val: fields.depressed || '' },
    { col: 'HA066', val: ynb(fields.spiritual) },
    { col: 'HA067', val: fields.rec_drugs || '' },
    { col: 'HA068', val: enrollment.confirmation_number || '' },
    { col: 'HA069', val: moment().format('YYYY-MM-DD HH:mm:ss') },

  ];
  fmt = fmt.sort((c1, c2) => c1.col.localeCompare(c2.col));
  return fmt;
};
