import React from 'react';

const FormSelect = ({
  controlId,
  className,
  name,
  label,
  options = [],
  optionValueKey = 'val',
  optionLabelKey = 'label',
  singleOption = false,
  placeholder,
  placeholderSelectable = false,
  hideLabel,
  required,
  invalid,
  disabled = false,
  onChange,
  onBlur,
  value,
  error,
  loading = false,
}) => {
  const classNameWithIconStatus = (className || '') + (singleOption || loading ? ' hide-icon' : '');

  const selectProps = {
    name,
    id: controlId,
    value,
  };
  if (disabled) selectProps.disabled = true;

  return (
    <div className={`mb-3 ${classNameWithIconStatus}`}>
      {label
        ? (
          <label htmlFor={controlId} className="form-label" hidden={hideLabel}>{label}
            {required && <span className="text-danger fw-bold ps-1">*</span>}
          </label>
        ) : null}
      {loading
        ? (
          <div className="form-select disabled loading" role="form" disabled>
            {loading}
          </div>
        )
        : (
          <select
            {...selectProps}
            className={`form-select ${invalid ? ' is-invalid' : ''}`}
            onChange={onChange}
            onBlur={onBlur}
          >
            {placeholder && <option value="" disabled={!placeholderSelectable}>{placeholder}</option>}
            {options.map((option) => (
              <option key={option[optionValueKey]} value={option[optionValueKey]} disabled={option.disabled}>
                {option[optionLabelKey]}
              </option>
            ))}
          </select>
        )}
      {invalid && error && <div className="d-block invalid-feedback">{error}</div>}
    </div>
  );
};
export default FormSelect;
