import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import Doctors from '../../list/plancard/Doctors';
import { StarRating } from '../../../../common/components';

// Utilities
import {
  fmtField,
  calculateProviderCoverage,
} from '../../../../utils/general';

class DetailTableRow extends React.PureComponent {
  render() {
    const {
      doctorsFeature,
      doctorList,
      navigate,
      plan,
      selectedPharmacy,
      shopper,
      tableRowClassName,
    } = this.props;

    const {
      append,
      format,
      key,
      name,
      options,
      onUndefined,
      pharmacyDependent,
      prepend,
    } = this.props.data;

    const planDetailValue = plan.plan[key];

    let formattedText = fmtField(planDetailValue, format, {
      boolOptions: options,
      prepend,
      append,
      onUndefined,
    });

    let formattedClassName = '';
    let formattedHoverText = formattedText.props.children[1];
    let disableRow = false;

    // Our formattedText is FontAwesome element and we need a descriptive hover
    if (format === 'bool') {
      formattedHoverText = planDetailValue ? 'Available' : 'Not available';
    }

    // Default case for hovering
    if (planDetailValue) {
      if (typeof formattedText === 'string') {
        formattedHoverText = `${name}: ${formattedText}${append || ''}`;
      }
    }

    // Append 'PPO/HMO/...' to Plan Type row
    if (key === 'PlanTypeDesc' && plan.plan.MedicalPlanTypeDesc) {
      formattedText = `${plan.plan.PlanTypeDesc} - ${plan.plan.MedicalPlanTypeDesc}`;
      formattedHoverText = `Plan type: ${formattedText}`;
    }

    if (key === 'DoctorsCovered') {
      const asString = true;
      formattedHoverText = `Doctors covered: ${calculateProviderCoverage(doctorList, plan.cpsID, asString)}`;
      formattedText = (
        <Doctors
          plan={plan}
          showFullText={false}
          showHighlightBorder={false}
        />
      );
      if (plan.planType === 'PDP') {
        formattedText = fmtField(false, 'bool');
        formattedHoverText = 'Not available';
      }
      if (!doctorsFeature) {
        disableRow = true;
      }
    }

    if (key === 'MedicalCoverage') {
      const hasMedicalCoverage = plan.plan.HasMedicalCoverage && plan.planType !== 'PDP';
      formattedText = fmtField(hasMedicalCoverage, 'bool');
      formattedHoverText = hasMedicalCoverage ? 'Available' : 'Not available';
    }

    if (key === 'EstimatedAnnualRXOOPC' || key === 'EstimatedAnnualCost') {
      // if (notHumanaPlan(plan.plan)) {
      //   formattedText = notHumanaPlan(plan.plan);
      //   formattedHoverText = planDetailValue ? `${name}: ${formattedText === 'string' ? formattedText : planDetailValue}` : 'Not available';
      // }
    }

    if (pharmacyDependent) {
      const selectedMedications = shopper.drugList && shopper.drugList.length > 0;
      formattedText = (
        <span
          className="mmb-link fw-bold"
          onClick={() => navigate('/rx/add/pharmacy')}
          role="button"
          tabIndex="0"
        >
          Select a pharmacy
        </span>
      );
      formattedHoverText = 'Select a pharmacy';
      if (selectedPharmacy && !selectedMedications) {
        formattedText = (
          <span
            className="mmb-link fw-bold"
            onClick={() => navigate('/rx/add/drug')}
            role="button"
            tabIndex="0"
          >
            Add medications
          </span>
        );
        formattedHoverText = 'Add medications';
      }
      if (selectedPharmacy && selectedMedications) {
        if (key === 'PharmacyNetworkDesc' && plan.plan.PricingPharmacies) {
          Object.keys(plan.plan.PricingPharmacies).forEach((pharmacyKey) => {
            const pharmacy = plan.plan.PricingPharmacies[pharmacyKey];
            if (pharmacy.NPI === `${selectedPharmacy}`) {
              formattedText = pharmacy.PharmacyNetworkDesc;
              formattedHoverText = pharmacy.PharmacyNetworkDesc;
            } else if (pharmacy.IsMailOrder && selectedPharmacy === 99999999) {
              formattedText = `${pharmacy.PharmacyNetworkDesc} Mail Order`;
              formattedHoverText = `${pharmacy.PharmacyNetworkDesc} Mail Order`;
            }
          });
        } else if (key === 'EstimatedAnnualRXOOPC') {
          formattedText = fmtField(plan.estAnnualOOPC, 'dollar');
          formattedHoverText = `$${plan.estAnnualOOPC}`;
        } else if (key === 'TotalAnnualOOPC') {
          formattedText = fmtField(plan.totalAnnualOOPC, 'dollar');
          formattedHoverText = `$${plan.totalAnnualOOPC}`;
        }
      }
    }

    // Star Rating component
    if (key === 'OverallRating') {
      formattedClassName = 'plandetails-star-rating';
      formattedText = plan ? <StarRating plan={plan} /> : '--';
    } else if (['TotalAnnualOOPC', 'EstimatedAnnualRXOOPC'].includes(key) && plan.plan.PartD_Suppressed) {
      formattedText = 'Not available';
    }

    if (key === 'InitialCoverageLimit') console.log('planDetailValue: ', planDetailValue);
    if (onUndefined && (planDetailValue === undefined)) formattedText = <span className="text-muted">{onUndefined}</span>;


    return disableRow ? null : (
      <div
        className={`plandetails-table-row ${tableRowClassName}`}
      >
        <div
          className="plandetails-table-detail-name"
          title={name}
        >
          {name}
        </div>
        <div
          className={`plandetails-table-detail-value ${formattedClassName}`}
          title={formattedHoverText}
        >
          {formattedText}
        </div>
      </div>
    );
  }
}

DetailTableRow.defaultProps = {
  data: {},
  format: 'text', // possible vals: text, dollar, bool, custom, star
  pharmacy: false,
  plan: {},
};

function mapStateToProps(store) {
  return {
    doctorsFeature: store.theme.features.doctors,
    doctorList: store.shopper.doctorList,
    doctorPlans: store.plan.doctorPlans,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
    modals: store.theme.modals,
  };
}

export default connect(mapStateToProps)(withRouterProps(DetailTableRow));
