import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

// utils
import { formatToPhone, formatPhoneToNum } from '../../utils/prettify';
// components
import { Popover } from '../../components';
import { useOutsideClick } from '../../hooks';

function HelpIcon({ appointmentsHelpPopoverText }) {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ debounce, setDebounce ] = useState(false);
  const ref = useRef();

  function handleClose() {
    if (!debounce && isOpen) {
      setIsOpen(false);
      setDebounce(true);
      setTimeout(() => setDebounce(false), 500);
    }
  }

  function handleOpen() {
    if (!debounce && !isOpen) {
      setIsOpen(true);
      setDebounce(true);
      setTimeout(() => setDebounce(false), 500);
    }
  }

  useOutsideClick(ref, handleClose);

  return (
    <span style={{ position: 'relative' }} className="ms-2">
      <FontAwesomeIcon
        role="button"
        icon={faCircleQuestion}
        onClick={() => handleOpen()}
      />
      <Popover
        isOpen={isOpen}
        containerRef={ref}
        style={{
          width: '325px',
          position: 'absolute',
        }}
      >
        {appointmentsHelpPopoverText || `The 'Cell Number' that you enter will only be used to send you SMS notifications
        when you have new leads. The 'Contact Number' that you enter will be provided in
        an email notification to the beneficiary (lead) per CMS compliance regulations that
        leads must be provided direct lines of communication.`}
      </Popover>
    </span>
  );
}

function ContactPhoneInput({
  disabled,
  appointmentsHelpPopoverText,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { contactPhone } = reduxProfile;
  const displayValue = formatToPhone(contactPhone);

  function handleChange(e) {
    const { value: val } = e.target;
    let unformatted = formatPhoneToNum(val);
    // NOTE(santeyio): this is jenky... I'm pretty tired and I'm not sure
    // that this is the best way to fix this?
    const re = /^\([0-9]{1,3}$/g;
    if (re.test(val)) {
      if (val.length === 4) unformatted = val.slice(1, 3);
      if (val.length === 3) unformatted = val.slice(1, 2);
      if (val.length === 2) unformatted = val.slice(1, 1);
    }
    setReduxProfileKeys({ contactPhone: unformatted });

    return unformatted;
  }

  return (
    <div>
      <label
        htmlFor="agent-contact-phone-input"
        className={`form-label ${disabled && 'text-muted'}`}
      >
        Contact Number
        <HelpIcon appointmentsHelpPopoverText={appointmentsHelpPopoverText} />
      </label>
      <input
        id="agent-contact-phone-input"
        disabled={disabled}
        className={`form-control ${disabled && 'text-muted'}`}
        value={displayValue || ''}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
}

function MobilePhoneInput({
  disabled,
  appointmentsHelpPopoverText,
  reduxAgentMobilePhone,
  setReduxAgentMobilePhone,
}) {
  const displayValue = formatToPhone(reduxAgentMobilePhone);

  function handleChange(e) {
    const { value: val } = e.target;
    let unformatted = formatPhoneToNum(val);
    // NOTE(santeyio): this is jenky... I'm pretty tired and I'm not sure
    // that this is the best way to fix this?
    const re = /^\([0-9]{1,3}$/g;
    if (re.test(val)) {
      if (val.length === 4) unformatted = val.slice(1, 3);
      if (val.length === 3) unformatted = val.slice(1, 2);
      if (val.length === 2) unformatted = val.slice(1, 1);
    }
    setReduxAgentMobilePhone(unformatted);

    return unformatted;
  }

  return (
    <div>
      <label
        htmlFor="agent-mobile-phone-input"
        className={`form-label ${disabled && 'text-muted'}`}
      >
        Cell Number
        <HelpIcon appointmentsHelpPopoverText={appointmentsHelpPopoverText} />
      </label>
      <input
        id="agent-mobile-phone-input"
        disabled={disabled}
        className={`form-control ${disabled && 'text-muted'}`}
        value={displayValue || ''}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
}

function ExternalProfileInput({
  disabled,
  reduxProfile,
  setReduxProfileKeys,
}) {
  const { externalProfileURL } = reduxProfile;

  function handleChange(e) {
    const { value } = e.target;
    setReduxProfileKeys({ externalProfileURL: value });
  }

  return (
    <div>
      <label
        htmlFor="agent-external-profile-input"
        className={`form-label ${disabled && 'text-muted'}`}
      >
        External Profile Link
      </label>
      <input
        id="agent-external-profile-input"
        disabled={disabled}
        className={`form-control ${disabled && 'text-muted'}`}
        value={externalProfileURL}
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
}

function AgentInfo({
  disabled,
  appointmentsHelpPopoverText,
  setReduxProfileKeys,
  reduxProfile,
  setReduxAgentMobilePhone,
  reduxAgentMobilePhone,
}) {
  const { appointmentProfileExternalLink } = useSelector(store => store.theme.features);

  return (
    <div className="row">
      <h3 className={disabled ? 'text-muted' : ''}>My Info</h3>
      <hr />

      <div className="col-md-3">
        <MobilePhoneInput
          appointmentsHelpPopoverText={appointmentsHelpPopoverText}
          disabled={disabled}
          setReduxAgentMobilePhone={setReduxAgentMobilePhone}
          reduxAgentMobilePhone={reduxAgentMobilePhone}
        />
      </div>

      <div className="col-md-3">
        <ContactPhoneInput
          appointmentsHelpPopoverText={appointmentsHelpPopoverText}
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          reduxProfile={reduxProfile}
        />
      </div>

      {appointmentProfileExternalLink && (
        <div className="col-md-6">
          <ExternalProfileInput
            disabled={disabled}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
          />
        </div>
      )}

    </div>
  );
}

export default AgentInfo;
