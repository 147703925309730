const initialState = {
  hash: null,
  name: '',
  logo: '',
  splash: '',
  privacyLink: '',
  favicon: '',
  ciHeader: '',
  greeting: '',
  bootswatchName: '',
  bootswatchCDN: '',
  physicianSearch: '',
  newsSearch: '',
  passwordResetWarning: '',
  modals: {
    plCompetitiveInfo: {},
    plRecommendedPlans: {},
    plAnnualOOPC: {},
    plOOPCRank: {},
    plpremiumRankvsMarket: {},
    plpremiumMarketSummary: {},
    plallPlans: {},
    pdBenefitHighlights: {},
    pdEstimatedMonthlyCost: {},
    pdDrugCostDetail: {},
    pdMonthlyCost: {},
    pdPreferredPharmacy: {},
    pdStandardPharmacy: {},
    pdMailOrderPharmacy: {},
    pdPlanDocuments: {},
  },
  features: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_THEME':
      return { ...state, ...action.payload };
    case 'LOAD_BOOTSWATCH':
      return {
        ...state,
        bootswatchName: action.theme.name || '',
        bootswatchCDN: action.theme.cssCdn || '',
      };
    default:
      return state;
  }
}
