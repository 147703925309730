import React from 'react';
import { connect } from 'react-redux';
import { handleOnlyNumberZip } from '../../../../utils/mixin';

class Zipcode extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    const { dispatch, shopper } = this.props;
    dispatch({ type: 'SET_DOCTORS_ZIPCODE', payload: shopper.zipcode });
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    const zip = event.target.value;
    if (zip.length < 5) {
      this.setState({ error: 'Please enter a valid 5 digit zip code' });
    } else {
      this.setState({ error: false });
    }
    dispatch({ type: 'SET_DOCTORS_ZIPCODE', payload: zip });
  };

  render() {
    const {
      selectedZip,
    } = this.props;

    return (
      <div className="d-flex align-items-center">
        of zip code
        <div className="mx-3 position-relative">
          <input
            id="doctor-zip-input"
            className="form-control"
            value={selectedZip}
            onChange={(e) => this.handleChange(e)}
            onKeyDown={(e) => handleOnlyNumberZip(e)}
          />
          <div className="invalid-feedback d-block position-absolute text-nowrap">{this.state.error}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
    selectedZip: store.doctor.selectedZip,
  };
}

export default connect(mapStateToProps)(Zipcode);
