/* eslint-disable no-undef */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import LoaderButton from '../../../common/LoaderButton';
import SOABadge from '../../../common/SOABadge';
import EnrollmentDisclaimer from '../../../common/EnrollmentDisclaimer';
import QuoteEnrollButton from '../../../common/QuoteEnrollButton';
import { StarRating } from '../../../../common/components';

// Components
import Benefits from './Benefits';
import DoctorsContainer from './DoctorsContainer';

// Utilities
import {
  formatSNPType,
  fmtField,
  formatDollarAmount,
  getPlanCarrierDisplay,
} from '../../../../utils/general';
import { showPopup } from '../../../../utils/popup';
import { renderDisplayCPS } from '../../../../utils/plans';

function PlanCard({ plan }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    compare: { planIds: plansToCompare },
    pharmacy: { selectedPharmacy },
    shopper,
    theme: {
      disclaimerDNP: disclaimer,
      features: {
        doctors: doctorsFeature,
      },
    },
  } = useSelector(store => store);

  function handleCompareToggle(event, planId, isSelected) {
    if (!isSelected) {
      const { planType } = plan;
      dispatch({
        type: 'ADD_PLAN_COMPARE',
        payload: {
          planType,
          planId,
          zipcode: shopper.zipcode,
        },
      });
    } else {
      dispatch({
        type: 'REMOVE_PLAN_COMPARE',
        payload: { planId },
      });
    }
  }

  function handleNavigate() {
    navigate('/plans/details');
    dispatch({
      type: 'SET_PLAN_DETAILS',
      payload: {
        planId: plan.planID,
        planType: plan.planType,
        planCarrier: plan.carrier,
        planName: plan.planName,
      },
    });
  }

  function copyToClipboard(e, data) {
    e.preventDefault();
    navigator.clipboard.writeText(data);
    showPopup('Success', 'Copied to clipboard', null, null, { autoClose: 1500 });
  }

  const isSelected = plansToCompare.indexOf(plan.planID) !== -1 || shopper.currentPlan === plan.cpsID;
  const isCurrentPlan = shopper.currentPlan === plan.cpsID;
  const canCompare = ((shopper.currentPlan ? 1 : 0) + plansToCompare.length) < 3;

  const displayName = getPlanCarrierDisplay({ plan });

  // Following three items are dependent on selecting pharmacies+meds
  // Selected Pharmacy Description
  // Estimated RX OOPC = Remaining yearly cost(RX)
  // Total Annual OOPC = Remaining yearly cost(RX + Premium)
  let selectedPharmacyCell = (
    <span
      className="mmb-link"
      onClick={() => navigate('/rx/add/pharmacy')}
      role="button"
      tabIndex="0"
      title="Select a pharmacy"
    >
      Select a pharmacy
    </span>
  );
  let estAnnualRxOOPCCell = selectedPharmacyCell;
  let totalAnnualOOPCCell = selectedPharmacyCell;

  const selectedMedications = shopper.drugList && shopper.drugList.length > 0;
  if (selectedPharmacy && plan.plan && plan.plan.PricingPharmacies) {
    // User has selected a pharmacy, but potentially has not selected any medications
    Object.keys(plan.plan.PricingPharmacies).forEach((pharmacyKey) => {
      const pharmacy = plan.plan.PricingPharmacies[pharmacyKey];
      if (pharmacy.NPI === `${selectedPharmacy}`) {
        selectedPharmacyCell = <span title={pharmacy.PharmacyNetworkDesc}>{pharmacy.PharmacyNetworkDesc}</span>;
      } else if (pharmacy.IsMailOrder && selectedPharmacy === 99999999) {
        selectedPharmacyCell = <span title={pharmacy.PharmacyNetworkDesc}>{pharmacy.PharmacyNetworkDesc} Mail Order</span>;
      }
    });
    // If they have not selected a drug, we cannot know either their oopc or pharmacy status
    if (!selectedMedications) {
      estAnnualRxOOPCCell = (
        <span
          className="mmb-link"
          onClick={() => navigate('/rx/add/drug')}
          role="button"
          tabIndex="0"
          title="Add medications"
        >
          Add medications
        </span>
      );
      totalAnnualOOPCCell = estAnnualRxOOPCCell;
      selectedPharmacyCell = estAnnualRxOOPCCell;
    } else {
      estAnnualRxOOPCCell = fmtField(plan.estAnnualOOPC, 'dollar');
      totalAnnualOOPCCell = formatDollarAmount(plan.totalAnnualOOPC);
    }
  }

  return (
    <div className="d-flex bg-bdlight border border-darker-grey shadow-sm mb-3">
      {/* Column #1 */}
      <div
        className="d-flex flex-column border-end border-darker-grey p-4 ms-2"
        style={{ width: plan.planType === 'PDP' ? '50%' : '34%' }}
      >
        <div title={`${plan.planType}\n${plan.carrier}\n${plan.planName}`}>
          <div
            className="d-flex align-items-center fw-bold text-primaryFocus fs-5"
            onContextMenu={(e) => copyToClipboard(e, JSON.stringify(plan))}
          >
            {plan.planType}
            <SOABadge planType={plan.planType} badgeType="icon" />
            {
              isCurrentPlan
                ? (
                  <span className="me-0 ms-auto border border-primaryFocus bg-primaryFocusBackground p-0 ps-2 pe-2 r-3">
                    CURRENT PLAN
                  </span>
                ) : null
            }
          </div>
          <div className="fw-bold mb-2 fs-5" style={{ lineHeight: '13px', color: 'var(--bs-gray-700)' }}>{displayName}</div>
          <div
            className="fs-4 fst-italic text-decoration-underline"
            onContextMenu={(e) => copyToClipboard(e, plan.planName)}
          >
            {plan.planName}
          </div>
          <div>
            <StarRating plan={plan} />
            <div
              title={plan.cpsID}
              style={{ color: 'var(--bs-gray-700)', display: 'inline-block', verticalAlign: '-.15rem' }}
              className="mb-0 ms-3"
              onContextMenu={(e) => copyToClipboard(e, renderDisplayCPS(plan.plan))}
            >
              Contract ID: {renderDisplayCPS(plan.plan)}
            </div>
          </div>
        </div>
        <div className="fs-4 mt-auto" title={formatDollarAmount(plan.monthlyPremium)}>
          Monthly premium: <span className="fw-bold mb-3">{formatDollarAmount(plan.monthlyPremium)}</span>
        </div>
        <div
          className="fs-4"
          data-disable={plan.plan.PartD_Suppressed}
          title={plan.plan.PartD_Suppressed ? '' : formatDollarAmount(plan.totalAnnualOOPC)}
        >
          Estimated total expense: <span className="fw-bold mb-3">{plan.plan.PartD_Suppressed ? 'Not Available' : totalAnnualOOPCCell }</span>
        </div>
        {
          plan.plan.IsSpecialNeedPlan
            ? <div>SNP Type: {formatSNPType(plan.plan.SNPIND)}</div>
            : null
        }
        {(plan.plan.SNPIND === 2) && (
          <div className="mt-3"><b>D-SNP Disclaimer:</b> {disclaimer}</div>
        )}
        <div className="w-100 mt-3 row" style={{ whiteSpace: 'nowrap', marginLeft: 'initial' }}>
          <QuoteEnrollButton
            className="fw-bold me-3 col-4"
            id="plancard-select-button"
            plan={plan}
            style={{ maxWidth: '175px' }}
          />
          <LoaderButton
            id="plancard-details-button"
            className="btn btn-outline-secondary fw-normal col-4"
            color="white"
            text="Plan details"
            onClick={() => handleNavigate()}
            style={{ maxWidth: '175px' }}
          />
          <div
            className={`align-items-center col-3 ${isCurrentPlan ? '' : ''}`}
            title={!canCompare ? 'You cannot compare more than 3 plans at a time.' : 'Compare'}
            style={{
              cursor: (canCompare || isSelected) && !isCurrentPlan ? 'pointer' : 'auto',
              display: 'inline !important',
            }}
            onClick={(e) => {
              if (canCompare || isSelected) {
                handleCompareToggle(e, plan.planID, isSelected);
              }
            }}
            role="button"
            tabIndex={0}
          >
            <input
              className="form-control-input mt-2 ms-2 me-2"
              id={`planlist-compare-button-${plan.planID}`}
              type="checkbox"
              readOnly
              checked={isSelected}
              disabled={(!canCompare && !isSelected) || isCurrentPlan}
              style={{
                width: '18px',
                height: '18px',
              }}
            />
            <label
              className="form-control-label fs-5 pe-none user-select-none"
              htmlFor={`planlist-compare-button-${plan.planID}`}
              id="planlist-compare-button-label"
              style={{
                cursor: canCompare || isSelected ? 'pointer' : 'auto',
                color: canCompare || isSelected ? 'black' : 'var(--bs-nbgrey)',
                verticalAlign: '.15rem',
              }}
            >
              Compare
            </label>
          </div>
        </div>
        <div
          className={`d-flex align-items-center ${isCurrentPlan ? '' : ''}`}
          title={!canCompare ? 'You cannot compare more than 3 plans at a time.' : 'Compare'}
          style={{
            cursor: (canCompare || isSelected) && !isCurrentPlan ? 'pointer' : 'auto',
          }}
          onClick={(e) => {
            if (canCompare || isSelected) {
              handleCompareToggle(e, plan.planID, isSelected);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <EnrollmentDisclaimer plan={plan} />
        </div>
      </div>
      {/* Column #2 */}
      {
        (plan.planType === 'PDP' || !doctorsFeature)
          ? null
          : (
            <DoctorsContainer plan={plan} />
          )
          }
      {/* Column #3 */}
      <Benefits plan={plan} />
    </div>
  );
}

export default PlanCard;
