import React from 'react';
import { connect } from 'react-redux';
import LoaderComponent from '../common/LoaderComponent';
import NavPage from '../nav/NavPage';
import { withRouterProps } from '../../utils/hocs';

class RouterLogic extends React.PureComponent {
  componentDidMount() {
    const { doctorList, navigate } = this.props;

    // Use of setTimeout recommended here and solves the problem described in this issue:
    // https://github.com/remix-run/react-router/issues/7460#issuecomment-988642684
    setTimeout(() => {
      if (doctorList.length <= 0) {
        navigate('/doctors/search');
      } else if (doctorList.length > 0) {
        navigate('/doctors/list');
      }
    }, 0);
  }

  render() {
    return (
      <NavPage activeLink="/doctors" showShopperInfobar>
        <LoaderComponent />
      </NavPage>
    );
  }
}

const mapStateToProps = (store) => ({
  doctorList: store.shopper.doctorList,
});

export default connect(mapStateToProps)(withRouterProps(RouterLogic));
