import React from 'react';
import { connect } from 'react-redux';

// Utilities
import { fmtField } from '../../../../utils/general';

class DrugDetailTableRow extends React.PureComponent {
  render() {
    const {
      coveragePhaseKeys,
      drug,
      headerTitles,
    } = this.props;

    let tierDescription = 'Not covered';
    if (drug.TierNum) {
      tierDescription = `Tier ${drug.TierNum} - ${drug.TierDescription}`;
    }
    const medicationNotCoveredStyle = 'text-danger';

    let quantityLimit = '-';

    // todo(@joeysapp): Hmm. This looks like manually creating a limit but we have QuantityLimitFullText now?
    // if (drug.QuantityLimit) {
    //   quantityLimit = drug.QuantityLimit;
    //   if (drug.ContainPackage) {
    //     if (drug.PackageDescription) {
    //       quantityLimit = `${quantityLimit / (drug.PackageSize * drug.QTY)} X ${drug.PackageDescription}`;
    //     } else if (drug.QuantityLimitDays) {
    //       quantityLimit = `${quantityLimit} / ${drug.QuantityLimitDays} days`;
    //     }
    //   } else if (drug.QuantityLimitDays) {
    //     quantityLimit = `${quantityLimit} / ${drug.QuantityLimitDays} days`;
    //   }
    // }

    if (drug.QuantityLimitFullText) {
      quantityLimit = drug.QuantityLimitFullText;
    }

    return (
      <tr className="plandetails-medication-cost-details-drug-row">
        <td
          className="plandetails-medication-cost-details-drug-row-info"
          title={`Medication\n\n${drug.Label}\n${tierDescription}`}
        >
          <span className="">
            {drug.Label}
          </span>
          <span className={drug.TierNum ? '' : medicationNotCoveredStyle}>
            {tierDescription}
          </span>
        </td>
        <td title={`Retail cost: $${String(drug.FullPharmacyPrice)}`}>{fmtField(drug.FullPharmacyPrice, 'dollar')}</td>
        {coveragePhaseKeys.map((key, idx) => (
          <td
            className="coverage-phase"
            title={`${headerTitles[idx + 2].text}: $${String(drug[key])}`}
            key={headerTitles[idx + 2].text}
          >
            {fmtField(drug[key], 'dollar')}
          </td>
        ))}
        <td
          className="restrictions"
          title={`Prior authorization: ${drug.PriorAuthorization ? 'Yes' : 'No'}`}
        >
          {drug.PriorAuthorization ? 'Yes' : 'No'}
        </td>
        <td
          className="restrictions"
          title={`Quantity limit: ${quantityLimit}`}
        >
          {quantityLimit}
        </td>
        <td
          className="restrictions"
          title={`Step therapy: ${drug.StepTherapy ? 'Yes' : 'No'}`}
        >
          {drug.StepTherapy ? 'Yes' : 'No'}
        </td>
      </tr>
    );
  }
}

DrugDetailTableRow.defaultProps = {
  coveragePhaseKeys: [],
  drug: {},
  headerTitles: [],
};

export default connect()(DrugDetailTableRow);
