import React from 'react';
import { connect } from 'react-redux';

const frequencies = [
  { name: '1 Month', value: 30 },
  { name: '2 Months', value: 60 },
  { name: '3 Months', value: 90 },
  { name: '1 Year', value: 365 },
];

class Frequency extends React.PureComponent {
  handleChange = (event) => {
    const { dispatch } = this.props;
    const { value } = event.target;
    dispatch({
      type: 'SET_SELECTED_FREQUENCY',
      payload: Number(value),
    });
  };

  render() {
    const {
      selectedFrequency,
    } = this.props;

    return (
      <>
        <select
          id="rx-select-frequency-input"
          className="form-control h-100"
          value={selectedFrequency}
          onChange={(e) => this.handleChange(e)}
        >
          {frequencies.map((fq) => (
            <option
              key={`fq-option-${fq.value}`}
              value={fq.value}
            >
              {fq.name}
            </option>
          ))}
        </select>
        <i
          className="fa fa-chevron-down"
          style={{
            fontSize: '15px',
            marginLeft: '-40px',
            marginTop: '20px',
            paddingLeft: '4px',
            position: 'relative',
            pointerEvents: 'none',
            left: '15px',
            backgroundColor: 'white',
            bottom: '1px',
          }}
        />
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedFrequency: store.drug.selectedFrequency,
  };
}

export default connect(mapStateToProps)(Frequency);
