
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

// TODO(joeysapp): Intent to remove from both Modal and Button next,
//                though not sure if the Modal is used elsewhere in the app
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';

// Actions
import { deleteNotification } from '../../../actions/notifications';

// Utilities
import { calculateNextNotification } from '../../../utils/general';
import {
  formatDateToDayString,
  formatDateToWeekString,
} from '../../../utils/formatFunctions';

class EditNotificationButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notificationToolTip: false,
    };
    this.toggleToolTip = this.toggleToolTip.bind(this);
  }

  setSelectedNotification(notification) {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_SELECTED_NOTIFICATION', payload: notification });
  }

  toggleToolTip = () => {
    this.setState((prevState) => ({
      ...prevState,
      notificationToolTip: !prevState.notificationToolTip,
    }));
  };

  showModal() {
    const { dispatch } = this.props;
    dispatch({ type: 'SHOW_MODAL', payload: 'editNotificationModal' });
  }

  render() {
    const {
      shopper,
      style,
    } = this.props;
    const { notificationToolTip } = this.state;
    const notification = this.props.notifications.filter((n) => n.shopper === shopper.username);
    return notification.length
      ? (
        <>
          <td
            className="align-middle text-center"
            style={style}
          >
            <div className="d-flex">
              <button
                className="btn btn-outline-secondary flex-grow-1 text-center"
                id={`Tooltip-${shopper.username}`}
                type="button"
              >
                {calculateNextNotification(notification[0])}
              </button>
              <button
                className="ms-1 btn btn-outline-secondary"
                onClick={() => {
                  this.setSelectedNotification(notification[0]);
                  this.showModal();
                }}
                type="button"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
              <button
                className="ms-1 btn btn-danger"
                color="danger"
                onClick={() => deleteNotification(notification[0].id)}
                type="button"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </td>
          <UncontrolledPopover
            isOpen={notificationToolTip}
            key={shopper.username}
            placement="bottom"
            target={`Tooltip-${shopper.username}`}
            toggle={this.toggleToolTip}
            trigger="legacy"
          >
            <PopoverHeader>Notification Info</PopoverHeader>
            <PopoverBody>
              <div>
                <div>Start Date: {formatDateToDayString(notification[0].start_date)}</div>
                <div>Interval:&nbsp;
                  {notification[0].interval === 'weekly'
                    ? formatDateToWeekString(notification[0].start_date)
                    : notification[0].interval.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
                </div>
                {
                  notification[0].interval === 'custom'
                    ? (
                      <div>
                        <b>Details:</b>
                        <div>
                          Days: {
                            Object.keys(notification[0].days).filter((d) => notification[0].days[d]).map((d) => d.charAt(0).toUpperCase() + d.substring(1, 2)).join(', ')
                          }
                        </div>
                        <div>End Date: {formatDateToDayString(notification[0].end_date)}</div>
                      </div>
                    ) : null
                }
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )
      : <td className="align-middle text-center" style={style}>-</td>;
  }
}

EditNotificationButton.defaultProps = {
  shopper: {},
};

const mapStateToProps = (store) => ({
  notifications: store.notifications.list,
});

export default connect(mapStateToProps)(EditNotificationButton);
