import React from 'react';
import { connect } from 'react-redux';

// Actions
import { loadDoctorSpecialties } from '../../../../actions/doctor';
import { mapListSort } from '../../../../utils/general';

class Specialty extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    loadDoctorSpecialties()
      .then((data) => {
        const options = [...data];
        mapListSort(options, 'desc');
        this.setState({ options });
      });
  }

  handleChange = (event) => {
    const { dispatch } = this.props;
    const val = event.target.value;
    dispatch({ type: 'SET_DOCTORS_SPECIALTY', payload: val });
  };

  render() {
    const { selectedSpecialty } = this.props;

    return (
      <div className="d-flex align-items-center">
        Specialty:
        <select
          id="doctor-select-specialty-input"
          className="form-control ms-3 me-3"
          onChange={(e) => this.handleChange(e)}
          value={selectedSpecialty}
        >
          <option value="">All</option>
          {this.state.options.map((o) => (
            <option key={o.id} value={o.id}>
              {o.desc}{o.subSpecialty ? ` - ${o.subSpecialty}` : null}
            </option>
          ))}
        </select>
        <i className="fa fa-chevron-down me-4" style={{ fontSize: '15px', marginLeft: '-40px', pointerEvents: 'none' }} />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedSpecialty: store.doctor.selectedSpecialty || 0,
  };
}

export default connect(mapStateToProps)(Specialty);
