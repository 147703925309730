/* eslint-disable react/jsx-props-no-multi-spaces */
import React from 'react';
import { connect } from 'react-redux';
import ReactPagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';

// Actions
import { loadShoppers } from '../../actions/shopper';

class Pagination extends React.PureComponent {
  render() {
    const {
      activePage,
      agentEmail,
      firstNameFilter,
      itemsCountPerPage,
      leadIDFilter,
      leadTypeFilter,
      showAllQuotes,
      sortTerm,
      sortInverse,
      stateFilter,
      selectedTab,
      totalItemsCount,
      statusFilter,
    } = this.props;

    // Wiki: https://github.com/wwwaiser/react-js-pagination
    return (
      <ReactPagination
        activePage={activePage}
        innerClass="pagination justify-content-center"
        itemClass="page-item"
        itemsCountPerPage={itemsCountPerPage}
        onChange={(pageNum) => {
          loadShoppers({
            user_who_created: agentEmail,
            show_all_quotes: showAllQuotes,
            name: firstNameFilter || null,
            lead_id: leadIDFilter || null,
            page: pageNum,
            lead_category: statusFilter ? undefined : selectedTab,
            lead_status: statusFilter || null,
            lead_type: leadTypeFilter || null,
            state: stateFilter || null,
            sort: sortTerm || null,
            desc: sortInverse || null,
          });
        }}
        linkClass="page-link"
        pageRangeDisplayed={5}
        totalItemsCount={totalItemsCount}

        // Visible components
        firstPageText={<FontAwesomeIcon icon={faChevronDoubleLeft} />}
        lastPageText={<FontAwesomeIcon icon={faChevronDoubleRight} />}
        prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
        nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
      />
    );
  }
}

function mapStateToProps(store) {
  return {
    activePage: store.shopperlist.activePage,
    agentEmail: store.agent.email,
    firstNameFilter: store.shopperlist.firstNameFilter,
    itemsCountPerPage: store.shopperlist.itemsCountPerPage,
    leadIDFilter: store.shopperlist.leadIDFilter,
    selectedTab: store.shopperlist.selectedTab,
    showAllQuotes: store.shopperlist.showAllQuotes,
    sortTerm: store.shopperlist.sortTerm,
    sortInverse: store.shopperlist.sortInverse,
    stateFilter: store.shopperlist.stateFilter,
    statusFilter: store.shopperlist.statusFilter,
    totalItemsCount: store.shopperlist.totalItemsCount,
  };
}

export default connect(mapStateToProps)(Pagination);
