/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { Link } from 'react-router-dom';

// actions
import { getReports } from '../../actions/reports';

function ReportTile({ report = {} }) {
  console.log('report: ', report);
  const toLink = `/reports/${report.type}/${report.id}?aws=${report.aws}`;

  return (
    <div
      className="col-md-8 mb-3 bg-white p-3"
      style={{
        border: 'solid 1px lightgrey',
        borderRadius: '3px',
        boxShadow: '0px 0px 3px 0px lightgrey',
      }}
    >
      <Link
        to={toLink}
        className="text-black text-decoration-none"
      >

        <div className="d-flex justify-content-between mb-3">
          <div className="">
            <h5 className="m-0">{report.name}</h5>
          </div>
          <div className="">
            <span className="fs-4">{report.year}</span>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <i>{HTMLReactParser(report.description)}</i>
        </div>

      </Link>
    </div>
  );
}

function List() {
  const [ reports, setReports] = useState([]);

  useEffect(() => {
    getReports()
      .then((data) => setReports(data));
  }, []);

  return (
    <div className="row px-5">
      <div className="flex-column">

        <div className="row">
          <div className="col">
            <h4 className="fw-bold mb-5">Reports</h4>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {reports.map((report) => (
              <ReportTile report={report} />
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default List;
