import axios from 'axios';
import { store } from '../store';

export function loadThreeWayCalls(params = {}) {
  const token = localStorage.getItem('SessionToken');
  const {
    stCallID,
    stCallStartDate,
    stCallEndDate,
    stClientFirstName,
    stClientLastName,
    stClientPhone,
  } = store.getState().threeWay;

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/call/`,
    headers: { Authorization: `Token ${token}` },
    params: {
      call_id: stCallID || null,
      call_start_max: stCallEndDate || null,
      call_start_min: stCallStartDate || null,
      shopper_first_name: stClientFirstName || null,
      shopper_last_name: stClientLastName || null,
      shopper_phone: stClientPhone || null,
      ...params,
    },
  }).then(({ data = {} }) => (data))
    .catch(err => {
      console.error(err);
    });
}

export function updateThreeWayCall(callData) {
  const token = localStorage.getItem('SessionToken');
  const { call_id } = callData;
  return axios({
    method: 'put',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/call/${call_id}/`,
    headers: { Authorization: `Token ${token}` },
    data: callData,
  }).then(({ data = {} }) => (data))
    .catch(err => {
      console.error(err);
    });
}
