import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

// Components
import LoaderButton from '../../../common/components/LoaderButton';
import LoaderEllipsis from '../../common/LoaderEllipsis';
import EnrollmentDisclaimer from '../../common/EnrollmentDisclaimer';
import EnrollmentExport from './EnrollmentExport';

// Actions + Utils
import { showPopup } from '../../../utils/popup';
import { canEnrollPlan, canSharePlan } from '../../../utils/perms';
import { getPlan } from '../../../actions/plan';
import { openEnrollment } from '../../../actions/quote';
import { getDateAsString } from '../../../utils/general';

function ShareQuoteButton({ quote = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // todo(joeysapp) - address 'sharing' old prop use when /quotes/share is refactored/un-formik'd.
  //                  probably could make it just on the SavedQuote element itself, not a new page..?
  const sharing = location.pathname.indexOf('share') !== -1;
  function handleShareClick() {
    dispatch({ type: 'SET_SHARE_QUOTE_ID', payload: quote.quoteID });
    navigate('/quotes/share');
  }

  return (
    <LoaderButton
      className="btn btn-primaryFocus fw-bold text-white ps-4 pe-4 me-4 text-nowrap"
      id="enroll-share-quote-button"
      text={sharing ? (<LoaderEllipsis className="fst-italic" text="Sharing" color="white" />) : 'Share quote'}
      style={{
        cursor: sharing ? 'initial' : 'pointer',
        opacity: sharing ? '0.5' : '1',
      }}
      onClick={() => handleShareClick()}
    />
  );
}

function EnrollmentStatus({ quote = {} }) {
  const { enrollment = {} } = quote;
  if (enrollment && enrollment.cancelled) {
    return (
      <div className="d-flex align-items-center ms-1 overflow-hidden">
        <span
          className="badge bg-danger ms-4 d-flex justify-content-center align-items-center"
          style={{
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '6px',
            paddingRight: '6px',
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </span>
        <div className="d-flex flex-column ms-3 overflow-hidden">
          <div className="fw-bold text-danger text-truncate">
            Enrollment cancelled | {getDateAsString(enrollment.cancel_date, 'longform')}
          </div>
          <div className="fs-6 text-danger text-truncate">
            Confirmation ID: {enrollment.confirmation_number}
          </div>
        </div>
      </div>
    );
  } else if (enrollment && enrollment.submitted_flag && !enrollment.final_submission_to_carrier) {
    return (
      <div
        className="d-flex align-items-center ms-1 overflow-hidden"
        style={{ opacity: 0.75 }}
      >
        <span className="badge bg-green ms-4 d-flex justify-content-center align-items-center p-1 ">
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <div className="d-flex flex-column ms-3 overflow-hidden">
          <div className="fw-bold text-success text-truncate">
            Enrollment pending submission to carrier | {getDateAsString(enrollment.submit_date, 'longform')}
          </div>
          <div className="fs-6 text-success text-truncate">
            Confirmation ID: {enrollment.confirmation_number}
          </div>
        </div>
      </div>
    );
  } else if (enrollment && enrollment.submitted_flag && enrollment.final_submission_to_carrier) {
    return (
      <div className="d-flex align-items-center ms-1 overflow-hidden">
        <span className="badge bg-green ms-4 d-flex justify-content-center align-items-center p-1">
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <div className="d-flex flex-column ms-3 overflow-hidden">
          <div className="fw-bold text-success text-truncate">
            Enrollment submitted to carrier | {getDateAsString(enrollment.final_carrier_submit_date, 'longform')}
          </div>
          <div className="fs-6 text-success text-truncate">
            Confirmation ID: {enrollment.confirmation_number}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="text-green fw-bold fst-italic fs-5 ms-4 text-truncate">
      Enrollment in progress...
    </div>
  );
}

function EnrollmentButtons({ quote = {} }) {
  const dispatch = useDispatch();
  const {
    enrollment = {},
  } = quote;
  const host = useSelector(store => store.theme.enrollment);

  function canEnroll() {
    const {
      carrierID,
      cpsID,
      planType,
      state,
      carrier,
      planYear,
    } = quote;
    return canEnrollPlan({ planType, carrierID, planState: state, cpsID, carrier, planYear });
  }

  // todo(joeysapp): Verify this works on dev/qa, without enrollment portal running locally it wigs out
  function handleEnrollClick() {
    if (canEnroll()) {
      dispatch({
        type: 'CONFIRM_MODAL_OPEN',
        quote: quote.quoteID,
        planID: quote.planID,
      });
    } else {
      const msg = `MMB does not support enrollment for ${quote.carrier || 'this carrier'}`;
      showPopup('Error', msg);
    }
  }

  return (enrollment && enrollment.submitted_flag) ? (
    <div className="d-flex flex-column align-items-left ms-1 mb-1 overflow-hidden mt-1">
      <div className="d-flex">
        <LoaderButton
          className="btn btn-primary fw-bold ps-4 pe-4 fs-5 text-nowrap"
          id="enroll-view-submitted-quote-button"
          text="View enrollment"
          style={{ maxHeight: '40px', maxWidth: '170px' }}
          onClick={() => {
            getPlan(quote.planID).then((response) => {
              const { CarrierName, PlanYear } = response.data;
              openEnrollment(quote.quoteID, host, CarrierName, PlanYear);
            });
          }}
        />
        <EnrollmentStatus quote={quote} />
      </div>
      <EnrollmentExport className="mb-2" quoteID={quote.quoteID} />
    </div>
  ) : (
    <div className="d-flex align-items-center">
      {canSharePlan({ quote }) && (<ShareQuoteButton quote={quote} />)}
      <LoaderButton
        className="btn btn-primary fw-bold ps-4 pe-4 fs-5 text-nowrap"
        id="enroll-save-quote-button"
        text={quote?.enrollment ? 'Go to enrollment' : 'Enroll now'}
        style={{ maxHeight: '40px', minWidth: '150px', opacity: !canEnroll() ? '0.33' : '1' }}
        disabled={!canEnroll()}
        onClick={() => handleEnrollClick()}
      />
      <div className="d-flex overflow-hidden">
        {(enrollment && enrollment.created) && (<EnrollmentStatus quote={quote} />)}
      </div>
      <EnrollmentDisclaimer
        className="ms-3 mt-0"
        style={{ whiteSpace: 'break-spaces', minHeight: 'auto', lineHeight: '15px' }}
        geoState={quote.shopper_state}
        quote={quote}
        plan={{
          carrierID: quote.carrierID,
          carrier: quote.carrier,
          planType: quote.planType,
          cpsID: quote.cpsID,
          plan: { PlanYear: quote.planYear },
        }}
      />
    </div>
  );
}
export default EnrollmentButtons;
