import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheck } from '@fortawesome/pro-solid-svg-icons';

// Components
import LoaderButton from '../../../common/components/LoaderButton';

// Actions + Utils
import { getHRA, createHRA } from '../../../actions/hra';
import { getHRAConfigName, getHRADeadline } from '../../../utils/hra';
import { getDateAsString } from '../../../utils/general';
import { showPopup } from '../../../utils/popup';
import { setCookie } from '../../../utils/cookie';

function HRAStatus({ quote }) {
  const {
    hra: quoteHRA,
  } = quote;
  const [deadline, setDeadline] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (quoteHRA && quoteHRA.submitted_flag) {
      setSubmitted(true);
    } else {
      // Handle agents repeatedly checking and unchecking completed checkbox in ExternalHRAModal
      setSubmitted(false);
    }
  }, [quote, quoteHRA]);

  useEffect(() => {
    setDeadline(getHRADeadline(quote));
  }, []);

  return (
    <div className="d-flex align-items-center ms-1 overflow-hidden">
      <div
        className={[
          'badge ms-4 d-flex p-1',
          'justify-content-center align-items-center',
          submitted ? 'bg-green' : 'border border-secondary',
        ].join(' ')}
      >
        {submitted
          ? <FontAwesomeIcon icon={faCheck} />
          : <FontAwesomeIcon icon={faSquare} />}
      </div>
      <div className="d-flex flex-column ms-3 overflow-hidden">
        {(submitted) ? (
          <div className="fw-bold text-success">
            {`HRA submitted ${getDateAsString(quoteHRA?.submit_date, 'longform')}`}
          </div>
        ) : (
          <>
            <div className="fw-bold text-secondary text-truncate">
              {/* {`HRA available until ${getDateAsString(HRADeadline(enrollment.submit_date, timeToSubmit, timeUnit), 'longform')}`} */}
              {`HRA available until ${getDateAsString(deadline, 'longform')}`}
            </div>
            {(quoteHRA && quoteHRA.source === 'internal' && !submitted)
             && (<div className="fs-6 text-secondary text-truncate">HRA in progress...</div>)}
          </>
        )}
      </div>
    </div>
  );
}

function HRAButtons({ quote = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    hra: quoteHRA,
  } = quote;
  const hra = useSelector(store => store.hra);
  const { configs = {} } = hra;
  const [buttonText, setButtonText] = useState('Start HRA');
  const [hraExists, setHraExists] = useState(Boolean(quoteHRA));

  useEffect(() => {
    if (quoteHRA) {
      const { submitted_flag, source } = quoteHRA;
      if (submitted_flag) {
        setButtonText('View HRA');
      } else if (source === 'internal') {
        setButtonText('Continue HRA');
      } else if (source === 'external' && submitted_flag === false) {
        // Handle agents repeatedly checking and unchecking completion in the modal
        setButtonText('Start HRA');
      }
    }
  }, [quote, quoteHRA]);

  /* eslint-disable no-unused-expressions */
  const DEBUG = true;
  async function handleHRAClick() {
    const { carrier } = quote;
    const configName = getHRAConfigName(carrier);
    const config = configName in configs ? configs[configName] : {};

    dispatch({ type: 'CLEAR_HRA' });
    dispatch({ type: 'SET_HRA_QUOTE_ID', payload: quote.quoteID });

    // Populate the redux store with an empty config
    dispatch({ type: 'SET_HRA', payload: config });
    DEBUG && console.debug('HRAButtons[00] \n config ->', config);
    DEBUG && console.debug('HRAButtons[00] \n storeHRA ->', hra);
    DEBUG && console.debug('HRAButtons[00] \n quoteHRA ->', quoteHRA);

    const { source } = config;
    if (source === 'internal') {
      navigate('/hra');
    } else if (source === 'external') {
      let loadingHRA = {};
      // The case where we previously had an HRA in the store may not have been properly cleared out
      try {
        if (!hraExists) {
          loadingHRA = await createHRA(quote.quoteID, [], 'external');
          setHraExists(true);
        } else {
          loadingHRA = await getHRA(quote.quoteID);
        }
        const token = localStorage.getItem('SessionToken');
        setCookie('hraToken', token);
        dispatch({ type: 'SET_HRA', payload: loadingHRA });
        dispatch({ type: 'SET_MODAL', payload: { externalHRAModal: true } });
      } catch (err) {
        const { response = {} } = err;
        const { data = {} } = response;
        showPopup('Error', 'Error in retrieving HRA');
        console.error(data);
      }
    } else {
      showPopup('Error', 'Error in loading HRA configuration', 'error');
    }
  }

  return (
    <div className="d-flex ms-1 align-items-center">
      <LoaderButton
        className="btn btn-primary fw-bold ps-4 pe-4 fs-5 text-nowrap"
        id="enroll-view-hra-button"
        text={buttonText}
        style={{ maxHeight: '40px', minWidth: '170px' }}
        onClick={() => handleHRAClick()}
      />
      <div className="d-flex align-items-center overflow-hidden">
        <HRAStatus quote={quote} />
      </div>
    </div>
  );
}

export default HRAButtons;
