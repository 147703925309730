const initialState = {
  vsoaConfig: {},
  vsoaValues: {},
  vsoaValid: {},
  values: {
    method: 'email', // email | sms | voice | external
    send_email: '',
    send_greeting: '',
    send_closing: 'Thank You,',
    send_signature: '',
    send_sms_phone: '',
    send_sms_greeting: '',
    soaType: '',
    soaDate: '',
    mapdMa: false,
    pdp: false,
    dvh: false,
    supHealth: false,
    medigap: false,
    otherDescription: '',
    checkbox: '',
  },
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VSOA_CONFIG':
      return { ...state, vsoaConfig: action.vsoaConfig };
    case 'SET_VSOA_VALUES':
      return {
        ...state,
        vsoaValues: {
          ...state.vsoaValues,
          ...action.fields,
        },
      };
    case 'SET_VSOA_VALID':
      return {
        ...state,
        vsoaValid: {
          ...state.vsoaValid,
          ...action.fields,
        },
      };
    case 'CLEAR_VSOA':
      return {
        ...state,
        vsoaConfig: {},
        vsoaValues: {},
        vsoaValid: {},
      };
    case 'SET_SOA_VALUES':
      return {
        ...state,
        values: action.payload,
      };
    case 'SET_SOA_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.payload,
        },
      };
    case 'SET_SOA_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.payload,
        },
      };
    case 'CLEAR_SOA_ERRORS':
      return {
        ...state,
        errors: {},
      };
    case 'CLEAR_SOA':
      return initialState;
    default:
      return state;
  }
}
