import React from 'react';
import {
  Spinner,
} from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowDown,
} from '@fortawesome/pro-light-svg-icons';

// Components
import MobileShopper from './MobileShopper';

class ShopperList extends React.PureComponent {
  handleSort(event) {
    const { dispatch } = this.props;
    const sortTerm = event.target.value;
    dispatch({ type: 'SET_MOBILE_SORT_TERM', payload: sortTerm });
  }

  handleFilter(event) {
    const { dispatch } = this.props;
    const filterStatusId = event.target.value;
    dispatch({ type: 'SET_MOBILE_FILTER_STATUS_ID', payload: filterStatusId });
  }

  renderShoppers() {
    const {
      filterStatusId,
      sortTerm,
      shopperList,
      shoppersLoaded,
    } = this.props;
    if (shopperList && shopperList.length > 0) {
      let filteredShopperList = [...shopperList];
      const filteredStatusIdString = `${filterStatusId}`;
      if (filteredStatusIdString !== '-1') {
        filteredShopperList = filteredShopperList.filter((s) => {
          const statusString = `${s.status}`;
          return statusString === filteredStatusIdString;
        });
      }

      const sortsToReverse = ['lastUpdated'];
      const reverseList = sortsToReverse.indexOf(sortTerm) !== -1 ? -1 : 1;
      filteredShopperList.sort((shopperA, shopperB) => {
        const a = shopperA[sortTerm] ? String(shopperA[sortTerm]) : '';
        const b = shopperB[sortTerm] ? String(shopperB[sortTerm]) : '';
        let equality = reverseList * a.localeCompare(b);
        if (equality === 0) {
          const lastNameA = shopperA.lastName ? shopperA.lastName : shopperA.firstName;
          const lastNameB = shopperB.lastName ? shopperB.lastName : shopperB.firstName;
          equality = lastNameA.localeCompare(lastNameB);
        }
        return equality;
      });

      if (filteredShopperList.length > 0) {
        return filteredShopperList.map((shopper) => (
          <MobileShopper
            key={shopper.username}
            shopper={shopper}
          />
        ));
      // eslint-disable-next-line
      } else {
        return (
          <td className="align-middle text-center" colSpan={12}>
            No Shoppers Available.
          </td>
        );
      }
    // eslint-disable-next-line
    } else if (shoppersLoaded === 'FAIL_STATE') {
      return (
        <td className="align-middle shoppers-failed-indicator" colSpan={12}>Something went wrong.
        </td>
      );
    }

    return (
      <td className="align-middle text-center" colSpan={12}>
        <Spinner
          color="primary"
          style={{
            display: 'block',
            margin: 'auto',
            marginTop: '25%',
            width: '9rem',
            height: '9rem',
          }}
        />
      </td>
    );
  }

  render() {
    const {
      filterStatusId,
      leadStatusOptions = [],
      shoppersLoaded,
      sortTerm,
      totalItemsCount,
      itemsCountPerPage,
      shopperListLastPageNum,
    } = this.props;

    const unloadedShoppersExist = (shopperListLastPageNum * itemsCountPerPage) < totalItemsCount;
    const filterStatusOptions = [
      {
        value: -1,
        description: 'All',
      },
      // eslint-disable-next-line
      ... leadStatusOptions ? leadStatusOptions : [],
    ];
    const shopperSortOptions = [
      {
        name: 'Last Updated',
        id: 'lastUpdated',
      }, {
        name: 'Status',
        id: 'status',
      }, {
        name: 'Last Name',
        id: 'lastName',
      },
    ];

    return (
      <>
        <div className="mobile-table-row prevent-touch" style={{ }}>
          <table className="mobile-table mobile-font-small prevent-touch">
            <tr>
              <td className="mobile-table-col prevent-touch">
                Showing:
              </td>
              <td
                className="mobile-table-col prevent-touch"
                style={{ paddingLeft: '15px' }}
              >
                Sort by:
              </td>
            </tr>
            <tr>
              <td className="mobile-table-col prevent-touch">
                <select
                  style={{
                    height: '44px',
                    marginTop: '7px',
                    paddingLeft: '12px',
                    paddingRight: '18%',
                    textOverflow: 'ellipsis',
                    width: '90%',
                  }}
                  onChange={(e) => { this.handleFilter(e); }}
                  value={filterStatusId}
                >
                  {filterStatusOptions.map((status) => (
                    <option value={status.value}>
                      {status.description}
                    </option>
                  ))}
                </select>
                <i
                  className="fa fa-chevron-down"
                  style={{
                    marginRight: '13%',
                    marginTop: '-37px',
                  }}
                />
              </td>

              <td
                className="mobile-table-col prevent-touch"
                style={{ paddingLeft: '15px' }}
              >
                <select
                  style={{
                    height: '44px',
                    marginTop: '7px',
                    paddingLeft: '15px',
                    width: '100%',
                  }}
                  onChange={(e) => { this.handleSort(e); }}
                  value={sortTerm}
                >
                  {shopperSortOptions.map((status) => (
                    <option value={status.id}>{status.name}</option>
                  ))}
                </select>
                <i
                  className="fa fa-chevron-down"
                  style={{
                    marginTop: '-37px',
                  }}

                />
              </td>
            </tr>
          </table>
        </div>
        <div style={{ position: 'relative', minHeight: '85%', paddingBottom: '110px' }}>
          <table className="mobile-leads-list">
            <tbody>{this.renderShoppers()}</tbody>
          </table>
          {(unloadedShoppersExist && shoppersLoaded === 'SUCCESS_STATE')
            ? (
              <div
                className="mobile-font-small"
                style={{
                  display: 'flex',
                  bottom: '0',
                  flexDirection: 'column',
                  width: '100%',
                  textAlign: 'center',
                  textStyle: 'italic',
                  paddingBottom: '4em',
                  position: 'absolute',
                  color: 'rgb(128, 128, 128)',
                }}
              >
                Drag to Load More Leads
                <FontAwesomeIcon style={{ width: '100%' }} icon={faLongArrowDown} />
              </div>
            )
            : <div />}
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    agentUsername: store.mobile.agentUsername,
    agentEmail: store.agent.email,
    isLoading: store.mobile.isLoading,
    filterStatusId: store.mobile.filterStatusId || -1,
    filteredShopperList: store.mobile.filteredShopperList,
    setLanguage: store.translations.setLanguage,
    shopperList: store.mobile.shopperList || [],
    shopperListLastPageNum: store.mobile.shopperListLastPageNum,
    shopperPage: store.shopperlist.shopperPage,
    shoppersLoaded: store.loaders.shoppersLoaded,
    sortTerm: store.mobile.sortTerm || 'status',
    leadStatusOptions: store.leadStatus.options,
    leadUsername: store.mobile.shopperUsername,
    translations: store.translations.sets,
    totalItemsCount: store.shopperlist.totalItemsCount,
    itemsCountPerPage: store.shopperlist.itemsCountPerPage,
  };
}

export default connect(mapStateToProps)(ShopperList);
