const coveragePhaseMap = {
  0: {
    key: 'DeductiblePrice',
    phaseKey: 'DeductiblePhase',
    name: 'Deductible',
  },
  1: {
    key: 'PreInitialCoveragePrice',
    phaseKey: 'PreInitialCoveragePhase',
    name: 'Pre Initial Coverage',
  },
  10: {
    key: 'InitialCoverageLevelPrice',
    phaseKey: 'InitialCoverageLevelPhase',
    name: 'Initial Coverage',
  },
  20: {
    key: 'CoverageGapPrice',
    phaseKey: 'CoverageGapPhase',
    name: 'Coverage Gap',
  },
  21: {
    key: 'PostInitialCoveragePrice',
    phaseKey: 'PostInitialCoveragePhase',
    name: 'Post Initial Coverage',
  },
  30: {
    key: 'CatastrophicCoveragePrice',
    phaseKey: 'CatastrophicCoveragePhase',
    name: 'Catastrophic',
  },
  100: {
    key: 'Unkown',
    name: 'Unknown',
  },
};

export default coveragePhaseMap;
