import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithTracker from '../common/WithTracker';

// Components
import RouterLogic from './RouterLogic';
import AddDrugsPage from './drug';
import AddPharmaciesPage from './pharmacy';
import ListRXPage from './list';

export default function () {
  return (
    <WithTracker>
      <Routes>
        <Route path="" element={<RouterLogic />} />
        <Route path="add/drug" element={<AddDrugsPage />} />
        <Route path="add/pharmacy" element={<AddPharmaciesPage />} />
        <Route path="list" element={<ListRXPage />} />
      </Routes>
    </WithTracker>
  );
}
