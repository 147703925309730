const initialState = {
  searchString: '',
  searchResults: [],
  awsConfig: {},
  profile: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_APPOINTMENTS':
      return { ...state, ...action.payload };
    case 'SET_APPOINTMENTS_PROFILE_KEYS':
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case 'CLEAR_APPOINTMENTS_PROFILE':
      return { ...state, profile: { ...initialState.profile } };
    case 'CLEAR_AGENT':
      return { ...initialState };
    default:
      return state;
  }
}
