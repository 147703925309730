import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

// Actions
import { updateDoctor } from '../../actions/doctor';

class MarkImportant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hover: false,
    };
  }

  handleChange = () => {
    const { loading } = this.state;
    const {
      dispatch,
      doctor,
      selected,
      username,
    } = this.props;
    if (loading) { return; }

    if (selected) {
      const invertImportant = !doctor.important;
      const updateDoc = doctor;
      updateDoc.important = invertImportant;

      // If user deselects doctor, don't keep hover ux
      if (!invertImportant) { this.setState({ hover: false }); }

      // Adds fa-spin to icon - an animation of 2s.
      // (Our star icon has 5 points it can stop at)
      this.setState({ loading: true });
      const loadStart = (new Date()).getMilliseconds();

      updateDoctor(doctor, username)
        .then(() => {
          dispatch({
            type: 'UPDATE_MEDCAB_DOCTOR',
            payload: { ...doctor, important: invertImportant },
          });

          const loadEnd = (new Date()).getMilliseconds();
          const msElapsed = loadEnd - loadStart;
          const msToSpinCompletion = 400 - (msElapsed % 400); // 2000 / 5 tips on a star

          setTimeout(() => {
            this.setState({ loading: false });
          }, msToSpinCompletion);
        }).catch(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { hover, loading } = this.state;
    const {
      doctor,
      hideText,
      iconClasses,
      selected,
      size,
      spanClasses,
    } = this.props;

    let classes = '';
    let starClass = '';
    if (selected && hover) {
      classes = '';
      starClass = 'hover';
    }
    if (selected && doctor.important) {
      classes = '';
      starClass = 'selected';
    }

    return (
      <span
        className={`${classes} ${spanClasses}`}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          cursor: selected && 'pointer',
          opacity: selected ? '1' : '0.25',
          userSelect: 'none',
        }}
      >
        {hideText ? '' : <span className="me-2">Mark as important</span>}
        <FontAwesomeIcon
          size={size}
          spin={loading}
          style={{
            marginTop: loading ? '1px' : '0px',
            marginRight: loading ? '1px' : '0px',
          }}
          icon={faStar}
          className={`doctor-important-star ${starClass} ${iconClasses}`}
          onClick={this.handleChange}
        />
      </span>
    );
  }
}

MarkImportant.defaultProps = {
  doctor: {},
  hideText: false,
  selected: false,
  size: 'lg',
  iconClasses: '',
  spanClasses: '',
};

function mapStateToProps(store) {
  return {
    doctorList: store.shopper.doctorList,
    username: store.shopper.username,
  };
}

export default connect(mapStateToProps)(MarkImportant);
