import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentExclamation } from '@fortawesome/pro-solid-svg-icons';

function ShareQuoteAlert() {
  return (
    <div
      className="d-flex align-items-center p-3 pe-5 mt-4 border border-warning-border bg-warning-bg shadow-sm r-1"
      style={{ width: 'fit-content' }}
    >
      <FontAwesomeIcon icon={faCommentExclamation} className="me-2 fs-3 o-2 text-warning-border" />
      Phone and Date of birth are required when sharing a quote with a client.
      They will use this information to verify their identity.
    </div>
  );
}

export default ShareQuoteAlert;
