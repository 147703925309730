import React from 'react';
import { connect } from 'react-redux';

// Actions
import { filterMedicare, filterMedigap } from '../../../../actions/planfilters';

// Components
import PlanComparisonTable from './PlanComparisonTable';

// Constants
/* eslint-disable no-unused-vars */
import { SUCCESS_STATE } from '../../../../constants';

function PlanTriTable({
  carrierFilters,
  carrierPerms,
  dispatch,
  loadedCarriers,
  medicareList,
  medicareListLoaded,
  showMedigapPlans,
  medigapList,
  medigapListLoaded,
  selectedPharmacy,
  selectFilter,
  soa,
  soaList,
  medigapFeature,
}) {
  let mapdPlan = {};
  let pdpPlan = {};
  let medigapPlan = {};

  let pdpSoaStatus = true;
  let mapdMaSoaStatus = true;
  if (soaList.length && soa) {
    if (soaList[0].submitted) {
      if (!soaList[0].mapd_ma) {
        mapdMaSoaStatus = false;
      }
      if (!soaList[0].pdp) {
        pdpSoaStatus = false;
      }
    }
  }

  const carrierFiltersObject = Object.keys(carrierFilters).filter((key) => carrierFilters[key]);
  const carrierFiltersForMedigap = carrierFiltersObject.map((carrier) => {
    if (carrier !== 'undefined') {
      return loadedCarriers.findIndex((loadedCarrier) => loadedCarrier === carrier);
    }
    return null;
  });

  // Columns [0: mapd, 1: pdp]
  if (medicareListLoaded === SUCCESS_STATE) {
    // Find recommended MAPD / MA plan
    const mapdList = filterMedicare(medicareList, {
      carrierFilters: carrierFiltersObject,
      npi: selectedPharmacy,
      planType: ['MAPD', 'MA'],
      carrierPerms,
      sortKey: 'totalAnnualOOPC',
    });
    const mapdListFiltered = mapdList.filter((plan) => !plan.plan.IsSpecialNeedPlan);
    mapdPlan = (mapdListFiltered.length ? mapdListFiltered[0] : {});
    if (mapdPlan) {
      dispatch({ type: 'SET_RECOMMENDED_MAPD', payload: mapdPlan.planID });
    }

    // Find recommended PDP plan
    const pdpList = filterMedicare(medicareList, {
      carrierFilters: carrierFiltersObject,
      npi: selectedPharmacy,
      planType: ['PDP'],
      carrierPerms,
      sortKey: 'totalAnnualOOPC',
    });
    pdpPlan = (pdpList.length ? pdpList[0] : {});
    if (pdpPlan) {
      dispatch({ type: 'SET_RECOMMENDED_PDP', payload: pdpPlan.planID });
    }
  }

  // Last column
  if (medigapListLoaded === SUCCESS_STATE && showMedigapPlans) {
    const medigapListFiltered = filterMedigap(medigapList, {
      carrierFilters: carrierFiltersForMedigap,
      select: selectFilter,
      carrierPerms,
    });
    medigapPlan = (medigapListFiltered.length ? medigapListFiltered[0] : {});
  }

  return (
    <PlanComparisonTable
      plans={[mapdPlan, pdpPlan]}
      pdpSoaStatus={pdpSoaStatus}
      mapdMaSoaStatus={mapdMaSoaStatus}
    />
  );
}


PlanTriTable.defaultProps = {
  doRecommendPlans: true,
};

const mapStateToProps = (state) => ({
  carrierFilters: state.plan.carrierFilters,
  carrierPerms: state.agent.carrierPermissions,
  currentPlanId: state.shopper.currentPlan,
  loadedCarriers: state.carriers,
  medicareList: state.plan.medicareList,
  showMedigapPlans: state.theme.features.medigap,
  medigapList: state.plan.medigapList,
  medicareListLoaded: state.loaders.medicareListLoaded,
  medigapListLoaded: state.loaders.medigapListLoaded,
  modals: state.theme.modals,
  selectedPharmacy: state.pharmacy.selectedPharmacy,
  soa: state.theme.features.soa,
  soaList: state.shopper.soaList,
  medigapFeature: state.theme.features.medigap,
});

export default connect(mapStateToProps)(PlanTriTable);
