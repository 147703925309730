const SHOPPER_CSV_SCHEMA = [
  {
    header: 'Client ID',
    path: 'lead_id',
    format: 'str',
  },
  {
    header: 'First Name',
    path: 'first_name',
    format: 'str',
  },
  {
    header: 'Middle Name',
    path: 'middle_name',
    format: 'str',
  },
  {
    header: 'Last Name',
    path: 'last_name',
    format: 'str',
  },
  {
    header: 'Address',
    path: 'address',
    format: 'str',
  },
  {
    header: 'Address Line 2',
    path: 'address_2',
    format: 'str',
  },
  {
    header: 'DOB',
    path: 'birthdate',
    format: 'date',
  },
  {
    header: 'City',
    path: 'city',
    format: 'str',
  },
  {
    header: 'Contact Email',
    path: 'contact_email',
    format: 'str',
  },
  {
    header: 'County FIPS',
    path: 'county',
    format: 'str',
  },
  {
    header: 'County Name',
    path: 'county_name',
    format: 'str',
  },
  {
    header: 'Created Date',
    path: 'created',
    format: 'date',
  },
  {
    header: 'Gender',
    path: 'gender',
    format: 'str',
  },
  {
    header: 'Home Phone',
    path: 'home_phone',
    format: 'str',
  },
  {
    header: 'Mobile Phone',
    path: 'mobile_phone',
    format: 'str',
  },
  {
    header: 'Disposition',
    path: 'lead_status',
    format: 'lead_status',
  },
  {
    header: 'Medicare ID',
    path: 'medicare_id',
    format: 'str',
  },
  {
    header: 'Notes',
    path: 'notes',
    format: 'notes',
  },
  {
    header: 'Pharmacy Address',
    path: 'pharmacy.address_1',
    format: 'str',
  },
  {
    header: 'Pharmacy Address Line 2',
    path: 'pharmacy.address_2',
    format: 'str',
  },
  {
    header: 'Pharmacy City',
    path: 'pharmacy.city',
    format: 'str',
  },
  {
    header: 'Pharmacy Name',
    path: 'pharmacy.name',
    format: 'str',
  },
  {
    header: 'Pharmacy State',
    path: 'pharmacy.state',
    format: 'str',
  },
  {
    header: 'Pharmacy Zip',
    path: 'pharmacy.zip',
    format: 'str',
  },
  {
    header: 'State',
    path: 'state',
    format: 'str.caps',
  },
  {
    header: 'Subsidy Level',
    path: 'subsidy_question',
    format: 'subsidy',
  },
  {
    header: 'Zipcode',
    path: 'zipcode',
    format: 'str',
  },
];

const DRUG_CSV_SCHEMA = [
  {
    header: 'Client ID',
    path: 'shopper.lead_id',
    format: 'str',
  },
  {
    header: 'Client First Name',
    path: 'shopper.first_name',
    format: 'str',
  },
  {
    header: 'Client Last Name',
    path: 'shopper.last_name',
    format: 'str',
  },
  {
    header: 'Name',
    path: 'name',
    format: 'str',
  },
  {
    header: 'Dosage',
    path: 'dosage',
    format: 'str',
  },
  {
    header: 'Package',
    path: 'package_description',
    format: 'str',
  },
  {
    header: 'Quantity',
    path: 'shopper_quantity',
    format: 'str',
  },
  {
    header: 'Frequency',
    path: 'frequency',
    format: 'str',
  },
  {
    header: 'NDC',
    path: 'ndc',
    format: 'str',
  },
  {
    header: 'Brand or Generic',
    path: 'drug_type',
    format: 'str',
  },
  {
    header: 'Saved in system',
    path: 'created',
    format: 'date',
  },
];

const PROVIDER_CSV_SCHEMA = [
  {
    header: 'Client ID',
    path: 'shopper.lead_id',
    format: 'str',
  },
  {
    header: 'Client First Name',
    path: 'shopper.first_name',
    format: 'str',
  },
  {
    header: 'Client Last Name',
    path: 'shopper.last_name',
    format: 'str',
  },
  {
    header: 'Name',
    path: 'name',
    format: 'str',
  },
  {
    header: 'Phone',
    path: 'phone',
    format: 'str',
  },
  {
    header: 'Specialties',
    path: 'specialties',
    format: 'specialties',
  },
  {
    header: 'Provider Type',
    path: 'provider_type',
    format: 'str',
  },
  {
    header: 'NPI',
    path: 'npi',
    format: 'str',
  },
  {
    header: 'Address',
    path: 'address',
    format: 'str',
  },
  {
    header: 'Address Line 2',
    path: 'address2',
    format: 'str',
  },
  {
    header: 'City',
    path: 'city',
    format: 'str',
  },
  {
    header: 'State',
    path: 'state',
    format: 'str',
  },
  {
    header: 'Zipcode',
    path: 'zipcode',
    format: 'str',
  },
  {
    header: 'Saved to client',
    path: 'created',
    format: 'date',
  },
];

const ENROLLMENT_CSV_SCHEMA = [
  {
    header: 'Client ID',
    path: 'quote_id',
    format: 'lead_id_from_quote_id',
  },
  {
    header: 'Client First Name',
    path: 'fields.first_name',
    format: 'str',
  },
  {
    header: 'Client Last Name',
    path: 'fields.last_name',
    format: 'str',
  },
  {
    header: 'Carrier',
    path: 'carrier',
    format: 'str',
  },
  {
    header: 'Confirmation Number',
    path: 'confirmation_number',
    format: 'str',
  },
  {
    header: 'Submit Date',
    path: 'submit_date',
    format: 'date',
  },
  {
    header: 'Medicare ID',
    path: 'fields.medicare_id',
    format: 'str',
  },
  {
    header: 'Plan ID',
    path: 'fields.quote.cpsID',
    format: 'str',
  },
  {
    header: 'Plan Name',
    path: 'fields.quote.planName',
    format: 'str',
  },
  {
    header: 'Plan Type',
    path: 'fields.quote.planType',
    format: 'str',
  },
  {
    header: 'Plan Year',
    path: 'fields.quote.planYear',
    format: 'str',
  },
  {
    header: 'Birthdate',
    path: 'fields.birthdate',
    format: 'date',
  },
  {
    header: 'Address',
    path: 'fields.street1',
    format: 'str',
  },
  {
    header: 'Address Line 2',
    path: 'fields.street2',
    format: 'str',
  },
  {
    header: 'City',
    path: 'fields.city',
    format: 'str',
  },
  {
    header: 'State',
    path: 'fields.state',
    format: 'str',
  },
  {
    header: 'Zipcode',
    path: 'fields.zipcode',
    format: 'str',
  },
  {
    header: 'Mailing Address',
    path: 'fields.mailstreet1',
    format: 'str',
  },
  {
    header: 'Mailing Address Line 2',
    path: 'fields.mailstreet2',
    format: 'str',
  },
  {
    header: 'Mailing City',
    path: 'fields.mailcity',
    format: 'str',
  },
  {
    header: 'Mailing State',
    path: 'fields.mailstate',
    format: 'str',
  },
  {
    header: 'Mailing Zipcode',
    path: 'fields.mailzip',
    format: 'str',
  },
  {
    header: 'PCP Name',
    path: 'fields.pcp_name',
    format: 'str',
  },
  {
    header: 'PCP Address',
    path: 'fields.pcp_street1',
    format: 'str',
  },
  {
    header: 'PCP City',
    path: 'fields.pcp_city',
    format: 'str',
  },
  {
    header: 'PCP State',
    path: 'fields.pcp_state',
    format: 'str',
  },
  {
    header: 'PCP Zip',
    path: 'fields.pcp_zip',
    format: 'str',
  },
  {
    header: 'Election Period',
    path: 'fields.enrolltype',
    format: 'str',
  },
  {
    header: 'SEP Code',
    path: 'fields.sep_code',
    format: 'str',
  },
  {
    header: 'SEP Date',
    path: 'fields.sep_date',
    format: 'date',
  },
  {
    header: 'SEP Reason',
    path: 'fields.sep_other',
    format: 'str',
  },
  {
    header: 'Effective Date',
    path: 'fields.quote.effectiveDate',
    format: 'date',
  },
  {
    header: 'Home Phone',
    path: 'fields.home_phone',
    format: 'str',
  },
  {
    header: 'Gender',
    path: 'fields.gender',
    format: 'str',
  },
  {
    header: 'Race',
    path: 'fields.race',
    format: 'race',
  },
  {
    header: 'Ethnicity',
    path: 'fields.ethnicity',
    format: 'ethnicity',
  },
  {
    header: 'Part A Effective Date',
    path: 'fields.part_a_effective_date',
    format: 'str',
  },
  {
    header: 'Part B Effective Date',
    path: 'fields.part_b_effective_date',
    format: 'str',
  },
  {
    header: 'POA First Name',
    path: 'fields.poa_fname',
    format: 'str',
  },
  {
    header: 'POA Last Name',
    path: 'fields.poa_lname',
    format: 'str',
  },
  {
    header: 'POA Relationship to Applicant',
    path: 'fields.rel',
    format: 'str',
  },
  {
    header: 'POA Address',
    path: 'fields.poa_addr_street1',
    format: 'str',
  },
  {
    header: 'POA Address Line 2',
    path: 'fields.poa_addr_street2',
    format: 'str',
  },
  {
    header: 'POA City',
    path: 'fields.poa_addr_city',
    format: 'str',
  },
  {
    header: 'POA State',
    path: 'fields.poa_addr_state',
    format: 'str',
  },
  {
    header: 'POA Zipcode',
    path: 'fields.poa_addr_zip',
    format: 'str',
  },
  {
    header: 'POA Phone Number',
    path: 'fields.poa_tel',
    format: 'str',
  },
];

export {
  SHOPPER_CSV_SCHEMA,
  DRUG_CSV_SCHEMA,
  PROVIDER_CSV_SCHEMA,
  ENROLLMENT_CSV_SCHEMA,
};
