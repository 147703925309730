import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

// Actions + Utils
import { fmtDocSpecialties } from '../../../utils/formatFunctions';
// Components
import MarkImportant from '../MarkImportant';
import CareHighlightsIcons from '../CareHighlightsIcons';

class TableRow extends React.PureComponent {
  render() {
    const {
      dispatch,
      doctor,
      careHighlights,
    } = this.props;

    return (
      <tr className="td-v-align-center">
        <td>{doctor.name}</td>
        <td>{fmtDocSpecialties(doctor.specialties)}</td>
        <td>{doctor.address}, {doctor.city}, {doctor.state} {doctor.zipcode}</td>
        { careHighlights ? <td><CareHighlightsIcons text={doctor.effectiveness} /></td> : null }
        { careHighlights ? <td><CareHighlightsIcons text={doctor.efficiency} type="award" /></td> : null }
        <td style={{ verticalAlign: 'middle', width: '80px' }}>
          <MarkImportant
            doctor={doctor}
            hideText
            selected
            spanClasses="me-2"
          />
          <FontAwesomeIcon
            role="button"
            tabIndex={0}
            icon={faTimes}
            size="lg"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch({ type: 'SHOW_MODAL', payload: 'deleteDoctorModal' });
              dispatch({ type: 'SET_DELETE_DOCTOR', payload: doctor });
            }}
          />
        </td>
      </tr>
    );
  }
}

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
    careHighlights: store.theme.features.careHighlights,
  };
}

export default connect(mapStateToProps)(TableRow);
