import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronCircleRight, faSyncAlt } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';

import { loadThreeWayCalls } from '../../actions/three_way';
import { formatToPhone, formatPhoneToNum } from '../../utils/general';

import DatePicker from './DatePicker';

function TableFilter() {
  const dispatch = useDispatch();
  const [callFilterOpen, setCallFilterOpen] = useState(false);
  const {
    loading,
    stCallID,
    stClientFirstName,
    stClientLastName,
    stClientPhone,
  } = useSelector(store => store.threeWay);

  function handleSearchTermChange({ target }) {
    const { name, value } = target;
    dispatch({ type: 'SET_SEARCH_TERM', name, value });
  }

  function handlePhoneChange(e) {
    const { value } = e.target;
    let unformatted = formatPhoneToNum(value);
    // NOTE(santeyio): this is jenky... I'm pretty tired and I'm not sure
    // that this is the best way to fix this?
    const re = /^\([0-9]{1,3}$/g;
    if (re.test(value)) {
      if (value.length === 4) unformatted = value.slice(1, 3);
      if (value.length === 3) unformatted = value.slice(1, 2);
      if (value.length === 2) unformatted = value.slice(1, 1);
    }
    dispatch({ type: 'SET_SEARCH_TERM', name: 'stClientPhone', value: unformatted });
  }

  function handleSubmit(event) {
    dispatch({ type: 'SET_THREE_WAY_LOADING', payload: true });
    event.preventDefault();
    loadThreeWayCalls()
      .then(config => {
        dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      }).catch(() => {
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      });
  }

  async function handleSearchClear() {
    dispatch({ type: 'SET_THREE_WAY_LOADING', payload: true });
    dispatch({ type: 'CLEAR_CALL_DATA' });
    await dispatch({ type: 'CLEAR_SEARCH_TERM' });
    loadThreeWayCalls()
      .then(config => {
        dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      }).catch(() => {
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      });
  }

  function handleRefresh() {
    dispatch({ type: 'CLEAR_CALL_DATA' });
    dispatch({ type: 'SET_THREE_WAY_LOADING', payload: true });
    loadThreeWayCalls().then(config => {
      dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
      dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
    });
  }

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="px-4 py-2 record-filters"
    >
      <div className="row">
        <div className="col-9">
          <div className="p-2 d-flex">
            <button type="button" className="btn btn-link" onClick={() => setCallFilterOpen(!callFilterOpen)}>
              <FontAwesomeIcon
                fixedWidth
                icon={faChevronCircleRight}
                rotation={callFilterOpen ? 90 : null}
                size="lg"
                className="me-1"
                style={{ transition: 'all .3s ease' }}
              />
              Filter Calls
            </button>
            <button
              type="button"
              className="btn btn-white ms-2"
              onClick={() => handleSearchClear()}
              style={{
                transition: 'all .3s ease-in-out',
                opacity: callFilterOpen ? '1' : '0',
              }}
              disabled={loading}
            >
              Clear Filters
            </button>
            <button
              type="submit"
              className="btn-primary btn text-white ms-2"
              style={{
                transition: 'all .3s ease-in-out',
                opacity: callFilterOpen ? '1' : '0',
              }}
              disabled={loading}
            >
              Search
            </button>
            <button type="button" color="white" className="btn" style={{ right: '1.5rem', position: 'absolute' }} onClick={() => handleRefresh()}>
              <FontAwesomeIcon
                fixedWidth
                icon={faSyncAlt}
                spin={loading}
                size="lg"
              />
            </button>
          </div>
        </div>
      </div>
      <Collapse
        isOpen={callFilterOpen}
        style={{
          transition: 'all .3s ease-in-out',
          opacity: callFilterOpen ? '1' : '0',
        }}
      >
        <div className="row">
          <div className="col-3 px-0">
            <div className="card border-0" style={{ backgroundColor: 'inherit' }}>
              <div className="card-body">
                <label className="form-label" htmlFor="stCallDateRange">Call Date Range</label>
                <DatePicker
                  id="stCallDateRange"
                  startProp="stCallStartDate"
                  endProp="stCallEndDate"
                />
                <label className="form-label mt-3" htmlFor="stID">Call ID</label>
                <input
                  className="form-control form-control"
                  type="text"
                  id="stCallID"
                  name="stCallID"
                  value={stCallID}
                  onChange={(e) => handleSearchTermChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-3 px-0">
            <div className="card border-0" style={{ backgroundColor: 'inherit' }}>
              <div className="card-body">
                <label className="form-label" htmlFor="stClientLastName">Client Last Name</label>
                <input
                  className="form-control form-control"
                  type="text"
                  name="stClientLastName"
                  id="stClientLastName"
                  value={stClientLastName}
                  onChange={(e) => handleSearchTermChange(e)}
                />
                <label className="form-label mt-3" htmlFor="stClientFirstName">Client First Name</label>
                <input
                  type="text"
                  className="form-control form-control"
                  name="stClientFirstName"
                  id="stClientFirstName"
                  value={stClientFirstName}
                  onChange={(e) => handleSearchTermChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-3 px-0">
            <div className="card border-0" style={{ backgroundColor: 'inherit' }}>
              <div className="card-body">
                <label className="form-label" htmlFor="stClientPhone">Client Phone Number</label>
                <input
                  type="text"
                  className="form-control form-control"
                  name="stClientPhone"
                  id="stClientPhone"
                  value={formatToPhone(stClientPhone) || ''}
                  onChange={(e) => handlePhoneChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </form>
  );
}

export default TableFilter;
