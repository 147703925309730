/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* Allow us to have conditionally-enabled onClicks (pharmacy/doctors) */
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import DoctorsList from './DoctorsList';

function DoctorsContainer({ plan }) {
  const { doctors: doctorsFeature } = useSelector(store => store.theme.features);

  return (
    <div
      className="d-flex flex-column border-end border-darker-grey fs-5"
      style={{ width: '22%', lineHeight: '20px' }}
    >
      <div className="d-flex flex-column p-4">
        <div className="position-relative">
          {(plan.planType !== 'PDP' && doctorsFeature)
            ? (
              <DoctorsList
                plan={plan}
                spanClassName="fs-3 text-blue text-decoration-underline"
                style={{ flexDirection: 'column', display: 'flex' }}
                showHighlightBorder={false}
              />
            )
            : null}
        </div>
      </div>
    </div>
  );
}

export default DoctorsContainer;
