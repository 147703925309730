import React from 'react';
import FormInput from '../../common/FormInput';
import FormInputPhone from '../../common/FormInputPhone';

const SendSMS = ({
  handleChange, handleBlur, errors, values, dateOfAppointment = false,
}) => (
  <div>
    <FormInputPhone
      controlId="method_sms_phone"
      label="Phone number"
      placeholder="(123) 123 - 1234"
      value={values.send_sms_phone}
      name="send_sms_phone"
      type="phone"
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={!!errors.send_sms_phone}
      error={errors.send_sms_phone}
      required
    />
    {dateOfAppointment && (
      <FormInput
        controlId="method_date_of_appointment"
        label="Date of appointment"
        placeholder=""
        value={values.date_of_appointment}
        name="date_of_appointment"
        onChange={handleChange}
        onBlur={handleBlur}
        type="date"
        invalid={!!errors.date_of_appointment}
        error={errors.date_of_appointment}
        required
      />
    )}
    <FormInput
      controlId="send_sms_greeting"
      label="Greeting"
      placeholder="Greeting"
      value={values.send_sms_greeting}
      name="send_sms_greeting"
      type="textarea"
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={!!errors.send_sms_greeting}
      error={errors.send_sms_greeting}
      inputClassName="greeting-color"
    />
  </div>
);

export default SendSMS;
