/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { SUCCESS_STATE, AWAIT_STATE } from '../../constants';
import { withRouterProps } from '../../utils/hocs';

// Actions
import { loadNotifications } from '../../actions/notifications';
import { loadShoppers, getShopperCount } from '../../actions/shopper';

// Components
import EditNotificationModal from '../modal/notifications/EditNotificationModal';
import { Highlight } from '../../guide';
import NavPage from '../nav/NavPage';
import ShopperListContainer from './ShopperListContainer';
import MmbDropdown from '../common/Dropdown';
import AppointmentsProfileAlert from './AppointmentsProfileAlert';
import ExportDataAlert from './ExportDataAlert';

const Dashboard = ({
  navigate,
  totalItemsCount, shoppersLoaded, selectedTab, shopper,
  agentNotifications, dispatch, shopperDataLoaded, isNewShopper,
  agentEmail, passwordExpired, passwordResetModal,
  firstNameFilter, leadIDFilter, leadTypeFilter, showAllQuotes, stateFilter,
  categories = [],
}) => {
  const clientOptions = useMemo(
    () => categories.map((option) => ({
      ...option,
      text: `${option.name} (${option.count})`,
    })),
    [categories],
  );

  useEffect(() => {
    if (agentNotifications) {
      loadNotifications();
    }
    if (shopperDataLoaded === SUCCESS_STATE && !isNewShopper && shopper.county && shopper.city) {
      dispatch({ type: 'SHOW_MODAL', payload: 'exitModal' });
    }
    getShopperCount();
    loadShoppers({
      new_load: true,
      lead_category: selectedTab,
      user_who_created: agentEmail,
    });
  }, []);

  useEffect(() => {
    // When an agent has a tmp pwd set, they are shown this modal. However, they can refresh to exit out of that flow.
    // We watch the modal flag itself to ensure it will never be closed while agent.passwordExpired.
    if (passwordExpired) {
      dispatch({ type: 'SHOW_MODAL', payload: 'passwordResetModal' });
    }
  }, [passwordExpired, passwordResetModal]);


  const handleChangeClientType = (payload) => {
    dispatch({ type: 'SET_SELECTED_TAB', payload });

    dispatch({ type: 'CLEAR_SEARCH_TERMS' });
    dispatch({ type: 'CLEAR_SORT_INVERSE' });
    dispatch({ type: 'CLEAR_SORT_TERM' });

    loadShoppers({
      name: firstNameFilter || null,
      new_load: true,
      lead_id: leadIDFilter || null,
      lead_category: payload,
      lead_type: leadTypeFilter || null,
      show_all_quotes: showAllQuotes,
      state: stateFilter || null,
      user_who_created: agentEmail,
    });
  };

  const handleRoute = (route) => {
    dispatch({ type: 'CLEAR_SHOPPER' });
    dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: SUCCESS_STATE });
    navigate(route);
  };

  return (
    <NavPage
      activeLink="/dashboard"
    >
      <div className="dashboard">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-4 gap-2 px-3 px-sm-0">
          <div className="d-flex align-items-center">
            <div className="dashboard-header me-4">
              Clients
            </div>
            <MmbDropdown
              header="FILTER BY STATUS"
              options={clientOptions}
              optionKey="name"
              value={selectedTab}
              onChange={(e) => handleChangeClientType(e)}
              disabled={shoppersLoaded === AWAIT_STATE}
            />
            <AppointmentsProfileAlert className="ms-5" />
            <ExportDataAlert className="ms-5" />
          </div>
          <Highlight
            className="dashboard-add-client"
            id="dash"
            i={2}
            side="bl"
          >
            <button
              className="btn btn-primary dashboard-add-client-button"
              id="dashboard-button-add-client"
              type="button"
              onClick={() => handleRoute('/shopper')}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              &nbsp;
              Add client
            </button>
          </Highlight>
        </div>
        <ShopperListContainer activeLink="/dashboard" />
      </div>
      <EditNotificationModal />
    </NavPage>
  );
};


const mapStateToProps = (store) => ({
  agentEmail: store.agent.email,
  passwordExpired: store.agent.passwordExpired,
  firstNameFilter: store.shopperlist.firstNameFilter,
  leadIDFilter: store.shopperlist.leadIDFilter,
  leadTypeFilter: store.shopperlist.leadTypeFilter,
  showAllQuotes: store.shopperlist.showAllQuotes,
  stateFilter: store.shopperlist.stateFilter,
  agentNotifications: store.theme.features.agentNotifications,
  dashboardTabs: store.shopperlist.dashboardTabs,
  selectedTab: store.shopperlist.selectedTab,
  shopperDataLoaded: store.loaders.shopperDataLoaded,
  isNewShopper: !store.shopper.username,
  shoppersLoaded: store.loaders.shoppersLoaded,
  totalItemsCount: store.shopperlist.totalItemsCount,
  categories: store.shopperlist.categories,
  shopper: store.shopper,
  passwordResetModal: store.modal.passwordResetModal,
});

export default connect(mapStateToProps)(withRouterProps(Dashboard));
