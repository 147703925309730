/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function getZipsForPoint(lat, lon, radius) {
  const token = localStorage.getItem('SessionToken');
  const params = { lat, lon, radius };
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/geographics/zips-in-area/`,
    headers: { Authorization: `Token ${token}` },
    params,
  });
}
