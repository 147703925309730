const initialState = {
  agentLastUpdate: null,
  expiredModalIsOpen: false,
  releaseNotesOpen: false,
  releaseNotesStale: false,
  tokenLastUpdate: null,

  // Client versioning
  latestVersion: null,
  version: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_RELEASE_NOTES':
      return { ...state, releaseNotesOpen: true };
    case 'HIDE_RELEASE_NOTES':
      return { ...state, releaseNotesOpen: false };
    case 'REFRESH_RELEASE_NOTES':
      return { ...state, releaseNotesStale: false };
    case 'EXPIRE_RELEASE_NOTES':
      return { ...state, releaseNotesStale: true };
    case 'SET_AGENT_LAST_UPDATE':
      return { ...state, agentLastUpdate: action.payload };
    case 'SET_TOKEN_LAST_UPDATE':
      return { ...state, tokenLastUpdate: action.payload };

    case 'UPDATE_LATEST_VERSION':
      return { ...state, latestVersion: action.payload };
    case 'UPDATE_LOCAL_VERSION':
      return { ...state, version: state.latestVersion };

    case 'SHOW_SESSION_EXPIRED_MODAL':
      return { ...state, expiredModalIsOpen: true };
    case 'HIDE_SESSION_EXPIRED_MODAL':
      return { ...state, expiredModalIsOpen: false };
    default:
      return state;
  }
}
