import axios from 'axios';
import { store } from '../store';
import {
  DEFAULT_STATE,
  SUCCESS_STATE,
} from '../constants';

export function drugSearch(searchString) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/drug/${searchString}/10/`,
    })
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      });
  });
}

export function getDirectGenericDosage(drugID, dosageID) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/drug/dosage/${drugID}/${dosageID}/`,
    })
      .then((res) => {
        const dosage = res.data;
        store.dispatch({ type: 'SET_SELECTED_DOSAGE_GENERIC', payload: dosage });
        resolve(dosage);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getDrugDetails(drugID) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/drug/${drugID}/`,
    })
      .then((res) => {
        const drugDetails = res.data;
        resolve(drugDetails);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getPackages(drugID, dosageID, selectedPackageID = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/drug/dosage/${drugID}/${dosageID}/package/`,
    })
      .then((res) => {
        const packages = res.data;
        store.dispatch({ type: 'SET_PACKAGES', payload: packages });
        let selectedPackage = null;
        packages.forEach((pckg) => {
          // if func param selectedDosageID is set use that
          if (selectedPackageID) {
            if (pckg.PackageID === selectedPackageID) { selectedPackage = pckg; }
          // else look for IsDefaultDosage to set for selected package
          } else {
            if (pckg.IsDefaultPackage) { selectedPackage = pckg; } // eslint-disable-line
          }
        });
        if (selectedPackage === null && Array.isArray(packages) && packages.length > 0) {
          [selectedPackage] = packages;
        }
        store.dispatch({ type: 'SET_SELECTED_PACKAGE', payload: selectedPackage });
        resolve(packages);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getDosages(drugID, selectedDosageID = null, selectedPackageID = null) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/drug/dosage/${drugID}/`,
    })
      .then((res) => {
        const dosages = res.data;
        store.dispatch({ type: 'SET_DOSAGES', payload: dosages });
        let selectedDosage = null;
        dosages.forEach((dosage) => {
          // if func param selectedDosageID is set use that
          if (selectedDosageID) {
            if (dosage.DosageID === selectedDosageID) { selectedDosage = dosage; }
          // else look for IsDefaultDosage to set for selected dosage
          } else {
            if (dosage.IsDefaultDosage) { selectedDosage = dosage; } // eslint-disable-line
          }
        });
        // if no default dosage is set, set it to first dosage
        if (selectedDosage === null && Array.isArray(dosages) && dosages.length > 0) {
          [selectedDosage] = dosages;
        }
        store.dispatch({ type: 'SET_SELECTED_DOSAGE', payload: selectedDosage });
        if (selectedDosage.ContainPackage) {
          getPackages(drugID, selectedDosage.DosageID, selectedPackageID);
        }
        if (selectedDosage.IsDirectGenericAvailable) {
          getDirectGenericDosage(drugID, selectedDosage.RelatedDosageID)
            .then((d) => getDrugDetails(d.DrugID)
              .then((dd) => {
                store.dispatch({ type: 'SET_GENERIC_DRUG', payload: dd });
                store.dispatch({ type: 'SET_GENERIC_LOADED_STATE', payload: SUCCESS_STATE });
              }));
        // if there is no generic, reset generic loading state
        } else {
          store.dispatch({ type: 'SET_GENERIC_LOADED_STATE', payload: DEFAULT_STATE });
        }
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function saveDrug(drug, username, quickQuote = false, editDrug = false) {
  const token = window.localStorage.getItem('SessionToken');
  const refmDrug = {
    drug_id: drug.drugID,
    name: drug.name,
    dosage: drug.dosage,
    dosage_id: drug.dosageID,
    quantity: drug.quantity,
    shopper_quantity: drug.userQuantity,
    frequency: drug.frequency,
    ndc: drug.ndc,
    package_description: drug.packageDescription,
    package_id: drug.packageID,
    drug_type: drug.drugType,
  };
  return new Promise((resolve, reject) => {
    const url = (
      editDrug
        ? `${process.env.REACT_APP_MMB_API_URL}/api/agent/drug/${drug.id}/`
        : `${process.env.REACT_APP_MMB_API_URL}/api/agent/drug/`
    );
    if (!username && quickQuote) {
      resolve(refmDrug);
    } else {
      axios({
        method: (editDrug ? 'PUT' : 'POST'),
        url,
        headers: { Authorization: `Token ${token}` },
        params: { shopper: username },
        data: refmDrug,
      })
        .then((res) => {
          resolve(res.data);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

export function saveQuickQuoteDrugs(drugList, username) {
  const promiseArray = [];

  drugList.forEach((drug) => {
    promiseArray.push(saveDrug(drug, username));
  });

  return Promise.all(promiseArray).then((values) => {
    const updatedList = [...drugList];

    for (let i = 0; i < values.length; i += 1) {
      updatedList[i].id = values[i].id;
    }

    store.dispatch({ type: 'SET_SHOPPER_DRUGS', payload: updatedList });
  });
}

export function deleteDrug(id, username, quickQuote = false) {
  const token = window.localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    if (!username && quickQuote) {
      resolve();
    } else {
      axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/drug/${id}/`,
        headers: { Authorization: `Token ${token}` },
        params: { shopper: username },
      })
        .then((res) => {
          resolve(res.data);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

function mapShopperDrugMetaData(drugData) {
  const returnedDrugData = drugData.map((drug) => ({
    id: drug.id,
    site: drug.site,
    shopper: drug.shopper,
    drugID: drug.drug_id,
    name: drug.name,
    dosage: drug.dosage,
    dosageID: drug.dosage_id,
    quantity: drug.quantity,
    userQuantity: drug.shopper_quantity,
    frequency: drug.frequency,
    pharmacyType: drug.pharmacy_type,
    ndc: drug.ndc,
    packageDescription: drug.package_description,
    packageID: drug.package_id,
    current: drug.current,
    dateStart: drug.date_start,
    dateEnd: drug.date_end,
    drugType: drug.drug_type,
  }));
  return returnedDrugData;
}

export function loadDrugs(username) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/drug/`,
      headers: { Authorization: `Token ${token}` },
      params: { shopper: username },
    }).then((res) => {
      const withMetaData = mapShopperDrugMetaData(res.data);
      resolve(withMetaData);
    }).catch((err) => {
      reject(err);
    });
  });
}
