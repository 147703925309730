/* eslint-disable */
const data = {
  "form": [
    {
      "key": "ok_to_record",
      "text": ["1.1: My name is {agent_name}, a licensed sales agent. We are an insurance agency, and are not affiliated with the federal government. Also, I need to let you know that we do record all of our calls for quality and accuracy. Please say YES if I have your permission to continue."],
      "instructions": "Must obtain a clear YES, or explain to the consumer that you cannot continue with call.",
      "fields": [
        {
          "label": "Permission given to record and continue",
          "name": "record_ok",
          "type": "checkbox",
          "validationRegex": "^true$",
          "required": true
        }
      ]
    },
    {
      "key": "document_name",
      "text": ["1.2: Thank you, who do I have the pleasure of speaking with today?"],
      "instructions": "Document First and Last Lead Name",
      "fields": [
        {
          "label": "First name",
          "name": "first_name",
          "type": "text",
          "validationRegex": "^[\\w ]{1,50}$",
          "required": true
        },
        {
          "label": "Last name",
          "name": "last_name",
          "type": "text",
          "validationRegex": "^[\\w ]{1,50}$",
          "required": true
        }
      ]
    },
    {
      "key": "document_phone",
      "text": ["1.3: Ok {shopper_name}, and in the event we get disconnected or I would need to contact you again, what is a good phone number to reach you at?"],
      "instructions": "Document phone number",
      "fields": [
        {
          "label": "Phone number",
          "name": "phone",
          "type": "phone",
          "validationRegex": "^[0-9]{10}$",
          "required": true
        }
      ],
    },
    {
      "key": "ok_to_contact",
      "text": ["1.4: Great. If you need to contact me I can be reached at {agent_phone}. And, do you give permission for YourMedicare Agency and its employees to contact you at the number provided?"],
      "fields": [
        {
          "label": "Permission given to be contracted at the provided number",
          "name": "ok_contact",
          "type": "checkbox",
          "validationRegex": "^true$",
          "required": true
        }
      ],
    },
    {
      "key": "agree_hear",
      "text": ["In your area, we have a wide variety of plans. I’d like to give you a brief overview of each of these plans, then we can decide which plan might be best for you based on your needs. Would that be ok?"],
      "instructions": "An affirmative response is required). Inform caller of available plans in the area (mention ALL that are available)",
      "fields": [
        {
          "label": "Permission given to hear overview of available plans",
          "name": "agree_hear",
          "type": "checkbox",
          "validationRegex": "^true$",
          "required": true
        }
      ],
    },
    {
      "key": "date_of_appointment",
      "text": ["We may discuss several types of health plan options during our appointment, including Medicare Advantage, Medicare Supplement, Prescription Drug Plans, Hospital Indemnity and Dental-Vision-Hearing plans. This will help us find a plan that may fit your specific needs and budget. I need to verify some additional information and read some required disclaimers, and then I’ll be able to provide you a health plan comparison. Please say yes, if I have your permission to discuss such plans with you during our appointment, {date_of_appointment} (require “Yes”)"],
      "fields": [
        {
          "label": "Date of appointment",
          "name": "date_of_appointment",
          "type": "date",
          "required": true
        },
        {
          "label": "Permission given to hear overview of available plans",
          "name": "agree_discuss_plan",
          "type": "checkbox",
          "required": true,
          "validationRegex": "^true$",
          "disabled": true
        }
      ],
    },
    {
      "key": "document_email",
      "text": ["2.2: Please be aware that you are not required to give any health-related information unless it will be used to determine your enrollment eligibility in the plan. If you choose not to provide the health information that is necessary to determine enrollment eligibility, then you may not be able to enroll. There is no obligation to enroll. Your current or future Medicare enrollment status will not be impacted and automatic enrollment will not occur.", "2.3: Please note that we do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. You can go to Medicare.gov or call 1 - 800 MEDICARE to get information on all of your options.", "2.4: As an OPTION, if you prefer to communicate via email and would like to provide it for me today, I will add it to your profile. By opting in your email, you agree to receive communications for quotes, virtual enrollment options, enrollment status and future potential changes in Medicare, to name a few. If you agree, I will add your email to your profile now. Please note, you are not required to add your email and it is not required for me to continue providing help today."],
      "fields": [
        {
          "label": "Email",
          "name": "email",
          "type": "text",
          "validationRegex": "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
        }
      ],
    },
    {
      "key": "poa_question",
      "text": ["2.5: And lastly {shopper_name}, do you make your own healthcare decisions? IF NO: Ok {shopper_name}. I can still provide plan information today, but would need to have a POA or authorized representative/legally recognized by the state on the phone, if you decide to enroll."]
    }

  ],
};

export default data;
