/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import {
  FAIL_STATE,
  AWAIT_STATE,
  DEFAULT_STATE,
  SUCCESS_STATE,
} from '../../../constants';
import { withRouterProps } from '../../../utils/hocs';
import { getParam } from '../../../utils/qs';
import { getPlanCarrierDisplay } from '../../../utils/general';
import { showPopup } from '../../../utils/popup';
import { renderDisplayCPS } from '../../../utils/plans';

// Actions
import { loadMedicareDetails, getStandalonePlan } from '../../../actions/plan';
import { getMedicarePlanByID } from '../../../actions/planfilters';

// Components
import NavPage from '../../nav/NavPage';
import CompareFooter from '../compare/CompareFooter';
import PricingDisclaimer from '../../common/PricingDisclaimer';
import DsnpDisclaimer from '../../common/DsnpDisclaimer';
import EnrollmentDisclaimer from '../../common/EnrollmentDisclaimer';
import LoaderComponent from '../../common/LoaderComponent';
import SOABadge from '../../common/SOABadge';
import QuoteEnrollButton from '../../common/QuoteEnrollButton';

// Tabs
import Benefits from './benefits';
import EstimatedCosts from './estimatedcosts';
import CiDetail from './ci';
import PlanDocuments from './PlanDocuments';

class PlanDetailsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.selectedTab,
      standalonePlan: false,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    const { standalone } = this.props;
    if (standalone) {
      const cpsID = getParam('cps');
      const planYear = getParam('year');
      getStandalonePlan(cpsID, planYear).then((data) => {
        const plan = data[0];
        const cps = `${plan.ExternalContractID}-${plan.ExternalPlanID}-${Number(plan.ExternalSegmentID)}`;
        this.setState({
          standalonePlan: plan,
        });
      });
    }
  }

  handleTabClick(tab) {
    const {
      selectedTab,
    } = this.state;

    if (selectedTab !== tab) {
      this.setState({ selectedTab: tab });
    }
  }

  // Note casing of planID/planId internally
  handleCompareToggle(event, planId, isSelected) {
    const {
      dispatch,
      medicareList,
      selectedPharmacy,
      shopper,
    } = this.props;

    const plan = getMedicarePlanByID(medicareList, planId, selectedPharmacy);

    if (!isSelected && plan) {
      const { planType } = plan;
      dispatch({
        type: 'ADD_PLAN_COMPARE',
        payload: {
          planType,
          planId,
          zipcode: shopper.zipcode,
        },
      });
    } else if (plan) {
      dispatch({
        type: 'REMOVE_PLAN_COMPARE',
        payload: { planId },
      });
    }
  }

  copyToClipboard(e, data) {
    e.preventDefault();
    navigator.clipboard.writeText(data);
    showPopup('Success', 'Copied to clipboard', null, null, { autoClose: 1500 });
  }

  renderDetailTabs(plan) {
    const {
      carrierLevelCI,
      drugList,
      selectedPharmacy,
      standalone,
    } = this.props;

    const {
      selectedTab,
    } = this.state;

    const tabs = [
      {
        tabText: 'Plan Benefits',
        tabId: 'plandetails-tab-plan-benefits',
      },
      carrierLevelCI === false ? null : {
        tabText: 'Competitive Intelligence',
        tabId: 'plandetails-tab-competitive-intelligence',
        disabled: standalone,
      },
      {
        tabText: 'Plan Documents',
        tabId: 'plandetails-tab-plan-documents',
        disabled: standalone,
      },
    ];
    // hide tab item when PartD_Suppressed == true
    if (!plan.plan?.PartD_Suppressed) {
      tabs.splice(1, 0, {
        tabText: 'Estimated Costs',
        tabId: 'plandetails-tab-estimated-costs',
        disabled: standalone,
      });
    }
    const displayedTabs = tabs.map((tab) => {
      if (tab) {
        let tabDisabled = false;
        if (tab.disabled || ((drugList.length === 0 || !selectedPharmacy)
          && (tab.tabText === 'Total estimated costs by month'
            || tab.tabText === 'Medication cost details'
            || tab.tabText === 'Medication cost by month'
          ))
        ) {
          tabDisabled = true;
        }
        if (plan.meta.detailsLoaded === SUCCESS_STATE) {
          if (tab.tabText === 'Plan Documents' && plan.plan.PlanDocuments) {
            if (plan.plan.PlanDocuments.length === 0) {
              tabDisabled = true;
            }
          }
        }

        return (
          <div
            id={tab.tabId}
            className={`plandetails-tab ${selectedTab === tab.tabText ? 'selected' : ''} ${tabDisabled ? 'disabled' : ''}`}
            disabled={tabDisabled}
            key={tab.tabId}
            onClick={() => {
              if (!tabDisabled) {
                this.handleTabClick(tab.tabText);
              }
            }}
            role="link"
            tabIndex={0}
            title={tab.tabText}
          >
            <span>
              {tab.tabText}
            </span>
          </div>
        );
      }
      return null;
    });

    return displayedTabs;
  }

  renderDetails(plan) {
    const {
      shopper,
      modals,
    } = this.props;

    const {
      selectedTab,
    } = this.state;

    switch (selectedTab) {
      case 'Plan Benefits':
        return (
          <Benefits plan={plan} />
        );
      case 'Estimated Costs':
        return (
          <EstimatedCosts shopper={shopper} plan={plan} />
        );
      case 'Competitive Intelligence':
        return <CiDetail />;
      case 'Plan Documents':
        return <PlanDocuments planDocuments={plan.plan.PlanDocuments} />;
      default:
        return null;
    }
  }

  render() {
    const { selectedTab } = this.state;
    const {
      medicareList,
      navigate,
      planDetailsID,
      plansToCompare,
      selectedPharmacy,
      shopper,
      standalone,
    } = this.props;

    let plan = {};
    let isSelected = false;
    let canCompare = false;
    let planID = null;

    if (standalone) {
      plan = this.state.standalonePlan;
    } else {
      planID = planDetailsID;
      plan = getMedicarePlanByID(medicareList, planID, selectedPharmacy);
      isSelected = plansToCompare.indexOf(plan.planID) !== -1 || shopper.currentPlan === plan.cpsID;
      canCompare = ((shopper.currentPlan ? 1 : 0) + plansToCompare.length) < 3;
      if (plan && (plan.meta.detailsLoaded === DEFAULT_STATE || plan.meta.detailsLoaded === FAIL_STATE)) {
        loadMedicareDetails(shopper, planID);
      }
    }

    const displayName = getPlanCarrierDisplay({ plan });

    let tabBody = null;
    if (!plan || plan.meta.detailsLoaded === FAIL_STATE) {
      tabBody = (
        <div className="pt-5 pb-5 d-flex justify-content-center">
          {"Couldn't find an available plan with that ID."}
        </div>
      );
    } else {
      const planHeaderHover = `Plan type: ${plan.planType}\nPlan carrier: ${plan.carrier}\nPlan name: ${plan.planName}`;
      tabBody = (
        <div className="postition-fixed d-flex flex-column fw-bold w-100 bg-white pt-3">
          <div className="d-flex flex-column fw-normal">
            <PricingDisclaimer />
            <DsnpDisclaimer plans={[plan]} />
          </div>
          {/* entire header */}
          <div
            className="plandetails-header position-sticky ps-5 pe-4 w-100 bg-white shadow-sm border-nbgrey border-bottom"
            style={{
              zIndex: '100',
              top: standalone ? '0' : '',
            }}
          >
            <div className="fs-3">
              Plan Details
            </div>
            <div className="d-flex pt-2">
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <div className="plan-tri-header" title={planHeaderHover}>
                    <span onContextMenu={(e) => this.copyToClipboard(e, JSON.stringify(plan))} id="type">
                      {plan.planType}
                      <SOABadge planType={plan.planType} badgeType="icon" style={{ marginRight: '6px' }} />
                    </span>
                    <span id="carrier">{displayName}</span>
                    <span onContextMenu={(e) => this.copyToClipboard(e, plan.planName)} id="name">{plan.planName}</span>
                  </div>
                  {
                    plan.cpsID === shopper.currentPlan
                      ? (
                        <span className="me-0 ms-auto border text-primaryFocus fw-bold border-primaryFocus bg-primaryFocusBackground p-0 ps-2 pe-2 r-3">
                          CURRENT PLAN
                        </span>
                      ) : null
                  }
                </div>
                <div
                  className="fw-normal mt-1 mb-1"
                  id="plandetails-header-contract-id"
                  title={`Contract ID: ${renderDisplayCPS(plan.plan)}`}
                  onContextMenu={(e) => this.copyToClipboard(e, renderDisplayCPS(plan.plan))}
                >
                  Contract ID: {renderDisplayCPS(plan.plan)}
                </div>
                {!standalone ? (
                  <div
                    className="d-flex align-items-center mt-1 mb-1"
                    title={!canCompare ? 'You cannot compare more than 3 plans at a time.' : 'Compare'}
                    style={{
                      cursor: canCompare || isSelected ? 'pointer' : 'auto',
                      maxWidth: '10vw',
                    }}
                    onClick={(e) => {
                      if (canCompare || isSelected) {
                        this.handleCompareToggle(e, plan.planID, isSelected);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <input
                      className="form-control-input me-3"
                      id={`planlist-compare-button-${plan.planID}`}
                      type="checkbox"
                      readOnly
                      checked={isSelected}
                      disabled={(!canCompare && !isSelected) || shopper.currentPlan === plan.cpsID}
                      style={{ width: '15px', height: '15px' }}
                    />
                    <label
                      className="form-control-label fs-5 pe-none user-select-none fw-normal"
                      htmlFor={`planlist-compare-button-${plan.planID}`}
                      id="planlist-compare-button-label"
                      style={{
                        cursor: canCompare || isSelected ? 'pointer' : 'auto',
                        color: canCompare || isSelected ? 'black' : 'var(--nbgrey)',
                      }}
                    >
                      Compare
                    </label>
                  </div>
                ) : null}
              </div>
              {!standalone && (
                <span className="plandetails-header-buttons d-flex flex-column me-0 ms-auto">
                  <div className="d-flex mb-1 me-0 ms-auto">
                    <button
                      className="btn btn-outline-secondary me-2 fw-normal d-flex"
                      id="plandetails-header-enroll"
                      type="button"
                      tabIndex={0}
                      onClick={() => navigate('/plans/list')}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} className="me-2 fs-2" />
                      Back to all plans
                    </button>
                    <QuoteEnrollButton plan={plan} id="plandetails-header-enroll" className="fw-bold" />
                  </div>
                  <EnrollmentDisclaimer plan={plan} />
                </span>
              )}
            </div>
          </div>
          <div className="fw-normal mt-3">
            <div className="plandetails-tabs d-flex fw-normal ms-5 fs-5">
              {this.renderDetailTabs(plan)}
            </div>
            {
              plan.meta.detailsLoaded === AWAIT_STATE
                ? <LoaderComponent offsetY={100} />
                : (
                  <div className="plandetails-body">
                    <div className="plandetails-body-header">
                      {selectedTab}
                    </div>
                    {this.renderDetails(plan)}
                  </div>
                )
            }
          </div>
        </div>
      );
    }

    if (standalone) {
      return (
        <div>
          <div className="plandetails">
            <div id="plandetails-loaded" />
            {tabBody}
          </div>
        </div>
      );
    }
    return (
      <NavPage
        activeLink="/plans"
        showShopperInfobar
      >
        <div className="plandetails">
          <div id="plandetails-loaded" />
          {tabBody}
        </div>
        <CompareFooter
          backButtonText="Compare"
          backOnClick={() => { navigate('/plans/compare'); }}
          openOnLoad={plansToCompare && plansToCompare.length > 0}
        />
      </NavPage>
    );
  }
}

PlanDetailsPage.defaultProps = {
  selectedTab: 'Plan Benefits',
};

function mapStateToProps(store) {
  return {
    carrierLevelCI: store.theme.features.carrierLevelCI,
    currentPlan: store.shopper.currentPlan,
    drugList: store.shopper.drugList,
    medicareCompareList: store.plan.medicareCompareList,
    medicareList: store.plan.medicareList,
    modals: store.theme.modals,
    planDetailsID: store.plan.planDetailsID,
    planDetailsType: store.plan.planDetailsType,
    plansToCompare: store.compare.planIds,
    recommendedMAPD: store.plan.recommendedMAPD,
    recommendedPDP: store.plan.recommendedPDP,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(PlanDetailsPage));
