import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import JSZip from 'jszip';

// actions
import {
  getAllShoppers,
  getAllDrugs,
  getAllProviders,
  getAllEnrollments,
  getAllCalls,
} from '../../actions/export';
import { loadConfig } from '../../common/actions/config';
import {
  jsonToCSV,
  formatShopperCSV,
  formatDrugCSV,
  formatProviderCSV,
  formatEnrollmentCSV,
} from './formatCSV';

// components
import { LoaderButton } from '../../common/components';
import NavPage from '../nav/NavPage';


function Row({ children, className }) {
  return (
    <div className={`row ${className}`}>
      <div className="d-flex col justify-content-center">
        {children}
      </div>
    </div>
  );
}

function Export() {
  const [ loading, setLoading ] = useState(false);
  const [ raceOptions, setRaceOptions ] = useState([]);
  const [ ethnicityOptions, setEthnicityOptions ] = useState([]);
  const [ callRecordings, setCallRecordings ] = useState([]);

  const {
    leadStatusOptions,
    features: { exportCallRecordings },
  } = useSelector(store => store.theme);

  function createBlob(data, formatter) {
    const csvData = formatter(data, leadStatusOptions, raceOptions, ethnicityOptions);
    const dataAsString = jsonToCSV(csvData);
    const blob = new Blob([dataAsString], { type: 'text/csv', charset: 'utf-8' });
    return blob;
  }

  function triggerFileDownload(url, fileName) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }

  async function doDownload() {
    setLoading(true);

    try {
      // get data from API
      const shopperData = await getAllShoppers();
      const drugData = await getAllDrugs();
      const providerData = await getAllProviders();
      const enrollmentData = await getAllEnrollments();
      // const instructionsMDData = await fetch('/files/instructions.md');
      const instructionsHTMLData = await fetch('/files/instructions.html');

      // turn json into csv blobs
      const shopperBlob = createBlob(shopperData, formatShopperCSV);
      const drugBlob = createBlob(drugData, formatDrugCSV);
      const providerBlob = createBlob(providerData, formatProviderCSV);
      const enrollmentBlob = createBlob(enrollmentData, formatEnrollmentCSV);
      // const instructionsMDBlob = await instructionsMDData.blob();
      const instructionsHTMLBlob = await instructionsHTMLData.blob();

      // initialize jszip
      const zip = new JSZip();

      zip.file(`mmb_clients_export_${Date.now()}.csv`, shopperBlob);
      zip.file(`mmb_drugs_export_${Date.now()}.csv`, drugBlob);
      zip.file(`mmb_providers_export_${Date.now()}.csv`, providerBlob);
      zip.file(`mmb_enrollments_export_${Date.now()}.csv`, enrollmentBlob);
      // don't include the markdown that is used to generate the html in the zip
      // zip.file('instructions.md', instructionsMDBlob);
      zip.file('instructions.html', instructionsHTMLBlob);

      const content = await zip.generateAsync({ type: 'blob' });

      const url = URL.createObjectURL(content);
      const fileName = `MMB_Data_Export_${Date.now()}.zip`;
      triggerFileDownload(url, fileName);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  const buttonStyles = { width: '230px' };

  // load race and ethnicity options for enrollments
  useEffect(() => {
    loadConfig('enrollment_form_race_options_json', 'ALL_SITES_SITE', 'public')
      .then((res) => setRaceOptions(res.data.value));
    loadConfig('enrollment_form_ethnicity_options_json', 'ALL_SITES_SITE', 'public')
      .then((res) => setEthnicityOptions(res.data.value));
    getAllCalls()
      .then((data) => setCallRecordings(data));
  }, []);

  return (
    <NavPage activeLink="export">
      <div className="container-fluid">

        <Row className="py-5">
          <LoaderButton
            style={buttonStyles}
            onClick={() => doDownload()}
            className="btn btn-outline-primary"
            text="Export data to CSV"
            loading={loading}
            disabled={loading}
          />
        </Row>

        {exportCallRecordings && (
          <>
            <hr />

            <div className="row justify-content-center mt-3">
              <div className="col-md-3">
                <p>
                  <i>
                    Note that if you have many call recordings they will be batched into multiple zip files.
                    Please click on each of the listed zip files to download your call recordings. Audio files
                    are large and may take some time to download depending on your connection speed. We recommend
                    downloading one file at a time and waiting for the download to complete before starting the next
                    download.
                  </i>
                </p>
              </div>
            </div>

            {callRecordings.map(call => (
              <Row className="pt-2">
                <LoaderButton
                  onClick={() => triggerFileDownload(call.url, call.file_name)}
                  className="btn btn-link fw-bold"
                  text={call.file_name}
                />
              </Row>
            ))}
          </>
        )}

      </div>
    </NavPage>
  );
}

export default Export;
