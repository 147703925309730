import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import FormLayout from './FormLayout';
import DEFAULT_SHOPPER_SCHEMA from './defaultShopperSchema.json';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../../constants';

// actions
import {
  loadShopper,
  loadCallNotes,
  loadShopperRecommendation,
} from '../../actions/shopper';
import { loadDrugs } from '../../actions/drug';
import { loadPharmacies } from '../../actions/pharmacy';
import { loadDoctors } from '../../actions/doctor';
import { loadSoas } from '../../actions/soa';
import { loadConfig } from '../../actions/config';

// utils
import { showPopup } from '../../utils/popup';
import { validateEffectiveDate } from '../../utils/general';
import { handleSubmit as handleSaveShopper } from './utils';

// components
import { BirthdayWarningModal, ShareQuoteAlert } from './components';
import Notes from './notes';
import Telephony from './telephony';
import NavPage from '../nav/NavPage';
import FooterNav from '../nav/footernav';
import LoaderComponent from '../common/LoaderComponent';
import DiscardChangesModal from '../common/DiscardChangesModal';
import EffectiveDateWarningModal from './components/EffectiveDateWarningModal';
import { AppointmentInfo, EditAppointmentInfo } from './appointments';

function OpenBEQText() {
  const dispatch = useDispatch();
  const {
    theme: {
      features: { beq },
    },
  } = useSelector(store => store);

  return beq ? (
    <div className="my-2">
      {'Save time by using the'}
      <button
        type="button"
        className="btn btn-link px-1 py-0 align-baseline text-blue"
        onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'beqModal' })}
      >
        CMS Beneficiary Eligibility Query (BEQ)
      </button>
      {'to prefill some information'}
    </div>
  ) : null;
}

function ShopperDetails() {
  const { username } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    soa: soaFeature,
    effectiveUpdate,
    beq: beqFeature,
    telephony: telephonyFeature,
    shareQuote: shareQuoteFeature,
    shopperDetailsNotes: shopperDetailsNotesFeature,
    appointmentLeads: appointmentLeadsFeature,
    drugs: df,
    doctors: doctorsFeature,
    quoting: qf,
  } = useSelector(store => store.theme.features);
  // if drugs or quoting are undefined, the features should be on. Only if
  // they are explicitly set to false should we turn them off.
  const quotingFeature = !(qf === false);
  const drugsFeature = !(df === false);
  const { shopper: shopperData } = useSelector(store => store);
  const {
    username: shopperAlreadyLoaded,
    errors,
    subsidyQuestion,
  } = shopperData;
  const hasErrors = Boolean(Object.values(errors).find(v => Boolean(v)));

  const [loading, setLoading] = useState(false);
  const [shopperSaving, setShopperSaving] = useState(false);

  const fadeStyles = {
    opacity: loading ? 0 : 1,
    transitionDuration: '0.5s',
  };

  function handleSubmit(redirect = '') {
    handleSaveShopper(shopperData, dispatch, (isLoading) => setShopperSaving(isLoading))
      .then(() => {
        if (redirect) navigate(redirect);
      }).catch(err => console.log(err));
  }

  // load shopper data from username
  useEffect(() => {
    if (username && !shopperAlreadyLoaded) {
      dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: AWAIT_STATE });
      dispatch({ type: 'SET_CALL_NOTES_LOADED_STATE', payload: AWAIT_STATE });
      dispatch({ type: 'SET_DRUGS_LOADED_STATE', payload: AWAIT_STATE });
      dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: AWAIT_STATE });
      setLoading(true);

      dispatch({ type: 'CLEAR_SHOPPER' });
      Promise.all([
        loadShopper(username),
        loadCallNotes(username),
        loadDrugs(username),
        loadPharmacies(username),
        loadDoctors(username),
        loadShopperRecommendation(username),
        loadSoas(username),
        loadConfig('shopper_details_schema_json'),
      ]).then(([shopper, callNotes, drugs, pharmacies, doctors, recs, soas, shopperSchema]) => {
        dispatch({ type: 'SET_SHOPPER', payload: shopper });
        dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: SUCCESS_STATE });
        dispatch({
          type: 'UPDATE_SHOPPER',
          payload: {
            previousEffectiveDate: shopper.effectiveDate,
            schema: shopperSchema || DEFAULT_SHOPPER_SCHEMA,
          },
        });
        dispatch({ type: 'SET_SHOPPER_CALL_NOTES', payload: callNotes });
        dispatch({ type: 'SET_CALL_NOTES_LOADED_STATE', payload: SUCCESS_STATE });
        dispatch({ type: 'SET_SHOPPER_DRUGS', payload: drugs });
        dispatch({ type: 'SET_DRUGS_LOADED_STATE', payload: SUCCESS_STATE });
        dispatch({ type: 'SET_SHOPPER_PHARMACIES', payload: pharmacies });
        if (pharmacies.length) dispatch({ type: 'SET_SELECTED_PHARMACY', payload: pharmacies[0].npi });
        dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: SUCCESS_STATE });
        dispatch({ type: 'SET_SHOPPER_DOCTORS', payload: doctors });
        dispatch({ type: 'SET_SHOPPER_DOCTORS_LOADED_STATE', payload: SUCCESS_STATE });
        dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'recommendation', val: recs } });
        dispatch({ type: 'SET_SHOPPER_SOAS', payload: soas });

        if (validateEffectiveDate(shopper.effectiveDate) && effectiveUpdate) {
          dispatch({ type: 'SHOW_MODAL', payload: 'effectiveDateWarningModal' });
        }

        setLoading(false);
      }).catch((err) => {
        console.log(err);
        showPopup('Error', 'There was a problem loading the Shopper.');

        dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: FAIL_STATE });
        dispatch({ type: 'SET_CALL_NOTES_LOADED_STATE', payload: FAIL_STATE });
        dispatch({ type: 'SET_SHOPPER_WEATHER_LOADED_STATE', payload: FAIL_STATE });
        dispatch({ type: 'SET_DRUGS_LOADED_STATE', payload: FAIL_STATE });
        dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: FAIL_STATE });
        dispatch({ type: 'SET_SHOPPER_DOCTORS_LOADED_STATE', payload: FAIL_STATE });
        setLoading(false);
      });
    } else if (!username) {
      setLoading(true);
      dispatch({ type: 'CLEAR_SHOPPER' });
      loadConfig('shopper_details_schema_json')
        .then(schema => dispatch({ type: 'UPDATE_SHOPPER', payload: { schema: schema || DEFAULT_SHOPPER_SCHEMA } }))
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (!subsidyQuestion) dispatch({ type: 'SET_SHOPPER', payload: { subsidyQuestion: 'no_extra_help' } });
  }, [subsidyQuestion]);

  return (
    <NavPage
      activeLink={`/shopper/${username}`}
      showShopperInfobar={username}
      disableNavOverride={hasErrors}
    >
      <div className="mmb-container">
        <div className="mmb-header">
          Client Info
          {username ? null : <span className="ms-1 text-primaryFocus"><i> - new client</i></span> }
        </div>
        <LoaderComponent loading={loading} />
        {!loading && (
          <div className="pe-4 ps-5">
            {beqFeature ? <OpenBEQText /> : null}
            {appointmentLeadsFeature && (
              <div className="row mt-3">
                <div className="col-md-6">
                  <AppointmentInfo />
                </div>
                <div className="col-md-6">
                  <EditAppointmentInfo />
                </div>
              </div>
            )}
            {shopperDetailsNotesFeature && <Notes shopper={shopperData} />}
            {telephonyFeature && <div className="row mt-3"><Telephony /></div>}
            {shareQuoteFeature && <ShareQuoteAlert />}
            <FormLayout className="fade" style={fadeStyles} />
          </div>
        )}
        <FooterNav
          confirmationModal
          planOnClick={() => handleSubmit('/plans/list')}
          nextOnClick={() => {
            if (soaFeature) {
              handleSubmit('/soa');
            } else if (drugsFeature) {
              handleSubmit('/rx');
            } else if (doctorsFeature) {
              handleSubmit('/doctors');
            } else {
              handleSubmit('/plans');
            }
          }}
          disableNext={hasErrors}
          nextLoading={shopperSaving}
          hideRightButton={!quotingFeature}
          hideLeftButton={!quotingFeature}
        />
      </div>
      <BirthdayWarningModal />
      <EffectiveDateWarningModal />
      <DiscardChangesModal
        handleSubmit={() => handleSubmit('/dashboard')}
      />
    </NavPage>
  );
}

export default ShopperDetails;
