import axios from 'axios';

/**
 * Assign default axios settings for all requests to use.
 */
export default function configureAxios() {
  axios.defaults.params = {
    site: process.env.REACT_APP_HOSTNAME || window.location.hostname,
  };
  axios.defaults.headers.common = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}
