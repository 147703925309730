import React from 'react';
import { connect } from 'react-redux';

// Actions
import { loadShoppers } from '../../actions/shopper';

// Components
import { Highlight } from '../../guide';
import Shopper from './Shopper';
import LoaderComponent from '../common/LoaderComponent';
import { AWAIT_STATE, FAIL_STATE, SUCCESS_STATE } from '../../constants';

class ShopperList extends React.PureComponent {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_SORT_INVERSE' });
    dispatch({ type: 'CLEAR_SORT_TERM' });
  }

  handleColumnSort(id) {
    const {
      dispatch,
      sortTerm,
      sortInverse,
    } = this.props;

    let newSortTerm = id;
    let newSortInverse = sortInverse;

    // Conditional order important for [ down -> up -> off ] w/ changing sorts
    const setInitialSort = sortTerm === null && newSortTerm !== sortTerm;
    const setNewSort = newSortTerm !== sortTerm;
    const setInverseSort = sortTerm !== null && sortInverse === false;
    const setDefaultSort = sortTerm !== null && sortInverse === true;
    if (setInitialSort || setNewSort) {
      newSortInverse = false;
    } else if (setInverseSort) {
      newSortInverse = !sortInverse;
    } else if (setDefaultSort) {
      newSortTerm = null;
      newSortInverse = false;
    }

    return new Promise((resolve, reject) => {
      dispatch({ type: 'SET_SORT_INVERSE', payload: newSortInverse });
      dispatch({ type: 'SET_SORT_TERM', payload: newSortTerm });
      resolve();
      reject();
    });
  }

  handleSearch() {
    const {
      agentEmail,
      firstNameFilter,
      leadIDFilter,
      leadTypeFilter,
      showAllQuotes,
      sortTerm,
      sortInverse,
      stateFilter,
      selectedTab,
      statusFilter,
    } = this.props;

    loadShoppers({
      desc: sortTerm ? sortInverse : null,
      show_all_quotes: showAllQuotes,
      sort: sortTerm || null,
      state: stateFilter || null,
      lead_id: leadIDFilter || null,
      lead_category: statusFilter ? undefined : selectedTab,
      lead_status: statusFilter || null,
      lead_type: leadTypeFilter || null,
      name: firstNameFilter || null,
      user_who_created: agentEmail,
    });
  }

  render() {
    const {
      activeLink,
      activePage,
      agentNotifications,
      dashShowLeadType,
      itemsCountPerPage,
      leadsHistory,
      selectedTab,
      shoppersLoaded,
      shopperPage,
      sortInverse,
      sortTerm,
      telephonyFeature,
      totalItemsCount,
    } = this.props;

    let firstShopperIdx = '0';
    let lastShopperIdx = '0';
    let totalItemsCountString = '0';

    if (shoppersLoaded === 'SUCCESS_STATE') {
      firstShopperIdx = 1 + ((activePage - 1) * itemsCountPerPage);
      lastShopperIdx = Math.min(activePage * itemsCountPerPage, totalItemsCount);
      totalItemsCountString = totalItemsCount;
    }

    const hasNotificationColumn = selectedTab === 'Follow up' && agentNotifications;
    const shopperColumns = [
      {
        columnId: 'dashboard-shopper-list-shopper-name',
        columnText: 'Client name',
        sortId: 'first_name',
        guide: true,
      },
      {
        columnId: 'dashboard-shopper-list-shopper-last-updated',
        columnText: 'Last updated',
        sortId: 'last_updated',
      },
      {
        columnId: 'dashboard-shopper-list-shopper-state',
        columnText: 'State',
        sortId: 'state',
      },
      {
        columnId: 'dashboard-shopper-list-shopper-city',
        columnText: 'City',
        sortId: 'city',
      },
      {
        columnId: 'dashboard-shopper-list-shopper-phone',
        columnText: 'Phone number',
        sortId: 'mobile_phone',
      },
      {
        columnId: 'dashboard-shopper-list-shopper-disposition',
        columnText: 'Disposition',
        sortId: 'lead_status',
      },
      ...(dashShowLeadType ? [{
        columnId: 'dashboard-shopper-list-shopper-lead-type',
        columnText: 'Lead type',
        sortId: 'lead_type',
      }] : []),
      ...(telephonyFeature ? [{
        columnId: 'dashboard-shopper-list-shopper-calls',
        columnText: 'Call recordings',
        sortId: 'calls',
      }] : []),
      ...(hasNotificationColumn ? [{
        columnText: 'Notification',
        sortId: 'notification',
      }] : []),
      ...(leadsHistory ? [{
        columnId: 'dashboard-shopper-list-shopper-lead-history',
        columnText: 'Lead history',
        sortId: 'lead_history',
        isClickable: false,
      }] : []),
    ];

    return (
      <>
        <div className={`dashboard-shopper-list-count ${shoppersLoaded !== 'SUCCESS_STATE' ? 'loading' : ''}`}>
          <span>
            {`${firstShopperIdx} - ${lastShopperIdx} of ${totalItemsCountString}`}
          </span>
        </div>
        <div className="dashboard-shopper-list-container">
          <table className="dashboard-shopper-list">
            <thead className="dashboard-shopper-list-header">
              {/* Click-to-sort Column Headers */}
              <tr>
                {
                  /* eslint-disable arrow-body-style */
                  shopperColumns.map((column) => {
                    return (
                      <th
                        className="dashboard-shopper-list-cell position-relative text-nowrap text-truncate"
                        id={column.columnId}
                        key={column.columnId}
                        onClick={() => {
                          if (column.isClickable !== false && shoppersLoaded !== 'AWAIT_STATE') {
                            this.handleColumnSort(column.sortId)
                              .then(() => this.handleSearch());
                          }
                        }}
                        style={{
                          cursor: column.isClickable !== false ? 'pointer' : 'auto',
                        }}
                      >
                        <div>
                          {column.columnText && (
                            <Highlight id="dash" i={4} padding="6" tag="span" disabled={!column.guide} className="me-1">
                              {column.isClickable === false
                                ? null
                                : (
                                  <span>
                                    <i
                                      aria-hidden="true"
                                      className={sortInverse === true ? 'fa fa-sort-up' : 'fa fa-sort-down'}
                                      style={{
                                        color: sortTerm === column.sortId ? 'var(--bs-primaryFocus)' : 'black',
                                        left: '20px',
                                        lineHeight: '23px',
                                        position: 'absolute',
                                      }}
                                      type="icon"
                                    />
                                    <i
                                      aria-hidden="true"
                                      className="fa fa-sort"
                                      type="icon"
                                    />
                                  </span>
                                )}
                            </Highlight>
                          )}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {column.columnText}
                          </span>
                        </div>
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            {/* Shopper List Content (Shoppers) */}
            <tbody className="position-relative">
              {
                shoppersLoaded === SUCCESS_STATE
                  ? (shopperPage.map((shopper, i) => (
                    <Shopper
                      activeLink={activeLink}
                      isFirst={i === 0}
                      key={shopper.username}
                      shopper={shopper}
                    />
                  )))
                  : null
              }
              {
                shoppersLoaded === SUCCESS_STATE && shopperPage.length === 0 && (
                <tr
                  className="dashboard-shopper-list-row text-nbgrey"
                  style={{
                    fontStyle: 'italic',
                    minHeight: '128px',
                    textAlign: 'center',
                    userSelect: 'none',
                    display: 'inline-table',
                  }}
                >
                  <td colSpan={12}>No search results</td>
                </tr>
                )
              }
              {
                shoppersLoaded === FAIL_STATE && (
                <tr
                  className="dashboard-shopper-list-row text-nbgrey"
                  style={{
                    fontStyle: 'italic',
                    minHeight: '128px',
                    textAlign: 'center',
                    userSelect: 'none',
                    display: 'inline-table',
                  }}
                >
                  <td colSpan={12} className="text-danger">Network Error</td>
                </tr>
                )
              }
            </tbody>
          </table>
          {shoppersLoaded === AWAIT_STATE && <LoaderComponent style={{ top: 'max(50vh, 360px' }} />}
        </div>
      </>
    );
  }
}

ShopperList.defaultProps = {
  activeLink: '/',
};

const mapStateToProps = (store) => ({
  activePage: store.shopperlist.activePage,
  agentEmail: store.agent.email,
  agentNotifications: store.theme.features.agentNotifications,
  dashShowLeadType: store.theme.features.dashShowLeadType,
  firstNameFilter: store.shopperlist.firstNameFilter,
  itemsCountPerPage: store.shopperlist.itemsCountPerPage,
  leadIDFilter: store.shopperlist.leadIDFilter,
  leadTypeFilter: store.shopperlist.leadTypeFilter,
  leadStatusOptions: store.leadStatus.options || [],
  selectedTab: store.shopperlist.selectedTab,
  shoppersLoaded: store.loaders.shoppersLoaded,
  shopperPage: store.shopperlist.shopperPage,
  sortInverse: store.shopperlist.sortInverse,
  sortTerm: store.shopperlist.sortTerm,
  stateFilter: store.shopperlist.stateFilter,
  statusFilter: store.shopperlist.statusFilter,
  totalItemsCount: store.shopperlist.totalItemsCount,
  telephonyFeature: store.theme.features.telephony,
  leadsHistory: store.theme.features.leadsHistory,
});

export default connect(mapStateToProps)(ShopperList);
