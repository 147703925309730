/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateField } from '../../utils';

// components
import Select from './Select';

// actions
// import { getCounties, getCities } from '../../../../actions/shopper';

function City({ disabled }) {
  const dispatch = useDispatch();
  const [disabledMsg, setDisabledMsg] = useState('');
  const {
    cities = [],
    city: currentValue,
    schema = {},
  } = useSelector(store => store.shopper);
  const { shopper } = useSelector(store => store);
  const { zipLoading } = useSelector(store => store.loaders);
  const isDisabled = (cities.length <= 1) || zipLoading;

  function updateCity(city) {
    dispatch({ type: 'UPDATE_SHOPPER', payload: { city } });
    validateField({ name: 'city', schema, currentValues: shopper, newValue: city });
  }

  function handleChange(e) {
    const { value } = e.target;
    updateCity(value);
  }

  useEffect(() => {
    if (cities.length === 0) {
      if (!currentValue) {
        setDisabledMsg('(Please enter a valid zipcode)');
      }
    } else if (cities.length === 1) {
      setDisabledMsg('');
      updateCity(cities[0].city);
    } else if (cities.length > 1) {
      setDisabledMsg('');
      const currentCityExistsInOptions = (cities.filter(c => c.city === currentValue).length > 0);
      if (!currentCityExistsInOptions) updateCity('');
    }
  }, [cities]);

  useEffect(() => {
    if (zipLoading) setDisabledMsg('Loading...');
  }, [zipLoading]);

  const options = cities.map(city => ({ desc: city.city, value: city.city }));

  if (cities.length > 1) {
    options.unshift({ desc: 'Select', value: '', disabled: true });
  }

  return (
    <Select
      name="city"
      label="City"
      handleChange={handleChange}
      disabled={disabled || isDisabled}
      disabledVal={disabledMsg}
      options={options}
    />
  );
}

export default City;
