import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { openEnrollment } from '../../actions/quote';
import { getPlan } from '../../actions/plan';

class EnrollConfirmModal extends React.PureComponent {
  openEnrollment = () => {
    const {
      id,
      host,
      close,
      planId,
    } = this.props;

    getPlan(planId).then(({ data }) => {
      const { CarrierName, PlanYear } = data;
      openEnrollment(id, host, CarrierName, PlanYear);
    });

    close();
  };

  render() {
    const { isOpen, close, id } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={close} size="lg">
        <div className="modal-header d-flex">
          <h5 className="m-0">Agent Agreement</h5>
          <div>{`Quote #: ${id}`}</div>
        </div>
        <ModalBody>
          <h5>
            You have completed an ethical and compliant sales presentation including,
            but not limited to, completion of the following:
          </h5>
          <ul>
            <li>Sustainability/Needs Assessment;</li>
            <li>Beneficiary Qualification (Medicare Status, Existing Coverage, LIS/Dual Eligibility (Extra Help));</li>
            <li>Scope of Appointment;</li>
            <li>Plan Benefits Review, Including Premiums and Effective Date;</li>
            <li>Pre-Enrollment Checklist Review;</li>
            <li>Discussion of PCP and/or Top Tier Provider(s); and</li>
            <li>Verbal Confirmation from Beneficiary of Intent to Enroll.</li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={close}
            className="me-2 btn btn-outline-secondary"
            tabIndex={0}
            type="button"
          >
            No, go back
          </button>
          <button
            onClick={this.openEnrollment}
            className="btn btn-primary fw-bold"
            tabIndex={0}
            type="button"
          >
            Yes, enroll applicant
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  host: state.theme.enrollment,
  isOpen: state.quote.confirmModalOpen,
  id: state.quote.confirmQuoteId,
  planId: state.quote.confirmPlanId,
});

const mapDispatchToProps = {
  close: () => ({ type: 'CONFIRM_MODAL_CLOSE' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollConfirmModal);
