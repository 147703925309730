import React from 'react';

import { Radio, Checkbox } from '../components/inputs';

function Social({ pageTitle }) {
  return (
    <div>
      <div className="fw-bold">{pageTitle}</div>
      <div className="d-flex flex-column">
        <Radio name="lack_of_food" id={1} labelClassName="fw-bold fs-4 text-secondary" />
        <Radio name="transportation_text" id={2} labelClassName="fw-bold fs-4 text-secondary" disabled />
        <div className="ms-2 ps-2 border-start">
          <Checkbox
            name="transport_yes"
            className="d-flex"
          />
          <Checkbox
            name="transport_yes_2"
            className="d-flex"
          />
          <Checkbox
            name="transport_no"
            className="d-flex"
          />
          <Checkbox
            name="transport_no_answer"
            className="mt-1"
          />
        </div>
        <Radio name="living_situation" id={3} labelClassName="fw-bold fs-4 text-secondary" />
        <Radio name="unable_text" id={4} labelClassName="fw-bold fs-4 text-secondary" disabled />
        <div className="ms-2 ps-2 border-start">
          <Radio
            name="no_clothing"
            className="d-flex"
          />
          <Radio
            name="no_employment"
            className="d-flex"
          />
          <Radio
            name="no_internet"
            className="d-flex"
          />
          <Radio
            name="no_medicine"
            className="d-flex"
          />
          <Radio
            name="no_phone"
            className="d-flex"
          />
          <Radio
            name="no_social"
            className="d-flex"
          />
          <Radio
            name="no_utilities"
            className="d-flex"
          />
        </div>
        <Radio name="tech_capable" id={5} labelClassName="fw-bold fs-4 text-secondary" />
      </div>
    </div>
  );
}

export default Social;
