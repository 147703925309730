/* eslint-disable spaced-comment,no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// actions / utils
import { getCookie } from '../../../common/utils/cookie';
import { loginSuccessActions } from '../utils';
import { validateTheme, loadTheme } from '../../../actions/theme';
import { loadAgentSelf } from '../../../actions/agent';
import { loadShopper } from '../../../actions/shopper';

// components
import LoaderComponent from '../../common/LoaderComponent';

/***************
 * This is the landing page that agents are pushed to from the EnrollHere dialer
 * to do quoting. We are expecting a lead id in the url and a cookie that contains
 * the authToken
 ****************/

function DialerLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leadID } = useParams();
  const [ error, setError ] = useState('');
  const authToken = getCookie('mmbAuthToken');

  const {
    theme: {
      features: { agentNotifications },
    },
    benefits: {
      MACustomBenefits,
      MAPDCustomBenefits,
      PDPCustomBenefits,
    },
  } = useSelector(store => store);

  async function loadAgent() {
    let hash, theme, agentData, shopperUsername; // eslint-disable-line
    try {
      hash = await validateTheme();
      theme = await loadTheme(hash);
      agentData = await loadAgentSelf(authToken);
      const shopperData = await loadShopper(leadID, true);
      ({ username: shopperUsername } = shopperData);
    } catch (err) {
      console.log(err);
    }

    loginSuccessActions({
      agentData,
      notify: false,
      dispatch,
      navigate,
      successRedirect: `/shopper/${shopperUsername}`,
      agentNotifications: false,
      MACustomBenefits,
      MAPDCustomBenefits,
      PDPCustomBenefits,
      showReleaseNotes: false,
    });
  }

  useEffect(() => {
    loadAgent();
  }, [authToken]);

  return (
    <div>
      {error ? (
        <div>
          {error}
        </div>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
}

export default DialerLandingPage;
