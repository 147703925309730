// eslint-disable-next-line import/no-cycle
import { refreshSession, checkIfLoggedIn } from '../actions/session';
// eslint-disable-next-line import/no-cycle
import { agentLogout } from '../actions/agent';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

let tokenIsUpdating = false;

/**
 * Middleware to keep session alive when user is still active on the site.
 */
export default () => (next) => (action) => {
  const token = localStorage.getItem('SessionToken');

  const currentState = store.getState();
  const tokenRefreshMinutes = Number(currentState.theme.tokenRefreshMinutes) || 10;

  const tokenLastUpdate = new Date(currentState.session.tokenLastUpdate);
  const currentTime = new Date();
  const tokenTimeDifferenceInMinutes = (currentTime - tokenLastUpdate) / (1000 * 60);
  const tokenHasExpired = tokenTimeDifferenceInMinutes > tokenRefreshMinutes;

  // Validate the session.
  if (tokenHasExpired && !tokenIsUpdating && !window.location.pathname.includes('error_')) {
    // NOTE(santeyio): Different behavior for mobile specific pages.
    // router paths /mobile/... have their own built in refresh logic.
    const pathIgnoreList = ['mobile', 'signup', 'enrollhere', 'password-reset'];
    let doLoginRedirect = true;
    pathIgnoreList.forEach(p => {
      if (window.location.pathname.includes(p)) doLoginRedirect = false;
    });
    if (doLoginRedirect) {
      tokenIsUpdating = true;
      checkIfLoggedIn().then(() => {
        refreshSession();
        tokenIsUpdating = false;
      }).catch(() => {
        // Above function will throw when the user is not logged in.

        // Cases
        // * Previously authenticated with a token and /check-if-logged-in threw so we log them out and throw them to /
        // * Has no token and visits /shopper/dashboard/..., so we "log them out" and throw them to /
        if (
          (token && window.location.pathname !== '/') || window.location.pathname !== '/') {
          console.log('session middleware logout');
          if (window.location.pathname !== '/features') agentLogout();
        }
      });
    }
  }

  return next(action);
};
