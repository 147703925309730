/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { handleSubmit as handleSaveShopper } from '../utils';

// components
import { ModalBase, LoaderButton } from '../../../common/components';

function StartTime({ setter = () => {}, value }) {
  const [ date, setDate ] = useState(DateTime.fromISO(value).toISODate());
  const [ time, setTime ] = useState(DateTime.fromISO(value).toFormat('HH:mm'));
  const [ zone, setZone ] = useState(DateTime.fromISO(value).toFormat('ZZZZ'));

  function handleDateChange(e) {
    const { value: v } = e.target;
    const updates = DateTime.fromISO(v);
    const updated = DateTime.fromISO(value).set({
      year: updates.year,
      month: updates.month,
      day: updates.day,
    });
    setter(updated.toISO());
  }

  function handleTimeChange(e) {
    const { value: v } = e.target;
    const updates = DateTime.fromISO(v);
    const updated = DateTime.fromISO(value).set({
      hour: updates.hour,
      minute: updates.minute,
    });
    setter(updated.toISO());
  }

  useEffect(() => {
    setDate(DateTime.fromISO(value).toISODate());
    setTime(DateTime.fromISO(value).toFormat('HH:mm'));
    setZone(DateTime.fromISO(value).toFormat('ZZZZ'));
  }, [value]);

  return (
    <>
      <div className="col-md-3">
        <label className="form-label" htmlFor="appointment-date-input">
          Appointment Date
        </label>
        <input
          className="form-control"
          name="appointment-date-input"
          type="date"
          onChange={(e) => handleDateChange(e)}
          value={date || ''}
        />
      </div>

      <div className="col-md-3">
        <label className="form-label" htmlFor="appointment-time-input">
          Start Time
        </label>
        <input
          className="form-control"
          name="appointment-time-input"
          type="time"
          onChange={(e) => handleTimeChange(e)}
          value={time || ''}
        />
      </div>

      <div className="col-md-2">
        <label className="form-label" htmlFor="appointment-timezone-input">
          Timezone
        </label>
        <input
          id="appointment-timezone-input"
          disabled
          className="form-control"
          value={zone || ''}
        />
      </div>
    </>
  );
}

function Location({ setter, value }) {
  return (
    <div className="col">
      <label className="form-label" htmlFor="appointment-location-input">
        Appointment Location
      </label>
      <input
        id="appointment-location-input"
        className="form-control"
        value={value}
        onChange={(e) => setter(e.target.value)}
      />
    </div>
  );
}

function AppointmentTypeSelect({ setter, value }) {
  const options = [
    { label: 'Online', value: 'OnLine' },
    { label: 'Phone', value: 'PhoneCall' },
    { label: 'At Beneficiary Location', value: 'InPerson_BeneficiaryLocation' },
    { label: 'At Agent Office', value: 'InPerson_AgentOffice' },
  ];

  return (
    <div className="col-md-6">
      <label className="form-label" htmlFor="appointment-type-select">
        Appointment Type
      </label>
      <select
        id="appointment-type-select"
        className="form-select"
        onChange={(e) => setter(e.target.value)}
        value={value || ''}
      >
        <option value="" disabled>Select...</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
}

function DurationSelect({ setter, value }) {
  const [ options, setOptions ] = useState([
    '00:15',
    '00:30',
    '00:45',
    '01:00',
    '01:15',
    '01:30',
    '01:45',
    '02:00',
    '02:15',
    '02:30',
    '02:45',
    '03:00',
  ]);

  if (value && !options.includes(value)) {
    setOptions([ ...options, value ]);
  }

  return (
    <div className="col-md-3">
      <label className="form-label" htmlFor="appointment-duration-input">
        Duration Hours
      </label>
      <select
        className="form-select"
        value={value || ''}
        onChange={(e) => setter(e.target.value)}
      >
        <option value="" disabled>Select...</option>
        {options.map(opt => (
          <option key={opt} value={opt}>{opt}</option>
        ))}
      </select>
    </div>
  );
}

function EditModal({ modalWidth = '50%' }) {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const { editAppointmentModal } = useSelector(store => store.modal);
  const { shopper } = useSelector(store => store);
  const { data = {} } = shopper;
  const {
    appointment: {
      appointment_start_time,
      appointment_duration,
      appointment_type,
      appointment_location,
    } = {},
    original_appointment,
  } = data;
  const [ startTime, setStartTime ] = useState(appointment_start_time);
  const [ duration, setDuration ] = useState(appointment_duration);
  const [ type, setType ] = useState(appointment_type);
  const [ location, setLocation ] = useState(appointment_location);

  function hideModal() {
    dispatch({ type: 'HIDE_MODAL', payload: 'editAppointmentModal' });
  }

  function handleUpdate() {
    const payload = {
      data: {
        ...data,
        appointment: {
          ...data.appointment,
          appointment_duration: duration,
          appointment_start_time: startTime,
          appointment_type: type,
          appointment_location: location,
        },
      },
      status: '30', // Appointment Updated status
    };
    if (!original_appointment) {
      payload.data.original_appointment = data.appointment;
    }
    const updatedShopper = {
      ...shopper,
      data: payload.data,
      status: '30',
    };
    handleSaveShopper(updatedShopper, dispatch, setLoading)
      .then(() => {
        dispatch({ type: 'UPDATE_SHOPPER', payload });
      }).finally(() => {
        hideModal();
      });
  }

  useEffect(() => {
    // NOTE(santeyio): if there is no existing appointment,
    // populate default values so that a new appointment can be added
    if (!startTime && !duration && !type && !location) {
      setStartTime(DateTime.local().toISO());
      setDuration('01:00');
    }
  }, []);

  return (
    <ModalBase
      isOpen={editAppointmentModal}
      hideHeader
      allowOverlayClickClose
      closeModalAction={() => hideModal()}
      padding="2em"
      staticWidth={modalWidth}
    >
      <div className="row">
        <StartTime
          setter={(e) => setStartTime(e)}
          value={startTime}
          durationValue={duration}
          durationSetter={setDuration}
        />
        <DurationSelect
          setter={setDuration}
          value={duration}
        />
      </div>

      <div className="row mt-3">
        <Location
          setter={(e) => setLocation(e)}
          value={location}
        />
      </div>

      <div className="row mt-3">
        <AppointmentTypeSelect
          setter={setType}
          value={type}
        />
      </div>

      <div className="row mt-5">
        <div className="d-flex col justify-content-between">
          <LoaderButton
            text="Close"
            className="btn btn-outline-danger"
            onClick={() => hideModal()}
          />
          <LoaderButton
            text="Update"
            className="btn btn-primary"
            loading={loading}
            onClick={() => handleUpdate()}
          />
        </div>
      </div>

    </ModalBase>
  );
}

export default EditModal;
