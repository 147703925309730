import { store } from '../../../store';


const OPTIONS = [
  // { value: 'online', label: 'Online', allZips: true },
  // ----
  // if we specify 'allZips' then we do not create a key in travelZipsByMeetingType and
  // we do not store points in pointsByMeetingType for this meeting type. We do not send
  // this key to leadscorz in travelZipsByMeetingType which indicates to them that this
  // meeting type should apply to all zips that an agent is licensed in if it is set to
  // enabled in the appointments_profile.meetingTypes object.
  // ----
  { value: 'call', label: 'Call', allZips: true },
  { value: 'in_person_office', label: 'In Person - At Office' },
  { value: 'in_person_beneficiary', label: 'In Person - At Beneficiary Location' },
];

function getMeetingTypeOptions() {
  const { appointmentProfileMeetingTypeOptions = OPTIONS } = store.getState().theme;
  return appointmentProfileMeetingTypeOptions;
}

function removeEmptyAvailabilitySlots(availabilityObject) {
  const cleaned = {};
  Object.entries(availabilityObject || {}).forEach(([key, val = {}]) => {
    const { timeBlocks = [] } = val;
    const cleanedTimeBlocks = [];
    timeBlocks.forEach((block = {}) => {
      if (block.start && block.end) cleanedTimeBlocks.push(block);
    });
    if (cleanedTimeBlocks.length) {
      cleaned[key] = { ...val, timeBlocks: cleanedTimeBlocks };
    // if there aren't any time blocks, we auto unselect the day as available
    } else {
      cleaned[key] = { ...val, timeBlocks: [], available: false };
    }
  });
  return cleaned;
}

export {
  getMeetingTypeOptions,
  removeEmptyAvailabilitySlots,
};
