/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import TIME_OPTIONS from './timeOptions';

const TIMEZONE_OPTIONS = [
  { display: 'Pacific Timezone', value: 'America/Los_Angeles' },
  { display: 'Mountain Timezone', value: 'America/Denver' },
  { display: 'Central Timezone', value: 'America/Chicago' },
  { display: 'Eastern Timezone', value: 'America/New_York' },
];

const DAYS = [
  { display: 'Monday', value: 'mon' },
  { display: 'Tuesday', value: 'tue' },
  { display: 'Wednesday', value: 'wed' },
  { display: 'Thursday', value: 'thu' },
  { display: 'Friday', value: 'fri' },
  { display: 'Saturday', value: 'sat' },
  { display: 'Sunday', value: 'sun' },
];

const DAYS_AVAILABLE = {
  mon: {
    available: false,
    timeBlocks: [],
  },
  tue: {
    available: false,
    timeBlocks: [],
  },
  wed: {
    available: false,
    timeBlocks: [],
  },
  thu: {
    available: false,
    timeBlocks: [],
  },
  fri: {
    available: false,
    timeBlocks: [],
  },
  sat: {
    available: false,
    timeBlocks: [],
  },
  sun: {
    available: false,
    timeBlocks: [],
  },
};

function TimeSelect({
  type,
  day,
  blockIndex,
  disabled,
  reduxProfile,
  setReduxProfileKeys,
}) {
  const { value } = day;
  const { availability = {} } = reduxProfile;
  const { [value]: { timeBlocks } } = availability;
  const { start, end } = timeBlocks[blockIndex];

  function handleChange(e) {
    const { value: v } = e.target;
    const updated = [ ...timeBlocks ];
    if (type === 'end') updated[blockIndex].end = v;
    if (type === 'start') updated[blockIndex].start = v;
    const payload = {
      ...availability,
      [value]: {
        ...availability[value],
        timeBlocks: updated,
      },
    };
    setReduxProfileKeys(payload);
  }

  function filterTimeOptions(options) {
    if (type === 'end') {
      return options.filter(opt => (moment(opt.value, 'H:mm') > moment(start, 'H:mm')));
    }
    return options;
  }

  return (
    <div className="col-md-4">
      <select
        className={`form-select ${disabled && 'text-muted'}`}
        value={(type === 'start') ? start : end}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      >
        <option disabled value="">Select</option>
        {filterTimeOptions(TIME_OPTIONS).map(time => (
          <option key={time.value} value={time.value}>
            {time.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function TimeBlocks({
  disabled,
  day,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { display, value } = day;
  const { availability = {} } = reduxProfile;
  const {
    [value]: {
      available,
      timeBlocks = [],
    } = {},
  } = availability;

  function handleDelete(blockIndex) {
    const updated = [ ...timeBlocks ];
    updated.splice(blockIndex, 1);
    const payload = {
      availability: {
        ...availability,
        [value]: {
          ...availability[value],
          timeBlocks: updated,
        },
      },
    };
    setReduxProfileKeys(payload);
  }

  return (
    <div className="col">
      {timeBlocks.map(({ start, end, key }, blockIndex) => (
        <div key={key} className="row mb-2">
          <TimeSelect
            type="start"
            day={day}
            blockIndex={blockIndex}
            disabled={disabled}
            reduxProfile={reduxProfile}
            setReduxProfileKeys={setReduxProfileKeys}
          />
          <div className="d-flex col-md-1 align-items-center justify-content-center">
            to
          </div>
          <TimeSelect
            type="end"
            day={day}
            blockIndex={blockIndex}
            disabled={disabled}
            reduxProfile={reduxProfile}
            setReduxProfileKeys={setReduxProfileKeys}
          />
          <div className="col-md-1">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => handleDelete(blockIndex)}
              disabled={disabled || (timeBlocks.length <= 1)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

function DaySelectButton({
  disabled,
  day,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { display, value } = day;
  const {
    availability = {
      ...reduxProfile.availability,
      [value]: {
        available: false,
        timeblocks: [],
      },
    },
  } = reduxProfile;
  const {
    [value]: {
      available,
      timeBlocks = [],
    } = {},
  } = availability;

  function handleDaySelect() {
    const payload = { availability: { ...availability } };
    // if we are updating day to available, add default time blocks
    if (!available) {
      payload.availability[value].available = true;
      payload.availability[value].timeBlocks = [
        { start: '9:00', end: '12:00', key: `${Date.now()}` },
        { start: '13:00', end: '17:00', key: `${Date.now()}2` },
      ];
    // otherwise unset timeBlocks available
    } else {
      payload.availability[value].available = false;
      payload.availability[value].timeBlocks = [];
    }
    setReduxProfileKeys(payload);
  }

  function handleAdd() {
    const payload = { availability: { ...availability } };
    payload.availability[value].timeBlocks.push({ start: '', end: '', key: `${Date.now()}` });
    setReduxProfileKeys(payload);
  }

  return (
    <div className="col-md-3">
      <div className="btn-group">
        <button
          type="button"
          style={{ width: '104px' }}
          className={`btn ${available ? 'btn-success' : 'btn-outline-secondary'}`}
          onClick={() => handleDaySelect()}
          disabled={disabled}
        >
          {display}
        </button>
        {available ? (
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={() => handleAdd()}
            disabled={disabled || (timeBlocks.length >= 3)}
          >
            +
          </button>
        ) : null}
      </div>
    </div>
  );
}

function TimezoneSelect({
  disabled,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { timezone } = reduxProfile;

  function handleChange(e) {
    const { value } = e.target;
    const payload = { timezone: value };
    setReduxProfileKeys(payload);
  }

  return (
    <select
      className={`form-select ${disabled && 'text-muted'}`}
      style={{ backgroundColor: (!timezone) ? 'rgb(var(--bs-warning-rgb), .5)' : '' }}
      value={timezone || ''}
      onChange={(e) => handleChange(e)}
      disabled={disabled}
    >
      <option value="" disabled>Select Timezone...</option>
      {TIMEZONE_OPTIONS.map(tz => (
        <option key={tz.value} value={tz.value}>{tz.display}</option>
      ))}
    </select>
  );
}

function Days({
  disabled,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { availability = {} } = reduxProfile;
  useEffect(() => {
    if (!availability.mon) {
      setReduxProfileKeys({ availability: DAYS_AVAILABLE });
    }
  }, [availability]);

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h3 className={disabled ? 'text-muted' : ''}>Days and times I am available for meetings</h3>
        </div>
        <div className="col-md-4">
          <TimezoneSelect
            disabled={disabled}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
          />
        </div>
      </div>
      <hr />
      {DAYS.map(day => (
        <div key={day.display} className="row my-3">
          <DaySelectButton
            disabled={disabled}
            day={day}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
          />
          <TimeBlocks
            disabled={disabled}
            day={day}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
          />
        </div>
      ))}
    </>
  );
}

export default Days;
