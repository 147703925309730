const initialState = {
  list: [],
  selectedNotification: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_NOTIFICATIONS':
      return { ...state, list: action.payload };
    case 'SET_SELECTED_NOTIFICATION':
      return { ...state, selectedNotification: action.payload };
    case 'CLEAR_NOTIFICATIONS':
      return initialState;
    default:
      return state;
  }
}
