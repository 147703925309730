import axios from 'axios';
import { store } from '../store';
import ciHack from '../utils/hacks';

export function loadMedigapCarrierCI(state) {
  const token = localStorage.getItem('SessionToken');
  const medigapUrl = `${process.env.REACT_APP_MMB_API_URL}/api/ci/medigap/?rating_state=${state}`;

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: medigapUrl,
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then(({ data }) => {
      if (!data.length) {
        store.dispatch({ type: 'SET_MEDIGAP_CI_NO_DATA_FLAG' });
      } else {
        const remap = ciHack(data);
        store.dispatch({ type: 'SET_MEDIGAP_CI_DATA', payload: remap });
      }
      resolve();
    }).catch((err) => {
      reject(err);
    });
  });
}

export function loadPdpCarrierCI(countyFips, state) {
  const token = localStorage.getItem('SessionToken');
  const pdpUrl = encodeURI(`${process.env.REACT_APP_MMB_API_URL}/api/ci/mamapdpdp/?county_fips=${countyFips}&rating_state=${state}&product=PDP`);

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: pdpUrl,
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then(({ data }) => {
      if (!data.length) {
        store.dispatch({ type: 'SET_PDP_CI_NO_DATA_FLAG' });
      } else {
        const remap = ciHack(data);
        store.dispatch({ type: 'SET_PDP_CI_DATA', payload: remap });
      }
      resolve();
    }).catch((err) => {
      reject(err);
    });
  });
}

export function loadMapdCarrierCI(countyFips, state) {
  const token = localStorage.getItem('SessionToken');
  const maMapdUrl = encodeURI(`${process.env.REACT_APP_MMB_API_URL}/api/ci/mamapdpdp/?county_fips=${countyFips}&rating_state=${state}&product=MAPD`);

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: maMapdUrl,
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then(({ data }) => {
      if (!data.length) {
        store.dispatch({ type: 'SET_MAPD_CI_NO_DATA_FLAG' });
      } else {
        const remap = ciHack(data);
        store.dispatch({ type: 'SET_MAPD_CI_DATA', payload: remap });
      }
      resolve();
    }).catch((err) => {
      reject(err);
    });
  });
}
