import React from 'react';

const NoteCard = ({
  note = {},
  style,
  className,

  adding = false,
  loading = false,

  onChange,
  onSave,
  onCancel,
}) => {
  // note(joeysapp): This needs to be a forwardRef I think so the ref can be reset
  // when it needs to be (e.g. Note list is updated), but it's UI sugar. Figure it out outside of work
  // const ref = useRef();
  // let isVisible = useVisible(ref, { threshold: 0.25 });
  const ref = null;
  const isVisible = true;
  return (
    <div
      className={`mb-5 p-3 bg-grey-200 border border-grey-500 overflow-hidden ${className}`}
      style={{
        opacity: isVisible ? '1' : '0.25',
        transition: 'opacity 0.5s ease',
        ...style,
      }}
      ref={ref}
    >
      {/* Basic view */}
      {!adding && (
        <div className="h-100 overflow-fade">
          <div className="d-flex mb-1 text-muted fs-6">
            <span className="fst-italic">Source: {note.source}</span>
            <span className="ms-auto me-0 text-muted">{note.created}</span>
          </div>
          <div className="mb-1 fw-bold">{note.title}</div>
          <div
            className="overflow-scroll h-75 pb-3"
            style={{
              width: 'fit-content',
              wordBreak: 'break-word',
            }}
          >
            {note.text.split('<br>').map(e => (<span key={e}>{e}<br /></span>))}
          </div>
        </div>
      )}
      {/* Adding - textbox */}
      {adding && (
        <div className="d-flex h-100 flex-column">
          <textarea
            className={`form-control h-100 ${loading ? 'text-muted' : ''}`}
            onChange={onChange}
            value={note.text || ''}
            disabled={loading}
          />
          <div className="d-flex mb-0 mt-auto fs-5">
            <div
              className="text-link ms-0 me-auto"
              onClick={onSave}
              tabIndex={0}
              role="button"
            >
              Save
            </div>
            <div
              className="text-decoration-underline ms-auto me-0"
              onClick={onCancel}
              tabIndex={0}
              role="button"
            >
              Cancel
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoteCard;
