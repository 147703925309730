/* eslint-disable quotes,max-len */
export default {
  nav: [
    `If you haven't saved your client yet, this button will appear and allow you
    to do so at any point.`,
    `Click to view and select other pharmacies.`,
    `Click here to view prescription drugs. To view, add, or edit them, go to the
    Medicine Cabinet.`,
    `Click here to view saved plan quotes. Click on any individual quote to
    see details.`,
    `Click here to add or view notes specific to this client. You can also see
    client’s local temperature and news to help you familiarize with their area.`,
    `Click here to get help, see release notes, view terms and conditions, change
    your password, and logout.`,
    `Click here to go to your Dashboard where you can find clients and their saved
    quotes.`,
    `Click here to add a new client.`,
    `Click here to confirm an enrollment.`,
    `Once you've selected a client, this submenu appears and allows you to go to
    the plan list page where you can view all plans available in the client's
    zip code.`,
    `The Medicine Cabinet allows you to view and edit your client's pharmacies,
    doctors, and drugs.`,
    `The Compare page allows you to see up to 3 plans in a side-by-side
    comparison. Recommended plans will automatically be selected, but you can
    change the selection at any time.`,
    `Click here to access training videos, view release notes, site terms and
    conditions and to change your password.  You can also log out from here.`,
    `Click "Edit Info" to change high level demographic information including
    Zipcode, DOB, Effective Date and Subsidy Level for this client.`,
    `Click here to search for Humana ONLY doctors and providers to confirm they
    are in network.`,
    `Click here to view status of this client’s Scope of Appointment.  If it says
    "New", then you will need to send an SOA via email or text before discussing
    plan options.`,
  ],
  dash: [
    `From the Client Dashboard, you can view your current clients, client
    related follow up items and list of closed sales.`,
    `Quotes are normally hidden. This automatically expands quotes for all
    clients.`,
    `Click here to add a new client.`,
    `If you're looking for a particular client, search for them by Lead ID,
    Name, or State.`,
    `Sort clients by category here.`,
    `If a lead is reassigned to a different agent you can access it's history
    here.`,
    `Click here to reveal any saved quotes.`,
  ],
  add: [
    `If you're short on time, use the quote quote to put in the minimum
    information required to look at plans and get quotes. Your client won't be
    saved, but you can do that later.`,
    `Next, fill in your client's information.`,
    `Go to the Medicine Cabinet to add your client's drugs and pharmacies. The
    information you just entered will be saved automatically.`,
    `You also have the option of going straight to the plan list without
    entering drugs or pharmacies.`,
  ],
  list: [
    `This displays your client's information.`,
    `Click any of the carrier names to filter which plans are displayed.`,
    `This section displays the lowest cost PDP and MAPD plans based on your
    client’s info, drugs and pharmacies.  For Medigap, Plan G is the default
    recommended plan.`,
    `Carrier, plan name, and Contract ID are displayed at the top.`,
    `Access additional information by clicking on any of the "?" icons.`,
    `Click here to view this plan's details.`,
    `Click here to save a quote or enroll your client in this plan.`,
    `This section displays all of the plans available to your client.`,
    `Select the type of plan you want to view here.`,
    `Click here to go to the compare page of the current plan type selected.`,
    `Carrier, plan name, and Contract ID are displayed here.`,
    `Premium, Total out-of-pocket-cost, and Network are displayed here.`,
    `Other plan highlights are shown in these sections. To see comprehensive
    details of a plan, click the "View Details" button.`,
    `For MAPD plans, filter results by SNP or non-SNP.`,
    `For Medigap plans, filter results by Network.`,
    `Medigap Plan Names, Select and Details buttons are located here on the
    left.`,
    `Differences in premium costs are shown by carrier.`,
    `The rest of the benefit highlight, which are standard across all carriers,
    are shown here.`,
  ],
  medcab: [
    `On this page you can view, edit and delete your client's pharmacies and
    prescription drugs.`,
    `Click here to add your client's drugs or pharmacies.`,
    `Select whether you want to view pharmacies or drugs.`,
    `Edit or delete a drug here.`,
  ],
  compare: [
    `If there is already a plan selected, click the Change Plan button to
    select a different one.`,
    `If there isn't a plan selected, click the Select Plan button to pick
    one.`,
    `A modal will pop up and allow you to search for and select individual
    plans to compare.`,
    `Select the plans you want to see compared.`,
    `Selected plan will show up here on the right. You can select up to 3.`,
    `Once plans have been selected, carrier, plan name, and contract ID are
    displayed here.`,
    `View this plan's details here.`,
    `Click "Select" to quote or enroll your client in this plan.`,
  ],
  details: [
    `Carrier, plan name, and Contract ID are displayed here.`,
    `Return to the Plan List Page.`,
    `Load this plan into the Compare Page.`,
    `Quote or enroll your client in this plan.`,
    `Each tab will reveal a different type of information about this plan.`,
  ],
  checkout: [
    `Some plans offer optional coverage or have discounts available. Make sure
    everything is correct here before quoting or enrolling to ensure
    accurate pricing.`,
    `See details for this plan.`,
    `This displays the breakdown of costs and the total amount your client
    will owe if enrolled in this plan.`,
    `Save a quote of this plan.`,
    `Begin the enrollment process for this plan.`,
    `Click any of the plan documents to view or download them.`,
  ],
  provider: [
    `The cost of treatment that members received compared to treatment provided by similar physicians. Ratings range from four badges (highest) to one badge (lowest). Not all providers will have a rating. This rating applies to 1 specialty of the doctor or facility. It may or may not be the doctor or facility’s primary specialty.`,
    `The effectiveness of treatment that members received may be measured qualitatively. Ratings range from four hearts (highest) to one heart (lowest). Not all providers will have a rating. This rating applies to 1 specialty of the doctor or facility. It may or may not be the doctor or facility’s primary specialty.`,
  ],
};
