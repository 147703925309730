import React from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CallCount({ expanded, calls }) {
  const icon = expanded ? faChevronUp : faChevronDown;
  const bgColor = expanded ? 'var(--bs-medium-grey)' : '';

  return (
    <div>
      <button
        type="button"
        className="btn"
        style={{
          backgroundColor: bgColor,
          borderRadius: '5px',
          paddingRight: '5px',
          paddingLeft: '5px',
          paddingTop: '0px',
          paddingBottom: '0px',
        }}
      >
        {calls.length}
        <FontAwesomeIcon
          size="sm"
          icon={icon}
          className={['ms-2', calls.length ? '' : 'd-none'].join(' ')}
        />
      </button>
    </div>
  );
}
