import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { useSelector, useDispatch } from 'react-redux';
import { SlideoutModal } from '../../common/components';

function BenefitDetailsSlideout() {
  const dispatch = useDispatch();
  const { benefitDetailsSlideout } = useSelector(store => store.modal);
  const {
    benefitName = 'benefitName',
    benefitNotes = [],
  } = useSelector(store => (store.plan.benefitDetails || {}));

  function closeAction() {
    dispatch({ type: 'HIDE_MODAL', payload: 'benefitDetailsSlideout' });
    dispatch({ type: 'SET_PLAN_KEY', payload: { benefitDetails: {} } });
  }

  return (
    <SlideoutModal
      isOpen={benefitDetailsSlideout}
      closeModalAction={() => closeAction()}
      width="40%"
      title={`Benefit Details - ${benefitName}`}
    >
      <div className="row ps-3 pe-4">
        <div className="pb-5 pt-3">
          <div style={{ overflowY: 'auto' }}>
            {benefitNotes.map((e) => {
              if (e) return (<p>{HTMLReactParser(String(e))}</p>);
              return null;
            })}
          </div>
        </div>
      </div>
    </SlideoutModal>
  );
}

export default BenefitDetailsSlideout;
