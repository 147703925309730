import React from 'react';

function Caret({
  isOpen,
  className = '',
}) {
  return (
    <i
      className={`fa fs-5 ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'} pe-none ${className}`}
      style={{ marginLeft: '-25px', zIndex: '6' }}
    />
  );
}

export default Caret;
