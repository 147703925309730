import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

// Actions
import { agentLogout } from '../../actions/agent';

/**
 * Modal to bring an inactive user back to the login page.
 */
class SessionExpiredModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { time: 20 };
    this.tick = this.tick.bind(this);
    this.onOpened = this.onOpened.bind(this);
    this.logout = this.logout.bind(this);
  }

  onOpened() {
    const {
      dispatch,
    } = this.props;
    // Start counting down.
    const logoutInterval = setInterval(this.tick, 1000);
    dispatch({ type: 'ADD_INTERVAL', payload: logoutInterval });
    this.setState({ time: 20 });
  }

  logout() {
    const {
      dispatch,
    } = this.props;
    dispatch({ type: 'CLEAR_INTERVALS' });
    dispatch({ type: 'HIDE_SESSION_EXPIRED_MODAL' });
    agentLogout();
  }

  tick() {
    const {
      time,
    } = this.state;

    const timeDelta = 1;
    const newTime = time - timeDelta;
    if (newTime > 0) {
      this.setState({ time: newTime });
    } else {
      this.logout();
    }
  }

  render() {
    const {
      isOpen,
    } = this.props;

    const {
      time,
    } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        backdrop="static"
        className="text-center"
        onOpened={this.onOpened}
      >
        <ModalBody>
          <h1>
            Your session has expired due to inactivity.
            <br />
            Please log in again.
          </h1>
          <button
            className="btn btn-primary"
            onClick={this.logout}
            type="button"
          >
            Continue ({time})
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

SessionExpiredModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

function mapStateToProps(store) {
  return {
    isOpen: store.session.expiredModalIsOpen,
  };
}

export default connect(mapStateToProps)(SessionExpiredModal);
