import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faStethoscope } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import NavItem from './NavItem';
import NavPopover from './NavPopover';
import MarkImportant from '../../../doctors/MarkImportant';

// Utils
import { fmtDocSpecialties } from '../../../../utils/formatFunctions';

function Doctors({
  dispatch,
  doctorList,
  navigate,
  showDoctorPopover,
  disabled,
}) {
  const [parentRef, setParentRef] = useState();

  return (
    <NavItem
      show={showDoctorPopover}
      icon={faStethoscope}
      count={doctorList.length}
      popoverName="showDoctorPopover"
      setRef={setParentRef}
      title={'Add doctor'}
    >
      <NavPopover
        show={showDoctorPopover}
        popoverName="showDoctorPopover"
        parentRef={parentRef}
      >
        <b>{doctorList.length} {doctorList.length > 1 || !doctorList.length ? 'Doctors' : 'Doctor'}</b>
        <div className="col navbar-drug-card-container mt-3">
          {
            doctorList && doctorList.length > 0
              ? (
                doctorList.map((doc) => (
                  <div key={doc.addressID || doc.id} className="row navbar-drug-card">
                    <div className="col py-2">
                      <div className="d-flex flex-row">
                        <MarkImportant
                          selected
                          doctor={doc}
                          size="sm"
                          spanClasses="navbar-doc-star me-2 ms-0"
                          iconClasses="ms-0"
                          hideText
                        />
                        <b>{doc.name}</b>
                      </div>
                      <div style={{ marginLeft: '1.75rem' }}>
                        <i>
                          {fmtDocSpecialties(doc.specialties, doc.npi)}
                        </i>
                      </div>
                    </div>
                  </div>
                ))
              )
              : (
                <div className="row navbar-drug-card text-center align-middle">
                  <div className="col py-3">
                    <i>Looks like no doctors have been added yet.</i>
                  </div>
                </div>
              )
          }
        </div>
        <button
          type="button"
          disabled={disabled}
          className="btn btn-primary fw-bold mt-3"
          onClick={() => {
            navigate('/doctors/search');
            dispatch({ type: 'HIDE_POPOVER', payload: 'showDoctorPopover' });
          }}
        >
          Add doctor
        </button>
      </NavPopover>
    </NavItem>
  );
}

function mapStateToProps(store) {
  return {
    showDoctorPopover: store.popover.showDoctorPopover,
    doctorList: store.shopper.doctorList,
  };
}

export default connect(mapStateToProps)(withRouterProps(Doctors));
