const initialState = {
  daysUntilReset: null,
  highContrast: false,
  isAdmin: false,
  lastUpdated: 0,
  lifespan: 0,
  needsPasswordReset: false,
  passwordExpired: false,
  token: '',
  username: '',
  statePermissions: [],
  carrierPermissions: [],
  mmbPermissions: {},
  appointmentsProfile: {},
  acceptingAppointments: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_AGENT':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_AGENT':
      return { ...state, ...action.payload };
    case 'CLEAR_AGENT':
      return { ...initialState };
    default:
      return state;
  }
}
