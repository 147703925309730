import React from 'react';
import { connect } from 'react-redux';

class Packages extends React.PureComponent {
  setSelectedPackage = (event) => {
    const { dispatch, packageList } = this.props;
    const packageID = event.target.value;
    const selectedPackage = packageList.find((p) => Number(p.PackageID) === Number(packageID));
    dispatch({ type: 'SET_SELECTED_PACKAGE', payload: selectedPackage });
  };

  render() {
    const {
      packageList,
      selectedPackage,
    } = this.props;

    return (
      <>
        <select
          disabled={packageList.length <= 0}
          id="rx-select-dosage-input"
          className="form-control h-100"
          value={selectedPackage.PackageID}
          onChange={(e) => this.setSelectedPackage(e)}
        >
          {packageList.map((pckg) => (
            <option
              key={`dosage-option-${pckg.PackageID}`}
              value={pckg.PackageID}
            >
              {pckg.PackageDescription}
            </option>
          ))}
        </select>
        {packageList.length > 0 && (
          <i
            className="fa fa-chevron-down"
            style={{
              fontSize: '15px',
              marginLeft: '-40px',
              marginTop: '20px',
              paddingLeft: '4px',
              position: 'relative',
              pointerEvents: 'none',
              left: '15px',
              backgroundColor: 'white',
              bottom: '1px',
            }}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    packageList: store.drug.packageList,
    selectedPackage: store.drug.selectedPackage,
  };
}

export default connect(mapStateToProps)(Packages);
