/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

function ModalHeader({
  closeModalAction,
  closeIcon,
  headerClasses,
  h2Classes,
  title,
}) {
  return (
    <div className={`p-3 ${headerClasses}`}>
      <h4 className={h2Classes}>
        {title}
        {closeIcon
          ? (
            <FontAwesomeIcon
              role="button"
              className="float-end"
              tabIndex={0}
              icon={faTimes}
              onClick={closeModalAction}
            />
          ) : null}
      </h4>
    </div>
  );
}

function ModalBase({
  allowOverlayClickClose = false,
  children,
  closeModalAction = () => {},
  closeIcon = true,
  headerClasses = '',
  h2Classes = 'fw-bold mb-0',
  hideHeader = false,
  id,
  isOpen = false,
  // modalClasses = '',
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  style,
  title,
  width = '500px',
}) {
  return (
    <div
      id={id}
      onClick={allowOverlayClickClose ? closeModalAction : () => {}}
      className="mmb-modal-custom"
      style={{
        opacity: isOpen ? '1' : '0',
        visibility: isOpen ? 'visible' : 'hidden',
      }}
    >
      <div
        style={{
          display: 'block',
          background: 'white',
          maxWidth: width,
          maxHeight: '100%',
          overflow: 'auto',
          marginTop: '-15%',
          ...style,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            paddingTop: paddingTop || padding || '10px',
            paddingBottom: paddingBottom || padding || '10px',
            paddingRight: paddingRight || padding || '20px',
            paddingLeft: paddingLeft || padding || '20px',
          }}
        >
          {!hideHeader && (
            <ModalHeader
              closeModalAction={closeModalAction}
              closeIcon={closeIcon}
              headerClasses={headerClasses}
              h2Classes={h2Classes}
              title={title}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalBase;
