import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isRequired,
  validateField,
  getValidClass,
} from '../../utils';

import FormLabel from './FormLabel';
import FieldError from './FieldError';

function TextInput({
  disabled,
  displayValue,
  label,
  name,
  placeholder,
  cols = '3',
  handleChange,
  maxLength,
  onUndefinedValue,
  onFalseyValue,
}) {
  const dispatch = useDispatch();
  const sizeClass = `col-md-${cols}`;
  const value = useSelector(store => store.shopper[name]);

  const { shopper = {} } = useSelector(store => store);
  const { schema = {}, validation = {} } = useSelector(store => store.shopper);
  const required = isRequired({ name, schema, currentValues: shopper });
  // errors are set during validation when form submission is attempted
  const validClass = getValidClass({ name, schema, currentValues: shopper, validation, required });
  const isValid = validation[name];

  const classes = ['form-control', validClass].join(' ');

  /*
  useEffect(() => {
    validateField({ name, schema, currentValues: shopper, newValue: value });
  }, [value]);
  */

  async function defaultHandleChange(e, callback) {
    const v = e.target.value;
    let callbackVal;

    if (callback) {
      callbackVal = await callback(e);
    } else {
      const val = e.target.value;
      dispatch({ type: 'UPDATE_SHOPPER', payload: { [name]: val } });
    }
    if (isValid !== undefined) {
      validateField({ name, schema, currentValues: shopper, newValue: (callbackVal || v), required });
    }
  }

  let handleNullValue = value;
  if (onFalseyValue && !value) {
    handleNullValue = onFalseyValue;
  } else if (onUndefinedValue && (value === undefined)) {
    handleNullValue = onUndefinedValue;
  }

  return (
    <div className={['mt-3', sizeClass].join(' ')}>
      <FormLabel name={name} label={label} required={required} />
      <input
        id={`shopper-input-${name}`}
        type="text"
        className={classes}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => defaultHandleChange(e, handleChange)}
        value={displayValue || handleNullValue}
        onBlur={() => validateField({
          name,
          schema,
          currentValues: shopper,
          newValue: value,
          required,
          forceValidation: true,
        })}
      />
      <FieldError name={name} />
    </div>
  );
}

export default TextInput;
