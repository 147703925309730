import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { handleSubmit } from '../../utils';

function ButtonText({ loading }) {
  const { username } = useSelector(store => store.shopper);

  if (loading) {
    return (
      <>
        <div className="spinner-border spinner-border-sm me-1" />
        Saving...
      </>
    );
  } else if (username) {
    return 'Save changes';
  }
  return 'Create shopper';
}

function SaveButton({
  disabled,
  divClass,
  buttonClass,
  buttonStyle,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    shopper,
    shopper: { lastUpdated },
  } = useSelector(store => store);
  const lastSaved = lastUpdated ? moment(lastUpdated).format('MM/DD/YYYY h:mm A') : '';

  const ddc = divClass || 'mt-4 d-grid gap-2';
  const dbc = buttonClass || 'btn btn-primary fw-bold';

  return (
    <div className={ddc}>
      <button
        type="button"
        className={dbc}
        style={buttonStyle}
        onClick={() => {
          handleSubmit(shopper, dispatch, setLoading)
            .then((data) => {
              if (data) {
                navigate(`/shopper/${data.username}`);
              }
            }).finally(() => setLoading(false));
        }}
        disabled={loading || disabled}
      >
        <ButtonText loading={loading} />
      </button>
      <div style={{ color: 'var(--bs-primaryFocus' }} className="fw-bold">
        {lastSaved && (
          <i>Last saved {lastSaved}</i>
        )}
      </div>
    </div>
  );
}

export default SaveButton;
