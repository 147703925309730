import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../utils/hocs';
import LoaderButton from '../../common/LoaderButton';

class BackToClients extends React.PureComponent {
  render() {
    const {
      backButtonText,
      backOnClick,
      confirmationModal,
      dispatch,
      navigate,
    } = this.props;

    const btnText = (
      <span>
        <FontAwesomeIcon
          style={{ color: 'black' }}
          icon={faAngleLeft}
          className="fa-lg me-2"
        />
        <b style={{ color: 'black' }}>
          {backButtonText}
        </b>
      </span>
    );

    return (
      <LoaderButton
        color="link"
        text={btnText}
        onClick={() => {
          if (backOnClick) {
            backOnClick();
          } else if (confirmationModal) {
            dispatch({ type: 'SHOW_MODAL', payload: 'discardChangesModal' });
          } else {
            navigate('/dashboard');
          }
        }}
      />
    );
  }
}

BackToClients.defaultProps = {
  backButtonText: 'Back to clients',
  backOnClick: false,
};

export default connect()(withRouterProps(BackToClients));
