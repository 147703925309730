import React from 'react';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import './index.scss';

function PrintableEnrollment({
  enrollment = {},
  agent = {},

  // A state setter we use to tell parent views all required info has been loaded and is printable
  setLoaded = () => {},

  className,
  style,
  id,
}) {
  return (
    <div
      className={[
        'd-flex flex-column w-100 justify-content-center',
        'printable-form',
        className,
      ].join(' ')}
      style={style}
      id={id}
    >
      <div className="page p-3">
        <PageOne enrollment={enrollment} agent={agent} />
      </div>
      <div className="page p-3">
        <PageTwo enrollment={enrollment} setLoaded={setLoaded} />
      </div>
    </div>
  );
}
export default PrintableEnrollment;
