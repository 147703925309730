import React from 'react';
import { connect } from 'react-redux';

// Components
import SOABadge from '../../../common/SOABadge';
import { getPlanCarrierDisplay } from '../../../../utils/general';
import { renderDisplayCPS } from '../../../../utils/plans';

class ColHeader extends React.PureComponent {
  render() {
    const {
      plan,
      planLabel,
      shopper,
    } = this.props;

    let {
      planHover,
      planTitle,
    } = this.props;

    if (plan.planType === 'MEDIGAP') {
      planTitle = <span className="text-fade">Not available</span>;
      planHover = 'Not available';
    }
    if (!plan && !planTitle) {
      planTitle = <span className="text-fade">No plans available</span>;
      planHover = 'Not available';
    }

    const planTypeDisplays = {
      MA: 'Recommended MA plan',
      MAPD: 'Recommended MAPD plan',
      PDP: 'Recommended PDP plan',
      MEDIGAP: 'Recommended Med Supp plan',
    };

    const displayName = getPlanCarrierDisplay({ plan });

    return (
      <div className="planlist-recommended-col-header">
        <div
          className="planlist-recommended-col-header-plan-type"
          title={planLabel || planTypeDisplays[plan.planType]}
        >
          <span className="w-100 d-flex flex-row align-items-center">
            <span>{planLabel || planTypeDisplays[plan.planType]}</span>
            <SOABadge planType={plan.planType} badgeType="icon" />
            {shopper.currentPlan === plan.cpsID
              ? (
                <span className="me-0 ms-auto border border-primaryFocus bg-primaryFocusBackground p-0 ps-2 pe-2 r-3">
                  CURRENT PLAN
                </span>
              ) : null}
          </span>
        </div>
        {plan.planName ? <span id="plans-loaded-indicator" /> : null}
        <div
          className="planlist-recommended-col-header-carrier"
          title={planHover || displayName}
        >
          {planTitle || displayName}
        </div>
        <div
          className="planlist-recommended-col-header-name"
          title={plan.planName}
        >
          {plan.planType !== 'MEDIGAP' ? (plan.planName || '--') : '--'}
        </div>
        {<div title={`Contract ID: ${renderDisplayCPS(plan.plan)}`}>{renderDisplayCPS(plan.plan)} </div> || '--'}
      </div>
    );
  }
}

ColHeader.defaultProps = {
  plan: {},
};

const mapStateToProps = (state) => ({
  blank: state.blank,
  shopper: state.shopper,
});

export default connect(mapStateToProps)(ColHeader);
