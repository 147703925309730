import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';
import { validateFields } from '../../../utils/general';

// Actions
import { createShopper, updateShopper } from '../../../actions/shopper';

// Components
import LoaderButton from '../../common/LoaderButton';
import StandardModal from '../../common/StandardModal';

// Utilities
import { showPopup } from '../../../utils/popup';

class BirthdayWarningModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(redirect) {
    const {
      dispatch,
      forceLIS,
      medigap,
      navigate,
      shopper,
    } = this.props;

    const validationFields = [
      { type: 'string', errmsg: 'Please Enter a valid Zip Code', val: shopper.county },
      { type: 'date', errmsg: 'Please Enter an Effective Date', val: shopper.effectiveDate },
    ];
    if (!shopper.quickQuote) {
      // validationFields.push({ type: 'string', errmsg: 'Please Enter a Lead ID', val: this.props.shopper.leadID });
      validationFields.push({ type: 'string', errmsg: 'Please Enter a First Name', val: shopper.firstName });
    }
    if (shopper.genderRequired && medigap) {
      validationFields.push({ type: 'string', errmsg: 'Please Select a Gender', val: shopper.gender });
    }
    if (shopper.tobaccoRequired && medigap) {
      validationFields.push({ type: 'string', errmsg: 'Please Select a Tobacco use status', val: shopper.tobacco });
    }
    if (forceLIS) {
      validationFields.push({ type: 'string', errmsg: 'Please enter a Valid LIS Value', val: shopper.subsidyLevel });
    }
    validateFields(validationFields).then(() => {
      if (shopper.quickQuote) {
        dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' });
        navigate(redirect);
      } else if (shopper.username) {
        updateShopper(shopper)
          .then(() => {
            dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' });
            showPopup('Success', 'Updated Shopper!');
            this.setState({ loading: false });
            navigate(redirect);
          }).catch(() => {
            showPopup('Error', 'There was a problem updating the Shopper.');
            this.setState({ loading: false });
          });
      } else {
        createShopper(shopper)
          .then((data) => {
            dispatch({ type: 'UPDATE_SHOPPER', payload: data });
            dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' });
            showPopup('Success', 'Added Shopper!');
            this.setState({ loading: false });
            navigate(redirect);
          }).catch(() => {
            showPopup('Error', 'There was a problem creating the Shopper.');
            this.setState({ loading: false });
          });
      }
    }).catch(() => {
      dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' });
      showPopup('Error', 'There was a problem.');
      this.setState({ loading: false });
    });
  }

  render() {
    const {
      birthdayWarningModal,
      birthdayWarningRouteLink,
      dispatch,
    } = this.props;

    const {
      loading,
    } = this.state;

    return (
      <StandardModal
        isOpen={birthdayWarningModal}
        closeModalAction={() => dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' })}
        title="Warning!"
      >
        <div className="px-3">
          Are you sure you want to quote a shopper under 65 years old?
          Plan availability and rates may vary.
        </div>
        <div className="px-3 pb-3 mt-2 text-right">
          <LoaderButton
            id="birthday-modal-continue"
            text="Continue"
            loadingText="Loading..."
            onClick={() => {
              this.setState({ loading: true });
              this.handleSubmit(birthdayWarningRouteLink);
            }}
            loading={loading}
          />
          <button
            className="btn btn-primary ms-1"
            onClick={() => dispatch({ type: 'HIDE_MODAL', payload: 'birthdayWarningModal' })}
            type="button"
          >
            Cancel
          </button>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    birthdayWarningModal: store.modal.birthdayWarningModal,
    birthdayWarningRouteLink: store.modal.birthdayWarningRouteLink,
    forceLIS: store.theme.features.force_lis,
    medigap: store.theme.features.medigap,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(BirthdayWarningModal));
