import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../utils/hocs';

import StandardModal from './StandardModal';
import LoaderButton from './LoaderButton';

class DiscardChangesModal extends React.PureComponent {
  render() {
    const {
      discardChangesModal,
      dispatch,
      handleSubmit,
      loading,
      loadingTrigger,
      navigate,
    } = this.props;
    return (
      <StandardModal
        isOpen={discardChangesModal}
        closeModalAction={() => dispatch({ type: 'HIDE_MODAL', payload: 'discardChangesModal' })}
        closeIcon
        id="dicard-changes-modal"
        width={400}
      >
        <div
          className="col"
          style={{ paddingTop: '50px', paddingBottom: '50px' }}
        >
          <div className="d-flex row mb-4 justify-content-center">
            <b className="w-auto">Do you want to save changes for this client?</b>
          </div>
          <div className="d-flex row justify-content-center">
            <LoaderButton
              id="exit-modal-save"
              tabIndex={0}
              className="me-4 px-4 w-auto"
              text="Discard"
              color="outline-secondary"
              onClick={() => {
                dispatch({ type: 'HIDE_MODAL', payload: 'discardChangesModal' });
                dispatch({ type: 'CLEAR_EVENT' });
                navigate('/dashboard');
              }}
            />
            <LoaderButton
              className="w-auto"
              text="Save"
              color="primary"
              loading={loading}
              onClick={() => {
                if (loadingTrigger) loadingTrigger();
                handleSubmit();
              }}
            />
          </div>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    discardChangesModal: store.modal.discardChangesModal,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(DiscardChangesModal));
