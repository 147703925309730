import React from 'react';

import { Radio } from '../components/inputs';

function Conditions({ pageTitle }) {
  return (
    <div>
      <div className="fw-bold">{pageTitle}</div>
      <div className="d-flex flex-column">
        <Radio name="treatment_text" id={1} labelClassName="fw-bold fs-4 text-secondary" disabled />
        <div className="ms-2 ps-2 border-start">
          <Radio
            name="has_copd"
            className="d-flex"
          />
          <Radio
            name="has_cancer"
            className="d-flex"
          />
          <Radio
            name="has_diabetes"
            className="d-flex"
          />
          <Radio
            name="has_kidney_failure"
            className="d-flex"
          />
          <Radio
            name="has_heart_misc"
            className="d-flex"
          />
          <Radio
            name="has_heart_failure"
            className="d-flex"
          />
          <Radio
            name="has_hbp"
            className="d-flex"
          />
          <Radio
            name="has_mental"
            className="d-flex"
          />
          <Radio
            name="has_obesity"
            className="d-flex"
          />
          <Radio
            name="has_stroke"
            className="d-flex"
          />
          <Radio
            name="has_other"
            className="d-flex"
          />
        </div>
      </div>
    </div>
  );
}

export default Conditions;
