import React, { useEffect, useRef } from 'react';

function YoutubeEmbed({
  embedURL,
  height,
  width,
  style,
  setPauseFunc = () => {}, // eslint-disable-line
}) {
  const playerRef = useRef(null);

  useEffect(() => {
    console.log('running YoutubeEmbed.useEffect');
    // This function loads the IFrame Player API code asynchronously.
    const loadYouTubeIframeAPI = () => {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://www.youtube.com/iframe_api';
      // Insert the script tag before other scripts
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    };

    function onPlayerReady() {
      const pf = () => (() => window.YT_PLAYER.pauseVideo());
      setPauseFunc(pf);
    }

    // This function creates an <iframe> and YouTube player after the API code downloads.
    window.onYouTubeIframeAPIReady = () => {
      // eslint-disable-next-line
      window.YT_PLAYER = new window.YT.Player('youtube-player', {
        events: { onReady: onPlayerReady },
      });
    };

    if (!window.YT) { // Load the YouTube IFrame API if not loaded
      loadYouTubeIframeAPI();
    } else { // If YT is already available, directly create the player
      window.onYouTubeIframeAPIReady();
    }
  }, [embedURL]); // Re-run effect if embedURL changes

  return (
    <iframe
      title="youtube-tutorial-player"
      id="youtube-player"
      src={embedURL}
      ref={playerRef}
      height={height}
      width={width}
      style={style}
    />
  );
}

export default YoutubeEmbed;
