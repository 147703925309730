/* eslint-disable */
import axios from 'axios';
import { store } from '../store';

export function loadEnrollment(params = { }) {
  const token = localStorage.getItem('SessionToken');
  const {
    quoteID = '',
  } = params;

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/enrollment/${quoteID}/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
    }).then(({ data = {} }) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    });
  });
}
