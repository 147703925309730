import React from 'react';
import { connect } from 'react-redux';

class GuideHighlight extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { ref: React.createRef() };
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen,
      isScrolling,
      side = 'r',
      i,
      id,
      book,
      disabled,
      padding = 0,
      addMarker,
    } = this.props;
    const {
      isOpen: wasOpen,
      isScrolling: wasScrolling,
    } = prevProps;
    const { ref } = this.state;

    const exists = Boolean(book[id][i]);
    const didOpen = !wasOpen && isOpen;
    const doneScrolling = wasScrolling && !isScrolling;
    const shouldRefresh = isOpen && doneScrolling;

    if (!disabled && exists && (didOpen || shouldRefresh)) {
      const rect = ref.current.getBoundingClientRect();
      addMarker({
        width: rect.width + 2 * padding,
        height: rect.height + 2 * padding,
        top: rect.top - padding,
        left: rect.left - padding,
        side,
        i,
        id,
      });
    }
  }

  render() {
    const {
      children,
      tag = 'div',
      className = '',
      style,
    } = this.props;
    const { ref } = this.state;
    const Tag = tag;

    return (
      <Tag
        className={`guide-highlight ${className}`}
        style={style}
        ref={ref}
      >
        {children}
      </Tag>
    );
  }
}

const mapStateToProps = (store) => ({
  isOpen: store.guide.isOpen,
  book: store.guide.book,
  isScrolling: store.guide.isScrolling,
});

const mapDispatchToProps = (dispatch, { id }) => ({
  addMarker: (marker) => dispatch({ type: 'ADD_GUIDE_MARKER', id, marker }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideHighlight);
