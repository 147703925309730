/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

// Actions
import { createAccount } from '../../../actions/agent_management';

// Components
import LoaderButton from '../../common/LoaderButton';

// Util
import FormInput from '../../common/FormInput';
import FormInputPhone from '../../common/FormInputPhone';
import { isFieldValid } from '../../soa/send/VoiceSOA';


function CreateAccountForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const {
    signup: {
      first_name = '',
      middle_name = '',
      last_name = '',
      email = '',
      email_confirm = '',
      signupSuccessful = '',
      password = '',
      password_confirm = '',
      mobile_phone = '',
      npn_linked = '',
      npn_type = '',
      errors = {},
    } = {},
  } = useSelector(store => store);
  const {
    assets: {
      loginHeaderLogo = 'https://mmb-static-assets-prod.s3.amazonaws.com/img/agent-portal/MMB+logo+horizontal+blue.png',
    } = {},
  } = useSelector(store => store.theme);

  const setLoading = (payload) => {
    setloading(payload);
    dispatch({ type: 'SET_ROOT_LOADER', payload });
  };

  function isValid(field, value) {
    if (!value) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'This field is required' });
      return false;
    }
    if (field === 'password') {
      let passwordErrors = 0;
      if (value.length < 8 || value.length > 20) {
        dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Password must be between 8 - 20 characters' });
        passwordErrors++;
      } else {
        let passwordCorrectItemCount = 4;
        // 0 if present, -1 if not
        passwordCorrectItemCount += value.search(/[a-z]/) === -1 ? -1 : 0;
        passwordCorrectItemCount += value.search(/[A-Z]/) === -1 ? -1 : 0;
        passwordCorrectItemCount += value.search(/[0-9]/) === -1 ? -1 : 0;
        passwordCorrectItemCount += value.search(/[^a-zA-Z0-9]/) === -1 ? -1 : 0;
        if (passwordCorrectItemCount < 3) {
          dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'This does not meet password requirements.' });
          passwordErrors++;
        }
      }
      if (value === password_confirm) {
        dispatch({ type: 'SET_SIGNUP_ERROR', field: 'password_confirm', payload: '' });
      } else {
        dispatch({ type: 'SET_SIGNUP_ERROR', field: 'password_confirm', payload: 'Passwords must match.' });
        passwordErrors++;
      }
      if (passwordErrors) return false;
    }
    if (field === 'password_confirm' && value !== password) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Passwords must match.' });
      return false;
    }
    if (field === 'email') {
      let emailErrors = 0;
      if (!isFieldValid({
        // eslint-disable-next-line no-useless-escape
        validationRegex: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        value,
      })) {
        dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Please enter a valid email address' });
        emailErrors++;
      }
      if (value === email_confirm) {
        dispatch({ type: 'SET_SIGNUP_ERROR', field: 'email_confirm', payload: '' });
      } else {
        dispatch({ type: 'SET_SIGNUP_ERROR', field: 'email_confirm', payload: 'Emails must match.' });
        emailErrors++;
      }
      if (emailErrors) return false;
    }
    if (field === 'email_confirm' && value !== email) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Emails must match.' });
      return false;
    }
    if (field === 'mobile_phone' && !isFieldValid({ validationRegex: '^[0-9]{10}$', value })) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Please enter a valid phone number' });
      return false;
    }
    dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: '' });
    return true;
  }

  function handleChange({ target: { value, name } }, validate = true) {
    dispatch({ type: 'UPDATE_SIGNUP_FIELD', field: name, payload: value });
    if (validate) isValid(name, value);
  }

  function validToSubmit() {
    const emailValid = isValid('email', email);
    const emailConfirmValid = isValid('email_confirm', email_confirm);
    const passwordValid = isValid('password', password);
    const passwordConfirmValid = isValid('password_confirm', password_confirm);
    const lastNameValid = isValid('last_name', last_name);
    const firstNameValid = isValid('first_name', first_name);
    const mobilePhoneValid = isValid('mobile_phone', mobile_phone);
    if (!firstNameValid || !lastNameValid || !emailValid || !emailConfirmValid || !passwordValid || !passwordConfirmValid || !mobilePhoneValid) return false;
    return true;
  }


  function handleSubmit() {
    if (!validToSubmit()) return;
    setLoading(true);
    createAccount({
      first_name,
      last_name,
      middle_name,
      email,
      email_confirm: email,
      password,
      password_confirm: password,
      mobile_phone,
      npn_linked,
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_SIGNUP',
          payload: {
            signupSuccessful: true,
            first_name,
            last_name,
            npn_linked,
            middle_name,
            email,
            password,
            mobile_phone,
          },
        });
      }).catch(({ response }) => {
        let errmsg = 'There was a problem';
        if (response && response.data) {
          const { data } = response;
          errmsg = '';
          if (data.non_field_errors && data.non_field_errors.length) {
            data.non_field_errors.forEach((e, idx) => {
              errmsg += `${idx > 0 ? ', ' : ''}${e}`;
            });
          } else if (response.status === 409) {
            errmsg = 'This email or secondary NPN has already been registered';
          } else if (Object.keys(data).length) {
            const fieldName = Object.keys(data)[0];
            errmsg = `${fieldName}: ${data[fieldName]}`;
          }
        }
        setErrorMsg(errmsg);
      }).finally(() => {
        setLoading(false);
      });
  }

  return (
    <div
      className="signup me-0 ms-auto"
      style={{
        textAlign: 'left',
        alignSelf: 'center',
        border: '1px solid lightgrey',
        borderRadius: '2px',
        padding: '3rem',
        paddingTop: '2rem',
        paddingBottom: '2rem',
      }}
    >
      <div
        style={{
          opacity: signupSuccessful ? '0' : '1',
          pointerEvents: signupSuccessful ? 'none' : 'auto',
          maxHeight: signupSuccessful && '50vh',
        }}
      >
        <div className="signup-row">
          <FormInput
            controlId="signup-email"
            label="Email"
            value={email}
            name="email"
            placeholder="John@mail.com"
            onChange={(e) => handleChange(e)}
            invalid={!!errors.email}
            error={errors.email}
            className="client-info-input w-50"
            required
          />
          <FormInput
            className="client-info-input w-50 ms-3"
            controlId="signup-email-verify"
            label="Confirm email"
            value={email_confirm}
            name="email_confirm"
            placeholder="email"
            onChange={(e) => handleChange(e)}
            invalid={!!errors.email_confirm}
            error={errors.email_confirm}
            required
          />
        </div>

        <div className="signup-row">
          <FormInput
            className="client-info-input w-50"
            controlId="signup-password"
            label="Password"
            value={password}
            name="password"
            placeholder=""
            onChange={(e) => handleChange(e)}
            invalid={!!errors.password}
            error={errors.password}
            required
            type={passwordType}
            append={(
              <span
                className="login-input-password-visibility-toggle-span"
                onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={passwordType === 'password' ? faEyeSlash : faEye} />
              </span>
                )}
          />
          <FormInput
            className="client-info-input w-50 ms-3"
            controlId="signup-password_confirm"
            label="Confirm password"
            value={password_confirm}
            name="password_confirm"
            placeholder="Confirm password"
            onChange={(e) => handleChange(e)}
            invalid={!!errors.password_confirm}
            error={errors.password_confirm}
            required
            type={passwordType}
            append={(
              <span
                className="login-input-password-visibility-toggle-span"
                onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                role="button"
                tabIndex={0}
              >
                <FontAwesomeIcon icon={passwordType === 'password' ? faEyeSlash : faEye} />
              </span>
                )}
          />
        </div>

        <div className="signup-row">
          <FormInput
            className="client-info-input w-50"
            controlId="signup-first_name"
            label="First name"
            value={first_name}
            name="first_name"
            placeholder=""
            onChange={(e) => handleChange(e)}
            invalid={!!errors.first_name}
            error={errors.first_name}
            required
          />
          <FormInput
            className="client-info-input w-25 ms-3"
            controlId="signup-last_name"
            label="Last name"
            value={last_name}
            name="last_name"
            placeholder=""
            onChange={(e) => handleChange(e)}
            invalid={!!errors.last_name}
            error={errors.last_name}
            required
          />
          <FormInput
            className="client-info-input flex-1 ms-3"
            controlId="signup-middle_name"
            label="Middle name"
            value={middle_name}
            name="middle_name"
            placeholder=""
            onChange={(e) => handleChange(e, false)}
          />
        </div>

        <div className="signup-row">

          <FormInputPhone
            className="client-info-input w-50"
            id="shopper-home-phone"
            label="Contact telephone number"
            value={mobile_phone}
            name="mobile_phone"
            placeholder="e.g. (123) 456 7890"
            onChange={(e) => handleChange(e)}
            invalid={!!errors.mobile_phone}
            error={errors.mobile_phone}
            required
          />

          <FormInput
            className="client-info-input flex-1 ms-3"
            controlId="signup-npnLinked"
            label={`${npn_type === 'individual' ? 'Corporate' : 'Individual'} NPN`}
            value={npn_linked}
            name="npn_linked"
            placeholder=""
            onChange={(e) => handleChange(e, false)}
          />

        </div>


        <div className="mt-4 d-flex flex-row">
          <LoaderButton
            color="primary"
            text="Finish"
            loadingText="Loading..."
            className="px-2 fw-bold"
            loading={loading}
            onClick={() => {
              setErrorMsg('');
              handleSubmit();
            }}
          />
          <div
            id="login-landing-return"
            className="me-0 ms-auto"
            role="button"
            tabIndex={0}
            style={{ alignSelf: 'center', fontSize: '15px' }}
            onClick={() => {
              dispatch({ type: 'CLEAR_SIGNUP' });
              navigate('/');
            }}
          >
            Sign in instead
          </div>
        </div>
      </div>

      {errorMsg && (
        <div className="alert alert-danger mt-3 text-pre" role="alert">
          {errorMsg}
        </div>
      )}

      <div style={{ opacity: signupSuccessful ? '1' : '0', pointerEvents: signupSuccessful ? 'auto' : 'none' }}>
        <div className="mb-4 text-primary position-absolute" style={{ top: '2rem' }}>
          <img
            alt="MyMedicareBot"
            className="login-header-logo"
            style={{ height: '28px', width: 'auto' }}
            src={loginHeaderLogo}
          />
          <div className="text-primary fw-bold" style={{ fontSize: '28px' }}>Congratulations!</div>
          <div className="text-primary d-flex flex-row" style={{ fontSize: '16px' }}>
            You may now
            <div
              className="login-link ms-1"
              role="button"
              tabIndex={0}
              style={{ fontSize: '16px' }}
              onClick={() => {
                navigate('/');
                dispatch({ type: 'CLEAR_SIGNUP' });
              }}
            >
              log in
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccountForm;
