import React from 'react';
import { useSelector } from 'react-redux';

// components
import Select from './Select';

function Disposition({ label, cols, disabled, containerMargin }) {
  const { options: leadStatusOptions = [] } = useSelector(store => store.leadStatus);

  const options = leadStatusOptions.map(opt => ({
    value: opt.value,
    desc: opt.description,
  }));

  return (
    <Select
      required
      name="status"
      label={label || 'Update disposition'}
      options={options}
      cols={cols || '6'}
      disabled={disabled}
      containerMargin={containerMargin}
    />
  );
}

export default Disposition;
