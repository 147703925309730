/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import { getMedicarePlanByContractID } from '../../actions/planfilters';

// Components
import NavPage from '../nav/NavPage';
import PlanInformation from './PlanInformation';
import ShopperInformation from './ShopperInformation';


const MPA = ({
  translations, setLanguage, medicareList, recommendation, selectedPharmacy, shopper, physicianSearchStatus,
}) => {
  const mainSectionTranslations = useMemo(() => {
    const mpaTranslations = translations[setLanguage]['Member Portfolio Analysis Page'] || {};
    return mpaTranslations['Main Section'] || {};
  }, [translations, setLanguage]);

  const currentPlan = getMedicarePlanByContractID(
    medicareList,
    recommendation.curContractID,
    { NPI: selectedPharmacy, test: true },
  );

  const recommendedPlan = getMedicarePlanByContractID(
    medicareList,
    recommendation.recContractID,
    { NPI: selectedPharmacy },
  );

  const nextBestPlan = getMedicarePlanByContractID(
    medicareList,
    recommendation.nbContractID,
    { NPI: selectedPharmacy },
  );

  return (
    <NavPage
      activeLink="/mpa"
      showShopperInfobar
    >
      <div id="mpa-loaded" />
      <div className="pl-section px-4">
        <h4 className="fw-bold pt-2 mb-3">{mainSectionTranslations['Title Text']}</h4>
        <Row className="mt-3">
          <Col xs="12" lg={6} className="mb-4">
            <PlanInformation
              shopper={shopper}
              currentPlan={currentPlan}
              recommendedPlan={recommendedPlan}
              nextBestPlan={nextBestPlan}
              physicianSearchStatus={physicianSearchStatus}
              mainSectionTranslations={mainSectionTranslations}
            />
          </Col>
          <Col xs="12" lg={6}>
            <ShopperInformation
              currentPlan={currentPlan}
              recommendedPlan={recommendedPlan}
              nextBestPlan={nextBestPlan}
              mainSectionTranslations={mainSectionTranslations}
            />
          </Col>
        </Row>
      </div>
    </NavPage>
  );
};

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
    medicareList: store.plan.medicareList,
    medigapList: store.plan.medigapList,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    recommendation: store.shopper.recommendation,
    physicianSearchStatus: store.theme.features.physician_search,
    translations: store.translations.sets,
    setLanguage: store.translations.setLanguage,
  };
}
export default connect(mapStateToProps)(MPA);
