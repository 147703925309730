import React from 'react';
import { connect } from 'react-redux';

// Utilities
import { formatStatus } from '../../utils/general';

class InactiveLeadsDropdown extends React.PureComponent {
  render() {
    const {
      shopper,
    } = this.props;

    const { isActive } = shopper;

    const inactiveLeadsColumns = [
      {
        columnId: 'dashboard-shopper-list-inactive-leads-last-updated',
        columnText: 'Last updated',
      },
      {
        columnId: 'dashboard-shopper-list-inactive-leads-state',
        columnText: 'State',
      },
      {
        columnId: 'dashboard-shopper-list-inactive-city',
        columnText: 'City',
      },
      {
        columnId: 'dashboard-shopper-list-inactive-disposition',
        columnText: 'Disposition',
      },
      {
        columnId: 'dashboard-shopper-list-inactive-assigned-agent',
        columnText: 'Assigned agent',
      },
    ];

    return (
      <tr className="dashboard-shopper-list-inactive-leads-dropdown">
        <td colSpan="12">
          <div className={`dashboard-shopper-list-inactive-leads-dropdown-container ${isActive || 'bcdg'}`}>
            <div className="dashboard-shopper-list-inactive-leads-dropdown-blurb">
              <b>Previous leads</b>
              <i>If a client is associated with multiple leads, the previous leads are listed here.</i>
            </div>
            <div className="dashboard-shopper-list-inactive-leads-dropdown-table">
              <table>
                <thead
                  className="dashboard-shopper-list-inactive-leads-dropdown-table-header"
                >
                  <tr>
                    {
                      /* eslint-disable arrow-body-style */
                      inactiveLeadsColumns.map((col) => {
                        return (
                          <th
                            className="dashboard-shopper-list-cell"
                            id={col.columnId}
                            key={col.columnId}
                          >
                            {col.columnText}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    shopper.inactiveLeads.map((lead) => {
                      return (
                        <tr
                          key={lead.leadID}
                          className={`dashboard-shopper-list-inactive-leads-dropdown-table-row ${isActive || 'bcdg'}`}
                        >
                          <td>
                            {
                              new Date(lead.lastUpdated).toLocaleTimeString(
                                [],
                                {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit',
                                },
                              )
                            }
                          </td>
                          <td>{lead.state ? lead.state : '-'}</td>
                          <td>{lead.city ? lead.city : '-'}</td>
                          <td>{lead.status ? formatStatus(lead.status) : '-'}</td>
                          <td
                            style={{ textTransform: 'capitalize' }}
                          >
                            {lead.agentWhoCreatedFirstName}{lead.agentWhoCreatedLastName ? ` ${lead.agentWhoCreatedLastName}` : ''}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

InactiveLeadsDropdown.defaultProps = {
  shopper: {},
};

export default connect()(InactiveLeadsDropdown);
