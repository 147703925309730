import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-regular-svg-icons';

// Components
import BenefitsList from './Benefits';
import BenefitHighlights from './BenefitHighlights';
import ExtraBenefits from './ExtraBenefits';

class Benefits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      benefitHighlights: {
        isOpening: false,
        isVisible: true,
      },
      benefits: {
        isOpening: false,
        isVisible: true,
      },
      extraBenefits: {
        isOpening: false,
        isVisible: true,
      },
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e, headerId, isCurrentlyVisible, isCurrentlyOpening) {
    e.stopPropagation();
    /* Animate closing/opening our tables */
    if (isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isVisible: false,
        },
      });
    } else if (!isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isOpening: true,
          isVisible: true,
        },
      });
      window.setTimeout(() => {
        this.setState({
          [headerId]: {
            isVisible: !isCurrentlyVisible,
            isOpening: false,
          },
        });
      }, 250);
    }
  }

  render() {
    const {
      benefitHighlights,
      benefits,
      extraBenefits,
    } = this.state;

    const {
      plan,
    } = this.props;

    return (
      <div>
        {/* Benefit Highlights */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'benefitHighlights', benefitHighlights.isVisible, benefitHighlights.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Benefit Highlights</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={benefitHighlights.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <BenefitHighlights
          plan={plan}
          starRating={plan.starRating}
          style={{
            // Smooth transition for open/closing, em = number of benefit highlights * 2
            maxHeight: benefitHighlights.isVisible ? '150em' : '0',
            overflow: benefitHighlights.isVisible && !benefitHighlights.isOpening ? '' : 'hidden',
            marginBottom: benefitHighlights.isVisible && !benefitHighlights.isOpening ? '40px' : '',
          }}
        />

        {/* Plan Benefits */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'benefits', benefits.isVisible, benefits.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Plan Benefits</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={benefits.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <BenefitsList
          plan={plan.plan}
          benefits={plan.plan.PlanBenefitDetails}
          style={{
            maxHeight: benefits.isVisible
              ? `${plan.plan.PlanBenefitDetails ? plan.plan.PlanBenefitDetails.length * 6 : 10}em`
              : '0',
            overflow: benefits.isVisible && !benefits.isOpening ? '' : 'hidden',
            marginBottom: benefits.isVisible && !benefits.isOpening ? '40px' : '',
          }}
        />
        {/* Extra Benefits */}
        <div
          className="plandetails-table-header"
          style={{ display: plan.plan.ExtraBenefits && plan.plan.ExtraBenefits.length > 0 ? 'block' : 'none' }}
          onClick={(e) => this.handleToggle(e, 'extraBenefits', extraBenefits.isVisible, extraBenefits.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Extra Benefits</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={extraBenefits.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <ExtraBenefits
          plan={plan.plan}
          style={{
            maxHeight: extraBenefits.isVisible
              ? `${plan.plan.ExtraBenefits ? plan.plan.ExtraBenefits.length * 6 : 10}em`
              : '0',
            overflow: extraBenefits.isVisible && !extraBenefits.isOpening ? '' : 'hidden',
            marginBottom: extraBenefits.isVisible && !extraBenefits.isOpening ? '40px' : '',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    somevar: store.somevar,
  };
}

export default connect(mapStateToProps)(Benefits);
