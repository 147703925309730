/* eslint-disable quote-props,react/no-unknown-property */
import React from 'react';
import { connect } from 'react-redux';
import { FormFeedback } from 'reactstrap';

// Actions
import { deleteNotification } from '../../../actions/notifications';

// Components
import EditNotificationModal from './EditNotificationModal';

// Utilities
import { showPopup } from '../../../utils/popup';

class AgentNotificationInput extends React.PureComponent {
  calculateDateRange = () => {
    const {
      values,
    } = this.props;

    let status = false;
    if (values.endDate && values.interval === 'custom') {
      if (values.endDate < values.date) {
        showPopup('Error', 'Please choose an end date after the start date.');
        status = true;
      }
    }
    return status;
  };

  render() {
    const {
      dispatch,
      handleIntervalDates,
      handleNotificationChange,
      handleOpt,
      notifications,
      username,
      values,
    } = this.props;

    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

    let hasNotification = false;
    const filteredNotifications = notifications.filter((n) => n.shopper === username);
    if (filteredNotifications.length !== 0) {
      hasNotification = true;
    }

    return (
      <div className="mb-3">
        <hr />
        <div className="row">
          <div className="col">
            {hasNotification
              ? (
                <div className="container ms-3">
                  <label
                    className="fw-bold"
                    htmlFor="settings"
                  >
                    Edit your current notification settings for this shopper.
                  </label>
                  <div
                    name="settings"
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        dispatch({ type: 'SET_SELECTED_NOTIFICATION', payload: filteredNotifications[0] });
                        dispatch({ type: 'SHOW_MODAL', payload: 'editNotificationModal' });
                      }}
                      type="button"
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger ms-1"
                      onClick={() => deleteNotification(filteredNotifications[0].id)}
                      type="button"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ) : (
                <div className="container ms-3">
                  <input
                    checked={values.optIn}
                    className="form-check-input"
                    name="setup"
                    onChange={handleOpt}
                    type="checkbox"
                  />
                  <label
                    className="fw-bold"
                    htmlFor="setup"
                  >
                    I would like to setup follow-up notification(s) for this shopper.
                  </label>
                </div>
              )}
          </div>
        </div>
        {
          values.optIn
            ? (
              <>
                <div className="row align-items-center">
                  <div className="col">
                    <div className="fw-bold">Agent Notifications</div>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <select
                      className="form-control"
                      disabled={!values.optIn}
                      onChange={handleNotificationChange}
                      id="interval"
                      name="select"
                      style={{ maxWidth: 'max-content' }}
                      type="select"
                      value={values.interval}
                    >
                      <option value="no-repeat">No Repeat Notifications</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly on {values.currentDay}</option>
                      <option value="weekdays">Every Weekday</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col d-flex align-items-center">
                    <label
                      className="me-2 mb-0"
                      htmlFor="date"
                    >
                      {values.interval === 'no-repeat' ? 'Date:' : 'Start:'}
                    </label>
                    <input
                      className="form-control"
                      disabled={!values.optIn}
                      id="date"
                      onChange={handleNotificationChange}
                      type="date"
                      value={values.date}
                    />
                  </div>
                  {
                  values.interval === 'custom'
                    ? (
                      <div className="col d-flex align-items-center">
                        <label className="me-2 mb-0 text-nowrap" htmlFor="endDate">End:</label>
                        <FormFeedback>That name is already taken.</FormFeedback>
                        <input
                          className="form-control"
                          disabled={!values.optIn}
                          onChange={handleNotificationChange}
                          id="endDate"
                          invalid={this.calculateDateRange()}
                          type="date"
                          value={values.endDate}
                        />
                      </div>
                    ) : null
                  }
                  <div className="col d-flex align-items-center">
                    <label className="me-2 mb-0" htmlFor="time">Time:</label>
                    <input
                      className="form-control"
                      disabled={!values.optIn}
                      onChange={handleNotificationChange}
                      id="time"
                      type="time"
                      value={values.time}
                    />
                  </div>
                </div>
                {
                  values.interval === 'custom'
                    ? (
                      <div className="row align-items-center">
                        <div className="col" style={{ maxWidth: 'fit-content' }}>Notification Recurrence:</div>
                        <div>
                          {
                            days.map((day) => (
                              <button
                                className={`btn ${!values.intervalDates[day] ? 'btn-outline' : ''} btn-${values.intervalDates[day] ? 'primary' : 'secondary'} me-1`}
                                disabled={!values.optIn}
                                id={day}
                                onClick={handleIntervalDates}
                                type="button"
                              >
                                {day}
                              </button>
                            ))
                          }
                        </div>
                      </div>
                    ) : null
                }
              </>
            ) : null
        }
        <EditNotificationModal />
      </div>
    );
  }
}

export default connect()(AgentNotificationInput);
