import React from 'react';

function Popover({
  isOpen,
  className = '',
  children,
  style = {},
  containerRef,
}) {
  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        position: 'fixed',
        zIndex: 999,
        backgroundColor: 'white',
        border: '1px solid grey',
        padding: '20px',
        display: isOpen ? undefined : 'none',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default Popover;
