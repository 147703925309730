import React, { useRef, useEffect, useState } from 'react';

function Collapse({
  id,
  children,
  isOpen,
  duration = 400, // milliseconds
  transitionType = 'ease-out',
  indent = false,
  style = {},
  className,
  // IMPORTANT NOTE: if you have nested collapses then the parent will not
  // be able to correctly recalculate it's height correctly based on the ref
  // because when the parent recalculates it's height the child is in the middle
  // of a transition or just starting it, so the parent sets it's heigh and cuts
  // off the bottom of the child. In order to properly recalculate the height
  // of the parent set `recalculateHeightOn` prop that changes when a nested
  // collapse will open / close and set the `recalculateDelay` to the Numduration
  // of the child transition.
  recalculateHeightOnChange,
}) {
  // sometimes we get children in the form of something like
  // [false, false] which in a simple boolean check comes out
  // as true but doesn't actually contain anything that should
  // be rendered.
  function actualChildren(c) {
    let isValid = true;
    if (!c) {
      isValid = false;
    } else if (Array.isArray(c)) {
      isValid = false;
      c.forEach(child => {
        if (child) isValid = true;
      });
    }
    return isValid;
  }

  const rhoc = (recalculateHeightOnChange !== undefined);
  const isDisplayed = (actualChildren(children) && isOpen);
  const ref = useRef();
  const [ height, setHeight ] = useState({});

  // add indent styling
  const indentStyles = {
    borderLeft: (indent ? 'solid 3px var(--bs-gray-400)' : undefined),
    paddingLeft: (indent ? '1.5em' : undefined),
    marginLeft: (indent ? '1em' : undefined),
    marginBottom: ((indent && isDisplayed) ? '1.5em' : undefined),
  };

  const styles = {
    transition: `height ${duration}ms ${transitionType}`,
    overflow: 'hidden',
    height: (isDisplayed && ref.current) ? `${ref.current.scrollHeight}px` : '0px',
    paddingBottom: (isDisplayed && ref.current) ? '7px' : '0px',
    paddingRight: (isDisplayed && ref.current) ? '5px' : '0px',
    ...indentStyles,
    ...style,
  };

  /*
  console.log('ref.current: ', ref);
  console.log('isOpen: ', isOpen);
  console.log('actualChildren(children): ', actualChildren(children));
  console.log('isDisplayed: ', isDisplayed);

  function logbyid(...msg) {
    if (id === 'othercoverage') {
      console.log(...msg);
    }
  }
  */

  useEffect(() => {
    if (rhoc && isDisplayed) {
      setHeight({ height: 'auto' });
    } else if (!isDisplayed) {
      setHeight({ height: '0px' });
    }
  }, [recalculateHeightOnChange]);

  useEffect(() => {
    // if (!rhoc && !isDisplayed) {
    if (!isDisplayed) {
      if (height.height === 'auto') {
        setHeight({ height: `${ref.current.scrollHeight}px` });
        setTimeout(() => setHeight({}), 100);
      } else {
        setHeight({});
      }
    // } else if (!rhoc && isDisplayed) {
    } else if (isDisplayed) {
      setHeight({ height: `${ref.current.scrollHeight}px` });
    }
  }, [isDisplayed]);

  return (
    <div
      id={id}
      style={{ ...styles, ...height }}
      ref={ref}
      className={className}
    >
      {children}
    </div>
  );
}

export default Collapse;
