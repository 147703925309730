import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ProfileLink() {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => navigate('/appointments')}
      className="btn btn-link p-0"
    >
      profile
    </button>
  );
}

function AppointmentsProfileAlert({ className }) {
  const dispatch = useDispatch();
  const { appointmentLeads: appointmentLeadsFeature } = useSelector(store => store.theme.features);
  const {
    appointmentsProfile: profile = {},
    acceptingAppointments,
  } = useSelector(store => store.agent);

  const noProfile = ((!profile.pointsByMeetingType && !profile.travelZipsByMeetingType) || !profile.meetingTypes);

  const alertClassesWarning = 'd-flex align-items-center py-1 px-3 border border-warning bg-warning-bg shadow-sm r-1';
  const alertClassesSuccess = 'd-flex align-items-center py-1 px-3 border border-success bg-success-bg shadow-sm r-1';

  if (!appointmentLeadsFeature) {
    return null;
  } else if (noProfile) {
    return (
      <div role="alert" className={`mb-0 ${alertClassesWarning} ${className}`}>
        Set up your&nbsp;<ProfileLink />&nbsp;to start receiving appointment leads.
        <button
          type="button"
          className="btn btn-link"
          onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'appointmentsTutorial' })}
        >
          Watch tutorial
        </button>
      </div>
    );
  } else if (!acceptingAppointments) {
    return (
      <div className={`${alertClassesWarning}  ${className}`}>
        You are currently set as not available to receive appointment leads
      </div>
    );
  } else if (acceptingAppointments) {
    return (
      <div className={`${alertClassesSuccess}  ${className}`}>
        You are currently set as available to receive appointment leads
      </div>
    );
  }
}

export default AppointmentsProfileAlert;
