import React from 'react';
import { connect } from 'react-redux';

class EmailPreview extends React.PureComponent {
  render() {
    const {
      greeting,
      closing,
      signature,
      phone,
      email,
      activationCode,
      textBlock1,
      textBlock2,
      tpmoDisclaimerText,
    } = this.props;

    return (
      <div style={{ maxWidth: '700px', wordBreak: 'break-word' }}>
        <p><span className="greeting-color">{greeting} </span></p>
        <p>{textBlock1}</p>
        <p>
          {textBlock2}
          {
            activationCode
              ? (
                <span className="font-monospace fs-6"> {'<code>'}</span>
              )
              : null
          }
        </p>
        <p className="text-blue font-monospace fs-6">{'<link>'}</p>
        <p>
          <span className="closing-color">{closing}</span><br />
          <span className="signature-color">{signature}</span>
        </p>
        <p>
          {phone}<br />
          {email}<br />
        </p>
        <p className="fst-italic">
          {tpmoDisclaimerText}
        </p>
      </div>
    );
  }
}

EmailPreview.defaultProps = {
  textBlock1: 'This is site config / pinpoint soa email text 1.',
  textBlock2: 'This is site config / pinpoint soa email text 2.',
};

export default connect()(EmailPreview);
