const initialState = {
  medigapCI: [],
  pdpCI: [],
  mapdCI: [],
  medigapCIavailable: false,
  pdpCIavailable: false,
  mapdCIavailable: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_MEDIGAP_CI_DATA':
      return { ...state, medigapCI: action.payload, medigapCIavailable: true };
    case 'SET_PDP_CI_DATA':
      return { ...state, pdpCI: action.payload, pdpCIavailable: true };
    case 'SET_MAPD_CI_DATA':
      return { ...state, mapdCI: action.payload, mapdCIavailable: true };
    case 'SET_MEDIGAP_CI_NO_DATA_FLAG':
      return { ...state, medigapCIavailable: false };
    case 'SET_PDP_CI_NO_DATA_FLAG':
      return { ...state, pdpCIavailable: false };
    case 'SET_MAPD_CI_NO_DATA_FLAG':
      return { ...state, mapdCIavailable: false };
    case 'CLEAR_CI_DATA':
      return initialState;
    default:
      return state;
  }
}
