import axios from 'axios';
import { store } from '../store';

// Actions
import { getAgent } from './agent';

// `site.com?preview` will load the unpublished preview site theme.
const preview = new URLSearchParams(window.location.search).has('preview');

/**
 * Load a Bootswatch color theme.
 * @see https://bootswatch.com/
 */
export function loadColorTheme(name) {
  axios('https://bootswatch.com/api/4.json').then(({ data }) => {
    const theme = data.themes.find((swatch) => swatch.name === name) || {};
    store.dispatch({ type: 'LOAD_BOOTSWATCH', theme });
  });
}

/**
 * Pull down the latest published theme.
 */
export function loadTheme(hash) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/`,
      params: { hash },
    }).then(({ data }) => {
      const theme = store.getState().theme.bootswatchName;
      if (data.bootswatchName !== theme) {
        loadColorTheme(data.bootswatchName);
      }

      store.dispatch({
        type: 'LOAD_THEME',
        payload: { ...data },
      });
      resolve({ data });
    }).catch((err) => {
      reject(err);
    });
  });
}

/**
 * Check for any published theme updates.
 */
export function validateTheme() {
  axios({
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/theme/versions/`,
  }).then(({ data }) => {
    const currentState = store.getState();
    const { email } = currentState.agent;
    const { hash } = currentState.theme;
    const builtHash = preview ? data.draft : data.live;

    if (hash !== builtHash) {
      loadTheme(builtHash)
        .then(() => {
          if (email) {
            getAgent();
          }
        });
    }
  });
}
