/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Load any Bootswatch theme.
 * Correct for minor style issues between Dashkit vs. Bootswatch.
 */
function ColorTheme(props) {
  return (
    <>
      <link rel="stylesheet" href={props.url} />
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .navbar.fixed-top, #sidebar, #sidebar > button {
              background-color: ${props.url ? null : 'white'} !important;
            }
            #mySidenav {
              background-color: ${props.url ? null : '#f9fbfd'} !important;
            }
            .breadcrumb-item+.breadcrumb-item:before {
              background-color: ${props.url ? 'inherit' : null} !important;
              -webkit-mask: ${props.url ? 'none' : null};
            }
            .table thead th {
              color: ${props.url ? 'inherit' : null};
              background-color: ${props.url ? 'inherit' : null};
            }
            #signin-greeting {
              font-size: ${props.url ? null : '3.25rem'};
            }
            #sideNavContent h4 {
              font-size: ${props.url ? null : '1.25rem'};
            }
          `,
        }}
      />
    </>
  );
}

ColorTheme.propTypes = {
  url: PropTypes.string.isRequired,
};

function mapStateToProps(store) {
  return {
    url: store.theme.bootswatchCDN,
  };
}

export default connect(mapStateToProps)(ColorTheme);
