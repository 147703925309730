import { setCookie } from '../utils/cookie';

const initialState = {
  meetType: undefined,
  softphoneOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_TELEPHONY_FIELD':
      return { ...state, [action.name]: action.value };
    case 'SET_MEET_TYPE': {
      setCookie('meetType', action.value);
      return { ...state, meetType: action.value };
    }
    case 'CLEAR_TELEPHONY': {
      setCookie('meetType', '');
      return initialState;
    }
    default:
      return state;
  }
}
