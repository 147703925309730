import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Actions
import { updateEffectDate } from '../../../actions/shopper';

// Components
import StandardModal from '../../common/StandardModal';

// Utility
import { showPopup } from '../../../utils/popup';
import { generateEffectiveDateOptions } from '../../../utils/general';

const EffectiveDateWarningModal = ({
  dispatch, shopper, effectiveDateWarningModal, updateEffectiveDate,
}) => {
  const [open, setOpen] = useState(false);
  const effectiveDate = generateEffectiveDateOptions()[0];

  function handleClose() {
    setOpen(true);
    dispatch({ type: 'HIDE_MODAL', payload: 'effectiveDateWarningModal' });

    // dispatch({ type: 'CLEAR_PLAN_DATA' });
    // dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: 'DEFAULT_STATE' });
    dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: 'DEFAULT_STATE' });
  }

  function handleConfirm() {
    setOpen(true);
    dispatch({ type: 'HIDE_MODAL', payload: 'effectiveDateWarningModal' });

    dispatch({ type: 'SET_ROOT_LOADER', payload: true });
    dispatch({ type: 'SET_EFFECTIVE_DATE', payload: effectiveDate.val });
    dispatch({ type: 'UPDATE_SHOPPER_VALIDATION', payload: { effectiveDate: true } });
    dispatch({ type: 'UPDATE_SHOPPER_ERROR', payload: { effectiveDate: undefined } });
    updateEffectDate(shopper.username, effectiveDate.val)
      .then(() => {
        showPopup('Success!', 'Updated Shopper!');
        if (updateEffectiveDate) updateEffectiveDate(effectiveDate.val);
      }).catch(() => {
        showPopup('Error', 'There was a problem updating the shopper.');
      }).finally(() => {
        dispatch({ type: 'SET_ROOT_LOADER', payload: false });
      });
  }

  return (
    <StandardModal
      closeModalAction={() => handleClose()}
      isOpen={effectiveDateWarningModal && !open}
      modalClasses="effective-date-warning-modal"
      title="Warning!"
    >
      <div className="px-3">
        The {"client's"} existing <b>effective date</b> is no longer valid and the
        next available effective date of {effectiveDate.desc} ({effectiveDate.val})
        has been selected. Please click Confirm to continue or Cancel to
        select a different effective date.
      </div>
      <div className="d-flex flex-row px-4 pb-3 mt-4 align-items-center">
        <button
          className="btn btn-outline-secondary me-4 ms-auto"
          onClick={() => handleClose()}
          type="button"
          style={{ paddingRight: '30px', paddingLeft: '30px' }}
        >
          Cancel
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleConfirm()}
          type="button"
          style={{ paddingRight: '30px', paddingLeft: '30px', fontWeight: '700' }}
        >
          Confirm
        </button>
      </div>
    </StandardModal>
  );
};

function mapStateToProps(store) {
  return {
    effectiveDateWarningModal: store.modal.effectiveDateWarningModal,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(EffectiveDateWarningModal));
