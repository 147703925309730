/* eslint-disable import/prefer-default-export */
// import { store } from '../store';

export function telephonyChecks({
  telephonyFeature,
  telephonyDebug,
  meetType,
  callStatus,
} = {}) {
  if (telephonyFeature && !telephonyDebug) {
    if (meetType === 'telephonic' && callStatus !== 'connected') {
      return true;
    // if meetType is not selected, disable nav
    } else if (!['inPerson', 'telephonic', 'telephonicThreeWay'].includes(meetType)) {
      return true;
    }
  }
  return false;
}
