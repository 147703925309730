import React from 'react';
import { connect } from 'react-redux';

// Actions
import { saveDrug } from '../../../actions/drug';

// Utilities
import { showPopup } from '../../../utils/popup';

class AddButton extends React.PureComponent {
  buildDrugFromProps = () => {
    const {
      editDrugID,
      selectedDosage,
      selectedDrug,
      selectedFrequency,
      selectedPackage,
      userQuantity,
    } = this.props;

    let quantity = Number(userQuantity);
    let packageID = null;
    let packageDescription = null;
    const drugType = selectedDosage.DrugTypeDesc;
    // if non oral drug calculate quantity and set packageID
    if (selectedDosage.ContainPackage) {
      const packageSize = Number(selectedPackage.PackageSize);
      const packageQuantity = Number(selectedPackage.PackageQuantity);
      quantity = Math.round(quantity * packageSize * packageQuantity * 100) / 100;
      packageID = selectedPackage.PackageID;
      packageDescription = selectedPackage.PackageDescription;
    }
    return {
      id: editDrugID,
      drugID: selectedDrug.DrugID,
      name: selectedDrug.DrugName,
      dosage: selectedDosage.Label,
      dosageID: selectedDosage.DosageID,
      userQuantity,
      frequency: selectedFrequency,
      ndc: selectedDosage.NDC,
      packageID,
      packageDescription,
      quantity,
      drugType,
    };
  };

  validateDrugValues = () => {
    const {
      userQuantity,
    } = this.props;

    if (Number(userQuantity) <= 0) {
      return false;
    }
    return true;
  };

  saveDrugLocal = (e) => {
    e.preventDefault();
    const {
      dispatch,
      drugList,
      editDrug,
      editIndex,
      quickQuote,
      username,
    } = this.props;

    const drug = this.buildDrugFromProps();
    const index = editIndex;
    // Different reducer methods for edit and add new drugs.
    let action = 'ADD_DRUG_TO_MEDCAB';
    // Which one is used depends on redux prop editDrug.
    if (editDrug) { action = 'EDIT_DRUG_IN_MEDCAB'; }

    const alreadyAdded = drugList
      .find(
        (savedDrug) => (
          (savedDrug.dosageID === drug.dosageID)
          && (savedDrug.packageID === drug.packageID)
          && (savedDrug.frequency === drug.frequency)
          && (savedDrug.quantity === drug.quantity)
        ),
      );
    if (alreadyAdded) {
      showPopup('Error', 'That drug is already in your medicine cabinet.');
    } else if (e.target.id === 'drug-search-add-more') {
      if (!this.validateDrugValues()) {
        showPopup('Error', 'Please enter a positive value for quantity.');
        return;
      }
      saveDrug(drug, username, quickQuote, editDrug)
        .then((resdata) => {
          if (!(editDrug)) {
            drug.id = resdata.id;
          }
          dispatch({ type: action, payload: { drug, index } });
          dispatch({ type: 'SET_DRUG_MODAL_STATE', payload: 'search' });
          dispatch({ type: 'CLEAR_MEDICARE_DATA' });
          dispatch({ type: 'CLEAR_PLAN_DATA' });
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: 'DEFAULT_STATE' });
          dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: 'DEFAULT_STATE' });
          dispatch({ type: 'CLEAR_SELECTED_DRUG' });
          dispatch({ type: 'SET_MESSAGE', payload: `${drug.name} has been added.` });
          dispatch({ type: 'SET_DRUG_SEARCH', payload: '' });
        }).catch(() => {
          showPopup('Error', 'There was an error adding that drug.');
        });
    }
  };

  render() {
    return (
      <button
        id="drug-search-add-more"
        className="btn btn-primary px-4 fw-bold"
        onClick={(e) => this.saveDrugLocal(e)}
        type="button"
      >
        Add
      </button>
    );
  }
}

function mapStateToProps(store) {
  return {
    anonymous: store.shopper.anonymous,
    drugList: store.shopper.drugList,
    editDrug: store.drug.editDrug,
    editDrugID: store.drug.editDrugID,
    editIndex: store.drug.editIndex,
    quickQuote: store.shopper.quickQuote,
    selectedDosage: store.drug.selectedDosage,
    selectedDrug: store.drug.selectedDrug,
    selectedFrequency: store.drug.selectedFrequency,
    selectedPackage: store.drug.selectedPackage,
    username: store.shopper.username,
    userQuantity: store.drug.userQuantity,
  };
}

export default connect(mapStateToProps)(AddButton);
