import axios from 'axios';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export const agentLogout = () => {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/expire/`,
    headers: { Authorization: `Token ${token}` },
    data: { token },
  }).finally(() => {
    store.dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: null });
    window.location.href = '/';
  });
};

function rfmAgent(data) {
  // Temporary override until Cliff is done adding batch upload support.
  const currentState = store.getState();
  const { statePermissions: statePermissionsStatus } = currentState.theme.features;

  const statePermissions = [];
  if (statePermissionsStatus) {
    data.state_permissions.forEach(({
      active, issued, expiration, state,
    }) => {
      if (active) {
        if (moment().isBetween(issued, expiration)) {
          statePermissions.push(state.toUpperCase());
        }
      }
    });
  }

  return {
    statePermissions,
    carrierPermissions: data.carrier_permissions,
    daysUntilReset: data.days_until_reset,
    highContrast: data.high_constrast,
    is_admin: data.isAdmin,
    lastUpdated: data.last_updated,
    needsPasswordReset: data.needs_password_reset,
    passwordExpired: data.password_expired,
    hasTemporaryPassword: data.has_temporary_password,
    token: data.token,
    username: data.username,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    npn: data.npn,
    npnLinked: data.npn_linked,
    npnCallRecording: data.npn_call_recording,
    callRecording: data.call_recording,
    startDate: data.start_date,
    mmbPermissions: data.mmb_permissions,
    agency: data.agency_info,
    appointmentsProfile: data.appointments_profile || {},
    acceptingAppointments: data.accepting_appointments,
    mobilePhone: data.mobile_phone,
  };
}

export const agentLogin = async (email, password, verificationCode, fingerprint) => {
  const { data } = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/token-auth/agent/`,
    withCredentials: true, // Amazon WAF token from our subdomain's .api. cookies
    data: {
      email,
      password,
      verification_code: verificationCode,
      fingerprint,
    },
  });

  if (!data.can_quote) {
    // throw new Error('You are not authorized for this site.');
  }

  localStorage.setItem('SessionToken', data.token);
  store.dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: new Date() });
  return rfmAgent(data);
};

export function getAgent() {
  const token = localStorage.getItem('SessionToken');
  const currentState = store.getState();
  const { email } = currentState.agent;
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/agent/${email}/`,
    headers: { Authorization: `Token ${token}` },
  }).then((data) => {
    const rfmData = rfmAgent(data.data);
    store.dispatch({ type: 'LOAD_AGENT', payload: rfmData });
  }).catch(() => Promise.reject(new Error('Whoops! Something went wrong')));
}

export function updateAgent(data) { // this endpoint only allows an agent to update themselves (not other agents)
  const token = localStorage.getItem('SessionToken');
  const currentState = store.getState();
  const { username } = currentState.agent;
  return axios({
    method: 'PUT',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/agent/${username}/`,
    headers: { Authorization: `Token ${token}` },
    data, // NOTE(jentgelmeier): for now only accepts npn_call_recording or call_recording
  }).then((response) => rfmAgent(response.data));
}

export function agentResetPassword(email, currentPassword, newPassword) {
  const token = localStorage.getItem('SessionToken');

  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/change-password/`,
    headers: { Authorization: `Token ${token}` },
    data: {
      email,
      current_password: currentPassword,
      new_password: newPassword,
    },
  }).then((res) => {
    Promise.resolve({
      success: true,
      data: res.data,
    });
  }).catch((err) => {
    if (err.response) { return Promise.reject(err.response.data); }
    return Promise.reject(new Error('Whoops! Something went wrong'));
  });
}

export function loadAgentSelf(authToken) {
  const token = authToken || localStorage.getItem('SessionToken');
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/self/`,
    headers: { Authorization: `Token ${token}` },
  }).then(({ data }) => {
    localStorage.setItem('SessionToken', token);
    store.dispatch({ type: 'SET_TOKEN_LAST_UPDATE', payload: new Date() });
    return rfmAgent(data);
  });
}

export const getReleaseNotes = async () => {
  const token = localStorage.getItem('SessionToken');
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/rnotes/`,
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};

export const getPrivacyDisclosure = async () => {
  const token = localStorage.getItem('SessionToken');
  const { data } = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/privacy/`,
    headers: { Authorization: `Token ${token}` },
  });
  return data;
};
