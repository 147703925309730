import React, { useState } from 'react';
import { connect } from 'react-redux';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDollarAmount } from '../../utils/general';

const ShopperInformation = (props) => {
  const [show, setShow] = useState(false);
  const renderShopperRecommendation = () => {
    if (props.recommendedPlan) {
      return <span className="mpa-recommendation">{props.mainSectionTranslations['Switch Plans Text']}</span>;
    }
    if (props.nextBestPlan) {
      return <span className="mpa-recommendation">{props.mainSectionTranslations['Stay Text']}</span>;
    }
    return <p>{props.mainSectionTranslations['No MPA Recommendation Text']}</p>;
  };

  const renderShopperInformationText = () => {
    const { firstName, lastName } = props.shopper;
    if (props.recommendedPlan) {
      const recPlan = props.recommendedPlan;
      const curPlan = props.currentPlan;
      const totalProjectedSavings = formatDollarAmount(curPlan.totalAnnualOOPC - recPlan.totalAnnualOOPC, true, true);

      return (
        <span>
          <p>
            {firstName} {lastName} was recommended to <b>SWITCH</b> plans to
            the <b>{recPlan.planName}</b> with a projected savings
            of <b>{totalProjectedSavings}</b>.
          </p>
        </span>
      );
    }

    if (props.nextBestPlan) {
      return (
        <span>
          <p>{firstName} {lastName} was recommended to STAY with current plan.</p>
        </span>
      );
    }

    return <p>{props.mainSectionTranslations['No Shopper Information Text']}</p>;
  };

  const renderShopperHistory = () => {
    if (props.shopper.recommendation.letterMailedDate) {
      return (
        <span>
          <div>Shopper Letter Mailed On: <b>{props.shopper.recommendation.letterMailedDate}</b></div>
          {props.shopper.recommendation.letterLink
            ? (
              <div>
                View PDF:
                <a
                  href={props.shopper.recommendation.letterLink}
                  className="mpa-letter-link ms-1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recommedation Letter Link
                </a>
              </div>
            )
            : null}
          <div>Shopper Last Visit to Site On: <b>2019-10-01</b></div>
          <div>Medicine Cabinet Last Update: <b>2019-10-01</b></div>
        </span>
      );
    }
    return <p>{props.mainSectionTranslations['No Shopper History Text']}</p>;
  };

  return (
    <div>
      <h5 className="my-4">{props.mainSectionTranslations['MPA Recommendation Text']}</h5>
      {renderShopperRecommendation()}
      <hr className="mt-4" />
      {show && (
      <div>
        <h5>{props.mainSectionTranslations['Shopper Information Text']}</h5>
        {renderShopperInformationText()}
        <hr />

        <h5>{props.mainSectionTranslations['Shopper History Text']}</h5>
        {renderShopperHistory()}
      </div>
      )}



      <button type="button" className="btn btn-outline-grey" onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faFileAlt} className="fa-lg me-2" />
        <span className="text-black ps-1 pe-5">Member letter</span>
      </button>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(ShopperInformation);
