import React from 'react';
import { connect } from 'react-redux';

// Components
import ShopperListSearch from './ShopperListSearch';
import ShopperList from './ShopperList';
import Pagination from './Pagination';

class ShopperListContainer extends React.PureComponent {
  render() {
    const { activeLink, shoppersLoaded } = this.props;

    return (
      <div
        className=""
      >
        <ShopperListSearch />
        <ShopperList activeLink={activeLink} />
        {shoppersLoaded === 'SUCCESS_STATE' && <Pagination />}

      </div>
    );
  }
}


ShopperListContainer.defaultProps = {
  activeLink: '/',
};

function mapStateToProps(store) {
  return {
    shoppersLoaded: store.loaders.shoppersLoaded,
  };
}

export default connect(mapStateToProps)(ShopperListContainer);
