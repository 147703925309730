import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Components
import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';
import Search from './inputs/Search';
import Results from './Results';
import Specialty from './inputs/Specialty';
import Radius from './inputs/Radius';
import Zipcode from './inputs/Zipcode';
import Disclaimer from './Disclaimer';


class DoctorSearch extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_DOCTORS_SPECIALTY', payload: '' });
    dispatch({ type: 'CLEAR_DOCTORS' });
  }

  render() {
    const { disclaimerHumanaProvider, navigate } = this.props;
    return (
      <NavPage
        activeLink="/doctors"
        showShopperInfobar
      >
        <div className="rx rx-add">
          <div className="mmb-header">Doctors</div>
          <div className="mmb-search">
            <div className="mt-1 ms-2">
              <div className="fw-bold mb-3" style={{ fontSize: '18px' }}>
                Add doctors
              </div>
              <div>
                <p>Add doctors to see if they are in network for specific plans. Add a star to doctors that are important to your client.</p>
              </div>
              <div className="d-flex flex-row">
                <form className="d-flex">
                  <Specialty />
                  <Radius />
                  <Zipcode />
                  <Search />
                </form>
              </div>
            </div>
            <div className="mt-4 ms-4">
              <Results />
            </div>
            {disclaimerHumanaProvider && <Disclaimer />}
          </div>
        </div>
        <FooterNav
          rightButtonText="Finish"
          planOnClick={() => navigate('/plans/list')}
          nextOnClick={() => navigate('/plans/list')}
          backButtonText="Back to doctors"
          backOnClick={() => navigate('/doctors/list')}
        />
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    doctorAddModal: store.modal.doctorAddModal,
    doctorModalState: store.modal.doctorModalState,
    disclaimerHumanaProvider: store.theme.features.disclaimerHumanaProvider,
  };
}

export default connect(mapStateToProps)(withRouterProps(DoctorSearch));
