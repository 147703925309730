import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Highlight } from '../../../guide';

class SaveShopperButton extends React.PureComponent {
  showSaveShopperModal = () => {
    this.props.dispatch({ type: 'SHOW_MODAL', payload: 'saveShopperModal' });
  };

  render() {
    if (this.props.shopper.quickQuote && this.props.activeLink !== '/shopper') {
      return (
        <Highlight id="nav" i={0} side="b" className="mx-4 align-self-center">
          <Button color="primary" onClick={this.showSaveShopperModal}>
            Save Client
          </Button>
        </Highlight>
      );
    }
    return null;
  }
}

SaveShopperButton.defaultProps = {
  activeLink: '/dashboard',
};

const mapStateToProps = (store) => ({
  shopper: store.shopper,
});

export default connect(mapStateToProps)(SaveShopperButton);
