import axios from 'axios';
import { store } from '../store';
import { default401 } from './auth';

import { formatPhoneToNum } from '../utils/general';

export function loadAgents(params = {}) {
  const token = localStorage.getItem('SessionToken');
  store.dispatch({ type: 'SET_LOADING_AGENTS_STATUS', payload: true });

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/search/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
      params: {
        ...params,
        page: params.page,
      },
    }).then(({ data }) => {
      store.dispatch({ type: 'LOAD_AGENT_LIST', payload: data });
      store.dispatch({ type: 'SET_LOADING_AGENTS_STATUS', payload: false });
      resolve(data);
    }).catch((err) => {
      store.dispatch({ type: 'SET_LOADING_AGENTS_STATUS', payload: false });
      reject(err);
    });
  });
}

export const updateAgent = (email, details) => {
  const token = localStorage.getItem('SessionToken');
  const {
    last_updated: lu,
    last_updated_by: lub,
    ...data
  } = details;

  const filteredData = Object.keys(data)
    .filter((detail) => detail !== 'site')
    .filter((detail) => detail !== 'agency')
    .reduce((obj, key) => ({
      ...obj,
      [key]: data[key],
    }), {});

  return axios({
    method: 'put',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/agent/${email}/`,
    headers: { Authorization: `Token ${token}` },
    data: filteredData,
  }).catch(default401);
};

export const updateSelectedAgents = (conf) => {
  const { agentManagement } = store.getState();
  // const selected = records.filter((agent) => agent.selected);

  const updates = agentManagement.selectedAgents.map((agent) => updateAgent(agent.email, conf));
  return Promise.all(updates)
    .catch(default401);
};

export const createAgent = (agent) => {
  const token = localStorage.getItem('SessionToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/agent/`,
    headers: { Authorization: `Token ${token}` },
    data: {
      ...agent,
      is_agent: true,
      first_login: true,
    },
  }).then(loadAgents)
    .catch(default401)
    .catch((err) => {
      let id = 'other';
      let message = null;

      if (err.response) {
        if (err.response.status === 500) {
          if (err.response.data.includes('mmb_clients_user_email_site_id')) {
            id = 'email';
          } else if (err.response.data.includes('mmb_clients_user_npn')) {
            id = 'npn';
          }
        }
        message = `Bad response: ${err.response.status}`;
      } else if (err.request) {
        message = 'Server did not respond!';
      } else {
        message = `Unknown error: ${err.message}`;
      }

      store.dispatch({ type: 'ADD_AGENT_ERROR', id, message });
      throw new Error(`${id}: ${message}`);
    });
};

export const verifyNpn = (payload) => {
  const { signup } = store.getState();
  const { activation_code, npn, last_name } = payload || signup;

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/verify/`,
    data: {
      activation_code,
      last_name,
      npn,
    },
  }).then(({ data }) => (data));
};

export const createAccount = (values) => {
  const { signup } = store.getState();
  const form = { ...signup, ...values };
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/register/`,
      data: {
        activation_code: form.activation_code,
        first_name: form.first_name,
        middle_name: form.middle_name,
        last_name: form.last_name,
        mobile_phone: formatPhoneToNum(form.mobile_phone),
        password: form.password,
        email: form.email,
        npn: form.npn,
        npn_type: form.npn_type,
        npn_linked: form.npn_linked || null,
      },
    }).then(({ data }) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    });
  });
};
