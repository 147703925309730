import React, { useRef } from 'react';

function Collapse({
  children,
  isOpen,
  duration = 400, // milliseconds
  transitionType = 'ease-out',
}) {
  const ref = useRef();

  const openStyles = {
    transition: `height ${duration}ms ${transitionType}`,
    height: (isOpen && ref.current) ? `${ref.current.scrollHeight}px` : undefined,
    overflow: 'hidden',
  };

  const closeStyles = {
    transition: `height ${duration}ms ${transitionType}`,
    height: '0px',
    overflow: 'hidden',
  };

  return (
    <div style={isOpen ? openStyles : closeStyles} ref={ref}>
      {children}
    </div>
  );
}

export default Collapse;
