import React from 'react';

class MobileNavbar extends React.PureComponent {
  render() {
    return (
      <div className="mobile-navbar p-0">
        <img
          alt="logo"
          className="nav-logo"
          src="/images/mmb-logo-horizontal.png"
          style={{
            marginLeft: '16px',
            display: 'block',
            height: '39px',
          }}
        />
      </div>
    );
  }
}

export default MobileNavbar;
