/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatToPhone, formatPhoneToNum } from '../../../../utils/general';

import TextInput from './TextInput';

function Phone({
  disabled,
  label,
  name,
  type,
  placeholder,
}) {
  const dispatch = useDispatch();
  const value = useSelector(store => store.shopper[name]);
  const displayValue = formatToPhone(value);

  function handleChange(e) {
    const { value: val } = e.target;
    let unformatted = formatPhoneToNum(val);
    // NOTE(santeyio): this is jenky... I'm pretty tired and I'm not sure
    // that this is the best way to fix this?
    const re = /^\([0-9]{1,3}$/g;
    if (re.test(val)) {
      if (val.length === 4) unformatted = val.slice(1, 3);
      if (val.length === 3) unformatted = val.slice(1, 2);
      if (val.length === 2) unformatted = val.slice(1, 1);
    }
    dispatch({ type: 'UPDATE_SHOPPER', payload: { [name]: unformatted } });

    return unformatted;
  }

  return (
    <TextInput
      name={name}
      displayValue={displayValue}
      label={label}
      type={type}
      placeholder={placeholder}
      handleChange={handleChange}
      disabled={disabled}
    />
  );
}

export default Phone;
