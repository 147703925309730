export function fmtAgent(agent) {
  return {
    username: agent.username,
    firstName: agent.first_name,
    lastName: agent.last_name,
    email: agent.email,
    mobilePhone: agent.mobile_phone,

    npn: agent.npn,
    npnLinked: agent.npn_linked,
    agency: agent.agency_info,

    startDate: agent.start_date,
    daysUntilReset: agent.days_until_reset,
    needsPasswordReset: agent.needs_password_reset,
    passwordExpired: agent.password_expired,
    lastUpdated: agent.last_updated,

    mmbPermissions: agent.mmb_permissions,
    statePermissions: agent.state_permissions,
    carrierPermissions: agent.carrier_permissions,
    highContrast: agent.high_constrast,
    phoneInfo: agent.agent_phone_info ? ({
      agentUsername: agent.agent_phone_info.agent_username,
      name: agent.agent_phone_info.name,
      connectUsername: agent.agent_phone_info.connect_username,
      instanceDomain: agent.agent_phone_info.instance_domain,
      phone: agent.agent_phone_info.phone,
      extension: agent.agent_phone_info.extension,
      fallbackQueue: agent.agent_phone_info.fallback_queue,
      status: agent.agent_phone_info.status,
      created: agent.agent_phone_info.created,
      updated: agent.agent_phone_info.updated,
    }) : undefined,
  };
}

export function fmtPlan(plan) {
  const fmt = {
    carrierID: plan.CarrierID,
    carrier: plan.CarrierName,
    org: plan.OrganizationName,
    orgID: plan.OrganizationID,
    cpsID: `${plan.ExternalContractID}-${plan.ExternalPlanID}-${Number(plan.ExternalSegmentID)}`,
    brand: plan.BrandName,

    plan,
    planID: plan.PlanID,
    planType: plan.PlanTypeDesc,
    planName: plan.PlanName,
    medicalType: plan.MedicalPlanTypeDesc,
    MOOP: plan.MOOPFromBenefit,
    monthlyPremium: plan.TotalPlanPremium,
    drugsCovered: plan.CoveredDrugCount,
    deductible: plan.DrugDeductibleLimit,
    doctorCopay: plan.DoctorVisitCopay,
    specialistCopay: plan.SpecialistCopay,

    starRating: plan.OverallStarRating,
    premiumRank: plan.PremiumRank,
    premiumRankVsMarket: plan.PremiumRankVsMarket,
  };

  return fmt;
}

export function fmtPlans(plans) {
  return plans.map(p => fmtPlan(p));
}

export function fmtEnrollment(enrollment) {
  const fmt = {
    quoteID: enrollment.quote_id,
    carrier: enrollment.carrier,
    enrollmentID: enrollment.enrollment_id,
    enrollmentCode: enrollment.enrollment_code,
    fields: enrollment.fields,
    submittedFlag: enrollment.submitted_flag,
    finalSubmissionToCarrier: enrollment.final_submission_to_carrier,
    finalCarrierSubmitDate: enrollment.final_carrier_submit_date,
    cancelled: enrollment.cancelled,
    submitDate: enrollment.submit_date,
    cancelDate: enrollment.cancel_date,
    confirmationNumber: enrollment.confirmation_number,
  };
  return fmt;
}

export function fmtQuote(quote) {
  return {
    quoteNumber: quote.quote_id_number,
    quoteID: quote.quote_id,

    carrier: quote.plan_carrier,
    carrierID: quote.carrier_id,
    org: quote.plan_org,
    orgID: quote.org_id,
    brand: quote.plan_brand,
    cpsID: quote.cps_id,

    created: quote.created,
    updated: quote.updated,
    agentWhoUpdated: quote.agent_who_updated,
    createdByUsername: quote.user_who_created,
    createdByEmail: quote.user_who_created_email,
    createdByFirstName: quote.user_who_created_first_name,
    createdByLastName: quote.user_who_created_last_name,
    confirmationNumber: quote.confirmation_number,
    enrollment: quote.enrollment,
    hra: quote.hra,

    shopperUsername: quote.shopper,
    state: quote.shopper_state,
    zip: quote.shopper_zip,
    county: quote.shopper_county,
    countyName: quote.shopper_county_name,
    city: quote.shopper_city,
    effectiveDate: quote.effective_date,
    planID: quote.plan_id,
    planName: quote.plan_name,
    planType: quote.plan_type,
    planYear: quote.plan_year,
    premium: quote.premium,
    calculatedPremium: quote.calculated_premium,
    extraBenefits: quote.extra_benefits,

    discountAgeAZ: quote.discount_age_az,
    discountHousehold: quote.discount_household,
    discountMonthlyBankDraft: quote.discount_monthly_bank_draft,
    discountNewToMedicare: quote.discount_new_to_medicare,
    osbCost: quote.osb_amount,
    osbID: quote.osb_id,
    osbName: quote.osb_name,
    riderOsbOnly: false, // NOTE(santeyio): ??? // note(joeysapp): ???
    wir_excs: quote.wir_excs,
    wir_hh: quote.wir_hh,
    wir_pbco: quote.wir_pbco,
    wir_pta: quote.wir_pta,
    wir_ptb: quote.wir_ptb,
    wir_tvl: quote.wir_tvl,
  };
}

export function fmtShopper(shopper = {}) {
  const { quotes = [] } = shopper;
  const rfmQuotes = quotes.map((quote) => fmtQuote(quote));
  const rfmData = {
    agentWhoCreatedFirstName: shopper.user_who_created_first_name || '',
    agentWhoCreatedLastName: shopper.user_who_created_last_name || '',
    userWhoCreated: shopper.user_who_created || '',
    created: shopper.created || '',
    lastUpdated: shopper.last_updated || '',
    anocCode: shopper.anoc_code || '',
    dob: shopper.birthdate || '',
    gender: shopper.gender || '',
    county: shopper.county || '',
    countyName: shopper.county_name || '',
    firstName: shopper.first_name || '',
    lastName: shopper.last_name || '',
    state: (shopper.state || '').toUpperCase(),
    zipcode: shopper.zipcode || '',
    tobacco: shopper.smoker,
    contactEmail: shopper.contact_email || '',
    email: shopper.email,
    leadID: shopper.lead_id || '',
    leadType: shopper.lead_type || '',
    leadSource: shopper.lead_source || '',
    city: shopper.city || '',
    effectiveDate: shopper.effective_date || '',
    previousEffectiveDate: shopper.effective_date || '',
    subsidyPercent: shopper.subsidy_percent,
    subsidyLevel: shopper.subsidy_level,
    subsidyQuestion: shopper.subsidy_question || '',
    subsidyPercentageUnknown: shopper.subsidy_percentage_unknown,
    medicareID: shopper.medicare_id || '',
    eDateMonth: shopper.part_a_effective_month || '',
    eDateYear: shopper.part_a_effective_year || '',
    quickQuote: false,
    quotes: rfmQuotes,
    currentPlan: shopper.current_plan || '',
    recommendedPlan: shopper.recommended_plan || '',
    homePhone: shopper.home_phone ? shopper.home_phone : '',
    mobilePhone: shopper.mobile_phone ? shopper.mobile_phone : '',
    callPermission: shopper.permission_to_call,
    textPermission: shopper.permission_to_text,
    emailPermission: shopper.permission_to_email,
    planYear: shopper.plan_year || '',
    username: shopper.username || '',
    status: shopper.lead_status || '',
    isActive: shopper.current_lead,
    data: shopper.data,
  };
  return rfmData;
}

export function fmtLeadProfile(profile) {
  const rfmData = {
    id: profile.id,
    availability: profile.availability,
    profileType: profile.profile_type,
    optIn: profile.opt_in,
    states: profile.states,
    zipcode: profile.zipcode,
    languages: profile.languages,
    phone: profile.phone,
    drivingRadiusMiles: profile.driving_radius_miles,
    email: profile.email,
    maxDailyAppointments: profile.max_daily_appointments,
  };
  return rfmData;
}

export function fmtLeadProfileToAPI(profile) {
  const rfmData = {
    availability: profile.availability,
    profile_type: profile.profileType,
    opt_in: profile.optIn,
    states: profile.states,
    zipcode: profile.zipcode,
    languages: profile.languages,
    phone: profile.phone,
    driving_radius_miles: profile.drivingRadiusMiles,
    email: profile.email,
    max_daily_appointments: profile.maxDailyAppointments,
  };
  return rfmData;
}

export function fmtReport(r) {
  return {
    type: r.report_type,
    id: r.external_id,
    name: r.name,
    category: r.category,
    description: r.description,
    year: r.year,
    agency: r.agency,
    tabs: r.tabs,
    aws: r.aws_account,
    useStatePerms: r.use_state_perms,
  };
}
