import React from 'react';
import { connect } from 'react-redux';
import LoaderComponent from '../common/LoaderComponent';
import NavPage from '../nav/NavPage';
import { withRouterProps } from '../../utils/hocs';

class RouterLogic extends React.PureComponent {
  componentDidMount() {
    const { drugList, navigate } = this.props;

    // If the user does not have a drugList or it is empty, send them to add.
    // Use of setTimeout recommended here and solves the problem described in this issue:
    // https://github.com/remix-run/react-router/issues/7460#issuecomment-988642684
    setTimeout(() => {
      if (!drugList.length || drugList.length === 0) {
        navigate('/rx/add/drug');
      } else if (drugList.length > 0) {
        navigate('/rx/list');
      }
    }, 0);
  }

  render() {
    return (
      <NavPage activeLink="/rx" showShopperInfobar>
        <LoaderComponent />
      </NavPage>
    );
  }
}

const mapStateToProps = (store) => ({
  drugList: store.shopper.drugList,
});

export default connect(mapStateToProps)(withRouterProps(RouterLogic));
