import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { loadSoas } from '../../../actions/soa';

// Compoments
import LoaderButton from '../../common/LoaderButton';

// Utility
import { fmtApproval } from '../../../utils/general';
import { Table } from '../../../common/components';
import { useOutsideClick } from '../../../common/hooks';


function FormatItemsApprovedCell(soa) {
  const [openSoaApproval, setOpenSoaApproval] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, setOpenSoaApproval);

  const itemsApproved = (soa.mapd_ma ? 1 : 0)
  + (soa.pdp ? 1 : 0)
  + (soa.dvh ? 1 : 0)
  + (soa.sup_health ? 1 : 0)
  + (soa.medigap ? 1 : 0);

  return (soa.submit_date ? (
    <span
      className={`soa-approval-span ${openSoaApproval === soa.trace_id ? 'open' : ''}`}
      onClick={(e) => {
        if (openSoaApproval !== soa.trace_id) {
          setOpenSoaApproval(soa.trace_id);
        } else if (e.target.id === soa.trace_id) {
          setOpenSoaApproval(false);
        }
      }}
      role="button"
      tabIndex={0}
      id={soa.trace_id}
      style={{ userSelect: 'none' }}
      ref={ref}
    >
      <FontAwesomeIcon
        className="me-2"
        style={{ marginBottom: '3px' }}
        icon={faSortDown}
      />
      {itemsApproved}
      <div
        className={`soa-approval-popover ${openSoaApproval === soa.trace_id ? 'open' : ''}`}
        style={{ lineHeight: '28px', width: '200px' }}
      >
        <div>{fmtApproval(soa.mapd_ma)} MA / MAPD</div>
        <div>{fmtApproval(soa.pdp)} PDP</div>
        <div>{fmtApproval(soa.dvh)} Dental / vision / hearing products</div>
        <div>{fmtApproval(soa.sup_health)} Supplemental health products</div>
        <div>{fmtApproval(soa.medigap)} Medicare Supplement</div>
      </div>
    </span>
  ) : <span id="AAAA" style={{ marginLeft: '18px' }}>--</span>
  );
}


function History() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    shopper: {
      soaList = [],
      username,
    },
    theme: { soaLink = 'https://dev.soa.mymedicarebot.com' },
  } = useSelector(store => store);

  function refreshSoas() {
    setLoading(true);
    loadSoas(username)
      .then((data) => {
        dispatch({ type: 'SET_SHOPPER_SOAS', payload: data });
        setLoading(false);
      }).catch(() => setLoading(false));
  }

  // Only show 'Pending' once on a row, from Opened -> Signed priority
  function fmtDate(date, showElement) {
    let tdElement = <span>--</span>;
    if (showElement) {
      tdElement = <span className="font-italic text-muted">Pending</span>;
    }
    if (date) {
      if (date.indexOf('T00:00:00Z') !== -1) {
        // If we are parsing external SOA dates, do not convert them to local timezone
        tdElement = moment.utc(date).format('MM/DD/YYYY');
      } else {
        tdElement = moment(date).format('MM/DD/YYYY h:mm A');
      }
    }
    return tdElement;
  }

  function openSoa(id) {
    const token = localStorage.getItem('SessionToken');
    const site = process.env.REACT_APP_HOSTNAME || window.location.hostname;
    const cookieAge = 60 * 1; // One minute expiration
    const cookieDomain = '.mymedicarebot.com';

    document.cookie = `soaToken=${token}; max-age=${cookieAge}; domain=${cookieDomain}; path=/; Secure;`;
    document.cookie = `site=${site}; max-age=${cookieAge}; domain=${cookieDomain}; path=/; Secure;`;

    const url = `${soaLink}/submitted/${id}`;
    window.open(url);
  }

  useEffect(() => {
    if (username) {
      refreshSoas();
    }
  }, []);

  const refreshText = (
    <span>
      <FontAwesomeIcon icon={faRedo} className="me-2" />
      Refresh
    </span>
  );

  // Cell Formatters
  const formatChannelCell = (soa) => (
    <div style={{ textTransform: soa.delivery_method === 'sms' ? 'uppercase' : 'capitalize' }}>
      {soa.delivery_method === 'other' && soa.other
        ? soa.other
        : soa.delivery_method}
    </div>
  );

  const formatDateSentCell = (soa) => (
    <div className={soa.delivery_method === 'other' && 'text-muted'}>
      {soa.delivery_method === 'other'
        ? 'N/A'
        : moment(soa.created).format('MM/DD/YYYY h:mm A')}
    </div>
  );

  const formatApptCell = (apptDate) => (
    apptDate ? moment(apptDate).format('MM/DD/YYYY') : '--'
  );

  const formatOpenedCell = (soa) => (
    <div className={soa.delivery_method === 'other' ? 'text-muted' : ''}>
      {soa.delivery_method === 'other'
        ? 'N/A'
        : fmtDate(soa.opened_date, (!soa.opened))}
    </div>
  );

  const formatSignedCell = (soa) => (
    fmtDate(soa.submit_date, (soa.opened && !soa.submitted))
  );

  const formatViewSOACell = (soa) => (
    soa.submit_date && soa.delivery_method !== 'other' ? (
      <span
        className="fw-bold link me-2"
        style={{ color: '#0058FF', cursor: 'pointer' }}
        onClick={() => openSoa(soa.trace_id)}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon className="me-2" icon={faFileAlt} />
        View SOA
      </span>
    ) : null
  );

  const schema = {
    cols: [
      {
        header: 'Channel',
        passAllData: true,
        formatter: formatChannelCell,
      },
      {
        header: 'Date Sent',
        passAllData: true,
        formatter: formatDateSentCell,
      },
      {
        header: 'Date of appointment',
        field: 'date_of_appointment',
        formatter: formatApptCell,
      },
      {
        header: 'Opened',
        passAllData: true,
        formatter: formatOpenedCell,
      },
      {
        header: 'Signed',
        passAllData: true,
        formatter: formatSignedCell,
      },
      {
        header: 'Items Approved',
        passAllData: true,
        formatter: FormatItemsApprovedCell,
      },
      {
        passAllData: true,
        formatter: formatViewSOACell,
      },
    ],
  };

  return (
    <>
      {soaList.length > 0 ? (
        <Table
          schema={schema}
          data={soaList}
          className="table table-striped drug-list-table"
        />
      ) : (
        <table className="table table-striped drug-list-table">
          <thead style={{ whiteSpace: 'nowrap' }}>
            <tr>
              <th>Channel</th>
              <th>Date sent</th>
              <th>Date of Appointment</th>
              <th>Opened</th>
              <th>Signed</th>
              <th style={{ paddingLeft: '18px' }}>Items approved</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: '200px', userSelect: 'none' }}>
              <td colSpan="12" className="text-center align-middle">
                <i>Looks like there has been no Scope of Appointment sent yet.</i>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <div className="d-flex mb-3">
        <div className="d-flex justify-content-start me-3">
          <LoaderButton
            text={refreshText}
            className="fw-bold"
            style={{ minWidth: '140px' }}
            color="primary"
            loadingText="Loading..."
            loading={loading}
            onClick={() => refreshSoas()}
          />
        </div>
      </div>
    </>
  );
}

export default History;
