import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentExclamation } from '@fortawesome/pro-solid-svg-icons';

function Alert({
  children,
  className, id, style,
}) {
  return (
    <div
      className={[
        'd-flex align-items-center',
        'p-3 pe-4 mt-3 mb-3',
        'border border-warning-border bg-warning-bg',
        'shadow-sm',
        className,
      ].join(' ')}
      id={id}
      style={{ width: 'fit-content', ...style }}
    >
      <FontAwesomeIcon icon={faCommentExclamation} className="fs-3 me-3 o-2 text-warning-border" />
      {children}
    </div>
  );
}
export default Alert;
