import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureAxios from './configureAxios';
import App from './components/App';
import { store, persistor } from './store';

// Kill off any existing service worker.
if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
  navigator.serviceWorker.ready.then((reg) => {
    reg.unregister().then((worked) => {
      if (worked) {
        // eslint-disable-next-line
        console.info('[ServiceWorker] Unregistered OK');
        window.location.reload(true);
      } else {
        // eslint-disable-next-line
        console.error('[ServiceWorker] Failed to unregister!');
      }
    });
  });
} else {
  // eslint-disable-next-line
  console.info('[ServiceWorker] No worker found.');
}

// Setup axios defaults.
configureAxios();

// Register and render the application.
/* React 17
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
*/

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);
