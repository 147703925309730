import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';

import { setEnrollmentToken } from '../../utils/cookie';

const openTelephony = (enrollmentUrl, shopperUsername, phone, effectiveDate, dispatch) => {
  const token = localStorage.getItem('SessionToken');
  setEnrollmentToken(token);

  // Send our user to enrollment portal to digest the above cookie for auth
  const href = `${enrollmentUrl}/softphone/auth?s=${shopperUsername}&p=${phone}&e=${effectiveDate}`;
  const popup = window.open(href, undefined, 'popup=true,width=410,height=450');
  dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpen', value: true });
  // we watch for this in App.jsx componentDidMount and run the interval on app refresh
  // if this value is set to true.
  dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpenIntervalShouldRun', value: true });

  // we only want to allow one call window to be open at a time;
  const timer = setInterval(() => {
    if (popup.closed) {
      clearInterval(timer);
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpen', value: false });
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'contactId', value: null });
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'status', value: null });
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpenIntervalShouldRun', value: false });
    }
  }, 1000);

  // we need to get the data related to an aws connect call once it is started
  // so that we can use it to associate with a voice soa if submitted. Define
  // the function globally so that we do not get multiple duplicate event
  // listeners running at the same time.
  window.softphoneListener = function softphoneListener(event) {
    if (event.origin !== enrollmentUrl) return;
    const { data: { contactId, status } } = event;
    if (contactId) {
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'contactId', value: contactId });
    }
    if (status) {
      dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'status', value: status });
    }
  };
  window.addEventListener('message', window.softphoneListener);

  return popup;
};

function OpenTelephony({ setValue = () => {} }) {
  const dispatch = useDispatch();

  const { softphoneOpen } = useSelector(store => store.telephony);
  const { username, effectiveDate, homePhone } = useSelector(store => store.shopper);
  let { enrollment: enrollmentUrl } = useSelector(store => store.theme);
  // below is to make this functional on localhost dev server
  if (process.env.NODE_ENV === 'development') {
    enrollmentUrl = process.env.REACT_APP_ENROLLMENT_DEV_SERVER_URL;
  }

  const [phone, setPhone] = useState('');
  const [popup, setPopup] = useState(undefined);

  // phone # validation
  let phoneIsValid = false;
  const re = /^[\d]{10}$/;
  if (re.test(phone)) {
    phoneIsValid = true;
  }

  // store the current effective date as a cookie so that it can be read
  // by the softphone window which is hosted in the enrollment portal.
  // We need to be able to associate an effective date with every call.
  useEffect(() => {
    if (popup && effectiveDate) {
      const postData = { effectiveDate };
      popup.postMessage(postData, enrollmentUrl);
    }
  }, [popup, effectiveDate]);

  return (
    <>
      <div className="row mt-3">
        <label className="form-label" htmlFor="callPhone">
          Phone number
        </label>
        <div className="col-md-4">
          <input
            className="form-control"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={!username}
          />
        </div>
        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-primary fw-bold"
            onClick={() => {
              const popupRef = openTelephony(enrollmentUrl, username, phone, effectiveDate, dispatch);
              if (!homePhone) {
                // On the shopper details page, if homePhone isn't populated then populate it.
                // setValue is passed from form.jsx -> telephony/index.jsx -> OpenTelephony.jsx
                setValue('homePhone', phone);
              }
              setPopup(popupRef);
            }}
            disabled={!phoneIsValid || softphoneOpen}
          >
            <FontAwesomeIcon icon={faPhone} className="me-2" />
            Open call window
          </button>
        </div>
      </div>
      {softphoneOpen && (
        <div className="row mt-2">
          <i className="fs-5 text-danger">You may only have one call window open at a time</i>
        </div>
      )}
    </>
  );
}

export default OpenTelephony;
