import React from 'react';
import { Button, Guide } from '../../../guide';
import guidebook from '../../../guide/guidebook';

export default function () {
  return (
    <div className="ms-auto">
      <Button />
      <Guide book={guidebook} />
    </div>
  );
}
