const initialState = {
  loginLoading: false,
  errmsg: '',
  helpmsg: '',
  email: '',
  password: '',
  fingerprint: '',
  showVerification: false,
  verification: '',
  verificationLoading: false,
  showCaptcha: false,
  captchaSolved: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_LOGIN_LOADING':
      return { ...state, loginLoading: action.payload };
    case 'SET_VERIFICATION_LOADING':
      return { ...state, verificationLoading: action.payload };
    case 'SET_LOGIN_ERR':
      return { ...state, errmsg: action.payload };
    case 'SET_LOGIN_HELP':
      return { ...state, helpmsg: action.payload };
    case 'SET_LOGIN_VERIFICATION_DISPLAY':
      return { ...state, showVerification: action.payload };
    case 'SET_LOGIN_EMAIL':
      return { ...state, email: action.payload };
    case 'SET_LOGIN_PASSWORD':
      return { ...state, password: action.payload };
    case 'SET_LOGIN_VERIFICATION':
      return { ...state, verification: action.payload };
    case 'SET_LOGIN_FINGERPRINT':
      return { ...state, fingerprint: action.payload };
    case 'SET_LOGIN_CAPTCHA_DISPLAY':
      return { ...state, showCaptcha: action.payload };
    case 'SET_LOGIN_CAPTCHA_SOLVED':
      return { ...state, captchaSolved: action.payload };
    case 'CLEAR_LOGIN':
      // Don't clear our WAF-token loadin
      return { ...initialState, captchaSolved: state.captchaSolved };
    default:
      return state;
  }
}
