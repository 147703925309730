import React, { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';

// Utilities
import { get } from 'lodash';
import { formatBenefits } from '../../../utils/formatFunctions';
import { formatDollarAmount } from '../../../utils/general';

function CompareTable({
  tableLabel,
  plans,
  dataKeys,
  type,
}) {
  const [opening, setOpening] = useState(false);
  const [visible, setVisible] = useState(true);
  const [pharmacyPrefixSelected, setPharmacyPrefixSelected] = useState('Pref');
  const [pharmacyPhaseSelected, setPharmacyPhaseSelected] = useState('10');

  function handleChange(event) {
    if (event.target.id === 'pharmacyPrefixSelected') {
      setPharmacyPrefixSelected(event.target.value);
    } else {
      setPharmacyPhaseSelected(event.target.value);
    }
  }

  function handleToggle(e, isCurrentlyVisible, isCurrentlyOpening) {
    e.stopPropagation();
    /* Animate closing/opening our tables */
    if (isCurrentlyVisible && !isCurrentlyOpening) {
      setVisible(false);
    } else if (!isCurrentlyVisible && !isCurrentlyOpening) {
      setOpening(true);
      setVisible(true);
      window.setTimeout(() => {
        setVisible(!isCurrentlyVisible);
        setOpening(false);
      }, 250);
    }
  }

  function getDescription(data, tier) {
    let text = '';
    const desc = data[`${tier}-30`]?.d;
    if (!desc) {
      text = '--';
    } else if (Number(desc) > 0) {
      // NOTE(santeyio): not sure why we have the redundant tier description...
      //  put it this way per Priya's request to match the plan details display
      text = `Tier ${desc}-${desc}`;
    } else {
      text = HTMLReactParser(desc);
    }
    return text;
  }

  function notCoveredComponent(planType) {
    return {
      comp: planType === 'PDP' ? 'N/A' : 'Not covered',
      id: planType === 'PDP' ? 'N/A' : 'Not covered',
    };
  }

  // Logic lifted from plans/details/PharmacyCoverage
  // Take this back in a future refactor as the loops done there are massively inefficient
  function getMedicationCost(benefit) {
    // const { pharmacyPrefixSelected } = this.state;
    const prefix = pharmacyPrefixSelected;

    let returnVal = '-';
    if (benefit[`${prefix}PaymentDesc`] === 'Copay') {
      // The case where there are 'Descriptions' returned, with keys e.g. MailPrefPaymentBenefitDescription
      // This will look like '$3.95 Copay or 5% Coinsurance, whichever is greater.'
      if (benefit[`${prefix}PaymentBenefitDescription`]) {
        returnVal = benefit[`${prefix}PaymentBenefitDescription`];
      } else {
        returnVal = `${formatDollarAmount(benefit[`${prefix}PaymentValue`])} Copay`;
      }
    } else if (benefit[`${prefix}PaymentDesc`] === 'CoInsurance') {
      if (benefit[`${prefix}PaymentBenefitDescription`]) {
        returnVal = benefit[`${prefix}PaymentBenefitDescription`];
      } else {
        returnVal = `${(benefit[`${prefix}PaymentValue`] * 100).toFixed(0)}% CoInsurance`;
      }
    } else if (benefit[`${prefix}PaymentDesc`] === 'None') {
      // Longer concat strings e.g. "X copay or X% (whichever the doctor likes)
      if (benefit[`${prefix}PaymentBenefitDescription`]) {
        returnVal = benefit[`${prefix}PaymentBenefitDescription`];
      } else {
        returnVal = 'None';
      }
    }

    return returnVal;
  }

  // Current comparison table options
  // Type       Label
  // 'key'      Competitive Intelligence
  // 'list'     Extra Benefits
  // 'list'     Plan Benefits
  // 'list'     Prescription Drug Tiers
  // dataKeys are used to access each given plan's information
  // todo(joeysapp): This entire 200-line component should be lifted out
  // or (my initial hope) we can unify all three tables display logic
  function getTable() {
    // const { pharmacyPrefixSelected, pharmacyPhaseSelected } = this.state;
    let table = null;
    if (type === 'key') {
      // Accessing plan details with keys
      /* eslint-disable arrow-body-style */
      table = dataKeys.map((dk) => {
        return (
          <div key={dk.label} className="plandetails-table-row plandetails-table-row-sm" style={{ borderLeft: '0', borderRight: '0', borderBottom: '1' }}>
            <div className="planlist-recommended-col" style={{ paddingLeft: '20px', borderLeft: '0' }}>{dk.label}</div>
            <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{get(plans[0], dk.key) || '--'}</div>
            <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{get(plans[1], dk.key) || '--'}</div>
            <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{get(plans[2], dk.key) || '--'}</div>
          </div>
        );
      });
    } else if (type === 'list') {
      // Access plan details by iterating through a given dataKey

      // Remark 1: The table y length must be max(plan[0].dk.len, plan[1].dk.len, plan[2].dk.len)
      //           Meaning every plans's info can be different, but we must create empty columns for missing info
      //           to ensure the information of every column/row matches with the left label
      const trueIcon = <FontAwesomeIcon icon={faCheck} style={{ fontSize: '20px', verticalAlign: '-0.25rem' }} className="text-green" />;
      const falseIcon = <FontAwesomeIcon icon={faTimes} style={{ fontSize: '20px', verticalAlign: '-0.25rem', marginLeft: '2px' }} className="text-grey" />;

      // Each plan's list of items [ExtraBenefits, PlanBenefits, ...]
      let l1 = get(plans[0], dataKeys) || [];
      let l2 = get(plans[1], dataKeys) || [];
      let l3 = get(plans[2], dataKeys) || [];

      if (tableLabel === 'Extra Benefits') {
        // Create rows using a dict to ensure we have no duplicates
        const rows = [...l1, ...l2, ...l3].reduce((curRows, b) => ({ ...curRows, [b.BenefitID]: b.BenefitName }), {});

        // Create dicts from lists once so we don't have to filter through the lists every row to find our given value
        // Dictionaries of { BenefitID: true/false, ... }
        const d1 = l1.length && l1.reduce((curDict, b) => ({ ...curDict, [b.BenefitID]: b.BenefitValue === 'Yes' }), {}); // {} is beginning obj
        const d2 = l2.length && l2.reduce((curDict, b) => ({ ...curDict, [b.BenefitID]: b.BenefitValue === 'Yes' }), {});
        const d3 = l3.length && l3.reduce((curDict, b) => ({ ...curDict, [b.BenefitID]: b.BenefitValue === 'Yes' }), {});

        table = Object.keys(rows).map((key) => {
          // If our dict of plan n exists, check its value
          // otherwise, we haven't selected a plan n
          /* eslint-disable no-nested-ternary */
          const v1 = d1 ? (d1[key] ? trueIcon : falseIcon) : '--';
          const v2 = d2 ? (d2[key] ? trueIcon : falseIcon) : '--';
          const v3 = d3 ? (d3[key] ? trueIcon : falseIcon) : '--';

          return (
            <div
              className="plandetails-table-row plandetails-table-row-sm"
              style={{ borderLeft: '0', borderRight: '0', borderBottom: '1' }}
              key={`${rows[key]}-${key}`}
            >
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px', borderLeft: '0' }}>{rows[key]}</div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{v1}</div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{v2}</div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px' }}>{v3}</div>
            </div>
          );
        });
      } else if (tableLabel === 'Plan Benefits') {
        // Remark 1 is particularly painful here, though we can likely
        // use a lot of the above (and hopefully combine these if cases?)
        const fb1 = formatBenefits((plans[0] || {}).plan || {});
        const fb2 = formatBenefits((plans[1] || {}).plan || {});
        const fb3 = formatBenefits((plans[2] || {}).plan || {});

        // We construct rows afterwards because formatBenefits also handles supression
        // of certain benefits, e.g. Optional supplemental benefits
        const rows = [...fb1, ...fb2, ...fb3].reduce((curRows, b) => ({ ...curRows, [b.benefitId]: b.benefitName }), {});

        // Dictionaries of { BenefitCategoryID: { c: [ ele1, ele2, ... ], h: hover }, ... }
        // - Remark: c in this object is a list of transformed elements from the plaintext-but-HTML DR response
        const d1 = fb1.length && fb1.reduce((d, b) => ({ ...d, [b.benefitId]: { c: b.component, h: b.hover } }), {});
        const d2 = fb2.length && fb2.reduce((d, b) => ({ ...d, [b.benefitId]: { c: b.component, h: b.hover } }), {});
        const d3 = fb3.length && fb3.reduce((d, b) => ({ ...d, [b.benefitId]: { c: b.component, h: b.hover } }), {});

        table = Object.keys(rows).map((key) => {
          // Setting initial td value if we have selected a plan
          let v1 = d1 ? { c: [notCoveredComponent(plans[0].planType)], id: 'none' } : '--';
          let v2 = d2 ? { c: [notCoveredComponent(plans[1].planType)], id: 'none' } : '--';
          let v3 = d3 ? { c: [notCoveredComponent(plans[2].planType)], id: 'none' } : '--';

          // Second value check - now using d[key] - if it is not present in the dictionary,
          // it is either not covered or N/A depending on MA/MAPD or PDP PlanType
          if (d1 && d1[key]) { v1 = d1[key]; }
          if (d2 && d2[key]) { v2 = d2[key]; }
          if (d3 && d3[key]) { v3 = d3[key]; }

          return (
            <div
              className="plandetails-table-row plandetails-table-row-sm"
              style={{
                borderLeft: '0',
                borderRight: '0',
                borderBottom: '1',
                height: 'auto',
              }}
              key={`${rows[key]}-${key}`}
            >
              <div
                className="planlist-recommended-col"
                style={{
                  borderLeft: '0',
                  lineHeight: '22px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  paddingRight: '20px',
                  paddingLeft: '20px',
                }}
              >
                {rows[key]}
              </div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                {
                  v1.c
                    ? v1.c.map((c) => <div key={`${key}-${c.id}-1`} title={v1.h}>{c.comp}</div>)
                    : v1
                  }
              </div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                {
                  v2.c
                    ? v2.c.map((c) => <div key={`${key}-${c.id}-2`} title={v2.h}>{c.comp}</div>)
                    : v2
                }
              </div>
              <div className="planlist-recommended-col" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                {
                  v3.c
                    ? v3.c.map((c) => <div key={`${key}-${c.id}-3`} title={v3.h}>{c.comp}</div>)
                    : v3
                }
              </div>
            </div>
          );
        });
      } else if (tableLabel === 'Prescription Drug Tiers') {
        // Explanation of these options/phases can be found on plans/details/PharmacyCoverage
        const pharmacyPrefixOptions = [
          { key: 'Pref', description: 'Preferred pharmacy' },
          { key: 'NonPref', description: 'Standard pharmacy' },
          { key: 'MailPref', description: 'Preferred mail order pharmacy' },
          { key: 'MailNonPref', description: 'Standard mail order pharmacy' },
        ];
        const pharmacyCoverageOptions = [
          { key: '10', description: 'Initial coverage phase' },
          // { key: '20', description: 'Coverage gap phase' },
          { key: '30', description: 'Catastrophic phase' },
        ];
        const rows = [
          { tier: 1 },
          { tier: 2 },
          { tier: 3 },
          { tier: 4 },
          { tier: 5 },
          { tier: 6 },
        ];

        // First we reduce our lists to only our selected phase
        l1 = l1.filter((b) => b.CoveragePhase === Number(pharmacyPhaseSelected));
        l2 = l2.filter((b) => b.CoveragePhase === Number(pharmacyPhaseSelected));
        l3 = l3.filter((b) => b.CoveragePhase === Number(pharmacyPhaseSelected));
        const d1 = l1.length && l1.reduce((d, b) => ({ ...d, [`${b.TierNum}-${b.DaysSupply}`]: { c: getMedicationCost(b), d: b.TierDescription } }), {});
        const d2 = l2.length && l2.reduce((d, b) => ({ ...d, [`${b.TierNum}-${b.DaysSupply}`]: { c: getMedicationCost(b), d: b.TierDescription } }), {});
        const d3 = l3.length && l3.reduce((d, b) => ({ ...d, [`${b.TierNum}-${b.DaysSupply}`]: { c: getMedicationCost(b), d: b.TierDescription } }), {});

        const colStyle = {
          lineHeight: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '8px',
          paddingBottom: '8px',
        };
        table = (
          <div>
            <div className="w-100 d-flex flex-row me-0 ms-auto align-items-center mt-3 mb-3">
              <label className="me-0 ms-auto" htmlFor="pharmacyPhaseSelected">
                Coverage phase:
              </label>
              <div>
                <select
                  className="form-control plandetails-pharmacy-select-dropdown"
                  id="pharmacyPhaseSelected"
                  name="select"
                  onChange={handleChange}
                  style={{ cursor: 'pointer', minWidth: '200px' }}
                  type="select"
                  value={pharmacyPhaseSelected}
                >
                  {pharmacyCoverageOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.description}
                    </option>
                  ))}
                </select>
                <i
                  className="fa fa-chevron-down"
                  style={{
                    color: 'black',
                    cursor: 'pointer',
                    fontSize: '15px',
                    marginLeft: '-25px',
                    pointerEvents: 'none',
                  }}
                />
              </div>
              <label className="ms-4" htmlFor="pharmacyPrefixSelected">
                Pharmacy type:
              </label>
              <div>
                <select
                  className="form-control plandetails-pharmacy-select-dropdown"
                  id="pharmacyPrefixSelected"
                  name="select"
                  onChange={handleChange}
                  style={{ cursor: 'pointer' }}
                  type="select"
                  value={pharmacyPrefixSelected}
                >
                  {pharmacyPrefixOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                      {option.description}
                    </option>
                  ))}
                </select>
                <i
                  className="fa fa-chevron-down"
                  style={{
                    color: 'black',
                    cursor: 'pointer',
                    fontSize: '15px',
                    marginLeft: '-25px',
                    pointerEvents: 'none',
                  }}
                />
              </div>
            </div>
            {
            rows.map((r) => {
              return (
                <div
                  className="plandetails-table-row plandetails-table-row-sm"
                  style={{
                    borderLeft: '0',
                    borderRight: '0',
                    borderBottom: '1',
                    height: 'auto',
                  }}
                  key={`${r.tier}-${r.label}`}
                >
                  <div
                    className="planlist-recommended-col d-flex flex-row"
                    style={{
                      borderLeft: '0',
                      lineHeight: '22px',
                      paddingRight: '20px',
                      paddingLeft: '20px',
                    }}
                  >
                    <div
                      className="d-flex flex-column"
                      style={{
                        width: '60%',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      }}
                    >
                      <b>Tier {r.tier}</b>
                    </div>
                    <div
                      className="d-flex flex-column me-0 ms-auto"
                      style={{
                        width: '40%',
                        paddingLeft: '20px',
                        borderLeft: '1px solid #E2E2E2',
                        paddingTop: '8px',
                        paddingRight: '8px',
                        paddingBottom: '8px',
                      }}
                    >
                      <span className="mb-1"><b>Supply</b></span>
                      <span>30 days</span>
                      <span>60 days</span>
                      <span>90 days</span>
                    </div>
                  </div>
                  <div className="planlist-recommended-col" style={colStyle}>
                    <div className="d-flex flex-column">
                      <span className="mb-1"><b>{getDescription(d1, r.tier)}</b></span>
                      <span>{d1[`${r.tier}-30`] ? HTMLReactParser(d1[`${r.tier}-30`].c) : '--'}</span>
                      <span>{d1[`${r.tier}-60`] ? HTMLReactParser(d1[`${r.tier}-60`].c) : '--'}</span>
                      <span>{d1[`${r.tier}-90`] ? HTMLReactParser(d1[`${r.tier}-90`].c) : '--'}</span>
                    </div>
                  </div>
                  <div className="planlist-recommended-col" style={colStyle}>
                    <div className="d-flex flex-column">
                      <span className="mb-1"><b>{getDescription(d2, r.tier)}</b></span>
                      <span>{d2[`${r.tier}-30`] ? HTMLReactParser(d2[`${r.tier}-30`].c) : '--'}</span>
                      <span>{d2[`${r.tier}-60`] ? HTMLReactParser(d2[`${r.tier}-60`].c) : '--'}</span>
                      <span>{d2[`${r.tier}-90`] ? HTMLReactParser(d2[`${r.tier}-90`].c) : '--'}</span>
                    </div>
                  </div>
                  <div className="planlist-recommended-col" style={colStyle}>
                    <div className="d-flex flex-column">
                      <span className="mb-1"><b>{getDescription(d3, r.tier)}</b></span>
                      <span>{d3[`${r.tier}-30`] ? HTMLReactParser(d3[`${r.tier}-30`].c) : '--'}</span>
                      <span>{d3[`${r.tier}-60`] ? HTMLReactParser(d3[`${r.tier}-60`].c) : '--'}</span>
                      <span>{d3[`${r.tier}-90`] ? HTMLReactParser(d3[`${r.tier}-90`].c) : '--'}</span>
                    </div>
                  </div>
                </div>
              );
            })
            }
          </div>
        );
      }
    }
    return table;
  }

  // Used for smooth opening transition (try to be as close as possible)
  const expectedHeights = {
    'Competitive Intelligence': '5em',
    'Extra Benefits': '5em',
    'Plan Benefits': '200em',
    'Prescription Drug Tiers': '200em',
  };

  return (
    <div style={{ marginRight: '30px' }}>
      <div
        className="plandetails-table-header"
        style={{ cursor: 'pointer' }}
        onClick={(e) => handleToggle(e, visible, opening)}
        role="button"
        tabIndex="0"
      >
        <div className="plandetails-table-header-title">
          {tableLabel || '--'}
        </div>
        <span className="plandetails-table-header-toggle" id="plandetails-table-header-toggle">
          <FontAwesomeIcon
            className="plandetails-table-header-toggle-icon"
            icon={visible ? faAngleUp : faAngleDown}
          />
        </span>
      </div>
      <div
        className="planlist-recommended"
        style={{
          maxHeight: visible ? expectedHeights.tableLabel : '0',
          overflow: visible && opening ? '' : 'hidden',
          transition: 'max-height .25s linear',
          paddingTop: '0',
          paddingBottom: '0',
          marginTop: '0',
          marginBottom: visible && !opening ? '40px' : '',
          marginRight: '0',
        }}
      >
        {getTable()}
      </div>
    </div>
  );
}

CompareTable.defaultProps = {
  tableLabel: '--',
  dataKeys: [],
  plans: [],
};

function mapStateToProps(store) {
  return {
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(CompareTable);
