export default function (state = {
  brandDrug: '',
  displayBrandDrug: true,
  deleteDrug: '',
  dosageList: [],
  editDrug: false,
  editDrugID: null,
  editIndex: null,
  genericDrug: '',
  packageList: [],
  search: '',
  selectedDrug: '',
  selectedFrequency: 30,
  selectedDosage: { DosageID: '' },
  selectedPackage: { PackageID: '' },
  selectedDosageGeneric: { IsDirectGenericAvailable: false },
  userQuantity: '',
  message: '',
}, action) {
  switch (action.type) {
    case 'SET_DRUG_SEARCH':
      return { ...state, search: action.payload };
    case 'CLEAR_DELETE_DRUG':
      return { ...state, deleteDrug: '' };
    case 'SET_DELETE_DRUG':
      return { ...state, deleteDrug: action.payload };
    case 'DISPLAY_BRAND_DRUG':
      return { ...state, displayBrandDrug: action.payload };
    case 'SET_BRAND_DRUG':
      return { ...state, brandDrug: action.payload };
    case 'SET_GENERIC_DRUG':
      return { ...state, genericDrug: action.payload };
    case 'SET_SELECTED_DRUG':
      return { ...state, selectedDrug: action.payload };
    case 'SET_SELECTED_DOSAGE':
      return { ...state, selectedDosage: action.payload };
    case 'SET_SELECTED_PACKAGE':
      return { ...state, selectedPackage: action.payload };
    case 'SET_SELECTED_DOSAGE_GENERIC':
      return { ...state, selectedDosageGeneric: action.payload };
    case 'SET_DOSAGES':
      return { ...state, dosageList: action.payload };
    case 'SET_PACKAGES':
      return { ...state, packageList: action.payload };
    case 'CLEAR_PACKAGES':
      return { ...state, packageList: [], selectedPackage: { PackageID: '' } };
    case 'SET_SELECTED_FREQUENCY':
      return { ...state, selectedFrequency: action.payload };
    case 'CLEAR_SELECTED_FREQUENCY':
      return { ...state, selectedFrequency: 30 };
    case 'SET_USER_QUANTITY':
      return { ...state, userQuantity: action.payload };
    case 'CLEAR_USER_QUANTITY':
      return { ...state, userQuantity: '' };
    case 'SET_MESSAGE':
      return { ...state, message: action.payload };
    case 'SET_EDIT_DRUG':
      return {
        ...state,
        editDrug: true,
        editIndex: action.payload.index,
        editDrugID: action.payload.id,
      };
    case 'CLEAR_EDIT_DRUG':
      return {
        ...state,
        editDrug: false,
        editIndex: null,
        editDrugID: null,
      };
    case 'CLEAR_SELECTED_DRUG':
      return {
        ...state,
        selectedDrug: '',
        selectedFrequency: 30,
        selectedDosage: { DosageID: '' },
        selectedPackage: { PackageID: '' },
        selectedDosageGeneric: { IsDirectGenericAvailable: false },
        userQuantity: '',
        editDrug: false,
        editIndex: null,
        editDrugID: null,
        dosageList: [],
        packageList: [],
        brandDrug: '',
        genericDrug: '',
        displayBrandDrug: true,
      };
    default:
      return state;
  }
}
