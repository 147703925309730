import axios from 'axios';

export function loadLeadStatusOptions() {
  const token = localStorage.getItem('SessionToken');

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/leadstatus/`,
    headers: { Authorization: `Token ${token}` },
  }).then(({ data = {} }) => (data))
    .catch(err => {
      console.error(err);
    });
}
