/* eslint-disable react/jsx-no-bind */
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// components
import DateInput from './DateInput';

function DateOfBirth({
  disabled,
  customContainerClasses,
  required,
}) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  function handleChange(e) {
    const { value } = e.target;
    const dob = moment(value);
    // note(joeysapp): Until this is a hard requirement, it's hard to prevent 'incorrect' input here.
    // For now let's allow weird years bc the datepicker element is finnicky cross-platform (e.g. mac vs windows)
    // const yearsDifference = moment.duration(dob.subtract(moment.now())).asYears();
    if (!value || (dob && dob.year() <= 9999)) {
      dispatch({ type: 'UPDATE_SHOPPER', payload: { dob: value } });
    } else {
      e.preventDefault();
    }
  }

  return (
    <DateInput
      name="dob"
      label="Date of birth"
      handleChange={handleChange}
      disabled={disabled}
      customContainerClasses={customContainerClasses}
      required={required}
    />
  );
}

export default DateOfBirth;
