import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';

// Components
import NavItem from '../NavItem';
import NavPopover from '../NavPopover';
import Summary from './Summary';
import Add from './Add';
import Detail from './Detail';

function Notes({
  showNotesPopover,
  callNotes,
  // data,
}) {
  const [noteIndex, setNoteIndex] = useState(0);
  const [view, setView] = useState('summary');
  const [parentRef, setParentRef] = useState();

  return (
    <NavItem
      show={showNotesPopover}
      icon={faPencilAlt}
      count={callNotes.length}
      popoverName="showNotesPopover"
      setRef={setParentRef}
      title={'Add note'}
    >
      <NavPopover
        show={showNotesPopover}
        popoverName="showNotesPopover"
        parentRef={parentRef}
        style={{ width: '280px', padding: '20px' }}
      >
        {view === 'summary' && (
          <Summary
            setView={setView}
            noteIndex={noteIndex}
            setNoteIndex={setNoteIndex}
          />
        )}
        {view === 'add' && (
          <Add setView={setView} />
        )}
        {view === 'detail' && (
          <Detail
            setView={setView}
            noteIndex={noteIndex}
          />
        )}
      </NavPopover>
    </NavItem>
  );
}

function mapStateToProps(store) {
  return {
    showNotesPopover: store.popover.showNotesPopover,
    callNotes: store.shopper.callNotes,
    data: store.shopper.data,
  };
}

export default connect(mapStateToProps)(Notes);
