import React from 'react';
import { Routes, Route } from 'react-router-dom';

// components
import NavPage from '../nav/NavPage';
import List from './List';
import QuicksightReportLoader from './QuicksightReportLoader';

function ReportsRouter() {
  return (
    <NavPage
      activeLink="/dashboard"
      noPadding
    >
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="qs/:reportID" element={<QuicksightReportLoader />} />
      </Routes>
    </NavPage>
  );
}

export default ReportsRouter;
