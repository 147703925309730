import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faClinicMedical } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import NavItem from './NavItem';
import NavPopover from './NavPopover';

function Pharmacy({
  dispatch,
  navigate,
  pharmacyList,
  showPharmacyPopover,
  disabled,
}) {
  const [parentRef, setParentRef] = useState();

  return (
    <NavItem
      show={showPharmacyPopover}
      icon={faClinicMedical}
      count={pharmacyList.length}
      popoverName="showPharmacyPopover"
      setRef={setParentRef}
      title={pharmacyList && pharmacyList.length > 0 ? 'Change pharmacy' : 'Add pharmacy'}
    >
      <NavPopover
        show={showPharmacyPopover}
        popoverName="showPharmacyPopover"
        parentRef={parentRef}
      >
        <b>{pharmacyList.length} {pharmacyList.length > 1 || !pharmacyList.length ? 'Pharmacies' : 'Pharmacy'}</b>
        <div className="col navbar-drug-card-container mt-3">
          {
            pharmacyList && pharmacyList.length > 0
              ? (
                pharmacyList.map((pharmacy) => (
                  <div key={pharmacy.npi} className="row navbar-drug-card">
                    <div className="col py-2 mb-2">
                      <b>{pharmacy.name}</b>
                      <br />
                      {pharmacy.city !== 'AUTO' && (
                        <i>
                          {pharmacy.address1.trim()},&nbsp;
                          {pharmacy.city.trim()} {pharmacy.state.trim()},&nbsp;
                          {pharmacy.zip.trim()}
                        </i>
                      )}

                    </div>
                  </div>
                ))
              )
              : (
                <div className="row navbar-drug-card text-center align-middle">
                  <div className="col py-3">
                    <i>Looks like no pharmacies have been added yet.</i>
                  </div>
                </div>
              )
          }
        </div>
        <button
          type="button"
          disabled={disabled}
          className="btn btn-primary fw-bold mt-3"
          onClick={() => {
            navigate('/rx/add/pharmacy');
            dispatch({ type: 'HIDE_POPOVER', payload: 'showPharmacyPopover' });
          }}
        >
          {
            pharmacyList && pharmacyList.length > 0
              ? 'Change pharmacy'
              : 'Add pharmacy'
          }
        </button>
      </NavPopover>
    </NavItem>
  );
}

function mapStateToProps(store) {
  return {
    showPharmacyPopover: store.popover.showPharmacyPopover,
    pharmacyList: store.shopper.pharmacyList,
  };
}

export default connect(mapStateToProps)(withRouterProps(Pharmacy));
