import React from 'react';
import { connect } from 'react-redux';

// Actions
import { filterMedicare } from '../../../actions/planfilters';

// Components
import PlanCard from './plancard';
import SOABadge from '../../common/SOABadge';

class PDPList extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      carrierPerms,
      doctorList,
      medicareList,
      planSort,
      selectedPharmacy,
    } = this.props;

    const pdpCompleteList = filterMedicare(medicareList, {
      planType: ['PDP'],
      carrierPerms,
      doctorList,
      npi: selectedPharmacy,
      sortKey: planSort,
    });

    this.state = {
      totalPlanCount: pdpCompleteList.length,
    };
  }

  componentDidMount() {
    const {
      customBenefitsSearchKey,
      dispatch,
      PDPCustomBenefits,
    } = this.props;

    const payload = customBenefitsSearchKey.filter(benefit => PDPCustomBenefits.includes(benefit.id));
    dispatch({ type: 'SET_BENEFITS_VALUE', field: 'displayedBenefits', payload });
  }

  componentDidUpdate(prevProps) {
    const {
      carrierPerms,
      doctorList,
      dispatch,
      displayedBenefits,
      medicareList,
      planSort,
      planType,
      selectedPharmacy,
    } = this.props;

    if (planType !== prevProps.planType) {
      const completeList = filterMedicare(medicareList, {
        planType: ['PDP'],
        carrierPerms,
        doctorList,
        npi: selectedPharmacy,
        sortKey: planSort,
      });
      this.setState({
        totalPlanCount: completeList.length,
      });
    }

    if (JSON.stringify(displayedBenefits) !== JSON.stringify(prevProps.displayedBenefits)) {
      dispatch({ type: 'SET_BENEFITS_VALUE', field: 'PDPCustomBenefits', payload: displayedBenefits.map(benefit => benefit.id) });
    }
  }

  render() {
    const { totalPlanCount } = this.state;
    const {
      carrierFilters,
      carrierPerms,
      doctorList,
      enrollCarriersFilter,
      medicareList,
      planSort,
      selectedPharmacy,
      soa,
      soaList,
    } = this.props;

    const { PDP: filters } = carrierFilters;
    const displayCarriers = [];
    filters.forEach(([a, b]) => {
      if (b) displayCarriers.push(a);
    });

    const pdpFilteredList = filterMedicare(medicareList, {
      carrierFilters: displayCarriers,
      planType: ['PDP'],
      carrierPerms,
      doctorList,
      npi: selectedPharmacy,
      sortKey: planSort,
      enrollCarriersFilter,
    });

    const plans = pdpFilteredList;
    const currentFilterCount = pdpFilteredList.length;

    let pdpSoaStatus = true;
    if (soaList.length && soa) {
      if (soaList[0].submitted) {
        if (!soaList[0].pdp) {
          pdpSoaStatus = false;
        }
      }
    }

    return (
      <div id="planlist-pdp">
        <span className="d-flex align-items-center fs-5 ms-3 mb-3">
          {`${currentFilterCount} of ${totalPlanCount} plans`}
          <SOABadge planType="PDP" badgeType="text" />
        </span>
        {plans.map((plan) => (
          <PlanCard
            key={`${plan.cpsID}`}
            plan={plan}
            soaStatus={pdpSoaStatus}
          />
        ))}
      </div>
    );
  }
}

PDPList.defaultProps = {
  plans: [],
};

function mapStateToProps(store) {
  return {
    carrierPerms: store.agent.carrierPermissions,
    carrierFilters: store.plan.carrierFilters,
    currentPlan: store.shopper.currentPlan,
    customBenefitsSearchKey: store.benefits.customBenefitsSearchKey,
    doctorList: store.shopper.doctorList,
    displayedBenefits: store.benefits.displayedBenefits,
    enrollCarriersFilter: store.plan.enrollCarriersFilter,
    medicareList: store.plan.medicareList,
    planSort: store.plan.planSort,
    PDPCustomBenefits: store.benefits.PDPCustomBenefits,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    soaList: store.shopper.soaList,
    soa: store.theme.features.soa,
  };
}

export default connect(mapStateToProps)(PDPList);
