import { store } from '../store';

export const hasCarrierPerms = (planType, planCarrierID, planState, planYear) => {
  const {
    agent,
  } = store.getState();

  const perms = agent.carrierPermissions || [];

  let hasPerms = false;
  let iNpn; // individual
  let cNpn; // corporate

  if (agent.npn_type === 'individual') {
    iNpn = agent.npn;
    cNpn = agent.npnLinked;
  } else if (agent.npn_type === 'corporate') {
    iNpn = agent.npnLinked;
    cNpn = agent.npn;
  } else {
    iNpn = agent.npn;
    cNpn = agent.npnLinked;
  }

  const iPerm = perms.find((p) => (
    p.active
      && p.carrier_id === planCarrierID
      && p.npn === iNpn
      && String(p.plan_year) === String(planYear)
  )) || {};
  const cPerm = perms.find((p) => (
    p.active
      && p.carrier_id === planCarrierID
      && p.npn === cNpn
      && String(p.plan_year) === String(planYear)
  )) || {};

  const iStates = iPerm.states || [];
  const cStates = cPerm.states || [];
  const iHasState = iStates.includes(planState);
  const cHasState = cStates.includes(planState);

  const hasProduct = (perm, product) => {
    const productMap = {
      MAPD: perm.product_mapd,
      PDP: perm.product_pdp,
      MA: perm.product_ma,
      Medigap: perm.product_medigap,
    };
    return Boolean(productMap[product]);
  };

  if (iHasState && cHasState) {
    hasPerms = hasProduct(iPerm, planType);
  } else if (iHasState) {
    hasPerms = hasProduct(iPerm, planType);
  } else if (cHasState) {
    hasPerms = hasProduct(cPerm, planType);
  }

  return hasPerms;
};

export const enabledEnrollPlan = ({ carrier, cpsID, planYear }) => {
  const {
    plan: { enrollDisabledPlans = [] },
    theme: {
      enrollCarriers = {},
    },
  } = store.getState();

  // if cps id is blacklisted
  if (enrollDisabledPlans.includes(cpsID)) {
    return false;
  }

  // if carrier does not have enrollment enabled
  const validCarriers = Object.keys(enrollCarriers);
  const validYears = enrollCarriers[carrier] || [];
  if (validCarriers.includes(carrier) && validYears.includes(planYear)) {
    return true;
  }
  return false;
};

export const canEnrollPlan = ({
  planType,
  carrierID,
  planState,
  cpsID,
  carrier,
  planYear,
}) => {
  const {
    theme: {
      features: {
        carrierPermissions,
      },
    },
  } = store.getState();

  if (enabledEnrollPlan({ carrier, cpsID, planYear: String(planYear) })) {
    // if carrier permissions feature enabled
    if (carrierPermissions) {
      return hasCarrierPerms(planType, carrierID, planState, String(planYear));
    }
  } else {
    return false;
  }
  // if none of the above cases...
  return true;
};

// showing of 'share quote' is determined only by feature switch currently
// eslint-disable-next-line
export function canSharePlan({
  // quote: { carrier, planYear },
}) {
  const {
    features: { shareQuote },
    // shareQuoteCarriers = [], // specified in the format 'Humana': ["2023", "2024"],
  } = store.getState().theme;

  // hra theme feature enabled
  if (!shareQuote) return false;
  return true;

  /*
  let carrierPerms = false;
  const validCarriers = Object.keys(shareQuoteCarriers);
  const validYears = shareQuoteCarriers[carrier] || [];
  if (validCarriers.includes(carrier) && validYears.includes(planYear)) carrierPerms = true;
  if (!carrierPerms) return false;
  return true;
  */
}
