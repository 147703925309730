import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import StandardModal from '../common/StandardModal';

class StatePermissionModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
  }

  close() {
    this.props.dispatch({ type: 'HIDE_MODAL', payload: 'statePermissionModal' });
  }

  render() {
    return (
      <StandardModal
        isOpen={this.props.statePermissionModal}
        closeModalAction={this.close}
        title="Warning!"
      >
        <div className="px-3">
          You are not licensed to quote or enroll this shopper in the current state.
        </div>
        <div className="px-3 pb-3 text-right">
          <Button className="ms-1" onClick={this.close}>Close</Button>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    statePermissionModal: store.modal.statePermissionModal,
  };
}

export default connect(mapStateToProps)(StatePermissionModal);
