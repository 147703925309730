import axios from 'axios';
// import { store } from '../store';

// eslint-disable-next-line
export function getCalls(shopperUsername) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/telephony/shoppercalls/${shopperUsername}/`,
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      });
  });
}
