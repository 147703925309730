/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ModalBase, LoaderButton } from '../../common/components';

import { updateHRA } from '../../actions/hra';

function Header({ confirmationNumber }) {
  return (
    <div className="d-flex flex-column text-nowrap">
      <div>
        Health Risk Assesment
      </div>
      <div className="fw-normal fs-5 mt-1 d-flex">
        <div className="me-1">Confirmation ID:</div>
        <div>{confirmationNumber}</div>
      </div>
    </div>
  );
}

function ExitButtons({ loading }) {
  const dispatch = useDispatch();
  const { hra: storeHRA } = useSelector(store => store);
  const { externalURL, externalRedirect, submittedFlag, carrierName, quoteID } = storeHRA;
  const [link, setLink] = useState('');

  /* eslint-disable no-unused-expressions */
  const DEBUG = true;
  useEffect(() => {
    DEBUG && console.debug('ExternalHRA[00]', storeHRA);
    if (!externalRedirect) {
      // Simple URL
      setLink(externalURL);
      DEBUG && console.debug(`ExternalHRA[01] \n link -> ${externalURL}`);
    } else {
      // Redirect through the API to an external location
      const newLink = `${process.env.REACT_APP_MMB_API_URL}/api/utils/hra/${carrierName.toLowerCase()}/?quote_id=${quoteID}&site=${process.env.REACT_APP_HOSTNAME || window.location.hostname}`;
      setLink(newLink);
      DEBUG && console.debug(`ExternalHRA[02] \n link -> ${newLink}`);
    }
  }, [externalURL, externalRedirect, submittedFlag, quoteID]);

  return (
    <div className="d-flex ms-auto me-0">
      {submittedFlag
        ? null
        : (
          <LoaderButton
            className="btn btn-outline-secondary me-0 ms-auto"
            style={{ maxHeight: '38px' }}
            text={'No, go back'}
            onClick={() => {
              dispatch({ type: 'SET_MODAL', payload: { externalHRAModal: false } });
            }}
          />
        )}
      {submittedFlag
        ? (
          <LoaderButton
            className="btn btn-primary me-0 ms-auto"
            text={'Return to Quotes'}
            loading={loading}
            onClick={() => {
              dispatch({ type: 'SET_MODAL', payload: { externalHRAModal: false } });
            }}
          />
        )
        : (
          <div className="d-flex flex-column align-items-center">
            <a
              className="btn btn-primary me-0 ms-3"
              disabled={submittedFlag}
              target="_blank"
              rel="noreferrer"
              href={link}
            >
              Okay, start HRA
            </a>
          </div>
        )}
    </div>
  );
}

function ExternalHRAModal() {
  const dispatch = useDispatch();
  const { externalHRAModal } = useSelector(store => store.modal);
  const { quotes = [] } = useSelector(store => store.shopper);

  const hra = useSelector(store => store.hra);
  const {
    submittedFlag,
    quoteID,
    siteName,
    carrierName,
    externalRedirectDescription,
  } = hra;

  const [loading, setLoading] = useState(false);
  const [confirmationNumber, setConfirmationNumber] = useState('');
  useEffect(() => {
    const quote = quotes.filter(q => q.quoteID === quoteID)[0] || {};
    const { enrollment = {} } = quote;
    const { confirmation_number } = enrollment;
    setConfirmationNumber(confirmation_number);
  }, [quoteID]);

  async function handleClick() {
    setLoading(true);
    hra.submittedFlag = !hra.submittedFlag;
    const submittedHRA = await updateHRA(hra);
    dispatch({ type: 'SET_HRA', payload: submittedHRA });
    dispatch({ type: 'SET_REFRESH_QUOTES_EVENT', payload: true });
    setLoading(false);
  }

  return (
    <ModalBase
      isOpen={externalHRAModal}
      title={<Header confirmationNumber={confirmationNumber} />}
      width={'75vw'}
      closeIcon={false}
    >
      <div className="d-flex flex-column border-top pt-2">
        <div>
          {externalRedirectDescription
             || `You are about to be redirected to ${siteName}. Once logged in, you may submit a HRA on behalf of your client. Compensation for HRAs are dependent on submission date. Please check with ${carrierName} for HRA submission and compensation guidelines.`}
        </div>
        <div className="mt-2 mb-2">
          <div className="fs-3 fw-bold">Remember:</div>
          <div className="d-flex flex-column">
            <div>You are not required to record your call during a health risk assessment.</div>
            <div>If you are in a telephonic meeting, your call recording will continue.</div>
            <div>After you have submitted the HRA, you <b>must end</b> the call recording and close the telephony window before working with other clients.</div>
          </div>
        </div>
      </div>
      <div className="d-flex border-top pt-2">
        <div className="d-flex form-check">
          <input
            className="me-2 form-check-input cursor-pointer"
            onChange={handleClick}
            disabled={loading}
            checked={submittedFlag}
            type="checkbox"
            id="submitted-hra"
          />
          <label className="form-check-label cursor-pointer" htmlFor="submitted-hra">I have already submitted this HRA</label>
        </div>
        <ExitButtons loading={loading} />
      </div>
    </ModalBase>
  );
}
export default ExternalHRAModal;
