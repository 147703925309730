/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { getMeetingTypeOptions } from './utils';

const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY',
  'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
  'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

function Checkbox({
  meetingType,
  label,
  disabled,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const {
    meetingTypes = {},
    meetingTypes: {
      [meetingType]: selected = false,
    } = {},
  } = reduxProfile;

  function handleCheck() {
    const payload = { meetingTypes: { ...meetingTypes } };
    payload.meetingTypes[meetingType] = !selected;
    setReduxProfileKeys(payload);
  }

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        onChange={() => handleCheck()}
        checked={selected}
        disabled={disabled}
      />
      <label
        onClick={() => handleCheck()}
        className={`form-check-label ${disabled && 'text-muted'}`}
      >
        {label}
      </label>
    </div>
  );
}

function OfficeAddress({
  disabled,
  inPersonOffice,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const {
    state: officeState,
    zip: officeZip,
    address: officeAddress,
    city: officeCity,
  } = (reduxProfile.officeAddress || {});

  function handleAddressChange(fieldName, updateValue) {
    const payload = {
      officeAddress: {
        address: officeAddress,
        zip: officeZip,
        state: officeState,
        city: officeCity,
      },
    };
    payload.officeAddress[fieldName] = updateValue;
    setReduxProfileKeys(payload);
  }

  /* NOTE(santeyio): I don't think there's a need to clear the office address
   * if unselected... if 'in person at office' gets unselected then the office
   * address won't be used anyways. And if they re-select it we should populate
   * whatever they had populated before back.
  useEffect(() => {
    // clear the office address fields if inPersonOffice is unselected
    if (inPersonOffice === false) {
      const payload = {
        officeAddress: {
          address: '',
          city: '',
          zip: '',
          state: '',
        },
      };
      setReduxProfileKeys(payload);
    }
  }, [inPersonOffice]);
  */

  return inPersonOffice ? (
    <div>
      <div className="row">
        <div className="col">
          <label
            className={`form-label ${disabled && 'text-muted'}`}
            htmlFor="office-address"
          >
            Office Address
          </label>
          <input
            disabled={disabled}
            name="office-address"
            className={`form-control ${disabled && 'text-muted'}`}
            value={officeAddress}
            onChange={(e) => handleAddressChange('address', e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label
            className={`form-label ${disabled && 'text-muted'}`}
            htmlFor="office-zip"
          >
            Office Zipcode
          </label>
          <input
            disabled={disabled}
            name="office-zipcode"
            className={`form-control ${disabled && 'text-muted'}`}
            value={officeZip}
            onChange={(e) => handleAddressChange('zip', e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <label
            className={`form-label ${disabled && 'text-muted'}`}
            htmlFor="office-zip"
          >
            Office City
          </label>
          <input
            disabled={disabled}
            name="office-city"
            className={`form-control ${disabled && 'text-muted'}`}
            value={officeCity}
            onChange={(e) => handleAddressChange('city', e.target.value)}
          />
        </div>
        <div className="col">
          <label
            className={`form-label ${disabled && 'text-muted'}`}
            htmlFor="office-state"
          >
            Office State
          </label>
          <select
            disabled={disabled}
            name="office-state"
            className={`form-select ${disabled && 'text-muted'}`}
            value={officeState || ''}
            onChange={(e) => handleAddressChange('state', e.target.value)}
          >
            <option value="" disabled>Select</option>
            {STATES.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  ) : null;
}

function AppointmentTypes({
  disabled,
  setReduxProfileKeys,
  reduxProfile,
}) {
  const { in_person_office: inPersonOffice } = (reduxProfile.meetingTypes || {});
  const appointmentProfileMeetingTypeOptions = getMeetingTypeOptions();

  return (
    <div className="row">
      <h3 className={disabled ? 'text-muted' : ''}>Types of appointments I will accept</h3>
      <hr />
      <div className="col">
        {appointmentProfileMeetingTypeOptions.map(({ value, label }) => (
          <Checkbox
            key={value}
            meetingType={value}
            label={label}
            disabled={disabled}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
          />
        ))}
      </div>
      <div
        className="col"
        style={{ borderLeft: 'solid 1px lightgrey' }}
      >
        <OfficeAddress
          inPersonOffice={inPersonOffice}
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          reduxProfile={reduxProfile}
        />
      </div>
    </div>
  );
}

export default AppointmentTypes;
