import React from 'react';
import { useDispatch } from 'react-redux';

// Utilities
import { formatBenefits } from '../../../../utils/formatFunctions';

function MoreDetailsLink({ benefit = {} }) {
  const dispatch = useDispatch();

  const detailsExist = (benefit.benefitNotes || []).filter(e => e).length;

  const benefitDetails = {
    benefitName: benefit.benefitName,
    benefitNotes: (benefit.benefitNotes || []),
  };

  function handleClick() {
    dispatch({ type: 'SHOW_MODAL', payload: 'benefitDetailsSlideout' });
    dispatch({ type: 'SET_PLAN_KEY', payload: { benefitDetails } });
  }

  return detailsExist ? (
    <>
      <span className="ms-2">-</span>
      <button
        type="button"
        className="btn btn-link ps-0 ms-2 text-blue"
        onClick={() => handleClick()}
      >
        More details
      </button>
    </>
  ) : null;
}

class Benefits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { benefitList: [] };
  }

  componentDidMount() {
    const { plan } = this.props;
    this.setState({
      benefitList: formatBenefits(plan),
    });
  }

  render() {
    const { benefitList } = this.state;
    const { style } = this.props;
    return (
      <div className="plandetails-table" style={style}>
        {benefitList.map((benefit) => {
          const rowStyle = { display: 'inline-table' };
          return (
            <div
              className="plandetails-table-row plandetails-table-row-sm"
              style={rowStyle}
              key={`${benefit.benefitId}-${benefit.benefitName}`}
            >
              <div
                className="plandetails-table-detail-name d-flex align-items-baseline"
                title={benefit.benefitName}
              >
                <span style={{ textTransform: 'capitalize' }}>
                  {benefit.benefitName}
                </span>
                <MoreDetailsLink benefit={benefit} />
              </div>
              <div
                className="plandetails-table-detail-value"
                title={benefit.hover}
              >
                {benefit.component.map((c) => (
                  <div
                    className="d-flex border-nbgrey"
                    key={`${benefit.benefitName}-${c.id}`}
                  >
                    {c.comp}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Benefits;
