import React from 'react';
import { connect } from 'react-redux';
import NavPage from '../nav/NavPage';

class HelpPage extends React.PureComponent {
  render() {
    const { helpLinks } = this.props;

    return (
      <NavPage
        activeLink="/help"
      >
        <div className="mmb-header">
          Help
        </div>
        <div className="mmb-content">
          {
            helpLinks && helpLinks.length > 0
              ? (
                <div className="d-flex flex-column">
                  {
                    helpLinks.map((hl) => {
                      const key = hl.text;
                      return (
                        <div
                          className="help-button"
                          onClick={() => {
                            window.open(hl.url);
                          }}
                          key={key}
                          role="button"
                          tabIndex={0}
                        >
                          <span className="fw-bold">{hl.text}</span>
                          <span className="text-blue">{hl.url}</span>
                        </div>
                      );
                    })
                  }
                </div>
              )
              : (
                <div className="font-italic">
                  {"Looks like there isn't anything here yet."}
                </div>
              )
          }
        </div>
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    helpLinks: store.theme.helpLinks,
  };
}

export default connect(mapStateToProps)(HelpPage);
