import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
// import { createBrowserHistory } from 'history';
// eslint-disable-next-line import/no-cycle
import createRootReducer from './reducers';
// eslint-disable-next-line import/no-cycle
import sessionMiddleware from './middleware/session';

// export const history = createBrowserHistory();

const initialState = {};
const middleware = [thunk, sessionMiddleware];
const enhancers = [];

/* eslint-disable no-mixed-operators */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

export const store = createStore(
  persistReducer({ key: 'agent', storage }, createRootReducer()),
  initialState,
  composeEnhancers(applyMiddleware(...middleware), ...enhancers),
);

export const persistor = persistStore(store);
