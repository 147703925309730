/* eslint-disable no-param-reassign */
import axios from 'axios';

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.REACT_APP_MMB_API_URL}/api`,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('SessionToken');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    config.params = {
      ...config.params,
      site: process.env.REACT_APP_HOSTNAME || window.location.hostname,
    };
    return config;
  },
  (error) => Promise.reject(error)
  ,
);

service.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error),
);

export default service;
