import React from 'react';
import { connect } from 'react-redux';

// Actions + Utils
import { fmtDocSpecialties } from '../../../utils/formatFunctions';
import tooltipsBook from '../tooltipsbook';

// Components
import SelectButton from './SelectButton';
import MarkImportant from '../MarkImportant';
import CareHighlightsIcons from '../CareHighlightsIcons';
import Tiers from './Tiers';
import QuestionToolTip from '../../common/QuestionToolTip';

class Doctor extends React.PureComponent {
  render() {
    const {
      doctor,
      selected,
      careHighlights,
    } = this.props;

    const {
      ceTitle,
      ceDesc,
      cqTitle,
      cqDesc,
    } = tooltipsBook.careHighlights;

    return (
      <div className="doctor-card">
        <div className="row ps-3 pe-2 py-1">
          <div className="col-5">
            <p className="fw-bold mb-0">{doctor.name}</p>
            <Tiers doctor={doctor} />
            <br />
            <em>{fmtDocSpecialties(doctor.specialties)}</em>
            <p className="mb-0">
              {doctor.address2 ? `(${doctor.address2}) ` : ''}{doctor.address}, {doctor.city}, {doctor.state} {doctor.zipcode}
            </p>
          </div>
          <div className="d-flex col-4 align-items-end justify-content-end">
            <div style={{ display: careHighlights ? '' : 'none' }}>
              <span className="me-1">
                <QuestionToolTip title={cqTitle} content={cqDesc} />
              </span>
              <span className="me-1">Clinical quality:</span>
              <CareHighlightsIcons text={doctor.effectiveness} />
              <br />
              <span className="me-1">
                <QuestionToolTip title={ceTitle} content={ceDesc} />
              </span>
              <span className="me-2">Cost efficiency:</span>
              <CareHighlightsIcons text={doctor.efficiency} type="award" />
            </div>
          </div>
          <div className="col-3 h-100 m-auto">
            <div className="row justify-content-end me-2 mb-4">
              <MarkImportant doctor={doctor} selected={selected} />
            </div>
            <div className="row justify-content-end me-2">
              <span>
                <SelectButton doctor={doctor} selected={selected} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Doctor.defaultProps = {
  doctor: {},
};

function mapStateToProps(store) {
  return {
    doctorAddModal: store.display.doctorAddModal,
    doctorModalState: store.display.doctorModalState,
    careHighlights: store.theme.features.careHighlights,
  };
}

export default connect(mapStateToProps)(Doctor);
