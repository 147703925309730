import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';

// Actions
import { loadShoppers } from '../../actions/shopper';

// Components
import ShopperListContainer from './ShopperListContainer';
import NavPage from '../nav/NavPage';

// Constants
import { DEFAULT_STATE } from '../../constants';

class ConfirmEnrollments extends React.PureComponent {
  componentDidMount() {
    const {
      agentEmail,
      dispatch,
    } = this.props;

    loadShoppers({
      user_who_created: agentEmail,
      new_load: true,
      show_all_quotes: true,
    });

    dispatch({ type: 'MY_SHOPPERS_DISPLAYED', payload: true });
    dispatch({ type: 'SET_CONFIRM_ENROLLMENTS', payload: true });
    dispatch({ type: 'TOGGLE_ALL_SHOPPER_EXPANDED_QUOTES', payload: true });
    dispatch({ type: 'CLEAR_SHOPPER' });
    dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: DEFAULT_STATE });
    dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: DEFAULT_STATE });
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_CONFIRM_ENROLLMENTS', payload: false });
    dispatch({ type: 'TOGGLE_EDIT_ALL_CONFIRMATION_NUMBERS', payload: false });
  }

  render() {
    const {
      dispatch,
      editAllConfirmationNumbers,
      translations,
      setLanguage,
      showAllQuotes,
    } = this.props;

    const dashboardTranslations = translations[setLanguage]['Dashboard and Confirm Enrollment Page'] || {};
    const mainSectionTranslations = dashboardTranslations['Main Section'] || {};

    return (
      <NavPage activeLink="/confirm" showAgentSidebar>
        <div id="dashboard-enrollment-loaded" />
        <div className="d-flex justify-content-between mx-2 mt-4 mb-0">
          <h2>Confirm Enrollments</h2>
          <span>
            <button
              className="btn btn-secondary me-1"
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_EDIT_ALL_CONFIRMATION_NUMBERS',
                  payload: !editAllConfirmationNumbers,
                });
              }}
              type="button"
            >
              {editAllConfirmationNumbers
                ? mainSectionTranslations['Exit Editing ACNs Button Text']
                : mainSectionTranslations['Edit All ACNs Button Text']}
            </button>
            <button
              id={showAllQuotes ? 'hide-all-quotes-button' : 'expand-all-quotes-button'}
              className="btn btn-secondary"
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_ALL_SHOPPER_EXPANDED_QUOTES',
                  payload: !(showAllQuotes),
                });
              }}
              type="button"
            >
              {showAllQuotes ? 'Hide All Quotes' : 'Expand All Quotes'}
            </button>
            <Link
              to="/shopper"
              className="btn btn-outline-primary ms-3"
              onClick={() => dispatch({ type: 'CLEAR_SHOPPER' })}
            >
              <span className="align-middle me-2">
                {mainSectionTranslations['Add Client Button Text']}
              </span>
              <span className="align-middle">
                <FontAwesomeIcon icon={faPlusCircle} />
              </span>
            </Link>
          </span>
        </div>
        <ShopperListContainer activeLink="/confirm" />
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    showAllQuotes: store.shopperlist.showAllQuotes,
    editAllConfirmationNumbers: store.display.editAllConfirmationNumbers,
    agentEmail: store.agent.email,
    translations: store.translations.sets,
    setLanguage: store.translations.setLanguage,
  };
}

export default connect(mapStateToProps)(ConfirmEnrollments);
