import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

// Components
import LoaderButton from '../../common/LoaderButton';

class Verification extends React.PureComponent {
  render() {
    const {
      dispatch,
      errmsg,
      fingerprint,
      handleLogin,
      helpmsg,
      loginLoading,
      verification,
      verificationLoading,
    } = this.props;

    return (
      <>
        <div
          className="login-verification-text"
        >
          {"We don't recognize this device. A verification code has been emailed to you. Please enter it below to verify ownership of this account."}
        </div>
        <input
          className="login-verification-input-code form-control border border-light-grey"
          id="login-verification-input"
          name="verification"
          onChange={(e) => {
            dispatch({ type: 'SET_LOGIN_VERIFICATION', payload: e.target.value });
          }}
          onKeyPress={(target) => {
            if (target.charCode === 13) {
              handleLogin(false);
            }
          }}
          placeholder="e.g. 111222"
          type="text"
          value={verification}
        />
        <span
          className={`login-input-error text-danger ${errmsg ? 'visible' : ''}`}
        >
          {errmsg}
        </span>
        <span
          className={`login-input-error text-success ${helpmsg ? 'visible' : ''}`}
        >
          {helpmsg}
        </span>
        <div className="login-verification-group d-flex ">
          <LoaderButton
            className="login-verification-submit-button"
            color="primary"
            disabled={fingerprint === null}
            id="login-verification-submit-button"
            loading={loginLoading}
            loadingColor="primary"
            loadingText="Continue"
            onClick={() => handleLogin(false)}
            text="Continue"
          />
          <LoaderButton
            className="login-verification-resend-button"
            color="outline-secondary"
            disabled={fingerprint === null}
            id="login-verification-resend-button"
            loading={verificationLoading}
            loadingColor="outline-secondary"
            loadingText="Resend"
            onClick={() => handleLogin(true)}
            text="Resend"
          />
        </div>
        <div
          className="login-verification-back-button"
          id="login-verification-back-button"
          onClick={() => {
            dispatch({ type: 'SET_LOGIN_VERIFICATION_DISPLAY', payload: false });
            dispatch({ type: 'SET_LOGIN_VERIFICATION', payload: '' });
            dispatch({ type: 'SET_LOGIN_HELP', payload: '' });
            dispatch({ type: 'SET_LOGIN_ERR', payload: '' });
          }}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            className="login-verification-back-button-caret"
            icon={faAngleLeft}
          />
          <div className="login-verification-back-button-text">
            Back to login
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(store) {
  return {
    errmsg: store.login.errmsg,
    fingerprint: store.login.fingerprint,
    helpmsg: store.login.helpmsg,
    loginLoading: store.login.loginLoading,
    verification: store.login.verification,
    verificationLoading: store.login.verificationLoading,
  };
}
export default connect(mapStateToProps)(Verification);
