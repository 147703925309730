const editKeys = [8, 35, 36, 37, 39, 46, 9];
// backspace, arrow(<, >), del, home, end, tab

export const handleOnlyNumberZip = (event) => {
  const zip = event.target.value;
  const charCode = event.charCode || event.which || event.keyCode;
  // only number keyboard
  if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
    // prevent when length==5 and no text selection
    const selectionStart = event.target.selectionStart || 0;
    const selectionEnd = event.target.selectionEnd || 0;
    if (zip.length > 4 && selectionStart === selectionEnd) {
      event.preventDefault();
    }
    return true;
  } if (editKeys.includes(charCode)) {
    return true;
  }
  event.preventDefault();
  return false;
};

export const handleOnlyNumber = (event) => {
  const charCode = event.charCode || event.which || event.keyCode;
  // only number keyboard
  if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106) || editKeys.includes(charCode)) {
    return true;
  }
  event.preventDefault();
  return false;
};

export const scrollToTop = (selector, offset = 0) => {
  const el = document.querySelector(selector);
  if (el) {
    let { offsetTop } = el;
    if (offsetTop > 100) offsetTop -= offset;
    try {
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    } catch (error) {
      document.body.scrollTop = offsetTop;
      document.documentElement.scrollTop = offsetTop;
    }
  }
};
