/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SUBSIDY_PERCENTAGE_OPTIONS_DEFAULT } from '../../../../constants';

// components
import Select from './Select';

function SubsidyPercent({ disabled }) {
  const dispatch = useDispatch();
  const { subsidyPercent } = useSelector(store => store.shopper);
  const { subsidyPercentageOptions: options = SUBSIDY_PERCENTAGE_OPTIONS_DEFAULT } = useSelector(store => store.theme);

  function handleChange(e) {
    const { value } = e.target;
    const data = {
      subsidyPercent: value,
      subsidyPercentageUnknown: (value === '101'),
    };
    dispatch({ type: 'UPDATE_SHOPPER', payload: data });
  }

  return (
    <Select
      name="subsidyPercent"
      label="What percentage does client pay for their monthly prescription drug plan premium?"
      handleChange={handleChange}
      options={options}
      cols="6"
      value={subsidyPercent}
      disabled={disabled}
    />
  );
}

export default SubsidyPercent;
