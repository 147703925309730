const initialState = {
  id: '',
  name: '',
  payload: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_EVENT':
      return { id: +new Date(), name: action.payload.name, payload: action.payload.payload };
    case 'CLEAR_EVENT':
      return { id: '', name: '', payload: {} };
    default:
      return state;
  }
}

