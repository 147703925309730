import React from 'react';
import { connect } from 'react-redux';

// Constants
import {
  AWAIT_STATE,
  DEFAULT_STATE,
  SUCCESS_STATE,
  FAIL_STATE,
} from '../../../../constants';

// Actions
import { doctorSearch } from '../../../../actions/doctor';

class NameSearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_DOCTORS_LOADED_STATE', payload: DEFAULT_STATE });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch(event);
    }
  };

  handleInputChange = (event) => {
    this.setState({
      searchString: event.target.value,
    });
  };

  handleSearch = (event) => {
    const {
      dispatch,
      zip,
      radius,
      specialty,
    } = this.props;
    const { searchString } = this.state;
    dispatch({ type: 'SET_DOCTORS_ERROR_MESSAGE', payload: '' });
    event.preventDefault();
    if (searchString === '') {
      dispatch({ type: 'SET_DOCTORS_ERROR_MESSAGE', payload: 'Please enter a name.' });
      dispatch({ type: 'SET_DOCTORS_LOADED_STATE', payload: FAIL_STATE });
    } else {
      dispatch({ type: 'SET_DOCTORS_LOADED_STATE', payload: AWAIT_STATE });
      doctorSearch(zip, searchString, radius, specialty)
        .then(() => dispatch({ type: 'SET_DOCTORS_LOADED_STATE', payload: SUCCESS_STATE }))
        .catch(() => dispatch({ type: 'SET_DOCTORS_LOADED_STATE', payload: FAIL_STATE }));
    }
  };

  render() {
    return (
      <>
        <div>
          <input
            id="provider-search-input"
            className="form-control"
            placeholder="e.g. John Smith"
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            value={this.state.searchString}
          />
        </div>
        <div>
          <button
            type="button"
            id="provider-search-search"
            className="btn btn-primary float-right ms-3 fw-bold"
            onClick={this.handleSearch}
          >
            Search
          </button>
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    zip: store.doctor.selectedZip,
    specialty: store.doctor.selectedSpecialty || null,
    radius: store.doctor.selectedRadius || null,
  };
}

export default connect(mapStateToProps)(NameSearch);
