const initialState = {
  soaPopover: false,
  showDrugPopover: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_POPOVER':
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    case 'HIDE_POPOVER':
      return {
        ...state,
        [action.payload]: false,
      };
    case 'SHOW_POPOVER':
      return {
        ...state,
        [action.payload]: true,
      };
    default:
      return state;
  }
}
