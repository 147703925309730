import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Actions
import { sendSoa, submitExternalSoa } from '../../../actions/soa';
import { loadConfig } from '../../../actions/config';

// Compoments
import LoaderButton from '../../common/LoaderButton';
import FormRadio from '../../common/FormRadio';
import EmailPreview from './EmailPreview';
import SMSPreview from './SMSPreview';

// Utilities
import { capitalize, formatPhoneToNum } from '../../../utils/general';
import { showPopup } from '../../../utils/popup';
import { getTPMODisclaimerText } from '../../../common/utils/tpmo';

import SendEmail from './Email';
import SendSMS from './SMS';
import External from './External';
import VoiceSOA, { isFieldValid } from './VoiceSOA';

function SendTab() {
  const dispatch = useDispatch();
  const {
    carrierPermissions,
    email: agentEmail,
    firstName: agentFirstName,
    lastName: agentLastName,
    agency: {
      name: agency,
    },
  } = useSelector((store) => store.agent);
  const agentName = `${agentFirstName}${agentLastName ? ` ${agentLastName}` : ''}`;
  const {
    email,
    firstName,
    lastName,
    mobilePhone,
    homePhone,
    username,
    effectiveDate,
    county: countyFips,
  } = useSelector((store) => store.shopper);
  const {
    status: callStatus,
    softphoneOpen,
    meetType,
  } = useSelector(store => store.telephony);
  const {
    features: {
      telephony: telephonyFeature,
      voiceSOADebug,
    },
  } = useSelector(store => store.theme);
  const { values = {}, errors = {} } = useSelector(store => store.soa);

  const [loading, setLoading] = useState(false);
  const [emailTextBlock1, setEmailTextBlock1] = useState('');
  const [emailTextBlock2, setEmailTextBlock2] = useState('');
  const [tpmoDisclaimerText, setTpmoDisclaimerText] = useState('');

  const [smsTextBlock1, setSmsTextBlock1] = useState('');
  const [smsTextBlock2, setSmsTextBlock2] = useState('');

  useEffect(() => {
    loadConfig('pinpoint_soa_email_text_json')
      .then(({ data }) => {
        setEmailTextBlock1(data.block_1);
        setEmailTextBlock2(data.block_2);
      });

    loadConfig('pinpoint_soa_sms_text_json')
      .then(({ data }) => {
        setSmsTextBlock1(data.block_1);
        setSmsTextBlock2(data.block_2);
      });

    const planYear = moment(effectiveDate).year();
    getTPMODisclaimerText({ planYear, carrierPermissions, countyFips })
      .then(text => setTpmoDisclaimerText(text));

    dispatch({ type: 'CLEAR_SOA_ERRORS' });
    dispatch({
      type: 'SET_SOA_VALUES',
      payload: {
        method: 'email', // email | sms | voice | external
        send_email: email,
        send_greeting: `${capitalize(firstName)} ${capitalize(lastName)},`,
        send_closing: 'Thank You,',
        send_signature: agentName ? `${capitalize(agentName)}` : '',
        send_sms_phone: mobilePhone || homePhone,
        send_sms_greeting: `Hi ${capitalize(firstName)}, this is ${capitalize(agentName)} from ${agency}.`,
        date_of_appointment: '',
        soaType: '',
        soaDate: '',
        mapdMa: false,
        pdp: false,
        dvh: false,
        supHealth: false,
        medigap: false,
        otherDescription: '',
        checkbox: '',
      },
    });
  }, []);

  function isValid(field, value) {
    if (!value) {
      dispatch({ type: 'SET_SOA_ERROR', field, payload: 'This field is required' });
      return false;
    }
    if (field === 'send_email'
      && !isFieldValid({
        // eslint-disable-next-line no-useless-escape
        validationRegex: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        value,
      })
    ) {
      dispatch({ type: 'SET_SOA_ERROR', field, payload: 'Please enter a valid email address' });
      return false;
    }
    if (field === 'send_sms_phone'
      && !isFieldValid({ validationRegex: '^[0-9]{10}$', value })
    ) {
      dispatch({ type: 'SET_SOA_ERROR', field, payload: 'Please enter a valid phone number' });
      return false;
    }
    if (field === 'soaDate' && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      dispatch({ type: 'SET_SOA_ERROR', field, payload: 'Date must not be in the future' });
      return false;
    }
    dispatch({ type: 'SET_SOA_ERROR', field, payload: '' });
    return true;
  }

  function isCheckboxValid(field, value) {
    const checkboxFields = ['mapdMa', 'pdp', 'dvh', 'supHealth', 'medigap'];
    // NOTE(jentgelmeier): this checks to see if all checkboxes are off. The updated value
    // has to be excluded because it still registers as true during this check if it was just turned off
    if (value === false
      && checkboxFields
        .filter(cField => cField !== field)
        .every(cField => values[cField] === false)
    ) {
      dispatch({ type: 'SET_SOA_ERROR', field: 'checkbox', payload: 'Please select at least one item.' });
      return false;
    }
    dispatch({ type: 'SET_SOA_ERROR', field: 'checkbox', payload: '' });
    return true;
  }

  function handleChange({
    event,
    field = event.target.name,
    payload = event.target.value,
    validate = false,
    checkbox = false,
  }) {
    dispatch({ type: 'SET_SOA_VALUE', field, payload });
    if (validate) {
      if (checkbox) {
        isCheckboxValid(field, payload);
      } else {
        isValid(field, payload);
      }
    }
  }

  function validToSubmit() {
    if (values.method === 'email') {
      const emailValid = isValid('send_email', values.send_email);
      const doaValid = isValid('date_of_appointment', values.date_of_appointment);
      const emailGreetValid = isValid('send_greeting', values.send_greeting);
      const closingValid = isValid('send_closing', values.send_closing);
      const signatureValid = isValid('send_signature', values.send_signature);
      if (!emailValid || !emailGreetValid || !closingValid || !signatureValid || !doaValid) return false;
    } else if (values.method === 'sms') {
      const phoneValid = isValid('send_sms_phone', values.send_sms_phone);
      const doaValid = isValid('date_of_appointment', values.date_of_appointment);
      const smsGreetValid = isValid('send_sms_greeting', values.send_sms_greeting);
      if (!phoneValid || !smsGreetValid || !doaValid) return false;
    } else if (values.method === 'external') {
      const typeValid = isValid('soaType', values.soaType);
      const doaValid = isValid('date_of_appointment', values.date_of_appointment);
      const dateValid = isValid('soaDate', values.soaDate);
      const checkboxValid = isCheckboxValid('mapdMa', values.mapdMa);
      if (values.soaType === 'other') {
        const otherValid = isValid('otherDescription', values.otherDescription);
        if (!otherValid) return false;
      }
      if (!typeValid || !dateValid || !checkboxValid || !doaValid) return false;
    }
    return true;
  }

  const handleSubmit = () => {
    const valid = validToSubmit();
    if (!valid) return;
    setLoading(true);
    dispatch({ type: 'SET_ROOT_LOADER', payload: true });
    if (values.method === 'external') {
      submitExternalSoa({
        shopper_username: username,
        other: values.soaType === 'other' ? values.otherDescription : values.soaType,
        pdp: values.pdp,
        mapd_ma: values.mapdMa,
        dvh: values.dvh,
        sup_health: values.supHealth,
        medigap: values.medigap,
        submit_date: values.soaDate,
        date_of_appointment: values.date_of_appointment,
      })
        .then(() => {
          dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'history' });
          showPopup('Success', 'External SOA status saved!');
        })
        .catch(() => {
          showPopup('Error', 'There was a problem saving the SOA.');
        }).finally(() => {
          setLoading(false);
          dispatch({ type: 'SET_ROOT_LOADER', payload: false });
        });
    } else {
      sendSoa({
        shopper_username: username,
        closing: values.send_closing,
        email: (values.method === 'email') ? values.send_email : undefined,
        greeting: (values.method === 'email') ? values.send_greeting : values.send_sms_greeting,
        signature: (values.method === 'email') ? values.send_signature : undefined,
        phone: (values.method === 'sms') ? formatPhoneToNum(values.send_sms_phone) : undefined,
        tpmo_disclaimer: (values.method === 'email') ? tpmoDisclaimerText : undefined,
        date_of_appointment: values.date_of_appointment,
      }, values.method)
        .then(() => {
          dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'history' });
          showPopup('Success', 'SOA successfully sent!');
        })
        .catch(() => {
          showPopup('Error', 'There was a problem sending the SOA.');
        }).finally(() => {
          setLoading(false);
          dispatch({ type: 'SET_ROOT_LOADER', payload: false });
        });
    }
  };

  // disable the voice soa unless the agent is on an active call or chose 3-way call
  const onActiveCall = (softphoneOpen && callStatus === 'connected');
  const onThreeWay = meetType === 'telephonicThreeWay';
  const voiceLabel = onActiveCall || onThreeWay ? (
    'Voice'
  ) : (
    <i>Voice (must be on an active call)</i>
  );

  return (
    <div className="d-flex" style={{ gap: '40px' }}>
      {/* Left section */}
      <div style={{ width: '320px' }}>
        <div className="">
          <FormRadio
            controlId="method_email"
            label="Email"
            name="soa"
            onChange={(event) => handleChange({ event, field: 'method', payload: 'email' })}
            checked={values.method === 'email'}
          />
          <FormRadio
            controlId="method_sms"
            label="Text (SMS)"
            name="soa"
            onChange={(event) => handleChange({ event, field: 'method', payload: 'sms' })}
            checked={values.method === 'sms'}
          />
          {telephonyFeature && (
            <FormRadio
              controlId="voice"
              label={voiceLabel}
              name="soa"
              onChange={(event) => handleChange({ event, field: 'method', payload: 'voice' })}
              checked={values.method === 'voice'}
              disabled={!onActiveCall && !onThreeWay && !voiceSOADebug}
            />
          )}
          <FormRadio
            controlId="method_external"
            label="I have a completed SOA via other method"
            name="soa"
            onChange={(event) => handleChange({ event, field: 'method', payload: 'external' })}
            checked={values.method === 'external'}
          />
        </div>

        <div>
          {values.method === 'email' && (
          <SendEmail
            handleChange={(event) => handleChange({ event, validate: true })}
            errors={errors}
            values={values}
            dateOfAppointment
          />
          )}

          {values.method === 'sms' && (
          <SendSMS
            handleChange={(event) => handleChange({ event, validate: true })}
            errors={errors}
            values={values}
            dateOfAppointment
          />
          )}

          {values.method === 'external' && (
          <External
            handleChange={(event) => handleChange({ event, validate: true })}
            handleTodayClick={(event) => handleChange({
              event,
              validate: true,
              field: 'soaDate',
              payload: moment().format('YYYY-MM-DD'),
            })}
            handleCheckbox={(event) => handleChange({
              event,
              validate: true,
              checkbox: true,
              field: event.target.name,
              payload: event.target.checked,
            })}
            errors={errors}
            values={values}
          />
          )}
        </div>

        {values.method !== 'voice' && (
        <LoaderButton
          className="fw-bold mt-3"
          style={{ maxWidth: '120px' }}
          loading={loading}
          loadingText={values.method === 'external' ? 'Saving..' : 'Sending..'}
          onClick={() => handleSubmit()}
          text={values.method === 'external' ? 'Save' : 'Send SOA'}
        />
        )}
      </div>
      {/* Right section */}
      <div style={{ flex: 1 }}>
        {values.method === 'email' && (
        <EmailPreview
          greeting={values.send_greeting}
          closing={values.send_closing}
          signature={values.send_signature}
          email={agentEmail}
          textBlock1={emailTextBlock1}
          textBlock2={emailTextBlock2}
          tpmoDisclaimerText={tpmoDisclaimerText}
        />
        )}

        {values.method === 'sms' && (
          <SMSPreview
            greeting={values.send_sms_greeting}
            textBlock1={smsTextBlock1}
            textBlock2={smsTextBlock2}
          />
        )}

        {values.method === 'voice' && (
          <VoiceSOA />
        )}
      </div>

    </div>
  );
}

export default SendTab;
