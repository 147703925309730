const initialState = {
  activePage: 1,
  dashboardTabs: [],
  itemsCountPerPage: 15,
  shopperPage: [], // Single page of clients being displayed.
  showAllQuotes: false,
  totalItemsCount: 50,

  // Dashboard Search functionality
  sortInverse: false,
  sortTerm: null,
  nameFilter: '',
  phoneFilter: '',
  statusFilter: '', // Disposition
  stateFilter: '',
  // Shopper Types
  selectedTab: 'All leads',
  categories: [
    {
      name: 'All leads',
      count: 0,
    },
    {
      name: 'New leads',
      count: 0,
    },
    {
      name: 'Follow up',
      count: 0,
    },
    {
      name: 'Sales won',
      count: 0,
    },
    {
      name: 'Archived',
      count: 0,
    },
  ],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SORT_TERM':
      return { ...state, sortTerm: action.payload };
    case 'CLEAR_SORT_TERM':
      return { ...state, sortTerm: null };
    case 'SET_STATUS_FILTER':
      return { ...state, statusFilter: action.payload };
    case 'CLEAR_STATUS_FILTER':
      return { ...state, statusFilter: '' };
    case 'SET_SORT_INVERSE':
      return { ...state, sortInverse: action.payload };
    case 'CLEAR_SORT_INVERSE':
      return { ...state, sortInverse: false };
    case 'LOAD_SHOPPER_LIST': {
      return {
        ...state,
        shopperPage: action.payload.users || [],
        totalItemsCount: action.payload.totalUsers,
        itemsCountPerPage: action.payload.maxPageSize,
        activePage: action.payload.page,
      };
    }
    case 'UPDATE_SHOPPER_LIST_ONE_BY_UERNAME': {
      const shopperPage = state.shopperPage.map((el) => {
        if (el.username === action.username) return action.payload;
        return el;
      });
      return {
        ...state,
        shopperPage,
      };
    }
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: action.payload,
      };
    case 'UPDATE_SHOPPER_LIST_FILTER': {
      /* Payload shape: { filterName: 'statusFilter', filterValue: '10,...' } */
      return {
        ...state,
        [action.payload.filterName]: action.payload.filterValue,
      };
    }
    case 'CLEAR_SEARCH_TERMS':
      return {
        ...state,
        nameFilter: '',
        phoneFilter: '',
        stateFilter: '',
        statusFilter: '',
      };
    case 'SORT_SHOPPER_LIST':
      return {
        ...state,
        shopperPage: state.shopperPage.slice().sort((a, b) => {
          if (action.payload === 'quotes') {
            if (state.sortInverse === false) {
              return (a[action.payload].length > b[action.payload].length) ? 1 : -1;
            }
            return (a[action.payload].length < b[action.payload].length) ? 1 : -1;
          }
          if (state.sortInverse === false) {
            return (a[action.payload].toUpperCase() > b[action.payload].toUpperCase()) ? 1 : -1;
          }
          return (a[action.payload].toUpperCase() < b[action.payload].toUpperCase()) ? 1 : -1;
        }),
      };
    case 'LOAD_SHOPPER_LIST_ERROR':
      return {
        ...state,
        shopperPage: 'error',
      };
    case 'UPDATE_QUOTE_CONFIRMATION_NUMBER': {
      const shopperPage = [...state.shopperPage];
      const { username, quote } = action.payload;

      const shopper = { ...shopperPage.find((_shopper) => _shopper.username === username) };
      const shopperIndex = shopperPage.findIndex((_shopper) => _shopper.username === username);

      const newQuote = { ...shopper.quotes.find((_quote) => _quote.quoteID === quote.quoteID) };
      const newQuoteIndex = shopper.quotes.findIndex((_quote) => _quote.quoteID === quote.quoteID);

      newQuote.confirmationNumber = quote.confirmationNumber;
      shopper.quotes.splice(newQuoteIndex, 1, newQuote);
      shopperPage.splice(shopperIndex, 1, shopper);

      return { ...state, shopperPage };
    }
    case 'CLEAR_SHOPPER_LIST':
      return {
        ...state,
        shopperPage: [],
      };
    case 'SET_SHOPPER_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
}
