import React from 'react';
import { connect } from 'react-redux';

class SMSPreview extends React.PureComponent {
  render() {
    const {
      activationCode,
      greeting,
      textBlock1,
      textBlock2,
    } = this.props;

    return (
      <p style={{ maxWidth: '300px', wordBreak: 'break-word' }}>
        <span className="greeting-color">{greeting}</span>
        <span> {textBlock1}</span>
        <span className="text-blue font-monospace fs-6"> {'<link>'}</span>
        <br />
        <br />
        <span> {textBlock2}</span>
        {
          activationCode
            ? (
              <span className="font-monospace fs-6"> {'<code>'}</span>
            )
            : null
        }
      </p>
    );
  }
}

SMSPreview.defaultProps = {
  textBlock1: 'This is site config / pinpoint soa sms text 1.',
  textBlock2: 'This is site config / pinpoint soa sms text 2.',
};

export default connect()(SMSPreview);
