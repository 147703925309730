import React from 'react';
import { connect } from 'react-redux';
import { AWAIT_STATE } from '../../../constants';

// Components
import Dosages from './Dosages';
import UserQuantity from './UserQuantity';
import Frequency from './Frequency';
import Packages from './Packages';

class DosageLayout extends React.PureComponent {
  render() {
    const { dosagesLoaded } = this.props;

    if (dosagesLoaded === AWAIT_STATE) {
      return (
        <>
          <div className="col-3">
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <div className="col-1">
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <div className="col-2">
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          <div className="col-3">
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-3 d-flex">
          <Dosages />
        </div>
        <div className="col-1 d-flex">
          <UserQuantity />
        </div>
        <div className="col-2 d-flex">
          <Frequency />
        </div>
        <div className="col-3 d-flex">
          <Packages />
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    dosagesLoaded: store.loaders.dosagesLoaded,
  };
}

export default connect(mapStateToProps)(DosageLayout);
