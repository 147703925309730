import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import '../styles/main.css';
// import '../styles/custom_theme.css';
import '../styles/theme.scss';

import * as Yup from 'yup';
import ErrorBoundary from './common/ErrorBoundary';
import Error403 from './common/Error403';
import { validateTheme } from '../actions/theme';
import { validateClientVersion, validateAgentLastUpdate } from '../actions/session';
import { validateTranslations } from '../actions/translations';
import { loadShopper } from '../actions/shopper';

import getGACode from '../utils/tracking';
import { getCookie } from '../utils/cookie';

// Components
import ColorTheme from './common/ColorTheme';
import ConfirmEnrollment from './dashboard/ConfirmEnrollment';
import Dashboard from './dashboard';
import QuotesRouter from './quotes';
import LoginPage from './landing/login';
import DialerLandingPage from './landing/dialer';
import SignupPage from './landing/signup';
import ResetPassword from './landing/reset';
import HelpPage from './help';
import RxRouter from './rx';
import DoctorsRouter from './doctors';
import MobileRouter from './mobile';
import PlanRouter from './plans';
import MPAPage from './mpa';
import ShopperPageRefactor from './shopper';
import HealthRiskAssesment from './hra';
import SOAPage from './soa';
import CallsPage from './calls';
import ThreeWay from './three-way';
import AppointmentsProfile from './appointments';
import Export from './export';
import Reports from './reports';

import ScrollToTop from './ScrollToTop';
import WithTracker from './common/WithTracker';

import PrivacyDisclosure from './common/PrivacyDisclosure';
import StaleClientModal from './common/StaleClientModal';
import ReleaseNotes from './common/ReleaseNotes';

import Features from './features';

// Modals
import AgentNotificationModal from './modal/notifications';
import StatePermissionModal from './modal/StatePermissionModal';
import ReleaseNotesModal from './modal/ReleaseNotesModal';
import SessionExpiredModal from './modal/SessionExpiredModal';
import ZipCodeWarningModal from './modal/ZipCodeWarningModal';
import EnrollConfirmModal from './modal/EnrollConfirmModal';
import SoftphoneModal from './modal/SoftphoneModal';
import BEQModal from './modal/BEQModal';
import ExternalHRAModal from './modal/ExternalHRAModal';
import CallDetailsModal from './modal/CallDetailsModal';
import BenefitDetailsSlideout from './modal/BenefitDetailsSlideout';
import BenefitsModal from './modal/BenefitsModal';
import AppointmentsProfileTutorial from './modal/AppointmentsProfileTutorial';

// Global validation message
Yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    email: 'Please enter a valid email address',
  },
});

function App() {
  const dispatch = useDispatch();
  const {
    shopper: { username: shopperUsername },
    telephony: {
      softphoneOpen: soRedux,
      softphoneOpenIntervalShouldRun,
    },
    theme: {
      enrollment: enrollmentUrl,
      favicon,
      htmlSiteTitle,
      htmlSiteFavicon,
    },
  } = useSelector(store => store);

  function establishValidationIntervals() {
    // Reset any timers on connection
    dispatch({ type: 'CLEAR_INTERVALS' });
    const intervalSeconds = 8;
    const intervalMs = intervalSeconds * 1000;

    // Validate the client version and update if needed.
    if (!window.location.pathname.includes('error_')) {
      validateClientVersion();
      const validateClientInterval = window.setInterval(validateClientVersion, intervalMs);
      dispatch({ type: 'ADD_INTERVAL', payload: validateClientInterval });
    }

    // Validate the theme version and update if needed.
    validateTheme();
    const validateThemeInterval = window.setInterval(validateTheme, intervalMs);
    dispatch({ type: 'ADD_INTERVAL', payload: validateThemeInterval });

    // Validate the translations and update if needed.
    validateTranslations();
    const validateTranslationsInterval = window.setInterval(validateTranslations, intervalMs);
    dispatch({ type: 'ADD_INTERVAL', payload: validateTranslationsInterval });

    // Update present Agent based on their last_update timestamp
    const validateAgentLastUpdateInterval = window.setInterval(validateAgentLastUpdate, intervalMs);
    dispatch({ type: 'ADD_INTERVAL', payload: validateAgentLastUpdateInterval });
  }

  useEffect(() => {
    getGACode()
      .then((code) => {
        ReactGA.initialize(code);
        ReactGA.pageview(window.location.pathname + window.location.search);
        /*
        history.listen((location) => {
          ReactGA.pageview(location.pathname + location.search);
        });
        */
      });

    // Close any modals left open on exit
    dispatch({ type: 'RESET_MODALS' });

    // Reset all loaders to default state
    dispatch({ type: 'RESET_LOADERS' });

    establishValidationIntervals();

    // Update favicon from Redux cache.
    document.getElementById('favicon').href = favicon;

    // This is to handle an edge case where the agent portal gets refreshed
    // while a softphone window is open. If this happens then we lose
    // the reference to the popup window and can no longer track when
    // it closes. This interval watches for a cookie shared across .mymedicarebot.com
    // domains `softphoneOpen` and updates redux to match the cookie
    // to handle this particular case. This cookie state is maintained
    // by the enrollment portal (softphone window).
    //
    // This interval only gets started if the agent portal is refreshed
    // while a softphone window is open.
    if (softphoneOpenIntervalShouldRun) {
      const i = setInterval(() => {
        const soCookie = getCookie('softphoneOpen');
        if (soRedux === true && soCookie === 'false') {
          dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpen', value: false });
          dispatch({ type: 'SET_TELEPHONY_FIELD', name: 'softphoneOpenIntervalShouldRun', value: false });
          clearInterval(i);
        }
      }, 1000);
    }

    // This is a listener for postMessage events from enrollment portal children
    // windows. Sometimes we make changes to a shopper object from the enrollment
    // portal (e.g. to add dob/phone). We don't want to inadvertantly erase
    // those updates by having an out of date shopper object in agent portal redux,
    // so this listener listens for events telling us that we need to reload
    // the shopper object in redux to update it.
    window.shopperUpdateListener = function shopperUpdateListener(event) {
      if (event.origin !== enrollmentUrl) return;
      const { data: { requestShopperToRefresh } } = event;
      if (requestShopperToRefresh && shopperUsername) {
        loadShopper(shopperUsername).then(data => dispatch({ type: 'SET_SHOPPER', payload: data }));
      }
    };
    window.addEventListener('message', window.shopperUpdateListener);

    return () => {
      dispatch({ type: 'CLEAR_INTERVALS' });
    };
  }, []);

  useEffect(() => {
    document.getElementById('favicon').href = favicon;
  }, [favicon]);

  if (htmlSiteTitle) {
    document.title = htmlSiteTitle;
  }
  if (htmlSiteFavicon) {
    const link = document.getElementById('favicon');
    link.href = htmlSiteFavicon;
  }

  return (
    <ErrorBoundary>
      <ColorTheme />
      <BrowserRouter>
        <ScrollToTop>
          <WithTracker>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/password-reset" element={<ResetPassword />} />
              <Route path="/enrollhere/:leadID" element={<DialerLandingPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/mobile/*" element={<MobileRouter />} />
              <Route path="/confirm" element={<ConfirmEnrollment />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/shopper/:username" element={<ShopperPageRefactor />} />
              <Route path="/shopper" element={<ShopperPageRefactor />} />
              <Route path="/doctors/*" element={<DoctorsRouter />} />
              <Route path="/rx/*" element={<RxRouter />} />
              <Route path="/soa" element={<SOAPage />} />
              <Route path="/plans/*" element={<PlanRouter />} />
              <Route path="/quotes/*" element={<QuotesRouter />} />
              <Route path="/hra/*" element={<HealthRiskAssesment />} />
              <Route path="/calls" element={<CallsPage />} />
              <Route path="/3-way-calls" element={<ThreeWay />} />
              <Route path="/mpa" element={<MPAPage />} />
              <Route path="/help" element={<HelpPage />} />
              <Route path="/releasenotes" element={<ReleaseNotes />} />
              <Route path="/privacy" element={<PrivacyDisclosure />} />
              <Route path="/error" element={<ErrorBoundary />} />
              <Route path="/error_403" element={<Error403 />} />
              <Route path="/features" element={<Features />} />
              <Route path="/appointments" element={<AppointmentsProfile />} />
              <Route path="/export" element={<Export />} />
              <Route path="/reports/*" element={<Reports />} />
            </Routes>
          </WithTracker>
        </ScrollToTop>

        <ReleaseNotesModal />
        <SessionExpiredModal />
        <StatePermissionModal />
        <ZipCodeWarningModal />
        <AgentNotificationModal />
        <StaleClientModal />
        <EnrollConfirmModal />
        <SoftphoneModal />
        <BEQModal />
        <ExternalHRAModal />
        <CallDetailsModal />
        <BenefitDetailsSlideout />
        <BenefitsModal />
        <AppointmentsProfileTutorial />
      </BrowserRouter>

      <ToastContainer
        position="top-center"
        autoClose={2800}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover={false}
      />
    </ErrorBoundary>
  );
}

export default App;
