/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

function InfoBox({ children }) {
  return (
    <div className="py-1 px-3 border border-warning bg-warning-bg shadow-sm mb-3">
      {children}
    </div>
  );
}

function ManualZipEntry({
  disabled,
  setReduxProfileKeys,
  reduxProfile,
  setManualZipInputValid,
  manualZipInputValid,
  meetingType,
}) {
  const [ displayValue, setDisplayValue ] = useState('');
  const {
    manualZipsByMeetingType: {
      [meetingType]: manualZips = [],
    } = {},
  } = reduxProfile;

  function handleChange(e) {
    const { value } = e.target;
    const zips = value.split('\n').filter((zip) => zip !== '');
    const isValid = zips.every((zip) => {
      const regex = /^[0-9]{5}$/g;
      const t = regex.test(zip);
      return t;
    });
    if (isValid) {
      setManualZipInputValid(true);
      setReduxProfileKeys({
        manualZipsByMeetingType: {
          ...(reduxProfile.manualZipsByMeetingType || {}),
          [meetingType]: zips,
        },
      });
    } else {
      setManualZipInputValid(false);
    }
    setDisplayValue(value);
  }

  useState(() => {
    setDisplayValue(manualZips.join('\n'));
  }, []);

  return (
    <>
      <InfoBox>
        Note that if you set the zipcodes manually here, the agent will not be able
        to modify their permissions from the agent portal. In order to allow the agent
        to customize their own settings you must set the option to &apos;Map&apos; and save
        the profile.
      </InfoBox>
      <div className="row">
        <h4>Manual Entry</h4>
        <div className="col-md-8">
          <p className="fs-4">
            <ul>
              <li>Enter a list of zipcodes with each zipcode on a new line</li>
              <li>Make sure each zip contains only numbers</li>
              <li>All zipcodes you have entered must be valid in order to save the profile</li>
              <li>
                If you have made changes but have not saved the profile you can close this
                modal and re-open it to clear your changes and see what is currently saved
                in the database.
              </li>
            </ul>
          </p>
        </div>
        <div className="col-md-4">
          <textarea
            disabled={disabled}
            className={`form-control ${manualZipInputValid ? 'is-valid' : 'is-invalid'}`}
            style={{ height: '500px' }}
            onChange={(e) => handleChange(e)}
            value={displayValue}
          />
        </div>
      </div>
    </>
  );
}

export default ManualZipEntry;
