const initialState = {
  activation_code: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  npn: '',
  npn_type: '',
  npn_linked: '',
  email: '',
  email_confirm: '',
  password: '',
  password_confirm: '',
  mobile_phone: '',
  npnVerified: false,
  signupSuccessful: false,
  errors: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_SIGNUP_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
      };
    case 'UPDATE_SIGNUP':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_SIGNUP':
      return initialState;
    case 'SET_SIGNUP_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.payload,
        },
      };
    case 'CLEAR_SIGNUP_ERRORS':
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
}
