// Hook for arbtirary amount of API requests where the .then(data) is guaranteed to be from the last-made request
// Example use case:
// Update shopper zipcode and then immediately change the effective date
// before the first request for plans from the zip change has resolved

let queue = [];
async function useNetworkQueue(asyncFn) {
  const ID = queue.length + 1;
  queue.push(ID);
  return new Promise((resolve, reject) => {
    asyncFn()
      .then((data) => {
        // Check to see if this was the most recent call
        const lastID = queue[queue.length - 1];
        if (ID === lastID) {
          queue = [];
          resolve(data);
        }
      }).catch((err) => {
        reject(err);
      });
  });
}
export default useNetworkQueue;
