import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Actions
import { verifyNpn } from '../../../actions/agent_management';

// Components
import LoaderButton from '../../common/LoaderButton';
import FormInput from '../../common/FormInput';
import FormSelect from '../../common/FormSelect';

const VerifyNationalProducerNumber = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    assets: {
      loginHeaderLogo = 'https://mmb-static-assets-prod.s3.amazonaws.com/img/agent-portal/MMB+logo+horizontal+blue.png',
    } = {},
    features: {
      threeWayCall,
    },
  } = useSelector(store => store.theme);
  const {
    signup: {
      last_name = '',
      npn = '',
      npn_type = '',
      activation_code = '',
      errors = {},
    },
  } = useSelector(store => store);

  const setLoading = (payload) => {
    setloading(payload);
    dispatch({ type: 'SET_ROOT_LOADER', payload });
  };

  function isValid(field, value) {
    if (!value) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'This field is required' });
      return false;
    }
    if (field === 'npn' && value.length > 10) {
      dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: 'Ensure this field has no more than 10 characters.' });
      return false;
    }
    dispatch({ type: 'SET_SIGNUP_ERROR', field, payload: '' });
    return true;
  }

  function handleChange({ target: { value, name } }) {
    dispatch({ type: 'UPDATE_SIGNUP_FIELD', field: name, payload: value });
    isValid(name, value);
  }

  function validToSubmit() {
    const lastNameValid = isValid('last_name', last_name);
    const npnValid = isValid('npn', npn);
    const npnTypeValid = isValid('npn_type', npn_type);
    const activationCodeValid = isValid('activation_code', activation_code);
    if (!lastNameValid || !npnValid || !npnTypeValid || !activationCodeValid) return false;
    return true;
  }

  function handleSubmit() {
    if (!validToSubmit()) return;
    setLoading(true);
    verifyNpn({
      last_name,
      npn,
      activation_code,
      npn_type,
    })
      .then(() => {
        dispatch({
          type: 'UPDATE_SIGNUP',
          payload: {
            npnVerified: true,
            last_name,
            npn,
            activation_code,
            npn_type,
          },
        });
      })
      .catch(({ response }) => {
        let msgWarning = 'There was a problem with your information';
        if (response && response.data) {
          const { data } = response;
          const non_field_errors = data?.non_field_errors || [];
          if (non_field_errors.length) {
            if (non_field_errors.includes('npn_not_found') || non_field_errors.includes('name_mismatch') || non_field_errors.includes('invalid')) {
              msgWarning = 'We were not able to find your account. Please contact your YourFMO representative';
            } else if (non_field_errors.includes('already_registered')) {
              msgWarning = 'An account with these credentials has already been registered';
            } else {
              msgWarning = non_field_errors;
            }
          } else if (Object.keys(data).length) {
            const fieldName = Object.keys(data)[0];
            msgWarning = `${fieldName}: ${data[fieldName]}`;
          }
        }
        setErrorMsg(msgWarning);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const style = threeWayCall
    ? {
      textAlign: 'left',
      borderRadius: '2px',
      padding: '3rem',
      paddingTop: '2rem',
      paddingBottom: '2rem',
    }
    : {
      textAlign: 'left',
      alignSelf: 'center',
      border: '1px solid lightgrey',
      borderRadius: '2px',
      padding: '3rem',
      paddingTop: '2rem',
      paddingBottom: '2rem',
      width: '450px',
    };

  return (
    <div
      className="signup"
      style={style}
    >
      <div className="mb-4 text-primary">
        <img
          alt="MyMedicareBot"
          className="login-header-logo"
          style={{ height: '28px', width: 'auto' }}
          src={loginHeaderLogo}
        />
        <div className="text-primary fw-bold" style={{ fontSize: '28px' }}>Create your agent account</div>
        <div className="text-primary">to get started</div>
      </div>

      <div className="client-info-input w-75" style={{ marginBottom: '1rem' }}>
        <FormInput
          controlId="signup-last-name"
          label="Last name"
          value={last_name}
          name="last_name"
          placeholder="Smith"
          onChange={(e) => handleChange(e)}
          invalid={!!errors.last_name}
          error={errors.last_name}
          required
        />
        <FormInput
          controlId="signup-npn"
          label="NPN"
          value={npn}
          name="npn"
          placeholder="NPN"
          onChange={(e) => handleChange(e)}
          invalid={!!errors.npn}
          error={errors.npn}
          required
        />
        <FormSelect
          controlId="signup-npn-type"
          label="NPN type"
          name="npn_type"
          options={[
            { val: 'individual', label: 'Individual' },
            { val: 'corporate', label: 'Corporate' },
          ]}
          placeholder="Select"
          value={npn_type}
          onChange={(e) => handleChange(e)}
          onBlur={({ target: { name, value } }) => isValid(name, value)}
          invalid={!!errors.npn_type}
          error={errors.npn_type}
          required
        />
        <FormInput
          controlId="signup-activation-code"
          label="Activation code"
          value={activation_code}
          name="activation_code"
          placeholder="Activation code"
          onChange={(e) => handleChange(e)}
          invalid={!!errors.activation_code}
          error={errors.activation_code}
          required
        />
      </div>

      <div className="mt-4 d-flex flex-row">
        <LoaderButton
          color="primary"
          text="Next"
          loadingText="Loading..."
          loading={loading}
          className="px-2 fw-bold"
          onClick={() => handleSubmit()}
        />
        {threeWayCall ? (
          <div
            id="login-landing-return"
            className="mt-2"
            role="button"
            tabIndex={0}
            style={{ marginLeft: '2rem', fontSize: '15px' }}
            onClick={() => {
              dispatch({ type: 'CLEAR_SIGNUP' });
              navigate('/');
            }}
          >
            Sign in instead
          </div>
        ) : (
          <div
            id="login-landing-return"
            className="me-0 ms-auto"
            role="button"
            tabIndex={0}
            style={{ alignSelf: 'center', fontSize: '15px' }}
            onClick={() => {
              dispatch({ type: 'CLEAR_SIGNUP' });
              navigate('/');
            }}
          >
            Sign in instead
          </div>
        )}
      </div>

      {errorMsg && (
        <div className="alert alert-danger mt-4" role="alert" style={{ whiteSpace: 'pre-line' }}>
          {errorMsg}
        </div>
      )}

    </div>
  );
};

export default VerifyNationalProducerNumber;
