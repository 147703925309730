import React, { useState, useEffect, useRef } from 'react';
import { GetPlaceCommand } from '@aws-sdk/client-location';
import turfcircle from '@turf/circle';

// hooks
import useOutsideClick from '../../../hooks/useOutsideClick';

// actions
import { getZipsForPoint } from '../../../actions/appointments';

function SearchResults({
  point,
  locationClient,
  setShowSearchResults,
  setSelectSignal,
  showSearchResults,
  mapInstance,
  updateSelf,
  setSearchString,
  searchResults,
  awsConfig,
}) {
  const ref = useRef();
  const [ initialSetDone, setInitialSetDone ] = useState(false);
  const {
    id: pointID,
    radius,
    place: selectedPlace = {},
    include,
    disableUpdate,
  } = point;
  const [ oldRadius, setOldRadius ] = useState(radius);
  const { defaultZoom, apiKey, placesName } = awsConfig;

  async function getPlace(placeID) {
    const params = {
      IndexName: placesName,
      Key: apiKey,
      PlaceId: placeID,
    };
    try {
      const place = await locationClient.send(new GetPlaceCommand(params));
      return place;
    } catch (err) {
      console.log('something went wrong getting the place, try searching again');
      console.error(err);
      return undefined;
    }
  }

  async function handlePlaceSelect(place) {
    setSelectSignal(true);

    // get place details and generate geojson polygon
    let result = {};
    try {
      result = await getPlace(place.PlaceId);
    } catch (err) {
      updateSelf({ radius: oldRadius });
      return;
    }
    setShowSearchResults(false);

    // get lattitude and longitude; if the point is disableUpdate then pull
    // the lattitude and longitude from redux. Else pull from the api call.
    let lon;
    let lat;
    if (disableUpdate) {
      [ lon, lat ] = point.center;
    } else {
      ({
        Place: {
          Geometry: {
            Point: [ lon, lat ],
          },
        },
      } = result);
    }
    setSearchString(place.Text);
    if (!initialSetDone) {
      mapInstance.jumpTo({
        center: [ lon, lat ],
        zoom: defaultZoom,
      });
    }
    const center = [lon, lat];
    const options = {
      // steps: 10,
      units: 'miles',
    };
    const circle = turfcircle(center, radius, options);
    const fillColor = (include ? '#036bfc' : '#db1304');

    getZipsForPoint(lat, lon, radius)
      .then(async ({ data }) => {
        updateSelf({ disableUpdate: true, place, center, zips: data });

        // if point already exists, then we update
        if (mapInstance.getSource(pointID)) {
          await mapInstance.getSource(pointID).setData(circle);
          await mapInstance.setPaintProperty(pointID, 'fill-color', fillColor);
        // if point doesn't exist, we add new point
        } else {
          await mapInstance.addSource(pointID, {
            type: 'geojson',
            data: circle,
          });
          await mapInstance.addLayer({
            id: pointID,
            type: 'fill',
            source: pointID,
            paint: {
              'fill-color': fillColor,
              'fill-opacity': 0.3,
            },
          });
          setInitialSetDone(true);
        }
      }).catch((err) => console.log('error getting zips | ', err));
    setOldRadius(radius);
  }

  useEffect(() => {
    if (initialSetDone) handlePlaceSelect(selectedPlace);
  }, [radius, include]);

  // if loading a saved point from redux, we need to draw it on the map
  useEffect(() => {
    if (mapInstance && selectedPlace.Text) handlePlaceSelect(selectedPlace);
  }, [mapInstance]);

  useOutsideClick(ref, () => setShowSearchResults(false));

  return (showSearchResults && (searchResults.length > 0)) ? (
    <ul
      ref={ref}
      className={`dropdown-menu ${showSearchResults ? 'show' : ''}`}
    >
      {searchResults.map(result => (
        <li key={result.PlaceId}>
          <button
            type="button"
            className="dropdown-item"
            href="#"
            key={result.Text}
            onClick={() => handlePlaceSelect(result)}
          >
            {result.Text}
          </button>
        </li>
      ))}
    </ul>
  ) : null;
}

export default SearchResults;
