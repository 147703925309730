import { loadExtraBenefitsKey } from '../../actions/plan';
import { loadConfig } from '../../actions/config';
import { loadNotifications } from '../../actions/notifications';
import { loadLeadStatusOptions } from '../../actions/lead_status';

export async function loginSuccessActions({
  agentData = {},
  notify,
  dispatch = () => {},
  navigate = () => {},
  successRedirect = '/dashboard',
  MACustomBenefits = [],
  MAPDCustomBenefits = [],
  PDPCustomBenefits = [],
  agentNotifications,
  showReleaseNotes = true,
}) {
  // loadCarriers();
  await loadExtraBenefitsKey();
  dispatch({ type: 'LOAD_AGENT', payload: agentData });
  if (showReleaseNotes) dispatch({ type: 'SHOW_RELEASE_NOTES' });
  if (agentNotifications && notify) {
    const currentNotifications = await loadNotifications();
    if (currentNotifications.length > 0) {
      dispatch({ type: 'SHOW_MODAL', payload: 'agentNotificationModal' });
    }
  }

  let disabledPlans = [];
  try {
    ({ data: { value: disabledPlans } } = await loadConfig('enroll_disabled_plans_json', null, 'public'));
  } catch {
    console.log('error loading site config enroll_disabled_plans_json');
  } finally {
    dispatch({ type: 'SET_DISABLED_PLANS', payload: disabledPlans });
  }

  const leadStatusOptions = await loadLeadStatusOptions();
  dispatch({ type: 'LOAD_LEAD_STATUS_OPTIONS', payload: leadStatusOptions });

  try {
    const { data: { value } } = await loadConfig('custom_benefits_json');
    const MA = value.filter(benefit => benefit.default.includes('MA'));
    const MAPD = value.filter(benefit => benefit.default.includes('MAPD'));
    const PDP = value.filter(benefit => benefit.default.includes('PDP'));
    dispatch({ type: 'SET_CUSTOM_BENEFITS_DEFAULTS', payload: { MA, MAPD, PDP } });
    dispatch({ type: 'SET_CUSTOM_BENEFITS_SEARCH_KEY', payload: value });
    // we check to see if these are length 0 because if they are populated we don't want to
    // overwrite the settings the agent has set up in their browser. If we overwrite every
    // time on login, then every time the agent logs in their benefit selections are wiped.
    if (MACustomBenefits.length === 0) {
      dispatch({ type: 'SET_BENEFITS_VALUE', field: 'MACustomBenefits', payload: MA.map(benefit => benefit.id) });
    }
    if (MAPDCustomBenefits.length === 0) {
      dispatch({ type: 'SET_BENEFITS_VALUE', field: 'MAPDCustomBenefits', payload: MAPD.map(benefit => benefit.id) });
    }
    if (PDPCustomBenefits.length === 0) {
      dispatch({ type: 'SET_BENEFITS_VALUE', field: 'PDPCustomBenefits', payload: PDP.map(benefit => benefit.id) });
    }
  } catch {
    console.log('error loading custom_benefits_json site config');
  }

  dispatch({ type: 'CLEAR_LOGIN', payload: false });

  const { hasTemporaryPassword } = agentData;
  if (hasTemporaryPassword) {
    // If the agent refreshes out before resetting, the dashboard will check for passwordExpired.
    dispatch({ type: 'SHOW_MODAL', payload: 'passwordResetModal' });
  }
  navigate(successRedirect);
}
