import React from 'react';
import { connect } from 'react-redux';

// Actions
import {
  loadMapdCarrierCI,
  loadMedigapCarrierCI,
  loadPdpCarrierCI,
} from '../../../../actions/ci';

class CompetitiveIntelligenceTable extends React.Component {
  componentDidMount() {
    const {
      planType,
      shopper,
      mapdCI,
      medigapCI,
      pdpCI,
    } = this.props;

    switch (planType) {
      case 'mapd':
        if (mapdCI.length === 0) {
          loadMapdCarrierCI(shopper.county, shopper.state);
        }
        break;
      case 'medigap':
        if (medigapCI.length === 0) {
          loadMedigapCarrierCI(shopper.state);
        }
        break;
      case 'pdp':
        if (pdpCI.length === 0) {
          loadPdpCarrierCI(shopper.county, shopper.state);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      style,
      mapdCI,
      medigapCI,
      pdpCI,
      planType,
    } = this.props;

    // Medigap: 8x4 array of CI information
    // MAPD/MA and PDP: 5x4 array of CI information
    let tableData = [];
    let tableRowIndices = [1, 2, 3, 4, 5];
    switch (planType) {
      case 'medigap':
        tableData = medigapCI[0] || [];
        tableRowIndices = [1, 2, 3, 4, 5, 6, 7];
        break;
      case 'mapd':
        tableData = mapdCI[0] || [];
        break;
      case 'pdp':
        tableData = pdpCI[0] || [];
        break;
      default:
        break;
    }

    tableData = tableData.rows && tableData.rows.map((row, rowIdx) => {
      const rowDataName = row[0];
      const translatedRow = row.map((data, dataIdx) => {
        const colDataName = row[0][dataIdx];
        let dataValue = data;
        let dataClassName = '';
        let dataHover = rowIdx > 0 && dataIdx > 0 ? `${rowDataName}: ${data}` : data;

        // Quick method of translating our CI data/headers
        if (data === 'N/A') {
          dataClassName = 'plandetails-empty';
          dataHover = 'Not available';
          dataValue = '--';
        } else if (data === 'Market Avg') {
          dataHover = 'Market Average';
          dataValue = 'Market Average';
        }

        return {
          key: `${rowDataName}-${colDataName}-${dataHover}`,
          component: (
            <span
              className={dataClassName}
              title={dataHover}
            >
              {dataValue}
            </span>
          ),
        };
      });
      return translatedRow;
    });

    return (
      <div className="plandetails-ci" style={style}>
        <table className="plandetails-table" style={style}>
          {
            !tableData || tableData.length <= 0
              ? (
                <tbody>
                  <tr className="plandetails-ci-row">
                    <td className="font-italic text-center p-4" colSpan={12}>
                      No Competitive Intelligence information available in this county
                    </td>
                  </tr>
                </tbody>
              )
              // Generate a 2D table dynamically with CI data
              : (
                <>
                  <thead>
                    <tr>
                      <td />
                      {
                        // All CI have 3 headers
                        tableData[0].slice(1, 4).map((header) => <td key={header.key}>{header.component}</td>)
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // The index of every row we want to display
                      tableRowIndices.map((rowIdx) => (
                        <tr key={rowIdx}>
                          {
                            // Loop through the row and return each element
                            tableData[rowIdx].map((data) => (
                              <td key={data.key}>{data.component}</td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </>
              )
          }
        </table>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
    mapdCI: store.ci.mapdCI,
    medigapCI: store.ci.medigapCI,
    pdpCI: store.ci.pdpCI,
  };
}

export default connect(mapStateToProps)(CompetitiveIntelligenceTable);
