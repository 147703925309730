import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

class PasswordInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: false,
    };
    this.handleVisClick = this.handleVisClick.bind(this);
  }

  handleVisClick() {
    this.setState((prevState) => ({ passwordVisible: !prevState.passwordVisible }));
  }

  render() {
    const {
      className,
      groupClassName,
      name,
      onChange,
      onKeyPress,
      showPasswordViewToggle,
      style,
      value,
      errs,
    } = this.props;

    const {
      passwordVisible,
    } = this.state;

    // Solution to pw input orbs looking too small
    const fs = (passwordVisible || !value) ? 'fs-4' : 'fs-2';
    const inputGroupClassName = `positive-relative ${groupClassName}`;
    const inputClassName = `form-control ${className} ${fs}`;

    return (
      <div className={inputGroupClassName}>
        <input
          autoComplete="on"
          className={inputClassName}
          id="login-input-password-input"
          name={name}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder="Password"
          style={{
            ...style,
          }}
          type={passwordVisible ? 'text' : 'password'}
          value={value}
        />
        {showPasswordViewToggle
          ? (
            <div
              className="login-input-password-visibility-toggle"
              id="login-input-password-visibility-toggle"
              style={{
                right: passwordVisible ? '2px' : '0px',
              }}
            >
              <span
                className="login-input-password-visibility-toggle-span"
                onClick={this.handleVisClick}
                role="button"
                tabIndex={0}
              >
                {
                  passwordVisible
                    ? (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )
                    : (
                      <FontAwesomeIcon icon={faEye} />
                    )
                }
              </span>
            </div>
          )
          : null}
        <div className="position-absolute fade text-danger fs-6" style={{ bottom: '-21px', opacity: errs ? '1' : '0' }}>
          {errs}
        </div>
      </div>
    );
  }
}

PasswordInput.defaultProps = {
  onChange: () => {},
  value: '',
  className: '',
  style: {},
  name: '',
};

export default connect()(PasswordInput);
