/* eslint-disable prefer-const, camelcase */
import axios from 'axios';
import { store } from '../store';
import {
  AWAIT_STATE,
  FAIL_STATE,
  SUCCESS_STATE,
  STATE_MAPPING,
} from '../constants';

export function doctorSearch(zipcode, searchString, radius = null, skill = null) {
  const token = window.localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      headers: { Authorization: `Token ${token}` },
      params: { radius, skills: skill },
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/provider/${zipcode}/${searchString}/`,
    })
      .then((res) => {
        const rfmData = [];
        res.data.forEach((doctor) => {
          doctor.addresses.forEach((location) => {
            let { state } = location;
            let { phone } = doctor;
            const phoneNumbers = location.phone_numbers;
            if (STATE_MAPPING[state]) {
              state = STATE_MAPPING[state];
            }
            if (phoneNumbers && phoneNumbers.length) {
              [phone] = phoneNumbers;
            }
            rfmData.push({
              npi: String(doctor.id),
              name: (doctor.presentation_name || '').trim(),
              address: (location.street_line_1 || '').trim(),
              address2: (location.street_line_2 || '').trim(),
              latitude: location.latitude,
              longitude: location.longitude,
              city: (location.city || '').trim(),
              state: (state || '').trim(),
              phone: (phone || '').trim(),
              zipcode: location.zip_code,
              coveredPlans: [],
              specialties: doctor.specialties,
              providerType: doctor.type,
              addressID: location.address_id,
              efficiency: location.efficiency, // humana only field
              effectiveness: location.effectiveness, // humana only field
              group: (location.group || '').trim(),
              ipa: (location.ipa || '').trim(),
              hospital: (location.hospital || '').trim(),
              pcpID: (location.pcp_id || '').trim(),
            });
          });
        });
        store.dispatch({ type: 'SET_DOCTORS', payload: rfmData });
        resolve(res.data);
      }).catch((err) => {
        reject(err);
      });
  });
}

export function loadDoctorPlans(npi, shopperUsername) {
  const token = window.localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    // NOTE(santeyio): to use the humana provider api we combine
    // the humana network id and internal humana provider id {networkid}-{humanaid}
    // because the humana API does not provide NPI. We use the network
    // and humana plan data api to determine which plans are covered.
    //
    // for the docurobot provider api we use the npi to get a list
    // of covered CPS IDs.
    store.dispatch({ type: 'SET_DOCTOR_PLANS_LOADED_STATE', payload: AWAIT_STATE });
    axios({
      method: 'GET',
      headers: { Authorization: `Token ${token}` },
      params: { shopper_username: shopperUsername },
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/provider/${npi}/plans/`,
    })
      .then((res) => {
        const coveredPlans = res.data;
        store.dispatch({ type: 'ADD_DOCTOR_PLANS', payload: { npi, plans: coveredPlans } });
        store.dispatch({ type: 'SET_DOCTOR_PLANS_LOADED_STATE', payload: SUCCESS_STATE });
        resolve(coveredPlans);
      }).catch((err) => {
        store.dispatch({ type: 'SET_DOCTOR_PLANS_LOADED_STATE', payload: FAIL_STATE });
        reject(err);
      });
  });
}

function formatAPIDoctor(doctor) {
  const rfmDoc = {
    npi: doctor.npi,
    name: doctor.name,
    address: doctor.address,
    address2: doctor.address2,
    latitude: doctor.latitude,
    longitude: doctor.longitude,
    city: doctor.city,
    state: doctor.state,
    zipcode: doctor.zipcode,
    phone: doctor.phone,
    specialties: doctor.specialties,
    important: doctor.important,
    efficiency: doctor.efficiency, // humana only field
    effectiveness: doctor.effectiveness, // humana only field
    provider_type: doctor.providerType,
    covered_plans: doctor.coveredPlans,
    group: doctor.group, // humana only field
    ipa: doctor.ipa, // humana only field
    hospital: doctor.hospital, // humana only field
  };
  return rfmDoc;
}

export function saveDoctor(doctor, coveredPlans = [], username, anonymous = false) {
  const token = window.localStorage.getItem('SessionToken');
  const rfmDoctor = formatAPIDoctor(doctor);
  rfmDoctor.covered_plans = coveredPlans;
  return new Promise((resolve, reject) => {
    if (anonymous) {
      resolve({ ...doctor, coveredPlans, id: null });
    } else {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/provider/`,
        headers: { Authorization: `Token ${token}` },
        data: rfmDoctor,
        params: { shopper: username },
      })
        .then((res) => {
          const { id } = res.data;
          const doctorWithID = { ...doctor, id, coveredPlans };
          resolve(doctorWithID);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

export function updateDoctor(doctor, username, anonymous = false) {
  const token = window.localStorage.getItem('SessionToken');
  const rfmDoctor = formatAPIDoctor(doctor);
  return new Promise((resolve, reject) => {
    if (anonymous) {
      resolve({ ...doctor, id: null });
    } else {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/provider/${doctor.id}/`,
        headers: { Authorization: `Token ${token}` },
        data: rfmDoctor,
        params: { shopper: username },
      })
        .then((res) => {
          const { id } = res.data;
          const doctorWithID = { ...doctor, id };
          resolve(doctorWithID);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

export function deleteDoctor(docID, anonymous = false) {
  const token = window.localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    if (anonymous) {
      resolve();
    } else {
      axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/provider/${docID}/`,
        headers: { Authorization: `Token ${token}` },
      })
        .then((res) => {
          resolve(res.data);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

export function loadDoctors(username) {
  const token = localStorage.getItem('SessionToken');
  const { reloadDocCoverage } = store.getState().theme.features;

  return new Promise((resolve, reject) => {
    store.dispatch({ type: 'SET_SHOPPER_DOCTORS_LOADED_STATE', payload: AWAIT_STATE });
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/provider/`,
      headers: { Authorization: `Token ${token}` },
      params: { shopper: username },
    }).then((res) => {
      const rfmDoctors = res.data.map((doc) => ({
        address: doc.address,
        address2: doc.address2,
        city: doc.city,
        coveredPlans: doc.covered_plans,
        effectiveness: doc.effectiveness,
        efficiency: doc.efficiency,
        id: doc.id,
        important: doc.important,
        latitude: doc.latitude,
        longitude: doc.longitude,
        name: doc.name,
        npi: doc.npi,
        phone: doc.phone,
        providerType: doc.provider_type,
        specialties: doc.specialties,
        state: doc.state,
        zipcode: doc.zipcode,
        group: doc.group,
        ipa: doc.ipa,
        hospital: doc.hospital,
      }));

      if (reloadDocCoverage) {
        const coverages = rfmDoctors.map((doc) => loadDoctorPlans(doc.npi, username));
        Promise.all(coverages).then((data) => {
          data.forEach((coverage, i) => {
            rfmDoctors[i].coveredPlans = coverage;
          });
          resolve(rfmDoctors);
        }).catch((err) => {
          reject(err);
        });
      } else {
        resolve(rfmDoctors);
      }
    }).catch((err) => {
      store.dispatch({ type: 'SET_SHOPPER_DOCTORS_LOADED_STATE', payload: FAIL_STATE });
      reject(err);
    });
  });
}

export function loadDoctorSpecialties() {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/provider/specialties/`,
      headers: { Authorization: `Token ${token}` },
    }).then((res) => {
      resolve(res.data);
    }).catch((err) => {
      reject(err);
    });
  });
}
