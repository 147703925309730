import axios from 'axios';
import forceDownload from 'js-file-download';

export default (url, filename) => {
  axios({
    method: 'get',
    url,
    responseType: 'blob',
  }).then((res) => {
    forceDownload(res.data, filename);
  });
};
