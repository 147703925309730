import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { getPlanCarrierDisplay } from '../../../utils/general';

// Components
import SOABadge from '../../common/SOABadge';

function CompareFooterPlancard({
  planID,
  showCurrentPlan,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { medicareList } = useSelector(store => store.plan);
  const [ plan, setPlan ] = useState({ planType: '', planName: '', cpsID: '' });

  useEffect(() => {
    const planToDisplay = medicareList.filter((eachPlan) => {
      if (showCurrentPlan) {
        return eachPlan.cpsID === planID;
      }
      return eachPlan.planID === planID;
    })[0] || {};
    setPlan(planToDisplay);
  }, [planID, medicareList]);

  return (
    <div
      className={`planlist-compare-footer-plancard ${showCurrentPlan ? 'bg-primaryFocusBackground' : ''}`}
      key={plan.planID}
    >
      {
        plan.planID > 0
          ? (
            <>
              <div
                className="d-flex flex-column plan"
                style={{
                  cursor: 'pointer',
                }}
                title={{}}
                onClick={() => {
                  navigate('/plans/details');
                  dispatch({
                    type: 'SET_PLAN_DETAILS',
                    payload: {
                      planId: plan.planID,
                      planType: plan.planType,
                      planCarrier: plan.carrier,
                      planName: plan.planName,
                    },
                  });
                }}
                role="button"
                tabIndex={0}
              >
                <div className="d-flex flex-row" style={{ lineHeight: '12px' }}>
                  <span className="plan-type">{plan.planType}</span>
                  <SOABadge planType={plan.planType} badgeType="icon" style={{ marginRight: '2px' }} offsetIcon />
                  <span className="plan-carrier">{getPlanCarrierDisplay({ plan })}</span>
                  {showCurrentPlan ? <span className="plan-type text-black fw-normal ms-1"> - CURRENT</span> : null}
                </div>
                <div className="plan-name">{plan.planName}</div>
              </div>
              {planID > 0
                ? (
                  <div
                    className="planlist-compare-footer-plancard-remove"
                    onClick={() => {
                      dispatch({
                        type: 'REMOVE_PLAN_COMPARE',
                        payload: { planId: planID },
                      });
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                ) : null}
            </>
          )
          : (
            <span className="d-flex flex-column plan" style={{ color: '#939598', fontStyle: 'italic' }}>
              No plan selected
            </span>
          )
      }
    </div>
  );
}


export default CompareFooterPlancard;
