const initialState = {
  options: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_LEAD_STATUS_OPTIONS':
      return { ...state, options: action.payload };
    case 'CLEAR_LEAD_STATUS':
      return initialState;
    default:
      return state;
  }
}
