import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withRouterProps } from '../../../utils/hocs';

// Actions
import { loadMedicarePlans } from '../../../actions/plan';

// Components
import PlanListHeader from './PlanListHeader';
import PricingDisclaimer from '../../common/PricingDisclaimer';
import LoaderComponent from '../../common/LoaderComponent';
import MAPDList from './MAPDList';
import NavPage from '../../nav/NavPage';
import PDPList from './PDPList';
import Recommended from './recommended';
import SOAReminderModal from '../../soa/Reminder';
import CompareFooter from '../compare/CompareFooter';
import { HiddenRefreshButton } from './components';

// Constants
import {
  AWAIT_STATE,
  DEFAULT_STATE,
  FAIL_STATE,
  SUCCESS_STATE,
} from '../../../constants';

// Utilities
import { validateEffectiveDate } from '../../../utils/general';

class PlanListPage extends React.PureComponent {
  async componentDidMount() {
    const {
      agent,
      dispatch,
      hasStatePermissionEnabled,
      medicareListLoaded,
      navigate,
      soa,
      shopper,
      compareZipcode,
      effectiveUpdate,
    } = this.props;

    // Load in fresh Carrier data
    // loadCarriers();

    if (shopper.zipcode !== compareZipcode) {
      dispatch({ type: 'CLEAR_COMPARE_PLANS' });
    }

    if (medicareListLoaded === DEFAULT_STATE || medicareListLoaded === FAIL_STATE) {
      // Loaded in on shopper form, but reloadable for updated pharmacy information
      dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: AWAIT_STATE });
      loadMedicarePlans(shopper)
        .then((data) => {
          dispatch({ type: 'SET_MEDICARE_PLAN_LIST', payload: data });
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: SUCCESS_STATE });
        }).catch((err) => {
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: FAIL_STATE });
          console.error(err);
        });
    }
    // Currently not using medigap
    // if (medigapListLoaded === DEFAULT_STATE || medigapListLoaded === FAIL_STATE) {
    //   loadMedigapData(shopper, availableCarriers);
    // }
    if (shopper.state && hasStatePermissionEnabled && !(agent.statePermissions.includes(shopper.state))) {
      dispatch({ type: 'SHOW_MODAL', payload: 'statePermissionModal' });
      navigate('/shopper');
    }
    if (shopper.soaList.length === 0 && soa) {
      // This modal is always shown after creating a shopper.
      // Delay so the UX is not shocking
      window.setTimeout(() => {
        dispatch({ type: 'SHOW_MODAL', payload: 'soaReminderModal' });
      }, 1500);
    }
    if (validateEffectiveDate(shopper.effectiveDate) && effectiveUpdate) {
      dispatch({ type: 'SHOW_MODAL', payload: 'effectiveDateWarningModal' });
      navigate(`/shopper/${shopper.username}`);
    }
  }

  render() {
    const {
      carrierPerms,
      invalidZipModal,
      medicareListLoaded,
      navigate,
      planlistTableTab,
    } = this.props;

    if (invalidZipModal) {
      navigate('/shopper');
      return null;
    }

    // const isNetworkView = networkView === 'SELECT';

    // TODO(santeyio): pull this navtab functionality out from medcab and
    // here and componentize it.
    const options = [{
      title: 'Recommended',
      stateLabel: 'recommended',
      plansLoaded: medicareListLoaded,
      body: (
        <Recommended
          key="RecommendedList"
        />
      ),
    }, {
      title: 'MA',
      stateLabel: 'ma',
      plansLoaded: medicareListLoaded,
      body: (
        <MAPDList
          key="MAPDList"
          planType={['MA']}
        />
      ),
    }, {
      title: 'MAPD',
      stateLabel: 'mapd',
      plansLoaded: medicareListLoaded,
      body: (
        <MAPDList
          key="MAPDList"
          planType={['MAPD']}
        />
      ),
    }, {
      title: 'PDP',
      stateLabel: 'pdp',
      plansLoaded: medicareListLoaded,
      body: (
        <PDPList
          key="PDPList"
        />
      ),
    }];

    const planTypePermissions = [];
    carrierPerms.forEach((p) => {
      if (p.active && !planTypePermissions.includes(p.plan_type)) {
        planTypePermissions.push(p.plan_type);
      }
    });

    return (
      <NavPage activeLink="/plans" showShopperInfobar>
        <div className="mmb-container" id="planlist">
          <HiddenRefreshButton />
          <PricingDisclaimer />
          <div className="mmb-header d-flex align-items-center">
            <PlanListHeader />
          </div>
          <div className="mmb-content" style={{ minHeight: '50vh' }}>
            {options.map((option) => {
              if (option.stateLabel === planlistTableTab) {
                switch (option.plansLoaded) {
                  case SUCCESS_STATE:
                    return option.body;
                  case AWAIT_STATE:
                    return <LoaderComponent key={option.stateLabel} />;
                  case DEFAULT_STATE:
                    return (
                      <div key={option.stateLabel} className="pl-section h5 text-grey text-center pt-5 pb-5">
                        Loading Plan information..
                      </div>
                    );
                  case FAIL_STATE:
                    return (
                      <div key={option.stateLabel} className="pl-section h5 text-grey text-center pt-5 pb-5">
                        There was a problem loading your Plans.
                      </div>
                    );
                  default:
                    return (
                      <div key={option.stateLabel} className="pl-section h5 text-grey text-center pt-5 pb-5">
                        Loading Plan information..
                      </div>
                    );
                }
              }
              return null;
            })}
          </div>
        </div>
        <CompareFooter
          backButtonText="Compare"
          backOnClick={() => { navigate('/plans/compare'); }}
        />
        <SOAReminderModal />
      </NavPage>
    );
  }
}

function WithNavigation(props) {
  const navigate = useNavigate();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PlanListPage {...props} navigate={navigate} />;
}

function mapStateToProps(store) {
  return {
    agent: store.agent,
    availableCarriers: store.carriers,
    carrierPerms: store.agent.carrierPermissions,
    effectiveUpdate: store.theme.features.effectiveUpdate,
    hasStatePermissionEnabled: store.theme.features.statePermissionStatus,
    invalidZipModal: store.modal.invalidZipModal,
    selectFilter: store.plan.selectFilter,
    shopper: store.shopper,
    soa: store.theme.features.soa,
    medigapList: store.plan.medigapList,
    medicareListLoaded: store.loaders.medicareListLoaded,
    networkView: store.plan.networkView,
    planlistTableTab: store.display.planlistTableTab,
    planSortSelection: store.plan.planSortSelection,
    compareZipcode: store.compare.zipcode,
  };
}

export default connect(mapStateToProps)(withRouterProps(WithNavigation));
