/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';

// actions
import { fetchQSEmbedURL } from '../../actions/reports';

function QuicksightReportLoader() {
  const ref = useRef();
  const navigate = useNavigate();
  const { reportID } = useParams();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('aws');
    console.log('type: ', type);

    fetchQSEmbedURL(reportID, type).then(({ data }) => {
      (async () => {
        console.log('data: ', data);
        ref.current.innerHTML = '';

        const url = data;

        createEmbeddingContext()
          .then((context) => {
            const { embedDashboard } = context;
            embedDashboard({
              url,
              container: ref.current,
              scrolling: 'yes',
              height: '100%',
              width: '100%',
            });
          });
      })();
    });
  }, []);

  return (
    <main
      style={{
        position: 'relative',
        top: '-5px',
      }}
    >
      <div
        className="ps-2 pb-2"
      >
        <span
          role="button"
          tabIndex={0}
          onClick={() => navigate('/reports')}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="fa-lg me-2"
          />
          <b>Back to Reports Home</b>
        </span>
      </div>

      <div
        id="quicksight-embed-div"
        ref={ref}
        style={{
          height: '90vh',
        }}
      >
        {/* NOTE(santeyio): spinner is automatically removed by embed process */}
        <div className="d-flex align-items-center justify-content-center h-100 pt-5">
          <div className="spinner-border" style={{ width: '4rem', height: '4rem' }} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>

      </div>
    </main>
  );
}

export default QuicksightReportLoader;
