import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

// Components
import EnrollmentButtons from './EnrollmentButtons';
import HRAButtons from './HRAButtons';

// Actions + Utils
import { withRouterProps } from '../../../utils/hocs';
import { canViewHRA } from '../../../utils/hra';

// import { scrollToTop } from '../../../utils/mixin';
import {
  fmtField,
  formatDollarAmount,
  getDateAsString,
  formatToPhone,
  getPlanCarrierDisplay,
} from '../../../utils/general';
import { getQuoteHistory } from '../../../actions/quote';

function SavedQuote({
  quote = {},
  key,
}) {
  const {
    enrollment = {},
    hra = {},
    extraBenefits = [],
  } = quote;
  const { shopper } = useSelector(store => store);
  const { submitted_flag } = enrollment || {};

  const [open, setOpen] = useState(false);
  const [newQuote, setNewQuote] = useState(false);
  const [history, setHistory] = useState([]);

  // Adding human-readable history to 'Show details' history section
  function parseHistory(quoteHistory) {
    const fmtHistory = [{ timestamp: quote.created, detail: 'Quote saved' }];
    quoteHistory.forEach(event => {
      if (event.created && !event.opened) {
        if (event.phone) fmtHistory.push({ timestamp: event.created, detail: `Quote texted (SMS) to: ${formatToPhone(event.phone)}` });
        else fmtHistory.push({ timestamp: event.created, detail: `Quote emailed to: ${event.email}` });
      } else if (event.opened && event.opened_date) {
        fmtHistory.push({ timestamp: event.opened_date, detail: 'Quote opened' });
      }
    });
    if (enrollment?.created) fmtHistory.push({ timestamp: enrollment.created, detail: 'Enrollment began' });
    if (enrollment?.submitted_flag) fmtHistory.push({ timestamp: enrollment?.submit_date, detail: 'Enrollment submitted' });
    if (hra?.created) fmtHistory.push({ timestamp: hra.created, detail: 'HRA began' });
    if (hra?.submit_date) fmtHistory.push({ timestamp: hra.submit_date, detail: 'HRA submitted' });
    fmtHistory.sort((a, b) => a.timestamp > b.timestamp);
    return fmtHistory;
  }

  function loadQuoteHistory() {
    getQuoteHistory(shopper.username, quote.quoteID)
      .then(({ data }) => {
        const fmtHistory = parseHistory(data, quote);
        setHistory(fmtHistory);
      });
  }

  useEffect(() => {
    /* If the quote was just created, open the card */
    const timeDiff = moment(new Date()).diff(moment(quote.created));
    if (timeDiff < 100) {
      setOpen(true);
      setNewQuote(true);
      setTimeout(() => {
        setNewQuote(false);
      }, 1000);
    }
    loadQuoteHistory();
    // todo(joeysapp): When is this called again? Juan added it
    // if (event.name === 'select-quote' && event.payload) {
    //   handleScrollTo(event.payload);
    // }
  }, []);

  // function handleScrollTo(quoteID) {
  //   if (quoteID === quote.quoteID) {
  //     setOpen(true);
  //     // delay after opened show detail
  //     setTimeout(() => {
  //       scrollToTop(`[data-id='${quote.quoteID}']`, 100);
  //     }, 800);
  //   } else {
  //     setOpen(false);
  //   }
  // };

  //   todo(joeysapp): this too..
  // componentDidUpdate(prevProps) {
  //   const { event } = this.props;
  //   if (prevProps.event.id !== event.id && event.name === 'select-quote' && event.payload) {
  //     this.handleScrollTo(event.payload);
  //   }
  //   if (prevProps.quote.enrollment !== this.props.quote.enrollment) {
  //     this.loadQuoteHistory();
  //   }
  // }

  return (
    <div
      className={`enroll-quote-card open ${newQuote ? 'new' : ''}`}
      key={key}
      data-id={quote.quoteID}
    >
      {/* Top row: [[cost], [info/buttons, hide details]] */}
      <div className="d-flex" style={{ minHeight: submitted_flag ? '200px' : '150px' }}>
        <div className="d-flex ps-4" style={{ minWidth: '125px' }}>
          <div
            id="quote-total"
            className="d-flex justify-content-center flex-column ms-1 text-nowrap"
            title={`Quote total: ${formatDollarAmount(quote.premium + quote.osbCost)}`}
          >
            <div>Quote total:</div>
            <div className="fs-2 fw-bold">{fmtField(quote.premium + quote.osbCost, 'dollar')}</div>
          </div>
        </div>
        <div
          className={[
            'd-flex flex-column justify-content-center',
            'border-start border-darker-grey',
            'ps-4 fs-5 overflow-hidden w-100',
          ].join(' ')}
          id="plan-id"
        >
          <div className="d-flex w-100">
            <div
              className={[
                'plan-tri-header',
                'ms-1 mb-1 me-auto',
                'align-items-center w-100 text-nowrap overflow-hidden',
              ].join(' ')}
            >
              <div id="type" className="">{quote.planType}</div>
              <div id="carrier" className="">{getPlanCarrierDisplay({ quote })}</div>
              <div id="name" className="text-truncate">
                {quote.planName}
              </div>
            </div>
            <div
              id="quote-created"
              className="me-3 ms-auto fs-5 opacity-50 text-nowrap"
            >
              Created {getDateAsString(quote.created, 'longform')}
            </div>
          </div>
          {/* Second row - [Share quote/Enroll Now] or [View Enrollment / HRA buttons] */}
          <div className="d-flex ms-1 mt-1 align-items-center">
            <div className="d-flex flex-column overflow-hidden me-1">
              <EnrollmentButtons quote={quote} />
              {(enrollment && enrollment.submitted_flag && !enrollment.cancelled && canViewHRA(quote)) && (
                <HRAButtons quote={quote} />
              )}
            </div>
            <button
              className="btn btn-outline-secondary ms-auto me-4 d-flex justify-content-center text-nowrap"
              tabIndex={0}
              type="button"
              onClick={() => setOpen(!open)}
              style={{ minWidth: '150px' }}
            >
              <div className="d-flex align-items-center ps-2 pe-2 fs-5">
                {open ? 'Hide' : 'Show'} details
                <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} className="ms-2 fs-2" />
              </div>
            </button>
          </div>
        </div>
      </div>
      {/* Bottom row: [[benefits, osbs, cost], [info, quote history]] */}
      <div
        className="d-flex border-top border-darker-grey overflow-hidden"
        style={{
          fontSize: '15px',
          maxHeight: open ? `${500 + history.length * 40}px` : '0px',
          transition: 'all 0.75s ease',
        }}
      >
        {/* Left side: [benefits, osbs, cost] */}
        <div className="d-flex flex-column w-50 border-end border-darker-grey">
          <div className="border-bottom border-darker-grey m-4 mt-3">
            <b>Extra benefits</b>
            <ul className="ps-3">
              {quote && extraBenefits && extraBenefits.map
                ? extraBenefits.map((b) => (b.BenefitValue === 'Yes'
                  ? <li key={`${quote.quoteID}-${b.BenefitName}`}>{b.BenefitName}</li>
                  : null))
                : 'N/A'}
            </ul>
          </div>
          <div className="border-bottom border-darker-grey m-4 mt-0 pb-3">
            <b>Optional supplemental benefits</b>
            <div className="ps-3">
              {quote && quote.osbName ? (
                <div className="d-flex">
                  <span className="ms-0 me-auto">{quote.osbName}</span>
                  <span className="ms-auto me-0">{fmtField(quote.osbCost, 'dollar')}</span>
                </div>
              ) : 'N/A'}
            </div>
          </div>
          <div className="d-flex flex-column m-4 mt-0 fs-4">
            <div className="d-flex">
              <span className="ms-0 me-auto">Base premium:</span>
              <span className="ms-auto me-0">{fmtField(quote.premium, 'dollar')}</span>
            </div>
            {quote && quote.osbName ? (
              <div className="d-flex">
                <span className="ms-0 me-auto">{quote.osbName}</span>
                <span className="ms-auto me-0">{fmtField(quote.osbCost, 'dollar')}</span>
              </div>
            ) : null}
            <div className="d-flex fw-bold fs-3">
              <span className="ms-0 me-auto">Total:</span>
              <span className="ms-auto me-0">{fmtField(quote.premium + quote.osbCost, 'dollar')}</span>
            </div>
          </div>
        </div>
        {/* Right side: [info, quote history] */}
        <div className="d-flex flex-column w-50">
          <div className="d-flex flex-column border-bottom border-darker-grey m-4 mt-3 pb-4">
            <div>Plan ID: {quote ? quote.cpsID : '--'}</div>
            <div>State: {quote ? quote.state : '--'}</div>
            <div>Effective date: {quote ? moment(quote.effectiveDate).format('MM-DD-YYYY') : '--'}</div>
            <div>Quote ID: {quote ? quote.quoteID : '--'}</div>
          </div>
          <div className="d-flex flex-column m-4 mt-0">
            <b>Quote history</b>
            {
              history.map((event) => (
                <div className="d-flex" key={event.timestamp}>
                  <span className="ms-0 me-auto">{getDateAsString(event.timestamp, 'longform')}</span>
                  <span className="ms-auto me-0" style={{ textAlign: 'right' }}>{event.detail}</span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouterProps(SavedQuote);
