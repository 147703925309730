import { useEffect } from 'react';

/*
 * Example usage
 *

function Component() {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShow);
  return (
    <div display={show} ref={wrappedRef}>
      Stuff
    </div>
  );
}

*/

function useOutsideClick(ref, callback) {
  useEffect(() => {
    /**
     * triger callback(false) function if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useOutsideClick;
