import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Components
import { Highlight } from '../../../guide';
import tooltipsBook from '../tooltipsbook';
import QuestionToolTip from '../../common/QuestionToolTip';
import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';
import TableRow from './TableRow';
import DeleteDocModal from './DeleteDocModal';

class ListView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      careHighlights,
      doctorList,
      navigate,
    } = this.props;

    const {
      ceTitle,
      ceDesc,
      cqTitle,
      cqDesc,
    } = tooltipsBook.careHighlights;

    return (
      <NavPage
        activeLink="/doctors"
        showShopperInfobar
      >
        <div className="doctors">
          <div className="mmb-header" style={{ minHeight: '90px' }}>
            <div className="d-flex flex-column">
              Doctors
              <button
                type="button"
                className="btn btn-primary mb-0 mt-auto fw-bold align-self-start mt-4"
                onClick={() => {
                  navigate('/doctors/search');
                }}
              >
                Add doctor
              </button>
            </div>
          </div>
          <div className="rx-list">
            <table className="table table-striped drug-list-table">
              <thead style={{ whiteSpace: 'nowrap' }}>
                <tr>
                  <th>Doctor name</th>
                  <th>Specialties</th>
                  <th>Address</th>
                  {careHighlights ? (
                    <th>
                      <Highlight id="provider" i={1} side="bl">
                        <QuestionToolTip title={cqTitle} content={cqDesc} />
                        <span className="me-1">Clinical quality</span>
                      </Highlight>
                    </th>
                  ) : null}
                  {careHighlights ? (
                    <th>
                      <Highlight id="provider" i={0} side="bl">
                        <QuestionToolTip title={ceTitle} content={ceDesc} />
                        <span className="me-1">Cost efficiency</span>
                      </Highlight>
                    </th>
                  ) : null}
                  <th />
                </tr>
              </thead>
              <tbody>
                {doctorList.length
                  ? doctorList.map((doctor) => (
                    <TableRow
                      key={`${doctor.addressID}-${doctor.name}`}
                      doctor={doctor}
                    />
                  )) : (
                    <tr style={{ height: '200px', userSelect: 'none' }}>
                      <td colSpan="12" className="text-center align-middle"><i>Looks like no doctors have been added yet.</i></td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </div>
        <FooterNav
          noPlanLink
          nextOnClick={() => navigate('/plans/list')}
        />
        <DeleteDocModal />
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    doctorList: store.shopper.doctorList,
    shopper: store.shopper,
    careHighlights: store.theme.features.careHighlights,
  };
}

export default connect(mapStateToProps)(withRouterProps(ListView));
