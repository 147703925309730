/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../utils/hocs';
import { persistor, history } from '../../../store';

// Actions
import getFingerprint from '../../../utils/fingerprint';
import { checkIfLoggedIn } from '../../../actions/session';

// Components
import LoginBox from './LoginBox';

// Utilities
import { showPopup } from '../../../utils/popup';

class LoginPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMobileDevice: false,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      navigate,
      redirectedFromDesktop,
      updateReady,
    } = this.props;

    // Specific body class for removing empty scrollbar
    document.body.className = 'login-landing-body';

    // Check if the user is on a mobile device, and if so push them to /mobile
    try {
      document.createEvent('TouchEvent');
      this.setState({ isMobileDevice: true });
    } catch (err) {
      this.setState({ isMobileDevice: false });
    }

    checkIfLoggedIn()
    .then(() => {
      navigate('/dashboard');
    }).catch(() => {
        dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: 'DEFAULT_STATE' });
        dispatch({ type: 'CLEAR_SHOPPER' });
        dispatch({ type: 'CLEAR_AGENT' });
        dispatch({ type: 'CLEAR_CARRIERS' });
        dispatch({ type: 'CLEAR_LOGIN' });
        dispatch({ type: 'CLEAR_THREE_WAY' });
        dispatch({ type: 'CLEAR_SHOPPER_LIST' });
        dispatch({ type: 'SET_LOGIN_LOADING', payload: false });
        getFingerprint()
          .then((fingerprint) => {
            dispatch({ type: 'SET_LOGIN_FINGERPRINT', payload: fingerprint });
          }).catch(() => {
            showPopup('Error', 'There was a problem with your credentials.');
          });
        // Clear app data.
        localStorage.removeItem('SessionToken');
        // Clear redux data.
        persistor.flush().then(() => {
          persistor.purge().then(() => {
            // Update app if available.
            if (updateReady) {
              window.location.reload(true);
            }
          });
        });
      });
  }

  render() {
    const { isMobileDevice } = this.state;
    const {
      loginHeaderLogo,
      loginLandingLogo,
      navigate,
      showVerification,
      showCaptcha,
      redirectedFromDesktop,
    } = this.props;

    // If we've already redirected them, only display redirect in footer
    if (isMobileDevice && !redirectedFromDesktop) {
      navigate('/mobile/login');
    }

    return (
      <div className="login-landing d-flex">
        <div className="login-landing-left vh-100 align-items-center">
          <img
            alt="MyMedicareBot"
            className="login-landing-logo"
            src={loginLandingLogo}
          />
        </div>
        <div className="login-landing-right vh-100 d-flex flex-column">
          <div className="login-header-container">
            <img
              alt="MyMedicareBot"
              className="login-header-logo"
              src={loginHeaderLogo}
            />
            {
              (showVerification || showCaptcha)
                || (
                  <div className="login-header-text text-primary">
                    Welcome to the Agent Portal!
                  </div>
                )
            }
            <LoginBox />
          </div>
        </div>
        {
          isMobileDevice
            ? (
              <div className="mobile-footer">
                <div
                  className="btn btn-secondary w-75"
                  style={{ fontWeight: '600', fontSize: '18px', borderRadius: '8px' }}
                  onClick={() => { navigate('/mobile/login'); }}
                  role="button"
                  tabIndex={0}
                >
                  View on mobile site
                  <FontAwesomeIcon
                    style={{ color: 'white' }}
                    icon={faAngleRight}
                    className="fa-lg ms-2"
                  />
                </div>
              </div>
            )
            : null
        }
      </div>
    );
  }
}

function mapStateToProps(store) {
  let loginHeaderLogo = 'https://mmb-static-assets-prod.s3.amazonaws.com/img/agent-portal/MMB+logo+horizontal+blue.png';
  let loginLandingLogo = 'https://mmb-static-assets-prod.s3.amazonaws.com/img/agent-portal/Agent-Portal-landing-image-websz.png';

  const themeAssets = store.theme.assets;
  if (themeAssets) {
    loginHeaderLogo = themeAssets.loginHeaderLogo || loginHeaderLogo;
    loginLandingLogo = themeAssets.loginLandingLogo || loginLandingLogo;
  }

  return {
    loginHeaderLogo,
    loginLandingLogo,
    showVerification: store.login.showVerification,
    showCaptcha: store.login.showCaptcha,
    redirectedFromDesktop: store.mobile.redirectedFromDesktop,
  };
}

export default connect(mapStateToProps)(withRouterProps(LoginPage));
