import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// Actions
import { loadDoctorPlans, saveDoctor, deleteDoctor } from '../../../actions/doctor';

// Utilities
import { showPopup } from '../../../utils/popup';

class DoctorSelectButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      color: 'secondary',
      selected: false,
      loading: false,
    };
    this.setSelect = this.setSelect.bind(this);
  }

  componentDidMount() {
    this.setSelect(this.props.selected);
  }

  setSelect(selected, saveFlag = false) {
    const {
      username,
      anonymous,
      dispatch,
      doctorList,
      doctor,
    } = this.props;

    if (selected) {
      if (saveFlag) {
        this.setState({ loading: true });
        loadDoctorPlans(doctor.npi, username)
          .then((coveredPlans) => saveDoctor(doctor, coveredPlans, username, anonymous))
          .then((d) => {
            dispatch({ type: 'ADD_DOCTOR_TO_MEDCAB', payload: d });
            this.setState({ loading: false, color: 'primary', selected: true });
          }).catch(() => {
            showPopup('Error', 'There was a problem selecting the doctor.');
            this.setState({ loading: false });
          });
      } else {
        this.setState({ color: 'primary', selected: true });
      }
    } else {
      if (saveFlag) { // eslint-disable-line
        this.setState({ loading: true });
        const { id } = doctorList.find((d) => d.id === doctor.id);

        deleteDoctor(id, anonymous)
          .then(() => {
            dispatch({ type: 'DELETE_DOCTOR_FROM_MEDCAB', payload: id });
            this.setState({ loading: false, color: 'secondary', selected: false });
          }).catch(() => {
            showPopup('Error', 'There was a problem deleting the doctor.');
            this.setState({ loading: false });
          });
      } else {
        this.setState({ color: 'secondary', selected: false });
      }
    }
  }

  render() {
    let text = 'Add';
    let btnClass = 'btn btn-primary';
    if (this.state.loading) {
      text = (
        <>
          Adding
          <div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </>
      );
    } else if (this.state.selected) {
      btnClass = 'btn btn-outline-primary';
      text = (
        <span className="font-italic fw-normal">
          Added
          <FontAwesomeIcon
            icon={faCheck}
            className="ms-2"
          />
        </span>
      );
    }
    return (
      <button
        type="button"
        id="doctor-add-button"
        className={`${btnClass} provider-search-select fw-bold`}
        style={{ minWidth: '94px' }}
        color={this.state.color}
        disabled={this.state.loading}
        onClick={() => this.setSelect(!this.state.selected, true)}
      >
        {text}
      </button>
    );
  }
}

DoctorSelectButton.defaultProps = {
  selected: false,
};

function mapStateToProps(store) {
  return {
    doctorPlansLoaded: store.loaders.doctorPlansLoaded,
    anonymous: store.shopper.anonymous,
    username: store.shopper.username,
    doctorList: store.shopper.doctorList,
  };
}

export default connect(mapStateToProps)(DoctorSelectButton);
