/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

// Actions
import { agentResetPassword, agentLogout } from '../../../actions/agent';

// Utilities
import { showPopup } from '../../../utils/popup';

// Components
import StandardModal from '../../common/StandardModal';
import LoaderButton from '../../../common/components/LoaderButton';

function PasswordResetModal() {
  const dispatch = useDispatch();
  const {
    agent = {},
    theme = {},
    modal = {},
  } = useSelector(store => store);
  const {
    passwordExpired,
    hasTemporaryPassword,
    agentNeedsPasswordReset,
    daysTilReset,
  } = agent;
  // note(joeysapp): These warnings were/are not currently being displayed
  const { passwordResetWarning, passwordResetWarning0Days } = theme;
  const { passwordResetModal } = modal;
  const hostname = process.env.REACT_APP_HOSTNAME || window.location.hostname;

  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerify, setNewPasswordVerify] = useState('');

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState('');
  const [success, setSuccess] = useState('');

  function handleLogout() {
    agentLogout();
  }

  function handleClose() {
    setEmail('');
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordVerify('');
    setError('');
    setSuccess('');
    dispatch({ type: 'HIDE_MODAL', payload: 'passwordResetModal' });
  }

  function validate() {
    if (email === '') {
      setError('Email must be provided.');
      return false;
    } else if (currentPassword ==  '') {
      setError('Current password must be provided.');
      return false;
    }

    if (newPassword === newPasswordVerify) {
      if ((newPassword.length < 8) || (newPassword.length > 20)) {
        setError('Passwords must be between 8 - 20 characters.');
        return false;
      }

      // verify password contains uppercase character, lowercase character, number, and special character
      const lowercaseVerify = newPassword.search(/[a-z]/);
      const uppercaseVerify = newPassword.search(/[A-Z]/);
      const numberVerify = newPassword.search(/[0-9]/);
      const specialCharacterVerify = newPassword.search(/[^a-zA-Z0-9]/);
      let number = 0;

      if (lowercaseVerify !== -1) {
        number += 1;
      }
      if (uppercaseVerify !== -1) {
        number += 1;
      }
      if (numberVerify !== -1) {
        number += 1;
      }
      if (specialCharacterVerify !== -1) {
        number += 1;
      }

      if (number < 3) {
        setError('Password does not contain the necessary characters');
        return false;
      }
    } else {
      setError('New passwords do not match.');
      return false;
    }
    return true;
  }

  function handleSubmit() {
    setError('');
    setSuccess('');
    if (validate()) {
      setLoading(true);
      agentResetPassword(
        email,
        currentPassword,
        newPassword,
      ).then(() => {
        dispatch({ type: 'SET_AGENT', payload: { passwordExpired: false } });
        setSuccess('Successfully updated password! You can now login.');
        handleClose();
        showPopup('Success', 'Succesfully changed password');
      }).catch((err = {}) => {
        const { description, detail } = err;
        const errorToDisplay = detail || description || 'There was an error';
        setError(errorToDisplay);
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  function ToggleVisibility() {
    return (
        <div
          className="position-absolute d-flex align-content-center justify-content-center h-100 fs-4 pe-2"
          style={{ right: 0, zIndex: 10 }}
          onClick={() => setPasswordVisibility(!passwordVisibility)}
          role="button"
          tabIndex={10000}
        >
          {passwordVisibility
           ? (<FontAwesomeIcon className="mt-2" icon={faEyeSlash} />) 
           : (<FontAwesomeIcon className="mt-2" icon={faEye} />)}
        </div>
    );
  }

  const passwordFontSize = passwordVisibility ? '15px' : '24px';
  const inputStyle = { height: '36px', width: '99%' };

  return (
    <StandardModal
      closeIcon={false}
      isOpen={passwordResetModal}
      width='540px'
    >
      <div className="mmb-modal">
        <div className="fw-bold fs-3">
          Change Password
        </div>
        {passwordExpired ? (
          <div className="text-danger mt-2 fs-4 fst-italic">
            Your password has expired. Please set a new password.
          </div>
        ) : null}
        <div>
          <ul className="pt-2 ps-3">
            <li>Your password must contain 8-20 characters.</li>
            <li>Your password must contain 3 of the following:</li>
            <ul className="ps-3">
              <li>Uppercase letter (A-Z)</li>
              <li>Lowercase letter (a-z)</li>
              <li>Numeric character (0-9)</li>
              <li>Special character (!, @, #, $, etc.)</li>
            </ul>
            <li>Your password must be different from your previous 5 passwords.</li>
          </ul>
        </div>
        <form className="w-75">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className="form-control border border-grey w-100"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyle}
              type="email"
            />
          </div>
          <div className="form-group pt-2">
            <label htmlFor="currentPassword">Current password</label>
            <div className="input-group">
              <input
                autoComplete="on"
                className="form-control border border-grey"
                id="currentPassword"
                onChange={(e) => setCurrentPassword(e.target.value)}
                type={passwordVisibility ? 'text' : 'password'}
                style={{ fontSize: passwordFontSize, ...inputStyle }}
                value={currentPassword}
              />
              <ToggleVisibility />
            </div>
          </div>
          <div className="form-group pt-2">
            <label htmlFor="newPassword">New password</label>
            <div className="input-group">
              <input
                autoComplete="on"
                className="form-control border border-grey"
                id="newPassword"
                onChange={(e) => setNewPassword(e.target.value)}
                type={passwordVisibility ? 'text' : 'password'}
                style={{ fontSize: passwordFontSize, ...inputStyle }}
                value={newPassword}
              />
              <ToggleVisibility />
            </div>
          </div>
          <div className="form-group pt-2">
            <label htmlFor="newPasswordVerify">Verify new password</label>
            <div className="input-group">
              <input
                autoComplete="on"
                className="form-control border border-grey"
                id="newPasswordVerify"
                onChange={(e) => setNewPasswordVerify(e.target.value)}
                type={passwordVisibility ? 'text' : 'password'}
                style={{ fontSize: passwordFontSize, ...inputStyle }}
                value={newPasswordVerify}
              />
              <ToggleVisibility />
            </div>
          </div>
        </form>
        <div className="pt-2 ">
          {error ? (<div className="text-danger">{error}</div>) : '' }
          {success ? (<div className="text-success">{success}</div>) : '' }
        </div>
        <div className="d-flex pt-2 w-75">
          <LoaderButton
            className="btn btn-primary fw-bold me-3"
            onClick={handleSubmit}
            type="button"
            loading={loading}
            text={"Change Password"}
          />
          {!passwordExpired ?
            (<button
               className="btn btn-outline-secondary fw-bold w-50"
               onClick={handleClose}
               type="button"
             >
               Cancel
             </button>
            ) : (
              <button
                className="btn btn-outline-secondary fw-bold w-50"
                onClick={handleLogout}
                type="button"
              >
                Logout
              </button>

          )}
        </div>
        <div className="mt-2">
          <a
            href={`${process.env.REACT_APP_MMB_API_URL}/reg/password_reset/?site=${hostname}`}
            className="text-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Forgot your current password?
          </a>
        </div>
      </div>
    </StandardModal>
  );
}

export default PasswordResetModal;
