import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavItem({
  children,
  count,
  dispatch,
  icon,
  id,
  popoverName,
  show,
  setRef,
  title,
}) {
  return (
    <div
      className={show ? 'navbar-si-link-highlighted' : 'navbar-shopper-infobar-link'}
      key="navbar-shopper-infobar-link-medcab"
      ref={setRef}
      title={title}
    >
      {children}
      {
        count ? (
          <div
            onClick={() => dispatch({ type: 'TOGGLE_POPOVER', payload: popoverName })}
            className="navbar-count-badge"
            role="button"
            tabIndex={0}
          >
            {count}
          </div>
        ) : null
      }
      <span
        onClick={() => dispatch({ type: 'TOGGLE_POPOVER', payload: popoverName })}
        className="navbar-shopper-infobar-link-icon"
        id={id}
        role="button"
        tabIndex={0}
      >
        <span className="navbar-shopper-infobar-link-icon-hover">
          <FontAwesomeIcon icon={icon} />
        </span>
      </span>
    </div>
  );
}

NavItem.defaultProps = {
  count: 0,
  icon: {},
  id: '',
  popoverName: '',
  show: false,
  setRef: () => {},
};

function mapStateToProps(store) {
  return {
    somevar: store.somevar,
  };
}

export default connect(mapStateToProps)(NavItem);
