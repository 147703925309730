/* eslint-disable no-return-assign */
import React from 'react';
import Floater from 'react-floater';
import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

class QuestionToolTip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.close = this.close.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    document.body.addEventListener('click', this.close);
  }

  callback(action, data) {
    const { cb } = this.props;
    if (action === 'close') {
      document.body.removeEventListener('click', this.close);
      cb(action, data);
    }
  }

  close() {
    if (this.tooltip) {
      this.tooltip.setState({ status: 'close' });
    }
  }

  render() {
    const {
      content,
      containerStyle,
      iconStyle,
      title,
    } = this.props;

    return (
      <Floater
        ref={(c) => (this.tooltip = c)}
        callback={this.callback}
        placement="right"
        title={title}
        content={HTMLReactParser(content || '')}
        styles={{
          container: { minWidth: '25rem', minHeight: 'none' },
          ...containerStyle,
        }}
      >
        <span
          onClick={this.handleClick}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{
              marginRight: '0.5rem', // Replacing original me-2, move to iconStyle standard use
              ...iconStyle,
            }}
          />
        </span>
      </Floater>
    );
  }
}

QuestionToolTip.defaultProps = {
  title: 'Title',
  content: 'Content',
};

export default QuestionToolTip;
