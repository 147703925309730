import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useSelector, useDispatch } from 'react-redux';

// components
import SlideoutModal from '../../common/components/SlideoutModal';

// hooks
import { useOutsideClick } from '../../common/hooks';

function BenefitCheckbox({ title = '--', checked = false }) {
  const dispatch = useDispatch();
  const { customBenefitsSearchKey, displayedBenefits } = useSelector(store => store.benefits);

  function handleToggle(event) {
    if (displayedBenefits.length < 8 && (event.target.checked || !displayedBenefits.map(benefit => benefit.name).includes(title))) {
      dispatch({
        type: 'ADD_BENEFIT',
        payload: customBenefitsSearchKey.find(benefit => benefit.name === title),
      });
    } else {
      dispatch({
        type: 'DELETE_BENEFIT',
        payload: title,
      });
    }
  }

  return (
    <div
      style={{ display: 'block' }}
      onClick={(e) => handleToggle(e)}
      role="button"
      tabIndex={0}
    >
      <input
        className="benefit-checkbox mt-2 me-2"
        type="checkbox"
        checked={checked}
        readOnly
        disabled={title === '--'}
        style={{
          width: '19px',
          height: '19px',
          accentColor: '#0E96CC',
        }}
      />
      <label
        className="form-check-label fs-5 pe-none user-select-none"
        id="custom-benefit-button-label"
        style={{
          verticalAlign: '.15rem',
          color: title === '--' ? 'var(--bs-gray-500)' : '',
        }}
      >
        {title}
      </label>
    </div>
  );
}

function BenefitsModal() {
  const dispatch = useDispatch();
  const { benefitsModal } = useSelector(store => store.modal);
  const {
    customBenefitsSearchKey = [],
    displayedBenefits = [],
    planType,
  } = useSelector(store => store.benefits);
  const dropdownRef = useRef();
  const [maxHeight, setMaxHeight] = useState();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState([]);

  // array for contructing empty checkboxes
  const emptyCheckboxArray = [1, 2, 3, 4, 5, 6, 7, 8];
  emptyCheckboxArray.length = 8 - displayedBenefits.length;

  // set height of benefits search dropdown
  const bottom = document.getElementById('benefits-sidebar-search-input')?.getBoundingClientRect().bottom;
  useEffect(() => {
    setMaxHeight(window.innerHeight - bottom - 80);
  }, [window.innerHeight, bottom]);

  // close search dropdown with outside click
  const searchRef = useRef(null);
  useOutsideClick(searchRef, () => {
    if (!search) {
      setSearchDropdown([]);
      setShowSearch(false);
    }
  });

  function handleInputChange(event) {
    const { value } = event.target;
    setSearch(value);
    if (value) {
      setSearchDropdown(customBenefitsSearchKey
        .filter(benefit => benefit.planTypes.includes(planType) && benefit.name.toLowerCase().includes(value.toLowerCase()))
        .map(benefit => benefit.name));
    } else {
      setSearchDropdown(customBenefitsSearchKey
        .filter(benefit => benefit.planTypes.includes(planType))
        .map(benefit => benefit.name));
    }
  }

  function handleFocus(event) {
    handleInputChange(event);
    setShowSearch(true);
  }

  function handleClearSearch() {
    setSearch('');
    setSearchDropdown([]);
    setShowSearch(false);
  }

  return (
    <SlideoutModal
      padding="0px"
      style={{ fontFamily: 'Source Sans Pro', minWidth: '260px' }}
      id="benefits-slideout"
      isOpen={benefitsModal}
      closeModalAction={() => dispatch({ type: 'HIDE_MODAL', payload: 'benefitsModal' }) && setSearch('')}
      hideHeader
      width="25%"
      slideInFrom="right"
    >
      <div
        style={{
          paddingTop: '1.1rem',
          paddingRight: '1.7rem',
          paddingLeft: '1.7rem',
          paddingBottom: '1.7rem',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <div>
          <div>
            <FontAwesomeIcon
              role="button"
              style={{
                position: 'absolute',
                right: '1rem',
                top: '1.1rem',
                color: 'var(--bs-gray-500)',
                height: '1.4rem',
                width: '1.4rem',
              }}
              tabIndex={0}
              icon={faTimes}
              onClick={() => dispatch({ type: 'HIDE_MODAL', payload: 'benefitsModal' }) && setSearch('')}
            />
            <div className="fw-bold" style={{ fontSize: '1.12rem', marginTop: '1.4rem' }}>
              {`Customize Benefits – ${planType}`}
            </div>
          </div>
          <div className="my-2">
            {`Choose up to 8 benefits you want displayed for ${planType} plans.`}
          </div>
          <div className="my-2" style={{ color: 'var(--bs-gray-600)' }}>
            {`${displayedBenefits.length} of 8 selected.`}
          </div>
          {
            displayedBenefits.map((benefit) => <BenefitCheckbox title={benefit.name} key={benefit.name} checked />)
          }
          {displayedBenefits.length < 8 && (
            emptyCheckboxArray.map(e => <BenefitCheckbox key={e} />)
          )}
          <div ref={searchRef}>
            <div
              className="input-group position-relative mb-2 mt-3"
              style={{ background: 'var(--bs-gray-100)' }}
            >
              <input
                id="benefits-sidebar-search-input"
                className="form-control shadow-fix"
                placeholder="Select benefits..."
                onChange={(e) => handleInputChange(e)}
                onFocus={(e) => handleFocus(e)}
                value={search}
                type="text"
                disabled={displayedBenefits.length >= 8}
                style={{
                  backgroundColor: 'var(--bs-gray-100)',
                  color: 'var(--bs-gray-900)',
                  borderColor: 'var(--bs-gray-200)',
                }}
              />
              {
                !search
                  ? (
                    <span
                      style={{
                        paddingRight: '8px',
                        paddingTop: '7px',
                        borderColor: 'var(--bs-gray-200)',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        zIndex: '1',
                      }}
                    >
                      <i className="fa fa-lg fa-search" style={{ color: 'var(--bs-gray-500)' }} />
                    </span>
                  )
                  : (
                    <button
                      type="button"
                      style={{
                        paddingRight: '8px',
                        paddingTop: '7px',
                        borderColor: 'var(--bs-gray-200)',
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        zIndex: '1',
                        backgroundColor: 'var(--bs-gray-100)',
                      }}
                      onClick={() => handleClearSearch()}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} size="lg" style={{ color: 'var(--bs-gray-600)' }} />
                    </button>
                  )
              }
            </div>
            {showSearch && displayedBenefits.length < 8 && (
              <ul
                className="list-group"
                id="search-dropdown-list"
                ref={dropdownRef}
                style={{
                  border: 'solid 1px var(--bs-gray-500)',
                  boxShadow: '0px 0px 8px #0000001F',
                  maxHeight: `${maxHeight}px`,
                  overflowY: 'auto',
                }}
              >
                {searchDropdown.length ? (
                  searchDropdown.map((result, idx) => {
                    if (idx !== 0) {
                      return (
                        <div
                          className="p-1"
                          key={result}
                          style={{ borderTop: '1px solid #CED4DA' }}
                        >
                          <BenefitCheckbox
                            title={result}
                            checked={displayedBenefits.map(benefit => benefit.name).indexOf(result) !== -1}
                          />
                        </div>
                      );
                    }
                    return (
                      <div className="p-1" key={result}>
                        <BenefitCheckbox
                          title={result}
                          checked={displayedBenefits.map(benefit => benefit.name).indexOf(result) !== -1}
                        />
                      </div>
                    );
                  })) : <li className="list-group-item">No results found</li>}
              </ul>
            )}
          </div>
          {displayedBenefits.length >= 8 ? (
            <div style={{ color: 'var(--bs-gray-600)', fontSize: '.95rem' }}>
              The maximum amount of benefits has been selected. Deselect one to search for another.
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <div
            className="mmb-link"
            onClick={() => dispatch({ type: 'RESET_BENEFITS', planType })}
            role="button"
            tabIndex={0}
            style={{ textAlign: 'center', marginTop: '1rem' }}
          >
            Restore default benefits
          </div>
        </div>
      </div>
    </SlideoutModal>
  );
}

export default BenefitsModal;
