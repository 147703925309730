import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import CreateBaseShopper from './CreateBaseShopper';
import OpenTelephony from '../../common/OpenTelephony';
import {
  TPMODisclaimer,
  TPMOExplanation,
  Collapse,
} from '../../../common/components';

import './styles.scss';

function ThreeWayCallsDisclaimer({ meetType }) {
  const isThreeWay = (meetType === 'telephonicThreeWay');
  const { threeWayCallEntryPhone = 'xxx-xxx-xxxx' } = useSelector(store => store.theme);

  return (
    <div
      className="animation-open"
      style={{
        transitionDuration: '1s',
        maxHeight: isThreeWay ? '70vh' : '0',
        opacity: isThreeWay ? 1 : 0,
        overflowY: 'auto',
      }}
    >
      <div className="row mt-3">
        <div className="col-12">
          <p>Telephonic: 3-way call recording using your own phone</p>
          <p>
            Use 3-way calling to record your client meeting using your own phone. This solution complies with CMS regulatory guidelines
            and allows you to record calls using our HIPAA and CMS compliant tool.  Calls are recorded in full and saved to your account
            for future retrieval.  You can initiate 3-way call recording before or after you connect with your client in 3 easy steps.
          </p>
          <ol>
            <li>Initiate the 3-way call</li>
            <ol type="a">
              <li>If you are already on the phone with your client, put your client on hold and dial {threeWayCallEntryPhone}.</li>
              <li>You can also begin recording before you call your client. Dial {threeWayCallEntryPhone} to get started.</li>
            </ol>
            <li>
              Follow the prompt and enter your NPN, your client’s name and your client’s phone number. This information is used
              so you can retrieve the call recording later.
            </li>
            <li>Connect the call</li>
            <ol type="a">
              <li>
                If you are already on the phone with your client the call, using your 3-way call function on your phone simply “merge” the calls.
                Once connected to your client, please inform your client that the call is being recorded.
              </li>
              <li>
                If you need to call your client, simply put the 3-way Recording system on hold and dial your client.
                Once your client is connected, you can “merge” the call. Once connected to your client, please inform your client that the call
                is being recorded.
              </li>
            </ol>
          </ol>
          <i>
            You are responsible for keeping track of all 3-way call recordings with your clients,
            especially if you intend to capture a voice scope or submit an enrollment.
          </i>
        </div>
      </div>
    </div>
  );
}

// setValue is passed in from form.jsx -> index.jsx -> CreateBaseShopper.jsx
function Telephony({ setValue }) {
  const dispatch = useDispatch();
  const { username, county, effectiveDate } = useSelector(store => store.shopper);
  const { meetType } = useSelector(store => store.telephony);
  const { callRecording, carrierPermissions } = useSelector(store => store.agent);
  const { threeWayCall } = useSelector(store => store.theme.features);
  const planYear = moment(effectiveDate).year();
  const [ tpmoExplanationOpen, setTpmoExplanationOpen ] = useState(false);

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="tel-box p-3 shadow-sm r-1">
          <div className="mb-2">
            What kind of meeting are you having with this client?
            <span className="text-danger fw-bold ms-1 mb-6">*</span>
          </div>
          {threeWayCall && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="meetType"
              id="telephonicThreeWay"
              checked={meetType === 'telephonicThreeWay'}
              disabled={!callRecording}
              onChange={() => (
                dispatch({ type: 'SET_MEET_TYPE', value: 'telephonicThreeWay' })
              )}
            />
            <label className="form-check-label" htmlFor="telephonicThreeWay" style={{ cursor: 'pointer' }}>
              Telephonic: 3-way call recording using my phone
            </label>
            {!callRecording && (
              <span className="ms-2">
                <a
                  style={{ color: 'var(--bs-blue)', opacity: '1 !important' }}
                  className="text-decoration-underline"
                  href="/3-way-calls"
                  target="_blank"
                >
                  Enable 3-way-calling
                </a>
              </span>
            )}
          </div>
          )}
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="meetType"
              id="telephonic"
              checked={meetType === 'telephonic'}
              onChange={() => (
                dispatch({ type: 'SET_MEET_TYPE', value: 'telephonic' })
              )}
            />
            <label className="form-check-label" htmlFor="telephonic" style={{ cursor: 'pointer' }}>
              Telephonic: MMB softphone
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="meetType"
              id="inPerson"
              checked={meetType === 'inPerson'}
              onChange={() => (
                dispatch({ type: 'SET_MEET_TYPE', value: 'inPerson' })
              )}
            />
            <label className="form-check-label" htmlFor="inPerson" style={{ cursor: 'pointer' }}>
              In-person
            </label>
          </div>
          <div
            className="animation-open"
            style={{
              transitionDuration: '1s',
              maxHeight: meetType === 'telephonic' ? '30vh' : '0',
              opacity: meetType === 'telephonic' ? 1 : 0,
            }}
          >
            {!username && (
              <CreateBaseShopper setValue={setValue} />
            )}
            {username && (
              <>
                <OpenTelephony setValue={setValue} />
                <div className="row mt-3">
                  <div className="col-12">
                    <i>
                      {`To continue, enter your client's phone number and click the button above to start a call.
                      Only 1 call window can be open at a time. If you can't find it, remember that it's a 
                      separate window, and it may be hidden behind other windows on your screen.`}
                    </i>
                  </div>
                </div>
              </>
            )}
          </div>
          <ThreeWayCallsDisclaimer meetType={meetType} />
        </div>
      </div>

      <div className="col-md-5">
        <div
          className="p-3 shadow"
          style={{
            border: 'solid 1px var(--bs-primaryFocusBorder)',
            backgroundColor: 'var(--bs-primaryFocusBackground)',
          }}
        >
          <div className="d-flex justify-content-between align-items-start pb-1">
            <span className="fw-bold pt-1">
              TPMO Disclaimer
            </span>
            <button
              type="button"
              className="btn btn-link p-0 align-top mb-1 text-blue"
              onClick={() => setTpmoExplanationOpen(!tpmoExplanationOpen)}
            >
              How is this calculated?
            </button>
          </div>
          <p className="mb-0">
            <TPMODisclaimer
              carrierPermissions={carrierPermissions}
              countyFips={county}
              planYear={planYear}
              selfAttest={!county}
            />
          </p>
          <Collapse isOpen={tpmoExplanationOpen}>
            <p className="mt-2 mb-0">
              <TPMOExplanation className="fst-italic text-muted fs-5" />
            </p>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default Telephony;
