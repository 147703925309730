import React from 'react';

function CheckOption({
  label,
  onChange,
  id,
  checked,
}) {
  return (
    <span className="d-flex align-items-center">
      <input
        className="form-control-input me-3"
        onChange={onChange}
        type="checkbox"
        id={id}
        checked={checked}
      />
      <label
        className="form-control-label"
        htmlFor={`planlist-carrier-filter-${label}`}
      >
        {label}
      </label>
    </span>
  );
}

export default CheckOption;
