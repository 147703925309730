// eslint-disable-next-line import/no-cycle
import { mapListSort } from '../utils/general';

const initialState = {
  state: {
    editing: false,
    adding: false,
    erroring: false,
    records: [],
    newEntry: {},
    sortBy: 'state',
  },
  carrier: {
    editing: false,
    adding: false,
    erroring: false,
    records: [],
    newEntry: {},
    sortBy: 'plan_year',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EDIT_PERMISSIONS': {
      const permissions = { ...state[action.level], editing: action.open };
      return { ...state, [action.level]: permissions };
    }
    case 'SET_ADD_PERMISSION': {
      const permissions = { ...state[action.level], adding: action.open };
      return { ...state, [action.level]: permissions };
    }
    case 'LOAD_PERMISSIONS': {
      // Convert states to caps.
      const records = action.records.map((row) => {
        const permission = { ...row };
        if ('state' in permission) {
          permission.state = permission.state.toUpperCase();
        }
        return permission;
      });
      mapListSort(records, state[action.level].sortBy);
      const permissions = { ...state[action.level], records };
      return { ...state, [action.level]: permissions };
    }
    case 'SET_PERMISSIONS_SORT': {
      const permissions = { ...state[action.level], sortBy: action.sortBy };
      const records = [...permissions.records];
      mapListSort(records, action.sortBy);
      return { ...state, [action.level]: permissions };
    }
    case 'EDIT_PERMISSION': {
      const { index: i } = action;
      const permissions = { ...state[action.level] };
      const records = [...permissions.records];

      records[i] = { ...records[i], [action.id]: action.value };
      permissions.records = records;

      return { ...state, [action.level]: permissions };
    }
    case 'EDIT_NEW_PERMISSION': {
      const permissions = { ...state[action.level] };
      permissions.newEntry = { ...permissions.newEntry, [action.id]: action.value };

      return { ...state, [action.level]: permissions };
    }
    case 'SET_PERMISSION_ERROR': {
      const permissions = { ...state[action.level], erroring: action.erroring };
      return { ...state, [action.level]: permissions };
    }
    case 'CLOSE_MODAL':
    case 'CLEAR_PERMISSIONS':
      return { ...initialState };
    default:
      return state;
  }
};
