/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SUCCESS_STATE } from '../../../../constants';

import { getFilterList } from '../../../../utils/plans';

// import { getPlanCarrierDisplay } from '../../../../utils/general';

const CurrentPlan = ({
  className, style, id, disabled,
}) => {
  const dispatch = useDispatch();
  const [carriers, setCarriers] = useState(['All']);
  const { planListFilterType: filterType = 'carrier' } = useSelector(store => store.theme.features);
  const planTypes = ['All', 'MA', 'MAPD', 'PDP'];

  const [planCarrier, setPlanCarrier] = useState('All');
  const [planType, setPlanType] = useState('All');
  const [planName, setPlanName] = useState('');

  const shopper = useSelector(store => store.shopper);
  const { medicareList = [] } = useSelector(store => store.plan);
  const { medicareListLoaded } = useSelector(store => store.loaders);

  useEffect(() => {
    if (medicareList.length
      && (medicareListLoaded === SUCCESS_STATE)
      && (shopper.zipcode.length === 5)
    ) {
      let carrierList = typeof medicareList === 'object' ? getFilterList({ planList: medicareList, filterType }) : [];
      // Ensure the carrier/brand list is always sorted
      carrierList = carrierList.sort((carrierOne, carrierTwo) => {
        let c1 = carrierOne;
        let c2 = carrierTwo;
        // Handle DR names with quotations / any non-alphanums
        if (!/^[a-z0-9]+$/i.test(c1.at(0))) c1 = carrierOne.slice(1);
        if (!/^[a-z0-9]+$/i.test(c2.at(0))) c2 = carrierTwo.slice(1);
        return c1.localeCompare(c2);
      });
      setCarriers([
        'All',
        ...carrierList,
      ]);
    }
  }, [shopper.county, shopper.subsidyLevel, shopper.subsidyPercent, shopper.effectiveDate]);

  useEffect(() => {
    if (medicareList.length > 0 && shopper.currentPlan) {
      // Temporarily handle medicareList being set incorrectly
      if (typeof medicareList !== 'object') return;

      const currentPlan = medicareList.filter(p => p.cpsID === shopper.currentPlan)[0];
      if (currentPlan) {
        setPlanName(currentPlan.cpsID);
        setPlanCarrier(currentPlan.carrier || currentPlan.brand);
        setPlanType(currentPlan.planType);
        dispatch({ type: 'SET_COMPARE_FOOTER', payload: true });
      }
    }
  }, [medicareList]);

  return (
    <div
      className={`${className} col mt-3`}
      id={id}
      style={style}
    >
      <i>Current plan</i>
      <div className="row mt-2 ps-2">
        <div className="col-md-3">
          Carrier
          <div className="mmb-select mt-1">
            <select
              id="shopper-current-plan-carrier-select"
              className="form-control border border-grey"
              style={{ cursor: disabled ? 'auto' : 'pointer' }}
              name="carrierName"
              onChange={e => {
                setPlanCarrier(e.target.value);
                setPlanType('All');
                setPlanName('');
              }}
              type="select"
              disabled={disabled || !medicareList.length}
              value={planCarrier}
            >
              {carriers.map(c => (
                <option
                  key={`carrier-${c}`}
                  value={c}
                >
                  {c}
                </option>
              ))}
            </select>
            <i className="fa fa-chevron-down" style={{ fontSize: '15px', marginLeft: '-25px', pointerEvents: 'none' }} />
          </div>
        </div>
        <div className="col-md-3">
          Plan type
          <div className="mmb-select mt-1">
            <select
              id="shopper-current-plan-carrier-select"
              className="form-control border border-grey"
              style={{ cursor: disabled ? 'auto' : 'pointer' }}
              name="carrierName"
              onChange={e => {
                setPlanType(e.target.value);
                setPlanName('');
              }}
              type="select"
              disabled={disabled || !medicareList.length}
              value={planType}
            >
              {planTypes.map((option) => {
                // Temporarily handle medicareList being set incorrectly
                if (typeof medicareList !== 'object') return;

                const filteredPlans = medicareList.filter(p => {
                  const filteredPlanCarrier = p.carrier === planCarrier || p.brand === planCarrier;
                  const filteredPlanType = p.planType === option;
                  return filteredPlanCarrier && filteredPlanType;
                });
                const carrierHasPlanType = filteredPlans.length > 0;
                if (planCarrier !== 'All' && !carrierHasPlanType && option !== 'All') return;
                return (
                  <option
                    key={`plantype-${option}`}
                    value={option}
                  >
                    {option}
                  </option>
                );
              })}
            </select>
            <i className="fa fa-chevron-down" style={{ fontSize: '15px', marginLeft: '-25px', pointerEvents: 'none' }} />
          </div>
        </div>
        <div className="col-md-3">
          Plan name
          <div className="mmb-select mt-1">
            <select
              id="shopper-current-plan-carrier-select"
              className="form-control border border-grey disabled"
              style={{ cursor: disabled ? 'auto' : 'pointer' }}
              name="carrierName"
              onChange={e => {
                setPlanName(e.target.value);
                if (e.target.value.length) {
                  dispatch({ type: 'SET_COMPARE_FOOTER', payload: true });
                } else {
                  dispatch({ type: 'SET_COMPARE_FOOTER', payload: false });
                }
                dispatch({
                  type: 'UPDATE_SHOPPER',
                  payload: { currentPlan: e.target.value },
                });
              }}
              type="select"
              disabled={disabled || !medicareList.length}
              value={planName}
              placeholder="Select a plan..."
            >
              <option key="All" value={''}>--</option>
              {typeof medicareList === 'object' && medicareList.sort((p1, p2) => p1.planName.localeCompare(p2.planName)).map(p => {
                if (planType !== 'All' && planType !== p.planType) return;
                if (planCarrier !== 'All' && planCarrier !== p.carrier && planCarrier !== p.brand) return;
                return (
                  <option key={`planname-${p.cpsID}`} value={p.cpsID}>
                    {p.planName}
                  </option>
                );
              })}
            </select>
            <i className="fa fa-chevron-down" style={{ fontSize: '15px', marginLeft: '-25px', pointerEvents: 'none' }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CurrentPlan;
