import React from 'react';
import { toast } from 'react-toastify';

export function constructErrorElement(errorContent) {
  let errorStatusCode;
  let errorData;
  let errorElement;

  if (errorContent.response) {
    errorStatusCode = errorContent.response.status;
    if (errorContent.response.data) {
      errorElement = (
        <span className="bg-white m-1 p-2 small">
          {
            Object.keys(errorData).forEach((key) => (
              <span>
                <b>{key}:</b> {errorData[key]}<br />
              </span>
            ))
          }
        </span>
      );
    }
  } else if (errorContent.length) {
    errorElement = (
      <span className="bg-white m-1 p-2 small">
        {
          errorContent.map((e) => (
            <span>
              {e}<br />
            </span>
          ))
        }
      </span>
    );
  } else {
    errorElement = (
      <span className="bg-white m-1 p-2 small text-body ">
        {errorContent}
      </span>
    );
  }

  /* eslint-disable implicit-arrow-linebreak */
  return {
    errorStatusCode,
    errorElement,
  };
}


export function showPopup(headerText, bodyText, popupType = null, errorContent = null, options = { autoClose: 5000 }) {
  const { errorElement } = errorContent ? constructErrorElement(errorContent) : {};
  const toastElement = (
    <div
      className="d-flex flex-column p-1"
      style={{ cursor: 'auto' }}
    >
      <div className="h3">{headerText}</div>
      <div className="d-flex flex-column">
        {bodyText}
        {errorElement}
      </div>
    </div>
  );

  // TODO(@joeysapp): Above functionality should be toast-agnostic
  const toastOptions = {
    ...options,
  };
  const toastToShow = popupType || headerText;

  switch (toastToShow.toLowerCase()) {
    case 'error':
      toast.error(toastElement, toastOptions);
      break;
    case 'info':
      toast.info(toastElement, toastOptions);
      break;
    case 'success':
      toast.success(toastElement, toastOptions);
      break;
    default:
      toast.success(toastElement, toastOptions);
      break;
  }
}
