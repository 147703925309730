/* eslint-disable eqeqeq */
import React from 'react';
import { useSelector } from 'react-redux';

import { Radio, FormLabel } from '../components/inputs';
import { Collapse } from '../../../common/components';

function Help({ pageTitle }) {
  const {
    help_bath,
    help_groom,
    help_restroom,
    help_eat,
    help_dress,
    help_move,
    help_transfer,
    help_meds,
    help_finance,
  } = useSelector(store => store.hra.fields);

  return (
    <div>
      <div className="fw-bold">{pageTitle}</div>
      <div className="d-flex flex-column">
        <FormLabel name="help_text" id={1} disabled className="fw-bold fs-4 text-secondary" />
        <div className="ms-2 ps-2 border-start">
          <Radio
            name="help_bath"
            className="d-flex"
          />
          <Radio
            name="help_eat"
            className="d-flex"
          />
          <Radio
            name="help_dress"
            className="d-flex"
          />
          <Radio
            name="help_groom"
            className="d-flex"
          />
          <Radio
            name="help_finance"
            className="d-flex"
          />
          <Radio
            name="help_move"
            className="d-flex"
          />
          <Radio
            name="help_meds"
            className="d-flex"
          />
          <Radio
            name="help_transfer"
            className="d-flex"
          />
          <Radio
            name="help_restroom"
            className="d-flex"
          />
        </div>
        <Collapse isOpen={[help_bath, help_groom, help_restroom, help_eat, help_dress, help_move, help_transfer, help_meds, help_finance].indexOf(true) !== -1}>
          <Radio
            name="satisfied_help"
            id="1b"
            labelClassName="fw-bold fs-4 text-secondary"
          />
        </Collapse>
        <Collapse isOpen={[help_bath, help_groom, help_restroom, help_eat, help_dress, help_move, help_transfer, help_meds, help_finance].indexOf(true) !== -1}>
          <FormLabel name="who_helps_text" id="1c" disabled className="fw-bold fs-4 text-secondary" />
          <div className="ms-2 ps-2 border-start">
            <Radio name="spouse_helps" className="d-flex" />
            <Radio name="guard_helps" className="d-flex" />
            <Radio name="family_helps" className="d-flex" />
            <Radio name="friend_helps" className="d-flex" />
            <Radio name="caregiver_helps" className="d-flex" />
            <Radio name="other_helps" className="d-flex" />
            <Radio
              name="dont_need_help"
              className="d-flex"
            />
          </div>
        </Collapse>
        <Radio name="provide_care" id={2} className="d-flex" labelClassName="fw-bold fs-4 text-secondary" />
        <Radio name="falls" id={3} className="d-flex" labelClassName="fw-bold fs-4 text-secondary" />
        <Radio name="pain_scale" id={4} className="d-flex" labelClassName="fw-bold fs-4 text-secondary" />
      </div>
    </div>
  );
}

export default Help;
