const initialState = {
  reports: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOAD_REPORTS':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_REPORTS':
      return { ...initialState };
    default:
      return state;
  }
}
