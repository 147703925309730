import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import MarkdownContent from '../common/MarkdownContent';

// todo(@joeysapp): This entire deal needs to be addressed in
// [MYR-3932] Agent Portal - Update/address /releasenotes
function ReleaseNotesModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    releaseNotesOpen: isOpen,
    releaseNotesStale: isStale,
  } = useSelector(store => store.session);
  const { threeWayExploreMoreLink } = useSelector(store => store.theme);
  const { threeWayCall } = useSelector(store => store.theme.features);
  const [ checked, setChecked ] = useState(false);

  function checkbox(event) {
    setChecked(event.target.checked);
  }

  function close() {
    if (checked) {
      // Block the modal until the next release.
      dispatch({ type: 'EXPIRE_RELEASE_NOTES' });
    }
    dispatch({ type: 'HIDE_RELEASE_NOTES' });
  }

  function getStarted() {
    navigate('/3-way-calls');
    close();
  }

  return (
    <Modal
      isOpen={
      isOpen
        && !isStale
        && location.pathname !== '/'
        && !/mobile/.test(location.pathname)
      }
      toggle={() => close()}
      className="release-notes-modal"
      size="lg"
      style={{ maxHeight: '60vh', overflowY: 'auto' }}
    >
      <ModalBody className="p-5">
        <MarkdownContent content="rnotes" length="1" />
        {threeWayCall && (
          <>
            <div>3-way call recording using your phone. Stay compliant. Anywhere. Anytime.</div>
            <div>
              <a
                style={{ fontWeight: 'bold', color: '#4682b4' }}
                href={threeWayExploreMoreLink}
                target="_blank"
                rel="noreferrer"
              >
                Explore more
              </a>
              {' or '}
              <b
                role="link"
                tabIndex="0"
                style={{ cursor: 'pointer', color: '#4682b4' }}
                onClick={() => getStarted()}
              >
                Get started
              </b>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between px-5 py-4" style={{ fontSize: '1rem' }}>
        <div className="col">
          <Link id="full-release-notes-link" to="/releasenotes" onClick={() => close()}>
            Click here for full release notes.
          </Link>
          <div className="form-check form-switch mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="staleNotesToggle"
              onChange={(e) => checkbox(e)}
            />
            <label className="form-check-label">
              {'Don\'t show this message until next update.'}
            </label>
          </div>
        </div>
        <button
          type="button"
          id="close-release-notes"
          style={{ minWidth: '120px' }}
          className="btn btn-danger fw-bold text-white"
          onClick={() => close()}
          tabIndex={0}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ReleaseNotesModal;
