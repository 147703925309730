import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { faCommentAltDollar } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import NavItem from './NavItem';
import NavPopover from './NavPopover';

function Quotes({
  quotes,
  showQuotesPopover,
  disabled,
}) {
  const [parentRef, setParentRef] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleShow(quoteID) {
    navigate('/quotes');
    // todo(joeysapp): Add in /quotes/:quoteID \or\ /quotes&id=, ask Caleb what's preferred
    dispatch({ type: 'SET_EVENT', payload: { name: 'select-quote', payload: quoteID } });
  }

  return (
    <NavItem
      show={showQuotesPopover}
      icon={faCommentAltDollar}
      popoverName="showQuotesPopover"
      setRef={setParentRef}
      count={quotes.length}
      title={'View quotes'}
    >
      <NavPopover
        show={showQuotesPopover}
        popoverName="showQuotesPopover"
        parentRef={parentRef}
      >
        <b>{quotes.length} Quotes</b>
        <div className="col navbar-drug-card-container mt-3">
          {
            quotes && quotes.length > 0
              ? (
                quotes.map((quote, ii) => (
                  <div
                    key={quote.quoteID}
                    className={`row navbar-drug-card ${disabled ? 'disabled' : ''}`}
                    role="button"
                    tabIndex={ii}
                    onClick={() => {
                      if (!disabled) handleShow(quote.quoteID);
                    }}
                  >
                    <div className="col py-2 mb-2">
                      <b>{quote.planName}</b>
                      <br />
                      <i>
                        {moment(quote.created).format('MM/DD/YYYY h:mma')}
                      </i>
                      <br />
                    </div>
                  </div>
                ))
              )
              : (
                <div className="row navbar-drug-card text-center align-middle">
                  <div className="col py-3">
                    <i>Looks like no quotes have been made yet.</i>
                  </div>
                </div>
              )
          }
        </div>
        <button
          type="button"
          className="btn btn-primary fw-bold mt-3"
          disabled={(quotes && quotes.length === 0) || disabled}
          title={quotes && quotes.length === 0 ? 'Make a quote to view your past quotes.' : ''}
          onClick={() => {
            dispatch({ type: 'SET_EVENT', payload: { name: 'select-quote', payload: 'first_position' } });
            navigate('/quotes');
            dispatch({ type: 'HIDE_POPOVER', payload: 'showQuotesPopover' });
          }}
        >
          View quotes
        </button>
      </NavPopover>
    </NavItem>
  );
}

function mapStateToProps(store) {
  return {
    showQuotesPopover: store.popover.showQuotesPopover,
    quotes: store.shopper.quotes,
  };
}

export default connect(mapStateToProps)(withRouterProps(Quotes));
