import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithTracker from '../common/WithTracker';

// Components
import QuotesList from './list';
import ShareQuotePage from './share';
import ExportEnrollment from './export';

export default function () {
  return (
    <WithTracker>
      <Routes>
        <Route path="/" element={<QuotesList />} />
        <Route path="/:quoteID" element={<QuotesList />} />
        <Route path="/share" element={<ShareQuotePage />} />
        <Route path="/export/:quoteID" element={<ExportEnrollment />} />
      </Routes>
    </WithTracker>
  );
}
