/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

class DsnpDisclaimer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isClosing: false,
      isVisible: true,
    };
  }

  render() {
    const {
      disclaimer,
      plans,
    } = this.props;

    const {
      isClosing,
      isVisible,
    } = this.state;

    let containsDsnp = false;
    plans.forEach((p) => {
      if (p.plan) {
        if (p.plan.SNPIND === 2) {
          containsDsnp = true;
        }
      }
    });

    const closingTime = 250;
    if (disclaimer && containsDsnp) {
      return isVisible
        ? (
          <div
            className="alert alert-warning alert-dismissible ms-4 me-4"
            style={{
              height: 'auto',
              maxHeight: isClosing ? '0px' : '6em',
              paddingTop: isClosing ? '0' : '',
              paddingBottom: isClosing ? '0' : '',
              marginBottom: isClosing ? '0' : '',
              border: isClosing ? '0' : '',
              minHeight: '0',
              overflow: 'hidden',
              transition: `all ${closingTime}ms ease`,
            }}
          >
            <span
              className="position-absolute"
              onClick={() => {
                this.setState({ isClosing: true });
                window.setTimeout(() => {
                  this.setState({ isVisible: false });
                }, closingTime * 1.25);
              }}
              role="button"
              style={{
                cursor: 'pointer',
                fontSize: '22px',
                right: '16px',
                top: '8px',
              }}
              tabIndex={0}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <span
              className="small"
              style={{ opacity: isClosing ? '0' : '', transition: `all ${closingTime}ms ease` }}
            >
              <b>D-SNP Disclaimer:</b>
              {` ${disclaimer}`}
            </span>
          </div>
        ) : null;
    }
    return null;
  }
}

DsnpDisclaimer.defaultProps = {
  plans: [],
};

function mapStateToProps(store) {
  return {
    disclaimer: store.theme.disclaimerDSNP || '',
  };
}

export default connect(mapStateToProps)(DsnpDisclaimer);
