/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStarHalf,
  faStar,
} from '@fortawesome/pro-solid-svg-icons';

const STAR_STYLES = {
  stroke: 'rgba(0, 0, 0, 0.2)',
  strokeWidth: '32px',
  marginRight: '2px',
  // FontAwesome verticalAlign must be overridden
  verticalAlign: '-0.25rem',
};

function StarFilled({ hoverText }) {
  return (
    <span>
      <FontAwesomeIcon
        title={hoverText}
        icon={faStar}
        style={{
          color: 'var(--bs-faStarGold)',
          ...STAR_STYLES,
        }}
      />
    </span>
  );
}

function StarUnfilled({ hoverText }) {
  return (
    <span>
      <FontAwesomeIcon
        title={hoverText}
        icon={faStar}
        style={{
          color: 'rgba(0, 0, 0, 0.1)',
          ...STAR_STYLES,
        }}
      />
    </span>
  );
}

function StarHalf({ hoverText }) {
  return (
    <span>
      <span className="position-absolute">
        <FontAwesomeIcon
          title={hoverText}
          icon={faStarHalf}
          style={{
            color: 'var(--bs-faStarGold)',
            ...STAR_STYLES,
          }}
        />
      </span>
      <span>
        <FontAwesomeIcon
          title={hoverText}
          icon={faStarHalf}
          style={{
            // Flip our half-star
            color: 'rgba(0, 0, 0, 0.1)',
            transform: 'scaleX(-1)',
            ...STAR_STYLES,
          }}
        />
      </span>
    </span>
  );
}

function StarRating({ plan = {} }) {
  const {
    starRating,
    plan: docurobotPlan = {},
  } = plan;

  const [score, setScore] = useState(0);
  useEffect(() => {
    const { OverallStarRatingDesc } = docurobotPlan;
    setScore(starRating < 0 ? OverallStarRatingDesc : starRating);
  }, [starRating]);

  const hoverText = score >= 0 ? `Plan rating: ${score.toString()}/5` : 'Plan rating: N/A';

  if (typeof score === 'number' && score >= 0 && score <= 5) {
    const stars = [];
    for (let idx = 1; idx <= 5; idx++) {
      if (score >= idx) {
        stars.push(<StarFilled key={`star-${idx}`} hoverText={hoverText} />);
      } else if (score === (idx - 0.5)) {
        stars.push(<StarHalf key={`star-${idx}`} hoverText={hoverText} />);
      } else {
        stars.push(<StarUnfilled key={`star-${idx}`} hoverText={hoverText} />);
      }
    }
    return stars;
  }
  // Just a star description
  return <span className="text-muted">{score}</span>;
}

export default StarRating;
