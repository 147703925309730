import axios from 'axios';
import { store } from '../store';

// Utilities
import { showPopup } from '../utils/popup';
import { setEnrollmentToken } from '../common/utils/cookie';

export function fmtQuoteToJS(data) {
  const rfmData = {
    agentWhoUpdated: data.agent_who_updated,
    brand: data.plan_brand,
    carrierID: data.carrier_id,
    calculatedPremium: data.calculated_premium,
    carrier: data.plan_carrier,
    confirmationNumber: data.confirmation_number,
    cpsID: data.cps_id || null,
    created: data.created,
    discountAgeAZ: data.discount_age_az,
    discountHousehold: data.discount_household,
    discountMonthlyBankDraft: data.discount_monthly_bank_draft,
    discountNewToMedicare: data.discount_new_to_medicare,
    effectiveDate: data.effective_date,
    enrollment: data.enrollment,
    hra: data.hra,
    extraBenefits: data.extra_benefits,
    org: data.plan_org,
    orgID: data.org_id,
    osbCost: data.osb_amount,
    osbID: data.osb_id,
    osbName: data.osb_name,
    planID: data.plan_id,
    planName: data.plan_name,
    planType: data.plan_type,
    planYear: data.plan_year,
    premium: data.premium,
    quoteID: data.quote_id,
    quoteNumber: data.quote_id_number,
    riderOsbOnly: false, // NOTE(santeyio): ???
    shopperID: data.shopper,
    state: data.shopper_state,
    zip: data.shopper_zip,
    county: data.shopper_county,
    countyName: data.shopper_county_name,
    city: data.shopper_city,
    subsidyLevel: data.shopper_subsidy_level,
    subsidyPercent: data.shopper_subsidy_percent,
    updated: data.updated,
    wir_excs: data.wir_excs,
    wir_hh: data.wir_hh,
    wir_pbco: data.wir_pbco,
    wir_pta: data.wir_pta,
    wir_ptb: data.wir_ptb,
    wir_tvl: data.wir_tvl,
  };
  return rfmData;
}

// TODO(BPham): Needs to be cleaned up ALOT.
// Riders currently not being returned
// Arizona Medigap Discount Unknown
export const saveQuote = async (quote, plan, osb, discounts, riders, shopper) => {
  const token = localStorage.getItem('SessionToken');
  const postData = {
    calculated_premium: quote.calculatedTotal,
    quote_id_number: quote.quoteNumber,
    quote_id: quote.quoteID,

    premium: plan.plan.TotalPlanPremium ? plan.plan.TotalPlanPremium : plan.monthlyPremium,
    plan_id: plan.planID ? plan.planID : plan.planName,
    plan_name: plan.planName,
    plan_type: plan.planType,
    cps_id: plan.cpsID ? plan.cpsID : null,

    osb_id: (osb.OSBMetaName || osb.benefitName)
      ? (osb.OSBMetaName || osb.benefitName)
      : null,
    osb_name: (osb.Description || osb.benefitName)
      ? (osb.Description || osb.benefitName)
      : null,
    osb_amount: (osb.CostShare || osb.premium)
      ? (osb.CostShare || osb.premium)
      : null,

    discount_monthly_bank_draft: discounts.EFT ? discounts.EFT : 0,
    discount_household: discounts.Household ? discounts.Household : 0,
    discount_new_to_medicare: discounts['New to Medicare'] ? discounts['New to Medicare'] : 0,

    discount_age_az: discounts.discountAgeAZ ? discounts.discountAgeAZ : 0,
    wir_excs: riders.WIR_EXCS || 0,
    wir_hh: riders.WIR_HH || 0,
    wir_pbco: riders.WIR_PBCO || 0,
    wir_pta: riders.WIR_PTA || 0,
    wir_ptb: riders.WIR_PTB || 0,
    wir_tvl: riders.WIR_TVL || 0,

    effective_date: shopper.effectiveDate,
    shopper_birthdate: shopper.dob,
    shopper_gender: shopper.gender,
    shopper_smoker: shopper.tobacco,
    shopper_first_name: shopper.firstName,
    shopper_lead_id: shopper.leadID,
    shopper_county: shopper.county,
    shopper_county_name: shopper.countyName,
    shopper_state: shopper.state,
    shopper_zip: shopper.zipcode,
    shopper_city: shopper.city,
    shopper_subsidy_level: shopper.subsidyLevel,
    shopper_subsidy_percent: shopper.subsidyPercent,
    rider_osb_only: false, // NOTE(santeyio): what is this for...
    extra_benefits: plan.plan.ExtraBenefits,

    carrier_id: plan.plan.CarrierID,
    org_id: plan.orgID,
    plan_carrier: plan.carrier,
    plan_org: plan.org,
    plan_brand: plan.brand,
    plan_year: plan.plan.PlanYear || null,
  };
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/quote/?shopper=${shopper.username}`,
      headers: { Authorization: `Token ${token}` },
      data: postData,
    }).then((res) => {
      const completeQuote = fmtQuoteToJS(res.data);
      // // note(joeysapp): On save, set the 'selected quote' to the one we just created
      showPopup('Success', 'Quote saved.');
      resolve(completeQuote);
    }).catch((err) => {
      showPopup('Error', 'There was a problem saving the Quote.');
      reject(err);
    });
  });
};

export const updateQuote = async (quote, username) => {
  const token = localStorage.getItem('SessionToken');
  const { quoteID, confirmationNumber = null } = quote;

  try {
    await axios({
      method: 'put',
      url: encodeURI(`${process.env.REACT_APP_MMB_API_URL}/api/agent/quote/${quoteID}/`),
      headers: { Authorization: `Token ${token}` },
      data: { confirmation_number: confirmationNumber },
    });

    if (confirmationNumber) {
      showPopup('Success', 'Confirmation Number cleared.');
    } else {
      showPopup('Success', 'Quote updated.');
    }

    store.dispatch({
      type: 'UPDATE_QUOTE_CONFIRMATION_NUMBER',
      payload: { username, quote },
    });
  } catch (error) {
    if (error.response && error.response.status === 409) {
      showPopup('Warning', 'This Confirmation Number already exists.');
    } else {
      showPopup('Error', 'There was a problem updating the Quote.');
    }
    throw error;
  }
};

export const openEnrollment = (quoteID, host, carrier, year) => {
  const token = localStorage.getItem('SessionToken');
  const { newEnrollmentURL } = store.getState().theme;

  setEnrollmentToken(token);

  // replace spaces in carrier names with a dash
  const reformattedCarrier = carrier.replaceAll(' ', '-').toLowerCase();

  // Send our user to enrollment portal to digest the above cookie for auth
  let href = `${host}/load/${reformattedCarrier}/${year}/?quote=${quoteID}`;
  if (newEnrollmentURL) {
    if (process.env.NODE_ENV === 'development') {
      href = `${process.env.REACT_APP_ENROLLMENT_DEV_SERVER_URL}/load?quoteID=${quoteID}`;
    } else {
      href = `${host}/load?quoteID=${quoteID}`;
    }
  }
  // on enrollment close set a redux indicator that quotes need to be refreshed
  const popup = window.open(href);
  const timer = setInterval(() => {
    // Handle the popup never opening or getting destroyed on hot reloads
    if (!popup || popup.closed) {
      clearInterval(timer);
      store.dispatch({ type: 'SET_REFRESH_QUOTES_EVENT', payload: true });
    }
  }, 50);
};

// The following items determine what is sent/shown:
// 'Share quote' button/functionality
// site_theme.features.sendQuote

// The templates AWS Pinpoint uses, that we insert user-inputted values into:
// site_config.quoting_email_template ; default `caleb_quote_template`     (stored in pinpoint itself)
// site_config.quoting_sms_template   ; default `caleb_quote_template_sms`

// The blocks of text shown on the frontend, should be copying the above e.g. caleb_quote_template
// site_config.pinpoint_soa_sms_text_json
// site_config.pinpoint_soa_email_text_json
export function sendQuote(method, data) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/send-quote/${method}/`,
      method: 'post',
      headers: { Authorization: `Token ${token}` },
      data,
    }).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}

// Contains a list of events, e.g. sent/opened/completed
export function getQuoteHistory(shopperId, quoteId) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/quotesent`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
      params: {
        shopper: shopperId,
        quote_id: quoteId,
      },
    }).then((res) => {
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}

/*
export function loadQuote(params = { }) {
  const token = localStorage.getItem('SessionToken');
  const {
    quoteID = '',
  } = params;

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/quote/${quoteID}`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
    }).then(({ data = {} }) => {
      resolve(data);
    }).catch(err => {
      reject(err);
    });
  });
}
*/
