import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoaderButton from '../../common/LoaderButton';

// Actions
import { agentLogin } from '../../../actions/agent';
// import { loadCarriers } from '../../../actions/carriers';
import { loginSuccessActions } from '../utils';

// Components
import PasswordInput from './PasswordInput';
import Verification from './Verification';
import CaptchaFrame from './CaptchaFrame';

function LoginBox({
  showPasswordViewToggle = true,
  notify = true,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // NOTE(joeysapp): Convenience for private browsing dev work
  const [email, setEmail] = useState(process.env.REACT_APP_LOGIN_USERNAME || '');
  const [password, setPassword] = useState(process.env.REACT_APP_LOGIN_PASSWORD || '');
  const [emailErrs, setEmailErrs] = useState();
  const [passwordErrs, setPasswordErrs] = useState();

  const [loading, setLoading] = useState();
  const [errs, setErrs] = useState();

  const [captchaSolved, setCaptchaSolved] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState();

  const { hideLogin } = useSelector(store => store.theme.features);
  const {
    fingerprint,
    showVerification,
    verification,
  } = useSelector(store => store.login);
  const {
    theme: {
      features: {
        agentNotifications,
        hideReleaseNotes,
      },
    },
    benefits: {
      MACustomBenefits,
      MAPDCustomBenefits,
      PDPCustomBenefits,
    },
  } = useSelector(store => store);


  const params = new URLSearchParams(window.location.search);
  const useSAML = Boolean(params.get('saml') === 'y');
  const showLoginOverride = Boolean(params.get('showloginoverride') === 'true');

  function doLogin(e, p, v, f) {
    agentLogin(e, p, v, f)
      .then(async (data = {}) => {
        await loginSuccessActions({
          agentData: data,
          notify,
          dispatch,
          navigate,
          successRedirect: '/dashboard',
          MACustomBenefits,
          MAPDCustomBenefits,
          PDPCustomBenefits,
          agentNotifications,
          showReleaseNotes: !hideReleaseNotes,
        });
      }).catch((err) => {
        setCaptchaVisible(false);
        dispatch({ type: 'SET_VERIFICATION_LOADING', payload: false });

        if (err.response) {
          if (err.response.status === 400) {
            setErrs('Incorrect login information provided');
          } else if (err.response.status === 421) {
            setErrs();
            const helpmsg = '';
            /* NOTE(santeyio 2025-02-27): not sure why this 'resend' is here, not sure where it comes from?
            if (resend) {
              helpmsg = 'A new verification code has been emailed to you.';
            }
            */
            dispatch({ type: 'SET_LOGIN_HELP', payload: helpmsg });
            dispatch({ type: 'SET_LOGIN_VERIFICATION_DISPLAY', payload: true });
          } else if (err.response.status === 423) {
            setErrs('The verification code you entered is invalid or expired');
          } else if (err.response.status === 403) {
            setErrs('Please clear your API credentials.');
          } else {
            setErrs(err?.response?.data?.Error || 'Please contact your nearest service representative');
          }
        } else {
          setErrs('You cannot be authorized at this time');
        }
      }).finally(() => setLoading(false));
  }

  function authorizeUser(resend) {
    if (errs) { setErrs('...'); }

    if (resend) {
      dispatch({ type: 'SET_VERIFICATION_LOADING', payload: true });
      dispatch({ type: 'SET_LOGIN_FINGERPRINT', payload: '' });
    } else {
      setLoading(true);
    }
    doLogin(email, password, verification, fingerprint);
  }

  function handleLogin() {
    const hasErrors = emailErrs || passwordErrs;
    if (!email || !password) {
      setErrs();
      if (!email) setEmailErrs('Email address required');
      if (!password) setPasswordErrs('Password required');
    } else if (!captchaSolved && !hasErrors) {
      // authorizeUser will be called in CaptchaFrame after solve
      setCaptchaVisible(true);
    } else if (!hasErrors) {
      /* captcha solved recently enough (cookie present) */
      authorizeUser(false);
    }
  }

  const url = process.env.REACT_APP_MMB_API_URL;
  const site = process.env.REACT_APP_HOSTNAME || window.location.hostname;
  const captchaUrl = `${url}/api/token-auth/captcha/?site=${site}`;

  // if set to use saml login then we should have session auth
  // set through cookies. We just need to hit the get token endpoint
  // setting any username / password and it should work because of
  // auth cookies set by the saml process.
  useEffect(() => {
    if (useSAML) doLogin('test@test.com', 'password', undefined, undefined);
  }, []);

  // don't allow users to login directly
  if (hideLogin && !showLoginOverride) return <i>Please use your SSO login link</i>;

  return (
    showVerification
      ? <Verification handleLogin={() => handleLogin()} />
      : (
        <div className="d-flex flex-column" style={{ height: '40vmax' }}>
          {!captchaVisible
            ? (
              <div>
                <div className="position-relative">
                  <input
                    className="form-control login-input border border-grey fs-4 mb-4 ps-3"
                    id="login-input-email"
                    name="email"
                    onChange={(e) => {
                      if (emailErrs) setEmailErrs();
                      setEmail(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.charCode === 13 || e.key === 'Enter') handleLogin(false);
                    }}
                    placeholder="Email"
                    type="text"
                    value={email}
                  />
                  <div className="position-absolute fade text-danger fs-6 pe-none" style={{ bottom: '-21px', opacity: emailErrs ? '1' : '0' }}>
                    {emailErrs}
                  </div>
                </div>
                <PasswordInput
                  className="form-control login-input border border-grey fs-4 ps-3 "
                  groupClassName="login-input-password-group"
                  id="login-input-password"
                  name="password"
                  onChange={(e) => {
                    if (passwordErrs) setPasswordErrs();
                    setPassword(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.charCode === 13 || e.key === 'Enter') handleLogin(false);
                  }}
                  showPasswordViewToggle={showPasswordViewToggle}
                  value={password}
                  errs={passwordErrs}
                />
                <div className="position-relative mt-4">
                  <LoaderButton
                    onClick={() => handleLogin(false)}
                    loading={loading}
                    disabled={fingerprint === null}
                    className="form-control login-input w-50 fw-bold fs-3"
                    id="login-input-password-submit-button"
                    color="primary"
                    text="Login"
                    loadingColor="primary"
                    loadingText="Loading.."
                  />
                  <div className="position-absolute fade text-danger fs-6" style={{ bottom: '-21px', opacity: errs ? '1' : '0' }}>
                    {errs}
                  </div>
                </div>
                <div className="mt-4">
                  <a
                    className="fs-4 text-decoration-underline text-blue"
                    href={`/password-reset?p=${encodeURIComponent(`/reg/password_reset/?site=${site}&linkback=${site}`)}`}
                    id="login-input-password-forgot-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>
            )
            : null}
          <CaptchaFrame
            src={captchaUrl}
            onSolve={() => authorizeUser()}
            setSolved={() => setCaptchaSolved(true)}
            visible={captchaVisible}
            className="h-100"
          />
        </div>
      )
  );
}

export default LoginBox;
