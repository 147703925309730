import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { withRouterProps } from '../../utils/hocs';

import {
  Attest,
  ArmedForces,
  Conditions,
  Drugs,
  Hospital,
  Help,
  Memory,
  Other,
  Social,
} from './pages';
import NavPage from '../nav/NavPage';
import Alert from '../common/Alert';
import LoaderButton from '../common/LoaderButton';
import LoaderComponent from '../common/LoaderComponent';

// Actions
import { getHRA, createHRA, updateHRA } from '../../actions/hra';

// Utils
import { getAllRoutes, getPageFromURL, formatCarrierHRA } from '../../utils/hra';
import { formatPhoneNumber, getLongestString } from '../../utils/general';

function HRADisclaimer() {
  return (
    <Alert>
      You must agree to the attestation and answer at least 1 question (besides Contact Information) to submit this HRA.
    </Alert>
  );
}

function FormNavigator() {
  const navigate = useNavigate();
  const { form, fields } = useSelector(store => store.hra);
  const routes = getAllRoutes(form);
  // console.log(routes);
  const location = useLocation();
  const currentRoute = location.pathname;
  const nextRoute = routes[routes.indexOf(currentRoute) + 1];
  const previousRoute = routes[routes.indexOf(currentRoute) - 1];
  const [disabled, setDisabled] = useState(false);

  // Equivalent of enrollment-portal nav/BottomNav's allowNav
  useEffect(() => {
    if (fields) {
      if (fields.attest !== true) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [fields]);

  return (
    <div className="d-flex flex-column mt-3" style={{ width: '200px' }}>
      {nextRoute ? (
        <LoaderButton
          className="mb-3"
          onClick={() => navigate(nextRoute)}
          disabled={disabled}
          loading={false}
          text="Next"
          tabIndex={0}
        />
      ) : (
        null
      )}
      {previousRoute ? (
        <button
          className="btn text-decoration-underline d-flex justify-content-center align-items-center me-3"
          onClick={() => navigate(previousRoute)}
          type="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="me-2 fa-md" />
          Back
        </button>
      ) : (
        null
      )}
    </div>
  );
}

function NavFrame({ children }) {
  const { quotes } = useSelector(store => store.shopper);
  const { quoteID } = useSelector(store => store.hra);
  const [quote, setQuote] = useState({});

  useEffect(() => {
    const submittedQuote = quotes.find(q => q.quoteID === quoteID);
    setQuote(submittedQuote);
  }, []);

  return (
    <NavPage activeLink="/hra" showShopperInfobar>
      <div className="mmb-container">
        <div className="mmb-header">
          <span className="me-2">HRA</span>
          <span className="me-2 fw-normal">-</span>
          <span className="me-2 fw-normal fst-italic">{quote?.planName}</span>
        </div>
        <div className="pe-4 ps-5 pt-2">
          {children}
        </div>
        <NavFooter />
      </div>
    </NavPage>
  );
}

function NavFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errs, setErrs] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { mobilePhone, homePhone } = useSelector(store => store.shopper);
  const { shopper } = useSelector(store => store);
  const { quotes } = shopper;
  const { hra } = useSelector(store => store);
  const { fields, quoteID } = hra;

  useEffect(() => {
    if (hra.submittedFlag) {
      setErrs('This HRA has been submitted and cannot be changed');
    }
  }, [hra]);

  useEffect(() => {
    if (fields) {
      let anyFieldAnswered = false;
      // _none_all are fields for toggling other fields on/off.
      const fieldExceptions = ['attest', 'armed_forces'];
      Object.keys(fields).forEach(k => {
        if (fields[k] !== '' && fieldExceptions.indexOf(k) === -1) {
          // this means a user field has been filled.
          anyFieldAnswered = true;
        }
      });
      const attested = fields.attest;
      if (anyFieldAnswered && attested) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  });

  async function handleSubmit() {
    const submittedQuote = quotes.find(q => q.quoteID === quoteID);
    const { carrier } = submittedQuote;
    setLoading(true);

    const formattedFields = formatCarrierHRA({ carrier, quote: submittedQuote, fields, shopper });
    try {
      hra.submitted = formattedFields;
      hra.submittedFlag = true;
      const submittedHRA = await updateHRA(hra);
      dispatch({ type: 'SET_HRA', payload: submittedHRA });
      dispatch({ type: 'SET_REFRESH_QUOTES_EVENT', payload: true });
      navigate('/quotes');
    } catch ({ error }) {
      setErrs(error);
    }
    setLoading(false);
  }

  return (
    <div className="footer-nav">
      <div className="d-flex align-items-center justify-content-between flex-row">
        <span className="me-auto">
          <button type="button" className="btn text-black" onClick={() => navigate('/quotes')}>
            <FontAwesomeIcon icon={faAngleLeft} className="me-2 fa-lg" />
            <b>
              Back to quotes
            </b>
          </button>
        </span>
        <div className="d-flex flex-column">
          <div>{errs}</div>
          <div className="text-secondary ms-auto me-auto fs-4">Client phone number: <b>{formatPhoneNumber(getLongestString([mobilePhone, homePhone]))}</b></div>
        </div>
        {errs === null
          ? (
            <LoaderButton
              className="btn btn-primary ms-auto me-2 ps-4 pe-4 fw-bold"
              type="button"
              disabled={disabled}
              loading={loading}
              text={'Submit HRA'}
              onClick={() => handleSubmit()}
            />
          ) : <div className="ms-auto me-auto" />}
      </div>
    </div>
  );
}

function HealthRiskAssesment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const { quotes } = useSelector(store => store.shopper);
  const { hra } = useSelector(store => store);
  const { quoteID, form } = hra;

  const { title } = getPageFromURL(form || [], location.pathname) || {};

  async function loadHRA() {
    const submittedQuote = quotes.find(q => q.quoteID === quoteID);
    // Look at hra.created in case we're hot reloading the page on a new HRA.
    const hraExists = Boolean(submittedQuote.hra || hra.created);
    let loadingHRA;
    setLoading(true);
    try {
      if (!hraExists) {
        loadingHRA = await createHRA(quoteID, form);
      } else {
        loadingHRA = await getHRA(quoteID);
      }
      dispatch({ type: 'LOAD_HRA', payload: loadingHRA });
      setLoading(false);
    } catch (err) {
      console.error(err);
      setErrMsg(<span>There was a problem loading the HRA for quote {quoteID}.</span>);
      setLoading(false);
      return;
    } try {
      const {
        route: sectionURL, // hra
        pages: [{ route: pageURL }], // attest, contact, etc.
      } = form[0];
      const hraURL = `${sectionURL}${pageURL}`;
      setLoading(false);
      navigate(hraURL);
    } catch (err) {
      console.error(err);
      setErrMsg(<span>There was a problem setting up the HRA.</span>);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadHRA();
  }, []);

  return (
    <Routes>
      <Route
        path={'/'}
        element={(
          <NavFrame>
            {loading && <LoaderComponent />}
            {errMsg}
          </NavFrame>
        )}
      />
      <Route
        path={'/attest'}
        element={(
          <NavFrame>
            <Attest />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/armedforces'}
        element={(
          <NavFrame>
            <ArmedForces pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/conditions'}
        element={(
          <NavFrame>
            <Conditions pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/social'}
        element={(
          <NavFrame>
            <Social pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/drugs'}
        element={(
          <NavFrame>
            <Drugs pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/hospital'}
        element={(
          <NavFrame>
            <Hospital pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/help'}
        element={(
          <NavFrame>
            <Help pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/memory'}
        element={(
          <NavFrame>
            <Memory pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
      <Route
        path={'/other'}
        element={(
          <NavFrame>
            <HRADisclaimer />
            <Other pageTitle={title} />
            <FormNavigator />
          </NavFrame>
        )}
      />
    </Routes>
  );
}

export default withRouterProps(HealthRiskAssesment);
