/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-cycle
import { store } from '../store';

export function fmtQuoteCount(number) {
  if (number < 10) {
    return `00${number.toString()}`;
  }
  if (number >= 10 && number < 100) {
    return `0${number.toString()}`;
  }
  if (number >= 100) {
    return number.toString();
  }
  return '001';
}

export function calcQuoteTotal(plan, osb) {
  const premium = plan.plan.TotalPlanPremium || plan.monthlyPremium || 0;
  const osbCost = Number(osb.CostShare) || 0;
  return premium + osbCost;
}

export function numberWithCommas(x) {
  const parts = x ? x.toString().split('.') : ['--'];
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function roundToNearestFive(x) {
  return (Math.round(parseFloat(x) * 2) / 2).toFixed(1);
}

function numWithCommas(number) {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '--';
}

export function formatPhoneNumber(number) {
  if (number) {
    const areaCode = number.substring(0, 3);
    const phone1 = number.substring(3, 6);
    const phone2 = number.substring(6);

    return `(${areaCode}) ${phone1}-${phone2}`;
  }
  return '';
}

export function formatDollarAmount(amount, withCents = true, absoluteValue = false) {
  let correctlyFormattedAmount;

  if (amount && withCents) {
    let cleanedAmount = amount.toString().replace(/\s/g, ''); // strip all whitespace
    cleanedAmount = cleanedAmount.toString().replace(/,/g, ''); // strip all commas
    if (cleanedAmount[0] === '$') { cleanedAmount = cleanedAmount.substr(1); } // strip beginning '$'
    const parts = cleanedAmount.split('.');
    if (parts.length === 1) {
      correctlyFormattedAmount = `${numWithCommas(parts[0])}.00`;
    } else if (parts[1].length >= 2) {
      correctlyFormattedAmount = `${numWithCommas(parts[0])}.${parts[1].substring(0, 2)}`;
    } else if (parts[1].length === 1) {
      correctlyFormattedAmount = `${numWithCommas(parts[0])}.${parts[1]}0`;
    }
  } else if (amount && !withCents) {
    const parts = amount.toString().split('.');
    if (parts.length === 1) {
      correctlyFormattedAmount = numWithCommas(parts[0]);
    } else if (parts[1].length >= 2) {
      if (Number(parts[1].substring(1, 2)) > 50) {
        correctlyFormattedAmount = Number(numWithCommas(parts[0])) + 1;
      }
      correctlyFormattedAmount = numWithCommas(parts[0]);
    } else if (parts[1].length === 1) {
      if (Number(parts[1]) > 5) {
        correctlyFormattedAmount = Number(numWithCommas(parts[0])) + 1;
      }
      correctlyFormattedAmount = numWithCommas(parts[0]);
    }
  } else {
    correctlyFormattedAmount = '0.00';
  }

  // NOTE(santeyio): not sure when this was put in but it doesn't work.
  // the correctlyFormattedAmount can't be converted to a number because
  // the number contains commas (e.g. 1,234.12).
  if (absoluteValue) {
    console.log('please see note in code');
  }
  if (false) {
    return `$${Math.abs(Number(correctlyFormattedAmount))}`;
  }

  return `$${correctlyFormattedAmount}`;
}

export function getAge(date, now = Date.now()) {
  const birthDate = new Date(date);
  const today = new Date(now);
  const yearDiff = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const pastDate = today.getDate() - birthDate.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && pastDate < 0)) {
    return yearDiff - 1;
  }
  return yearDiff;
}

/* * *
fieldArray should be in the following format:
[
  {
    errmsg: 'Please enter a valid Date of Birth',
    val: <field value>,
    type: 'date', // (optional defaults to string)
  },
]
possible values for type: 'date', 'string', 'email',
* * */
export function validateFields(fieldArray) {
  return new Promise((resolve, reject) => {
    const errorMessages = [];

    fieldArray.forEach(({
      type,
      val,
      errmsg,
      effectiveDate,
    }) => {
      switch (type) {
        case 'date': {
          const dateRegex = /(\d{4})-(\d{2})-(\d{2})/g;
          if (!dateRegex.test(val)) {
            errorMessages.push(errmsg);
          }
          if (effectiveDate) {
            if (getAge(val, effectiveDate) < 65) {
              resolve('Under 65 Years Old');
            } else if (getAge(val, effectiveDate) > 130) {
              errorMessages.push(errmsg);
            }
          }
          break;
        }
        case 'email': {
          // eslint-disable-next-line
          const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
          if (!emailRegex.test(val)) {
            errorMessages.push(errmsg);
          }
          break;
        }
        case 'phone': {
          // eslint-disable-next-line
          const phoneRegex = /\d{10}/g;
          if (!phoneRegex.test(val)) {
            errorMessages.push(errmsg);
          }
          break;
        }
        case 'year': {
          // eslint-disable-next-line
          if (val < 2019 || val > 2040) {
            errorMessages.push(errmsg);
          }
          break;
        }
        case 'password': {
          // >= 8 chars, 1 lower case, 1 uppercase, 1 number
          const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[0-9]).{6,}/g;
          if (!passwordRegex.test(val)) {
            errorMessages.push(errmsg);
          }
          break;
        }
        case 'zipcode': {
          errorMessages.push(errmsg);
          break;
        }
        default: {
          if (val === null || val === undefined || val === '') {
            errorMessages.push(errmsg);
          }
          break;
        }
      }
    });

    if (errorMessages.length === 0) {
      resolve();
    } else {
      reject(errorMessages);
    }
  });
}

/* types available: 'str', 'dollar', 'bool', 'star', 'mdeductible', 'foreignTravel', 'snf', 'tier'
 *
 */
const yes = <FontAwesomeIcon icon={faCheck} className="text-success fs-3" />;
const no = <FontAwesomeIcon icon={faTimes} className="text-grey fs-3" />;
export function fmtField(field, type = 'str', {
  boolOptions = [no, yes],
  prepend = null,
  append = null,
} = {}) {
  let formatted = '--';
  if (type === 'str' && field) {
    formatted = field;
  } else if (type === 'dollar') {
    if (field || field === 0 || field === '0') {
      formatted = formatDollarAmount(field);
    }
    if (typeof field === 'string') {
      if (Number.isNaN(Number(field.slice(1).replace(',', '')))) {
        formatted = field;
      }
    }
  } else if (type === 'bool') {
    if (field === false) {
      formatted = boolOptions[0]; // eslint-disable-line
    } else if (field === true) {
      formatted = boolOptions[1]; // eslint-disable-line
    }
  } else if (type === 'percent') {
    if (field || field === 0 || field === '0') {
      formatted = `${field * 100}%`;
    }
  } else if (type === 'mdeductible') {
    if (typeof field === 'string') {
      // NOTE(santeyio): we only want to display the dollar amount, so chop
      // off the rest of the string since the dollar amount comes first
      [formatted] = field.split(' ');
    }
  } else if (type === 'foreignTravel') {
    formatted = yes;
    if (!field || field.toLowerCase() === 'all costs') {
      formatted = no;
    }
  } else if (type === 'snf') {
    formatted = no;
    if (field === '$0') {
      formatted = yes;
    }
  } else if (type === 'tier') {
    // CoverageGapTiers
    if (Array.isArray(field)) {
      const tiers = field;
      if (tiers.length === 5) {
        formatted = `Tier ${tiers[0]}, Tier ${tiers[1]}, Tier ${tiers[2]}, Tier ${tiers[3]} & Tiers ${tiers[4]} available with Coverage Gap Benefit`;
      } else if (tiers.length === 4) {
        formatted = `Tier ${tiers[0]}, Tier ${tiers[1]}, Tier ${tiers[2]} & Tier ${tiers[3]} available with Coverage Gap Benefit`;
      } else if (tiers.length === 3) {
        formatted = `Tier ${tiers[0]}, Tier ${tiers[1]} & Tier ${tiers[2]} available with Coverage Gap Benefit`;
      } else if (tiers.length === 2) {
        formatted = `Tier ${tiers[0]} & Tier ${tiers[1]} available with Coverage Gap Benefit`;
      } else if (tiers.length === 1) {
        formatted = `Tier ${tiers[0]} available with Coverage Gap Benefit`;
      } else {
        formatted = 'None';
      }
    } else {
      formatted = 'None';
    }
  }
  if (formatted === '--' || formatted === 'None') {
    return <span className="text-fade">{formatted}</span>;
  }
  return (
    <span>
      {prepend || ''}
      {formatted}
      {append || ''}
    </span>
  );
}

export function fmtApproval(approvalValue) {
  return (
    <FontAwesomeIcon
      icon={approvalValue ? faCheck : faTimes}
      style={{
        color: approvalValue ? 'var(--bs-green)' : 'var(--bs-red)',
        marginLeft: approvalValue ? '4px' : '6px',
        marginRight: approvalValue ? '4px' : '10px',
        verticalAlign: '-0.25rem',
        fontSize: '20px',
      }}
    />
  );
}

export function formatSNPType(snpInd) {
  let snpType = 'N/A';
  if (snpInd !== undefined) {
    switch (snpInd) {
      case 0:
        snpType = 'Not a SNP';
        break;
      case 1:
        snpType = 'Chronic or Disabling Condition';
        break;
      case 2:
        snpType = 'Dual-Eligible';
        break;
      case 3:
        snpType = 'Institutional';
        break;
      default:
        snpType = 'N/A';
    }
  }
  return snpType;
}

export function getCarriersArray(carriersObject) {
  return Object.keys(carriersObject).filter((key) => carriersObject[key]);
}

export function getDateAsString(d, type) {
  let formattedString = '-';
  if (type === 'key') {
    // Return: `1993-08-01'`
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    formattedString = `${year}-${month}-${day}`;
  } else if (type === 'readable') {
    // Return: 'August 1993'
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const month = d.toLocaleString('en', { month: 'long' });
    formattedString = `${month} ${year}`;
  } else if (type === 'longform') {
    // Return: '01-01-1999 at 12:34am'
    formattedString = `${moment(d).format('MM-DD-YYYY')} at ${moment(d).format(' h:mma')}`;
  }
  return formattedString;
}

export function generateEffectiveDateOptions() {
  const currentState = store.getState();

  // Allow an extra arbitrary year/month set in the theme
  const { additionalEffectiveDate } = currentState.theme;
  let additionalEffectiveDateObject = null;
  let addedAdditionalEffectiveDate = true;
  if (additionalEffectiveDate) {
    const [ year, month, day ] = additionalEffectiveDate.val.split('-');
    additionalEffectiveDateObject = new Date(year, month - 1, day, 0, 0);
    addedAdditionalEffectiveDate = false;
  }

  // We want to offer [1, 3] months from now
  const effectiveDates = [];
  const currentDate = new Date();
  const currentEffectiveDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  for (let i = 0; i < 3; i += 1) {
    currentEffectiveDate.setMonth(currentEffectiveDate.getMonth() + 1);

    // Insert our additional effective date if it is in these 3 months
    let isConfiguredEffectiveDate = false;
    if (additionalEffectiveDateObject) {
      if (getDateAsString(additionalEffectiveDateObject, 'key') === getDateAsString(currentEffectiveDate, 'key')) {
        isConfiguredEffectiveDate = true;
        addedAdditionalEffectiveDate = true;
      }
    }

    effectiveDates.push({
      val: getDateAsString(currentEffectiveDate, 'key'),
      desc: getDateAsString(currentEffectiveDate, 'readable'),
      priority: isConfiguredEffectiveDate,
    });
  }
  if (!addedAdditionalEffectiveDate) {
    effectiveDates.push({
      val: getDateAsString(additionalEffectiveDateObject, 'key'),
      desc: getDateAsString(additionalEffectiveDateObject, 'readable'),
      priority: true,
    });
  }
  return effectiveDates;
}

export function formatEffectiveDate(dateString) {
  const [ year, month, day ] = dateString.split('-');
  const date = new Date(year, month - 1, day, 0, 0);
  const monthsList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthsNumberList = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const currentYear = date.getFullYear();

  const monthValue = `${currentYear}-${monthsNumberList[date.getMonth()]}-01`;
  const displayed = `${monthsList[date.getMonth()]} ${currentYear}`;
  return { val: monthValue, desc: displayed };
}

export function validateEffectiveDate(effectiveDate) {
  return (moment().format()) > (moment(effectiveDate).format());
}

export function formatSubsidyLevelText(subsidyNumber, subsidyPercent) {
  switch (Number(subsidyNumber)) {
    case 1:
      return 'Full Benefit Dual Eligible';
    case 2:
      return 'Full Extra Help';
    case 3:
      switch (Number(subsidyPercent)) {
        case 25:
          return 'Partial Extra Help: 25%';
        case 50:
          return 'Partial Extra Help: 50%';
        case 75:
          return 'Partial Extra Help: 75%';
        case 100:
          return 'Partial Extra Help: 100%';
        default:
          return 'Partial Extra Help: Unknown';
      }
    case 4:
      return 'No Extra Help';
    case 5:
      return 'Partial Benefit Dual Eligible';
    default:
      return '-';
  }
}

export function capitalize(string, {
  replaceWithAllCaps = undefined,
} = {}) {
  let rstring = '';

  if (string) {
    rstring = string.toLowerCase();

    if (replaceWithAllCaps) {
      rstring = rstring.replace(String(replaceWithAllCaps).toLowerCase(), String(replaceWithAllCaps).toUpperCase());
    }

    rstring = rstring
      .split(' ')
      .map((word) => (word ? word[0].toUpperCase() + word.substr(1) : ''))
      .join(' ');
  }

  return rstring;
}

export function validateSearchInput(leadID, firstName, state) {
  const currentState = store.getState();
  const validations = currentState.theme.validation;
  let status = '';
  if (leadID && leadID !== '' && !leadID.match(new RegExp(validations.lead_id))) {
    status = 'Carrier ID';
  } else if (firstName && firstName !== '' && !firstName.match(new RegExp(validations.first_name))) {
    status = 'First Name';
  } else if (state && state !== '' && !state.match(new RegExp(validations.state))) {
    status = 'State';
  }
  return status;
}

export function validateShopperInput(shopperData, quickQuoteStatus = false) {
  const currentState = store.getState();
  const validations = currentState.theme.validation;
  let status = '';
  if (quickQuoteStatus) {
    if (!shopperData.zipcode.match(new RegExp(validations.zipcode))) {
      status = 'Zip Code';
    }
  }
  if (!quickQuoteStatus) {
    if (!shopperData.leadID.match(new RegExp(validations.lead_id))) {
      status = 'Carrier ID';
    } else if (!shopperData.firstName.match(new RegExp(validations.first_name))) {
      status = 'First Name';
    } else if (shopperData.lastName !== '') {
      if (!shopperData.lastName.match(new RegExp(validations.last_name))) {
        status = 'Last Name';
      }
    } else if (!shopperData.zipcode.match(new RegExp(validations.zipcode))) {
      status = 'Zip Code';
    // } else if (shopperData.leadType !== '') {
    //   if (!shopperData.leadType.match(new RegExp(validations.lead_type))) {
    //     status = 'Lead Type';
    //   }
    } else if (shopperData.leadSource !== '') {
      if (!shopperData.leadSource.match(new RegExp(validations.lead_source))) {
        status = 'Lead Source';
      }
    }
  }
  return status;
}

export function validateCallNoteText(callNoteText) {
  const currentState = store.getState();
  const validations = currentState.theme.validation;

  if (callNoteText && !callNoteText.match(new RegExp(validations.call_note_text))) {
    return true;
  }
  return false;
}

export function retrieveExtraBenefitValue(benefitToFind, availableBenefits, position) {
  const benefit = availableBenefits[position].find((extraBenefit) => benefitToFind === extraBenefit.BenefitName);
  let iconToDisplay = '-';
  if (benefit) {
    iconToDisplay = ((benefit.BenefitValue === 'Y') || (benefit.BenefitValue === 'Yes'))
      ? <FontAwesomeIcon icon={faCheck} className="text-success" />
      : <FontAwesomeIcon icon={faTimes} className="text-danger" />;
  }
  return iconToDisplay;
}

export function calculateProviderCoverage(doctorList, planContractID, asString = false) {
  if (doctorList.length === 0) {
    return <span>No Providers Selected</span>;
  }
  const { doctorHack } = store.getState().theme.features;
  let providersCovered = 0;
  doctorList.forEach((doctor) => {
    // NOTE(santeyio): to use the humana provider api we combine
    // the humana network id and internal humana provider id {networkid}-{humanaid}
    // because the humana API does not provide NPI. We use the network
    // and humana plan data api to determine which plans are covered.
    if (doctor.coveredPlans.includes(planContractID)) {
      providersCovered += 1;
    } else if (doctorHack) {
      // TODO(santeyio):
      // Hack for demo purposes. Forces a list of doctors specified in the theme
      // to cover a list of plans also specified in the theme. Should be removed
      // in production
      const dl = store.getState().theme.doctors;
      dl.forEach((d) => {
        if (Number(doctor.npi) === Number(d.npi) && d.plans.includes(planContractID)) {
          providersCovered += 1;
        }
      });
    }
  });

  return asString
    ? `${providersCovered} of ${doctorList.length}`
    : (
      <span>
        {providersCovered} of {doctorList.length}
      </span>
    );
}

export function retrieveArrayofExtraBenefits(extraBenefits) {
  const array = [];

  Object.keys(extraBenefits).forEach((extraBenefit) => {
    if (extraBenefits[extraBenefit]) {
      array.push(extraBenefit);
    }
  });

  return array;
}

export function mapListSort(set, key) {
  set.sort((mapA, mapB) => {
    if (mapA[key] > mapB[key]) {
      return 1;
    }
    if (mapA[key] < mapB[key]) {
      return -1;
    }
    return 0;
  });
}

export function mapStateAbbreviation(abv) {
  const map = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };
  return map[abv];
}

export function formatStatus(num) {
  const currentState = store.getState();
  const options = currentState.leadStatus?.options || [];
  const selectStatus = options.find((status) => status.value === num);
  if (selectStatus) {
    return selectStatus.description;
  }
  return 'Invalid Status Number';
}

export function formatToPhone(num) {
  let returnValue;
  const target = num || '';
  const input = target.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    returnValue = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    returnValue = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    returnValue = `(${zip})`;
  }

  return returnValue;
}

export function formatPhoneToNum(num) {
  return num.replace(/\(|\)|-/g, '').replace(/ /g, '').substring(0, 10);
}

export function calculateNextNotification(notification) {
  const createddate = new Date(notification.start_date);
  const day = createddate.getDay();
  const month = createddate.getMonth();
  const date = createddate.getDate();
  const hour = createddate.getHours();
  const minute = createddate.getMinutes();

  // const currentDate = new Date();
  // const cDay = currentDate.getDay();
  // const cMonth = currentDate.getMonth();
  // const cDate = currentDate.getDate();
  // const cYear = currentDate.getFullYear();
  // const cHour = currentDate.getHours();
  // const cMinute = currentDate.getMinutes();

  const dayData = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const monthData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // const currentTime = (`${cHour}:${cMinute < 10 ? `0${cMinute}` : cMinute}`);
  // const nTime = (`${hour}:${minute < 10 ? `0${minute}` : minute}`);

  if (notification.interval === 'custom') {
    const daysListed = Object.keys(notification.days).filter((d) => notification.days[d]).map((d) => d.charAt(0).toUpperCase() + d.substring(1, 2)).join(', ');
    return `${daysListed} at ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }
  if (notification.interval === 'daily') {
    // if (currentTime < nTime) {
    //   return moment(`${cYear}-${cMonth}-${cDate} ${hour}:${minute < 10 ? `0${minute}` : minute}`).add(1, 'days').format('lll');
    // }
    // return moment(`${cYear}-${cMonth}-${cDate} ${hour}:${minute < 10 ? `0${minute}` : minute}`).format('lll');
    return `Everyday at ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }
  if (notification.interval === 'weekdays') {
    return `Every Weekday at ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }
  if (notification.interval === 'weekly') {
    return `Weekly on ${dayData[day]} at ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }

  return `${dayData[day]}, ${monthData[month]} ${date}, ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
}

function pad(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

export function formatDateToISO(str, outlook = false) {
  const year = str.getUTCFullYear();
  const month = pad(str.getUTCMonth() + 1);
  const date = pad(str.getUTCDate());
  const hours = pad(str.getUTCHours());
  const minutes = pad(str.getUTCMinutes());
  const seconds = pad(str.getUTCSeconds());

  if (outlook) {
    return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}Z`;
  }

  return `${year}${month}${date}T${hours}${minutes}${seconds}Z`;
}

export function formatDateToURLEncoded(str) {
  const year = str.getUTCFullYear();
  const month = pad(str.getUTCMonth() + 1);
  const date = pad(str.getUTCDate());
  const hours = pad(str.getHours());
  const minutes = pad(str.getUTCMinutes());
  const seconds = pad(str.getUTCSeconds());

  return `${year}-${month}-${date}T${hours}%3A${minutes}%3A${seconds}`;
}


export function formatRecurrence(interval, currentDay, intervalDates, endDateInterval) {
  let base = '&recur=RRULE:FREQ=';

  let intDates = '';
  Object.keys(intervalDates).forEach((day) => {
    if (intervalDates[day]) {
      intDates += `${day.substring(0, 2)},`;
    }
  });

  if (interval === 'daily') {
    base += 'DAILY';
  } else if (interval === 'weekly') {
    base += `WEEKLY;BYDAY=${currentDay.substring(0, 2)},Tu`;
  } else if (interval === 'weekdays') {
    base += 'WEEKLY;BYDAY=Mo,Tu,We,Th,Fr';
  } else if (interval === 'custom') {
    base += `WEEKLY;BYDAY=${intDates};UNTIL=${endDateInterval}`;
  }

  return base;
}

export function notHumanaPlan(plan) {
  const currentState = store.getState();
  const { humanaOnlyOOPC } = currentState.theme.features;
  if (!humanaOnlyOOPC) {
    return false;
  }
  if (plan.carrier !== 'Humana' && plan.carrier !== undefined) {
    return <span>Not Available</span>;
  }
  if (plan.CarrierID !== 2 && plan.CarrierID !== undefined) {
    return <span>Not Available</span>;
  }
  return false;
}

export function formatNameToWidth(firstNameInput, lastNameInput, maxWidth) {
  const firstName = firstNameInput || '';
  const lastName = lastNameInput || '';

  let formattedName = `${firstName} ${lastName}`;
  if (formattedName.length > maxWidth) {
    formattedName = `${firstName} ${lastName[0] || ''}${lastName ? '.' : ''}`;
  }
  if (formattedName.length > maxWidth) {
    formattedName = `${firstName[0]}. ${lastName[0] || ''}${lastName ? '.' : ''}`;
  }
  return formattedName;
}

// By default removes all html, removes spaces & lowercases. Use case for keys
export function replaceAllHtmlElementsWithString(str, replaceString = '', removeSpaces = true, doLowercase = true) {
  let cleanedString = str ? str.replace(/<\/?[^>]+(>|$)/g, replaceString) : '';
  if (removeSpaces) {
    cleanedString = cleanedString.replace(/\s/g, '');
  }
  if (doLowercase) {
    cleanedString = cleanedString.toLowerCase();
  }
  return cleanedString;
}

// Brute-force method of finding newlines that's fragile
// todo(@joeysapp): Perhaps find/design a better regex,
//                  or just do a simple loop through the string
export function countNewlinesInHtmlString(str) {
  let count = 0;
  count += (str.match(/<br>/ig) || []).length;
  count += (str.match(/\/br>/ig) || []).length;
  count += (str.match(/<br\/>/ig) || []).length;

  count += (str.match(/< br>/ig) || []).length;
  count += (str.match(/<br >/ig) || []).length;

  count += (str.match(/\/ br>/ig) || []).length;
  count += (str.match(/<br \/>/ig) || []).length;

  return count;
}

export function asBool(obj) {
  // returns a false bool if empty array or empty object.
  // Because js is dumb and Boolean({}) === true
  // and Boolean([]) === true.
  if (Array.isArray(obj)) {
    if (obj.length) {
      return true;
    }
    return false;
  }
  if (typeof obj === 'object' && obj !== null) {
    if (Object.keys(obj).length) {
      return true;
    }
    return false;
  }
  return false;
}

export function sortObjects(sortArray, sortKey, sortReverse = false) {
  const sortedArray = [...sortArray];
  sortedArray.sort((first, second) => {
    if (first[sortKey] < second[sortKey]) {
      return 1;
    }
    if (first[sortKey] > second[sortKey]) {
      return -1;
    }
    return 0;
  });
  return sortReverse ? sortedArray.reverse() : sortedArray;
}

export function getPlanCarrierDisplay({ quote, plan }) {
  const {
    theme: {
      features: { planListFilterType = 'carrier' },
    },
  } = store.getState();

  if (!['org', 'carrier', 'brand'].includes(planListFilterType)) {
    throw new Error('planListFilterType is invalid. src/utils/fmtFunctions.getDisplayName');
  }

  if (plan) {
    return plan[planListFilterType];
  } else if (quote) {
    return quote[planListFilterType];
  }
  return 'Error';
}

// Motivation: Not having to null check *and* length check with ?.length
/* eslint-disable no-confusing-arrow */
export function getLongestString(strings = []) {
  const longest = strings.reduce((a = '', b = '') => (a.length > b.length) ? a : b);
  return longest;
}
