/* eslint-disable */
import { store } from '../store';
import { capitalize } from '../utils/general';
import { MEDICAL_TYPE_FILTERS } from '../constants';

import { hasCarrierPerms } from '../utils/perms';

function getMedicarePricing(planArray, npi, doctorList = false) {
  return planArray.map((plan) => {
    let doctorsCovered = null;
    let estAnnualOOPC = null;
    let totalAnnualOOPC = null;
    let pharmacyNetwork = null;
    if (doctorList) {
      doctorsCovered = 0;
      doctorList.forEach((doctor) => {
        if (doctor.coveredPlans.includes(plan.cpsID)) {
          doctorsCovered += 1;
        }
      });
    }
    if (npi) {
      const [pricing] = plan.plan.PricingPharmacies.filter(pharmacy => {
        const npiNumber = Number(npi)
        if ((pharmacy.IsMailOrder && npiNumber === 99999999) || Number(pharmacy.NPI) === npiNumber) return true
        else return false
      });
      estAnnualOOPC = pricing ? pricing.EstimatedAnnualRXOOPC : null;
      totalAnnualOOPC = pricing ? pricing.EstimatedAnnualCost : null;
      pharmacyNetwork = pricing ? pricing.PharmacyNetworkDesc : null;
      // if (plan.planType === 'PDP') {
      // estAnnualOOPC = plan.plan.TotalPlanPremium * 12;
      // }
    } else {
      totalAnnualOOPC = plan.plan.RetailEstimatedAnnualDrugCost;
    }
    return {
      ...plan,
      doctorsCovered,
      estAnnualOOPC,
      totalAnnualOOPC,
      pharmacyNetwork,
    }

  });
}

export function planSort(planData, sortKey = 'monthlyPremium', sortAsc = true) {
  // Keys that need be reversed, i.e. highest-down, not lowest-down
  const reverseSortKeys = ['starRating', 'doctorsCovered', 'drugsCovered'];
  const sorted = planData.sort((planA, planB) => planA[sortKey] - planB[sortKey]);
  if (reverseSortKeys.indexOf(sortKey) !== -1) return sorted.toReversed();
  return sorted;
}

function searchMedicare(searchString, plan) {
  if (Object.keys(plan).length) {
    let s = searchString.toLowerCase();
    if (plan.planName.toLowerCase().includes(s)) { return true; }
    if (plan.planType.toLowerCase().includes(s)) { return true; }
    if (plan.cpsID.toLowerCase().includes(s)) { return true; }
  }
  return false;
}

// -----------------------------------
// Possible planTypes: MAPD, MA, PDP
// -----------------------------------
export function filterMedicare(planData, {
  planType = ['MAPD', 'MA'],
  carrierPerms = [], // array of carrier_permissions from redux
  carrierFilters = 'all',
  enrollCarriersFilter = false,
  saved = false,
  npi = false,
  sortKey = 'totalAnnualOOPC',
  sortAsc = true,
  doctorList = false,
  medicalTypeFilters = MEDICAL_TYPE_FILTERS,
  snpFilter = false,
  snpValue = false,
  rxFilter = false,
  doctorFilter = false,
  premiumLimitFilter = false,
  searchText = '',
} = {}) {
  let filteredPlans = getMedicarePricing(planData, npi, doctorList);
  const currentState = store.getState();
  const {
    features: {
      carrierPermissions: carrierPermsTheme,
      planListFilterType: filterType = 'carrier',
    },
    enrollCarriers,
  } = currentState.theme;
  const { enrollDisabledPlans = [] } = currentState.plan;

  const enrollCarriersArray = Object.keys(enrollCarriers);

  if (planType.length) {
    filteredPlans = filteredPlans.filter((plan) => {
      let carrierMatch = false;
      let enrollCarrierMatch = true;
      let typeMatch = false;
      // let medicalTypeMatch = false;
      let snpFilterVal = true;
      let rxFilterVal = true;
      let doctorFilterVal = true;
      let premiumLimitFilterVal = true;
      let searchValMatch = true;
      planType.forEach((type) => {
        if (plan.planType === type) { typeMatch = true; }
      });
      // medicalTypeFilters.forEach((type) => {
      // if (plan.medicalType === type) { medicalTypeMatch = true; }
      // });
      if (carrierFilters === 'all') {
        carrierMatch = true;
      } else if (Array.isArray(carrierFilters)) {
        if (filterType === 'org') {
          if (carrierFilters.includes(plan.org)) { carrierMatch = true; }
        } else if (filterType === 'carrier') {
          if (carrierFilters.includes(plan.carrier)) { carrierMatch = true; }
        } else if (filterType === 'brand') {
          if (carrierFilters.includes(plan.brand)) { carrierMatch = true; }
        }
      }
      if (enrollCarriersFilter) {
        // ReadyToSell and Online Enrollment capability filtering
        let readyToSell = !carrierPermsTheme;
        const carrierPermissions = carrierPerms.filter(p => p.carrier_id === plan.plan.CarrierID);
        carrierPermissions.forEach((carrierPermission) => {
          if (carrierPermission.active) {
            readyToSell = hasCarrierPerms(
              plan.planType,
              carrierPermission.carrier_id,
              currentState.shopper.state,
              plan.plan.PlanYear,
            );
          }
        });
        enrollCarrierMatch = readyToSell && enrollCarriersArray.indexOf(plan.carrier) !== -1;
        if (enrollDisabledPlans.includes(plan.cpsID)) enrollCarrierMatch = false;
      }
      if (snpFilter) { snpFilterVal = plan.plan.IsSpecialNeedPlan === snpValue }
      if (rxFilter) { rxFilterVal = (plan.drugsCovered > 0); }
      if (doctorFilter) { doctorFilterVal = (plan.doctorsCovered > 0); }
      if (premiumLimitFilter) { premiumLimitFilterVal = (plan.monthlyPremium <= Number(premiumLimitFilter)); }
      if (searchText) { searchValMatch = searchMedicare(searchText, plan); }

      return typeMatch
        // && medicalTypeMatch
        && snpFilterVal
        && rxFilterVal
        && premiumLimitFilterVal
        && doctorFilterVal
        && searchValMatch
        && carrierMatch
        && enrollCarrierMatch;
    });
  }
  if (saved) {
    filteredPlans = filteredPlans.filter(plan => plan.meta.saved);
  }
  return planSort(filteredPlans, sortKey, sortAsc);
}

export function getMedicarePlanByID(planList, planID, NPI = false) {
  planID = Number(planID);
  const planListWithPricing = getMedicarePricing(planList, NPI);
  let plan = planListWithPricing.filter(plan => plan.planID === planID)[0];
  if (!plan) { return null; }
  if (NPI) {
    let pharmacyInfo = {};
    plan.plan.PricingPharmacies.forEach((pharmacy) => {
      if (Number(pharmacy.NPI) === Number(NPI) || (pharmacy.IsMailOrder && Number(NPI) === 99999999)) {
        pharmacyInfo = { ...pharmacy };
      }
    });
    plan.plan = { ...plan.plan, ...pharmacyInfo };
  }
  return plan;
}

export function getMedicarePlanByContractID(planList, cpsID, { NPI = false }) {
  const planListWithPricing = getMedicarePricing(planList, NPI);
  let plan = planListWithPricing.filter(plan => plan.cpsID === cpsID)[0];
  if (!plan) { return null; }
  return plan;
}

export function filterMedigap(planData, {
  carrierPerms = [],
  carrierFilters = 'all',
  sortKey = 'monthlyPremium',
  sortAsc = true,
  select = false,
} = {}) {
  const currentState = store.getState();
  const carrierPermsTheme = currentState.theme.features.carrierPermissions;

  let filteredPlans = planData.filter((plan) => {
    let carrierPermsMatch = false;
    let carrierMatch = false;
    let selectMatch = false;
    if (carrierFilters === 'all') {
      carrierMatch = true;
    } else if (Array.isArray(carrierFilters)) {
      if (carrierFilters.includes(plan.plan.carrierID)) { carrierMatch = true; }
    }
    if (carrierPerms.length && carrierPermsTheme) {
      carrierPermsMatch = parseCarrierPerms(carrierPerms, plan);
    } else {
      carrierPermsMatch = true;
    }
    if (select === plan.select) { selectMatch = true; }
    return carrierPermsMatch && selectMatch && carrierMatch;
  });
  return sortedPlans;
}

export function getMedigapPremium(planList, planName, carrier) {
  let premium = null;
  planList.forEach(plan => {
    if (plan.planName === planName && plan.plan.carrierID === carrier) {
      premium = plan.monthlyPremium;
    }
  });
  return premium;
}
