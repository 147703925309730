import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'react-js-pagination';
import { loadThreeWayCalls } from '../../actions/three_way';

function Pagination() {
  const dispatch = useDispatch();
  const { callData = {} } = useSelector(store => store.threeWay);
  const {
    Page: callPageIdx,
    PageSize: callPageSize,
    TotalItems,
  } = callData;

  function handleChange(paginationIdx) {
    if (paginationIdx === callPageIdx) return;
    dispatch({ type: 'SET_THREE_WAY_LOADING', payload: true });
    loadThreeWayCalls({ page: paginationIdx })
      .then(config => {
        dispatch({ type: 'SET_THREE_WAY_FIELD_VALUE', field: 'callData', payload: config });
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      }).catch(() => {
        dispatch({ type: 'SET_THREE_WAY_LOADING', payload: false });
      });
  }

  return (
    <ReactPagination
      activePage={callPageIdx}
      itemsCountPerPage={callPageSize}
      totalItemsCount={TotalItems || 0}
      pageRangeDisplayed={5}
      innerClass="pagination"
      linkClass="page-link"
      onChange={(p) => handleChange(p)}
    />
  );
}

export default Pagination;
