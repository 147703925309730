/* eslint-disable no-confusing-arrow,react/no-unknown-property */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { withRouterProps } from '../../../utils/hocs';

// Components
import LoaderButton from '../../common/LoaderButton';
import EnrollmentDisclaimer from '../../common/EnrollmentDisclaimer';

// Actions + Utils
import { saveQuote } from '../../../actions/quote';
import {
  calcQuoteTotal,
  fmtQuoteCount,
  fmtField,
  formatDollarAmount,
} from '../../../utils/general';

class UnsavedQuote extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      loading: false,
      quoteNumber: '',
      quoteID: '',
    };
  }

  componentDidMount() {
    const { quotes, shopper } = this.props;
    const qn = quotes.length;
    this.setState({
      quoteNumber: qn,
      quoteID: `${shopper.leadID}.${fmtQuoteCount(qn)}`,
    });
  }

  handleSaveQuote = () => {
    const {
      dispatch,
      plan,
      selectedOSB,
      shopper,
    } = this.props;
    this.setState({ loading: true });
    const discounts = {};
    const riders = {};
    const quote = {
      calculatedTotal: calcQuoteTotal(plan, selectedOSB),
      quoteID: this.state.quoteID,
      quoteNumber: this.state.quoteNumber,
    };
    saveQuote(quote, plan, selectedOSB, discounts, riders, shopper)
      .then((savedQuote) => {
        this.setState({ loading: false });
        dispatch({ type: 'ADD_SHOPPER_QUOTE', payload: savedQuote });
        dispatch({ type: 'SET_SELECTED_PLAN', payload: null });
        dispatch({ type: 'SET_QUOTE_LIST_SORT', payload: 'updated' });
      }).catch(() => this.setState({ loading: false }));
  };

  render() {
    const {
      dispatch,
      plan,
      shopper,
      selectedOSB,
      shareQuoteFeature,
      refreshQuotes,
    } = this.props;
    const { open } = this.state;

    const quoteTotal = plan.plan && selectedOSB
      ? calcQuoteTotal(plan, selectedOSB)
      : '--';
    const idHover = `Plan type: ${plan.planType}\nPlan carrier: ${plan.carrier}\nPlan name: ${plan.planName}`;

    return (
      <div
        className={`enroll-quote-card new ${open ? 'open' : ''}`}
      >
        {/* Top row: [[cost], [info/buttons, cancel]] */}
        <div className="d-flex" style={{ minHeight: '128px' }}>
          <div className="d-flex ps-4" style={{ width: '17.5%' }}>
            <div
              id="quote-total"
              className="d-flex justify-content-center flex-column ms-1"
              title={`Quote total: ${formatDollarAmount(quoteTotal)}`}
            >
              <div className="">
                Quote total:
              </div>
              <div className="fs-4 fw-bold">
                {fmtField(quoteTotal, 'dollar')}
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column justify-content-center border-start border-primaryFocusBorder ps-4 position-relative"
            id="plan-id"
            style={{ width: '82.5%' }}
          >
            <div className="plan-tri-header mt-0 ms-1">
              <span id="type" title={idHover}>{plan.planType}</span>
              <span id="carrier" title={idHover}>{plan.carrier}</span>
              <span id="name" title={idHover}>{plan.planName}</span>
            </div>
            <div className="d-flex flex-row mt-3 ms-1">
              {
                shareQuoteFeature
                  ? (
                    <LoaderButton
                      className="fw-bold text-white ps-4 pe-4 opacity-25"
                      color="primaryFocus"
                      id="enroll-share-quote-button"
                      text="Share quote"
                      disabled
                    />
                  )
                  : null
              }
              <div className="d-flex align-items-center">
                <LoaderButton
                  className={`fw-bold ps-2 pe-2 ${shareQuoteFeature ? 'ms-4' : ''}`}
                  color="primary"
                  id="enroll-save-quote-button"
                  text="Save quote"
                  loadingText="Saving quote"
                  disabled={refreshQuotes}
                  onClick={this.handleSaveQuote}
                  loading={this.state.loading}
                />
                <EnrollmentDisclaimer
                  className="ms-4 me-4"
                  style={{ whiteSpace: 'break-spaces', minHeight: 'auto', lineHeight: '15px' }}
                  plan={plan}
                />
              </div>
            </div>
            <div
              className="position-absolute fs-4"
              style={{ right: '18px', top: '15px' }}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.setState({ open: false });
                setTimeout(() => {
                  dispatch({ type: 'CLEAR_ENROLLMENT' });
                }, 750);
              }}
            >
              Cancel
              <FontAwesomeIcon icon={faTimes} className="ms-2" />
            </div>
          </div>
        </div>
        {/* Bottom row: [[benefits, osbs, cost], [info, quote history]] */}
        <div className="d-flex border-top border-primaryFocusBorder fs-5">
          {/* Left side: [benefits, osbs, cost] */}
          <div className="d-flex flex-column w-50 border-end border-primaryFocusBorder">
            <div className="border-bottom border-primaryFocusBorder m-4 mt-3">
              <b>Extra benefits</b>
              <ul className="ps-3">
                {
                  /* Every quote has extraBenefits - first check it exists, then look for 'Yes'/'No' */
                  plan.plan && plan.plan.ExtraBenefits
                    ? plan.plan.ExtraBenefits.map((b) => b.BenefitValue === 'Yes'
                      ? <li key={`${plan.id}-${b.BenefitName}`}>{b.BenefitName}</li>
                      : null)
                    : 'N/A'
                }
              </ul>
            </div>
            <div className="border-bottom border-primaryFocusBorder m-4 mt-0">
              <b>Add optional supplemental benefits</b>
              <ul className="ps-3">
                {
                  plan.plan && plan.plan.OptionalSupplementalBenefits
                    ? plan.plan.OptionalSupplementalBenefits.forEach((osb) => (
                      <li>
                        <select
                          onClick={() => {
                            if (osb.Description === selectedOSB.Description) {
                              dispatch({ type: 'SET_OSB', payload: {} });
                            } else {
                              dispatch({ type: 'SET_OSB', payload: osb });
                            }
                          }}
                          checked={selectedOSB.Description === osb.Description}
                          type="checkbox"
                        />
                        {osb.Description},{osb.CostShare}
                      </li>
                    ))
                    : 'N/A'
                }
              </ul>
            </div>
            <div className="d-flex flex-column m-4 mt-0 fs-4">
              <div className="d-flex">
                <span className="ms-0 me-auto">Base premium:</span>
                <span className="ms-auto me-0">{plan && plan.plan ? fmtField(plan.plan.TotalPlanPremium, 'dollar') : '--'}</span>
              </div>
              <div className="d-flex">
                <span className="ms-0 me-auto">{selectedOSB ? selectedOSB.Description : 'OSB'}</span>
                <span className="ms-auto me-0">{selectedOSB ? selectedOSB.CostShare : '--'}</span>
              </div>
              <div className="d-flex fw-bold fs-3">
                <span className="ms-0 me-auto">Total:</span>
                <span className="ms-auto me-0">{fmtField(quoteTotal, 'dollar')}</span>
              </div>
            </div>
          </div>
          {/* Right side: [info, quote history] */}
          <div className="d-flex flex-column w-50">
            <div className="d-flex flex-column border-bottom border-primaryFocusBorder m-4 mt-3 pb-4">
              <div>Plan ID: {plan ? plan.cpsID : '--'}</div>
              <div>State: {shopper ? shopper.state : '--'}</div>
              <div>Effective date: {shopper ? moment(shopper.effectiveDate).format('MM-DD-YYYY') : '--'}</div>
              <div>Quote ID: --</div>
            </div>
            <div className="d-flex flex-column m-4 mt-0">
              <b>Quote history</b>
              --
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnsavedQuote.defaultProps = {
  plan: {},
};

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
    quotes: store.shopper.quotes || [],
    refreshQuotes: store.quote.refreshQuotes,
    selectedOSB: store.enrollment.selectedOSB,
    shareQuoteFeature: store.theme.features.shareQuote,
  };
}

export default connect(mapStateToProps)(withRouterProps(UnsavedQuote));
