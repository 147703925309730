/* eslint-disable react/jsx-no-bind */
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie } from '../../../../utils/cookie';

// components
import Select from './Select';

function EffectiveDate({ disabled }) {
  const dispatch = useDispatch();
  const { effectiveDate, errors, validation } = useSelector(store => store.shopper);
  const { additionalEffectiveDate: aed = {} } = useSelector(store => store.theme);

  function generateOptions() {
    const opts = [];
    let defaultOption = null;
    for (let i = 1; i < 4; i += 1) {
      const value = moment().add(i, 'months').format('YYYY-MM-01');
      const desc = moment().add(i, 'months').format('MMMM YYYY');

      // if aed is one of the autogenerated effective dates save it to
      // add after the loop so it gets added to the first place in the array
      // and is the default option
      if (value === aed.val) {
        defaultOption = { value, desc };
      } else {
        opts.push({ value, desc });
      }
    }

    if (defaultOption) opts.unshift(defaultOption);
    // if aed is not in the autogenerated options set it as the default option
    if (!defaultOption && aed.val) opts.unshift({ value: aed.val, desc: aed.desc });

    // if we have an old and invalid effective date not in the autogenerated list,
    // add it as a disabled option to the options list
    const ed = moment(effectiveDate);
    if (!opts.map(d => d.value).includes(effectiveDate) && ed.isValid()) {
      opts.unshift({
        value: effectiveDate,
        desc: `(outdated) ${ed.format('MMMM YYYY')}`,
        disabled: true,
      });
      if (validation.effectiveDate !== false) {
        dispatch({ type: 'UPDATE_SHOPPER_VALIDATION', payload: { effectiveDate: false } });
      }
      if (!errors.effectiveDate) {
        dispatch({ type: 'UPDATE_SHOPPER_ERROR', payload: { effectiveDate: 'Invalid effective date' } });
      }
    }

    return opts;
  }

  const options = generateOptions();

  useEffect(() => {
    if (!effectiveDate) {
      dispatch({ type: 'UPDATE_SHOPPER', payload: { effectiveDate: options[0].value } });
    } else {
      // set effective date in cookie state so that softphone window can read it
      setCookie('effectiveDate', effectiveDate);
    }
  }, [effectiveDate]);

  function handleChange(e) {
    const { value } = e.target;
    dispatch({ type: 'UPDATE_SHOPPER', payload: { effectiveDate: value } });
  }

  return (
    <Select
      name="effectiveDate"
      label="Effective date"
      handleChange={handleChange}
      value={effectiveDate}
      options={options}
      disabled={disabled}
    />
  );
}

export default EffectiveDate;
