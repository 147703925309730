/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

// Actions
import { deleteDrug } from '../../../actions/drug';

// Components
import StandardModal from '../../common/StandardModal';
import LoaderButton from '../../common/LoaderButton';

class DeleteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loadingVar: false,
    };
  }

  deleteDrug = () => {
    const {
      dispatch,
      drug: {
        id,
        dosageID,
        packageID,
      },
      shopper,
      quickQuote,
    } = this.props;

    this.setState({ loadingVar: true });

    return new Promise((resolve, reject) => {
      deleteDrug(id, shopper.username, quickQuote)
        .then(() => {
          if (quickQuote) {
            dispatch({ type: 'DELETE_DRUG_FROM_MEDCAB', payload: { dosageID, packageId } });
          } else {
            dispatch({ type: 'DELETE_DRUG_FROM_MEDCAB', payload: { id } });
          }
          dispatch({ type: 'CLEAR_PLAN_DATA' });
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: 'DEFAULT_STATE' });
          dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: 'DEFAULT_STATE' });
          this.setState({ loadingVar: false });
          resolve();
        }).catch((err) => {
          this.setState({ loadingVar: false });
          console.log(err);
          reject();
        });
      });
  }

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'HIDE_MODAL', payload: 'deleteDrugModal' });
    setTimeout(() => {
      dispatch({ type: 'CLEAR_DELETE_DRUG' });
    }, 250);
  }

  render() {
    const {
      deleteDrugModal,
      drug,
      dispatch,
    } = this.props;

    return (
      <StandardModal
        closeIcon={false}
        isOpen={deleteDrugModal}
        width='350px'
      >
        <div className="col delete-drug-modal-container">
          <div className="row text-center justify-content-center mb-2">
            <FontAwesomeIcon className="rx-trash-icon" icon={faTrash} size="large" size="2x" />
          </div>
          <div className="row text-center justify-content-center mb-4">
              Are you sure you want to delete <br />
              <b className="d-flex flex-column mt-2 align-items-center">
                <span className="mb-1 fs-5">{drug.name}</span>
                <span className="pt-1 border-top border-grey">{drug.dosage}?</span>
              </b>
          </div>
          <div className="d-flex flex-column justify-content-around">
            <LoaderButton
              type="button"
              text="Delete"
              loadingText="Deleting..."
              className="mb-2 fw-bold text-white"
              color="danger"
              loading={this.state.loadingVar}
              onClick={() => {
                this.deleteDrug()
                  .then(() => {
                    dispatch({ type: 'HIDE_MODAL', payload: 'deleteDrugModal' });
                    /* Delete drug when modal is 100% gone */
                    setTimeout(() => {
                      dispatch({ type: 'CLEAR_DELETE_DRUG' });
                    }, 250);
                  });
              }}
            />
            <LoaderButton
              type="button"
              color="outline-secondary"
              text="Cancel"
              onClick={this.closeModal}
            />
          </div>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    drug: store.drug.deleteDrug || {},
    deleteDrugModal: store.modal.deleteDrugModal,
    quickQuote: store.shopper.quickQuote,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(DeleteModal);
