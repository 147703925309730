/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import download from 'js-file-download';

export const forceDownload = (url, filename) => {
  axios({
    method: 'get',
    url,
    responseType: 'blob',
  }).then((res) => {
    download(res.data, filename);
  }).catch(() => {
    alert('There was an error retrieving the requested file');
  });
};
