const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_CARRIERS': {
      const carriers = [];
      action.payload.forEach(({ CarrierID, CarrierName }) => {
        carriers[CarrierID] = CarrierName;
      });
      return carriers;
    }
    case 'CLEAR_CARRIERS':
      return [];
    default:
      return state;
  }
};
