import React from 'react';
import { connect } from 'react-redux';
import { hasCarrierPerms } from '../../utils/perms';
import { getPlanCarrierDisplay } from '../../utils/general';

class EnrollmentDisclaimer extends React.PureComponent {
  render() {
    const {
      className,
      enrollCarriers,
      enrollDisabledPlans = [],
      plan = {},
      shopperState,
      geoState,
      style,
      useCarrierPerms,
      quote = {},
    } = this.props;
    const {
      carrier,
      carrierID,
      cpsID,
      planType,
      plan: drPlan = {},
    } = plan;
    const { PlanYear } = drPlan;
    const planYear = String(PlanYear);

    // if we don't get a geoState passed in as a prop (e.g. CT or AZ) use shopper state.
    // We only need to pass in the state when we are displaying the disclaimer for
    // a saved quote, since the shopper could have theoretically moved after the quote
    // was initially saved.
    const state = geoState || shopperState;

    // In some cases the full plan data is not available when we are displaying the disclaimer
    // (as in the case of a previously saved quote). So we read the display name from the quote
    // instead of the plan as a fallback.
    const displayName = getPlanCarrierDisplay({ plan }) || getPlanCarrierDisplay({ quote });

    let disclaimer = '';
    let title = '';

    // if enrollment for the carrier + year is globally unavailable
    const validCarriers = Object.keys(enrollCarriers);
    const validYears = enrollCarriers[carrier];
    const isValid = (validCarriers.includes(carrier) && validYears.includes(planYear));
    if (!isValid) {
      disclaimer = `Online enrollment is unavailable for ${displayName} ${planYear} plans.`;
      title = `Online enrollment is currently unavailable for all ${displayName} ${planYear} plans.`;

    // if enrollment for this specific plan is not available
    } else if (enrollDisabledPlans.includes(cpsID)) {
      disclaimer = 'Online enrollment is unavailable for this plan.';
      title = 'Online enrollment is currently unavailable for this specific plan.';

    // if agent doesn't have RTS perms
    } else if (useCarrierPerms && !hasCarrierPerms(planType, carrierID, state, planYear)) {
      disclaimer = 'Ready to Sell certification needed for enrollment';
      title = `You do not have Ready to Sell certification from ${displayName} to sell this plan.`;
    }

    return (
      <div
        className={`d-flex flex-column text-danger mt-1 ${className || null}`}
        style={{
          cursor: 'help',
          minHeight: '25px',
          fontSize: '12px',
          userSelect: 'none',
          zIndex: '5',
          lineHeight: '12px',
          ...style,
        }}
      >
        <span title={title}>
          {disclaimer}
        </span>
      </div>
    );
  }
}

EnrollmentDisclaimer.defaultProps = {
  plan: {
    carrierID: undefined,
    carrier: undefined,
    planType: undefined,
    cpsID: undefined,
  },
  geoState: undefined,
  style: {},
};

const mapStateToProps = (state) => ({
  enrollCarriers: state.theme.enrollCarriers || [],
  enrollDisabledPlans: state.plan.enrollDisabledPlans || [],
  shopperState: state.shopper.state,
  useCarrierPerms: state.theme.features.carrierPermissions,
});

export default connect(mapStateToProps)(EnrollmentDisclaimer);
