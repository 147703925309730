import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { loadShoppers } from '../../actions/shopper';

// Components
import { Highlight } from '../../guide';

// Constants
import { AWAIT_STATE } from '../../constants';

// Utility
import { showPopup } from '../../utils/popup';
import { validateSearchInput } from '../../utils/general';

function ShopperListSearch() {
  const dispatch = useDispatch();
  const {
    leadStatus: { options: leadStatusOptions = [] },
    agent: { email: agentEmail },
    loaders: { shoppersLoaded },
    shopperlist: {
      selectedTab,
      showAllQuotes,
      sortInverse,
      sortTerm,
      nameFilter,
      stateFilter,
      statusFilter,
      leadIDFilter,
      leadTypeFilter,
    },
  } = useSelector(store => store);

  useEffect(() => {
    dispatch({ type: 'CLEAR_SEARCH_TERMS' });
  }, []);

  function handleChange(event) {
    const { id, value, name } = event.target;
    console.log('name: ', name);
    if ((name === 'state') && ((value || '').length > 2)) {
      showPopup('Error', 'Please use a 2 letter State abbreviation');
      return;
    }
    dispatch({ type: 'UPDATE_SHOPPER_LIST_FILTER', payload: { filterName: id, filterValue: value } });
  }

  function handleSearch() {
    loadShoppers({
      // todo(joeysapp): Refactor loadShoppers to handle city/phone/name
      desc: sortTerm ? sortInverse : null,
      lead_id: leadIDFilter || null,
      lead_status: statusFilter || null,
      lead_type: leadTypeFilter || null,
      name: nameFilter || null,
      show_all_quotes: showAllQuotes,
      sort: sortTerm || null,
      state: stateFilter || null,
      user_who_created: agentEmail,
    });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const status = validateSearchInput(nameFilter, stateFilter);
      if (status === '') {
        handleSearch();
      } else {
        showPopup('Error', `${status} Input Value is Invalid.`);
      }
    }
  }

  function handleReset() {
    const tabsAllowingDispositionFilter = ['All leads'];

    return new Promise((resolve) => {
      dispatch({ type: 'CLEAR_SEARCH_TERMS' });
      dispatch({ type: 'CLEAR_SORT_TERM' });
      dispatch({ type: 'CLEAR_SORT_INVERSE' });
      dispatch({ type: 'SET_SHOPPERS_LOADED_STATE', payload: AWAIT_STATE });
      dispatch({ type: 'SET_SELECTED_TAB', payload: 'All leads' });
      if (tabsAllowingDispositionFilter.indexOf(selectedTab) !== -1) {
        dispatch({ type: 'CLEAR_STATUS_FILTER' });
      }
      resolve();
    });
  }

  // Only allow filtering by disposition if tab is 'All'
  const tabsAllowingDispositionFilter = ['All leads'];

  return (
    <div className="dashboard-shopper-list-search">
      <form role="group" className="d-inline-flex w-100">
        <Highlight
          id="dash"
          tag="label"
          i={3}
          className="dashboard-shopper-list-search-inputs-label"
        >
          Search by:
        </Highlight>
        <div className="dashboard-shopper-list-search-inputs">
          <input
            className="form-control dashboard-shopper-list-search-inputs-name"
            id="nameFilter"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Name..."
            type="text"
            value={nameFilter}
          />
          <input
            className="form-control dashboard-shopper-list-search-inputs-state"
            id="stateFilter"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="State..."
            type="text"
            value={stateFilter}
            name="state"
          />
          <div className="dashboard-shopper-list-search-break" />
        </div>
        <div className="dashboard-shopper-list-search-break" />
        <div className="dashboard-shopper-list-search-inputs">
          <label className="dashboard-shopper-list-search-inputs-label" htmlFor="statusFilter">
            Disposition:
          </label>
          <div className="dashboard-shopper-list-search-inputs">
            <select
              className="form-control dashboard-shopper-list-search-inputs-disposition"
              id="statusFilter"
              name="select"
              type="select"
              disabled={tabsAllowingDispositionFilter.indexOf(selectedTab) === -1 || shoppersLoaded === AWAIT_STATE}
              value={statusFilter}
              style={{
                cursor: tabsAllowingDispositionFilter.indexOf(selectedTab) === -1 ? 'auto' : 'pointer',
                opacity: tabsAllowingDispositionFilter.indexOf(selectedTab) === -1 ? '0.5' : '1',
              }}
              /* Search when user selects new disposition filter */
              onChange={(e) => {
                handleChange(e);
                handleSearch(e);
              }}
            >
              {(tabsAllowingDispositionFilter.indexOf(selectedTab) === -1
                ? <option key="" value="">...</option>
                : <option key="" value="">All</option>
              )}
              {leadStatusOptions.map((option) => (
                option.value
                  ? <option key={option.value} value={option.value}>{option.description}</option>
                  : null
              ))}
            </select>
            <i
              className="fa fa-chevron-down"
              style={{
                color: 'black',
                cursor: tabsAllowingDispositionFilter.indexOf(selectedTab) === -1 ? 'auto' : 'pointer',
                fontSize: '15px',
                marginLeft: '-25px',
                pointerEvents: 'none',
              }}
            />
          </div>
        </div>
        <div className="dashboard-shopper-list-search-inputs buttons">
          <button
            className="btn btn-primary"
            id="filter-search-submit"
            onClick={(e) => handleSearch(e)}
            type="button"
          >
            Search
          </button>
          <button
            className="btn btn-outline-secondary"
            id="filter-search-reset"
            onClick={(e) => {
              handleReset(e)
                .then(() => handleSearch(e));
            }}
            type="button"
          >
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default ShopperListSearch;
