import { NAME_SEARCH } from '../constants';

const initialState = {
  callDetails: false,
  benefitsModal: false,
  cityModal: false,
  drugAddModal: false,
  pharmacyAddModal: false,
  doctorAddModal: false,
  drugModalState: 'search',
  doctorModalState: NAME_SEARCH,
  saveQuoteModal: false,
  quickQuoteWarningModal: false,
  quickQuoteWarningRouteLink: '',
  saveShopperModal: false,
  compareSelectModal: false,
  effectiveDateWarningModal: false,
  birthdayWarningModal: false,
  birthdayWarningRouteLink: '',
  statePermissionModal: false,
  eligibilityModal: false,
  editCurrentPlanModal: false,
  addAgentModal: false,
  editAgentModal: false,
  exitModal: false,
  exitRoute: '',
  sendSoaModal: false,
  invalidZipModal: false,
  missingMedCabItem: '',
  agentNotificationModal: false,
  editNotificationModal: false,
  discardChangesModal: false,
  deleteDrugModal: false,
  passwordResetModal: false,
  softphoneModal: false,
  staleClientModal: false,
  externalHRAModal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        [action.payload]: true,
      };
    case 'HIDE_MODAL':
      return {
        ...state,
        [action.payload]: false,
      };
    case 'SET_MODAL': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_EXIT_MODAL_ROUTE':
      return { ...state, exitRoute: action.payload };
    case 'SET_MISSING_MEDCAB':
      return { ...state, missingMedCabItem: action.payload };
    case 'SET_QQ_WARNING_LINK_STATE':
      return { ...state, quickQuoteWarningRouteLink: action.payload };
    case 'SET_BIRTHDAY_WARNING_LINK_STATE':
      return { ...state, birthdayWarningRouteLink: action.payload };
    case 'SET_DRUG_MODAL_STATE':
      return { ...state, drugModalState: action.payload };
    case 'SET_DOCTOR_MODAL_STATE':
      return { ...state, doctorModalState: action.payload };
    case 'SET_INVALID_ZIP_CODE':
      return { ...state, invalidZipModal: action.payload };
    case 'RESET_MODALS':
      return { ...initialState };
    default:
      return state;
  }
}
