import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

// Components
import VerifyNationalProducerNumber from './VerifyNationalProducerNumber';
import CreateAccountForm from './CreateAccountForm';

function SignupPage() {
  const dispatch = useDispatch();
  const {
    npnVerified,
    signupSuccessful,
  } = useSelector(store => store.signup);
  const { threeWayExploreMoreLink } = useSelector(store => store.theme);
  const { threeWayCall } = useSelector(store => store.theme.features);

  useEffect(() => {
    // Specific body class for removing empty scrollbar
    document.body.className = 'login-landing-body';

    // Odd case where a user logs out or refreshes and goes to sign up, rehydrate and re-open release notes
    dispatch({ type: 'HIDE_RELEASE_NOTES' });

    // Clear out all entered fields on reload
    dispatch({ type: 'CLEAR_SIGNUP' });
  }, []);
  if (threeWayCall) {
    return (
      <div className="signup-landing d-flex">
        <div className="vh-100 d-flex justify-content-end" style={{ position: 'relative', width: '50vw', whiteSpace: 'nowrap' }}>
          <div className="justify-content-center d-flex ms-4 my-4" style={{ width: '100%', border: '1px solid lightgrey' }}>
            {
              npnVerified
                ? (<CreateAccountForm />)
                : (<VerifyNationalProducerNumber />)
            }
          </div>
        </div>
        <div
          className="vh-100 d-flex flex-column"
          style={{
            position: 'relative',
            width: '50vw',
            marginRight: '2.5vw',
            marginLeft: '2.5vw',
          }}
        >
          <div style={{ opacity: signupSuccessful ? '0' : '1' }}>
            <div className="signup-disclaimer-3 my-4">
              <div className="text-primary fw-bold" style={{ fontSize: '28px' }}>Sell more with YourFMO</div>
              <br />
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" />
                {/* eslint-disable-next-line */}
                <u
                  style={{ cursor: 'pointer', color: '#4682b4' }}
                  onClick={() => window.open(threeWayExploreMoreLink)}
                >
                  3-way call recording
                </u>
                {' anytime and anywhere using your phone'}
              </div>
              <ul className="mb-0 ms-3">
                <li>No need to use the MMB Portal</li>
                <li>Yes–ALL agents, including Connecture and Sunfire</li>
              </ul>
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" /> Verify member eligibility using the Beneficiary Eligibility Query (BEQ) Tool</div>
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" /> Telephonic enrollment tool solution includes</div>
              <ul className="mb-0 ms-3">
                <li>Scope of Appointment (email, text (SMS), and voice)</li>
                <li>Medicare quoting and enrollment</li>
                <li>recording of all telephonic interactions</li>
              </ul>
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" /> All call transcriptions are available</div>
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" /> CMS and HIPAA compliant</div>
              <div><FontAwesomeIcon icon={faCheck} className="fs-4 text-success" /> Stored, downloadable, and archived for up to 10 years</div>
              <ul className="mb-0 ms-3">
                <li>Scope of Appointment</li>
                <li>Call recording</li>
              </ul>
            </div>
          </div>
          <div className="mb-4" style={{ height: '100%', opacity: signupSuccessful ? '0' : '1' }}>
            {
              npnVerified
                ? (
                  <div className="signup-disclaimer-2">
                    {'To complete your registration, please provide your contact information and choose your password.'}
                    <br />
                    <br />
                    <b>{'Note: your email address will be used as your user ID.'}</b>
                    <br />
                    <br />
                    {'Your password must...'}
                    <br />
                    <ul>
                      <li>contain 8 - 20 characters.</li>
                      <li>contain 3 of the following:</li>
                      <ul>
                        <li>uppercase letter (A-Z)</li>
                        <li>lowercase letter (a-z)</li>
                        <li>numeric character (0-9)</li>
                        <li>special character (!, @, #, S, etc.)</li>
                      </ul>
                    </ul>
                    <br />
                    {`Please enter your client contact phone number and address
                    so we can include this information on outbound notifications
                    to your clients.`}
                    <br />
                    <br />
                    {`Optional: If you have another NPN associated with an individual
                    or business entity, please enter it here. This is important if you
                    know that it is used by one or more carriers for your RTS certification`}
                  </div>
                )
                : (
                  <div className="signup-disclaimer-2">
                    {`Welcome to the YourFMO Medicare telephonic enrollment system.
                    Once you have completed certification by any Medicare carrier through
                    YourFMO, you can create an account and begin using the site.`}
                    <br />
                    <br />
                    {`Please enter your last name, your NPN (national producer number),
                    NPN Type (see below), and YourFMO activation number and
                    click "Next" to continue.`}
                    <br />
                    <br />
                    {`Some agents have both individual and business entity NPNs that are used
                    by carriers for ready to sell certification. Please enter your NPN and
                    designate if it is tied to your individual or business entity.
                    If you do not know which yours is, please select the 'Individual' option.`}
                  </div>
                )
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="signup-landing d-flex">
      <div className="signup-landing-left vh-100 d-flex" style={{ justifyContent: 'center' }}>
        <div className="signup-header-container" style={{ right: '0' }}>
          {
            npnVerified
              ? (<CreateAccountForm />)
              : (<VerifyNationalProducerNumber />)
          }
        </div>
      </div>
      <div className="signup-landing-right vh-100 d-flex flex-column">
        <div className="signup-header-container" style={{ left: '0', opacity: signupSuccessful ? '0' : '1' }}>
          {
            npnVerified
              ? (
                <div className="signup-disclaimer" style={{ width: '90%' }}>
                  {'To complete your registration, please provide your contact information and choose your password.'}
                  <br />
                  <br />
                  <b>{'Note: your email address will be used as your user ID.'}</b>
                  <br />
                  <br />
                  {'Your password must...'}
                  <br />
                  <ul>
                    <li>contain 8 - 20 characters.</li>
                    <li>contain 3 of the following:</li>
                    <ul>
                      <li>uppercase letter (A-Z)</li>
                      <li>lowercase letter (a-z)</li>
                      <li>numeric character (0-9)</li>
                      <li>special character (!, @, #, S, etc.)</li>
                    </ul>
                  </ul>
                  <br />
                  {`Please enter your client contact phone number and address
                  so we can include this information on outbound notifications
                  to your clients.`}
                  <br />
                  <br />
                  {`Optional: If you have another NPN associated with an individual
                  or business entity, please enter it here. This is important if you
                  know that it is used by one or more carriers for your RTS certification`}
                </div>
              )
              : (
                <div className="signup-disclaimer" style={{ maxWidth: '450px' }}>
                  {`Welcome to the YourFMO Medicare telephonic enrollment system.
                  Once you have completed certification by any Medicare carrier through
                  YourFMO, you can create an account and begin using the site.`}
                  <br />
                  <br />
                  {`Please enter your last name, your NPN (national producer number),
                  NPN Type (see below), and YourFMO activation number and
                  click "Next" to continue.`}
                  <br />
                  <br />
                  {`Some agents have both individual and business entity NPNs that are used
                  by carriers for ready to sell certification. Please enter your NPN and
                  designate if it is tied to your individual or business entity.
                  If you do not know which yours is, please select the 'Individual' option.`}
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
