import React, { useEffect } from 'react';

/*
  This is a component that wraps the `405` response of `/api/token-auth/captcha` and displays the Amazon WAF captcha to the user.
  If the `aws-waf-cookie` cookie is already set, this component will not display and will call a function passed to it (`setSolved`).
  Local development with this component is tricky because the cookie's domain cannot be `localhost` for mmb-api/Amazon WAF to ingest it.

  Note: Will not function if you've accessed the django admin without clearing their cookies (`XSRF-TOKEN` and `sessisonid`)
*/

function CaptchaFrame({
  src, // ${url}/api/token-auth/captcha?site=${site}
  visible = false,
  onSolve = () => {},
  setSolved = () => {},
  id,
  className,
  style,
}) {
  const iframeListener = (msg) => {
    if (msg.data === 'captcha_passed') {
      // aws-waf-cookie cookie present on dev.api domain
      // (also, ensure the django admin's XSRF-TOKEN/sessionid is cleared)
      setSolved();
      // above should be setting external item for any logic needed
      if (visible) {
        // User has entered email/pw and just now solved captcha
        onSolve();
        // otherwise they haven't entered email/pw yet but have the cookie already
        // so the external onSolve should fire independently in that view.
      }
    }
  };

  useEffect(() => {
    // This message is sent from the configured `site_config` captcha page
    window.addEventListener('message', iframeListener);
    return () => {
      window.removeEventListener('message', iframeListener);
    };
  });

  return (
    <div
      className={className}
      id={id}
      style={{
        display: visible ? '' : 'none',
        ...style,
      }}
    >
      <iframe
        style={{ height: '100%', width: '100%', minWidth: '350px', minHeight: '550px' }}
        id="iframe-container"
        title="MyMedicareBot - Captcha"
        src={src}
        scrolling="no"
      />
    </div>
  );
}

export default CaptchaFrame;
