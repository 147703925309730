/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/pro-solid-svg-icons';
import NavPage from '../../nav/NavPage';
import FooterNav from '../../nav/footernav';

// Components
import EnrollmentDisclaimer from '../../common/EnrollmentDisclaimer';
import UnsavedQuote from '../components/UnsavedQuote';
import SavedQuote from '../components/SavedQuote';
import LoaderComponent from '../../common/LoaderComponent';
import LoaderButton from '../../common/LoaderButton';

// Actions + Utils
import { loadShopper } from '../../../actions/shopper';
import { showPopup } from '../../../utils/popup';
import { canEnrollPlan } from '../../../utils/perms';
import { sortObjects } from '../../../utils/general';
import { removeEnrollSubmitted } from '../../../utils/cookie';

import { loadConfigs } from '../../../actions/config';

function QuotesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { quoteID } = useParams();
  const [loading, setLoading] = useState(false);
  const { hra } = useSelector(store => store);

  const {
    shopper,
    plan: { medicareList, medigapList },
    quote: {
      // Coming from the planlist page
      selectedPlan,
      // todo(joeysapp): shareQuoteID is not needed here?
      shareQuoteID,
      refreshQuotes,
      sortKey,
      sortInverse,
    },
  } = useSelector(store => store);
  const { quotes, username } = shopper;

  // note(joeysapp): Make sure the saved quote will be at the top on save (sorting)
  function loadQuotes() {
    setLoading(true);
    loadShopper(username)
      .then((data) => {
        dispatch({ type: 'SET_SHOPPER', payload: data }); // in case dob/phone is updated in enrollment portal
        dispatch({ type: 'SET_SHOPPER_QUOTES', payload: data.quotes });
        dispatch({ type: 'SET_REFRESH_QUOTES_EVENT' , payload: false });
        setRecentEnrollment(data.quotes);
        removeEnrollSubmitted();
      }).catch(() => {
        showPopup('Error', 'There was a problem loading the quotes.');
      }).finally(() => setLoading(false));
  }

  function loadHRAConfigs() {
    setLoading(true);
    loadConfigs('hra_form_agent_category')
      .then((data) => {
        dispatch({ type: 'SET_HRA_CONFIGS', payload: data });
      }).catch(() => {
        showPopup('Error', 'There was a problem loading the HRA information.'); // note (Jason): is this popup necessary?
      }).finally(() => setLoading(false));
  }

  useEffect(() => {
    loadHRAConfigs();
  }, []);

  useEffect(() => {
    if (refreshQuotes) {
      loadQuotes();
    }
  }, [refreshQuotes]);

  const [sortedQuotes, setSortedQuotes] = useState([]);
  const [currentEnrollment, setCurrentEnrollment] = useState({});
  useEffect(() => {
    // Handle premium/premium-inverse
    if (sortKey) {
      const key = sortKey.split('-')[0];
      // if a quote has an enrollment use the enrollment.updated instead of quote.updated
      const remappedQuotes = quotes.map(q => (q.enrollment) ? { ...q, updated: q.enrollment.updated } : q);
      const sorted = sortObjects(remappedQuotes, key, sortInverse);
      setSortedQuotes(sorted);
    }
  }, [sortKey, sortInverse, quotes, selectedPlan]);

  function setRecentEnrollment(quotes) {
    setCurrentEnrollment({});
    let recentQuotes = quotes.filter((q) => {
      if (q.enrollment && (!q.enrollment.submitted_flag && !q.enrollment.submit_date)) {
        return q.enrollment;
      }
    });
    recentQuotes.sort((q1, q2) => q2.enrollment.updated.localeCompare(q1.enrollment.updated));
    if (recentQuotes.length) {
      setCurrentEnrollment(recentQuotes[0]);
    }
  }

  function canEnroll() {
    const {
      planType,
      carrierID,
      state,
      cpsID,
      carrier,
      planYear,
    } = currentEnrollment;
    return canEnrollPlan({ planType, carrierID, planState: state, cpsID, carrier, planYear });
  }

  function handleClick() {
    if (canEnroll()) {
      dispatch({
        type: 'CONFIRM_MODAL_OPEN',
        quote: currentEnrollment.quoteID,
        planID: currentEnrollment.planID,
      });
    } else {
      const msg = `MMB does not support enrollment for ${currentEnrollment.carrier || 'this carrier'}`;
      showPopup('Error', msg);
    }
  }

  return (
    <NavPage activeLink="/plans" showShopperInfobar>
      <div className="mmb-container" id="enrollment">
        <div className="mmb-header d-flex align-items-center">
          Quotes
          {/* Sorting, Refresh */}
          <div
            className="ms-auto me-3 d-flex align-items-center pe-0 fw-normal fs-5"
            style={{ transition: 'all 1s ease', opacity: quotes.length !== 0 ? '1' : '0' }}
          >
            Sort by:
          </div>
          <form
            className="d-flex align-items-center me-3 ms-0 fs-5"
            style={{ transition: 'all 1s ease', opacity: quotes.length !== 0 ? '1' : '0' }}
          >
            <select
              className="form-control position-relative"
              id="quote-list-sort"
              style={{ width: '180px' }}
              type="select"
              name="sortKey"
              value={sortKey}
              onChange={(e) => {
                dispatch({ type: 'SET_QUOTE_LIST_SORT', payload: e.target.value });
                if (e.target.value === 'premium-inverse') {
                  dispatch({ type: 'SET_QUOTE_LIST_SORT_INVERSE', payload: true });
                } else {
                  dispatch({ type: 'SET_QUOTE_LIST_SORT_INVERSE', payload: false });
                }
              }}
            >
              <option value="updated">Last updated</option>
              <option value="created">Last created</option>
              <option value="premium-inverse">Price: Low to high</option>
              <option value="premium">Price: High to low</option>
            </select>
            <i
              className="fa fa-chevron-down"
              style={{ marginLeft: '-25px', pointerEvents: 'none', zIndex: '6' }}
            />
          </form>
          <LoaderButton
            className={`btn btn-primary ms-3 me-0 ps-4 pe-4 fw-bold fs-5`}
            id="refresh-quotes-button"
            loadingText="Loading.."
            loading={loading}
            text={(
              <span>
                <FontAwesomeIcon icon={faRedo} className="me-2" />
                Refresh
              </span>
            )}
            onClick={() => loadQuotes()}
          />
        </div>
      </div>
      <div className="mmb-content enroll-quotes-container">
        {/* Enrollment in progress header */ }
        {
          (currentEnrollment && currentEnrollment.planName && !loading) && (
            <div
              className="d-flex border border-success bg-success-bg fs-5 ps-4 pe-4 pt-2 pb-2 align-items-center"
              style={{ marginBottom: '25px', whiteSpace: 'nowrap', overflow: 'hidden' }}
            >
              This client is currently enrolling in
              <span
                className="ms-1 me-3 fst-italic text-decoration-underline"
                style={{ opacity: loading ? 0.5 : 1 }}
              >
                {currentEnrollment.planName}.
              </span>
              <LoaderButton
                className="fw-bold ps-4 pe-4 fs-5"
                color="primary"
                id="enroll-save-quote-button"
                text="Go to enrollment"
                style={{ maxHeight: '40px', opacity: !canEnroll() ? '0.5' : '1' }}
                disabled={!canEnroll()}
                onClick={() => handleClick()}
              />
              <EnrollmentDisclaimer
                className="ms-3 mt-0"
                style={{ whiteSpace: 'break-spaces', minHeight: 'auto', lineHeight: '15px' }}
                plan={{
                  carrierID: currentEnrollment ? currentEnrollment.carrierID : '0',
                  carrier: currentEnrollment ? currentEnrollment.carrier : '',
                  planType: currentEnrollment ? currentEnrollment.planType : '',
                  cpsID: currentEnrollment ? currentEnrollment.cpsID : '',
                  plan: { PlanYear: currentEnrollment ? currentEnrollment.planYear : '' },
                }}
              />
            </div>
        )}
        {(selectedPlan && selectedPlan.planName) ? <UnsavedQuote plan={selectedPlan} /> : null}
        {loading 
          ? (<LoaderComponent offsetY={(selectedPlan && selectedPlan.planName) ? '300px' : '0'} />)
          : (sortedQuotes.map((quote) => <SavedQuote quote={quote} key={quote.quoteID} />))
        }
        <div
          className="d-flex"
          style={{
            opacity: !selectedPlan && quotes.length === 0 ? '1' : '0',
            transition: 'all 1s ease',
          }}
        >
          <i>You have no saved quotes.</i>
          <span
            className="text-link ms-1 fw-bold"
            onClick={() => navigate('/plans')}
            role="button"
            tabIndex={0}
          >
            Go to plan list
          </span>
        </div>
      </div>
      <FooterNav
        backOnClick={() => navigate('/plans/list')}
        backButtonText="Back to plans"
        noPlanLink
        noNextLink
      />
    </NavPage>
  );
}

export default QuotesList;
