import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../utils/hocs';
import { canEnrollPlan } from '../../utils/perms';

class QuoteEnrollButton extends React.PureComponent {
  handleClick = () => {
    const { dispatch, navigate, plan } = this.props;
    dispatch({ type: 'CLEAR_EVENT' });
    navigate('/quotes');
    dispatch({ type: 'SET_SELECTED_PLAN', payload: plan });
  };

  render() {
    const {
      className,
      disabled,
      id,
      plan: {
        carrier,
        carrierID,
        cpsID,
        planType,
        plan: { PlanYear: planYear },
      },
      shopperState,
      style,
    } = this.props;

    let btnText = 'Quote/enroll';

    // if enrollment for the carrier is unavailable or agent doesn't have perms
    if (!canEnrollPlan({ planType, carrierID, planState: shopperState, cpsID, carrier, planYear })) {
      btnText = 'Quote';
    }

    return (
      <button
        className={`btn btn-primary d-flex align-items-center justify-content-center ${className}`}
        id={id}
        type="button"
        tabIndex={0}
        onClick={this.handleClick}
        disabled={disabled}
        style={style}
      >
        {btnText}
      </button>
    );
  }
}

QuoteEnrollButton.defaultProps = {
  id: '',
  plan: {},
  disabled: false,
  className: '',
  style: {},
};

function mapStateToProps(store) {
  return {
    shopperState: store.shopper.state,
  };
}

export default connect(mapStateToProps)(withRouterProps(QuoteEnrollButton));
