import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import DrugDetailTableRow from './DrugDetailTableRow';

// Constants
import coveragePhaseMap from './coveragePhaseMap';

// Utilities
import { firstCharUpper } from '../../../../utils/formatFunctions';

class DrugDetail extends React.PureComponent {
  render() {
    const {
      copays,
      coveragePhases,
      navigate,
      planYear,
      selectedPharmacy,
      style,
    } = this.props;

    const coveragePhaseKeys = [];
    let headerTitles = [
      {
        text: 'Medication',
        class: '',
      },
      {
        text: 'Retail cost',
        class: '',
      },
    ];

    // Insert given phases into our table header
    coveragePhases.forEach((phase) => {
      // only show coverage gap and post initial coverage phasefor plans 2024 or before
      const isGapOrPostInitialCoveragePhase = [20, 21].includes(Number(phase));
      if ((Number(planYear) > 2024) && isGapOrPostInitialCoveragePhase) return;
      headerTitles.push({
        text: firstCharUpper(coveragePhaseMap[phase].name),
        class: 'coverage-phase',
      });
      coveragePhaseKeys.push(coveragePhaseMap[phase].key);
    });

    headerTitles = [
      ...headerTitles,
      {
        text: 'Prior authorization',
        class: 'restrictions',
      },
      {
        text: 'Quantity limits',
        class: 'restrictions',
      },
      {
        text: 'Step therapy',
        class: 'restrictions',
      },
    ];

    return (
      <div className="plandetails-table plandetails-medication-cost-details" style={{ paddingRight: '20px', ...style }}>
        {
          !copays || copays.length === 0
            ? (
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <span className="ms-1">
                  <span
                    style={{
                      color: '#0058FF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (selectedPharmacy) {
                        navigate('/rx');
                      } else {
                        navigate('/rx/add/pharmacy');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    { selectedPharmacy ? 'Add medications' : 'Select a pharmacy' }
                  </span> to see medication cost details.
                </span>
              </div>
            )
            : (
              <table>
                <tbody>
                  <tr className="text-center fst-italic" style={{ lineHeight: '40px' }}>
                    <td colSpan="1" />
                    <td colSpan={coveragePhaseKeys.length}>
                      <div>Medication costs by coverage phase</div>
                    </td>
                    <td colSpan="3">
                      <div>Medication restrictions</div>
                    </td>
                  </tr>
                  {/* Header */}
                  <tr>
                    {headerTitles.map((title) => (
                      <th
                        className={title.class}
                        key={title.text}
                      >
                        {title.text}
                      </th>
                    ))}
                  </tr>
                  {/* Medication Rows */}
                  {copays.map((drug) => (
                    <DrugDetailTableRow
                      coveragePhaseKeys={coveragePhaseKeys}
                      drug={drug}
                      headerTitles={headerTitles}
                      key={`${drug.Label}-${drug.PurchasingQTY}`}
                    />
                  ))}
                </tbody>
              </table>
            )
        }
      </div>
    );
  }
}

DrugDetail.defaultProps = {
  copays: [],
  coveragePhases: [],
};

function mapStateToProps(store) {
  return {
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(withRouterProps(DrugDetail));
