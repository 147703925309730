/* eslint-disable arrow-body-style */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { useSelector } from 'react-redux';

import {
  handleChange,
  getOptions,
  getFieldKey,
} from '../../../../utils/hra';

import FormLabel from './FormLabel';

function Radio({
  boldLabel,
  disabled,
  label,
  name,
  className,
  labelClassName,
  id,
  options = [],
  onChangeCallback = () => {},
}) {
  const { fields, form, submittedFlag } = useSelector(store => store.hra);
  const customLabel = getFieldKey(form, name, 'label');
  const value = fields[name];

  let opts;
  const configOpts = getOptions(form, name) || [];
  if (configOpts.length) {
    opts = configOpts;
  } else {
    opts = options;
  }

  // Remark - need to determine if this is the desired logic,
  // const defaultValue = getFieldKey(form, name, 'defaultValue');
  // function unsetFieldOnSecondClick(newValue, oldValue) {
  //   if (newValue === oldValue) {
  //     handleChange({
  //       value: defaultValue || '',
  //       name,
  //       onChangeCallback,
  //     });
  //   }
  // }

  return (
    <>
      <FormLabel label={customLabel || label} name={name} id={id} className={labelClassName} />
      <div className={`${className}`}>
        {opts.map(option => {
          return (
            <div key={option.value || `radio-${option.label}`}>
              <div
                role="button"
                tabIndex={0}
                className="form-check my-1"
                style={{ paddingLeft: '1.75rem' }}
              >
                <input
                  className={['form-check-input', 'cursor-pointer'].join(' ')}
                  type="radio"
                  id={`${name}-${option.value || 'default'}`}
                  name={name}
                  value={option.value}
                  checked={value === option.value ? true : false}
                  readOnly={true}
                  disabled={submittedFlag}
                  onClick={!disabled
                    ? () => {
                      handleChange({
                        value: option.value,
                        name,
                        onChangeCallback,
                      });
                    }
                    : undefined}
                />
                <label
                  htmlFor={`${name}-${option.value || 'default'}`}
                  className={['form-check-label', 'cursor-pointer ', ((option.desc || boldLabel) && 'fw-bold')].join(' ')}
                  style={{ opacity: '100%' }}
                >
                  {option.label}
                </label>
                {option.desc && (
                  <div className="text-muted">
                    {option.desc}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
export default Radio;
