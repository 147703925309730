/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../utils/hocs';

// Actions
import { agentLogout } from '../../actions/agent';
import {
  doesNotificationExist,
  saveNotification,
} from '../../actions/notifications';
import { addCallNote, updateShopper, loadShoppers } from '../../actions/shopper';
import { pushTrackingEvent } from '../../actions/tracking';

// Components
import AddToCalendar from './notifications/AddToCalendar';
import AgentNotificationInput from './notifications/AgentNotificationInput';
import LoaderButton from '../common/LoaderButton';
import { Disposition } from '../shopper/components';
import StandardModal from '../common/StandardModal';

// Utilities
import { showPopup } from '../../utils/popup';
import { validateCallNoteText } from '../../utils/general';
import { setCookie } from '../../utils/cookie';

class ExitModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      callNoteAdded: false,
      statusUpdated: false,
      optIn: false,
      currentDay: '',
      date: '',
      endDate: '',
      time: '',
      endTime: '',
      interval: 'no-repeat',
      intervalDates: {
        Sun: false,
        Mon: false,
        Tues: false,
        Wed: false,
        Thurs: false,
        Fri: false,
        Sat: false,
      },
      notificationSaved: false,
      shopperSaving: false,
      shopperStatus: '',
    };
    this.handleCallNoteChange = this.handleCallNoteChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExit = this.handleExit.bind(this);
    this.handleOpt = this.handleOpt.bind(this);
    this.handleNotificationChange = this.handleNotificationChange.bind(this);
    this.handleIntervalDates = this.handleIntervalDates.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const {
      shopper,
    } = this.props;

    const currentHour = moment().format('HH');
    const currentDay = moment().add(1, 'days').format('dddd');
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    this.setState({
      date: tomorrow,
      time: `${currentHour}:00`,
      endTime: `${currentHour}:30`,
      currentDay,
      shopperStatus: shopper.status,
    });
  }

  handleOpt = (event) => {
    this.setState({ optIn: event.target.checked });
  };

  handleNotificationChange = (event) => {
    if (event.target.id === 'time') {
      let hour = Number(event.target.value.split(':')[0]);
      let minutes = Number(event.target.value.split(':')[1]) + 30;

      if (minutes >= 60) {
        minutes -= 60;
        hour += 1;
      }

      this.setState({
        [event.target.id]: event.target.value,
        endTime: `${hour}:${minutes}`,
      });
    } else if (event.target.id === 'date') {
      const currentDay = moment(event.target.value).format('dddd');
      this.setState({
        [event.target.id]: event.target.value,
        currentDay,
      });
    } else {
      this.setState({ [event.target.id]: event.target.value });
    }
  };

  handleIntervalDates = (e) => {
    const target = (e.target.id);
    this.setState((prevState) => ({
      intervalDates: { ...prevState.intervalDates, [target]: !prevState.intervalDates[target] },
    }));
  };

  handleCallNoteChange = ({ target }) => {
    this.setState({ note: target.value });
  };

  handleChange = (event) => {
    const {
      dispatch,
    } = this.props;

    const key = event.target.name;
    const val = event.target.value;
    dispatch({
      type: 'UPDATE_SHOPPER_KEY',
      payload: { val, key },
    });
  };

  initializeDashboard = (setSelectedTab = true) => {
    const { dispatch } = this.props;
    dispatch({ type: 'CLEAR_SHOPPER' });
    dispatch({ type: 'CLEAR_PLAN_DATA' });
    dispatch({ type: 'RESET_PLAN_TYPE_PAGE' });
    dispatch({ type: 'CLEAR_CI_DATA' });
    dispatch({ type: 'CLEAR_DOCTORS_PLANS' });
    dispatch({ type: 'CLEAR_PHARMACIES' });
    dispatch({ type: 'CLEAR_COMPARE_PLANS' });
    dispatch({ type: 'SET_COMPARE_FOOTER', payload: false });
    dispatch({ type: 'RESET_SHOPPER_LOADERS' });
    dispatch({ type: 'CLEAR_SOA' });
    dispatch({ type: 'CLEAR_QUOTE_LIST' });
    dispatch({ type: 'CLEAR_CARRIER_FILTER' });
    dispatch({ type: 'CLEAR_TELEPHONY' });
    if (setSelectedTab) {
      dispatch({ type: 'SET_SELECTED_TAB', payload: 'All leads' });
    }
    // clear cookie state for county and effective date used by sofphone modal
    setCookie('effectiveDate', '');
    setCookie('countyFips', '');
  };

  handleSubmit = () => {
    const {
      agentEmail,
      agentUsername,
      shopper,
      dispatch,
    } = this.props;

    const {
      date,
      endDate,
      interval,
      intervalDates,
      note,
      optIn,
      time,
    } = this.state;

    this.setState({
      shopperSaving: true,
    });

    if (interval === 'custom') {
      if (Object.values(intervalDates).filter((d) => d).length === 0) {
        showPopup('Error', 'Please select recurrence date(s).');
        return;
      }

      if (!endDate) {
        showPopup('Error', 'Please enter end date.');
        return;
      }

      if (endDate < date) {
        showPopup('Error', 'Please choose an end date after the start date.');
        return;
      }
    }

    if (optIn) {
      const notificationObject = {
        start_date: (new Date(`${date} ${time}`).toISOString()),
        end_date: endDate ? (new Date(`${endDate} ${time}`).toISOString()) : null,
        interval,
        days: {
          sunday: intervalDates.Sun,
          monday: intervalDates.Mon,
          tuesday: intervalDates.Tues,
          wednesday: intervalDates.Wed,
          thursday: intervalDates.Thurs,
          friday: intervalDates.Fri,
          saturday: intervalDates.Sat,
        },
      };
      if (doesNotificationExist(notificationObject, shopper.username)) {
        showPopup('Error', 'Notification already exists.');
        return;
      }
      saveNotification(notificationObject, shopper.username)
        .then(() => {
          this.setState({
            notificationSaved: true,
            optIn: false,
          });
        });
    }
    if (note !== '') {
      if (validateCallNoteText(note)) {
        showPopup('Error', 'Call Note Text is Invalid.');
      } else {
        addCallNote(
          shopper.username,
          note,
        ).then(() => {
          this.setState({
            callNoteAdded: true,
          });
        });
      }
    }

    updateShopper(shopper)
      .then(() => {
        pushTrackingEvent(
          'disposition_update@desktop/shopper/exit_modal',
          agentUsername,
          shopper.username,
        );
        // wait to reload the shopper list so that ES has time
        // to reindex the record
        setTimeout(() => (
          loadShoppers({
            new_load: true,
            user_who_created: agentEmail,
          })
        ), 2000);
      }).catch(() => {
        showPopup('Error', 'There was a problem updating the Shopper.');
        this.setState({
          shopperSaving: false,
        });
      }).finally(() => {
        if (this.props.isLogout) agentLogout();
        else {
          setTimeout(() => {
            this.setState({
              statusUpdated: true,
              shopperSaving: false,
            });
            dispatch({ type: 'HIDE_MODAL', payload: 'exitModal' });
            this.initializeDashboard();
          }, 2000);
        }
      });
  };

  handleBack = () => {
    this.props.dispatch({ type: 'HIDE_MODAL', payload: 'exitModal' });
    this.props.navigate(-1);
  };

  handleHideModal = () => {
    const { dispatch, isLogout } = this.props;

    return new Promise((resolve) => {
      if (this.state.shopperSaving) {
        resolve(false);
        return;
      }
      dispatch({ type: 'HIDE_MODAL', payload: 'exitModal' });
      if (isLogout) agentLogout();
      else {
        this.initializeDashboard(false);
        /* Closing our modal must complete animation first */
        window.setTimeout(() => {
          resolve(true);
        }, 150);
      }
    });
  };

  handleExit = () => {
    const {
      dispatch,
      navigate,
      exitRoute,
    } = this.props;

    dispatch({ type: 'HIDE_MODAL', payload: 'exitModal' });
    // Routes are primarily links, i.e. /medcab
    if (exitRoute && exitRoute[0] === '/') {
      navigate(exitRoute);
    } else if (exitRoute === 'disposition') {
      this.setState({
        statusUpdated: false,
      });
    } else if (exitRoute === 'logout') {
      dispatch({ type: 'CLEAR_SHOPPER' });
      agentLogout();
    } else if (exitRoute.indexOf('https') !== -1) {
      window.open(exitRoute, 'MyMedicareBot PTC Enrollment System Tutorial');
    }
  };

  render() {
    const {
      shopper,
      notifications,
      exitModal,
      agentNotifications,
      leadStatusOptions = [],
      isLogout,
    } = this.props;

    const {
      callNoteAdded,
      currentDay,
      note,
      notificationSaved,
      optIn,
      shopperSaving,
      statusUpdated,
    } = this.state;

    const updateForm = (
      <>
        <div className="px-5 pt-1">
          Be sure to update the disposition and add a call note for <strong>{shopper.firstName} {shopper.lastName}</strong>.
        </div>
        <div className="px-5 mt-3">
          <Disposition
            title="Status"
            name="status"
            type="select"
            options={leadStatusOptions.map((o) => ({ val: o.value, desc: o.description }))}
            placeholder=""
            value={shopper.status}
            onChange={this.handleChange}
          />
        </div>
        <div className="px-5">
          <div className="">Note</div>
          <form>
            <div className="form-group" style={{ marginTop: '.35rem' }}>
              <textarea
                className="form-control border border-grey"
                name="message"
                onChange={this.handleCallNoteChange}
                type="textarea"
                value={note}
              />
            </div>
          </form>
        </div>
        {agentNotifications
          ? (
            <div className="px-5">
              <AgentNotificationInput
                values={this.state}
                handleOpt={this.handleOpt}
                handleNotificationChange={this.handleNotificationChange}
                handleIntervalDates={this.handleIntervalDates}
                notifications={notifications}
                username={shopper.username}
              />
            </div>
          ) : null}
        <div className="d-flex flex-row px-5 my-3 py-2 align-items-center justify-content-between">
          <LoaderButton
            color="primary"
            loading={shopperSaving}
            loadingText="Saving..."
            onClick={() => this.handleSubmit()}
            text={isLogout ? 'Save & Logout' : 'Save & Close'}
            type="button"
            className="px-4 text-bold"
            id="exit-modal-save"
          />
          {!isLogout && (
          <button
            className="btn btn-outline-primary px-4 text-bold"
            onClick={() => this.handleBack()}
            type="button"
            disabled={shopperSaving}
            id="exit-modal-return"
          >
            Return to client
          </button>
          )}

        </div>
      </>
    );

    let notificationSavedStatus = true;
    if (optIn && !notificationSaved) {
      notificationSavedStatus = false;
    }

    let hasNotification = false;
    const filteredNotifications = notifications.filter((n) => n.shopper === shopper.username);
    if (filteredNotifications.length !== 0) {
      hasNotification = true;
    }

    const updatedScreen = (
      <>
        <div className="m-auto pt-5 pb-5">
          The client has been saved
          {callNoteAdded ? ' and a note has been added' : null}
          !
        </div>
        {
          notificationSaved || hasNotification
            ? (
              <div className="m-auto py-3 px-3">
                <AddToCalendar
                  values={hasNotification
                    ? ({
                      date: filteredNotifications[0].start_date.substring(0, 10),
                      time: moment(filteredNotifications[0].start_date).format('HH:mm'),
                      interval: filteredNotifications[0].interval,
                      intervalDates: {
                        Sun: filteredNotifications[0].days.sunday,
                        Mon: filteredNotifications[0].days.monday,
                        Tues: filteredNotifications[0].days.tuesday,
                        Wed: filteredNotifications[0].days.wednesday,
                        Thurs: filteredNotifications[0].days.thursday,
                        Fri: filteredNotifications[0].days.friday,
                        Sat: filteredNotifications[0].days.saturday,
                      },
                      endDate: filteredNotifications[0].end_date ? filteredNotifications[0].end_date.substring(0, 10) : '',
                      endTime: moment(filteredNotifications[0].start_date).add(30, 'minutes').format('HH:mm'),
                      currentDay,
                      note,
                    })
                    : this.state}
                  shopperFName={shopper.firstName}
                  shopperLName={shopper.lastName}
                />
              </div>
            ) : null
        }
        <div className="d-flex flex-row px-3 pb-3 mt-2 align-items-center">
          <span
            className="me-auto"
            onClick={() => {
              this.handleHideModal()
                .then((res) => {
                  /* If user stays on Shopper page, they may need to re-save */
                  if (res) {
                    this.setState({
                      statusUpdated: false,
                    });
                  }
                });
            }}
            role="button"
            tabIndex={0}
            id="exit-modal-return"
          >
            <FontAwesomeIcon
              style={{ color: 'black' }}
              icon={faAngleLeft}
              className="fa-lg me-2"
            />
            <b style={{ color: 'black' }}>
              Return to client
            </b>
          </span>
          <LoaderButton
            color="primary"
            onClick={this.handleExit}
            text="Continue"
            type="button"
            style={{ paddingRight: '25px', paddingLeft: '25px', fontWeight: '700' }}
            id="exit-modal-continue"
          />
        </div>
      </>
    );

    return (
      <StandardModal
        isOpen={exitModal}
        /* Maintain size to prevent jarring UX */
        style={{ minWidth: '725px', width: '725px' }}
        closeModalAction={() => this.handleHideModal()}
        headerClasses="ps-5"
        title="Update client"
        size={statusUpdated && notificationSavedStatus ? 'md' : 'lg'}
      >
        {statusUpdated && notificationSavedStatus
          ? updatedScreen
          : updateForm}
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    agentUsername: store.agent.username,
    agentEmail: store.agent.email,
    exitModal: store.modal.exitModal,
    exitRoute: store.modal.exitRoute,
    isLogout: store.modal.exitRoute === 'logout',
    shopper: store.shopper,
    agentName: store.agent.username,
    notifications: store.notifications.list,
    agentNotifications: store.theme.features.agentNotifications,
    leadStatusOptions: store.leadStatus.options,
  };
}

export default connect(mapStateToProps)(withRouterProps(ExitModal));
