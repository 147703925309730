import React from 'react';
import ReactPagination from 'react-js-pagination';

function Pagination({
  // function that should accept an object with { page: pageNum }
  handleLoadData = () => {},
  // function that sets the active page for a previously loaded page. Accepts new page number as an arg.
  setActivePage = () => {},
  page,
  pageSize,
  totalPages,
  totalCount,
  dataBook,
}) {
  const pageCountStart = ((page - 1) * pageSize) + 1;
  let pageCountEnd = page * pageSize;
  if (totalPages === page) pageCountEnd = totalCount;

  return (
    <div className="d-flex flex-row m-3 position-relative">
      <div className="d-flex ms-0 position-absolute">
        <span className="text-muted">
          <i>{`${pageCountStart}-${pageCountEnd} of ${totalCount}`}</i>
        </span>
      </div>
      <ReactPagination
        activePage={page}
        itemsCountPerPage={pageSize}
        totalItemsCount={totalCount || 0}
        pageRangeDisplayed={5}
        innerClass="pagination mb-0 ms-auto me-auto"
        itemClass="page-item"
        linkClass="page-link"
        onChange={(pageNum) => {
          if (dataBook[pageNum - 1]) {
            setActivePage(pageNum);
          } else {
            handleLoadData({ page: pageNum });
          }
        }}
      />
    </div>
  );
}

export default Pagination;
