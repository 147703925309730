const initialState = {
  doctorSearchList: [],
  selectedSpecialty: '',
  selectedRadius: 15,
  selectedZip: '',
  deleteDoctor: '',
  errorMessage: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_DOCTORS':
      return { ...state, doctorSearchList: action.payload };
    case 'SET_DOCTORS_SPECIALTY':
      return { ...state, selectedSpecialty: action.payload };
    case 'SET_DOCTORS_RADIUS':
      return { ...state, selectedRadius: action.payload };
    case 'SET_DOCTORS_ZIPCODE':
      return { ...state, selectedZip: action.payload };
    case 'SET_DELETE_DOCTOR':
      return { ...state, deleteDoctor: action.payload };
    case 'SET_DOCTORS_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'CLEAR_DELETE_DOCTOR':
      return { ...state, deleteDoctor: '' };
    case 'CLEAR_DOCTORS':
      return {
        ...state,
        doctorSearchList: [],
      };
    case 'RESET_DOCTOR_SEARCH':
      return {
        ...state,
        selectedSpecialty: '',
        selectedRadius: 15,
        selectedZip: '',
        deleteDoctor: '',
      };
    default:
      return state;
  }
}
