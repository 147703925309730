import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import HTMLReactParser from 'html-react-parser';

function Benefit({ header, description, html = false }) {
  return (
    <div className="col-6">
      <div className="fw-bold" style={{ lineHeight: '1.4rem' }}>{header}</div>
      <div className="mb-3" style={{ lineHeight: '1.4rem' }}>
        {(html && typeof description === 'string')
          ? HTMLReactParser(description)
          : description}
      </div>
    </div>
  );
}

export default function Benefits({ plan = {} }) {
  const dispatch = useDispatch();
  const { displayedBenefits = [] } = useSelector(store => store.benefits);
  const { plan: docurobotPlan } = plan;

  function fmtBenefit(path = '') {
    let result;
    const array = path.split('.');

    if (array.length === 1) {
      result = plan.plan[array[0]];
    }
    if (array[0] === 'ExtraBenefits') {
      const { ExtraBenefits = [] } = docurobotPlan;
      const benefit = ExtraBenefits.filter(b => b.BenefitName === array[1])[0] || {};
      const { BenefitValue } = benefit;
      result = BenefitValue;
    }

    if (result === true || result === 'Yes') {
      return (
        <>
          <FontAwesomeIcon icon={faCheck} className="fs-4 text-success" style={{ verticalAlign: '-0.25rem' }} />
          <span className="ms-2">Covered</span>
        </>
      );
    }
    if (result === false || result === 'No') {
      return (
        <>
          <FontAwesomeIcon icon={faTimes} className="fs-4 text-nbgrey" style={{ verticalAlign: '-0.25rem' }} />
          <span className="ms-2">Not Covered</span>
        </>
      );
    }
    return result || '';
  }

  return (
    <div
      className="flex-column p-4"
      style={{ width: plan.planType === 'PDP' ? '50%' : '44%', lineHeight: '17px' }}
    >
      <div className="row">
        {
          displayedBenefits.map(benefit => (
            <Benefit
              header={benefit.name}
              description={fmtBenefit(benefit.path)}
              html={benefit.htmlFormat}
              key={benefit.name}
            />
          ))
        }
        <span
          className="mmb-link col"
          onClick={() => dispatch({ type: 'SET_BENEFITS_MODAL_PLANTYPE', payload: plan.planType }) && dispatch({ type: 'SHOW_MODAL', payload: 'benefitsModal' })}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faPencil} className="pe-1" />Customize
        </span>
      </div>
    </div>
  );
}
