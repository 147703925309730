import axios from 'axios';
import { fmtReport } from '../common/utils/format';

export function getReports() {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/reports/`,
    headers: { Authorization: `Token ${token}` },
  }).then(({ data = [] }) => data.map(d => fmtReport(d)));
}

export function fetchQSEmbedURL(id, type) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/quicksight/`,
    headers: { Authorization: `Token ${token}` },
    params: { id, type },
  });
}
