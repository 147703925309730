import { omit } from 'lodash';

import { MEDICAL_TYPE_FILTERS } from '../constants';

const initialState = {
  medicareList: [],
  medigapList: [],
  medicalTypeFilters: MEDICAL_TYPE_FILTERS,
  premiumLimitFilter: '',
  rxFilter: false,
  doctorFilter: false,
  planDetailsID: '',
  planDetailsType: '',
  planDetailsCarrier: '',
  planDetailsName: '',
  carrierFilters: {
    MA: [],
    MAPD: [],
    PDP: [],
  },
  // Toggled on planlist to filter based on theme.enrollCarriers
  enrollCarriersFilter: false,
  snpFilters: {
    showNonSnp: true,
    showSnp: false,
  },
  planSort: 'totalAnnualOOPC',
  compareLists: {
    mapd: [],
    pdp: [],
  },
  planSortSelection: 'totalAnnualOOPC',
  networkView: 'STANDARD',
  recommendedPDP: null,
  recommendedMAPD: null,
  extraBenefitsKey: [],
  doctorPlans: {},
  enrollDisabledPlans: [],
  benefitDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_DOCTOR_PLANS': {
      const { npi, plans } = action.payload;
      return { ...state, doctorPlans: { ...state.doctorPlans, [npi]: plans } };
    }
    case 'REMOVE_DOCTOR_PLANS':
      return { ...state, doctorPlans: omit(state.doctorPlans, Number(action.payload)) };
    case 'SET_RECOMMENDED_PDP':
      return { ...state, recommendedPDP: action.payload };
    case 'SET_RECOMMENDED_MAPD':
      return { ...state, recommendedMAPD: action.payload };
    case 'SET_PLAN_SORT':
      return { ...state, planSort: action.payload };
    case 'SET_NETWORK_VIEW':
      return { ...state, networkView: action.payload };
    case 'SET_SNP_FILTER': {
      // Object of form { nonSnp: false } ... { snp: true }
      const filterValues = action.payload;
      return {
        ...state,
        snpFilters: {
          ...state.snpFilters,
          ...filterValues,
        },
      };
    }
    case 'SET_CARRIER_FILTER':
      return { ...state, carrierFilters: { ...state.carrierFilters, ...action.payload } };
    case 'SET_CARRIER_FILTER_STATUS':
      return { ...state, carrierFilters: { ...state.carrierFilters, [action.planType]: action.payload } };
    case 'CLEAR_CARRIER_FILTER':
      return { ...state, carrierFilters: initialState.carrierFilters };
    case 'SET_MEDICARE_PLAN_LIST':
      return { ...state, medicareList: action.payload };
    case 'SET_MEDIGAP_PLAN_LIST':
      return { ...state, medigapList: action.payload };
    case 'SET_FILTERED_MEDICARE_PLANS':
      return { ...state, medicareFiltered: action.payload };
    case 'TOGGLE_PLAN_SAVED':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload);
          if (plan.planID === planID) {
            updatePlan.meta.saved = !plan.meta.saved;
          }
          return updatePlan;
        }),
      };
    // todo(joeysapp): MYR-update-plan-compare-functionality
    case 'ADD_PLAN_COMPARE':
      return {
        ...state,

      };
    case 'REMOVE_PLAN_COMPARE':
      return {
        ...state,

      };
    case 'TOGGLE_PLAN_COMPARE':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload);
          if (plan.planID === planID) {
            updatePlan.meta.compare = !plan.meta.compare;
          }
          return updatePlan;
        }),
      };
    case 'SET_COMPARE_PLAN_TYPE':
      return {
        ...state,
        comparePlanType: action.payload,
      };
    case 'TOGGLE_PLAN_CARD_EXPAND':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload);
          if (plan.planID === planID) {
            updatePlan.meta.expanded = !plan.meta.expanded;
          }
          return updatePlan;
        }),
      };
    case 'UPDATE_PLAN_DETAILS':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload.PlanID);
          if (plan.planID === planID) {
            updatePlan.plan = action.payload;
            updatePlan.meta.details = true;
          }
          return updatePlan;
        }),
      };
    case 'SET_MEDICARE_DOCUMENTS':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload.planId);
          if (plan.planID === planID) {
            updatePlan.plan = { ...plan.plan, PlanDocuments: action.payload.documents };
            updatePlan.meta.documents = true;
          }
          return updatePlan;
        }),
      };
    case 'SET_PLAN_META':
      return {
        ...state,
        medicareList: state.medicareList.map((plan) => {
          const updatePlan = plan;
          const planID = Number(action.payload.PlanID);
          if (plan.planID === planID) {
            updatePlan.meta = { ...plan.meta, ...action.payload.meta };
          }
          return updatePlan;
        }),
      };
    case 'UPDATE_COMPARE_LIST':
      return {
        ...state,
        medicareCompareList: action.payload,
      };
    case 'UPDATE_MEDIGAP_COMPARE_LIST':
      return {
        ...state,
        medigapCompareList: action.payload,
      };
    case 'TOGGLE_PLAN_MEDICAL_FILTERS':
      if (state.medicalTypeFilters.includes(action.payload)) {
        return {
          ...state,
          medicalTypeFilters: state.medicalTypeFilters.filter((filter) => filter !== action.payload),
        };
      }
      return {
        ...state,
        medicalTypeFilters: [...state.medicalTypeFilters, action.payload],
      };
    case 'TOGGLE_PLAN_RX_FILTER':
      return {
        ...state,
        rxFilter: !state.rxFilter,
      };
    case 'TOGGLE_PLAN_DOCTOR_FILTER':
      return {
        ...state,
        doctorFilter: !state.doctorFilter,
      };
    case 'TOGGLE_PLAN_AVAILABLE_ENROLLMENT':
      return {
        ...state,
        enrollCarriersFilter: !state.enrollCarriersFilter,
      };
    case 'SET_PREMIUM_LIMIT_FILTER':
      return {
        ...state,
        premiumLimitFilter: action.payload,
      };
    case 'SET_DISABLED_PLANS':
      return { ...state, enrollDisabledPlans: action.payload };
    case 'SET_PLAN_DETAILS':
      return {
        ...state,
        planDetailsID: action.payload.planId,
        planDetailsType: action.payload.planType,
        planDetailsCarrier: action.payload.planCarrier,
        planDetailsName: action.payload.planName,
      };
    case 'SET_PLAN_KEY':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_MEDICARE_DATA':
      return {
        ...state,
        medicareList: [],
      };
    case 'CLEAR_MEDIGAP_DATA':
      return {
        ...state,
        medigapList: [],
      };
    case 'LOAD_EXTRA_BENEFITS_KEY':
      return {
        ...state,
        extraBenefitsKey: action.payload,
      };
    case 'CLEAR_DOCTORS_PLANS':
      return {
        ...state,
        doctorPlans: {},
      };
    case 'CLEAR_PLAN_DATA':
      return {
        ...initialState,
        extraBenefitsKey: state.extraBenefitsKey,
        doctorPlans: state.doctorPlans,
        enrollDisabledPlans: state.enrollDisabledPlans,
        carrierFilters: state.carrierFilters,
      };
    default:
      return state;
  }
}
