import Cookies from 'js-cookie';

const ENROLLMENT_SUBMITTED_KEY = 'enrollment_submitted';

export function getEnrollSubmitted() {
  return Cookies.get(ENROLLMENT_SUBMITTED_KEY);
}

export function removeEnrollSubmitted() {
  return Cookies.remove(ENROLLMENT_SUBMITTED_KEY);
}

export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookie(key, value) {
  const isDev = process.env.NODE_ENV === 'development';
  const domain = isDev ? 'localhost' : '.mymedicarebot.com';
  Cookies.set(key, value, { domain });
  return true;
}

export function setEnrollmentToken(token) {
  const isDev = process.env.NODE_ENV === 'development';
  const { hostname } = window.location;
  const domainParts = hostname.split('.');
  const tld = domainParts.at(-1);
  const sld = domainParts.at(-2);
  const domain = isDev ? 'localhost' : `.${sld}.${tld}`;
  const expires = new Date(new Date().getTime() + 60 * 1000);
  Cookies.set('enrollmentToken', token, { domain, expires });
  return true;
}

export function setHRAToken(token) {
  const isDev = process.env.NODE_ENV === 'development';
  const { hostname } = window.location;
  const domain = isDev ? 'localhost' : `.${hostname}`;
  const expires = new Date(new Date().getTime() + 60 * 1000);
  Cookies.set('hraToken', token, { domain, expires });
  return true;
}
