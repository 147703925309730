import axios from 'axios';
import { store } from '../store';

/**
 * Pull down the latest published translations.
 */

export function loadTranslations(hash) {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/translation/`,
      params: { hash },
    }).then(({ data }) => {
      store.dispatch({ type: 'LOAD_TRANSLATIONS', payload: { ...data } });
      resolve();
    }).catch((err) => {
      reject(err);
    });
  });
}

/**
 * Check for any published translations updates.
 */

export function validateTranslations() {
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/translation/`,
    }).then(({ data }) => {
      const current = store.getState().translations.hash;
      const { hash } = data;

      if (hash !== current) {
        loadTranslations(hash).then(() => {
          resolve();
        });
      }
    }).catch((err) => {
      reject(err);
    });
  });
}
