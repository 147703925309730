import React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { getFieldKey } from '../../../../utils/hra';

function RequiredStar() {
  return <span className="me-1 fw-bold fs-4 text-danger">*</span>;
}

// lifted from enrollment-portal (common candidate?)
// https://github.com/mymedicarebot/enrollment-portal/blob/dev/src/views/enroll/components/inputs/FormLabel.jsx
function FormLabel({
  className,
  id,
  name,
  label,
  required,
  disabled,
}) {
  // Allow FormLabel to find customLabels to place field values without inputs
  const { form } = useSelector(store => store.hra);
  const customLabel = getFieldKey(form, name, 'label') || '';

  function parseLine(line) {
    let modifiedLine = line;
    let boldClass = '';
    let italicClass = '';
    let underlineClass = '';
    let strikeClass = '';
    if (line.search('<b>') !== -1) {
      boldClass = 'fw-bold';
      modifiedLine = modifiedLine.replace('<b>', '');
    }
    if (line.search('<i>') !== -1) {
      italicClass = 'fst-italic';
      modifiedLine = modifiedLine.replace('<i>', '');
    }
    if (line.search('<u>') !== -1) {
      underlineClass = 'text-decoration-underline';
      modifiedLine = modifiedLine.replace('<u>', '');
    }
    if (line.search('<s>') !== -1) {
      strikeClass = 'text-decoration-line-through';
      modifiedLine = modifiedLine.replace('<s>', '');
    }

    return (
      <span className={`${boldClass} ${italicClass} ${underlineClass} ${strikeClass}`}>
        {modifiedLine}
      </span>
    );
  }

  function parseLabelArray(labelArray, req) {
    // if the label doesn't have any break tags then we don't want
    // the additional padding that a <p> tag creates
    if (labelArray.length === 1) {
      if (req) return <><RequiredStar />{parseLine(labelArray[0])}</>;
      return parseLine(labelArray[0]);
    }
    return labelArray.map((line, idx) => {
      if ((idx === 0) && req) {
        return (
          <p key={`${line}`}>
            <RequiredStar />
            {parseLine(line)}
          </p>
        );
      }
      /* eslint-disable react/no-array-index-key */
      return (
        <p key={`${line}-${idx}`}>
          {parseLine(line)}
        </p>
      );
    });
  }

  const splitOnBreakTags = label ? label.split('<br>') : customLabel.split('<br>');

  return (
    <label
      htmlFor={disabled ? null : name}
      alt={label}
      className={[
        id ? 'd-flex' : '',
        'mb-2',
        className,
      ].join(' ')}
    >
      {id
        ? (
          <div className="me-1">
            {id}.
          </div>
        )
        : null}
      {parseLabelArray(splitOnBreakTags, required)}
    </label>
  );
}

export default FormLabel;
