import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { store } from '../store';

export function logErrorSuccess(error) {
  store.dispatch({
    type: 'LOG_ERROR',
    payload: error,
  });
}

export function doesNotificationExist(data, shopperUsername) {
  const { list } = store.getState().notifications;

  let notificationExists = false;
  const newNotification = {
    ...data,
    end_date: data.end_date ? new Date(data.end_date) : null,
    shopper: shopperUsername,
    start_date: data.start_date ? new Date(data.start_date) : null,
  };
  list.forEach((notif) => {
    // Copy the entire notification except id/shopper_name
    const comparisonNotification = {
      days: notif.days,
      end_date: notif.end_date ? new Date(notif.end_date) : null,
      interval: notif.interval,
      shopper: notif.shopper,
      start_date: notif.start_date ? new Date(notif.start_date) : null,
    };
    if (_.isEqual(comparisonNotification, newNotification)) {
      notificationExists = true;
    }
  });
  return notificationExists;
}

export function deleteNotification(id) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'DELETE',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/notification/${id}/`,
      headers: { Authorization: `Token ${token}` },
    }).then((res) => {
      const { list } = store.getState().notifications;
      const newList = list.filter((n) => n.id !== id);
      store.dispatch({
        type: 'LOAD_NOTIFICATIONS',
        payload: newList,
      });
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function loadNotifications() {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/notification/`,
      headers: { Authorization: `Token ${token}` },
    }).then((res) => {
      const notifications = res.data;
      const notificationsToLoad = [];
      notifications.forEach((n) => {
        if (n.interval === 'no-repeat' && (moment(n.start_date).diff(moment(new Date())) < 0)) {
          deleteNotification(n.id);
        } else {
          notificationsToLoad.push(n);
        }
      });
      store.dispatch({
        type: 'LOAD_NOTIFICATIONS',
        payload: notificationsToLoad,
      });
      resolve(notificationsToLoad);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function saveNotification(data, shopperUsername) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/notification/`,
      headers: { Authorization: `Token ${token}` },
      params: { shopper: shopperUsername },
      data,
    }).then((res) => {
      loadNotifications();
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function updateNotification(id, data) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'PUT',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/notification/${id}/`,
      headers: { Authorization: `Token ${token}` },
      data,
    }).then((res) => {
      loadNotifications();
      resolve(res);
    }).catch((err) => {
      reject(err);
    });
  });
}
