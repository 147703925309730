/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../utils/hocs';
import {
  AWAIT_STATE,
} from '../../constants';
import MmbDropdown from '../common/Dropdown';

// Actions
import {
  updateShopper, loadShoppers, getShopperCount,
} from '../../actions/shopper';

// Components
import EditNotificationButton from '../modal/notifications/EditNotificationButton';
import InactiveLeadsDropdown from './InactiveLeadsDropdown';
import CallsDropdown from './CallsDropdown';
import { Highlight } from '../../guide';
import { CallCount } from './components';

// Utilities
import { formatPhoneNumber } from '../../utils/general';
import { showPopup } from '../../utils/popup';

class Shopper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inactiveLeadsExpanded: false,
      callsExpanded: false,
    };
    this.handleChangeClientType = this.handleChangeClientType.bind(this);
  }

  handleLoadShopper = async (cellId) => {
    if (cellId === 'dashboard-shopper-list-shopper-disposition') return;
    if (cellId === 'dashboard-shopper-list-shopper-calls') return;
    const { dispatch, navigate, shopper } = this.props;
    const { username } = shopper;

    if (shopper.isActive) {
      await Promise.all([
        dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: AWAIT_STATE }),
        dispatch({
          type: 'SET_SHOPPER',
          payload: {
            ...shopper, cities: [], pharmacyList: [], quotes: [],
          },
        }),
        dispatch({ type: 'HIDE_MODAL', payload: 'effectiveDateWarningModal' }),
      ]);
      if (shopper.status === 10 || !shopper.county) {
        dispatch({ type: 'CLEAR_SHOPPER' });
        navigate(`/shopper/${username}`);
      } else {
        navigate('/plans/list');
      }
    }
  };

  handleChangeClientType(status) {
    const { dispatch, shopper } = this.props;
    const payload = { ...shopper, status };
    dispatch({ type: 'UPDATE_SHOPPER_LIST_ONE_BY_UERNAME', username: shopper.username, payload });
    dispatch({ type: 'SET_ROOT_LOADER', payload: true });
    updateShopper(payload, { updateShoperInfo: false }).then((res) => {
      dispatch({ type: 'UPDATE_SHOPPER_LIST_ONE_BY_UERNAME', username: shopper.username, payload: { ...payload, lastUpdated: res.lastUpdated } });
      showPopup('Success', 'Updated Shopper!');
      getShopperCount();
      if (this.props.selectedTab && this.props.selectedTab !== 'All leads') {
        loadShoppers({
          new_load: true,
          lead_category: this.props.selectedTab,
          name: this.props.firstNameFilter || null,
          lead_id: this.props.leadIDFilter || null,
          lead_type: this.props.leadTypeFilter || null,
          show_all_quotes: this.props.showAllQuotes,
          state: this.props.stateFilter || null,
          user_who_created: this.props.agentEmail,
        });
      }
    }).catch(() => {
      showPopup('Error', 'There was a problem updating the Shopper.');
    }).finally(() => {
      dispatch({ type: 'SET_ROOT_LOADER', payload: false });
    });
  }

  toggleInactiveLeadsDropdown() {
    const {
      inactiveLeadsExpanded,
    } = this.state;

    this.setState({
      inactiveLeadsExpanded: !inactiveLeadsExpanded,
    });
  }


  render() {
    const {
      agentNotifications,
      dashShowLeadType,
      isFirst,
      leadStatusOptions = [],
      selectedTab,
      shopper,
      telephonyFeature,
      leadsHistory,
    } = this.props;

    const {
      inactiveLeadsExpanded,
      callsExpanded,
    } = this.state;

    const { isActive } = shopper;

    const shopperListCells = [
      {
        cellId: 'dashboard-shopper-list-shopper-name',
        cellValue: `${shopper.firstName}${shopper.lastName ? ` ${shopper.lastName}` : ''}`,
        classsName: 'text-line-1',
      },
      {
        cellId: 'dashboard-shopper-list-shopper-last-updated',
        cellValue: `${new Date(shopper.lastUpdated).toLocaleTimeString(
          [],
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          },
        )}`,
        classsName: 'text-line-1',
      },
      {
        cellId: 'dashboard-shopper-list-shopper-state',
        cellValue: shopper.state || '-',
      },
      {
        cellId: 'dashboard-shopper-list-shopper-city',
        cellValue: shopper.city || '-',
      },
      {
        cellId: 'dashboard-shopper-list-shopper-phone',
        cellValue: isActive ? formatPhoneNumber(shopper.mobilePhone) : <i>Lead reassigned</i>,
      },
      {
        cellId: 'dashboard-shopper-list-shopper-disposition',
        cellHover: false,
        cellValue: <MmbDropdown
          leftCaret
          value={shopper.status}
          key={shopper.username}
          options={leadStatusOptions}
          optionKey="value"
          optionText="description"
          onChange={(e, option) => this.handleChangeClientType(e, option)}
        />,
      },
      ...(dashShowLeadType ? [{
        cellId: 'dashboard-shopper-list-shopper-lead-type',
        cellValue: shopper.leadType ? shopper.leadType : '-',
        classsName: 'text-line-1',
      }] : []),
    ];

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable arrow-body-style */

    // Conditional used for 'has inactive leads'
    const il = shopper.inactiveLeads && shopper.inactiveLeads.length > 0;
    return (
      <>
        <tr
          className={`dashboard-shopper-list-row ${isActive || 'bcdg'} ${callsExpanded && 'dashboard-row-hl'}`}
        >
          {shopperListCells.map((cell) => {
            return (
              <td
                className="dashboard-shopper-list-cell"
                id={cell.cellId}
                key={cell.cellId}
                onClick={() => this.handleLoadShopper(cell.cellId)}
                title={cell.cellHover ? cell.cellValue : null}
              >
                <div className={`${cell.classsName || ''}`}>
                  {cell.cellValue}
                </div>
              </td>

            );
          })}
          {
            selectedTab === 'follow-up' && agentNotifications
              ? (
                <EditNotificationButton
                  shopper={shopper}
                />
              ) : null
          }
          {telephonyFeature && (
            <td
              className="dashboard-shopper-list-cell"
              id="dashboard-shopper-list-shopper-calls"
              onClick={() => (shopper.calls && shopper.calls.length) ? this.setState({ callsExpanded: !callsExpanded }) : {}}
            >
              <CallCount expanded={callsExpanded} calls={shopper.calls} />
            </td>
          )}
          {leadsHistory && (
            <td
              id="dashboard-shopper-list-shopper-lead-history"
              onClick={() => {
                if (il) {
                  this.toggleInactiveLeadsDropdown();
                }
              }}
            >
              <div
                className={
                  'dashboard-shopper-list-shopper-lead-history-container '
                  + `${inactiveLeadsExpanded && il && isActive ? 'open' : ''} ${il ? '' : 'disabled'} ${isActive || 'bcdg'}`
                }
              >
                <Highlight
                  tag="span"
                  id="dash"
                  i={5}
                  side="l"
                  disabled={!isFirst}
                >
                  {il ? shopper.inactiveLeads.length : 0}
                </Highlight>
                {il
                  ? (
                    <Highlight
                      disabled={!isFirst}
                      id="dash"
                      i={6}
                      side="l"
                    >
                      <span
                        className="dashboard-shopper-list-shopper-lead-history-toggle"
                        id="dashboard-shopper-list-shopper-lead-history-toggle"
                        role="button"
                        tabIndex="0"
                      >
                        <FontAwesomeIcon
                          className="dashboard-shopper-list-shopper-lead-history-toggle-icon"
                          icon={inactiveLeadsExpanded ? faChevronUp : faChevronDown}
                        />
                      </span>
                    </Highlight>
                  )
                  : null}
              </div>
            </td>
          )}
        </tr>
        { inactiveLeadsExpanded && <InactiveLeadsDropdown shopper={shopper} /> }
        { callsExpanded && <CallsDropdown shopper={shopper} /> }
      </>
    );
  }
}

Shopper.defaultProps = {
  shopper: {},
  activeLink: '/dashboard',
};

const mapStateToProps = (store) => ({
  agentEmail: store.agent.email,
  firstNameFilter: store.shopperlist.firstNameFilter,
  leadIDFilter: store.shopperlist.leadIDFilter,
  leadTypeFilter: store.shopperlist.leadTypeFilter,
  showAllQuotes: store.shopperlist.showAllQuotes,
  stateFilter: store.shopperlist.stateFilter,
  agentNotifications: store.theme.features.agentNotifications,
  callNotesLoaded: store.loaders.callNotesLoaded,
  confirmEnrollments: store.display.confirmEnrollments,
  dashShowLeadType: store.theme.features.dashShowLeadType,
  drugsLoaded: store.loaders.drugsLoaded,
  selectedTab: store.shopperlist.selectedTab,
  leadStatusOptions: store.leadStatus?.options?.sort((mapA, mapB) => {
    if (mapA.description > mapB.description) {
      return 1;
    }
    if (mapA.description < mapB.description) {
      return -1;
    }
    return 0;
  }),
  shopperPage: store.shopperlist.shopperPage,
  telephonyFeature: store.theme.features.telephony,
  leadsHistory: store.theme.features.leadsHistory,
});

export default connect(mapStateToProps)(withRouterProps(Shopper));
