import React from 'react';
import { connect } from 'react-redux';

import MarkdownContent from './MarkdownContent';
import NavPage from '../nav/NavPage';

class PrivacyDisclosure extends React.PureComponent {
  render() {
    return (
      <NavPage
        activeLink="/privacy"
        showShopperHeader={this.props.username || this.props.quickQuote}
        showShopperSidebar={this.props.username || this.props.quickQuote}
      >
        <div id="privacy-disclosure-loaded" />
        <div className="m-5">
          <MarkdownContent content="privacy" />
        </div>
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    username: store.shopper.username,
    quickQuote: store.shopper.quickQuote,
  };
}

export default connect(mapStateToProps)(PrivacyDisclosure);
