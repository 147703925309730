import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

// Actions + Utils
import { addCallNote, loadCallNotes } from '../../../../../actions/shopper';
import { showPopup } from '../../../../../utils/popup';

// Components
import LoaderButton from '../../../../common/LoaderButton';

function Add({
  username,
  setView,
}) {
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  function handleChange(event) {
    event.preventDefault();
    const val = event.target.value;
    setText(val);
  }

  function handleSubmit() {
    setLoading(true);
    addCallNote(username, text)
      .then(() => {
        loadCallNotes(username)
          .then((callNotes) => {
            dispatch({ type: 'SET_SHOPPER_CALL_NOTES', payload: callNotes });
            showPopup('Success', 'Successfully saved note');
            setLoading(false);
            setView('summary');
          });
      }).catch(() => {
        setLoading(false);
        showPopup('Error', 'Something went wrong saving the note');
      });
  }

  return (
    <div className="col">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-bottom mb-3">
          <b>New note</b>
        </div>
        <div>
          <div
            role="button"
            tabIndex={-1}
            className="text-muted"
            onClick={() => setView('summary')}
          >
            Cancel
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <textarea
          value={text}
          onChange={(e) => handleChange(e)}
          style={{
            height: '200px',
            width: '100%',
          }}
        />
      </div>
      <div className="d-flex">
        <LoaderButton
          text="Save"
          loadingText="Saving..."
          loading={loading}
          className="btn btn-primary fw-bold mt-3 w-100"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}

function mapStateToProps(store) {
  return {
    username: store.shopper.username,
  };
}

export default connect(mapStateToProps)(Add);
