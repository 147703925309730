const initialState = {
  addAgentValues: {},
  addAgentValidations: {},
  addAgentErrors: {},
  addingAgentStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'EDIT_ADD_USER': {
      let addAgentValues = { ...state.addAgentValues, [action.id]: action.value };
      let addAgentValidations = { ...state.addAgentValidations, [action.id]: action.valid };

      if (action.id === 'agency' && action.value === null) {
        const filteredValues = Object.keys(state.addAgentValues)
          .filter((value) => value !== 'agency')
          .reduce((obj, key) => ({
            ...obj,
            [key]: state.addAgentValues[key],
          }), {});
        const filteredValidations = Object.keys(state.addAgentValidations)
          .filter((validation) => validation !== 'agency')
          .reduce((obj, key) => ({
            ...obj,
            [key]: state.addAgentValidations[key],
          }), {});

        addAgentValues = filteredValues;
        addAgentValidations = filteredValidations;
      }
      return { ...state, addAgentValues, addAgentValidations };
    }
    case 'ADD_AGENT_SAVE':
      return { ...state, addingAgentStatus: true, addAgentErrors: {} };
    case 'ADD_AGENT_CLEAR_SAVE':
      return { ...state, addingAgentStatus: false };
    case 'ADD_AGENT_ERROR': {
      const addAgentErrors = { ...state.addAgentErrors, [action.id]: action.message || true };
      return { ...state, addAgentErrors };
    }
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
