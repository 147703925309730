/* eslint-disable react/no-unknown-property */
/* eslint-disable */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import Doctors from '../plancard/Doctors';
import { StarRating } from '../../../../common/components';

// Utilities
import { fmtField } from '../../../../utils/general';

function PlanSummary({
  doctorsFeature,
  carrierLevelCI,
  navigate,
  plan,
  planType,
  selectedPharmacy,
  shopper,
}) {
  const dataUnavailableMessage = 'Data unavailable';
  const emptyCellItemContent = <span id="planlist-recommended-empty">--</span>;
  const emptyCellItem = {
    detailTitle: dataUnavailableMessage,
    detailContent: emptyCellItemContent,
    detailID: 'planlist-recommended-empty',
  };
  let planSummaryColumn = Array(9).fill(emptyCellItem, 0, 10);
  if (doctorsFeature) {
    planSummaryColumn = Array(10).fill(emptyCellItem, 0, 10);
  }

  // Following three items are dependent on selecting pharmacies+meds
  // Selected Pharmacy Description
  // Estimated RX OOPC = Remaining yearly cost(RX)
  // Total Annual OOPC = Remaining yearly cost(RX + Premium)
  let selectedPharmacyCell = (
    <span
      className="mmb-link fw-bold"
      onClick={() => navigate('/rx/add/pharmacy')}
      role="button"
      tabIndex="0"
    >
      Select a pharmacy
    </span>
  );
  let estAnnualRxOOPCCell = selectedPharmacyCell;
  let totalAnnualOOPCCell = selectedPharmacyCell;

  const selectedMedications = shopper.drugList && shopper.drugList.length > 0;
  if (selectedPharmacy && plan.plan && plan.plan.PricingPharmacies) {
    // User has selected a pharmacy, but potentially has not selected any medications
    Object.keys(plan.plan.PricingPharmacies).forEach((pharmacyKey) => {
      const pharmacy = plan.plan.PricingPharmacies[pharmacyKey];
      if (pharmacy.NPI === `${selectedPharmacy}`) {
        selectedPharmacyCell = pharmacy.PharmacyNetworkDesc;
      } else if (pharmacy.IsMailOrder && selectedPharmacy === 99999999) {
        selectedPharmacyCell = `${pharmacy.PharmacyNetworkDesc} Mail Order`;
      }
    });
    // If they have not selected a drug, we cannot know either their oopc or pharmacy status
    if (!shopper.drugList || shopper.drugList.length <= 0) {
      estAnnualRxOOPCCell = (
        <span
          className="mmb-link"
          onClick={() => navigate('/rx/add/drug')}
          role="button"
          tabIndex="0"
        >
          Add medications
        </span>
      );
      totalAnnualOOPCCell = estAnnualRxOOPCCell;
      selectedPharmacyCell = estAnnualRxOOPCCell;
    } else {
      estAnnualRxOOPCCell = fmtField(plan.estAnnualOOPC, 'dollar');
      totalAnnualOOPCCell = fmtField(plan.totalAnnualOOPC, 'dollar');
    }
  }

  if (Object.keys(plan).length) {
    planSummaryColumn = [
      {
        detailTitle: 'Doctors in-network',
        // Large component, referenced from ../plancard/Doctors
        detailContent: plan.planType !== 'PDP'
            ? (
              <Doctors
                plan={plan}
                showFullText={false}
                showHighlightBorder={false}
              />
            )
            : (
              <FontAwesomeIcon
                className="fs-3"
                icon={faTimes}
                style={{
                  color: 'var(--bs-grey)',
                  marginLeft: '2px',
                  verticalAlign: '-0.25rem',
                }}
              />
            ),
        detailHover: plan.planType !== 'PDP' ? '' : 'Not available',
        detailId: 'planlist-recommended-doctors-in-network',
        detailStyle: { position: 'relative', marginBottom: '-1px' },
        disabled: !doctorsFeature,
      },
      {
        detailTitle: 'Medical coverage',
        detailContent: (
          <FontAwesomeIcon
            className="fs-3"
            icon={plan.plan.HasMedicalCoverage ? faCheck : faTimes}
            style={{
              color: plan.plan.HasMedicalCoverage ? 'var(--bs-green)' : 'var(--bs-grey)',
              marginLeft: plan.plan.HasMedicalCoverage ? '' : '2px',
              verticalAlign: '-0.25rem',
            }}
          />
        ),
        detailHover: plan.plan.HasMedicalCoverage ? 'Available' : 'Not available',
        detailId: 'planlist-recommended-medical-coverage',
      },
      {
        detailTitle: 'Rx coverage',
        detailContent: (
          <FontAwesomeIcon
            className="fs-3"
            icon={plan.plan.HasRXCoverage ? faCheck : faTimes}
            style={{
              color: plan.plan.HasRXCoverage ? 'var(--bs-green)' : 'var(--bs-grey)',
              marginLeft: plan.plan.HasRXCoverage ? '' : '2px',
              verticalAlign: '-0.25rem',
            }}
          />
        ),
        detailHover: plan.plan.HasRXCoverage ? 'Available' : 'Not available',
        detailId: 'planlist-recommended-rx-coverage',
      },
      {
        detailTitle: 'Mail order pharmacy',
        detailContent: (
          <FontAwesomeIcon
            className="fs-3"
            icon={plan.plan.ContainMailOrder ? faCheck : faTimes}
            style={{
              color: plan.plan.ContainMailOrder ? 'var(--bs-green)' : 'var(--bs-grey)',
              marginLeft: plan.plan.ContainMailOrder ? '' : '2px',
              verticalAlign: '-0.25rem',
            }}
          />
        ),
        detailHover: plan.plan.ContainMailOrder ? 'Available' : 'Not available',
        detailId: 'planlist-recommended-mail-order-pharmacy',
      },
      {
        detailTitle: 'Insulin Savings Program',
        detailContent: (
          <FontAwesomeIcon
            className="fs-3"
            icon={plan.plan.InsulinSavingsProgram ? faCheck : faTimes}
            style={{
              color: plan.plan.InsulinSavingsProgram ? 'var(--bs-green)' : 'var(--bs-grey)',
              marginLeft: plan.plan.InsulinSavingsProgram ? '' : '2px',
              verticalAlign: '-0.25rem',
            }}
          />
        ),
        detailHover: plan.plan.InsulinSavingsProgram ? 'Available' : 'Not available',
        detailId: 'planlist-recommended-insulin-savings-program',
        disabled: (!shopper.effectiveDate.includes('2023')),
      },
      {
        detailTitle: 'Selected pharmacy',
        // Either:
        // 1. User has selected a pharmacy, show description
        // 2. User has not selected a pharmacy
        // 3. User has not selected medications
        detailContent: selectedPharmacyCell,
        detailHover: (
          ((selectedPharmacy && selectedMedications) && selectedPharmacyCell)
              || (!selectedPharmacy && 'Select a pharmacy')
              || (!selectedMedications && 'Add medications')
        ),
        detailId: 'planlist-recommended-selected-pharmacy',
      },
      {
        detailTitle: 'Est annual Rx OOPC',
        detailContent: !plan.plan.PartD_Suppressed ? estAnnualRxOOPCCell : 'Not available',
        detailHover: !plan.plan.PartD_Suppressed && (
          ((selectedPharmacy && selectedMedications) && `$${plan.estAnnualOOPC}`)
              || (!selectedPharmacy && 'Select a pharmacy')
              || (!selectedMedications && 'Add medications')
        ),
        detailId: 'planlist-recommended-est-annual-rx-oopc',
      },
      {
        detailTitle: 'Total annual OOPC',
        detailContent: !plan.plan.PartD_Suppressed ? totalAnnualOOPCCell : 'Not available',
        detailHover: !plan.plan.PartD_Suppressed && (
          ((selectedPharmacy && selectedMedications) && `$${plan.totalAnnualOOPC}`)
              || (!selectedPharmacy && 'Select a pharmacy')
              || (!selectedMedications && 'Add medications')
        ),
        detailId: 'planlist-recommended-total-annual-oopc',
      },
      {
        detailTitle: 'Monthly premium',
        detailContent: plan.monthlyPremium || plan.monthlyPremium >= 0
            ? fmtField(plan.monthlyPremium, 'dollar')
            : dataUnavailableMessage,
        detailHover: `$${plan.monthlyPremium}`,
        detailId: 'planlist-recommended-monthly-premium',
      },
      {
        detailTitle: 'Star rating',
        detailContent: <StarRating plan={plan} />,
        detailId: 'planlist-recommended-star-rating',
        detailHover: plan.starRating ? `${plan.starRating} / 5` : 'Not available',
        detailStyle: {
          // FontAwesome star paths need specific positioning
          display: 'inline-block',
          height: '38px',
        },
      },
      {
        detailTitle: 'Star rating vs Market',
        detailContent: (plan.plan.StarRatingRank
                        ? fmtField(plan.plan.StarRatingRank, 'str')
                        : dataUnavailableMessage),
        detailHover: plan.plan.StarRatingRank,
        detailId: 'planlist-recommended-star-rating-vs-market',
        disabled: !carrierLevelCI,
      },
    ];
  } else if (Object.keys(plan).length && plan.planType === 'MEDIGAP') {
    planSummaryColumn = [
      {
        detailTitle: 'Doctors in-network',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-doctors-in-network',
      },
      {
        detailTitle: 'Medical coverage',
        detailContent: emptyCellItemContent,
        detailHover: 'Available',
        detailId: 'planlist-recommended-medical-coverage',
      },
      {
        detailTitle: 'Rx coverage',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-rx-coverage',
      },
      {
        detailTitle: 'Mail order pharmacy',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-mail-order-pharmacy',
      },
      {
        detailTitle: 'Selected pharmacy',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-selected-pharmacy',
      },
      {
        detailTitle: 'Est annual Rx OOPC',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-est-annual-rx-oopc',
      },
      {
        detailTitle: 'Total annual OOPC',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-total-annual-oopc',
      },
      {
        detailTitle: 'Monthly premium',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-monthly-premium',
      },
      {
        detailTitle: 'Star rating',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-star-rating',
        detailStyle: {
          display: 'inline-block',
        },
      },
      {
        detailTitle: 'Star rating vs Market',
        detailContent: emptyCellItemContent,
        detailHover: 'Not available',
        detailId: 'planlist-recommended-star-rating-vs-market',
      },
    ];
  }

  return (
    <table>
      <tbody>
        {planSummaryColumn.map((detail, idx) => {
          return detail.disabled ? null : (
            <tr key={`${plan.cpsID}-${idx.toString()}`}>
              <td
                dataurl={detail.detailDataUrl}
                id={detail.detailId}
                title={detail.detailHover}
                style={{
                  ...detail.detailStyle,
                }}
              >
                {detail.detailContent}
              </td>
            </tr>
          )}
       )}
      </tbody>
    </table>
  );
}


function mapStateToProps(store) {
  return {
    doctorsFeature: store.theme.features.doctors,
    doctorList: store.shopper.doctorList,
    doctorPlans: store.plan.doctorPlans,
    carrierLevelCI: store.theme.features.carrierLevelCI,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(withRouterProps(PlanSummary));
