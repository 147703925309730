import React from 'react';
import { connect } from 'react-redux';

class TableRow extends React.PureComponent {
  render() {
    const {
      dispatch,
      drug,
    } = this.props;

    // NOTE(santeyio): this field is new as of 07/21/2021. If it is null
    // then the drug was created before now and has a null value for brand.
    // In that case we should display '--'.
    let isBrand = '--';
    if (drug.drugType) {
      isBrand = drug.drugType;
    }

    return (
      <tr>
        <td>{drug.name}</td>
        <td>{isBrand}</td>
        <td>{drug.dosage}</td>
        <td>{drug.userQuantity}</td>
        <td>{drug.frequency}</td>
        <td>{drug.packageDescription || '--'}</td>
        <td style={{ verticalAlign: 'middle', padding: '0' }}>
          <i
            role="button"
            tabIndex={0}
            className="fas fa-times float-right"
            style={{ padding: '1em', cursor: 'pointer' }}
            onClick={() => {
              dispatch({ type: 'SHOW_MODAL', payload: 'deleteDrugModal' });
              dispatch({ type: 'SET_DELETE_DRUG', payload: drug });
            }}
          />
        </td>
      </tr>
    );
  }
}

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(TableRow);
