import React from 'react';
import { connect } from 'react-redux';

// Components
import QuestionToolTip from '../../../common/QuestionToolTip';

class TableDescription extends React.PureComponent {
  render() {
    const { doctorsFeature, carrierLevelCI, effectiveDate } = this.props;

    const ispRow = [];
    if (effectiveDate.includes('2023')) ispRow.push({ label: 'Insulin Savings Program' });

    /* eslint-disable max-len */
    const tableDescriptionRows = [
      { label: 'Doctors in-network', disabled: !doctorsFeature },
      { label: 'Medical coverage' },
      { label: 'Rx coverage' },
      { label: 'Mail order pharmacy' },
      ...ispRow,
      { label: 'Selected pharmacy' },
      {
        label: 'Est annual Rx OOPC',
        helpHover: {
          header: 'Estimated Annual Rx OOPC',
          text: 'The Estimated Annual RX OOPC is an important value indicator as it shows the actual usage costs of the insurance plan for the shopper based upon their prescription drug needs. The OOPC is calculated using the Medicine Cabinet information include Prescription Drugs and Selected Pharmacy. This calculation is based upon the Medicare.gov algorithm and uses the Plan, Plan Formulary, Pharmacy and detailed Drug costs. The Estimated Annual RX OOPC does not include the plan’s premium.<br/><br/>Estimated Annual prescription out of pocket cost is calculated based upon the effective date through the end of the calendar year. If the effective date is not January 1st, the OOPC will reflect partial year calculation.',
        },
      },
      {
        label: 'Total annual OOPC',
        helpHover: {
          header: 'Total Annual Out of Pocket Cost',
          text: 'The Total Annual OOPC is an important value indicator as it shows the actual usage costs of the insurance plan for the shopper based upon their prescription drug needs. The OOPC is calculated using the Medicine Cabinet information include Prescription Drugs and Selected Pharmacy. This calculation is based upon the Medicare.gov algorithm and uses the Plan, Plan Formulary, Pharmacy and detailed Drug costs. The Total Annual OOPC includes the plan’s premium.<br/><br/>Total Annual out of pocket cost is calculated based upon the effective date through the end of the calendar year. If the effective date is not January 1st, the OOPC will reflect partial year calculation.',
        },
      },
      { label: 'Monthly premium' },
      { label: 'Star rating' },
      { label: 'Star rating vs market', disabled: !carrierLevelCI },
    ];

    return (
      <table>
        <tbody>
          {tableDescriptionRows.map((row) => {
            let tableRowObject = null;
            // Empty icon to ensure spacing is right
            let icon = <span style={{ }} />;
            let floater = null;

            if (row.helpHover) {
              icon = null;
              floater = (
                <QuestionToolTip
                  content={row.helpHover.text}
                  title={row.helpHover.header}
                  iconStyle={{
                    marginLeft: '-1.5em',
                  }}
                />
              );
            }

            tableRowObject = (
              <tr key={row.label}>
                <td>
                  {icon}
                  {floater}
                  {row.label}
                </td>
              </tr>
            );
            return row.disabled ? null : tableRowObject;
          })}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorsFeature: state.theme.features.doctors,
  carrierLevelCI: state.theme.features.carrierLevelCI,
  effectiveDate: state.shopper.effectiveDate,
});

export default connect(mapStateToProps)(TableDescription);
