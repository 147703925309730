/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
// import axios from 'axios';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { UserAgentApplication } from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';

import { formatDateToISO, formatDateToURLEncoded, formatRecurrence } from '../../../utils/general';

class AddToCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      accessToken: null,
      outlookEventCreated: false,
      error: false,
      sessionToken: null,
    };

    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: '02ff750b-c5d0-4d36-8850-c8c75818dad9',
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'sessionStorage',
      },
    });

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    // this.userAgentApplication.logout();
    this.setState({
      sessionToken: localStorage.getItem('SessionToken'),
    });

    const account = this.userAgentApplication.getAccount();

    if (account) {
      this.getUserProfile();
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleEventAdd = (event) => {
    const calType = event.target.id;
    const text = `Follow Up with ${this.props.shopperFName}${this.props.shopperLName ? ` ${this.props.shopperLName}` : ''}`;
    const date = `${this.props.values.date} ${this.props.values.time}`;
    const endDate = this.props.values.interval === 'custom'
      ? `${this.props.values.endDate} ${this.props.values.endTime}`
      : `${this.props.values.date} ${this.props.values.endTime}`;
    const details = this.props.values.note ? `&details=${this.props.values.note}` : '';
    const recurrence = this.props.values.interval !== 'no-repeat'
      ? formatRecurrence(this.props.values.interval, this.props.values.currentDay, this.props.values.intervalDates, formatDateToISO(new Date(this.props.values.endDate)))
      : '';

    let link;
    if (calType === 'google') {
      const startDateTime = formatDateToISO(new Date(date));
      const endDateTime = formatDateToISO(new Date(endDate));
      link = `https://www.google.com/calendar/render?action=TEMPLATE&text=${text}&dates=${startDateTime}/${endDateTime}${details}${recurrence}`;
    } else if (calType === 'outlook') {
      const startDateTime = formatDateToURLEncoded(new Date(date));
      const endDateTime = formatDateToURLEncoded(new Date(endDate));
      link = `https://outlook.live.com/owa/?path=%2Fcalendar%2Faction%2compose&rru=addevent&startdt=${startDateTime}&enddt=${endDateTime}&subject=${text}&body=${details}`;
    } else if (calType === 'icloud') {
      link = '';
    }
    window.open(link, '_blank');
  };

  getAccessToken = async () => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: ['user.read', 'calendars.readwrite'],
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const interactiveResult = await this.userAgentApplication.acquireTokenPopup({
          scopes: ['user.read', 'calendars.readwrite'],
        });

        return interactiveResult.accessToken;
      }
      this.setState({
        error: true,
      });
      throw err;
    }
  };

  getAuthenticatedClient = (accessToken) => {
    // Initialize Graph client
    const client = Client.init({
      // Use the provided access token to authenticate
      // requests
      authProvider: (done) => {
        done(null, accessToken);
      },
    });

    return client;
  };

  getUserProfile = async () => {
    try {
      const accessToken = await this.getAccessToken(['user.read', 'calendars.readwrite']);

      if (accessToken) {
        this.setState({
          accessToken,
        });
      }
    } catch (err) {
      this.setState({
        error: true,
      });
    }
  };

  handleOutlook = async () => {
    try {
      if (!this.state.accessToken) {
        await this.userAgentApplication.loginPopup(
          {
            scopes: ['user.read', 'calendars.readwrite'],
            prompt: 'select_account',
          },
        );
        await this.getUserProfile();
      }

      if (this.state.accessToken) {
        const client = this.getAuthenticatedClient(this.state.accessToken);

        const text = `Follow Up with ${this.props.shopperFName}${this.props.shopperLName ? ` ${this.props.shopperLName}` : ''}`;
        const date = `${this.props.values.date} ${this.props.values.time}`;
        const endDate = `${this.props.values.date} ${this.props.values.endTime}`;
        const details = this.props.values.note ? this.props.values.note : '';
        // const recurrence = this.props.values.interval !== 'no-repeat'
        //   ? formatRecurrence(this.props.values.interval, this.props.values.currentDay, this.props.values.intervalDates, formatDateToISO(new Date(this.props.values.endDate)))
        //   : '';
        let type = null;
        if (this.props.values.interval === 'daily') {
          type = 'daily';
        } else {
          type = 'weekly';
        }

        let daysOfWeek = [];
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        Object.values(this.props.values.intervalDates).forEach((d, index) => {
          if (d) {
            daysOfWeek.push(days[index]);
          }
        });

        if (this.props.values.interval === 'weekdays') {
          daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
        }
        if (this.props.values.interval === 'weekly') {
          daysOfWeek = [moment(this.props.values.date).format('dddd').toLowerCase()];
        }
        const startDateTime = formatDateToISO(new Date(date), true);
        const endDateTime = formatDateToISO(new Date(endDate), true);

        if (this.props.values.interval === 'no-repeat') {
          const response = await client.api('/me/events').post({
            subject: text,
            body: {
              contentType: 'HTML',
              content: details,
            },
            start: {
              dateTime: startDateTime,
              timeZone: 'Pacific Standard Time',
            },
            end: {
              dateTime: endDateTime,
              timeZone: 'Pacific Standard Time',
            },
          });
          if (response.id) {
            localStorage.setItem('SessionToken', this.state.sessionToken);

            this.setState({
              outlookEventCreated: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        } else {
          const response = await client.api('/me/events').post({
            subject: text,
            body: {
              contentType: 'HTML',
              content: details,
            },
            start: {
              dateTime: startDateTime,
              timeZone: 'Pacific Standard Time',
            },
            end: {
              dateTime: endDateTime,
              timeZone: 'Pacific Standard Time',
            },
            recurrence: {
              pattern: {
                type,
                interval: 1,
                daysOfWeek: type === 'daily' ? undefined : daysOfWeek,
              },
              range: {
                type: this.props.values.interval !== 'custom' ? 'noEnd' : 'endDate',
                startDate: this.props.values.date,
                endDate: this.props.values.interval !== 'custom' ? undefined : this.props.values.endDate,
              },
            },
          });
          if (response.id) {
            localStorage.setItem('SessionToken', this.state.sessionToken);

            this.setState({
              outlookEventCreated: true,
            });
          } else {
            this.setState({
              error: true,
            });
          }
        }
      }
    } catch {
      this.setState({
        error: true,
      });
    }
  };

  render() {
    return (
      <div className="d-flex flex-column">
        <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} block>
          <DropdownToggle caret color="primary">Add to Calendar</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.handleEventAdd} id="google">Google Calendar</DropdownItem>
            <DropdownItem onClick={this.handleOutlook} id="outlook">Microsoft Outlook</DropdownItem>
            {/* <DropdownItem onClick={this.handleEventAdd} id="icloud">Apple iCloud</DropdownItem> */}
          </DropdownMenu>
        </ButtonDropdown>
        <div
          className="text-center mt-1"
          style={{ visibility: this.state.outlookEventCreated ? 'visible' : 'hidden' }}
        >
          Outlook notification created!
        </div>
        {this.state.error ? <div className="text-center">Something went wrong</div> : null}
      </div>
    );
  }
}

export default AddToCalendar;
