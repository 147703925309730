/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// Actions
import { savePharmacy, deletePharmacy } from '../../../actions/pharmacy';

function PharmacySelectButton({
  selected = false,
  pharmacy = {},
}) {
  const dispatch = useDispatch();
  const {
    shopper: {
      username,
      quickQuote,
      pharmacyList,
      anonymous,
    },
    display: { pharmacyAddModal },
    pharmacy: { selectedPharmacy },
  } = useSelector(store => store);
  const [ color, setColor ] = useState('secondary');
  const [ loading, setLoading ] = useState(false);

  function sPharmacy() {
    return new Promise((resolve, reject) => {
      savePharmacy(pharmacy, username, quickQuote)
        .then((pharmacyWithID) => {
          dispatch({ type: 'ADD_PHARMACY_TO_MEDCAB', payload: pharmacyWithID });
          dispatch({ type: 'CLEAR_PLAN_DATA' });
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: 'DEFAULT_STATE' });
          dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: 'DEFAULT_STATE' });
          setColor('primary');
          dispatch({ type: 'SET_SELECTED_PHARMACY', payload: pharmacyWithID.npi });
          resolve();
        }).catch((err) => {
          reject(err);
        }).finally(() => setLoading(false));
    });
  };

  function dPharmacy(npi) {
    return new Promise((resolve, reject) => {
      setLoading(true);
      deletePharmacy(npi, username, quickQuote)
        .then(() => {
          dispatch({ type: 'DELETE_PHARMACY_FROM_MEDCAB', payload: npi });
          dispatch({ type: 'CLEAR_PLAN_DATA' });
          dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: 'DEFAULT_STATE' });
          dispatch({ type: 'SET_MEDIGAP_LOADED_STATE', payload: 'DEFAULT_STATE' });
          setColor('secondary');
          if (pharmacyList.length <= 1) {
            dispatch({ type: 'SET_SELECTED_PHARMACY', payload: false });
          } else if (pharmacyList.length > 1) {
            if (selectedPharmacy === pharmacy.npi) {
              dispatch({ type: 'SET_SELECTED_PHARMACY', payload: pharmacyList[0].npi });
            }
          }
          resolve();
        }).catch((err) => {
          reject(err);
        }).finally(() => setLoading(false));
    });
  };

  function setSelect(selected, saveFlag = false) {
    if (selected) {
      if (saveFlag) {
        setLoading(true);
        if (pharmacyList.length > 0) {
          // note(@joeysapp): @santeyio, it sounds like Gabe is wanting the ability
          // to have multiple pharmacies selected?
          const pharmacyToDelete = pharmacyList[0];
          dPharmacy(pharmacyToDelete.npi).then(() => sPharmacy());
        } else {
          sPharmacy();
        }
      } else {
        setColor('primary');
      }
    } else if (saveFlag) {
      dPharmacy(pharmacy.npi);
    }
  };

  let pharmacyLimit = pharmacyList.length >= 5;
  let classes = 'btn btn-primary px-5';
  let text = 'Add';

  if (loading) {
    classes = 'btn btn-secondary';
    text = (
      <>
        Loading...
        <div className="spinner-border spinner-border-sm ms-2" role="status">
          <span className="sr-only" />
        </div>
      </>
    );
  } else if (selected) {
    classes = 'btn btn-outline-primary';
    pharmacyLimit = false;
    text = (
      <span className="font-italic fw-normal">
        Added
        <FontAwesomeIcon
          icon={faCheck}
          className="ms-2"
        />
      </span>
    );
  }

  return (
    <button
      type="button"
      className={`pharmacy-add-button me-4 ms-auto ${classes}`}
      color={color}
      disabled={loading || pharmacyLimit}
      onClick={() => setSelect(!selected, true)}
    >
      {text}
    </button>
  );
}

export default PharmacySelectButton;
