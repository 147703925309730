import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-regular-svg-icons';
import { withRouterProps } from '../../../utils/hocs';
import { AWAIT_STATE } from '../../../constants';

// Utilities
import { formatNameToWidth, formatSubsidyLevelText } from '../../../utils/general';

// Components
import Dropdowns from './dropdowns';
import SOABadge from './SOABadge';

function ShopperInfobar({
  shopper,
  shopperDataLoaded,
  showShopperInfobar,
  soaFeature,
}) {
  const [dropdownOpen, setDropdownOpen] = useState();

  // Shorten Shopper name from [ John Smith ] -> [ John S. ] -> [ J. S. ]
  const shopperDisplayName = formatNameToWidth(shopper.firstName, shopper.lastName, 16);

  // Left Side of Shopper Infobar w/ info
  const staticPanels = [
    {
      panelData: <SOABadge soaList={shopper.soaList} />,
      panelDataId: 'navbar-shopper-infobar-panel-soa-status',
      panelDisplayLabel: 'SOA Status',
    },
    {
      panelData: shopper.zipcode !== '' ? shopper.zipcode : '--',
      panelDataId: 'navbar-shopper-infobar-panel-zipcode',
      panelDisplayLabel: 'Zipcode',
    },
    {
      // Allows for location truncation if needed in the future
      panelData: (
        <div className="d-flex">
          <div className="overflow-hidden text-nowrap">
            {shopper.city ? `${shopper.city}` : '--'}
          </div>
          <div>
            {shopper.state && `, ${shopper.state}`}
          </div>
        </div>
      ),
      panelDataId: 'navbar-shopper-infobar-panel-city-state',
      panelDisplayLabel: 'City, State',
    },
    {
      panelData: (
        <div className="text-capitalize">
          {shopper.countyName ? `${shopper.countyName}` : '--'}
        </div>
      ),
      panelDataId: 'navbar-shopper-infobar-panel-county-name',
      panelDisplayLabel: 'County',
    },
    {
      panelData: shopper.dob !== '' ? shopper.dob : '--',
      panelDataId: 'navbar-shopper-infobar-panel-date-of-birth',
      panelDisplayLabel: 'Date of birth',
    },
    {
      panelData: shopper.effectiveDate !== '' ? shopper.effectiveDate : '--',
      panelDataId: 'navbar-shopper-infobar-panel-effective',
      panelDisplayLabel: 'Effective',
    },
    {
      panelData: formatSubsidyLevelText(shopper.subsidyLevel, shopper.subsidyPercent),
      panelDataId: 'navbar-shopper-infobar-panel-current-subsidy',
      panelDisplayLabel: 'Current Subsidy',
    },
  ];

  if (!soaFeature) {
    staticPanels.splice(0, 1);
  }

  // Fade-in loaded info so the topbar doesn't flash in on load
  const fadeIn = { opacity: shopper.username || shopperDataLoaded !== AWAIT_STATE ? '1' : '0', transition: '0.1s all ease' };
  return showShopperInfobar && (
    <div className="navbar-shopper-infobar bg-bdlight border border-bddark shadow-sm" style={fadeIn}>
      <div className="navbar-shopper-infobar-panel bg-bdlight border border-bddark navbar-shopper-infobar-panel-name">
        <span>
          {shopperDisplayName}
        </span>
      </div>
      {/* ShopperInfobar Left Side (Shopper Information) */}
      <div
        className={`navbar-shopper-infobar-static-panels ${dropdownOpen ? 'open' : 'closed'}`}
        style={{ position: 'relative', overflow: 'visible' }}
      >
        {
          /* eslint-disable arrow-body-style */
          staticPanels.map((p, idx) => {
            return (
              <div
                className={`navbar-shopper-infobar-panel bg-bdlight border border-bddark ${dropdownOpen ? 'shadow-sm' : ''}`}
                key={p.panelDataId}
                style={{ display: (dropdownOpen || idx === 0) ? 'flex' : 'none' }}
              >
                <span className="navbar-shopper-infobar-panel-label">{p.panelDisplayLabel}</span>
                <span id={p.panelDataId}>{p.panelData}</span>
                {
                  /* Only visible in Compact view */
                  idx === 0
                    ? (
                      <span
                        className="navbar-shopper-infobar-dropdown-toggle bg-bdlight"
                        id="navbar-shopper-infobar-dropdown-toggle"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        role="button"
                        tabIndex="0"
                      >
                        <FontAwesomeIcon
                          className="navbar-shopper-infobar-dropdown-toggle-icon"
                          icon={dropdownOpen ? faAngleUp : faAngleDown}
                        />
                      </span>
                    )
                    : null
                }
              </div>
            );
          })
        }
      </div>

      <Dropdowns />

    </div>
  );
}

const mapStateToProps = (store) => ({
  shopper: store.shopper,
  shopperDataLoaded: store.loaders.shopperDataLoaded,
  soaFeature: store.theme.features.soa,
});

export default connect(mapStateToProps)(withRouterProps(ShopperInfobar));
