/* eslint-disable react/no-unknown-property */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';

// Actions
import { deleteNotification } from '../../../actions/notifications';
import { loadShopper, loadCallNotes, loadShopperRecommendation } from '../../../actions/shopper';
import { loadDrugs } from '../../../actions/drug';
import { loadPharmacies } from '../../../actions/pharmacy';
import { loadDoctors } from '../../../actions/doctor';
import { loadSoas } from '../../../actions/soa';

// Components
import StandardModal from '../../common/StandardModal';

// Utilities
import { calculateNextNotification } from '../../../utils/general';

class AgentNotificationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      upcoming: [],
    };

    this.close = this.close.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      list,
    } = this.props;

    window.agentNotificationModalInterval = window.setInterval(() => {
      const upcomingNotifications = [];
      list.forEach((n) => {
        if (n.interval === 'no-repeat' || n.interval === 'daily') {
          const timeDiff = (moment(n.start_date).diff(moment(new Date()), 'minutes'));
          if (timeDiff === 10) {
            upcomingNotifications.push(n);
            dispatch({ type: 'SHOW_MODAL', payload: 'agentNotificationModal' });
          }
        } else if (n.interval === 'weekdays') {
          const today = new Date().getDay();
          if (today > 0 && today < 6) {
            const hour = moment(n.start_date).hour();
            const minute = moment(n.start_date).minute();
            const notificationTime = moment().hour(hour).minute(minute);

            const timeDiff = notificationTime.diff(moment(new Date()), 'minutes');
            if (timeDiff === 10) {
              upcomingNotifications.push(n);
              dispatch({ type: 'SHOW_MODAL', payload: 'agentNotificationModal' });
            }
          }
        } else if (n.interval === 'weekly') {
          const day = new Date(n.start_date).getDay();
          const today = new Date().getDay();
          if (day === today) {
            const hour = moment(n.start_date).hour();
            const minute = moment(n.start_date).minute();
            const notificationTime = moment().hour(hour).minute(minute);

            const timeDiff = notificationTime.diff(moment(new Date()), 'minutes');
            if (timeDiff === 10) {
              upcomingNotifications.push(n);
              dispatch({ type: 'SHOW_MODAL', payload: 'agentNotificationModal' });
            }
          }
        } else if (n.interval === 'custom') {
          const today = new Date().getDay();
          if (Object.values(n.days)[today]) {
            const hour = moment(n.start_date).hour();
            const minute = moment(n.start_date).minute();
            const notificationTime = moment().hour(hour).minute(minute);

            const timeDiff = notificationTime.diff(moment(new Date()), 'minutes');
            if (timeDiff === 10) {
              upcomingNotifications.push(n);
              dispatch({ type: 'SHOW_MODAL', payload: 'agentNotificationModal' });
            }
          }
        }
      });
      this.setState({ upcoming: upcomingNotifications });
    }, 59000);
  }

  componentWillUnmount() {
    // Clear interval on abrupt App changes to prevent memory leaks
    window.clearInterval(window.agentNotificationModalInterval);
  }

  close() {
    const {
      dispatch,
    } = this.props;

    dispatch({ type: 'HIDE_MODAL', payload: 'agentNotificationModal' });
  }

  renderInterval(int, days, date) {
    if (int === 'no-repeat') {
      return '-';
    }
    if (int === 'daily' || int === 'weekdays') {
      return int.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
    }
    if (int === 'custom') {
      const daysListed = Object.keys(days).filter((d) => days[d]).map((d) => d.charAt(0).toUpperCase() + d.substring(1, 2));
      return daysListed.join(', ');
    }
    if (int === 'weekly') {
      const day = new Date(date).getDay();
      const dayData = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
      return `Weekly on ${dayData[day]}`;
    }
    return int;
  }

  /*
  renderDate(date) {
    const createddate = new Date(date);
    const day = createddate.getDay();
    const month = createddate.getMonth();
    const d = createddate.getDate();
    const hour = createddate.getHours();
    const minute = createddate.getMinutes();

    const dayData = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const monthData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${dayData[day]}, ${monthData[month]} ${d}, ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }
  */

  renderTime(date) {
    const createddate = new Date(date);
    const hour = createddate.getHours();
    const minute = createddate.getMinutes();

    return `${hour > 12 ? hour - 12 : hour}:${minute < 10 ? `0${minute}` : minute} ${hour > 11 ? 'PM' : 'AM'}`;
  }

  render() {
    const {
      agentNotificationModal,
      callNotesLoaded,
      dispatch,
      drugsLoaded,
      list,
      navigate,
      pharmaciesLoaded,
      shopper,
      shopperDataLoaded,
      shopperDoctorsLoaded,
    } = this.props;

    const {
      upcoming,
    } = this.state;

    if (
      agentNotificationModal
      && shopperDataLoaded === 'SUCCESS_STATE'
      && callNotesLoaded === 'SUCCESS_STATE'
      && drugsLoaded === 'SUCCESS_STATE'
      && pharmaciesLoaded === 'SUCCESS_STATE'
      && shopperDoctorsLoaded === 'SUCCESS_STATE'
      && shopper.username
    ) {
      dispatch({ type: 'SET_SHOPPER_DATA_LOADED_STATE', payload: 'DEFAULT_STATE' });
      dispatch({ type: 'SET_CALL_NOTES_LOADED_STATE', payload: 'DEFAULT_STATE' });
      dispatch({ type: 'SET_DRUGS_LOADED_STATE', payload: 'DEFAULT_STATE' });
      dispatch({ type: 'SET_PHARMACIES_LOADED_STATE', payload: 'DEFAULT_STATE' });
      dispatch({ type: 'SET_SHOPPER_DOCTORS_LOADED_STATE', payload: 'DEFAULT_STATE' });
      dispatch({ type: 'SET_SHOPPER_WEATHER_LOADED_STATE', payload: 'DEFAULT_STATE' });
      navigate('/planlist');
      return null;
    }

    return (
      <StandardModal
        isOpen={agentNotificationModal}
        closeModalAction={this.close}
        title="Agent Notification"
        size="xl"
      >
        <div className="px-3 pb-3">
          <div className="mb-3">
            {upcoming.map((a) => (
              <div className="alert alert-primary d-flex align-items-center justify-content-between mb-1" role="alert">
                <div>
                  <h4 className="d-inline me-2">
                    <span className="badge bg-danger">
                      !
                    </span>
                  </h4>
                  <b>Follow Up with {a.shopper_name} - {this.renderTime(a.start_date)}</b> - {a.shopper}
                </div>
                <button
                  className="btn btn-primary me-1"
                  onClick={() => {
                    dispatch({ type: 'HIDE_MODAL', payload: 'agentNotificationModal' });

                    loadShopper(a.shopper).then((s) => {
                      dispatch({ type: 'SET_SHOPPER', payload: s });
                      dispatch({ type: 'SET_SHOPPER_QUOTES', payload: s.quotes });
                    });
                    loadCallNotes(a.shopper);
                    loadDrugs(a.shopper);
                    loadPharmacies(a.shopper);
                    loadDoctors(a.shopper);
                    loadShopperRecommendation(a.shopper);
                    loadSoas(a.shopper);
                  }}
                  type="button"
                >
                  Show Record
                </button>
              </div>
            ))}
          </div>
          <div style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
            <h4>Upcoming Notifications</h4>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-nowrap">Lead ID</th>
                  <th className="text-nowrap">Name</th>
                  <th className="text-nowrap">Notification</th>
                  <th className="text-nowrap">Notification Interval</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  list.map((n) => (
                    <tr
                      key={n}
                    >
                      <th scope="row" style={{ verticalAlign: 'middle' }}>{n.shopper}</th>
                      <td style={{ verticalAlign: 'middle' }}>
                        {n.shopper_name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
                      </td>
                      {/* <td className="text-nowrap">{this.renderDate(n.start_date)}</td> */}
                      <td style={{ verticalAlign: 'middle' }} className="text-nowrap">{calculateNextNotification(n)}</td>
                      <td style={{ verticalAlign: 'middle' }}>{this.renderInterval(n.interval, n.days, n.start_date)}</td>
                      <td className="text-right">
                        <button
                          className="btn btn-secondaryme-1"
                          outline="true"
                          onClick={() => {
                            dispatch({ type: 'SET_SELECTED_NOTIFICATION', payload: n });
                            dispatch({ type: 'SHOW_MODAL', payload: 'editNotificationModal' });
                          }}
                          type="button"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                        <button className="btn btn-danger" onClick={() => deleteNotification(n.id)} type="button">
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="px-3 pb-3 text-right">
          <button className="btn btn-primary ms-1" onClick={this.close} type="button">Close</button>
        </div>
      </StandardModal>
    );
  }
}

function WithNavigation(props) {
  const navigate = useNavigate();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AgentNotificationModal {...props} navigate={navigate} />;
}

function mapStateToProps(store) {
  return {
    agentNotificationModal: store.modal.agentNotificationModal,
    list: store.notifications.list,
    shopperDataLoaded: store.loaders.shopperDataLoaded,
    callNotesLoaded: store.loaders.callNotesLoaded,
    drugsLoaded: store.loaders.drugsLoaded,
    pharmaciesLoaded: store.loaders.pharmaciesLoaded,
    shopperDoctorsLoaded: store.loaders.shopperDoctorsLoaded,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(WithNavigation);
