import React from 'react';
import { useSelector } from 'react-redux';

function FieldError({ name }) {
  const { errors = {} } = useSelector(store => store.shopper);
  const errMsg = errors[name];
  return errMsg && (
    <div className="text-danger mt-1">
      <small>{errMsg}</small>
    </div>
  );
}

export default FieldError;
