export default {
  careHighlights: {
    ceTitle: 'Cost efficiency',
    ceDesc: `The cost of treatment that members received compared
      to treatment provided by similar physicians. Ratings range from four
      badges (highest) to one badge (lowest). Not all providers will have a rating.
      This rating applies to 1 specialty of the doctor or facility.
      It may or may not be the doctor or facility’s primary specialty.`,
    cqTitle: 'Clinical quality',
    cqDesc: `The effectiveness of treatment that members received
      may be measured qualitatively. Ratings range from four hearts (highest)
      to one heart (lowest). Not all providers will have a rating.
      This rating applies to 1 specialty of the doctor or facility.
      It may or may not be the doctor or facility’s primary specialty.`,
  },
};
