import React from 'react';
import { connect } from 'react-redux';

// Components
import DetailTableRow from './DetailTableRow';
import detailList from './BenefitHighlightsFieldConstructor';

class BenefitHighlights extends React.PureComponent {
  render() {
    const {
      plan,
      selectedPharmacy,
      style,
      shopper: { effectiveDate },
    } = this.props;

    return (
      <div
        className="plandetails-table"
        style={style}
      >
        {detailList.map((detail) => {
          // skip insulin savings if not 2023
          if (detail.key === 'InsulinSavingsProgram' && !effectiveDate.includes('2023')) return null;
          // skip coverage gap if plan year > 2024
          if (detail.key === 'CoverageGapTiers' && !effectiveDate.includes('2024')) return null;
          return (
            <DetailTableRow
              data={detail}
              key={detail.name}
              pharmacy={selectedPharmacy}
              plan={plan}
              tableRowClassName="plandetails-table-row-sm"
            />
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
    modals: store.theme.modals,
  };
}

export default connect(mapStateToProps)(BenefitHighlights);
