import React, { useEffect, useState } from 'react';

function PasswordResetPage() {
  const [iframeSrc, setIframeSrc] = useState('');

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_MMB_API_URL;
    const urlParams = new URLSearchParams(window.location.search);
    const encodedPath = urlParams.get('p');

    if (encodedPath) {
      try {
        const decodedPath = decodeURIComponent(encodedPath);
        setIframeSrc(`${baseUrl}${decodedPath}`);
      } catch (error) {
        console.error('Error decoding URL parameter: ', error);
      }
    } else {
      console.warn('No "p" parameter found in URL.');
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        id="embeddedFrame"
        src={iframeSrc}
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Embedded Page"
      />
    </div>
  );
}

export default PasswordResetPage;
