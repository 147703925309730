const initialState = {
  currentPlanDisplay: false,
  editAllConfirmationNumbers: false,
  confirmEnrollments: false,
  countySelectModal: false,
  countySelectData: [],
  genderRequired: false,
  smokerRequired: false,
  drugAddModal: false,
  pharmacyAddModal: false,
  doctorAddModal: false,
  anocVerifyModal: false,
  keepPlanModal: false,
  selectPlansModalState: false,
  selectPlansModalData: false,
  selectPlansModalIndex: 0,
  medcabTableTab: 'drug',
  planlistTableTab: 'mapd',
  medicareSortType: 'estAnnualOOPC',
  releaseNotes: [],
  privacyDisclosure: {},
  sortAsc: true,
  soaModalTab: 'send',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_EDIT_ALL_CONFIRMATION_NUMBERS':
      return {
        ...state,
        editAllConfirmationNumbers: action.payload,
      };
    case 'SET_CONFIRM_ENROLLMENTS':
      return {
        ...state,
        confirmEnrollments: action.payload,
      };
    case 'SHOW_COUNTY_SELECT_MODAL':
      return {
        ...state,
        countySelectModal: true,
        countySelectData: action.payload,
      };
    case 'HIDE_COUNTY_SELECT_MODAL':
      return { ...state, countySelectModal: false };
    case 'SET_GENDER_REQUIRED':
      return { ...state, genderRequired: true };
    case 'SET_SMOKER_REQUIRED':
      return { ...state, smokerRequired: true };
    case 'RESET_ADDITIONAL_INFO_FORM':
      return {
        ...state,
        countySelectModal: false,
        countySelectData: [],
        smokerRequired: false,
        genderRequired: false,
      };
    case 'TOGGLE_CURRENT_PLAN':
      return { ...state, currentPlanDisplay: !state.currentPlanDisplay };
    case 'SHOW_DRUG_MODAL':
      return { ...state, drugAddModal: true };
    case 'HIDE_DRUG_MODAL':
      return { ...state, drugAddModal: false };
    case 'SHOW_PHARMACY_MODAL':
      return { ...state, pharmacyAddModal: true };
    case 'HIDE_PHARMACY_MODAL':
      return { ...state, pharmacyAddModal: false };
    case 'SHOW_DOCTOR_MODAL':
      return { ...state, doctorAddModal: true };
    case 'HIDE_DOCTOR_MODAL':
      return { ...state, doctorAddModal: false };
    case 'SET_TABLE_TAB':
      return { ...state, [action.payload.key]: action.payload.val };
    case 'SET_SORT_TYPE':
      return { ...state, medicareSortType: action.payload };
    case 'SET_SORT_ORDER':
      return { ...state, sortAsc: action.payload };
    case 'SET_RELEASE_NOTES':
      return { ...state, releaseNotes: action.payload };
    case 'SET_PRIVACY_DISCLOSURE':
      return { ...state, privacyDisclosure: action.payload };
    case 'CLEAR_AGENT':
      return { ...initialState };
    case 'SET_SOA_MODAL_TAB':
      return { ...state, soaModalTab: action.payload };
    case 'RESET_PLAN_TYPE_PAGE':
      return { ...state, planlistTableTab: 'mapd' };
    default:
      return state;
  }
}
