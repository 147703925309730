import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-regular-svg-icons';

// Components
import DrugChart from './DrugChart';
import DrugDetail from './DrugDetail';
import DrugMonthly from './DrugMonthly';
import PharmacyCoverage from './PharmacyCoverage';
// import BenefitHighlights from './BenefitHighlights';
// import ExtraBenefits from './ExtraBenefits';

class EstimatedCosts extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drugChart: {
        isOpening: false,
        isVisible: true,
      },
      drugDetail: {
        isOpening: false,
        isVisible: true,
      },
      drugMonthly: {
        isOpening: false,
        isVisible: true,
      },
      pharmacyCoverage: {
        isOpening: false,
        isVisible: true,
      },
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e, headerId, isCurrentlyVisible, isCurrentlyOpening) {
    e.stopPropagation();
    /* Animate closing/opening our tables */
    if (isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isVisible: false,
        },
      });
    } else if (!isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isOpening: true,
          isVisible: true,
        },
      });
      window.setTimeout(() => {
        this.setState({
          [headerId]: {
            isVisible: !isCurrentlyVisible,
            isOpening: false,
          },
        });
      }, 250);
    }
  }

  render() {
    const {
      drugChart,
      drugDetail,
      drugMonthly,
      pharmacyCoverage,
      selectedPharmacy,
    } = this.state;

    const {
      plan,
      shopper,
      modals,
    } = this.props;
    const { PlanYear: planYear } = (plan.plan || {});

    // Find the highest amount of drugs taken in a month to size sections dynamically
    let drugCount = shopper ? shopper.drugList.length : 10;
    drugCount = Math.max(drugCount, 10);
    let { MonthlyCosts, Copays } = plan.plan;
    const PricingPharmacy = plan.plan.PricingPharmacies[0] || {};

    if (!MonthlyCosts && PricingPharmacy.IsMailOrder && selectedPharmacy === 99999999) {
      MonthlyCosts = PricingPharmacy.MonthlyCosts;
    }

    if (!Copays && PricingPharmacy.IsMailOrder && selectedPharmacy === 99999999) {
      Copays = PricingPharmacy.Copays;
    }

    return (
      <div>
        {/* Total estimated costs by month chart */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'drugChart', drugChart.isVisible, drugChart.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Total Estimated Costs by Month</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={drugChart.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <DrugChart
          plan={plan}
          monthlyCosts={MonthlyCosts}
          effectiveDate={shopper.effectiveDate}
          modals={modals}
          style={{
            // maxHeight is used to animate open/closing
            maxHeight: drugChart.isVisible ? '1000px' : '0',
            overflow: drugChart.isVisible && !drugChart.isOpening ? '' : 'hidden',
            marginBottom: drugChart.isVisible && !drugChart.isOpening ? '40px' : '',
          }}
        />
        {/* Medication cost details (medication phase table) */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'drugDetail', drugDetail.isVisible, drugDetail.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Medication Cost Details</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={drugDetail.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <DrugDetail
          planYear={planYear}
          copays={Copays}
          coveragePhases={plan.plan.SubsidyCoveragePhase}
          modals={modals}
          style={{
            maxHeight: drugDetail.isVisible ? `${drugCount * 200}px` : '0',
            overflow: drugDetail.isVisible && !drugDetail.isOpening ? '' : 'hidden',
            marginBottom: drugDetail.isVisible && !drugDetail.isOpening ? '40px' : '',
          }}
        />
        {/* Medication cost by month (monthly phase table) */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'drugMonthly', drugMonthly.isVisible, drugMonthly.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>Medication Cost by Month</div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={drugMonthly.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <DrugMonthly
          monthlyCosts={MonthlyCosts}
          modals={modals}
          style={{
            /* Each drug row is ~40px tall, max shown 12 months */
            maxHeight: drugMonthly.isVisible ? `${drugCount * 40 * 12}px` : '0',
            overflow: drugMonthly.isVisible && !drugMonthly.isOpening ? '' : 'hidden',
            marginBottom: drugMonthly.isVisible && !drugMonthly.isOpening ? '40px' : '',
          }}
        />
        {/* Pharmacy Coverage */}
        <div style={{ display: plan.plan.HasRXCoverage ? 'block' : 'none' }}>
          <div
            className="plandetails-table-header"
            onClick={(e) => this.handleToggle(e, 'pharmacyCoverage', pharmacyCoverage.isVisible, pharmacyCoverage.isOpening)}
            role="button"
            tabIndex="0"
          >
            <div>Medication Costs by Type of Pharmacy</div>
            <span className="plandetails-table-header-toggle">
              <FontAwesomeIcon icon={pharmacyCoverage.isVisible ? faAngleUp : faAngleDown} />
            </span>
          </div>
          <PharmacyCoverage
            planYear={planYear}
            monthlyCosts={MonthlyCosts}
            tierBenefits={plan.plan.TierBenefits}
            partialCoverageGapTiers={plan.plan.PartialCoverageGapTiers}
            modals={modals}
            style={{
              maxHeight: pharmacyCoverage.isVisible ? '100vh' : '0',
              overflow: pharmacyCoverage.isVisible && !pharmacyCoverage.isOpening ? '' : 'hidden',
              marginBottom: pharmacyCoverage.isVisible && !pharmacyCoverage.isOpening ? '40px' : '',
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    somevar: store.somevar,
    modals: store.theme.modals,
    shopper: store.shopper,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(EstimatedCosts);
