import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { connect } from 'react-redux';

// Constants
import { medicareDisclaimer } from './medicareDisclaimer';

class Disclaimer extends React.PureComponent {
  render() {
    return (
      <div id="doctor-search-disclaimer" className="small">
        {HTMLReactParser(medicareDisclaimer || '')}
      </div>
    );
  }
}

export default connect()(Disclaimer);
