import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import coveragePhaseMap from './coveragePhaseMap';

// Utilities
import { formatDollarAmount } from '../../../../utils/general';

const months = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

const headerTitles = [
  'Month',
  'Cost',
  'Total cost',
  'Medications',
  'Phase',
  'Medication cost',
];

class DrugMonthly extends React.PureComponent {
  constructor(props) {
    super(props);
    this.didRefillPrescription = this.didRefillPrescription.bind(this);
    this.getDrugKey = this.getDrugKey.bind(this);
  }

  getDrugKey(month, drug) {
    return `${month.Month}-${drug.Label}-${drug.PurchasingQTY}`;
  }

  didRefillPrescription(month, drug) {
    const quantityPurchased = drug.PurchasingDaysSupply; // [30, 60, 90, 365]
    const monthIdx = month.Month; // [1, 2, ..., 12]
    // Which months a given quantity is purchased in
    const purchaseTable = {
      30: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      60: [1, 3, 5, 7, 9, 11],
      90: [1, 4, 7, 10],
      365: [1],
    };
    return purchaseTable[quantityPurchased].indexOf(monthIdx) !== -1;
  }

  render() {
    const {
      monthlyCosts,
      effectiveDate,
      navigate,
      selectedPharmacy,
      style,
    } = this.props;

    const [ year, eMonth, day ] = effectiveDate.split('-');
    const effectiveDateObject = new Date(year, eMonth - 1, day, 0, 0);
    const effectiveMonthNumber = effectiveDateObject.getMonth();

    const monthlyCostsToDisplay = monthlyCosts.slice(0, 12 - effectiveMonthNumber);
    const monthsToDisplay = [];
    for (let i = 0; i < 12 - effectiveMonthNumber; i += 1) {
      monthsToDisplay.push((effectiveMonthNumber + i) % 12);
    }

    let totalMedicationCost = 0;
    return (
      <div className="plandetails-table" style={{ ...style }}>
        {
          !monthlyCosts || monthlyCosts.length === 0
            ? (
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <span className="ms-1">
                  <span
                    style={{
                      color: '#0058FF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (selectedPharmacy) {
                        navigate('/rx');
                      } else {
                        navigate('/rx/add/pharmacy');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    { selectedPharmacy ? 'Add medications' : 'Select a pharmacy' }
                  </span> to see medication cost by month.
                </span>
              </div>
            )
            : (
              <table>
                <thead>
                  <tr>
                    {headerTitles.map((title) => (
                      <th
                        className="plandetails-medication-cost-table-header"
                        key={title}
                      >
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="plandetails-medication-cost-table-body">
                  {monthlyCostsToDisplay.map((month, idx) => {
                    totalMedicationCost += month.BeneficiaryPays;
                    return (
                      <tr key={month.Month}>
                        {/* Month */}
                        <td
                          className="plandetails-medication-cost-label"
                          style={{ width: '5%' }}
                        >
                          {/* {months[month.Month]} */}
                          {months[monthsToDisplay[idx % 12]]}
                        </td>
                        {/* Total medication cost of the month */}
                        <td
                          className="plandetails-medication-cost-label"
                          style={{ width: '10%' }}
                        >
                          {formatDollarAmount(month.BeneficiaryPays/* + month.TotalPlanPremium */)}
                        </td>
                        {/* Cost summation through the year */}
                        <td
                          className="plandetails-medication-cost-label"
                          style={{ width: '10%' }}
                        >
                          {formatDollarAmount(totalMedicationCost)}
                        </td>
                        {/* Medications */}
                        <td>
                          <div className="plandetails-medication-cost-list">
                            {month.MonthlyPricedDosages.map((drug) => {
                              /* Make drugs not purchased in this month opaque */
                              const drugIsPurchased = this.didRefillPrescription(month, drug);
                              return (
                                <div
                                  className={`${!drugIsPurchased && 'plandetails-empty'}`}
                                  key={`${this.getDrugKey(month, drug)}-medication`}
                                >
                                  {drug.Label}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        {/* Phase */}
                        <td>
                          <div className="plandetails-medication-cost-list">
                            {month.MonthlyPricedDosages.map((drug) => {
                              const drugIsPurchased = this.didRefillPrescription(month, drug);
                              return (
                                <div
                                  className={`${!drugIsPurchased && 'plandetails-empty'}`}
                                  key={`${this.getDrugKey(month, drug)}-phase`}
                                >
                                  {
                                    drugIsPurchased
                                      ? drug.CoveringCoveragePhases.map((phase, phaseIdx) => `${phaseIdx > 0 ? ', ' : ''}${coveragePhaseMap[phase].name}`)
                                      : '--'
                                  }
                                </div>
                              );
                            })}
                          </div>
                        </td>
                        {/* Medication cost */}
                        <td>
                          <div className="plandetails-medication-cost-list">
                            {month.MonthlyPricedDosages.map((drug) => {
                              const drugIsPurchased = this.didRefillPrescription(month, drug);
                              return (
                                <div
                                  className={`${!drugIsPurchased && 'plandetails-empty'}`}
                                  key={`${this.getDrugKey(month, drug)}-cost`}
                                >
                                  {
                                    drugIsPurchased
                                      ? formatDollarAmount(drug.TotalBeneficaryPays)
                                      : '--'
                                  }
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
        }
      </div>
    );
  }
}

DrugMonthly.defaultProps = {
  monthlyCosts: [],
};

function mapStateToProps(store) {
  return {
    effectiveDate: store.shopper.effectiveDate,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(withRouterProps(DrugMonthly));
