import React from 'react';
import { connect } from 'react-redux';

class Error403 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.props.dispatch({ type: 'HIDE_RELEASE_NOTES' });
  }

  render() {
    return (
      <div className="container">
        <div className="row justify-content-center my-5">
          <div className="col-12 col-md-5 col-xl-4 my-5 text-center">
            <img
              src="https://s3.amazonaws.com/mmb-static-assets-dev/themes/dev/MMBLogo.png"
              alt="MMB_Logo"
              style={{ maxWidth: '75%' }}
            />

            <div className="text-center my-5">

              <h6 className="text-uppercase text-muted mb-4">
                Error
              </h6>

              <h3 className="mb-4">
                You do not have access to this protected site.
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

export default connect(
  mapStateToProps,
)(Error403);
