/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY',
  'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
  'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

function StateButton({ state }) {
  const dispatch = useDispatch();
  const { licenseStates = [] } = useSelector(store => store.appointments.profile);
  const selected = licenseStates.includes(state);

  function handleClick() {
    let updated = [];
    if (selected) {
      updated = licenseStates.filter(s => s !== state);
    } else {
      updated = [ ...licenseStates, state ];
    }
    dispatch({ type: 'SET_APPOINTMENTS_PROFILE_KEYS', payload: { licenseStates: updated } });
  }

  return (
    <button
      type="button"
      className={`btn ${selected ? 'btn-success' : 'btn-light'} mb-2 me-2`}
      onClick={() => handleClick()}
    >
      {state}
    </button>
  );
}

function Licenses() {
  return (
    <div>
      <h1>States I am Licensed in</h1>
      {STATES.map(state => (
        <StateButton state={state} />
      ))}
    </div>
  );
}

export default Licenses;
