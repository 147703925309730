const initialState = {
  customBenefitsSearchKey: [],
  defaults: {
    MA: [],
    MAPD: [],
    PDP: [],
  },
  displayedBenefits: [],
  MACustomBenefits: [],
  MAPDCustomBenefits: [],
  PDPCustomBenefits: [],
  planType: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_BENEFITS_VALUE':
      return { ...state, [action.field]: action.payload };
    case 'SET_BENEFITS_MODAL_PLANTYPE':
      return { ...state, planType: action.payload };
    case 'SET_CUSTOM_BENEFITS_SEARCH_KEY':
      return { ...state, customBenefitsSearchKey: action.payload };
    // case 'ADD_BENEFIT': {
    //   const field = `${action.planType}CustomBenefits`;
    //   return {
    //     ...state,
    //     [field]: [...state[field], action.payload],
    //   };
    // }
    case 'ADD_BENEFIT':
      return {
        ...state,
        displayedBenefits: [...state.displayedBenefits, action.payload],
      };
    // case 'DELETE_BENEFIT': {
    //   const field = `${action.planType}CustomBenefits`;
    //   let benefitsCopy = [...state[field]];
    //   benefitsCopy = benefitsCopy.filter(benefit => benefit.name !== action.payload);
    //   return { ...state, [field]: benefitsCopy };
    // }
    case 'DELETE_BENEFIT': {
      let benefitsCopy = [...state.displayedBenefits];
      benefitsCopy = benefitsCopy.filter(benefit => benefit.name !== action.payload);
      return { ...state, displayedBenefits: benefitsCopy };
    }
    case 'SET_CUSTOM_BENEFITS_DEFAULTS':
      return { ...state, defaults: action.payload };
    case 'RESET_BENEFITS': {
      // const field = `${action.planType}CustomBenefits`;
      // return {
      //   ...state,
      //   [field]: state.defaults[action.planType],
      // };
      return { ...state, displayedBenefits: state.defaults[action.planType] };
    }
    default:
      return state;
  }
}
