import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function BEQButton() {
  const dispatch = useDispatch();
  const { username: shopperUsername } = useSelector(store => store.shopper);
  const { beq: beqFeature } = useSelector(store => store.theme.features);

  return (shopperUsername && beqFeature) ? (
    <div className="ms-auto navbar-shopper-infobar-link">
      <div
        className="navbar-button navbar-shopper-infobar-hover px-3 fw-bold text-black"
        role="button"
        tabIndex={0}
        onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'beqModal' })}
      >
        BEQ
      </div>
    </div>
  ) : <div className="ms-auto">&nbsp;</div>; // if beq feature is disabled need the ms-auto still
}

export default BEQButton;
