import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

// Actions
import { deleteDoctor } from '../../../actions/doctor';

// Components
import StandardModal from '../../common/StandardModal';
import LoaderButton from '../../common/LoaderButton';

// Utilities
import { showPopup } from '../../../utils/popup';

class DeleteModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  deleteDoctor = () => {
    const {
      dispatch,
      doctor: { id },
      anonymous,
    } = this.props;

    this.setState({ loading: true });

    deleteDoctor(id, anonymous)
      .then(() => {
        dispatch({ type: 'DELETE_DOCTOR_FROM_MEDCAB', payload: id });
        this.setState({ loading: false });
      }).catch(() => {
        showPopup('Error', 'There was a problem deleting the doctor.');
        this.setState({ loading: false });
      });
    this.closeModal();
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'HIDE_MODAL', payload: 'deleteDoctorModal' });
    dispatch({ type: 'CLEAR_DELETE_DOCTOR' });
  };

  render() {
    const {
      deleteDoctorModal,
      doctor,
    } = this.props;

    return (
      <StandardModal
        closeIcon={false}
        isOpen={deleteDoctorModal}
        width="350px"
      >
        <div className="col delete-drug-modal-container">
          <div className="row text-center justify-content-center mb-2">
            <FontAwesomeIcon className="rx-trash-icon" icon={faTrash} size="2x" />
          </div>
          <div className="row text-center justify-content-center mb-4">
            <b>
              Are you sure you want to delete <br />
              {doctor.name}? <br />
            </b>
          </div>
          <div className="row justify-content-around">
            <LoaderButton
              type="button"
              text="Delete"
              loadingText="Deleting..."
              color="danger"
              loading={this.state.loading}
              onClick={this.deleteDoctor}
            />
            <LoaderButton
              type="button"
              color="outline-primary"
              text="Cancel"
              onClick={this.closeModal}
            />
          </div>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    doctor: store.doctor.deleteDoctor || {},
    deleteDoctorModal: store.modal.deleteDoctorModal,
    anonymous: store.shopper.anonymous,
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(DeleteModal);
