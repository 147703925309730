import {
  DEFAULT_STATE,
  SUCCESS_STATE,
} from '../constants';

const initialState = {
  shopperLoaded: DEFAULT_STATE,
  shoppersLoaded: DEFAULT_STATE,
  shopperDataLoaded: DEFAULT_STATE,
  shopperWeatherLoaded: DEFAULT_STATE,
  callNotesLoaded: DEFAULT_STATE,
  drugsLoaded: DEFAULT_STATE,
  pharmaciesLoaded: DEFAULT_STATE,
  doctorListLoaded: DEFAULT_STATE,
  medicareListLoaded: DEFAULT_STATE,
  medigapListLoaded: DEFAULT_STATE,
  shopperDoctorsLoaded: DEFAULT_STATE,
  allDoctorPlansLoaded: DEFAULT_STATE,
  dosagesLoaded: DEFAULT_STATE,
  genericLoaded: DEFAULT_STATE,
  root: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_SHOPPER_LOADER':
      return { ...state, shopperLoaded: action.payload };
    case 'SET_SHOPPERS_LOADED_STATE':
      return { ...state, shoppersLoaded: action.payload };
    case 'SET_SHOPPER_DATA_LOADED_STATE':
      return { ...state, shopperDataLoaded: action.payload };
    case 'SET_SHOPPER_WEATHER_LOADED_STATE':
      return { ...state, shopperWeatherLoaded: action.payload };
    case 'SET_CALL_NOTES_LOADED_STATE':
      return { ...state, callNotesLoaded: action.payload };
    case 'SET_SHOPPER_DOCTORS_LOADED_STATE':
      return { ...state, shopperDoctorsLoaded: action.payload };
    case 'SET_ALL_DOCTORS_PLANS_LOADED_STATE':
      return { ...state, allDoctorPlansLoaded: action.payload };
    case 'SET_DOCTOR_PLANS_LOADED_STATE':
      return { ...state, doctorPlansLoaded: action.payload };
    case 'SET_DRUGS_LOADED_STATE':
      return { ...state, drugsLoaded: action.payload };
    case 'SET_PHARMACIES_LOADED_STATE':
      return { ...state, pharmaciesLoaded: action.payload };
    case 'SET_DOCTORS_LOADED_STATE':
      return { ...state, doctorListLoaded: action.payload };
    case 'SET_MEDICARE_LOADED_STATE':
      return { ...state, medicareListLoaded: action.payload };
    case 'SET_MEDIGAP_LOADED_STATE':
      return { ...state, medigapListLoaded: action.payload };
    case 'SET_DOSAGES_LOADED_STATE':
      return { ...state, dosagesLoaded: action.payload };
    case 'SET_GENERIC_LOADED_STATE':
      return { ...state, genericLoaded: action.payload };
    case 'RESET_SHOPPER_LOADERS':
      return {
        ...state,
        doctorListLoaded: DEFAULT_STATE,
        pharmaciesLoaded: DEFAULT_STATE,
        drugsLoaded: DEFAULT_STATE,
        medicareListLoaded: DEFAULT_STATE,
        medigapListLoaded: DEFAULT_STATE,
        shopperDoctorsLoaded: DEFAULT_STATE,
        dosagesLoaded: DEFAULT_STATE,
        genericLoaded: DEFAULT_STATE,
      };
    /* eslint-disable no-case-declarations */
    case 'RESET_LOADERS':
      const failedLoadersReset = {};
      Object.keys(state).forEach((loaderKey) => {
        if (loaderKey === 'zipLoading') {
          // pass
        } else if (state[loaderKey] !== SUCCESS_STATE) {
          failedLoadersReset[loaderKey] = DEFAULT_STATE;
        } else {
          failedLoadersReset[loaderKey] = SUCCESS_STATE;
        }
      });
      failedLoadersReset.root = false;
      return failedLoadersReset;
    case 'SET_ROOT_LOADER':
      return { ...state, root: action.payload };
    case 'SET_LOADER':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
