const initialState = {
  selectedQuote: {},
  selectedShopper: '',
  selectedPlan: {},
  shareQuoteID: '',
  values: {
    method: 'email', // email | sms
    send_email: '',
    send_greeting: '',
    send_closing: 'Thank you,',
    send_signature: '',
    send_sms_phone: '',
    send_sms_greeting: '',
    date_of_appointment: '',
  },
  errors: {},

  busySaving: false,

  // from EnrollConfirmModal -> quotes/openEnrollment -> close
  refreshQuotes: false,

  confirmModalOpen: false,
  confirmQuoteId: null,
  confirmPlanId: null,
  sortKey: 'updated',
  sortInverse: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'CONFIRM_MODAL_OPEN':
      return {
        ...state,
        confirmModalOpen: true,
        confirmQuoteId: action.quote,
        confirmPlanId: action.planID,
      };
    case 'SET_REFRESH_QUOTES_EVENT':
      return { ...state, refreshQuotes: action.payload };
    case 'CONFIRM_MODAL_CLOSE':
      return { ...state, confirmModalOpen: false };
    case 'QUOTE_IS_SAVING':
      return { ...state, busySaving: true };
    case 'QUOTE_DONE_SAVING':
      return { ...state, busySaving: false };
    case 'SET_SHARE_QUOTE_ID':
      return { ...state, shareQuoteID: action.payload };
    case 'SET_SELECTED_PLAN':
      return { ...state, selectedPlan: action.payload };
    case 'SET_SELECTED_QUOTE': {
      const selectedQuote = { ...action.payload };
      return { ...state, selectedQuote };
    }
    case 'SET_SELECTED_SHOPPER': {
      const selectedShopper = { ...action.payload };
      return { ...state, selectedShopper };
    }
    case 'CLEAR_SELECTED_QUOTE':
      return { ...state, selectedQuote: '' };
    case 'SET_QUOTE_LIST_SORT':
      return { ...state, sortKey: action.payload };
    case 'SET_QUOTE_LIST_SORT_INVERSE':
      return { ...state, sortInverse: action.payload };
    case 'CLEAR_QUOTE_LIST':
      return { ...initialState };
    case 'SET_QUOTE_VALUES':
      return {
        ...state,
        values: action.payload,
      };
    case 'SET_QUOTE_VALUE':
      return {
        ...state,
        values: {
          ...state.values,
          [action.field]: action.payload,
        },
      };
    case 'SET_QUOTE_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.payload,
        },
      };
    case 'CLEAR_QUOTE_ERRORS':
      return {
        ...state,
        errors: {},
      };
    default:
      return state;
  }
}
