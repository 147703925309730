import React from 'react';
import { connect } from 'react-redux';

// Actions
import { filterMedicare } from '../../../actions/planfilters';

// Components
import PlanCard from './plancard';
import SOABadge from '../../common/SOABadge';

class MAPDList extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      carrierPerms,
      doctorList,
      medicareList,
      planSort,
      planType,
      selectedPharmacy,
    } = this.props;

    // Compute all plans regardless of filters only once for speedup
    const completeList = filterMedicare(medicareList, {
      planType,
      carrierPerms,
      doctorList,
      npi: selectedPharmacy,
      sortKey: planSort,
    });
    this.state = {
      totalPlanCount: completeList.length,
    };
  }

  componentDidMount() {
    const {
      customBenefitsSearchKey,
      dispatch,
      planType,
      MACustomBenefits,
      MAPDCustomBenefits,
    } = this.props;

    const typeBenefits = JSON.stringify(planType) === '["MA"]' ? MACustomBenefits : MAPDCustomBenefits;
    const payload = customBenefitsSearchKey.filter(benefit => typeBenefits.includes(benefit.id));
    dispatch({ type: 'SET_BENEFITS_VALUE', field: 'displayedBenefits', payload });
  }

  componentDidUpdate(prevProps) {
    const {
      carrierPerms,
      customBenefitsSearchKey,
      doctorList,
      dispatch,
      displayedBenefits,
      MACustomBenefits,
      MAPDCustomBenefits,
      medicareList,
      planSort,
      planType,
      selectedPharmacy,
    } = this.props;

    if (JSON.stringify(planType) !== JSON.stringify(prevProps.planType)) {
      const completeList = filterMedicare(medicareList, {
        planType,
        carrierPerms,
        doctorList,
        npi: selectedPharmacy,
        sortKey: planSort,
      });
      this.setState({
        totalPlanCount: completeList.length,
      });

      const typeBenefits = JSON.stringify(planType) === '["MA"]' ? MACustomBenefits : MAPDCustomBenefits;
      const payload = customBenefitsSearchKey.filter(benefit => typeBenefits.includes(benefit.id));
      dispatch({ type: 'SET_BENEFITS_VALUE', field: 'displayedBenefits', payload });
    }

    if (JSON.stringify(displayedBenefits) !== JSON.stringify(prevProps.displayedBenefits)) {
      const field = `${prevProps.planType}CustomBenefits`;
      dispatch({ type: 'SET_BENEFITS_VALUE', field, payload: displayedBenefits.map(benefit => benefit.id) });
    }
  }

  render() {
    const { totalPlanCount } = this.state;
    const {
      carrierFilters,
      carrierPerms,
      doctorList,
      enrollCarriersFilter,
      medicareList,
      planSort,
      planType,
      selectedPharmacy,
      showSnp,
      showNonSnp,
      soa,
      soaList,
    } = this.props;
    const filters = carrierFilters[planType];
    const displayCarriers = [];
    filters.forEach(([a, b]) => {
      if (b) displayCarriers.push(a);
    });

    // Logic to handle on/off on two checkboxes (SNP filtering)
    // If we want to show only SNP, we set snpValueForFilter = true
    // If we want to show only Non-SNP, we set snpValueForFilter = false
    // If we want both, leave doFilter = false.
    let snpValueForFilter = false;
    let doFilter = false;
    if (!showSnp || !showNonSnp) {
      doFilter = true;

      if (!showSnp && showNonSnp) {
        snpValueForFilter = false;
      }

      if (showSnp && !showNonSnp) {
        snpValueForFilter = true;
      }
    }

    let mapdFilteredList = [];

    if (showSnp || showNonSnp) {
      mapdFilteredList = filterMedicare(medicareList, {
        carrierFilters: displayCarriers,
        doctorList,
        planType,
        carrierPerms,
        npi: selectedPharmacy,
        sortKey: planSort,
        snpFilter: doFilter,
        snpValue: snpValueForFilter,
        enrollCarriersFilter,
      });
    }

    const plans = mapdFilteredList;
    const currentFilterCount = mapdFilteredList.length;

    let mapdMaSoaStatus = true;
    if (soaList.length && soa) {
      if (soaList[0].submitted) {
        if (!soaList[0].mapd_ma) {
          mapdMaSoaStatus = false;
        }
      }
    }
    return (
      <div id="planlist-mapd">
        <span className="d-flex align-items-center fs-5 ms-3 mb-3">
          {`${currentFilterCount} of ${totalPlanCount} plans`}
          <SOABadge planType="MAPD" badgeType="text" />
        </span>
        {plans.map((plan) => (
          <PlanCard
            key={`${plan.cpsID}`}
            plan={plan}
            soaStatus={mapdMaSoaStatus}
          />
        ))}
      </div>
    );
  }
}

MAPDList.defaultProps = {
  plans: [],
};

function mapStateToProps(store) {
  return {
    carrierPerms: store.agent.carrierPermissions,
    carrierFilters: store.plan.carrierFilters,
    customBenefitsSearchKey: store.benefits.customBenefitsSearchKey,
    displayedBenefits: store.benefits.displayedBenefits,
    doctorList: store.shopper.doctorList,
    enrollCarriersFilter: store.plan.enrollCarriersFilter,
    MACustomBenefits: store.benefits.MACustomBenefits,
    MAPDCustomBenefits: store.benefits.MAPDCustomBenefits,
    medicareList: store.plan.medicareList,
    planSort: store.plan.planSort,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    soaList: store.shopper.soaList,
    soa: store.theme.features.soa,
    showSnp: store.plan.snpFilters.showSnp,
    showNonSnp: store.plan.snpFilters.showNonSnp,
  };
}

export default connect(mapStateToProps)(MAPDList);
