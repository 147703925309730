/* eslint-disable */
import React, { useEffect, useState } from 'react';

function Checkbox({
  value = false,
  className,
  style,
  id,
}) {
  let _style = { borderRadius: '0', border: '1px solid black' };
  return value ? (
    <input
      className={[
        'me-1 ms-1',
        'pe-none',
        className,
      ].join(' ')}
      id={id}
      type="checkbox"
      style={{..._style, ...style}}
      onClick={(e) => e.preventDefault()}
      readOnly
      checked
      value={true}
    />
  ) : (
    <input
      className={[
        'me-1 ms-1',
        'pe-none',
        className,
      ].join(' ')}
      id={id} 
      type="checkbox"
      style={{..._style, ...style}}
      onClick={(e) => e.preventDefault()}
      readOnly
      value={false}
    />
  );
};
export default Checkbox;
