import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../utils/hocs';

// Compoments
import StandardModal from '../common/StandardModal';

class Reminder extends React.PureComponent {
  render() {
    const {
      dispatch,
      navigate,
    } = this.props;

    return (
      <StandardModal
        closeIcon={false}
        title="SOA Reminder"
        headerClasses="ps-5"
        isOpen={this.props.soaReminderModal}
        width="650px"
      >
        <div className="px-5 pt-1">
          <div className="mb-4">
            <div className="mb-3">
              The Scope of Appointment has not been approved by the shopper.
              Please remind the shopper or resend the Scope of Appointment to the shopper.
            </div>
            <div className="">
              The shopper needs to approve the Scope of Appointment form before you may
              discuss Medicare insurance products.
            </div>
          </div>
          <div className="d-flex justify-content-around mb-4">
            <button
              id="soa-check-status"
              className="btn btn-info fw-bold text-white ps-4 pe-4"
              color="info"
              onClick={() => {
                dispatch({ type: 'HIDE_MODAL', payload: 'soaReminderModal' });
                navigate('/soa?tab=history');
              }}
              type="button"
            >
              Check Status
            </button>
            <button
              id="resend-soa"
              className="btn btn-warning fw-bold text-white  ps-4 pe-4"
              onClick={() => {
                dispatch({ type: 'HIDE_MODAL', payload: 'soaReminderModal' });
                navigate('/soa');
              }}
              type="button"
            >
              Resend SOA
            </button>
            <button
              id="soa-skip-to-planlist"
              className="btn btn-danger fw-bold text-white ps-4 pe-4"
              onClick={() => {
                dispatch({ type: 'HIDE_MODAL', payload: 'soaReminderModal' });
              }}
              type="button"
            >
              Skip to planlist
            </button>
          </div>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    soaReminderModal: store.modal.soaReminderModal,
    tab: store.display.soaModalTab,
  };
}

export default connect(mapStateToProps)(withRouterProps(Reminder));
