import React from 'react';

import { Radio } from '../components/inputs';

function Attest() {
  return (
    <div>
      <Radio name="attest" />
    </div>
  );
}

export default Attest;
