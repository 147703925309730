/* eslint-disable no-else-return,object-curly-newline */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faUser } from '@fortawesome/pro-regular-svg-icons';

// Actions
import { agentLogout } from '../../../actions/agent';

// Components
import HelpButton from './HelpButton';
import SaveShopperButton from '../quickquote/SaveShopperButton';
import SaveShopperModal from '../quickquote/SaveShopperModal';
import PasswordResetModal from './PasswordResetModal';
import useOutsideClick from '../../../common/hooks/useOutsideClick';

// Utilities
import { formatNameToWidth } from '../../../utils/general';
import { telephonyChecks } from '../../../utils/nav';

function TopNavBar({ activeLink = '/dashboard', disableNavOverride, quickQuote = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    helpButtonLink,
    learningCenterLink,
    logo,
    features: {
      doctors: doctorsFeature,
      mpaPage: mpaFeature,
      soa: soaFeature,
      telephony: telephonyFeature,
      telephonyDebug,
      threeWayCall,
      appointmentLeads: appointmentLeadsFeature,
      drugs: df,
      quoting: qf,
      reports: reportsFeature,
    },
  } = useSelector(store => store.theme);
  // if drugs or quoting are undefined, the features should be on. Only if
  // they are explicitly set to false should we turn them off.
  const quotingFeature = !(qf === false);
  const drugsFeature = !(df === false);
  const {
    agent,
    shopper,
    telephony: {
      meetType,
      softphoneOpen,
      status: callStatus,
    },
  } = useSelector(store => store);
  const {
    callRecording,
    mmbPermissions: {
      can_view_agent_reports: canViewReports,
    },
  } = agent;
  const username = { shopper };
  const [ agentDropdownOpen, setAgentDropdownOpen ] = useState(false);
  const [ clientDropdownOpen, setClientDropdownOpen ] = useState(false);

  // close navbarAgentDropdown with outside click
  const navbarAgentDropdownRef = useRef(null);
  useOutsideClick(navbarAgentDropdownRef, setAgentDropdownOpen);

  // eslint-disable-next-line no-shadow
  function isShopperDetails(activeLink) {
    if (activeLink === `/shopper/${username}`) {
      return true;
    } else if (activeLink === '/shopper') {
      return true;
    }

    return false;
  }

  function validateNavigation(link) {
    // eslint-disable-next-line no-undef, no-restricted-globals
    const currentPath = location.pathname;
    if (currentPath === link) return;
    if (quickQuote && (isShopperDetails(activeLink))) {
      // question (Jason): is quickQuote supposed to be pulled from redux or passed in as props?
      // todo(joeysapp): Confirm quick quote is functional
      dispatch({ type: 'SHOW_MODAL', payload: 'quickQuoteWarningModal' });
      dispatch({ type: 'SET_QQ_WARNING_LINK_STATE', payload: link });
    } else if (link !== '/dashboard') {
      if (link.indexOf('https') === -1) {
        let isValid = true;
        // Agent cannot navigate to /plans without zip/county/city
        if (link === '/plans') {
          if (shopper.zipcode && (!shopper.county || !shopper.city)) {
            isValid = false;
          }
        }

        if (isValid) {
          navigate(link);
        } else {
          // todo(@joeysapp): Remove / understand / document Juan's GlobalEventSystem®
          // So it'll fire here, then it calls this usePreviousJuanThing on shopper.ShopperForm..
          // also, check out this payload.payload.payload.payload
          dispatch({ type: 'SET_EVENT', payload: { name: 'check-validation', payload: { redirect: link } } });
        }
      } else {
        window.open(link, 'MyMedicareBot PTC Enrollment System Tutorial');
      }
    } else {
      if (link === '/dashboard' && softphoneOpen) {
        dispatch({ type: 'SHOW_MODAL', payload: 'softphoneModal' });
        return;
      }
      navigate(link);
    }
  }

  function toggleDropdown(type) {
    if (type === 'agent') {
      setAgentDropdownOpen(!agentDropdownOpen);
      setClientDropdownOpen(false);
    } else {
      setAgentDropdownOpen(false);
      setClientDropdownOpen(!clientDropdownOpen);
    }
  }

  const shopperBaseURLs = [
    `/shopper/${shopper.username}`,
    '/shopper',
    '/shopper/undefined',
  ];
  const shopperBaseURL = shopper.username ? `/shopper/${shopper.username}` : '/shopper/undefined';

  let navbarClientButtons = [
    {
      buttonText: 'Client Info',
      buttonId: 'navbar-button-client-info',
      buttonNavigation: shopperBaseURL,
    },
    {
      buttonText: 'SOA',
      buttonId: 'navbar-button-client-soa',
      buttonNavigation: '/soa',
      disabled: !soaFeature,
    },
    {
      buttonText: 'Rx',
      buttonId: 'navbar-button-client-rx',
      buttonNavigation: '/rx',
      disabled: !drugsFeature,
    },
    {
      buttonText: 'Doctors',
      buttonId: 'navbar-button-client-doctors',
      buttonNavigation: '/doctors',
      disabled: !doctorsFeature,
    },
    {
      buttonText: 'Plans',
      buttonId: 'navbar-button-client-plans',
      buttonNavigation: '/plans',
      disabled: !quotingFeature,
    },
    {
      buttonText: 'Member Letter',
      buttonId: 'navbar-button-client-member-letter',
      buttonNavigation: '/mpa',
      disabled: !mpaFeature,
    },
    {
      buttonText: 'Calls',
      buttonId: 'navbar-button-calls',
      buttonNavigation: '/calls',
      disabled: !telephonyFeature,
    },
  ];

  const navbarSettingsButtons = [
    {
      buttonText: 'Help',
      buttonId: 'navbar-button-settings-help',
      // todo(joeysapp): Likely changing this
      buttonNavigation: '/help',
      disabled: !helpButtonLink,
    },
    {
      buttonText: 'Appointments Profile',
      buttonId: 'navbar-button-settings-appointments',
      buttonNavigation: '/appointments',
      disabled: !appointmentLeadsFeature,
    },
    {
      buttonText: 'Reports / Analytics',
      buttonId: 'navbar-button-settings-reports',
      buttonNavigation: '/reports',
      disabled: (!reportsFeature || !canViewReports),
    },
    {
      buttonText: 'Release Notes',
      buttonId: 'navbar-button-settings-release-notes',
      buttonNavigation: '/releasenotes',
    },
    {
      buttonText: '3-way Call & Recording',
      buttonId: 'navbar-button-settings-3-way-calling',
      disabled: !threeWayCall,
      buttonNavigation: '/3-way-calls',
    },
    {
      buttonText: 'Learning Center',
      buttonId: 'navbar-button-settings-learning-center',
      disabled: !learningCenterLink,
      buttonAction: () => {
        window.open(learningCenterLink);
      },
    },
    {
      buttonText: 'Terms & Conditions',
      buttonId: 'navbar-button-settings-toc',
      buttonNavigation: '/privacy',
    },
    {
      buttonText: 'Change Password',
      buttonId: 'navbar-button-settings-change-password',
      buttonAction: () => {
        dispatch({ type: 'SHOW_MODAL', payload: 'passwordResetModal' });
      },
    },
    {
      buttonText: 'Logout',
      buttonId: 'navbar-button-settings-logout',
      buttonAction: () => {
        if (!['/dashboard', '/3-way-calls', '/help', '/releasenotes', '/privacy'].includes(activeLink)) {
          dispatch({ type: 'SET_EXIT_MODAL_ROUTE', payload: 'logout' });
          dispatch({ type: 'SHOW_MODAL', payload: 'exitModal' });
        } else {
          agentLogout();
        }
      },
    },
  ];

  // Compact View: Place active page at the top of our dropdown
  // $navbarModeLarge referenced from styles/_nav_styles
  // todo(joeysapp): Figure out how to access scss variables via :export
  const navbarModeLarge = 1000;
  if (window.innerWidth <= navbarModeLarge && activeLink !== '/dashboard' && activeLink !== '/3-way-calls') {
    let indexOfCurrentPage = navbarClientButtons.findIndex((b) => b.buttonNavigation === activeLink);
    // If we're on a page without a nav link, just show 'Client Info'
    if (indexOfCurrentPage === -1) indexOfCurrentPage = 0;

    const currentPage = { ...navbarClientButtons.at(indexOfCurrentPage) };
    currentPage.buttonId = `${currentPage.buttonId}-compact-view`;
    navbarClientButtons = navbarClientButtons.filter((b) => b.buttonNavigation !== activeLink);
    navbarClientButtons.unshift(currentPage);
  }

  // Shorten Agent name from [ John Smith ] -> [ John S. ] -> [ J. S. ]
  const agentDisplayName = formatNameToWidth(agent.firstName, agent.lastName, 10);

  return (
    <div className="navbar shadow-sm bg-white border border-nbgrey">
      <div
        className="navbar-button"
        id="navbar-button-home"
        onClick={() => validateNavigation('/dashboard')}
        role="button"
        tabIndex="0"
      >
        <img
          alt="MyMedicareBot Logo"
          className="navbar-icon"
          src={logo}
        />
      </div>
      {/* Navbar Left Side (Client dropdown) */}
      <div
        className="navbar-button"
        id="navbar-button-dashboard"
        onClick={() => validateNavigation('/dashboard')}
        role="button"
        tabIndex={0}
      >
        <span className={`navbar-button-text ${activeLink === '/dashboard' ? 'selected' : ''}`}>
          Clients
        </span>
      </div>
      {threeWayCall && callRecording && (
      <div
        className="navbar-button"
        id="navbar-button-three-way"
        onClick={() => validateNavigation('/3-way-calls')}
        role="button"
        tabIndex={0}
      >
        <span className={`navbar-button-text ${activeLink === '/3-way-calls' ? 'selected' : ''}`}>
          3-Way Calls
        </span>
      </div>
      )}
      <div
        className={`navbar-client-buttons bg-white d-flex ${clientDropdownOpen ? 'shadow-sm' : ''}`}
        style={{ left: callRecording ? '350px' : '225px' }}
      >
        {
            navbarClientButtons.map((b, idx) => {
              /* eslint-disable no-mixed-operators */

              // todo(joeysapp): Redo this in functional react and make it not a headache to read

              // Below classes implement logic for styling inactive links & compact view
              // Non-dashboard link are disabled iff you are not viewing a Client, and
              // if the Client is being created, you cannot navigate further until it is saved
              if (b.disabled) {
                return null;
              }

              /* eslint-disable */
              const disableNav = telephonyChecks({ telephonyFeature, telephonyDebug, meetType, callStatus }) || disableNavOverride;
              const creatingNewShopper = (
                (shopper.created === '' || !shopper.county)
                && (b.buttonNavigation !== '/dashboard' || !shopperBaseURLs.includes(b.buttonNavigation))
              );

              const isDashboardLink = (b.buttonNavigation === '/dashboard')
              const onDash = ['/dashboard'].includes(activeLink);
              const alwaysActive = [
                '/dashboard',
                '/releasenotes',
                '/privacy',
                '/help',
              ].includes(activeLink);

              const isClickable = (
                (
                  (isDashboardLink && onDash) || !alwaysActive
                )
                && !creatingNewShopper
                && (!disableNav || [...shopperBaseURLs, '/dashboard', '/calls'].includes(b.buttonNavigation))
              );

              const buttonHolderClass = [ 'navbar-button' ];
              if (clientDropdownOpen) {
                buttonHolderClass.push('open');
              }
              if (
                ((b.buttonNavigation !== '/dashboard' && activeLink === '/dashboard')
                  || (activeLink === '/releasenotes' || activeLink === '/privacy' || activeLink === '/help'))
                || creatingNewShopper
                || (disableNav && !['/dashboard', ...shopperBaseURLs, '/calls'].includes(b.buttonNavigation))
              ) {
                  buttonHolderClass.push('disabled');
              }

              const buttonTextClass = [ 'navbar-button-text' ];
              if (b.buttonNavigation === activeLink) {
                buttonTextClass.push('selected');
              }
              if (
                (
                  // if on dashboard (or a few other pages), all other links topbar are disabled
                  (b.buttonNavigation !== '/dashboard' && activeLink === '/dashboard')
                  || (activeLink === '/releasenotes' || activeLink === '/privacy' || activeLink === '/help')
                )
                || (!shopperBaseURLs.includes(b.buttonNavigation) && creatingNewShopper)
                || (disableNav && !['/dashboard', ...shopperBaseURLs, '/calls'].includes(b.buttonNavigation))
                || (activeLink === '/3-way-calls' && b.buttonText === 'Client Info' && !shopper.username)
              ) {
                buttonTextClass.push('disabled');
              }

              return (
                <div className={buttonHolderClass.join(' ')} key={b.buttonId}>
                  <div
                    id={b.buttonId}
                    onClick={() => {
                      if (isClickable) {
                        validateNavigation(b.buttonNavigation);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <span className={buttonTextClass.join(' ')}>
                      {b.buttonText}
                    </span>
                  </div>
                  {
                    /* Only visible in Compact view */
                    idx === 0
                      ? (
                        <span
                          className={`navbar-client-dropdown-toggle  ${clientDropdownOpen ? 'open' : ''}`}
                          id="navbar-client-dropdown-toggle"
                          onClick={() => toggleDropdown('client')}
                          role="button"
                          tabIndex="0"
                        >
                          <FontAwesomeIcon
                            className={`navbar-client-dropdown-toggle-icon ${isClickable ? '' : 'disabled'}`}
                            icon={clientDropdownOpen ? faAngleUp : faAngleDown}
                          />
                        </span>
                      )
                      : null
                  }
                </div>
              );
            })
          }
      </div>

      <HelpButton />

      {/* Navbar Right Side (Agent dropdown) */}
      <div
        className={`navbar-agent navbar-button border-left border-bddark ${agentDropdownOpen ? 'open' : 'closed'}`}
        id="navbar-agent"
        onClick={() => toggleDropdown('agent')}
        role="button"
        ref={navbarAgentDropdownRef}
        tabIndex="0"
      >
        <FontAwesomeIcon className="navbar-agent-icon" icon={faUser} />
        <span className="navbar-agent-name">
          {agentDisplayName}
        </span>
        <span
          className={`navbar-agent-dropdown-toggle ${agentDropdownOpen ? 'open' : 'closed'}`}
          id="navbar-agent-dropdown-toggle"
        >
          <FontAwesomeIcon
            className="navbar-agent-dropdown-toggle-icon"
            icon={agentDropdownOpen ? faAngleUp : faAngleDown}
          />
        </span>
        <div className={`navbar-agent-dropdown-content ${agentDropdownOpen ? 'open' : 'closed'}`}>
          {
              navbarSettingsButtons.map((b) => {
                // Disable settings through theme options, for now all usable
                if (b.disabled) {
                  return null;
                }
                const isClickable = true;
                const buttonHolderClass = [
                  'navbar-button',
                  agentDropdownOpen ? 'open' : '',
                ].join(' ');

                const buttonTextClass = [
                  'navbar-button-text',
                  b.buttonNavigation === activeLink ? 'selected' : '',
                ].join(' ');

                return (
                  <div className={buttonHolderClass} key={b.buttonId}>
                    <div
                      id={b.buttonId}
                      onClick={() => {
                        if (isClickable && !b.buttonAction) {
                          validateNavigation(b.buttonNavigation);
                        } else if (b.buttonAction) {
                          b.buttonAction();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <span className={buttonTextClass}>
                        {b.buttonText}
                      </span>
                    </div>
                  </div>
                );
              })
            }
        </div>
      </div>

      {/* Behavior will change, i.e. auto-save on every change */}
      <SaveShopperModal />
      <SaveShopperButton />
      <PasswordResetModal />
    </div>
  );
}

export default TopNavBar;
