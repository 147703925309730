import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithTracker from '../common/WithTracker';

// Components
import RouterLogic from './RouterLogic';
import DoctorSearch from './search';
import DoctorList from './list';

export default function () {
  return (
    <WithTracker>
      <Routes>
        <Route path="" element={<RouterLogic />} />
        <Route path="search" element={<DoctorSearch />} />
        <Route path="list" element={<DoctorList />} />
      </Routes>
    </WithTracker>
  );
}
