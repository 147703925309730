/* eslint-disable */
import axios from 'axios';
import { store } from '../store';
import {
  AWAIT_STATE,
  SUCCESS_STATE,
  FAIL_STATE,
} from '../constants';

function getRadii(zip) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/pharmacy/${zip}/radius/`,
    }).then((res) => {
      const radiusList = res.data;
      store.dispatch({ type: 'SET_RADII', payload: radiusList });
      resolve(radiusList);
    }).catch((err) => {
      reject(err);
    });
  });
}

function getPharmacyList(zip, county, radius) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/pharmacy/${zip}/${radius}/${county}/`,
    }).then((res) => {
      const reformattedData = res.data.map(pharmacy => ({
        npi: Number(pharmacy.NPI),
        name: pharmacy.Name,
        address1: pharmacy.Address1,
        address2: pharmacy.Address2,
        city: pharmacy.City,
        state: pharmacy.State,
        zip: pharmacy.Zip,
        phone: pharmacy.Phone,
        preferredCount: pharmacy.PreferCount,
        standardCount: pharmacy.StandardCount,
      }));
      store.dispatch({ type: 'SET_PHARMACIES', payload: reformattedData });
      store.dispatch({ type: 'SET_SEARCH_ZIP', payload: zip });
      resolve(reformattedData);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function getPharmacies(zip, county, radius = null) {
  return new Promise((resolve, reject) => {
    if (radius) {
      getPharmacyList(zip, county, radius)
        .then((res) => resolve(res))
        .catch(() => reject());
    } else {
      getRadii(zip)
        .then((radiusList) => {
          getPharmacyList(zip, county, radiusList[0])
            .then((res) => resolve(res))
            .catch(() => reject());
        });
    }
  });
}

export function savePharmacy(pharmacy, username, quickQuote = false) {
  const token = window.localStorage.getItem('SessionToken');
  let rfmPharmacy = {
    pharmacy_id: pharmacy.npi,
    name: pharmacy.name,
    address_1: pharmacy.address1,
    address_2: pharmacy.address2,
    city: pharmacy.city,
    state: pharmacy.state,
    zip: pharmacy.zip,
    phone: pharmacy.phone,
    preferred_count: pharmacy.preferredCount,
    standard_count: pharmacy.standardCount,
  };
  return new Promise((resolve, reject) => {
    if (!username && quickQuote) {
      resolve(pharmacy);
    } else {
      axios({
        method: 'POST',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/pharmacy/`,
        headers: { Authorization: `Token ${token}` },
        params: { shopper: username },
        data: rfmPharmacy,
      })
        .then((res) => {
          const id = res.data.id;
          const pharmacyWithID = { ...pharmacy, id: id };
          resolve(pharmacyWithID);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

export function saveQuickQuotePharmacies(pharmacyList, username) {
  const promiseArray = [];

  pharmacyList.forEach((pharmacy) => {
    promiseArray.push(savePharmacy(pharmacy, username));
  });

  return Promise.all(promiseArray).then((values) => {
    const updatedList = [...pharmacyList];

    for (let i = 0; i < values.length; i += 1) {
      updatedList[i].id = values[i].id;
    }

    store.dispatch({ type: 'SET_SHOPPER_PHARMACIES', payload: updatedList });
  });
}

export function deletePharmacy(npi, username, quickQuote = false) {
  const token = window.localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    if (!username && quickQuote) {
      resolve();
    } else {
      axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/pharmacy/${npi}/`,
        headers: { Authorization: `Token ${token}` },
        params: { shopper: username },
      })
        .then((res) => {
          resolve(res.data);
        }).catch((err) => {
          reject(err);
        });
    }
  });
}

function mapShopperPharmacyMetaData(pharmacyData) {
  const returnedPharmacyData = pharmacyData.map(pharmacy => ({
    id: pharmacy.id,
    site: pharmacy.site,
    shopper: pharmacy.shopper,
    npi: pharmacy.pharmacy_id,
    name: pharmacy.name,
    address1: pharmacy.address_1,
    address2: pharmacy.address_2,
    city: pharmacy.city,
    state: pharmacy.state,
    zip: pharmacy.zip,
    phone: pharmacy.phone,
    preferredCount: pharmacy.preferred_count,
    standardCount: pharmacy.standard_count,
    current: pharmacy.current,
    dateStart: pharmacy.date_start,
    dateEnd: pharmacy.date_end,
  }));
  return returnedPharmacyData;
}

export function loadPharmacies(username) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/pharmacy/`,
      headers: { Authorization: `Token ${token}` },
      params: { shopper: username },
    }).then((res) => {
      const withMetaData = mapShopperPharmacyMetaData(res.data);
      resolve(withMetaData);
    }).catch((err) => {
      reject(err);
    });
  });
}
