/* eslint-disable */
/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';

/*
  Hook to determine what scroll state an element is in
*/
export default function useScroll(ref, options = {}) {
  options = {
    top: true,
    left: false,
    ...options,
  };
  const [scroll, setScroll] = useState(0);
  const handleScroll = (event) => {
    // option for scrollTop
    if (options.left) {
      setScroll(ref.current.scrollLeft);
    } else {
      setScroll(ref.current.scrollTop);
    }
  }

  useEffect(() => {
    ref.current.addEventListener('scroll', handleScroll);
    return () => {
      if (ref && ref.current) ref.current.removeEventListener('scroll', handleScroll);
    }
  }, [ref]);

  return scroll;
}
