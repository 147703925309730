const initialState = {
  selectedUsername: '',
  editAgentTab: 'details',
  editing: false,
  erroring: false,
  saving: false,
  values: {},
  errors: {},
  accessSaving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_EDIT_DETAILS':
      return { ...state, editing: true };
    case 'CLOSE_EDIT_DETAILS':
      return { ...state, editing: false };
    case 'DETAILS_SAVING':
      return { ...state, saving: true };
    case 'DETAILS_DONE_SAVING':
      return { ...state, saving: false };
    case 'LOAD_AGENT_DETAILS': {
      const values = { ...action.payload };
      return { ...state, values };
    }
    case 'EDIT_DETAILS': {
      const values = { ...state.values, [action.id]: action.value };
      const errors = { ...state.errors, [action.id]: !action.valid };
      const erroring = Object
        .keys(errors)
        .reduce((_erroring, id) => _erroring || errors[id], false);

      return {
        ...state,
        values,
        errors,
        erroring,
      };
    }
    case 'CHANGE_EDIT_AGENT_TAB':
      return { ...state, editAgentTab: action.payload };
    case 'SET_EDIT_AGENT':
      return { ...state, selectedUsername: action.payload };
    case 'ACCESS_SAVING':
      return { ...state, accessSaving: true };
    case 'ACCESS_DONE_SAVING':
      return { ...state, accessSaving: false };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
