/* eslint-disable */
const initialState = {
  calls: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_CALL_RECORDS':
      return {
        ...state,
        calls: action.calls,
      };
    case 'CLEAR_SHOPPER':
      return initialState;
    default:
      return state;
  }
}
