/* eslint-disable quotes,dot-notation */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import MobileNavbar from '../nav/MobileNavbar';

class ErrorPage extends React.PureComponent {
  render() {
    const {
      dispatch,
      tl,
    } = this.props;
    const { errorType } = this.props.match.params;
    let errorText = `Whoops! Couldn't find a shopper record. Please try loading your notification link again or logging in through a computer to view your lead.`;
    try {
      errorText = tl['Mobile']['Errors']['Generic'];
      if (errorType === 'lead-not-owned') {
        errorText = tl['Mobile']['Errors']['Lead Not Owned'];
      }
    } catch (err) {
      errorText = `Whoops! Couldn't find a shopper record. Please try loading your notification link again or logging in through a computer to view your lead.`;
    }

    return (
      <>
        <MobileNavbar />
        <div className="mobile-container">
          <Link
            className="mobile-page-navigation"
            to="/mobile/dashboard"
            onClick={() => { dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: true }); }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                fontSize: '23px',
                marginRight: '13px',
                height: 'auto',
              }}
            />
            <div>
              All Leads
            </div>
          </Link>
          <div className="mobile-page-content mobile-font-small">
            <div
              className="mobile-font-small"
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                textAlign: 'center',
                textStyle: 'italic',
                color: 'rgb(128, 128, 128)',
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '80px',
                  width: '100%',
                  paddingBottom: '18px',
                }}
                icon={faExclamationTriangle}
              />
              {errorText}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    tl: store.translations.sets[store.translations.setLanguage],
  };
}

export default connect(mapStateToProps)(ErrorPage);
