/* eslint-disable */
import React from 'react';

const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA',
  'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY',
  'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
  'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

function StateButton({
  state,
  setReduxProfileKeys,
  reduxProfile,
  disabled,
}) {
  const { travelStates = [] } = reduxProfile;
  const selected = travelStates.includes(state);

  function handleClick() {
    let updated = [];
    if (selected) {
      updated = travelStates.filter(s => s !== state);
    } else {
      updated = [ ...travelStates, state ];
    }
    setReduxProfileKeys({ travelStates: updated });
  }

  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn ${selected ? 'btn-success' : 'btn-light'} mb-2 me-2`}
      onClick={() => handleClick()}
    >
      {state}
    </button>
  );
}

function StateZipEntry({ setReduxProfileKeys, reduxProfile, disabled }) {
  return (
    <div>
      <p>
        I will travel to all locations within the specified states.
      </p>
      <br />
      {STATES.map(state => (
        <StateButton
          state={state}
          setReduxProfileKeys={setReduxProfileKeys}
          reduxProfile={reduxProfile}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

export default StateZipEntry;
