import React from 'react';
import { connect } from 'react-redux';

// Actions
import { checkIfLoggedIn } from '../../../actions/session';
import { loadShoppers } from '../../../actions/shopper';

// Components
import MobileNavbar from '../nav/MobileNavbar';
import MobileShopperList from './MobileShopperList';

// Utils
import { withRouterProps } from '../../../utils/hocs';

class MobileDashboard extends React.PureComponent {
  componentDidMount() {
    const {
      agentEmail,
      dispatch,
      navigate,
    } = this.props;

    // note(@joeysapp): Always clear out old shopperList data
    dispatch({ type: 'SET_MOBILE_SHOPPER_LIST', payload: [] });
    dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: true });

    checkIfLoggedIn().then(() => {
      loadShoppers({
        user_who_created: agentEmail,
      }).then((data) => {
        dispatch({ type: 'SET_MOBILE_SHOPPER_LIST', payload: data });
        dispatch({ type: 'SET_MOBILE_SHOPPER_LIST_LAST_PAGE_NUM', payload: 1 });

        dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: false });
      }).catch(() => {
        navigate('/mobile/error');
      });
    }).catch(() => {
      navigate('/mobile/login');
    });
  }

  /* eslint-disable */
  handleScroll(e) {
    const {
      agentEmail,
      dispatch,
      shopperListLastPageNum,
      shoppersLoaded,
      totalItemsCount,
      itemsCountPerPage,
    } = this.props;
    const c = document.getElementById('container');

    const unloadedShoppersExist = (shopperListLastPageNum * itemsCountPerPage) < totalItemsCount;
    const loadersCompleted = shoppersLoaded === 'SUCCESS_STATE';
    const scrolledToBottomOfList = c.offsetHeight + c.scrollTop >= c.scrollHeight;

    if (unloadedShoppersExist && scrolledToBottomOfList && loadersCompleted) {
      loadShoppers({
        page: shopperListLastPageNum + 1,
        user_who_created: agentEmail,
      }).then((data) => {
        dispatch({ type: 'SET_MOBILE_SHOPPER_LIST_LAST_PAGE_NUM', payload: shopperListLastPageNum + 1 });
        dispatch({ type: 'APPEND_MOBILE_SHOPPER_LIST', payload: data });
      });
    }
  }

  render() {
    return (
      <>
        <MobileNavbar />
        <div
          role="grid"
          tabIndex={0}
          id="container"
          className="mobile-container"
          onScroll={(e) => { this.handleScroll(e); }}
          onWheel={(e) => { this.handleScroll(e); }}
        >
          <div className="mobile-page-navigation">
            <div
              className="mobile-font-large"
              style={{
                color: 'black',
                lineHeight: '34px',
              }}
            >
              Your Leads
            </div>
          </div>
          <div id="list" className="mobile-page-list">
            <MobileShopperList />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    agentEmail: store.agent.email,
    isLoading: store.mobile.isLoading,
    shoppersLoaded: store.loaders.shoppersLoaded,
    shopperList: store.mobile.shopperList || [],
    shopperListLastPageNum: store.mobile.shopperListLastPageNum,
    sortTerm: store.mobile.sortTerm || 'status',
    totalItemsCount: store.shopperlist.totalItemsCount,
    itemsCountPerPage: store.shopperlist.itemsCountPerPage,
  };
}

export default connect(mapStateToProps)(withRouterProps(MobileDashboard));
