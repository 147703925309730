import React, { useState } from 'react';
import { connect } from 'react-redux';
import { faPrescriptionBottleAlt } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import NavItem from './NavItem';
import NavPopover from './NavPopover';

function Drugs({
  dispatch,
  drugList,
  navigate,
  showDrugPopover,
  disabled,
}) {
  const [parentRef, setParentRef] = useState();

  return (
    <NavItem
      show={showDrugPopover}
      icon={faPrescriptionBottleAlt}
      count={drugList.length}
      popoverName="showDrugPopover"
      setRef={setParentRef}
      title={'Add medications'}
    >
      <NavPopover
        show={showDrugPopover}
        popoverName="showDrugPopover"
        parentRef={parentRef}
      >
        <b>{drugList.length} {drugList.length > 1 || !drugList.length ? 'Medications' : 'Medication'}</b>
        <div className="col navbar-drug-card-container mt-3">
          {
            drugList && drugList.length > 0
              ? (
                drugList.map((drug) => (
                  <div key={`${drug.drugID}-${drug.id}`} className="row navbar-drug-card">
                    <div className="col py-2 mb-2">
                      <b>{drug.name}</b>
                      <br />
                      <i>{drug.dosage}</i>
                    </div>
                  </div>
                ))
              )
              : (
                <div className="row navbar-drug-card text-center align-middle">
                  <div className="col py-3">
                    <i>Looks like no medications have been added yet.</i>
                  </div>
                </div>
              )
          }
        </div>
        <button
          type="button"
          className="btn btn-primary fw-bold mt-3"
          disabled={disabled}
          onClick={() => {
            navigate('/rx/add/drug');
            dispatch({ type: 'HIDE_POPOVER', payload: 'showDrugPopover' });
          }}
        >
          Add medications
        </button>
      </NavPopover>
    </NavItem>
  );
}

function mapStateToProps(store) {
  return {
    showDrugPopover: store.popover.showDrugPopover,
    drugList: store.shopper.drugList,
  };
}

export default connect(mapStateToProps)(withRouterProps(Drugs));
