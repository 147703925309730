/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { getDosages } from '../../../actions/drug';
import { AWAIT_STATE, FAIL_STATE, SUCCESS_STATE } from '../../../constants';

class GenericDrugButton extends React.PureComponent {
  render() {
    const {
      dispatch,
      displayBrandDrug,
      genericLoaded,
      genericDrug,
      selectedDrug,
    } = this.props;

    // if there's no drug selected we don't want to see anything
    if (!selectedDrug) {
      return null;
    }

    // if we're still loading dosages, show loader
    if (!genericDrug && genericLoaded === AWAIT_STATE) {
    // if (true) {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }

    // if there isn't a generic drug, display messaging
    if (!genericDrug) {
      return <i>No generic available</i>;
    }

    let classes = 'btn-brand-v-generic';

    if (!displayBrandDrug) {
      classes = 'btn-brand-v-generic-active';
    }

    return (
      <button
        type="button"
        className={`btn btn-block w-100 ${classes}`}
        onClick={() => {
          dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: AWAIT_STATE });
          dispatch({ type: 'DISPLAY_BRAND_DRUG', payload: false });
          dispatch({ type: 'SET_SELECTED_DRUG', payload: genericDrug });
          getDosages(genericDrug.DrugID)
            .then(() => dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: SUCCESS_STATE }))
            .catch(() => dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: FAIL_STATE }));
        }}
      >
        <div>
          { genericDrug.DrugName || 'Generic Drug' }
        </div>
        <div style={{ marginTop: '-10px' }}>
          <i className="small text-muted">(generic)</i>
        </div>
      </button>
    );
  }
}

function mapStateToProps(store) {
  return {
    displayBrandDrug: store.drug.displayBrandDrug,
    genericLoaded: store.loaders.genericLoaded,
    genericDrug: store.drug.genericDrug,
    selectedDrug: store.drug.selectedDrug,
  };
}

export default connect(mapStateToProps)(GenericDrugButton);
