export function getFilterList({
  planList,
  filterType = 'org', // must be 'carrier' or 'org' or 'brand'
}) {
  const validFilterTypes = ['org', 'carrier', 'brand'];
  if (!validFilterTypes.includes(filterType)) {
    throw Error('filterType is invalid. src/utils/plans.getFilterList');
  }
  const names = [];
  planList.forEach(plan => {
    if (names.includes(plan[filterType])) return;
    names.push(plan[filterType]);
  });
  return names;
}

export function renderDisplayCPS(DRPlanData) {
  return `${DRPlanData.ExternalContractID}-${DRPlanData.ExternalPlanID}-${DRPlanData.ExternalSegmentID}`;
}
