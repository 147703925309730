/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import Checkbox from './Checkbox';
import { prettifyPhone } from '../../utils/prettify';

import { loadConfig } from '../../actions/config';

function PageTwo({
  enrollment: {
    fields = {},
  },
  setLoaded = () => {},
}) {
  const {
    ethnicity = [],
    race = [],

    preflang,

    altwritten,

    willwork,
    willworkspouse,

    pcp_name,
    pcp_num,

    email,
    okemail,

    enrolltype = '',
    sep_code,
    sep_date,

    plan = {},
  } = fields;

  const {
    CustomerServiceHours,
    PlanTypeDesc,
    PlanName,
    TotalPlanPremium,
    MemberPhoneNumber,
    TTYTDDPhoneNumber,
  } = plan;

  // note(joeysapp): Is there any possibility of adding to these?
  const [ethnicities, setEthnicities] = useState([]);
  const [races, setRaces] = useState([]);
  useEffect(() => {
    /* eslint-disable arrow-body-style */
    Promise.all([
      loadConfig('enrollment_form_ethnicity_options_json', 'ALL_SITES_SITE', 'public')
        .then(({ data: newData = {} }) => {
          let { value } = newData;
          setEthnicities(value);
        }),
      loadConfig('enrollment_form_race_options_json', 'ALL_SITES_SITE', 'public')
        .then(({ data: newData = {} }) => {
          const { value } = newData;
          setRaces(value);
        }),
    ]).then((e) => {
      setLoaded(true);
    });
  }, []);

  const [electionPeriod, setElectionPeriod] = useState('');
  useEffect(() => {
    if (enrolltype.toLowerCase() === 'aep' || !sep_code) {
      setElectionPeriod(enrolltype);
    } else {
      setElectionPeriod(`${sep_code.toUpperCase()}${sep_date ? moment(sep_date).format(' MM/DD/YYYY') : ''}`);
    }
  }, [enrolltype, sep_code, sep_date]);

  // Matching Medicare form's language
  function padEthnicityLabel(value, label) {
    if (value === 'NA') {
      return `No, ${label}.`;
    } else if (value !== 'NR') {
      return `Yes, ${label}.`;
    }
    // Choose not to answer needs no padding
    return label;
  }

  function PrivacyStatement() {
    return (
      <div className="d-flex flex-column m-3 ms-5 me-5" style={{ fontSize: '10px', lineHeight: '12px' }}>
        <div className="fw-bold ms-auto me-auto">PRIVACY ACT STATEMENT</div>
        {PlanTypeDesc === 'PDP'
          ? (
            <div className="">
              The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1860D-1 of the Social Security Act and 42 CFR §§ 423.30 and 423.32 authorize the collection of this information. CMS may use, disclose and exchange enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
            </div>
          ) : (
            <div className="">
              The Centers for Medicare & Medicaid Services (CMS) collects information from Medicare plans to track beneficiary enrollment in Medicare Advantage (MA) Plans, improve care, and for the payment of Medicare benefits. Sections 1851 of the Social Security Act and 42 CFR §§ 422.50 and 422.60 authorize the collection of this information. CMS may use, disclose and exchange enrollment data from Medicare beneficiaries as specified in the System of Records Notice (SORN) "Medicare Advantage Prescription Drug (MARx)", System No. 09-70-0588. Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
            </div>
          )}
      </div>
    );
  }

  function EthnicityQuestion() {
    return (
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">Are you Hispanic, Latino/a, or Spanish origin? Select all that apply.</div>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            {ethnicities.slice(0, 2).map(({ value, label }) => {
              return <div className="d-flex" key={label} ><Checkbox value={ethnicity.indexOf(value) !== -1} /> {padEthnicityLabel(value, label)}</div>
            })}
          </div>
          <div className="d-flex flex-column">
            {ethnicities.slice(3, -1).map(({ value, label }) => {
              return <div className="d-flex" key={label} ><Checkbox value={ethnicity.indexOf(value) !== -1} /> {padEthnicityLabel(value, label)}</div>
            })}
          </div>
        </div>
        {/* Choose not to answer is at bottom */}
        {ethnicities.slice(-1).map(({ value, label }) => {
          return (
            <div className="d-flex fw-bold" key={label} ><Checkbox value={ethnicity.indexOf(value) !== -1} /> {padEthnicityLabel(value, label)}</div>
          );
        })}
      </div>
    );
  }

  function RaceQuestion() {
    return (
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">What's your race? Select all that apply.</div>
        <div className="d-flex">
          {/* The official medicare printout has 3 columns, but ours are stored in sequence, so do rows and wrap: */}
          <div className="d-flex flex-row flex-wrap" style={{ maxWidth: '755px' }}>
            {races.slice(0, -1).map(({ value, label }) => {
              return (
              <div className="d-flex text-nowrap" key={label} style={{ width: 'calc(755px / 3)' }}><Checkbox value={race.indexOf(value) !== -1} /> {label}</div>
              );
            })}
          </div>
        </div>
        {/* Choose not to answer is at bottom */}
        {races.slice(-1).map(({ value, label }) => {
          return (
            <div className="d-flex fw-bold" key={label} ><Checkbox value={race.indexOf(value) !== -1} /> {label}</div>
          );
        })}
      </div>
    );
  }

  return (
    <>
    <div className="d-flex flex-column w-100 border">
      <div className="d-flex justify-content-center fw-bold border-bottom p-1 ps-1 fs-4 bg-grey-300 sans-serif">
        Section 2 - All fields on this page are optional
      </div>
      <div className="d-flex justify-content-center border-bottom fw-bold">
        Answering these questions is your choice. You can't be denied coverage because you don't fill them out.

      </div>
      {/* ---------------------------------------- */}
      <EthnicityQuestion />
      <RaceQuestion />
      {/* ---------------------------------------- */}
      <div className={[(preflang && (preflang.toLowerCase() !== 'english')) ? 'd-flex' : 'd-none', 'flex-column border-bottom ps-1'].join(' ')}>
        <div className="">Select one if you want us to send you information in a language other than English.</div>
        <div className="d-flex">
          <Checkbox value /> {preflang}
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">Select one if you want us to send you information in an accessible format.</div>
        <div className="d-flex pb-3">
          <div className="me-5"><Checkbox value={altwritten === 'Braille'} /> Braille</div>
          <div className="me-5"><Checkbox value={altwritten === 'LargePrint'} /> Large print</div>
          <div className=""><Checkbox value={altwritten === 'Audio'} /> Audio CD</div>
        </div>
        <div className="d-flex">
          Please contact {PlanName} at {prettifyPhone(MemberPhoneNumber)} if you need information in an accessible format other than what's listed above. {CustomerServiceHours ? `Our office hours are ${CustomerServiceHours}. ` : ''}TTY users can call {TTYTDDPhoneNumber}{prettifyPhone(MemberPhoneNumber)}.
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex border-bottom ps-1">
        <div className="d-flex w-100">
          <div className="me-2">Do you work?</div>
          <Checkbox value={willwork === true} /> Yes
          <Checkbox value={willwork === false} /> No
        </div>
        <div className="d-flex w-100">
          <div className="me-2">Does your spouse work?</div>
          <Checkbox value={willworkspouse === true} /> Yes
          <Checkbox value={willworkspouse === false} /> No
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">
          List your Primary Care Physician (PCP), clinic, or health center:
          <span className="ms-1">{pcp_name} (#{pcp_num})</span>
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">I want to get the following materials via email. Select one or more.</div>
        <div className="d-flex">
          <Checkbox value={okemail === true} />
          {/* [Plans may list those types or categories of materials that are available for electronic delivery] */}
          Agent communication
        </div>
        <div className="d-flex">
          E-mail address: {email}
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="">
          Election period: {electionPeriod}
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className={`${TotalPlanPremium > 0 ? 'd-flex' : 'd-none'} flex-column border-bottom ps-1`}>
        <div className="d-flex justify-content-center fs-4 fw-bold sans-serif">Paying your plan premiums</div>
        <div className="">
          You can pay your monthly plan premium
          {PlanTypeDesc === 'MAPD' && (' (including any late enrollment penalty that you currently have or may owe) ')}
          by mail each month.
          <span className="fw-bold">
            You can also choose to pay your premium by having it automatically taken out of your Social Security or Railroad Retirement Board (RRB) benefit each month.
          </span>
        </div>
        {PlanTypeDesc !== 'MA' && (
          <div className="">
            If you have to pay a Part D-Income Related Monthly Adjustment Amount (Part D-IRMAA), you must pay this extra amount in addition to your plan premium. DON'T pay {PlanName} the Part D-IRMAA.
          </div>
        )}
      </div>
    </div>
    <PrivacyStatement />
    </>
  );
}
export default PageTwo;
