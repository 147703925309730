import React from 'react';
import { connect } from 'react-redux';

// Components
import Drugs from './Drugs';
import Pharmacy from './Pharmacy';
import Doctors from './Doctors';
import Notes from './notes';
import Quotes from './Quotes';
import BEQButton from './BEQButton';

import { telephonyChecks } from '../../../../utils/nav';

const Dropdowns = ({
  doctorsFeature,
  telephonyFeature,
  telephonyDebug,
  meetType,
  callStatus,
}) => {
  /* eslint-disable object-curly-newline */
  const disableNav = telephonyChecks({ telephonyFeature, telephonyDebug, meetType, callStatus });
  return (
    <div
      className="ms-auto navbar-shopper-infobar-links"
      id="navbar-shopper-infobar-links"
    >
      <BEQButton />
      <Drugs disabled={disableNav} />
      <Pharmacy disabled={disableNav} />
      {doctorsFeature && <Doctors disabled={disableNav} />}
      <Notes disabled={disableNav} />
      <Quotes disabled={disableNav} />
    </div>
  );
};

function mapStateToProps(store) {
  return {
    doctorsFeature: store.theme.features.doctors,
    telephonyFeature: store.theme.features.telephony,
    telephonyDebug: store.theme.features.telephonyDebug,
    meetType: store.telephony.meetType,
    callStatus: store.telephony.status,
  };
}

export default connect(mapStateToProps)(Dropdowns);
