/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../utils/hocs';

// Components
import LoaderButton from '../../common/LoaderButton';
import CompareFooterPlancard from './CompareFooterPlancard';

function CompareFooter({
  planIds,

  backOnClick,
  backButtonText,
  footerStyle,

  openOnLoad,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPlan } = useSelector(store => store.shopper);
  const { visible } = useSelector(store => store.compare);

  const goingToComparePage = backButtonText === 'Compare';
  const arrowElement = (
    <FontAwesomeIcon
      style={{ color: 'white', fontSize: '24px' }}
      icon={goingToComparePage ? faAngleRight : faAngleLeft}
      className={`fa-lg ${goingToComparePage ? 'ms-2' : 'me-2'}`}
    />
  );
  const buttonElement = (
    <b style={{ color: 'white', verticalAlign: 'text-bottom', fontSize: '15px' }}>
      {backButtonText}
    </b>
  );
  let loaderComponent = (
    <span>
      {arrowElement}
      {buttonElement}
    </span>
  );
  if (backButtonText === 'Compare') {
    loaderComponent = (
      <span>
        {buttonElement}
        {arrowElement}
      </span>
    );
  }

  return (
    <div
      className="planlist-compare-footer d-flex position-fixed w-100 bg-primary fs-5"
      style={{
        bottom: visible ? '0px' : '-70px',
        ...footerStyle,
      }}
    >
      <div className="planlist-compare-footer-container">
        <div className="d-flex" style={{ flexBasis: '0', flexGrow: '1', marginRight: '30px' }}>
          <LoaderButton
            className="fw-bold ps-5 pe-5"
            style={{ marginTop: '12px', marginBottom: '12px' }}
            color="primaryFocus"
            text={loaderComponent}
            onClick={() => {
              if (backOnClick) {
                backOnClick();
              } else {
                navigate('/plans/list');
              }
            }}
          />
        </div>
        <CompareFooterPlancard planID={currentPlan ? currentPlan : planIds[0]} showCurrentPlan={currentPlan ? true : false} />
        <CompareFooterPlancard planID={currentPlan ? planIds[0] : planIds[1]} />
        <CompareFooterPlancard planID={currentPlan ? planIds[1] : planIds[2]} />
      </div>
      <div
        className="planlist-compare-footer-toggle text-white"
        onClick={() => {
          dispatch({ type: 'TOGGLE_COMPARE_FOOTER' });
          dispatch({ type: 'USER_TOGGLE_COMPARE_FOOTER', payload: true });
        }}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={visible ? faAngleDown : faAngleUp} />
      </div>
    </div>
  );
  // }
}

CompareFooter.defaultProps = {
  backButtonText: 'Back to all plans',
  backOnClick: false,
  openOnLoad: false,
  planIds: [],
};

function mapStateToProps(store) {
  return {
    planIds: store.compare.planIds,
    currentPlan: store.shopper.currentPlan,
  };
}

export default connect(mapStateToProps)(withRouterProps(CompareFooter));
