/* eslint-disable array-bracket-spacing */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import { withRouterProps } from '../../../utils/hocs';

// Actions
import { pushTrackingEvent } from '../../../actions/tracking';

// Components
import LoginBox from '../../landing/login/LoginBox';
import MobileNavbar from '../nav/MobileNavbar';

function MobileLogin({
  dispatch,
  agentUsername,
  shopperUsername,
  loginRedirectTo,
  navigate,
}) {
  const [ showFooter, setShowFooter ] = useState(true);

  useEffect(() => {
    dispatch({ type: 'SET_LOGIN_LOADING', payload: false });
    pushTrackingEvent('sms_link_login_page', agentUsername, shopperUsername);
  }, []);

  return (
    <>
      <MobileNavbar />
      <div className="mobile-container">
        <div className="mobile-page-header">
          Please sign in to access your new lead.
        </div>
        <LoginBox
          redirect={loginRedirectTo || '/mobile/dashboard'}
          notify={false}
          mobile
        />
        {showFooter
          ? (
            <div className="mobile-footer position-fixed">
              <div
                className="btn btn-secondary w-75"
                style={{ fontWeight: '600', fontSize: '18px', borderRadius: '8px' }}
                onClick={() => {
                  dispatch({ type: 'SET_MOBILE_DEVICE_REDIRECT', payload: true });
                  navigate('/');
                }}
                role="button"
                tabIndex={0}
              >
                View on desktop site
                <FontAwesomeIcon
                  style={{ color: 'white' }}
                  icon={faAngleRight}
                  className="fa-lg ms-2"
                />
              </div>
              <FontAwesomeIcon
                icon={faTimes}
                className="position-absolute fs-3"
                style={{ cursor: 'pointer', right: '8px', top: '4px' }}
                onClick={() => setShowFooter(false)}
              />
            </div>
          )
          : null}
      </div>
    </>
  );
}

function mapStateToProps(store) {
  return {
    agentUsername: store.mobile.agentUsername,
    loginRedirectTo: store.mobile.loginRedirectTo,
    shopperUsername: store.mobile.shopperUsername,
  };
}

export default connect(mapStateToProps)(withRouterProps(MobileLogin));
