import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { persistor } from '../../store';

function StaleClientModal() {
  const dispatch = useDispatch();
  const { staleClientModal } = useSelector(store => store.modal);

  function update() {
    dispatch({ type: 'SET_MODAL', payload: { staleClientModal: false } });
    persistor.flush().then(() => {
      dispatch({ type: 'UPDATE_LOCAL_VERSION' });
      window.location.reload(true);
    });
  }

  return staleClientModal
    ? (
      <div
        className={[
          'position-fixed d-flex d-print-none',
          'p-3 ps-4 pe-4',
          'bg-grey-200 shadow-sm border',
        ].join(' ')}
        style={{ right: '25px', bottom: '25px', zIndex: 9999 }}
      >
        <div
          className="d-flex"
          style={{ cursor: 'pointer' }}
          onClick={() => update()}
          tabIndex={0}
          role="button"
        >
          <div className="user-select-none">An update to the tool is available.</div>
          <div className="text-link ms-2">Update now</div>
        </div>
      </div>
    )
    : null;
}
export default connect()(StaleClientModal);
