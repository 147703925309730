import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

function CloseButton({ close, side = 'tr' }) {
  return (
    <Button
      size="lg"
      onClick={close}
      className="guide-close mt-3 ms-6"
      data-side={side}
    >
      Close
    </Button>
  );
}

const mapDispatchToProps = {
  close: () => ({ type: 'CLOSE_GUIDE' }),
};

export default connect(null, mapDispatchToProps)(CloseButton);
