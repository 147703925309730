import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';

function DatePicker({ startProp = 'stCallStartDate', endProp = 'stCallEndDate', id = '' }) {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { stCallStartDate, stCallEndDate } = useSelector(store => store.threeWay);

  function setSearchTerm(name, value) {
    dispatch({ type: 'SET_SEARCH_TERM', name, value });
  }
  useEffect(() => {
    document.addEventListener('click', (e) => {
      const datePickerEl = document.getElementById(id);
      const targetEl = e.target;
      if (datePickerEl && datePickerEl.contains(targetEl)) {
        // don't change the display to false since we're
        // inside of the date picker element
        return;
      }
      // we've gotten to an element outside of the date picker
      // so we should close the date picker
      setDisplay(false);
    });
  }, []);

  function handleSearchDatesChange({ startDate: sd, endDate: ed }) {
    if (sd) {
      setSearchTerm(startProp, moment(sd).startOf('day').toISOString());
    } else {
      setSearchTerm(startProp, null);
    }

    if (ed) {
      setSearchTerm(endProp, moment(ed).endOf('day').toISOString());
    } else {
      setSearchTerm(endProp, null);
    }
  }

  function renderDisplayDates(sDate, eDate) {
    // Fmtd = Formatted
    let startFmtd = 'Start Date';
    let endFmtd = 'End Date';
    if (sDate) {
      startFmtd = moment(sDate).format('MM/DD/YYYY');
    }
    if (eDate) {
      endFmtd = moment(eDate).format('MM/DD/YYYY');
    }
    return `${startFmtd} - ${endFmtd}`;
  }

  function handleClearClick(e) {
    e.preventDefault();
    handleSearchDatesChange({
      startDate: null,
      endDate: null,
    });
    setStartDate(new Date());
    setEndDate(new Date());
  }

  const datePickerRange = {
    startDate: stCallStartDate ? moment(stCallStartDate).toDate() : startDate,
    endDate: stCallEndDate ? moment(stCallEndDate).toDate() : endDate,
    key: 'selection',
  };

  return (
    <div id={id}>
      <div className="input-group">
        <input
          className="form-control"
          onFocus={() => setDisplay(true)}
          value={renderDisplayDates(stCallStartDate, stCallEndDate)}
          readOnly
        />
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={(e) => handleClearClick(e)}
          style={{
            borderBottomRightRadius: '5px',
            borderTopRightRadius: '5px',
            paddingLeft: '.8em',
            border: '1px solid #e57373',
            backgroundColor: 'white',
          }}
        >
          X
        </button>
      </div>
      <div
        className="record-date-picker-box"
        style={{
          display: display ? 'block' : 'none',
          background: '#fff',
          border: '2px solid var(--bs-light)',
          boxShadow: '0 5px 10px rgba(0,0,0,.251)',
          height: '387px',
          position: 'absolute',
          width: '893px',
          zIndex: '9999',
        }}
      >
        <DateRangePicker
          onChange={(d) => {
            handleSearchDatesChange(d.selection);
            setStartDate(d.selection.startDate);
            setEndDate(d.selection.endDate);
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[datePickerRange]}
          direction="horizontal"
        />
      </div>
    </div>
  );
}

export default DatePicker;
