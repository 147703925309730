import axios from 'axios';
import { store } from '../store';
import api from './api';

export function loadSoas(username) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      headers: { Authorization: `Token ${token}` },
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/soa/?shopper=${username}`,
    })
      .then((res) => {
        const { data } = res;
        // comment(joeysapp): We store SOAs by creation date, but we want to display
        // them by submission/completion date. If the SOA has not been completed,
        // we use its creation date for comparison.
        data.sort((a, b) => {
          const dateA = a.submit_date || a.created;
          const dateB = b.submit_date || b.created;
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
          return 0;
        });
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function sendSoa(data, method = 'email') {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      headers: { Authorization: `Token ${token}` },
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/send-soa/${method}/`,
      data,
    })
      .then((res) => {
        const { rdata } = res;
        resolve(rdata);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function submitExternalSoa(data) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      headers: { Authorization: `Token ${token}` },
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/send-soa/other/`,
      data,
    })
      .then((res) => {
        const { rdata } = res;
        resolve(rdata);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function submitVoiceSoa(data) {
  const { username } = store.getState().shopper;
  return api.post(
    '/agent/send-soa/other/',
    {
      shopper_username: username,
      other: 'voice',
      ...data,
    },
  );
}
