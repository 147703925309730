import React from 'react';
import { Spinner } from 'reactstrap';

class LoaderButton extends React.PureComponent {
  render() {
    const {
      className,
      color,
      disabled,
      id,
      loading,
      loadingColor,
      loadingText,
      onClick,
      // outline,
      size,
      style,
      tabIndex,
      text,
    } = this.props;

    const buttonClassName = `btn btn-${loadingColor || color}`;
    const loadingTextIsLarger = loadingText.length > text.length;
    const spinnerStyle = {
      verticalAlign: 'middle',
      marginTop: '-4px',
      marginRight: '4px',
      width: '1rem',
      height: '1rem',
      borderWidth: '0.175em',
    };
    const visibleStyle = {
      alignItems: 'center',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      top: '50%',
    };

    if (loading) {
      return (
        <button
          className={`${buttonClassName} position-relative ${className}`}
          color={buttonClassName}
          disabled
          id={id}
          // outline={outline.toString()}
          style={style}
          tabIndex={tabIndex}
          type="button"
        >
          <div
            style={{ visibility: 'hidden' }}
          >
            {loadingTextIsLarger
              ? (
                <div>
                  <Spinner
                    size={size}
                    style={spinnerStyle}
                  /> {loadingText}
                </div>
              )
              : text}
          </div>
          <div
            className="d-inline w-100 position-absolute"
            style={visibleStyle}
          >
            <Spinner
              size={size}
              style={spinnerStyle}
            /> {loadingText}
          </div>
        </button>
      );
    }
    return (
      <button
        className={`${buttonClassName} position-relative ${className}`}
        disabled={disabled}
        id={id}
        onClick={onClick}
        // outline={outline.toString()}
        style={style}
        tabIndex={tabIndex}
        type="button"
      >
        <div
          style={{ visibility: 'hidden' }}
        >
          {loadingTextIsLarger
            ? (
              <div>
                <Spinner
                  size={size}
                  style={{ marginRight: '4px' }}
                /> {loadingText}
              </div>
            )
            : text}
        </div>
        <div
          className="d-inline w-100 position-absolute"
          style={visibleStyle}
        >
          {text}
        </div>
      </button>
    );
  }
}

LoaderButton.defaultProps = {
  id: null,
  text: 'Do Something',
  loading: false,
  loadingText: 'Loading',
  loadingColor: null,
  onClick: () => {},
  color: 'primary',
  className: '',
  size: 'sm',
  style: {},
  tabIndex: 1,
  // outline: false,
};

export default LoaderButton;
