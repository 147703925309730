import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-regular-svg-icons';

// Components
import CompetitiveIntelligenceTable from './CompetitiveIntelligenceTable';

class CiDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      medigapTable: {
        isOpening: false,
        isVisible: true,
      },
      mapdTable: {
        isOpening: false,
        isVisible: true,
      },
      pdpTable: {
        isOpening: false,
        isVisible: true,
      },
    };

    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(e, headerId, isCurrentlyVisible, isCurrentlyOpening) {
    e.stopPropagation();
    /* Animate closing/opening our tables */
    if (isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isVisible: false,
        },
      });
    } else if (!isCurrentlyVisible && !isCurrentlyOpening) {
      this.setState({
        [headerId]: {
          isOpening: true,
          isVisible: true,
        },
      });
      window.setTimeout(() => {
        this.setState({
          [headerId]: {
            isVisible: !isCurrentlyVisible,
            isOpening: false,
          },
        });
      }, 250);
    }
  }

  render() {
    const {
      medigapTable,
      mapdTable,
      pdpTable,
    } = this.state;

    const {
      mapdCI,
      mapdCIavailable,
      medigapCI,
      medigapCIavailable,
      pdpCI,
      pdpCIavailable,
      selectedPharmacy,
      showMedigapTable,
    } = this.props;

    return (
      <div>
        {/* Medigap CI */}
        {showMedigapTable
          ? (
            <>
              <div
                className="plandetails-table-header"
                onClick={(e) => this.handleToggle(e, 'medigapTable', medigapTable.isVisible, medigapTable.isOpening)}
                role="button"
                tabIndex="0"
              >
                <div>
                  {(!selectedPharmacy || medigapCIavailable) && medigapCI.length !== 0
                    ? `${medigapCI[0].rows[0][1]} Medigap Strength`
                    : <span className="text-muted">Medigap not available in this county</span>}
                </div>
                <span className="plandetails-table-header-toggle">
                  <FontAwesomeIcon icon={medigapTable.isVisible ? faAngleUp : faAngleDown} />
                </span>
              </div>
              <CompetitiveIntelligenceTable
                planType="medigap"
                style={{
                  // Smooth transition for open/closing
                  maxHeight: medigapTable.isVisible ? '24em' : '0',
                  overflow: medigapTable.isVisible && !medigapTable.isOpening ? '' : 'hidden',
                }}
              />
            </>
          )
          : null}
        {/* MA / MAPD Ci */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'mapdTable', mapdTable.isVisible, mapdTable.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>
            {(!selectedPharmacy || mapdCIavailable) && mapdCI.length !== 0
              ? `${mapdCI[0].rows[0][1]} MAPD Strength`
              : <span className="text-muted">MAPD not available in this county</span>}
          </div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={mapdTable.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <CompetitiveIntelligenceTable
          planType="mapd"
          style={{
            maxHeight: mapdTable.isVisible ? '20em' : '0',
            overflow: mapdTable.isVisible && !mapdTable.isOpening ? '' : 'hidden',
          }}
        />
        {/* PDP Ci */}
        <div
          className="plandetails-table-header"
          onClick={(e) => this.handleToggle(e, 'pdpTable', pdpTable.isVisible, pdpTable.isOpening)}
          role="button"
          tabIndex="0"
        >
          <div>
            {(!selectedPharmacy || pdpCIavailable) && pdpCI.length !== 0
              ? `${pdpCI[0].rows[0][1]} PDP Strength`
              : <span className="text-muted">PDP not available in this county</span>}
          </div>
          <span className="plandetails-table-header-toggle">
            <FontAwesomeIcon icon={pdpTable.isVisible ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <CompetitiveIntelligenceTable
          planType="pdp"
          style={{
            maxHeight: pdpTable.isVisible ? '20em' : '0',
            overflow: pdpTable.isVisible && !pdpTable.isOpening ? '' : 'hidden',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    ciHeader: store.theme.ciHeader,
    mapdCI: store.ci.mapdCI,
    mapdCIavailable: store.ci.mapdCIavailable,
    medigapCI: store.ci.medigapCI,
    medigapCIavailable: store.ci.medigapCIavailable,
    modals: store.theme.modals,
    pdpCI: store.ci.pdpCI,
    pdpCIavailable: store.ci.pdpCIavailable,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    showMedigapTable: store.theme.features.medigap,
  };
}

export default connect(mapStateToProps)(CiDetail);
