import { combineReducers } from 'redux';
// import { reducer as form } from 'redux-form';
// import { reducer as modal } from 'redux-modal';
import addAgent from './agent_add';
import agent from './agent';
import agentManagement from './agent_management';
// eslint-disable-next-line import/no-cycle
import agentPermissions from './agent_permissions';
import benefits from './benefits';
import carriers from './carriers';
import ci from './ci';
import compare from './compare';
import display from './display';
import doctor from './doctor';
import drug from './drug';
import editAgent from './agent_edit';
import enrollment from './enrollment';
import error from './error';
import event from './event';
import guide from '../guide/reducer';
import intervals from './intervals';
import leadStatus from './lead_status';
import loaders from './loaders';
import login from './login';
import mobile from './mobile';
import modal from './modal';
import notifications from './notifications';
import pharmacy from './pharmacy';
import plan from './plan';
import popover from './popover';
import quote from './quote';
import session from './session';
import shopper from './shopper';
import shopperlist from './shopperlist';
import signup from './signup';
import theme from './theme';
import translations from './translations';
import telephony from './telephony';
import hra from './hra';
import call from './call';
import soa from './soa';
import threeWay from './three_way';
import appointments from './appointments';
import report from './report';

export default () => combineReducers({
  addAgent,
  agent,
  agentManagement,
  agentPermissions,
  appointments,
  benefits,
  call,
  carriers,
  ci,
  compare,
  display,
  doctor,
  drug,
  editAgent,
  enrollment,
  error,
  event,
  guide,
  intervals,
  leadStatus,
  loaders,
  login,
  mobile,
  modal,
  notifications,
  pharmacy,
  plan,
  popover,
  quote,
  report,
  session,
  shopper,
  shopperlist,
  signup,
  soa,
  telephony,
  theme,
  threeWay,
  translations,
  hra,
});
