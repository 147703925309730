/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-light-svg-icons';

// utils
import { toDateString } from '../../../../../common/utils/prettify';

class Summary extends React.PureComponent {
  pageForward = () => {
    const {
      callNotes,
      noteIndex,
      setNoteIndex,
    } = this.props;
    if (noteIndex < callNotes.length) {
      setNoteIndex(noteIndex + 1);
    }
  };

  pageBack = () => {
    const {
      noteIndex,
      setNoteIndex,
    } = this.props;
    if (noteIndex > 0) {
      setNoteIndex(noteIndex - 1);
    }
  };

  render() {
    const {
      callNotes,
      setView,
      noteIndex,
    } = this.props;
    const currentPath = location.pathname;
    let note = {};
    if (callNotes.length > 0) {
      note = callNotes[noteIndex];
    }
    return (
      <div className="col">
        {currentPath !== '/shopper' ? (
          <div
            className="d-flex navbar-notes-row mb-3 text-link font-decoration-underline"
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (currentPath !== '/shoppper') {
                setView('add');
              }
            }}
          >
            Add Note
            <div className="me-2 text-muted">
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        ) : null}
        { callNotes.length
          ? (
            <div className="navbar-notes-row">
              <div className="col p-1 whitespace-break">
                <div className="d-flex">
                  <div className="ms-0 me-auto text-muted fs-6">
                    Note #{callNotes.length - noteIndex}
                  </div>
                  <div className="me-0 ms-auto text-muted fs-6">
                    {toDateString(note.created, { longform: true })}
                  </div>
                </div>
                {(note.text || '').substring(0, 60).split('<br>').map(e => (<div key={e}>{e}<br /></div>))}
                {note.text && note.text.length > 60 ? '...' : ''}
                {
                  note.text && note.text.length > 60
                    ? (
                      <div
                        role="button"
                        tabIndex={-1}
                        className="navbar-note-details-link fst-italic mt-2"
                        onClick={() => setView('detail', noteIndex)}
                      >
                        See more...
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="text-muted">
                No call notes
              </div>
            </div>
          )}
        <div className="d-flex justify-content-center">
          <button
            type="button"
            disabled={noteIndex <= 0}
            className="btn btn-outline me-3 mt-3"
            onClick={() => this.pageBack()}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            disabled={noteIndex >= (callNotes.length - 1)}
            className="btn btn-outline mt-3"
            onClick={() => this.pageForward()}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    );
  }
}

Summary.defaultProps = {
  setView: () => {},
  noteIndex: 0,
};

function mapStateToProps(store) {
  return {
    callNotes: store.shopper.callNotes,
  };
}

export default connect(mapStateToProps)(Summary);
