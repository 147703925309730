/* eslint-disable */
export function calculateTravelZips({
  profile = {},
  canEditLocation,
  meetingType,
}) {
  const {
    // points = [],
    pointsByMeetingType: {
      [meetingType]: points = [],
    } = {},
    travelZipsByMeetingType: {
      [meetingType]: travelZips = [],
    } = {},
    zipEntryTypes: {
      [meetingType]: zipEntryType = 'map',
    } = {},
    manualZipsByMeetingType: {
      [meetingType]: manualZips,
    } = {},
  } = profile;

  if ((zipEntryType === 'map') && canEditLocation) {
    let includeZips = [];
    let excludeZips = [];
    (points || []).forEach(point => {
      if (point.include) includeZips = [ ...includeZips, ...(point.zips || []) ];
      if (!point.include) excludeZips = [ ...excludeZips, ...(point.zips || []) ];
    });
    return includeZips.filter(zip => !excludeZips.includes(zip));
  } else if ((zipEntryType === 'manual') && canEditLocation) {
    if (manualZips) return manualZips;
  }

  return travelZips;
}
