/* eslint-disable */
import React from 'react';

import './styles.scss';

function Spinner({
  size, // number of pixels for width + height
  color,
  className = '',
  weight = '0.3em',
}) {
  const classes = ['mmb-loading-spinner', className];
  const backgroundColor = `rgba(var(--bs-${(color || 'primaryFocus')}-rgb), 0.4)`;
  const highlightColor = `var(--bs-${(color || 'primaryFocus')})`;

  return (
    <div
      className={classes.join(' ')}
      style={{
        height: size ? `${size}px` : undefined,
        width: size ? `${size}px` : undefined,
        border: `${weight} solid ${backgroundColor}`,
        borderRightColor: highlightColor,
      }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default Spinner;
