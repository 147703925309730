import React, { useState } from 'react';
// import { SearchPlaceIndexForSuggestionsCommand } from '@aws-sdk/client-location';

// components
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';

function GeoPoint({
  point,
  locationClient,
  mapInstance,
  disabled,
  setReduxProfileKeys,
  reduxProfile,
  awsConfig,
  radiusOptions,
  meetingType,
}) {
  const {
    id: pointID,
    radius,
    include,
    disableUpdate,
  } = point;
  const [ searchResults, setSearchResults ] = useState([]);
  const [ searchString, setSearchString ] = useState('');
  const [ showSearchResults, setShowSearchResults ] = useState(false);
  const [ selectSignal, setSelectSignal ] = useState(false);
  const {
    pointsByMeetingType: {
      [meetingType]: points = [],
    } = {},
  } = reduxProfile;

  function updateSelf(updates) {
    const indexToReplace = points.findIndex(e => e.id === pointID);
    const updateCollection = [ ...points ];
    updateCollection.splice(indexToReplace, 1, { ...point, ...updates });
    setReduxProfileKeys({
      pointsByMeetingType: {
        ...(reduxProfile.pointsByMeetingType || {}),
        [meetingType]: updateCollection,
      },
    });
  }

  function updateRadius(e) {
    const { value } = e.target;
    updateSelf({ radius: Number(value) });
  }

  async function handleDelete() {
    if (mapInstance.getLayer(pointID)) await mapInstance.removeLayer(pointID);
    if (mapInstance.getSource(pointID)) await mapInstance.removeSource(pointID);
    const indexToDelete = points.findIndex(e => e.id === pointID);
    const updateCollection = [ ...points ];
    updateCollection.splice(indexToDelete, 1);
    setReduxProfileKeys({
      pointsByMeetingType: {
        ...(reduxProfile.pointsByMeetingType || {}),
        [meetingType]: updateCollection,
      },
    });
  }

  function handleTypeChange(e) {
    const { value } = e.target;
    if (value === 'include') updateSelf({ include: true });
    if (value === 'exclude') updateSelf({ include: false });
  }

  return (
    <>
      <div className="col-md-2">
        <select
          className={[
            'form-select',
            'bg-opacity-25',
            (include ? 'bg-success' : 'bg-danger'),
            (disabled ? 'text-muted' : ''),
          ].join(' ')}
          style={{ backgroundColor: include ? 'var(--bs-success)' : 'var(--bs-danger)' }}
          value={include ? 'include' : 'exclude'}
          onChange={handleTypeChange}
          disabled={disabled}
        >
          <option className="bg-light" value="include">Include</option>
          <option className="bg-light" value="exclude">Exclude</option>
        </select>
      </div>
      <div className="col-md-4">
        <div className="dropdown">
          <SearchInput
            setSearchResults={setSearchResults}
            locationClient={locationClient}
            searchString={searchString}
            setSearchString={setSearchString}
            setShowSearchResults={setShowSearchResults}
            setSelectSignal={setSelectSignal}
            selectSignal={selectSignal}
            disabled={disabled}
            disableUpdate={disableUpdate}
            awsConfig={awsConfig}
          />
          <SearchResults
            point={point}
            locationClient={locationClient}
            searchResults={searchResults}
            setSearchString={setSearchString}
            showSearchResults={showSearchResults}
            setShowSearchResults={setShowSearchResults}
            mapInstance={mapInstance}
            updateSelf={(e) => updateSelf(e)}
            setSelectSignal={setSelectSignal}
            selectSignal={selectSignal}
            awsConfig={awsConfig}
          />
        </div>
      </div>
      <div className="col-md-2">
        <select
          className={`form-select ${disabled && 'text-muted'}`}
          type="number"
          value={radius}
          onChange={updateRadius}
          disabled={disabled}
        >
          {radiusOptions.map(o => (
            <option key={o.value} value={o.value}>{o.label}</option>
          ))}
        </select>
      </div>
      <div className="col-md-1">
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={handleDelete}
          disabled={disabled}
        >
          Delete
        </button>
      </div>
    </>
  );
}

export default GeoPoint;
