import React from 'react';
import { connect } from 'react-redux';

class UserQuantity extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.updateUserQuantityFromProps = this.updateUserQuantityFromProps.bind(this);
  }

  componentDidMount() {
    // Set default user quantity on component mount, but
    //   only if we're not editing a drug. In the case of
    //   editing a drug userQuantity is set from the edit
    //   button.
    if (!this.props.editDrug) {
      this.updateUserQuantityFromProps();
    }
  }

  componentDidUpdate(prevProps) {
    // Update default userQuantity on dosage change
    if (this.props.selectedDosage !== prevProps.selectedDosage) {
      if (!this.props.editDrug) {
        this.updateUserQuantityFromProps();
      }
    }
  }

  handleChange(event) {
    const userQuantity = event.target.value;
    this.props.dispatch({ type: 'SET_USER_QUANTITY', payload: userQuantity });
  }

  updateUserQuantityFromProps() {
    // ---------------------------
    // Non oral drugs:
    //   1) Try to pull default quantity from package
    //   2) Try to pull default quantity from dosage
    //   3) Resort to setting default quantity to global default 1
    // ---------------------------
    let userQuantity;
    if (this.props.selectedDosage.ContainPackage) {
      if (this.props.selectedPackage.DefaultQuantity) {
        userQuantity = this.props.selectedPackage.DefaultQuantity;
      } else if (this.props.selectedDosage.DefaultUserQTY) {
        userQuantity = this.props.selectedDosage.DefaultUserQTY;
      } else {
        userQuantity = 1;
      }
    // ---------------------------
    // Oral drugs:
    //   1) Try to pull default quantity from dosage
    //   2) Resort to setting default quantity to global default 30
    // ---------------------------
    } else {
      if (this.props.selectedDosage.DefaultUserQTY) { // eslint-disable-line
        userQuantity = this.props.selectedDosage.DefaultUserQTY;
      } else {
        userQuantity = 30;
      }
    }
    this.props.dispatch({ type: 'SET_USER_QUANTITY', payload: userQuantity });
  }

  render() {
    return (
      <input
        id="drug-search-user-quantity-input"
        type="number"
        className="form-control h-100"
        value={this.props.userQuantity}
        onChange={this.handleChange}
      />
    );
  }
}

function mapStateToProps(store) {
  return {
    userQuantity: store.drug.userQuantity,
    selectedDosage: store.drug.selectedDosage,
    selectedPackage: store.drug.selectedPackage,
    editDrug: store.drug.editDrug,
  };
}

export default connect(mapStateToProps)(UserQuantity);
