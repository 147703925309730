/* eslint-disable no-param-reassign,no-return-assign */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CarrierFilter } from './components';

import { useOutsideClick } from '../../../common/hooks';


function PlanListHeader() {
  const dispatch = useDispatch();
  const planTypeRef = useRef();
  const onlineEnrollmentRef = useRef();
  const specialNeedRef = useRef();
  const {
    enrollCarriersFilter,
    planSort,
    snpFilters: {
      showSnp,
      showNonSnp,
    },
  } = useSelector(store => store.plan);
  const { planlistTableTab } = useSelector(store => store.display);
  const [ ptt, setPtt ] = useState(false);
  const [ oet, setOet ] = useState(false);
  const [ snt, setSnt ] = useState(false);

  useOutsideClick(planTypeRef, setPtt);
  useOutsideClick(onlineEnrollmentRef, setOet);
  useOutsideClick(specialNeedRef, setSnt);

  const options = [
    /*
    {
      title: 'Recommended',
      stateLabel: 'recommended',
    },
    */
    {
      title: 'MA',
      stateLabel: 'ma',
    },
    {
      title: 'MAPD',
      stateLabel: 'mapd',
    },
    {
      title: 'PDP',
      stateLabel: 'pdp',
    },
  ];

  const currentPlanFilter = options.filter((o) => o.stateLabel === planlistTableTab)[0].title;

  return (
    <div className="d-flex align-items-center w-100">
      <span className="me-4">Plans</span>
      <form className="d-flex align-items-center me-4 fs-5">
        <div
          className={`form-control position-relative user-select-none fw-bold text-primaryFocus ${ptt && 'open'}`}
          id="planlist-plan-type-panel"
          onClick={() => setPtt(!ptt)}
          ref={planTypeRef}
          role="button"
          tabIndex={-1}
          style={{ width: '150px' }}
        >
          {currentPlanFilter}
          <div className={`planlist-tooltip-panel fs-5 ${ptt && 'open'}`}>
            <span className="text-nbgrey fs-6" style={{ cursor: 'default' }}>
              PLAN TYPE
            </span>
            {options.map((tab) => {
              if (tab.disabled) {
                return null;
              }
              const currentView = tab.stateLabel.toLowerCase() === currentPlanFilter.toLowerCase();
              return (
                <span
                  className={`planlist-tooltip-panel-choice ${currentView ? 'selected' : ''} rts`}
                  onClick={() => {
                    setPtt(false);
                    dispatch({
                      type: 'SET_TABLE_TAB',
                      payload: {
                        key: 'planlistTableTab',
                        val: tab.stateLabel,
                      },
                    });
                  }}
                  role="button"
                  tabIndex={0}
                  key={`planfilter-${tab.stateLabel}`}
                  title={`View ${tab.stateLabel ? tab.stateLabel.toUpperCase() : ''} plans`}
                >
                  {tab.title}
                </span>
              );
            })}
          </div>
        </div>
        <i
          className={`text-primaryFocus pe-none fa ${ptt ? 'fa-chevron-up' : 'fa-chevron-down'}`}
          style={{ marginLeft: '-25px', zIndex: '6' }}
        />
      </form>
      {
        planlistTableTab !== 'recommended'
          && (
            <>
              {/* Carrier filter */}
              <form className="d-flex align-items-center user-select-none me-4">
                <CarrierFilter planType={currentPlanFilter} />
              </form>

              {/* Online enrollment available filter */}
              <form className="d-flex align-items-center me-4 fs-5">
                <div
                  className={`form-control position-relative fs-5 user-select-none ${oet && 'open'}`}
                  id="planlist-carrier-filter-panel"
                  onClick={(e) => {
                    // Handling clicking various checkboxes inside of the tooltip
                    const { current } = onlineEnrollmentRef;
                    if (current && (!current.contains(e.target) || current === e.target)) {
                      setOet(!oet);
                    }
                  }}
                  ref={onlineEnrollmentRef}
                  role="button"
                  tabIndex={-1}
                  style={{ width: '150px' }}
                >
                  Plan availability
                  <div className={`planlist-tooltip-panel ${oet && 'open'}`}>
                    <span className="text-nbgrey fs-6" style={{ cursor: 'default' }}>
                      FILTER BY AVAILABILITY
                    </span>
                    <span className="d-flex">
                      <input
                        className="form-control-input me-3"
                        onChange={() => dispatch({ type: 'TOGGLE_PLAN_AVAILABLE_ENROLLMENT' })}
                        id="planlist-online-enrollment-button"
                        type="checkbox"
                        checked={enrollCarriersFilter}
                      />
                      <label
                        className="form-control-label"
                        htmlFor="planlist-online-enrollment-button"
                        id="planlist-online-enrollment-button-label"
                      >
                        Show only plans available for online enrollment
                      </label>
                    </span>
                  </div>
                </div>
                <i
                  className={`fa ${oet ? 'fa-chevron-up' : 'fa-chevron-down'} pe-none`}
                  style={{ marginLeft: '-25px', zIndex: '6' }}
                />
              </form>
              {/* SNP toggling on MA/MAPD */}
              <form
                className="align-items-center me-5 fs-5"
                style={{
                  display: (planlistTableTab === 'ma' || planlistTableTab === 'mapd') ? 'flex' : 'none',
                }}
              >
                <div
                  className={`form-control position-relative user-select-none ${snt && 'open'}`}
                  id="planlist-carrier-filter-panel"
                  onClick={(e) => {
                    // Handling clicking various checkboxes inside of the tooltip
                    const { current } = specialNeedRef;
                    if (current && (!current.contains(e.target) || current === e.target)) {
                      setSnt(!snt);
                    }
                  }}
                  ref={specialNeedRef}
                  role="button"
                  tabIndex={-1}
                  style={{ width: '75px' }}
                >
                  SNP
                  <div className={`planlist-tooltip-panel ${snt && 'open'}`}>
                    <span className="text-nbgrey fs-6" style={{ cursor: 'default' }}>
                      FILTER BY PLAN FEATURE
                    </span>
                    <span className="d-flex align-items-center">
                      <input
                        className="form-control-input me-3"
                        onChange={() => dispatch({ type: 'SET_SNP_FILTER', payload: { showSnp: !showSnp } })}
                        id="planlist-snp-button"
                        type="checkbox"
                        checked={showSnp}
                      />
                      <label
                        className="form-control-label"
                        htmlFor="planlist-snp-button"
                        id="planlist-snp-button-label"
                      >
                        SNP
                      </label>
                    </span>
                    <span className="d-flex align-items-center">
                      <input
                        className="form-control-input me-3"
                        style={{ cursor: 'pointer' }}
                        onChange={() => dispatch({ type: 'SET_SNP_FILTER', payload: { showNonSnp: !showNonSnp } })
                          && (showNonSnp ? dispatch({ type: 'SET_CARRIER_LIST', payload: {} }) : null)}
                        id="planlist-non-snp-button"
                        type="checkbox"
                        checked={showNonSnp}
                      />
                      <label
                        className="form-control-label"
                        style={{ cursor: 'pointer' }}
                        htmlFor="planlist-non-snp-button"
                        id="planlist-non-snp-button-label"
                      >
                        Non-SNP
                      </label>
                    </span>
                  </div>
                </div>
                <i
                  className={`fa ${snt ? 'fa-chevron-up' : 'fa-chevron-down'} pe-none`}
                  style={{ marginLeft: '-25px', zIndex: '6' }}
                />
              </form>
              <div className="d-flex me-3 ms-auto">
                {/* Sorting */}
                <div className="d-flex align-items-center me-2 fw-normal fs-5 user-select-none">Sort by:</div>
                <form className="d-flex align-items-center fs-5 fw-bold">
                  <select
                    className="form-control fs-5"
                    id="planlist-list-sort"
                    name="sortPlans"
                    onChange={(e) => { dispatch({ type: 'SET_PLAN_SORT', payload: e.target.value }); }}
                    type="select"
                    value={planSort}
                    style={{ width: '250px', cursor: 'pointer' }}
                  >
                    <option value="totalAnnualOOPC">Total annual OOPC</option>
                    <option value="monthlyPremium">Monthly premium</option>
                    <option value="starRating">Star rating</option>
                    {
                      currentPlanFilter !== 'PDP'
                        ? <option value="doctorsCovered">Doctors in-network</option>
                        : null
                    }
                  </select>
                  <i
                    className="fa fa-chevron-down pe-none"
                    style={{ marginLeft: '-25px', zIndex: '6' }}
                  />
                </form>
              </div>
            </>
          )
      }
    </div>
  );
}

export default PlanListHeader;
