import React from 'react';
import { connect } from 'react-redux';

// Components
import TableDescription from './TableDescription';
import ColHeader from './ColHeader';
import PlanSummary from './PlanSummary';
import Footer from './Footer';

function PlanComparisonTable({
  plans,
  style,
  shopper,
  mapdMaSoaStatus,
  pdpSoaStatus,
}) {
  return (
    <div className="planlist-recommended" style={{ ...style }}>
      <div className="planlist-recommended-row">
        {/* Spacer */}
        <div className="planlist-recommended-col" />
        {
          /* Plan Headers */
          [...plans, {}, {}, {}].slice(0, 3).map((plan, idx) => {
            const doHighlight = plan.cpsID === shopper.currentPlan;
            const soaStatus = plan.planType === 'PDP' ? pdpSoaStatus : mapdMaSoaStatus;
            return (
              <div
                className={`planlist-recommended-col ${doHighlight && 'bg-highlight-bg'} ${soaStatus ? null : 'soa-filter'}`}
              >
                <ColHeader
                  plan={plan}
                  planLabel={plan.planType}
                  planTitle={plan.planName}
                  soaStatus={soaStatus}
                  id={idx}
                />
              </div>
            );
          })
        }
      </div>
      <div className="planlist-recommended-row">
        <div className="planlist-recommended-col" style={{ marginTop: '2px' }}>
          <TableDescription />
        </div>
        {
          /* Plan Information */
          [...plans, {}, {}, {}].slice(0, 3).map((plan) => {
            const doHighlight = plan.cpsID === shopper.currentPlan;
            return (
              <div
                className={`planlist-recommended-col ${doHighlight && 'bg-highlight-bg'} ${plan.soaStatus ? null : 'soa-filter'}`}
              >
                <PlanSummary
                  plan={plan}
                  planType={plan.planType}
                />
              </div>
            );
          })
        }
      </div>
      <div className="planlist-recommended-row">
        {/* Spacer */}
        <div className="planlist-recommended-col" />

        {
          /* Plan Footers */
          [...plans, {}, {}, {}].slice(0, 3).map((plan) => {
            const doHighlight = plan.cpsID === shopper.currentPlan;
            return (
              <div
                className={`planlist-recommended-col ${doHighlight && 'bg-highlight-bg'} ${plan.soaStatus ? null : 'soa-filter'}`}
              >
                <Footer
                  plan={plan}
                  planType={plan.planType}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  shopper: state.shopper,
});

export default connect(mapStateToProps)(PlanComparisonTable);
