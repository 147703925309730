/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { SUBSIDY_OPTIONS_DEFAULT } from '../../../../constants';

// components
import Select from './Select';

function SubsidyLevel({ disabled }) {
  const dispatch = useDispatch();
  const { subsidyQuestion, effectiveDate } = useSelector(store => store.shopper);
  const {
    subsidyLevelOptions = SUBSIDY_OPTIONS_DEFAULT,
  } = useSelector(store => store.theme);
  const effectiveDateYear = moment(effectiveDate).year();

  function handleChange(e) {
    const val = subsidyLevelOptions[effectiveDateYear].find(o => o.value === e.target.value);
    const data = {
      subsidyQuestion: val.value,
      subsidyPercent: val.percent,
      subsidyLevel: val.level,
    };
    dispatch({ type: 'UPDATE_SHOPPER', payload: data });
  }

  return (
    <Select
      name="subsidyQuestion"
      label="Does client get extra help from Medicare or their state to pay their Medicare prescription drug costs?"
      handleChange={handleChange}
      options={subsidyLevelOptions[effectiveDateYear]}
      cols="9"
      value={subsidyQuestion}
      disabled={disabled}
    />
  );
}

export default SubsidyLevel;
