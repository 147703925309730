import React from 'react';
import axios from 'axios';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../constants';
import { store } from '../store';
import api from './api';

// Actions
import { fmtQuoteToJS } from './quote';

// Utilities
// eslint-disable-next-line import/extensions
import { capitalize, formatPhoneToNum } from '../utils/general.jsx';

export function remapAPIShopper(apiShopper) {
  /* eslint-disable no-unreachable */
  // todo(@joeysapp): Being replaced by mmb-common-js, remove after testing
  // return fmtShopper(apiShopper, { all: true });

  const data = apiShopper;
  const { quotes } = apiShopper;
  const rfmQuotes = quotes && quotes.map((quote) => fmtQuoteToJS(quote));
  const rfmData = {
    agentWhoCreatedFirstName: apiShopper.user_who_created_first_name || '',
    agentWhoCreatedLastName: apiShopper.user_who_created_last_name || '',
    created: data.created || '',
    lastUpdated: data.last_updated || '',
    anocCode: data.anoc_code || '',
    dob: data.birthdate || '',
    gender: data.gender || '',
    county: data.county || '',
    countyName: data.county_name || '',
    firstName: data.first_name || '',
    middleName: data.middle_name || '',
    lastName: data.last_name || '',
    state: data.state.toUpperCase() || '',
    zipcode: data.zipcode || '',
    tobacco: data.smoker,
    email: data.contact_email || '',
    accountEmail: data.email || '',
    leadID: data.lead_id || '',
    leadType: data.lead_type || '',
    leadSource: data.lead_source || '',
    city: data.city || '',
    effectiveDate: data.effective_date || '',
    previousEffectiveDate: data.effective_date || '',
    subsidyPercent: data.subsidy_percent,
    subsidyLevel: data.subsidy_level,
    subsidyQuestion: data.subsidy_question || '',
    subsidyPercentageUnknown: data.subsidy_percentage_unknown,
    medicareID: data.medicare_id || '',
    eDateMonth: data.part_a_effective_month || '',
    eDateYear: data.part_a_effective_year || '',
    quickQuote: false,
    quotes: rfmQuotes,
    currentPlan: data.current_plan || '',
    recommendedPlan: data.recommended_plan || '',
    homePhone: data.home_phone ? formatPhoneToNum(data.home_phone) : '',
    mobilePhone: data.mobile_phone
      ? formatPhoneToNum(data.mobile_phone)
      : '',
    callPermission: data.permission_to_call,
    textPermission: data.permission_to_text,
    emailPermission: data.permission_to_email,
    planYear: data.plan_year || '',
    username: data.username || '',
    status: data.lead_status || '',
    isActive: data.current_lead,
    data: data.data,
    lastBEQTime: data.last_beq_time,
    hasShopperAccount: data.shopper_account,
    address: data.address,
    address2: data.address_2,
  };
  return rfmData;
}

function mapShoppersMetaData(shopperData, newLoad, showAllQuotes = false) {
  const returnedShopperData = shopperData.Users.map((shopper) => {
    const { quotes } = shopper;
    const rfmQuotes = quotes && quotes.map((quote) => fmtQuoteToJS(quote));

    // We need to map all a lead's given prior leads to digest the information
    const remappedInactiveLeads = [];
    shopper.inactive_leads.forEach((lead) => {
      const remappedLead = remapAPIShopper(lead);
      remappedInactiveLeads.push(remappedLead);
    });

    return {
      agentWhoCreatedFirstName: shopper.user_who_created_first_name,
      agentWhoCreatedLastName: shopper.user_who_created_last_name,
      callPermission: shopper.permission_to_call,
      city: shopper.city,
      dob: shopper.birthdate,
      email: shopper.email,
      emailPermission: shopper.permission_to_email,
      firstName: shopper.first_name,
      homePhone: shopper.home_phone,
      inactiveLeads: remappedInactiveLeads,
      leadID: shopper.lead_id,
      lastName: shopper.last_name,
      lastUpdated: shopper.last_updated,
      leadType: shopper.lead_type,
      mobilePhone: shopper.mobile_phone,
      planYear: shopper.plan_year,
      quotes: rfmQuotes,
      quotesExpanded: showAllQuotes,
      state: shopper.state.toUpperCase(),
      status: shopper.lead_status,
      textPermission: shopper.permission_to_text,
      username: shopper.username,
      zipcode: shopper.zipcode,
      isActive: shopper.current_lead,
      calls: shopper.shopper_calls || [],
    };
  });

  return {
    maxPageSize: shopperData.MaxPageSize,
    page: shopperData.Page,
    pageSize: shopperData.PageSize,
    totalPages: shopperData.TotalPages,
    totalUsers: shopperData.TotalUsers,
    users: returnedShopperData,
    newLoad,
  };
}



export function loadShoppers(params = {}) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    store.dispatch({ type: 'SET_SHOPPERS_LOADED_STATE', payload: AWAIT_STATE });
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/shopper/search/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
      params: {
        ...params,
        page: params.page,
      },
    })
      .then((res) => {
        const withMetaData = mapShoppersMetaData(
          res.data,
          params.new_load,
          params.show_all_quotes,
        );
        store.dispatch({ type: 'LOAD_SHOPPER_LIST', payload: withMetaData });
        store.dispatch({
          type: 'SET_SHOPPERS_LOADED_STATE',
          payload: SUCCESS_STATE,
        });
        resolve(withMetaData);
      })
      .catch((err) => {
        store.dispatch({
          type: 'SET_SHOPPERS_LOADED_STATE',
          payload: FAIL_STATE,
        });
        store.dispatch({ type: 'LOAD_SHOPPER_LIST_ERROR' });
        reject(err);
      });
  });
}

export function getShopperCount() {
  const { username } = store.getState().agent;
  return api.get(`/agent/shopper/counts/${username}/`).then((data) => {
    const payload = data.map((da) => ({
      name: da[0],
      count: da[1],
    }));
    store.dispatch({ type: 'SET_SHOPPER_CATEGORIES', payload });
  });
}

// returns a list of [DR_PLAN_IDs]
export function getPlansAvailable(countyFIPS) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/plans/coverage/${countyFIPS}/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function mapWeatherMetaData(weatherData) {
  return {
    icon: weatherData.weather[0].icon,
    description: weatherData.weather[0].description,
    temperature: weatherData.main.temp,
  };
}

export function getShopperWeather(zipcode) {
  return new Promise((resolve, reject) => {
    axios({
      url: `https://api.openweathermap.org/data/2.5/weather?zip=${zipcode},us&units=imperial&appid=${process.env.REACT_APP_WEATHER_KEY}`,
    })
      .then(({ data }) => {
        const withMetaData = mapWeatherMetaData(data);
        store.dispatch({ type: 'SET_SHOPPER_WEATHER', payload: withMetaData });
        resolve(withMetaData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function loadShopper(usernameOrLeadID, isLeadID) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/shopper/${usernameOrLeadID}/`,
      method: 'get',
      headers: { Authorization: `Token ${token}` },
      params: { leadid: isLeadID },
    }).then((res) => {
      const rfmData = remapAPIShopper(res.data);
      // getShopperWeather(rfmData.zipcode);
      resolve(rfmData);
    }).catch((err) => {
      reject(err);
    });
  });
}

export function getCounties(zipcode) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/geographics/zip/${zipcode}/`,
    })
      .then((res) => {
        if (res.data.length > 0) {
          const rfmData = res.data.map((c) => ({
            county: c.CountyFIPS,
            countyName: c.CountyName,
            // city: c.Cityname,
            state: c.State,
            genderRequired: c.GenderRequired,
            tobaccoRequired: c.SmokerRequired,
          }));
          resolve(rfmData);
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`No zipcodes found for ${zipcode}.`);
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`Error getting counties for zipcode ${zipcode}.`);
      });
  });
}

// eslint-disable-next-line no-unused-vars
export function createShopper(shopper, { updateShopperInfo = true } = {}) {
  const token = localStorage.getItem('SessionToken');
  const rfmData = {
    birthdate: shopper.dob || null,
    gender: shopper.gender,
    county: shopper.county,
    county_name: shopper.countyName,
    current_plan: shopper.currentPlan,
    first_name: shopper.firstName,
    middle_name: shopper.middleName,
    last_name: shopper.lastName,
    state: shopper.state,
    zipcode: shopper.zipcode,
    smoker: shopper.tobacco,
    lead_id: shopper.leadID,
    lead_type: 'Agent Initiated',
    lead_source: shopper.leadSource,
    city: shopper.city,
    effective_date: shopper.effectiveDate,
    subsidy_percent: shopper.subsidyPercent !== '' ? shopper.subsidyPercent : null,
    subsidy_level: shopper.subsidyLevel !== '' ? shopper.subsidyLevel : null,
    subsidy_question: shopper.subsidyQuestion !== '' ? shopper.subsidyQuestion : null,
    subsidy_percentage_unknown: shopper.subsidyPercentageUnknown,
    created_from_quickquote: shopper.quickQuote,
    home_phone: shopper.homePhone?.replace(/\(|\)|-| /g, '') || undefined,
    mobile_phone: shopper.mobilePhone?.replace(/\(|\)|-| /g, '') || undefined,
    permission_to_call: shopper.callPermission,
    permission_to_text: shopper.textPermission,
    permission_to_email: shopper.emailPermission,
    contact_email: shopper.email || undefined,
    plan_year: shopper.planYear ? Number(shopper.planYear) : undefined,
    lead_status: 10,
    last_beq_time: shopper.lastBEQTime || undefined,
    medicare_id: shopper.medicareID || undefined,
    data: shopper.data || undefined,
  };

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/shopper/`,
      headers: { Authorization: `Token ${token}` },
      data: rfmData,
    })
      .then((res) => {
        const returnShopper = remapAPIShopper(res.data);
        if (updateShopperInfo) store.dispatch({ type: 'UPDATE_SHOPPER', payload: returnShopper }); resolve(returnShopper);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        } else if (err.response.data && typeof err.response.data === 'object') {
          // Response of type { field1: err, field2: err, ... }
          const errorMessages = [];
          const errCount = Object.keys(err.response.data).length - 1;
          Object.keys(err.response.data).forEach((fieldName, idx) => {
            // errorMessages.push(`${fieldName}: ${err.response.data[fieldName]}`);
            errorMessages.push(
              // eslint-disable-next-line react/jsx-filename-extension
              <div
                className={idx < errCount ? 'mb-4' : 'mb-2'}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
              >
                <div className="fw-bold h6">
                  {capitalize(fieldName.replace('_', ' '))}
                </div>
                {err.response.data[fieldName]}
              </div>,
            );
          });
          reject(errorMessages);
        }
        reject(err);
      });
  });
}

export function createBaseShopper({ firstName, lastName }) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/shopper/?short=true`,
      headers: { Authorization: `Token ${token}` },
      data: { first_name: firstName, last_name: lastName },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

export function updateShopper(shopper, {
  mobile = false,
  updateShoperInfo = true,
} = {}) {
  const token = localStorage.getItem('SessionToken');
  const rfmData = {
    birthdate: shopper.dob || null,
    gender: shopper.gender || null,
    county: shopper.county,
    county_name: shopper.countyName,
    first_name: shopper.firstName,
    middle_name: shopper.middleName,
    last_name: shopper.lastName,
    state: shopper.state,
    zipcode: shopper.zipcode,
    smoker: shopper.tobacco,
    lead_id: shopper.leadID,
    lead_source: shopper.leadSource,
    city: shopper.city,
    effective_date: shopper.effectiveDate || undefined,
    subsidy_percent: shopper.subsidyPercent || undefined,
    subsidy_level: shopper.subsidyLevel || undefined,
    subsidy_question: shopper.subsidyQuestion || undefined,
    subsidy_percentage_unknown: shopper.subsidyPercentageUnknown,
    current_plan: shopper.currentPlan,
    home_phone: shopper.homePhone?.replace(/\(|\)|-| /g, ''),
    mobile_phone: shopper.mobilePhone?.replace(/\(|\)|-| /g, ''),
    permission_to_call: shopper.callPermission,
    permission_to_text: shopper.textPermission,
    permission_to_email: shopper.emailPermission,
    contact_email: shopper.email || '',
    plan_year: shopper.planYear ? Number(shopper.planYear) : undefined,
    lead_status: shopper.status ? Number(shopper.status) : undefined,
    last_beq_time: shopper.lastBEQTime || undefined,
    medicare_id: shopper.medicareID || undefined,
    data: shopper.data || undefined,
    address: shopper.address || undefined,
    address_2: shopper.address2 || undefined,
  };
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/shopper/${shopper.username}/`,
      method: 'put',
      headers: { Authorization: `Token ${token}` },
      params: { mobile: !!mobile },
      data: rfmData,
    })
      .then((res) => {
        const updateData = remapAPIShopper(res.data);
        if (updateShoperInfo) {
          store.dispatch({ type: 'UPDATE_SHOPPER', payload: updateData });
        }
        resolve(updateData);
      })
      .catch((err) => {
        if (err.response && err.response.status === 409) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Whoops! This email is already associated with another account');
        } else if (err.response && err.response.data && err.response.data.error) {
          reject(err.response.data.error);
        } else if (err.response.data && typeof err.response.data === 'object') {
          const errorMessages = [];
          const errCount = Object.keys(err.response.data).length - 1;

          Object.keys(err.response.data).forEach((fieldName, idx) => {
            // errorMessages.push(`${fieldName}: ${err.response.data[fieldName]}`);
            errorMessages.push(
              <div
                className={idx < errCount ? 'mb-4' : 'mb-2'}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
              >
                <div className="fw-bold h6">
                  {capitalize(fieldName.replace('_', ' '))}
                </div>
                {err.response.data[fieldName]}
              </div>,
            );
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(errorMessages);
        }
        reject(err);
      });
  });
}

export function updateEffectDate(username, effective_date) {
  return api.put(`/agent/shopper/${username}/`, { effective_date }).then(() => {
    store.dispatch({ type: 'SET_SHOPPER', payload: { effectiveDate: effective_date } });
  });
}

export function getCities(zipcode) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/geographics/city/${zipcode}/`,
    })
      .then((res) => {
        if (res.data.length > 0) {
          const rfmData = res.data.map((c) => ({
            zip: c.Zip,
            county: c.CountyFIPS,
            countyName: c.CountyName,
            city: c.CityName,
            state: c.State,
            // latitude: c.Latitude,
            // longitude: c.Longitude,
            genderRequired: c.GenderRequired,
            tobaccoRequired: c.SmokerRequired,
          }));
          resolve(rfmData);
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`No cities found for ${zipcode}.`);
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(`Error getting cities for zipcode ${zipcode}.`);
      });
  });
}

function mapCallNotesData(callNotesData) {
  const returnedCallNotesData = callNotesData.map((callNote) => ({
    id: callNote.id,
    shopper: callNote.shopper,
    text: callNote.text,
    created: callNote.created,
    agent: callNote.agent ? callNote.agent : {},
    type: callNote.note_type,
  }));
  return returnedCallNotesData;
}

export function loadCallNotes(shopperUsername) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/note/`,
      method: 'get',
      params: { shopper: shopperUsername },
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        const withMetaData = mapCallNotesData(res.data);
        resolve(withMetaData);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function addCallNote(shopperUsername, text) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/note/`,
      method: 'post',
      headers: { Authorization: `Token ${token}` },
      params: { shopper: shopperUsername },
      data: { text },
    })
      .then((res) => {
        loadCallNotes(shopperUsername)
          .then(() => {
            resolve(res);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function getRequiredFieldsFromState(state) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/geographics/state/${state}/`,
      method: 'get',
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateEligibilityScrapeWithShopper(username, scrapeID) {
  const token = localStorage.getItem('SessionToken');

  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/subsidy-info/`,
      data: {
        username,
        id: scrapeID,
      },
      headers: {
        Authorization: `Token ${token}`,
      },
    }).then(() => {
    }).catch((err) => {
      reject(err);
    });
  });
}

export function loadShopperRecommendation(username) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem('SessionToken');
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/recommendation/`,
      headers: { Authorization: `Token ${token}` },
      params: { shopper: username },
      method: 'GET',
    }).then((res) => {
      let currentContractID = '';
      if (res.data.mapd_cid) {
        currentContractID = res.data.mapd_cid;
      } else if (res.data.pdp_cid) {
        currentContractID = res.data.pdp_cid;
      }

      const rfmData = {
        npi: res.data.pharmacy_id,
        recContractID: res.data.recommended_cid,
        curContractID: currentContractID,
        nbContractID: res.data.next_best_cid,
        year: res.data.year,
        letterMailedDate: res.data.letter_mailed_date,
        letterLink: res.data.letter_link,
        effectiveDate: res.data.effective_date,
      };
      resolve(rfmData);
    }).catch((err) => {
      reject(err.response.data.error);
    });
  });
}

function rfmBEQData(data) {
  const modelValues = {
    city: data.city,
    county: data.county,
    dob: data.dob,
    firstName: data.first_name,
    gender: data.gender.toLowerCase(),
    lastName: data.last_name,
    subsidyLevel: String(data.lis),
    medicareID: data.medicare_id,
    middleName: data.middle_initial,
    subsidyPercent: String(data.subsidy_percent),
    zipcode: data.zipcode,
    state: data.state,
  };

  // we store all of the beq values in the data.beq key
  // so that if the model values change (like name, zip, etc)
  // we have recorded the info that came down from the BEQ
  // API unmodified.
  return {
    ...modelValues,
    data: {
      beq: {
        ...modelValues,
        partAEffectiveDate: data.part_a_effective_date,
        partBEffectiveDate: data.part_b_effective_date,
        address: data.address,
      },
    },
  };
}

export function getBEQData({ mbi, dob, gender }) {
  const token = window.localStorage.getItem('SessionToken');

  const sendData = {
    contractNumber: 'H5521',
    beneficiary: {
      id: mbi,
      dob,
      gender: gender.toUpperCase(),
    },
  };

  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/beq/`,
    headers: { Authorization: `Token ${token}` },
    data: sendData,
  }).then(({ data }) => {
    if (data.hu_return_data) return rfmBEQData(data.hu_return_data);
    throw new Error('No beneficiary information found');
  });
}
