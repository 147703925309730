import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// actions + utils
import { loadConfig } from '../../../actions/config';
import { submitVoiceSoa, loadSoas } from '../../../actions/soa';
import { showPopup } from '../../../utils/popup';

// components
import { LoaderButton, Spinner } from '../../../common/components';

// tmp
import DEFAULT_CONFIG from './DEFAULT_VSOA_FORM';

// -------------------
//     utils
// -------------------
//
function getAllFields(formConfig = []) {
  const fields = [];
  formConfig.forEach(section => {
    (section.fields || []).forEach(field => fields.push(field));
  });
  return fields;
}

export function isFieldValid({ validationRegex, value }) {
  const re = new RegExp(validationRegex);
  let testVal = '';
  if (value !== undefined) {
    testVal = value;
  }
  return re.test(testVal);
}

function injectVars(text, context = {}) {
  let updatedText = text;
  Object.entries(context).forEach(([ key, val ]) => {
    const textToReplace = `{${key}}`;
    const replaceWith = val || `[${key}]`;
    updatedText = updatedText.replaceAll(textToReplace, replaceWith);
  });
  return updatedText;
}

// ----------------- end utils -------------------

function RequiredStar({ required }) {
  return required ? <span className="text-danger fw-bold ms-1">*</span> : null;
}

function TextInput({ field }) {
  const dispatch = useDispatch();
  const {
    vsoaValues: values = {},
    vsoaValid = {},
  } = useSelector(store => store.soa);
  const valid = vsoaValid[field.name];
  let validClass = '';
  if (valid === true && !field.disabled && field.required) validClass = 'is-valid';
  if (valid === false && !field.disabled && field.required) validClass = 'is-invalid';

  function handleChange(e) {
    const { value } = e.target;
    dispatch({ type: 'SET_VSOA_VALUES', fields: { [field.name]: value } });
  }

  return (
    <div className="col-md-6 mb-4">
      <label
        className="form-label"
        htmlFor={field.name}
      >
        {field.label}
        <RequiredStar required={field.required} />
      </label>
      <input
        id={field.name}
        type={field.type}
        className={`form-control ${validClass}`}
        onChange={handleChange}
        value={values[field.name]}
        disabled={field.disabled}
        onBlur={() => {
          const isValid = isFieldValid({
            validationRegex: field.validationRegex,
            value: values[field.name],
          });
          dispatch({ type: 'SET_VSOA_VALID', fields: { [field.name]: isValid } });
        }}
      />
    </div>
  );
}

function CheckInput({ field }) {
  const dispatch = useDispatch();
  const { vsoaValues: values = {} } = useSelector(store => store.soa);

  function handleClick() {
    const value = !values[field.name];
    const isValid = isFieldValid({
      validationRegex: field.validationRegex,
      value,
    });
    dispatch({ type: 'SET_VSOA_VALUES', fields: { [field.name]: value } });
    dispatch({ type: 'SET_VSOA_VALID', fields: { [field.name]: isValid } });
  }

  return (
    <div className="col-md-12">
      <div
        role="button"
        tabIndex={0}
        className="form-check"
      >
        <input
          id={field.name}
          type={field.type}
          className="form-check-input"
          onChange={handleClick}
          checked={values[field.name]}
        />
        <label
          className="form-check-label"
          htmlFor={field.name}
        >
          {field.label}
          <RequiredStar required={field.required} />
        </label>
      </div>
    </div>
  );
}

function ScriptSection({ section = {} }) {
  const {
    soa: {
      vsoaValues: {
        first_name: shopperFirstName,
        last_name: shopperLastName,
        submit_date: today,
      },
      values: {
        date_of_appointment,
      },
    },
    agent: {
      firstName: agentFirstName,
      lastName: agentLastName,
      mobilePhone,
      homePhone,
    },
  } = useSelector(store => store);

  const { text = [], fields = [] } = section;

  const context = {
    todays_date: moment(today).format('MMMM Do YYYY'),
    date_of_appointment: moment(date_of_appointment).format('YYYY MM DD'),
    agent_name: `${agentFirstName} ${agentLastName}`,
    shopper_name: `${shopperFirstName} ${shopperLastName}`,
    agent_phone: mobilePhone || homePhone || undefined,
  };

  return (
    <div>
      <div className="row alert alert-info text-black px-4">
        {text.map((s, i) => {
          // set 0 margin on the bottom of only the last <p> in the array
          if ((i + 1) === text.length) return (<p key={s} className="mb-0">{injectVars(s, context)}</p>);
          return (<p key={s}>{injectVars(s, context)}</p>);
        })}
      </div>
      <div className="row ps-4 mb-4">
        <div className="col-md-12 mb-3">
          <em>{section.instructions}</em>
        </div>
        {fields.map(field => ((field.type === 'checkbox')
          ? <CheckInput key={field.name} field={field} />
          : <TextInput key={field.name} field={field} />
        ))}
      </div>
    </div>
  );
}

function SubmitButton({ fields }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
  const [ errMsg, setErrMsg ] = useState('');
  const { vsoaValid, vsoaValues } = useSelector(store => store.soa);
  const { contactId } = useSelector(store => store.telephony);
  const { username: shopperUsername } = useSelector(store => store.shopper);

  const allFieldsValid = fields.every(field => {
    if (!field.required) return true;
    if (vsoaValid[field.name] === true) return true;
    return false;
  });

  async function handleSubmit() {
    setLoading(true);
    setErrMsg('');
    try {
      await submitVoiceSoa({
        contactId,
        ok_contact: vsoaValues.ok_contact,
        ok_record: vsoaValues.ok_record,
        submit_date: vsoaValues.submit_date || (new Date()),
        date_of_appointment: vsoaValues.date_of_appointment,
        name: `${vsoaValues.first_name} ${vsoaValues.last_name}`,
        phone: vsoaValues.phone || undefined,
        email: vsoaValues.email || undefined,
        pdp: true,
        mapd_ma: true,
        dvh: true,
        sup_health: true,
        medigap: true,
      });
      const data = await loadSoas(shopperUsername);
      dispatch({ type: 'SET_SHOPPER_SOAS', payload: data });
    } catch (err) {
      console.log(err);
      setErrMsg('There was a problem submitting the VSOA');
      showPopup('Error', 'Error submitting VSOA');
    }
    setLoading(false);
    showPopup('Success', 'Successfully submitted VSOA!');
    navigate('/rx');
  }

  return (
    <div>
      <LoaderButton
        className="btn btn-primary"
        text="Submit"
        loadingText="Submitting"
        onClick={() => handleSubmit()}
        loading={loading}
        disabled={!allFieldsValid}
      />
      {!allFieldsValid && (
        <p className="text-danger mt-2">All required fields must be filled</p>
      )}
      {errMsg && (
        <p className="text-danger mt-2">{errMsg}</p>
      )}
    </div>
  );
}

function VoiceSOA() {
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const { vsoaConfig: { form = [] } = {} } = useSelector(store => store.soa);
  const { firstName, lastName, mobilePhone, homePhone, email } = useSelector(store => store.shopper);
  const fields = getAllFields(form);

  useEffect(() => {
    // load in the config for the voice soa script
    setLoading(true);
    dispatch({ type: 'CLEAR_VSOA' });
    loadConfig('voice_soa_config')
      .then((res = {}) => {
        const { data: { value } = {} } = res;
        const config = value ? JSON.parse(value) : DEFAULT_CONFIG;
        const configFields = getAllFields(config.form || []);
        dispatch({ type: 'SET_VSOA_CONFIG', vsoaConfig: config });

        // set initial values from shopper info
        const initialValues = {
          submit_date: moment().format('YYYY-MM-DD'),
          first_name: firstName,
          last_name: lastName,
          phone: (mobilePhone || homePhone || undefined),
          email,
        };
        dispatch({ type: 'SET_VSOA_VALUES', fields: initialValues });

        // run validation on initial values
        Object.entries(initialValues).forEach(([ key, val ]) => {
          if (val !== undefined && val !== '') {
            const field = configFields.find(f => f.name === key) || {};
            const isValid = isFieldValid({ validationRegex: field.validationRegex, value: val });
            dispatch({ type: 'SET_VSOA_VALID', fields: { [field.name]: isValid } });
          }
        });
      }).catch(err => {
        console.log(err);
      }).finally(() => setLoading(false));
  }, []);

  return !loading ? (
    <div>
      {form.map(section => (
        <ScriptSection key={section.key} section={section} />
      ))}
      <SubmitButton fields={fields} />
    </div>
  ) : (
    <div className="row justify-content-center pt-5">
      <Spinner weight="0.4em" size="50" />
    </div>
  );
}

export default VoiceSOA;
