/* eslint-disable quotes */
export const DEFAULT_STATE = 'DEFAULT_STATE';
export const AWAIT_STATE = 'AWAIT_STATE';
export const FAIL_STATE = 'FAIL_STATE';
export const SUCCESS_STATE = 'SUCCESS_STATE';

export const ZIP_SEARCH = 1;
export const NAME_SEARCH = 2;
export const DETAIL = 3;

export const DOCTOR_PLAN_YEAR = 2019;

export const MEDICAL_TYPE_FILTERS = [
  'UNKNOWN',
  'HMO',
  'PPO',
  'EPO',
  'POS',
  'HDHP',
  'HMO_With_POS',
  'PRIVATE_FEE_FOR_SERVICE',
  'COST_PLAN',
  'NATIONAL_PACE',
  'MEDICARE_MEDICAID_PLAN',
  'MEDICAL_SAVINGS_ACCOUNT',
  'HMOPOS',
  'RPPO',
  'LPPO',
  'HMO_SNP',
  'HMO_WITH_POS_SNP',
  'Local_Preferred_Provider_Organization',
  'Local_Preferred_Provider_Organization_SNP',
  'PDP',
  'PREFERRED_PROVIDER_ORGANIZATION',
  'PREFERRED_PROVIDER_ORGANIZATION_SNP',
  'PFFS',
];

export const STATUS_OPTIONS = [
  { val: 10, desc: 'New' },
  { val: 11, desc: "1st Attempt (Smile, it's a new prospect)" },
  { val: 12, desc: "2nd Attempt (Within 24 hours of 1st call)" },
  { val: 13, desc: "3rd Attempt (Within 24 hours of 2nd call)" },
  { val: 14, desc: "4th Attempt (Within 6 days of 1st call)" },
  { val: 15, desc: "5th Attempt (Within 10 days of 1st call)" },
  { val: 20, desc: 'Follow Up – Deliver Sales Materials' },
  { val: 21, desc: 'Follow Up – Conduct Sales Presentation' },
  { val: 22, desc: 'Follow Up – Reassign to Multilingual Agent' },
  { val: 23, desc: 'Follow Up – Other' },
  { val: 30, desc: 'Sale - Humana via MyMedicareBot' },
  { val: 31, desc: 'Sale - Humana via Other Method' },
  { val: 32, desc: 'Sale - UHC' },
  { val: 33, desc: 'Sale - Aetna' },
  { val: 34, desc: 'Sale - Other Carrier' },
  // { val: 40, desc: 'Other Product Sale Completed' }, // deprecated 12/02/2020
  { val: 50, desc: 'Not Interested - Presentation Completed' },
  { val: 51, desc: 'Not Interested - No Presentation Completed' },
  { val: 52, desc: 'Not Interested - Other' },
  { val: 92, desc: 'Not Eligible' },
  { val: 90, desc: 'Unable to Contact - No Response' },
  { val: 91, desc: 'Unable to Contact - Incorrect Contact Information' },
];

export const STATE_MAPPING = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const SUBSIDY_OPTIONS_TEMPLATE = ['DEPRECATED'];
export const PERCENTAGE_OPTIONS = ['DEPRECATED'];

export const SUBSIDY_OPTIONS_DEFAULT = {
  2021: [
    {
      value: "no_extra_help",
      desc: "L4 - Client doesn't get any extra help",
      percent: "0",
      level: "4",
    },
    {
      value: "medicaid",
      desc: "L1 - Client gets help from Medicaid",
      percent: "100",
      level: "1",
    },
    {
      value: "supplemental_security_income",
      desc: "L1 - Client gets supplemental security income",
      percent: "100",
      level: "1",
    },
    {
      value: "medicare",
      desc: "L5 - Client belongs to Medicare Savings Program (MSP)",
      percent: "100",
      level: "5",
    },
    {
      value: "social_security",
      desc: "L2 - Client applied for and got extra help through social security and pays $3.70 - $9.20 for covered drugs",
      percent: "100",
      level: "2",
    },
    {
      value: "coinsurance",
      desc: "L4 - Client applied for and got extra help through social security and pays 15% coinsurance for covered drugs",
      percent: "100",
      level: "3",
    },
    {
      value: "does_not_know",
      desc: "L4 - Client doesn't know",
      percent: "0",
      level: "4",
    },
  ],
  2022: [
    {
      value: "no_extra_help",
      desc: "L4 - Client doesn't get any extra help",
      percent: "0",
      level: "4",
    },
    {
      value: "medicaid",
      desc: "L1 - Client gets help from Medicaid",
      percent: "100",
      level: "1",
    },
    {
      value: "supplemental_security_income",
      desc: "L1 - Client gets supplemental security income",
      percent: "100",
      level: "1",
    },
    {
      value: "medicare",
      desc: "L5 - Client belongs to Medicare Savings Program (MSP)",
      percent: "100",
      level: "5",
    },
    {
      value: "social_security",
      desc: "L2 - Client applied for and got extra help through social security and pays $3.95 - $9.85 for covered drugs",
      percent: "100",
      level: "2",
    },
    {
      value: "coinsurance",
      desc: "L4 - Client applied for and got extra help through social security and pays 15% coinsurance for covered drugs",
      percent: "100",
      level: "3",
    },
    {
      value: "does_not_know",
      desc: "L4 - Client doesn't know",
      percent: "0",
      level: "4",
    },
  ],
  2023: [
    {
      value: "no_extra_help",
      desc: "L4 - Client doesn't get any extra help",
      percent: "0",
      level: "4",
    },
    {
      value: "medicaid",
      desc: "L1 - Client gets help from Medicaid",
      percent: "100",
      level: "1",
    },
    {
      value: "supplemental_security_income",
      desc: "L1 - Client gets supplemental security income",
      percent: "100",
      level: "1",
    },
    {
      value: "medicare",
      desc: "L5 - Client belongs to Medicare Savings Program (MSP)",
      percent: "100",
      level: "5",
    },
    {
      value: "social_security",
      desc: "L2 - Client applied for and got extra help through social security and pays $4.15 - $10.35 for covered drugs",
      percent: "100",
      level: "2",
    },
    {
      value: "coinsurance",
      desc: "L4 - Client applied for and got extra help through social security and pays 15% coinsurance for covered drugs",
      percent: "100",
      level: "3",
    },
    {
      value: "does_not_know",
      desc: "L4 - Client doesn't know",
      percent: "0",
      level: "4",
    },
  ],
  2024: [
    {
      value: "no_extra_help",
      desc: "L4 - Client doesn't get any extra help",
      percent: "0",
      level: "4",
    },
    {
      value: "medicaid",
      desc: "L1 - Client gets help from Medicaid",
      percent: "100",
      level: "1",
    },
    {
      value: "supplemental_security_income",
      desc: "L1 - Client gets supplemental security income",
      percent: "100",
      level: "1",
    },
    {
      value: "medicare",
      desc: "L5 - Client belongs to Medicare Savings Program (MSP)",
      percent: "100",
      level: "5",
    },
    {
      value: "social_security",
      desc: "L2 - Client applied for and got extra help through social security and pays $4.50 - $11.20 for covered drugs",
      percent: "100",
      level: "2",
    },
    {
      value: "does_not_know",
      desc: "L4 - Client doesn't know",
      percent: "0",
      level: "4",
    },
  ],
  2025: [
    {
      value: "no_extra_help",
      desc: "L4 - Client doesn't get any extra help",
      percent: "0",
      level: "4",
    },
    {
      value: "medicaid",
      desc: "L1 - Client gets help from Medicaid",
      percent: "100",
      level: "1",
    },
    {
      value: "supplemental_security_income",
      desc: "L1 - Client gets supplemental security income",
      percent: "100",
      level: "1",
    },
    {
      value: "medicare",
      desc: "L5 - Client belongs to Medicare Savings Program (MSP)",
      percent: "100",
      level: "5",
    },
    {
      value: "social_security",
      desc: "L2 - Client applied for and got extra help through social security and pays $4.90 - $12.15 for covered drugs",
      percent: "100",
      level: "2",
    },
    {
      value: "does_not_know",
      desc: "L4 - Client doesn't know",
      percent: "0",
      level: "4",
    },
  ],
};

export const SUBSIDY_PERCENTAGE_OPTIONS_DEFAULT = [
  { value: '100', desc: '0%' },
  { value: '75', desc: '25%' },
  { value: '50', desc: '50%' },
  { value: '25', desc: '75%' },
  { value: '101', desc: `Don't know` },
];
