import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FormInput from '../../common/FormInput';
import FormSelect from '../../common/FormSelect';
import FormCheckbox from '../../common/FormCheckbox';

// SOA checkbox items
const soaCheckItems = [
  { name: 'mapdMa', label: 'MA/MAPD' },
  { name: 'pdp', label: 'PDP' },
  { name: 'dvh', label: 'Dental / vision / hearing products' },
  { name: 'supHealth', label: 'Supplemental health products' },
  { name: 'medigap', label: 'Medicare Supplement' },
];

function ExternalInputs({
  handleChange,
  handleTodayClick,
  handleCheckbox,
  handleBlur,
  errors,
  values,
  sm = 1,
  md = 1,
  lg = 1,
}) {
  const { externalSOAOptions } = useSelector(store => store.theme);
  let options = [
    { val: 'Paper form', label: 'Paper form' },
    { val: 'Business reply card', label: 'Business reply card' },
    { val: 'other', label: 'Other..' },
  ];
  if (Array.isArray(externalSOAOptions) && externalSOAOptions.length) options = externalSOAOptions;

  return (
    <div className="ps-4">
      <Row xs="1" sm={sm} md={md} lg={lg}>
        <Col>
          <FormSelect
            controlId="soaType"
            label="SOA type"
            name="soaType"
            placeholder="Select a SOA type"
            options={options}
            value={values.soaType}
            onChange={handleChange}
            onBlur={handleBlur}
            invalid={!!errors.soaType}
            error={errors.soaType}
            required
          />
        </Col>
        {
          values.soaType === 'other' && (
          <Col>
            <FormInput
              controlId="shopper-other-description"
              label="Other description"
              placeholder="description"
              value={values.otherDescription}
              name="otherDescription"
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={!!errors.otherDescription}
              error={errors.otherDescription}
              required
            />
          </Col>
          )
        }
        <Col className="d-flex align-items-end">
          <FormInput
            controlId="shopper-soa-date"
            label="Date signed"
            className="w-100"
            value={values.soaDate}
            name="soaDate"
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
            invalid={!!errors.soaDate}
            error={errors.soaDate}
            required
            max={moment().format('YYYY-MM-DD')}
          />
          <button
            type="button"
            className="btn btn-link ms-3"
            style={{ marginBottom: errors.soaDate ? '41px' : '16px' }}
            onClick={handleTodayClick}
          >
            today
          </button>
        </Col>
        <FormInput
          controlId="method_date_of_appointment"
          label="Date of appointment"
          placeholder=""
          value={values.date_of_appointment}
          name="date_of_appointment"
          onChange={handleChange}
          onBlur={handleBlur}
          type="date"
          invalid={!!errors.date_of_appointment}
          error={errors.date_of_appointment}
          required
        />
      </Row>
      <div>
        <label className="form-label" htmlFor="checked">
          Select all approved items: <span className="text-danger ps-1">*</span>
        </label>
        {errors.checkbox && (
          <div style={{ marginTop: '-1rem', marginBbottom: '1rem' }}>
            <div className="d-block invalid-feedback mt-2 mb-1">{errors.checkbox}</div>
          </div>
        )}
        { soaCheckItems.map((soaCheckItem) => (
          <FormCheckbox
            key={`soa_approval_${soaCheckItem.name}`}
            controlId={soaCheckItem.name}
            label={soaCheckItem.label}
            name={soaCheckItem.name}
            value={values[soaCheckItem.name]}
            onChange={handleCheckbox}
          />
        ))}
      </div>


    </div>
  );
}

export default ExternalInputs;
