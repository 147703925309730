import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';

function Caret({
  isOpen,
  className = '',
  size = '1x',
  style = {},
}) {
  return isOpen ? (
    <FontAwesomeIcon size={size} style={style} icon={faChevronUp} className={className} />
  ) : (
    <FontAwesomeIcon size={size} style={style} icon={faChevronDown} className={className} />
  );
}

export default Caret;
