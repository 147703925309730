import React from 'react';
import FormInput from '../../common/FormInput';

const SendEmail = ({
  handleChange, handleBlur, errors, values, dateOfAppointment = false,
}) => (
  <div>
    <FormInput
      controlId="method_email_email"
      label="Email to"
      placeholder="email@gmail.com"
      value={values.send_email}
      name="send_email"
      onChange={handleChange}
      onBlur={handleBlur}
      type="email"
      invalid={!!errors.send_email}
      error={errors.send_email}
      required
    />
    {dateOfAppointment && (
      <FormInput
        controlId="method_date_of_appointment"
        label="Date of appointment"
        placeholder=""
        value={values.date_of_appointment}
        name="date_of_appointment"
        onChange={handleChange}
        onBlur={handleBlur}
        type="date"
        invalid={!!errors.date_of_appointment}
        error={errors.date_of_appointment}
        required
      />
    )}
    <FormInput
      controlId="method_email_greeting"
      label="Greeting"
      placeholder="Greeting"
      value={values.send_greeting}
      name="send_greeting"
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={!!errors.send_greeting}
      error={errors.send_greeting}
      inputClassName="greeting-color"
    />
    <FormInput
      controlId="method_email_closing"
      label="Closing"
      placeholder="Closing"
      value={values.send_closing}
      name="send_closing"
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={!!errors.send_closing}
      error={errors.send_closing}
      inputClassName="bg-success-bg"
    />
    <FormInput
      controlId="method_email_signature"
      label="Signature"
      placeholder="Signature"
      value={values.send_signature}
      name="send_signature"
      onChange={handleChange}
      onBlur={handleBlur}
      invalid={!!errors.send_signature}
      error={errors.send_signature}
      inputClassName="signature-color"
    />
  </div>
);

export default SendEmail;
