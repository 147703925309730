import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { AWAIT_STATE, FAIL_STATE, SUCCESS_STATE } from '../../../constants';

// Actions
import { drugSearch, getDosages } from '../../../actions/drug';

// Components
import LoaderButton from '../../common/LoaderButton';

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasSearchedForDrugs: false,
      results: [],
      typeSearchLoading: false,
      buttonLoading: false,
      cursor: -1,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.drugSearchDebounced = debounce(500, this.handleDrugSearch);
    this.handleDrugSelect = this.handleDrugSelect.bind(this);
    // this.utilizeFocus = this.utilizeFocus.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_DRUG_SEARCH', payload: '' });
  }

  componentDidUpdate() {
    if (this.props.message) {
      setTimeout(() => {
        this.props.dispatch({ type: 'SET_MESSAGE', payload: '' });
      }, 3000);
    }
  }

  handleDrugSearch() {
    const { search } = this.props;

    // this.setState({ loading: true });

    drugSearch(search)
      .then((data) => {
        this.setState({
          results: data,
          hasSearchedForDrugs: true,
          typeSearchLoading: false,
          buttonLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          results: [],
          typeSearchLoading: false,
          buttonLoading: false,
        });
      });
  }

  handleDrugSelect(drug) {
    const { dispatch } = this.props;

    this.setState({
      results: [],
      hasSearchedForDrugs: false,
    });
    dispatch({ type: 'CLEAR_SELECTED_DRUG' });
    dispatch({ type: 'SET_DRUG_SEARCH', payload: drug.DrugName });
    dispatch({ type: 'SET_SELECTED_DRUG', payload: drug });

    // If there is only a generic type available, do not set it as the brand drug
    if (drug.DrugTypeDesc === 'Generic') {
      dispatch({ type: 'SET_GENERIC_DRUG', payload: drug });
      dispatch({ type: 'DISPLAY_BRAND_DRUG', payload: false });
    } else {
      dispatch({ type: 'SET_BRAND_DRUG', payload: drug });
      dispatch({ type: 'DISPLAY_BRAND_DRUG', payload: true });
    }

    dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: AWAIT_STATE });
    dispatch({ type: 'SET_GENERIC_LOADED_STATE', payload: AWAIT_STATE });
    getDosages(drug.DrugID)
      .then(() => {
        dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: SUCCESS_STATE });
      })
      .catch((err) => {
        dispatch({ type: 'SET_DOSAGES_LOADED_STATE', payload: FAIL_STATE });
        console.log(err);
      });
  }

  handleInputChange(event) {
    const { dispatch } = this.props;
    const search = event.target.value;
    if (search === '') {
      this.setState({ hasSearchedForDrugs: false });
      dispatch({ type: 'CLEAR_SELECTED_DRUG' });
    }
    dispatch({ type: 'SET_DRUG_SEARCH', payload: search });
    this.setState({ typeSearchLoading: true });
    this.drugSearchDebounced();
  }

  // arrow up/down button should select next/previous list element
  handleKeyDown(e) {
    const { cursor, results } = this.state;
    if (e.keyCode === 38 && cursor > -1) {
      // up
      this.setState((prevState) => ({
        cursor: prevState.cursor - 1,
      }));
      if (cursor === 0) document.getElementById('medcab-drug-search-input').focus();
      else document.getElementById(`drug_index_${cursor - 1}`).focus();
    } else if (e.keyCode === 40 && cursor < results.length - 1) {
      // down
      this.setState((prevState) => ({
        cursor: prevState.cursor + 1,
      }));
      document.getElementById(`drug_index_${cursor + 1}`).focus();
    }
  }

  render() {
    const { hasSearchedForDrugs, results, cursor } = this.state;
    /* eslint-disable no-nested-ternary */
    return (
      <div className="mt-1 ms-2">
        <div className="fw-bold mb-3" style={{ fontSize: '18px' }}>
          Add medications
        </div>
        <div>
          <p>Add medications for more accurate plan price estimates.</p>
        </div>
        <div className="row">
          <div
            className="col d-flex flex-row"
            style={{ height: '36px', minWidth: '100%' }}
          >
            <div
              className="input-group position-relative w-50"
              style={{ maxWidth: '360px' }}
            >
              <input
                autoComplete="off"
                id="medcab-drug-search-input"
                className="form-control shadow-fix"
                onChange={this.handleInputChange}
                value={this.props.search}
                type="text"
                onKeyDown={this.handleKeyDown}
              />
              <span
                className=""
                style={{ paddingRight: '8px', paddingTop: '7px', zIndex: '1' }}
              >
                <i className="fa fa-lg fa-search" />
              </span>
              {this.state.typeSearchLoading ? (
                <div
                  className="text-input-spinner p-absolute"
                  style={{ left: '-24px', top: '4px' }}
                >
                  <div
                    className="spinner-border spinner-border-sm text-primary"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : null}
            </div>
            <LoaderButton
              color="primary"
              text="Search"
              loadingText=""
              className="px-4 ms-3 fw-bold"
              loading={this.state.buttonLoading}
              onClick={() => {
                this.setState({ buttonLoading: true });
                this.drugSearchDebounced();
              }}
            />
          </div>
          <div className="col" />
        </div>
        <div className="mt-2 text-bold text-success">{this.props.message}</div>
        <ul className="list-group drug-search-results">
          {results.length > 0 ? (
            results.map((drug, i) => (
              <button
                key={drug.DrugID}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleDrugSelect(drug);
                }}
                type="button"
                className={
                  `bg-white list-group-item-action list-group-item${
                    cursor === i ? ' text-success' : ''}`
                }
                id={`drug_index_${i}`}
                onKeyDown={this.handleKeyDown}
              >
                <span id="medcab-drug-search-result-name" className="fw-bold">
                  {drug.DrugName}
                </span>
              </button>
            ))
          ) : hasSearchedForDrugs ? (
            <li className="list-group-item">There were no results.</li>
          ) : null}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    search: store.drug.search,
    message: store.drug.message,
  };
}

export default connect(mapStateToProps)(Search);
