import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart as faHeartOutline,
  // faCertificate as faCertificateOutline,
  faAward as faAwardOutline,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHeart as faHeartSolid,
  // faCertificate as faCertificateSolid,
  faAward as faAwardSolid,
} from '@fortawesome/pro-solid-svg-icons';

class CareHighlightsIcons extends React.PureComponent {
  render() {
    const { text, type } = this.props;

    let classSolid = 'me-1';
    let classOutline = 'me-1';
    let rendered = <i className="text-muted">Not Available</i>;
    let solid = faHeartSolid;
    let outline = faHeartOutline;

    if (type === 'award') {
      solid = faAwardSolid;
      outline = faAwardOutline;
      classSolid = 'me-2';
      classOutline = 'me-2';
    }

    if (text && text.includes('1')) {
      rendered = (
        <>
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
        </>
      );
    } else if (text && text.includes('2')) {
      rendered = (
        <>
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
        </>
      );
    } else if (text && text.includes('3')) {
      rendered = (
        <>
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classOutline} icon={outline} />
        </>
      );
    } else if (text && text.includes('4')) {
      rendered = (
        <>
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
          <FontAwesomeIcon className={classSolid} icon={solid} />
        </>
      );
    }
    return rendered;
  }
}

CareHighlightsIcons.defaultProps = {
  text: '',
};

export default CareHighlightsIcons;
