const initialState = {
  agentUsername: null,
  filterStatusId: -1,
  filteredShopperList: [],
  isLoading: false,
  loginRedirectTo: null,
  shopperList: [],
  shopperListLastPageNum: 0,
  shopperUsername: null,
  sortTerm: 'status',
  redirectedFromDesktop: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_LEAD_UN':
      return {
        ...state,
        shopperUsername: action.payload,
      };
    case 'SET_AGENT_UN':
      return {
        ...state,
        agentUsername: action.payload,
      };
    case 'SET_MOBILE_LOGIN_REDIRECT_TO':
      return {
        ...state,
        loginRedirectTo: action.payload,
      };
    case 'SET_MOBILE_DEVICE_REDIRECT':
      return {
        ...state,
        redirectedFromDesktop: action.payload,
      };
    case 'SET_MOBILE_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_MOBILE_SORT_TERM':
      return {
        ...state,
        sortTerm: action.payload,
      };
    case 'SET_MOBILE_FILTER_STATUS_ID':
      return {
        ...state,
        filteredShopperList: [],
        filterStatusId: action.payload,
      };
    case 'FILTER_MOBILE_SHOPPER_LIST':
      /* eslint-disable */
      let filteredShopperList = [ ...state.shopperList ];
      let filteredStatusIdString = state.filterStatusId+'';
      if (filteredStatusIdString !== '-1'){
        filteredShopperList = filteredShopperList.filter((s) => {
          const statusString = s.status + '';
          return statusString === filteredStatusIdString;
        });
      }
      return {
        ...state,
        filteredShopperList: filteredShopperList,
      }
      /* eslint-enable */
    case 'SET_MOBILE_SHOPPER_LIST_LAST_PAGE_NUM':
      return {
        ...state,
        shopperListLastPageNum: action.payload,
      };
    case 'UPDATE_MOBILE_SHOPPER':
      // eslint-disable-next-line
      let idxToReplace = -1;
      state.shopperList.forEach((e, idx) => {
        if (e.username === action.payload.username) {
          idxToReplace = idx;
        }
      });
      // eslint-disable-next-line
      const newShopperList = state.shopperList;
      newShopperList[idxToReplace] = action.payload;
      return {
        ...state,
        shopperList: newShopperList,
      };
    case 'SET_MOBILE_SHOPPER_LIST':
      return {
        ...state,
        shopperList: action.payload.users,
      };
    case 'APPEND_MOBILE_SHOPPER_LIST':
      // Ensure we don't have any duplicates on the frontend
      /* eslint-disable */
      const newShoppers = action.payload.users.filter((user) => {
        return true;
      });
      return {
        ...state,
        shopperList: [...state.shopperList, ...newShoppers],
      };
    case 'CLEAR_MOBILE_SHOPPER_LIST':
      return {
        ...state,
        shopperList: [],
      };
    case 'REFRESH_MOBILE_SHOPPER_LIST':
      return {
        ...state,
        shopperList: state.shopperList,
      };
    default:
      return state;
  }
}
