import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';

// Utility
import { fmtApproval } from '../../../utils/general';

class SOABadge extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { popoverOpen: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setShopperInfobarRef = (node) => {
    this.shopperInfobarRef = node;
  };

  setPopoverRef = (node) => {
    this.popoverRef = node;
  };

  handleClickOutside = (event) => {
    const { popoverOpen } = this.state;
    if (!popoverOpen) return;

    if (this.popoverRef !== event.target && !this.popoverRef.contains(event.target)) {
      if (this.shopperInfobarRef && !this.shopperInfobarRef.contains(event.target)) {
        this.setState({ popoverOpen: false });
      }
    }
  };

  render() {
    const { soaList } = this.props;
    const { popoverOpen } = this.state;

    const statusStyles = {
      unsent: {
        color: 'white',
        backgroundColor: '#eb0000',
      },
      sent: {
        color: 'white',
        backgroundColor: '#9d9fa2',
      },
      signed: {
        color: 'white',
        backgroundColor: '#419a6f',
      },
    };

    let msg = null;
    if (!soaList || !soaList.length) {
      msg = (
        <div className="mmb-badge" style={statusStyles.unsent}>
          Need to send
        </div>
      );
    } else {
      soaList.sort((a, b) => a.created > b.created);
      const soa = soaList[0];

      // 'Submitted' means we have received a response
      if (!soa.submitted) {
        msg = (
          <div className="mmb-badge" style={statusStyles.sent}>
            Sent
          </div>
        );
      } else {
        msg = (
          <span
            onClick={(e) => {
              if (this.popoverRef && this.popoverRef.contains(e.target)) {
                return;
              }
              this.setState({ popoverOpen: !popoverOpen });
            }}
            role="button"
            tabIndex={0}
            ref={this.setShopperInfobarRef}
            id={soa.trace_id}
            style={{
              cursor: 'pointer',
              userSelect: 'none',
              marginLeft: '8px',
              marginRight: '8px',
            }}
          >
            <span className="mmb-badge" style={statusStyles.signed}>
              <FontAwesomeIcon
                className="me-1"
                style={{ marginBottom: '3px' }}
                icon={faSortDown}
              />
              Signed
            </span>
            <div
              className={`soa-approval-popover ${popoverOpen ? 'open' : ''}`}
              ref={this.setPopoverRef}
              style={{ lineHeight: '28px' }}
              id={soa.trace_id}
            >
              <div>{fmtApproval(soa.mapd_ma)} MA / MAPD</div>
              <div>{fmtApproval(soa.pdp)} PDP</div>
              <div>{fmtApproval(soa.dvh)} Dental / vision / hearing products</div>
              <div>{fmtApproval(soa.sup_health)} Supplemental health products</div>
              <div>{fmtApproval(soa.medigap)} Medicare Supplement</div>

            </div>
          </span>
        );
      }
    }
    return msg;
  }
}

SOABadge.defaultProps = {
  soaList: [],
};

export default SOABadge;
