/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getMeetingTypeOptions } from '../utils';

// actions + utils
import { calculateTravelZips } from '../../../utils/general';

// components
import MapsInterface from './MapsInterface';
import ManualZipEntry from './ManualZipEntry';
import StateZipEntry from './StateZipEntry';
import { LoaderButton } from '../../../components';

function AgentWithManualZipEntry({ reduxProfile, meetingType }) {
  const {
    travelZipsByMeetingType: {
      [meetingType]: travelZips = [],
    } = {},
  } = (reduxProfile || {});
  const displayValue = (travelZips || []).join('\n');
  return (
    <div className="row">
      <div className="col">
        Your manager has manually set up your location preferences for you for
        this meeting type. These are the zipcodes they have set. If you would like to change
        them please reach out to your primary contact for the leads program.
      </div>

      <div className="d-flex col">
        <textarea
          disabled
          style={{ height: '200px', overflowY: 'scroll' }}
          value={displayValue}
        />
      </div>
    </div>
  );
}

function ZipInterface({
  disabled,
  setReduxProfileKeys,
  setReduxAppointments,
  reduxProfile,
  canEditLocation,
  awsConfig,
  awsConfigUseDataSite = false,
  radiusOptions = [],
  allowManualZipEntry = false,
  allowStateZipEntry = false,
  setManualZipInputValid,
  manualZipInputValid,
  meetingType,
  allZipsMeetingType,
  activeTab,
}) {
  const {
    meetingTypes: activeMeetingTypes = {},
    zipEntryTypes: {
      [meetingType]: zipEntryType = 'map',
    } = {},
  } = reduxProfile;
  const isVisible = ((meetingType === activeTab) && activeMeetingTypes[meetingType]);
  const isActiveTab = (meetingType === activeTab);
  const isActiveMeetingType = activeMeetingTypes[meetingType];

  if (!isActiveMeetingType && isActiveTab) {
    return (
      <div className="fst-italic text-muted">
        You have not currently opted to receive appointments of this type.
      </div>
    );
  } else if (allZipsMeetingType && isVisible) {
    return (
      <div className="fst-italic text-muted">
        You will automatically receive appointments from all zipcodes within your licensed states.
      </div>
    );
  } else if (canEditLocation && !allowManualZipEntry && (zipEntryType === 'manual') && isActiveTab) {
    return <AgentWithManualZipEntry reduxProfile={reduxProfile} meetingType={meetingType} />;
  } else if (!canEditLocation) {
    return (
      <div>Your account does not have permission to edit location preferences.</div>
    );
  }

  function handleZipEntryTypeUpdate(type) {
    setReduxProfileKeys({
      zipEntryTypes: {
        ...(reduxProfile.zipEntryTypes || {}),
        [meetingType]: type,
      },
    });
  }

  return isVisible ? (
    <>
      <div className="row">
        <header className="mb-4">
          {(allowStateZipEntry || allowManualZipEntry) && (
            <LoaderButton
              type="button"
              className={`btn ${(zipEntryType === 'map') ? 'btn-secondary' : 'btn-outline-secondary'}`}
              text="Map"
              loadingText="Map"
              onClick={() => handleZipEntryTypeUpdate('map')}
            />
          )}
          {allowStateZipEntry && (
            <LoaderButton
              type="button"
              className={`ms-3 btn ${(zipEntryType === 'state') ? 'btn-secondary' : 'btn-outline-secondary'}`}
              text="State"
              loadingText="State"
              onClick={() => handleZipEntryTypeUpdate('state')}
            />
          )}
          {allowManualZipEntry && (
            <LoaderButton
              type="button"
              className={`ms-3 btn ${(zipEntryType === 'manual') ? 'btn-secondary' : 'btn-outline-secondary'}`}
              text="Manual"
              loadingText="Manual"
              onClick={() => handleZipEntryTypeUpdate('manual')}
            />
          )}
        </header>
      </div>
      {(zipEntryType === 'map') && (
        <MapsInterface
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          setReduxAppointments={setReduxAppointments}
          reduxProfile={reduxProfile}
          canEditLocation={canEditLocation}
          awsConfig={awsConfig}
          awsConfigUseDataSite={awsConfigUseDataSite}
          radiusOptions={radiusOptions}
          meetingType={meetingType}
        />
      )}
      {(zipEntryType === 'manual') && (
        <ManualZipEntry
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          reduxProfile={reduxProfile}
          canEditLocation={canEditLocation}
          setManualZipInputValid={setManualZipInputValid}
          manualZipInputValid={manualZipInputValid}
          meetingType={meetingType}
        />
      )}
      {(zipEntryType === 'state') && (
        <StateZipEntry
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          reduxProfile={reduxProfile}
          canEditLocation={canEditLocation}
          meetingType={meetingType}
        />
      )}
    </>
  ) : null;
}

function NavTab({
  type,
  reduxProfile,
  activeTab,
  setActiveTab,
}) {
  const { meetingTypes: activeMeetingTypes = {} } = reduxProfile;
  const disabled = !activeMeetingTypes[type.value];
  const isActive = (type.value === activeTab);
  const classes = `nav-link ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''} ${disabled ? 'fst-italic' : ''}`;

  return (
    <li className="nav-item">
      <button
        type="button"
        className={classes}
        onClick={() => setActiveTab(type.value)}
        disabled={disabled}
      >
        {type.label}
      </button>
    </li>
  );
}

function Navigation({
  disabled,
  setReduxProfileKeys,
  setReduxAppointments,
  reduxProfile,
  canEditLocation,
  awsConfig,
  awsConfigUseDataSite = false,
  radiusOptions = [],
  allowManualZipEntry = false,
  allowStateZipEntry = false,
  setManualZipInputValid,
  manualZipInputValid,
}) {
  const meetingTypes = getMeetingTypeOptions();
  const {
    meetingTypes: activeMeetingTypes = {},
    travelZipsByMeetingType = {},
  } = reduxProfile;
  const [ activeTab, setActiveTab ] = useState(meetingTypes[0].value);

  useEffect(() => {
    // if we are on a new profile with no active Meeting types, this causes an
    // infinite loop of updates unless we first check that there are activeMeetingTypes
    // existing.
    if (Object.keys(activeMeetingTypes).length) {
      const updatedTravelZips = {};
      const updatedManualZipsHistory = {};
      meetingTypes.forEach((type) => {
        const key = type.value;
        const value = (activeMeetingTypes[key] || false);
        // if a meeting type is unselected, clear the zipcodes for it in travelZipsByMeetingType
        if (!value) {
          updatedTravelZips[key] = [];
        // if a meeting type is selected, calculate travel zips using saved points
        } else if (value === true) {
          const tz = calculateTravelZips({
            profile: (reduxProfile || {}),
            canEditLocation,
            meetingType: key,
          });
          updatedTravelZips[key] = tz;
        }
      });
      setReduxProfileKeys({
        travelZipsByMeetingType: {
          ...(reduxProfile.travelZipsByMeetingType || {}),
          ...updatedTravelZips,
        },
      });
    }
  }, [activeMeetingTypes]);

  return (
    <div>
      <h3 className={`${disabled ? 'text-muted' : ''}`}>
        Geographies
      </h3>
      <p className="text-muted text-small mb-4">
        Set the zipcodes where you will receive leads for the different lead types. You must specify
        a geography for each of the different lead types you would like to receive.
      </p>
      <ul className="nav nav-tabs">
        {meetingTypes.map((type) => (
          <NavTab
            key={type.value}
            activeTab={activeTab}
            setActiveTab={(v) => setActiveTab(v)}
            type={type}
            reduxProfile={reduxProfile}
          />
        ))}
      </ul>

      <br />

      {meetingTypes.map((type) => (
        <ZipInterface
          key={type.value}
          disabled={disabled}
          setReduxProfileKeys={setReduxProfileKeys}
          setReduxAppointments={setReduxAppointments}
          reduxProfile={reduxProfile}
          canEditLocation={canEditLocation}
          awsConfig={awsConfig}
          awsConfigUseDataSite={awsConfigUseDataSite}
          radiusOptions={radiusOptions}
          allowManualZipEntry={allowManualZipEntry}
          allowStateZipEntry={allowStateZipEntry}
          setManualZipInputValid={setManualZipInputValid}
          manualZipInputValid={manualZipInputValid}
          meetingType={type.value}
          allZipsMeetingType={type.allZips}
          isActiveMeetingType={activeMeetingTypes[type.value]}
          activeTab={activeTab}
        />
      ))}

    </div>
  );
}

export default Navigation;
