/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

// Components
import TopNavbar from './topbar/';
import ShopperInfobar from './topbar/ShopperInfobar';

// import ShoperInfo from './topbar/ShopperInfo';
import QuickQuoteWarningModal from './quickquote/QuickQuoteWarningModal';
import ExitModal from '../modal/ExitModal';

class NavPage extends React.PureComponent {
  render() {
    const {
      activeLink,
      children,
      shopper,
      showShopperInfobar,
      disableNavOverride,
      noPadding,
    } = this.props;

    return (
      <div
        className={`navpage ${showShopperInfobar && 'extended'}`}
        style={{ paddingBottom: noPadding ? '0px' : '100px' }}
      >
        <TopNavbar
          activeLink={activeLink}
          disableNavOverride={disableNavOverride}
        />
        <ShopperInfobar
          showShopperInfobar={showShopperInfobar}
          activeLink={activeLink}
        />
        {children}
        <QuickQuoteWarningModal />
        <ExitModal />
      </div>
    );
  }
}

NavPage.defaultProps = {
  activeLink: '/dashboard',
  showShopperInfobar: false,
};

function mapStateToProps(store) {
  return {
    shopper: store.shopper,
  };
}

export default connect(mapStateToProps)(NavPage);
