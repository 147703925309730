import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

// Actions
import { getReleaseNotes, getPrivacyDisclosure } from '../../actions/agent';

// Utilities
import { showPopup } from '../../utils/popup';

class MarkdownContent extends React.PureComponent {
  componentDidMount() {
    const {
      dispatch,
    } = this.props;

    Promise.all([getReleaseNotes(), getPrivacyDisclosure()])
      .then((data) => {
        dispatch({ type: 'SET_RELEASE_NOTES', payload: data[0] });
        dispatch({ type: 'SET_PRIVACY_DISCLOSURE', payload: data[1] });
      }).catch(() => {
        showPopup('Error', 'There was a problem loading release notes or privacy disclosure.');
      });
  }

  render() {
    const {
      content,
      length,
      privacyDisclosure,
      releaseNotes,
    } = this.props;

    let markdown = [];
    if (!length && content === 'rnotes') {
      markdown = releaseNotes.map((note) => (
        <ReactMarkdown key={note.title}>
          {`## ${note.title}\n${note.body}`}
        </ReactMarkdown>
      ));
    } else if (length && content === 'rnotes') {
      markdown = releaseNotes.map((note, index) => {
        if (index < length) {
          return (
            <ReactMarkdown key={note.title}>
              {`## ${note.title}\n${note.body}`}
            </ReactMarkdown>
          );
        }
        return null;
      });
    } else if (content === 'privacy') {
      const { title, body } = privacyDisclosure;
      markdown = (
        <ReactMarkdown escapeHtml={false}>
          {`## ${title}\n${body}`}
        </ReactMarkdown>
      );
    }
    return markdown;
  }
}

MarkdownContent.defaultProps = {
  content: 'rnotes', // options: 'rnotes', 'privacy'
  length: 0,
};

function mapStateToProps(store) {
  return {
    privacyDisclosure: store.display.privacyDisclosure,
    releaseNotes: store.display.releaseNotes,
  };
}

export default connect(mapStateToProps)(MarkdownContent);
