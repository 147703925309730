import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';


class PlanDocuments extends React.PureComponent {
  translateName = (name) => {
    const translations = {
      SOB: 'Summary of Benefits',
      EOC: 'Evidence of Coverage',
    };
    return translations[name] || name;
  };

  render() {
    const {
      planDocuments = [],
    } = this.props;

    return (
      <div className="plandetails-plan-documents">
        <table>
          <tbody>
            {planDocuments.map((planDocument) => (
              <tr
                className=""
                key={planDocument.DocumentName}
              >
                <td className="text-left">
                  <a
                    className="plandetails-plan-documents-name plandetails-plan-documents-row"
                    href={planDocument.URL}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      className="plandetails-plan-documents-icon"
                      icon={faFileAlt}
                    />
                    {this.translateName(planDocument.DocumentName)}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect()(PlanDocuments);
