import React /* , { useEffect, useState } */ from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function SelectedPharmacy() {
  const navigate = useNavigate();
  const {
    shopper: { pharmacyList = [] },
    // pharmacy: { selectedPharmacy },
  } = useSelector(store => store);
  // const [ sPharm, setSPharm ] = useState('');

  // useEffect(() => {
  //   setSPharm(pharmacyList[0]);
  //   // setSPharm(pharmacyList.find((p) => Number(p.npi) === Number(selectedPharmacy))); // note (Jason): old method for when multiple pharmacies
  // }, [pharmacyList]);

  let text = <i><b>No pharmacy selected</b></i>;

  if (pharmacyList.length) {
    text = (
      <>
        <span>Selected Pharmacy:</span>
        <span>{pharmacyList[0].name}</span>
        {pharmacyList[0].city !== 'AUTO' && (
        <>
          <span>{pharmacyList[0].address1}</span>
          <span>{pharmacyList[0].city}, {pharmacyList[0].state}, {pharmacyList[0].zip}</span>
        </>
        )}
      </>
    );
  }

  return (
    <div className="d-flex h-100">
      <div className="selected-pharmacy-col">
        {text}
      </div>
      <div className="selected-pharmacy-col">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => navigate('/rx/add/pharmacy')}
        >
          {
            pharmacyList.length
              ? 'Change Pharmacy'
              : 'Select Pharmacy'
          }
        </button>
      </div>
    </div>
  );
}
