/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

// Utils
import { fmtDocSpecialties } from '../../../utils/formatFunctions';

class DoctorPopover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hideShade: false,
    };
  }

  handleScroll = (e) => {
    const isAtBottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 25;
    if (isAtBottom) {
      this.setState({ hideShade: true });
    } else {
      this.setState({ hideShade: false });
    }
  };

  render() {
    const {
      doctorList,
      planCPS,
      planName,
    } = this.props;
    const { hideShade } = this.state;

    return (
      <div className="provider-coverage-popover">
        <div className="fs-4 fw-bold" title={planName}>Doctors in-network for</div>
        <div title={planName}>{planName}</div>
        <div className="provider-coverage-popover-list" onScroll={(e) => this.handleScroll(e)}>
          {
            doctorList.map((doctor) => {
              let inNetwork = false;

              const hasAStar = doctor.important;

              const { npi } = doctor;
              if (doctor.coveredPlans.includes(planCPS)) {
                inNetwork = true;
              }

              return (
                <div
                  className="provider-coverage-popover-list-item"
                  key={`provider-coverage-popover-${npi}`}
                  style={{ backgroundColor: inNetwork ? '' : 'var(--bs-bddark)' }}
                >
                  <span className="d-flex" title={doctor.name}>
                    <FontAwesomeIcon
                      className={`doctor-important-star me-2 ms-0 ${hasAStar ? 'selected' : ''}`}
                      icon={faStar}
                      style={{
                        opacity: hasAStar
                          ? inNetwork ? '1' : '0.5'
                          : 0,
                      }}
                    />
                    <b className="me-4" style={{ opacity: inNetwork ? '1' : '0.5' }}>{doctor.name}</b>
                  </span>
                  <span
                    className="fst-italic"
                    style={{
                      marginLeft: '1.75rem',
                      opacity: inNetwork ? '1' : '0.5',
                    }}
                    title={doctor.specialties}
                  >
                    {fmtDocSpecialties(doctor.specialties)}
                  </span>
                  <span
                    className="provider-coverage-popover-in-network"
                    title={inNetwork ? 'In-network' : 'Outside of network'}
                  >
                    <FontAwesomeIcon
                      icon={inNetwork ? faCheck : faTimes}
                      style={{ color: inNetwork ? 'var(--bs-green)' : 'var(--bs-red)', right: '0px' }}
                    />
                  </span>
                </div>
              );
            })
          }
        </div>
        {
          doctorList.length > 4
            && <div className={`provider-coverage-popover-list-shade ${hideShade ? 'hidden' : ''}`} />
        }
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    doctorList: store.shopper.doctorList,
  };
}

export default connect(mapStateToProps)(DoctorPopover);
