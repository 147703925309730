import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';

// Components
import QuestionToolTip from '../../../common/QuestionToolTip';

// Utilities
import { retrieveArrayofExtraBenefits } from '../../../../utils/general';

class ExtraBenefits extends React.PureComponent {
  render() {
    const {
      extraBenefits,
      extraBenefitsKey,
      extraBenefitsStatus,
      plan,
      style,
    } = this.props;

    if (!plan.ExtraBenefits || plan.ExtraBenefits.length === 0 || !extraBenefitsStatus) {
      return null;
    }

    return (
      <div
        className="plandetails-table"
        style={style}
      >
        {plan.ExtraBenefits.map((benefit) => {
          const extraBenefitsToDisplay = retrieveArrayofExtraBenefits(extraBenefits);
          if (!extraBenefitsToDisplay.includes(benefit.BenefitName)) {
            return null;
          }

          const description = extraBenefitsKey.find((extraBenefit) => extraBenefit.BenefitID === benefit.BenefitID);
          let benefitDesc = '';
          if (description) {
            benefitDesc = description.BenefitDesc;
          }

          const benefitPresent = (benefit.BenefitValue === 'Y') || (benefit.BenefitValue === 'Yes');

          return (
            <div
              className="plandetails-table-row plandetails-table-row-sm"
              key={benefit.BenefitName}
            >
              <div
                className="plandetails-table-detail-name"
                style={{ textTransform: 'capitalize' }}
                title={benefit.BenefitName}
              >
                <QuestionToolTip
                  title={benefit.BenefitName}
                  content={benefitDesc}
                />
                {benefit.BenefitName}
              </div>
              <div
                className="plandetails-table-detail-value"
                title={`${benefitPresent ? 'Available' : 'Not available'}`}
              >
                {benefitPresent
                  ? <FontAwesomeIcon icon={faCheck} className="text-success" />
                  : <FontAwesomeIcon icon={faTimes} className="text-grey" />}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    benefitHighlightsExpanded: store.display.benefitHighlightsExpanded,
    extraBenefits: store.theme.extraBenefits || {},
    extraBenefitsKey: store.plan.extraBenefitsKey,
    extraBenefitsStatus: store.theme.features.showExtraBenefits,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
    modals: store.theme.modals,
  };
}

export default connect(mapStateToProps)(ExtraBenefits);
