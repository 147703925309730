import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouterProps } from '../../../utils/hocs';

// Actions
import { loadMedicareDetails } from '../../../actions/plan';
import { filterMedicare, getMedicarePlanByID } from '../../../actions/planfilters';

// Components
import NavPage from '../../nav/NavPage';
import PlanComparisonTable from '../list/recommended/PlanComparisonTable';
import CompareFooter from './CompareFooter';
import CompareTable from './CompareTable';
import PricingDisclaimer from '../../common/PricingDisclaimer';
import DsnpDisclaimer from '../../common/DsnpDisclaimer';

import { DEFAULT_STATE, FAIL_STATE } from '../../../constants';

function ComparePage({
  planIds,
  selectedPharmacy,
  shopper,
  carrierLevelCIFeature,
}) {
  const { medicareList } = useSelector(store => store.plan);
  const [filteredMedicareList, setFilteredMedicareList] = useState([]);
  const [comparedPlans, setComparedPlans] = useState([{}, {}, {}]);

  useEffect(() => {
    setFilteredMedicareList(filterMedicare(medicareList, {
      planType: ['MAPD', 'MA', 'PDP'],
      npi: selectedPharmacy,
    }));
  }, []);

  useEffect(() => {
    if (!filteredMedicareList.length) return;

    // Clear out any previous state
    setComparedPlans([]);
    const currentPlanID = shopper.currentPlan
      ? (filteredMedicareList.filter((plan) => plan.cpsID === shopper.currentPlan)[0])?.planID || -1
      : -1;
    const loadingPlanIDs = currentPlanID === -1 ? [...planIds] : [currentPlanID, ...planIds];
    // Load in Plan Details/Benefits, etc.
    loadingPlanIDs.forEach((planID, idx) => {
      const plan = getMedicarePlanByID(medicareList, planID, selectedPharmacy);
      if (plan && (plan.meta.detailsLoaded === DEFAULT_STATE || plan.meta.detailsLoaded === FAIL_STATE)) {
        loadMedicareDetails(shopper, planID)
          .then(() => {
            const detailedPlan = getMedicarePlanByID(medicareList, planID, selectedPharmacy);
            setComparedPlans(prevPlans => {
              const newPlans = [...prevPlans];
              newPlans.splice(idx, 0, detailedPlan);
              return newPlans;
            });
          });
      } else if (plan) {
        // Medicare data has been previously loaded in, set our internal state
        setComparedPlans(prevPlans => {
          const newPlans = [...prevPlans];
          newPlans.splice(idx, 0, plan);
          return newPlans;
        });
      }
    });
  }, [filteredMedicareList, planIds]);

  // function handleEnrollButtonClick(plan) {
  //   navigate('/enrollment');
  //   dispatch({ type: 'SET_ENROLLMENT_PLAN', payload: plan.planID });
  // }

  return (
    <NavPage
      activeLink="/plans"
      showShopperInfobar
    >
      <div className="mmb-container">
        <div className="w-100">
          <PricingDisclaimer />
          <DsnpDisclaimer plans={comparedPlans} />
        </div>
        <div className="mmb-header">
          Compare plans
        </div>
        <div className="mmb-content">
          {/* Plan Summaries, pulled from recommended plans */}
          {/* There's some issues arrising from fixing React.PureComponents and functions... */}
          <PlanComparisonTable
            style={{ marginBottom: '40px' }}
            plans={comparedPlans}
          />
          {carrierLevelCIFeature && (
            <CompareTable
              tableLabel="Competitive Intelligence"
              type="key"
              plans={comparedPlans}
              dataKeys={[
                {
                  label: 'Star rating vs market',
                  key: 'plan.StarRatingRank',
                },
                {
                  label: 'Premum rank vs market',
                  key: 'plan.PremiumRankVsMarket',
                },
                {
                  label: 'Premum market summary',
                  key: 'plan.PremiumRank',
                },
              ]}
            />
          )}
          <CompareTable
            tableLabel="Extra Benefits"
            type="list"
            plans={comparedPlans}
            dataKeys="plan.ExtraBenefits"
          />
          <CompareTable
            tableLabel="Plan Benefits"
            type="list"
            plans={comparedPlans}
            dataKeys="plan.PlanBenefitDetails"
          />
          <CompareTable
            tableLabel="Prescription Drug Tiers"
            type="list"
            plans={comparedPlans}
            dataKeys="plan.TierBenefits"
          />
        </div>
      </div>
      <CompareFooter openOnLoad />
    </NavPage>
  );
}


function mapStateToProps(store) {
  return {
    planIds: store.compare.planIds,
    medicareListLoaded: store.loaders.medicareListLoaded,
    selectedPharmacy: store.pharmacy.selectedPharmacy,
    shopper: store.shopper,
    carrierLevelCIFeature: store.theme.features.carrierLevelCI,
  };
}

export default connect(mapStateToProps)(withRouterProps(ComparePage));
