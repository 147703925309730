/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import { LocationClient } from '@aws-sdk/client-location';
import {
  Map,
  NavigationControl,
  GeolocateControl,
} from 'maplibre-gl';

// actions
import { loadConfig } from '../../../actions/config';
import { calculateTravelZips } from '../../../utils/general';

// components
import 'maplibre-gl/dist/maplibre-gl.css';
import GeoPoint from './GeoPoint';

function TravelZipsDisplay({ reduxProfile, meetingType }) {
  const travelZips = calculateTravelZips({
    profile: (reduxProfile || {}),
    canEditLocation: true,
    meetingType,
  });
  return (
    <div>
      <p>
        These are the zipcodes which have been included / excluded based on your radii settings.
      </p>
      <textarea
        disabled
        className="form-control"
        style={{ height: '200px' }}
        value={(travelZips || []).join('\n')}
      />
    </div>
  );
}

function MapsInterface({
  disabled,
  setReduxProfileKeys,
  setReduxAppointments,
  reduxProfile,
  canEditLocation,
  awsConfig,
  awsConfigUseDataSite = false,
  radiusOptions = [],
  meetingType,
}) {
  const [ locationClient, setLocationClient ] = useState();
  const [ mapInstance, setMapInstance ] = useState();

  // const { points = [] } = reduxProfile;
  const { pointsByMeetingType = {} } = reduxProfile;
  const points = (pointsByMeetingType[meetingType] || []);

  async function initializeMap(center) {
    const site = (process.env.REACT_APP_HOSTNAME || window.location.hostname);
    const { data: config = {} } = await loadConfig('aws_location_services_config_json', site, 'agent', awsConfigUseDataSite);
    setReduxAppointments({ awsConfig: config });
    const {
      mapName,
      region,
      apiKey,
      defaultZoom,
    } = config;
    const mapStyle = `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`;
    const map = new Map({
      container: 'map', // HTML element ID of map element
      center, // Initial map centerpoint
      zoom: defaultZoom, // Initial map zoom
      // Defines the appearance of the map and authenticates using an API key
      style: mapStyle,
    });

    map.on('error', (e) => console.log('map error: ', e));

    // Add navigation control to the top left of the map
    const nc = new NavigationControl();
    map.addControl(nc);

    setMapInstance(map);

    const gc = new GeolocateControl({ trackUserLocation: true });
    gc.on('error', (e) => console.log('GeolocateControl error: ', e));
    map.addControl(gc);

    const authHelper = await withAPIKey(apiKey);
    const client = new LocationClient({
      region,
      ...authHelper.getLocationClientConfig(),
    });
    setLocationClient(client);
  }

  useEffect(() => {
    async function main() {
      let center = [-77.03674, 38.891602 ];
      // Initialize map and Amazon Location SDK client:
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async e => {
          center = [ e.coords.longitude, e.coords.latitude ];
          await initializeMap(center);
        });
      } else {
        await initializeMap(center);
      }
    }
    main();
  }, []);

  function addPoint() {
    const id = `point-${points.length}`;
    const updatedPoints = [
      ...points,
      {
        id,
        radius: 10,
        include: true,
      },
    ];
    setReduxProfileKeys({
      pointsByMeetingType: {
        ...(reduxProfile.pointsByMeetingType || {}),
        [meetingType]: updatedPoints,
      },
    });
  }

  return canEditLocation ? (
    <>
      <div className="row my-2">
        <div className="col">
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={addPoint}
            disabled={disabled}
          >
            + Add Radius
          </button>
        </div>
      </div>

      {points.map(point => (
        <div key={point.id} className="row my-2">
          <GeoPoint
            key={point.id}
            point={point}
            locationClient={locationClient}
            mapInstance={mapInstance}
            disabled={disabled}
            setReduxProfileKeys={setReduxProfileKeys}
            reduxProfile={reduxProfile}
            awsConfig={awsConfig}
            radiusOptions={radiusOptions}
            meetingType={meetingType}
          />
        </div>
      ))}

      <div className="row">
        <hr className="mt-2" />
        <main>
          <div style={{ height: '500px' }} id="map" />
        </main>
      </div>

      <div className="row my-2 mt-5">
        <TravelZipsDisplay reduxProfile={reduxProfile} meetingType={meetingType} />
      </div>

    </>
  ) : (
    <div>Your account does not have permission to edit location preferences.</div>
  );
}

export default MapsInterface;
