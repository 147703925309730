const initialState = {
  loading: false,
  sortBy: 'email',
  desc: false,
  agentBook: [],
  agentPage: [],
  activePage: 1,
  totalItemsCount: 50,
  itemsCountPerPage: 15,
  searchTerm: '',
  selectedAgents: [],
  selectAgentsSidebarOpen: false,
  allSelectCheck: false,
  initialSelect: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_AGENTS_STATUS':
      return { ...state, loading: action.payload };
    case 'LOAD_AGENT_LIST': {
      const agentBook = action.payload.newLoad ? [] : [...state.agentBook];
      const activePage = action.payload.Page;
      agentBook[activePage - 1] = action.payload.Users || [];

      return {
        ...state,
        agentBook,
        agentPage: agentBook[activePage - 1],
        totalItemsCount: action.payload.TotalUsers,
        itemsCountPerPage: action.payload.MaxPageSize,
        activePage,
      };
    }
    case 'SET_AGENTS_ACTIVE_PAGE': {
      const agentBook = [...state.agentBook];
      return {
        ...state,
        activePage: action.payload,
        agentPage: agentBook[action.payload - 1],
      };
    }
    case 'SET_INITIAL_SELECT':
      return { ...state, initialSelect: action.payload };
    case 'SET_ALL_SELECT_CHECKBOX':
      return { ...state, allSelectCheck: action.payload };
    case 'ADD_SELECTED_AGENT': {
      const selectedAgents = [...state.selectedAgents];
      selectedAgents.push(action.payload);
      return { ...state, selectedAgents };
    }
    case 'REMOVE_SELECTED_AGENT': {
      const selectedAgents = [...state.selectedAgents];
      const filtered = selectedAgents.filter((agent) => agent.username !== action.payload.username);
      return { ...state, selectedAgents: filtered };
    }
    case 'SELECT_ALL_AGENTS': {
      const selectedAgents = [...state.selectedAgents];
      const allSelectedAgents = selectedAgents.concat(action.payload);
      const filtered = Array.from(new Set(allSelectedAgents.map((a) => a.username)))
        .map((username) => (allSelectedAgents.find((a) => a.username === username)));
      return { ...state, selectedAgents: filtered };
    }
    case 'DESELECT_ALL_AGENTS': {
      const selectedAgents = Array.from(new Set([...state.selectedAgents].map((a) => a.username)));
      const agentsToRemove = Array.from(new Set(action.payload.map((a) => a.username)));
      const newAgentsList = [];
      selectedAgents.forEach((username) => {
        if (!agentsToRemove.includes(username)) {
          newAgentsList.push([...state.selectedAgents].find((a) => a.username === username));
        }
      });
      return { ...state, selectedAgents: newAgentsList };
    }
    case 'CLEAR_SELECTED_AGENTS':
      return { ...state, selectedAgents: [] };
    case 'SET_SORT_BY':
      return { ...state, sortBy: action.payload };
    case 'SET_DESC_SORT':
      return { ...state, desc: !state.desc };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
    case 'CLEAR_SEARCH_TERM':
      return { ...state, searchTerm: '' };
    case 'CLEAR_AGENT_LIST': {
      return {
        ...state,
        agentBook: [],
        agentPage: [],
      };
    }
    case 'TOGGLE_AGENT_SIDEBAR':
      return {
        ...state,
        selectAgentsSidebarOpen: action.payload ? action.payload : !state.selectAgentsSidebarOpen,
      };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
