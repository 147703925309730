/* eslint-disable  */

import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
// import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';

class SOABadge extends React.PureComponent {
  render() {
    const {
      badgeType,
      soa,
      soaList,
      style,
      iconStyle,
      planType,
    } = this.props;

    let badge = null;

    // Agent has sent SOA
    if (soaList && soaList.length && planType) {
      const latestReply = soaList[0];
      let typeApproval =
        planType === 'PDP'
          ? latestReply.pdp
          : latestReply.mapd_ma;
      const textLabel = typeApproval
        ? `${planType === 'PDP' ? 'PDP' : 'MA/MAPD'} is SOA approved`
        : `${planType === 'PDP' ? 'PDP' : 'MA/MAPD'} is not SOA approved`;

       
      const badgeStyle = badgeType === 'icon' ?
        {
          minWidth: '14px',
          minHeight: '14px',
          borderRadius: '50%',
          cursor: 'help',
          lineHeight: '0',
        }
        : {
          paddingLeft: '18px',
          paddingRight: '18px',
          paddingTop: '4px',
          paddingBottom: '4px',
          marginLeft: '18px',
          borderRadius: '6px',
          fontSize: '14px',
          cursor: 'default',
        };

      badge = (
        <div
          className={`badge bg-${typeApproval ? 'green' : 'danger'}`}
          style={{
            display: 'flex',
            marginLeft: '4px',
            fontSize: '12x',
            fontWeight: '700',
            alignItems: 'center',
            userSelect: 'none',
            position: 'relative',
            padding: '0',
            ...badgeStyle,
            ...style,
          }}
          title={textLabel}
        >
          {
            badgeType === 'icon'
              ? <FontAwesomeIcon
                style={{
                  position: 'absolute',
                  /* x.x px values seem to work for centering */
                  width: '6.75px',
                  height: '7px',
                  left: '4px',
                  top: '3.5px',
                  ...iconStyle
                }}
                icon={typeApproval ? faCheck : faExclamation}
              />
              : textLabel
            }
        </div>
      );
    }

    return badge;
  }
}

SOABadge.defaultProps = {
  badgeType: 'icon',
  planType: '',
};

function mapStateToProps(store) {
  return {
    soa: store.theme.features.soa,
    soaList: store.shopper.soaList,
  };
}

export default connect(mapStateToProps)(SOABadge);
