import React from 'react';

class Tiers extends React.PureComponent {
  render() {
    const { doctor } = this.props;
    const tiers = [];
    if (doctor.group) {
      tiers.push(doctor.group);
    }
    if (doctor.ipa) {
      tiers.push(doctor.ipa);
    }
    if (doctor.hospital) {
      tiers.push(doctor.hospital);
    }

    return tiers.map((tier, i) => (
      <>
        <span className="text-muted">{tier}{(i + 1) === tiers.length ? null : ','}</span>
        <br />
      </>
    ));
  }
}


Tiers.defaultProps = {
  doctor: {},
};

export default Tiers;
