/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { SUBSIDY_OPTIONS_DEFAULT } from '../../constants';

// actions
import {
  getBEQData,
  createShopper,
  updateShopper,
  getCities,
  getCounties,
} from '../../actions/shopper';

// components
import { SlideoutModal, LoaderButton } from '../../common/components';

function RequiredStar() {
  return (
    <span className="text-danger ms-1">*</span>
  );
}

function InputRow({ children, label, id }) {
  return (
    <div className="mt-3">
      <label htmlFor={id} className="form-label">
        {label}
        <RequiredStar />
      </label>
      {children}
    </div>
  );
}

function DisplayInput({ value, label, col = '3', date }) {
  return (
    <div className={`col-md-${col}`}>
      <label className="form-label">{label}</label>
      <input
        type={date ? 'date' : undefined}
        className="form-control"
        value={value}
        disabled
      />
    </div>
  );
}

function Results() {
  const {
    lastBEQTime,
    data: { beq = {} } = {},
  } = useSelector(store => store.shopper);

  // if a query has been run previously then it has a lastBEQTime,
  // if it hasn't then this field will be falsey.
  return lastBEQTime ? (
    <>
      <div className="ms-3 row mb-2">
        <div className="d-flex col justify-content-center">
          <h5 className="fw-bold">
            Last query was run at
            <span className="text-primaryFocus ms-2">
              {moment(lastBEQTime).format('MM/DD/YYYY HH:mmA')}
            </span>
          </h5>
        </div>
      </div>

      {/*
      <div className="ms-3 row justify-content-center">
        <hr style={{ width: '50%' }} />
      </div>
      */}

      <div className="ms-3 row">
        <DisplayInput value={beq.firstName} label="First name" />
        <DisplayInput value={beq.middleName} label="Middle name" />
        <DisplayInput value={beq.lastName} label="Last name" />
        <DisplayInput value={beq.gender} label="Gender" />
      </div>
      <div className="ms-3 row mt-3">
        <DisplayInput value={beq.address} label="Address" col="6" />
        <DisplayInput value={beq.city} label="City" />
        <DisplayInput value={beq.state} label="State" />
      </div>
      <div className="ms-3 row mt-3">
        <DisplayInput value={beq.zipcode} label="Zipcode" />
        <DisplayInput value={beq.county} label="County FIPS" />
        <DisplayInput value={beq.subsidyPercent} label="Subsidy percent" />
        <DisplayInput value={beq.subsidyLevel} label="Subsidy level" />
      </div>
      <div className="ms-3 row mt-3">
        <DisplayInput value={beq.medicareID} label="Medicare ID" />
        <DisplayInput value={beq.dob} label="Date of birth" date />
        <DisplayInput value={beq.partAEffectiveDate} label="Part A Effective Date" date />
        <DisplayInput value={beq.partBEffectiveDate} label="Part B Effective Date" date />
      </div>
    </>
  ) : (
    <div className="ms-3 row">
      <div className="col">
        <h5 className="fw-bold">
          No query has been run yet
        </h5>
      </div>
    </div>
  );
}

function BEQModal() {
  const dispatch = useDispatch();
  const {
    effectiveDate,
    data: shopperDataJsonField = {},
    username: shopperUsername,
  } = useSelector(store => store.shopper);
  const { beqModal } = useSelector(store => store.modal);
  const { subsidyLevelOptions = SUBSIDY_OPTIONS_DEFAULT } = useSelector(store => store.theme);
  const [ mbi, setMbi ] = useState('');
  const [ dob, setDob ] = useState('');
  const [ gender, setGender ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);

  async function handleSubmit() {
    setLoading(true);
    setError(false);
    try {
      const data = await getBEQData({ mbi, dob, gender });
      const cities = await getCities(data.zipcode);
      const counties = await getCounties(data.zipcode);

      let city, countyName, subsidyQuestion;

      // check to see if one of the DR cities matches the city sent by humana BEQ api.
      // if one is found, set the city from the DR data.
      const cityObj = cities.find(c => (
        c.city.toLowerCase().replaceAll(' ', '') === data.city.toLowerCase().replaceAll(' ', '')
      ));
      if (cityObj) city = cityObj.city;

      // find the DR county that matches the county fips sent by the humana BEQ api.
      // set the county name from the DR data.
      const countyObj = counties.find(c => (
        c.county.toLowerCase() === data.county.toLowerCase()
      ));
      if (countyObj) countyName = countyObj.countyName;

      const effectiveDateYear = moment(effectiveDate).year();
      // find the subsidy level question based on subsidy level and subsidy percent
      const subsidyQuestionObj = subsidyLevelOptions[effectiveDateYear].find(s => (
        (s.percent === data.subsidyPercent) && (s.level === data.subsidyLevel)
      ));
      if (subsidyQuestionObj) subsidyQuestion = subsidyQuestionObj.value;

      const payload = {
        ...data,
        lastBEQTime: moment().toISOString(),
        city,
        countyName,
        subsidyQuestion,
        username: shopperUsername,
      };

      dispatch({ type: 'UPDATE_SHOPPER', payload });
      dispatch({ type: 'UPDATE_SHOPPER', payload: { cities, counties } });

      /* don't actually update or create the shopper in the api
      if (shopperUsername) {
        await updateShopper(payload, { updateShopperInfo: false });
      } else {
        const { username } = await createShopper(payload, { updateShopperInfo: false });
        dispatch({ type: 'UPDATE_SHOPPER', payload: { username } });
      }
      */
    } catch (err) {
      const { response: { data: { errors } = {} } = {} } = err;
      console.log(err);
      if (errors) {
        setError(errors[0]);
      } else {
        setError('We were not able to find any records associated with this information');
      }
    }

    setLoading(false);
  }

  function onClose() {
    dispatch({ type: 'HIDE_MODAL', payload: 'beqModal' });
    setMbi('');
    setDob('');
    setGender('');
    setDob('');
  }

  return (
    <SlideoutModal
      id="slideout-modal"
      isOpen={beqModal}
      closeModalAction={onClose}
      staticWidth="70%"
      slideInFrom="right"
      title="Beneficiary Eligibility Query"
    >
      <div className="ms-3 row">
        <div className="col-md-5">
          <InputRow label="MBI/HICN/RRB number" id="mbi-input">
            <input
              id="mbi-input"
              className="form-control"
              value={mbi}
              onChange={(e) => setMbi(e.target.value)}
            />
          </InputRow>

          <InputRow label="Date of birth" id="dob-input">
            <input
              type="date"
              id="mbi-input"
              className="form-control"
              value={dob}
              onChange={(e) => setDob(e.target.value)}
            />
          </InputRow>

          <InputRow label="Gender" id="gender-input">
            <select
              id="gender-input"
              className="form-select"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" disabled />
              <option value="unknown">Unknown</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </InputRow>
        </div>
        
        <div className="d-flex col-md-7 align-items-end justify-content-end">
          <div>
            <LoaderButton
              className="btn btn-outline-secondary me-3"
              text="Clear"
              disabled={loading}
              onClick={() => {
                setMbi('');
                setDob('');
                setGender('');
                setError('');
              }}
            />
            <LoaderButton
              className="btn btn-primary"
              text="Submit Inquiry"
              loadingText="Searching"
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>

      <div className="row text-danger justify-content-center mt-3">
        {error}
      </div>

      <hr className="mb-4" />

      <Results />
    </SlideoutModal>
  );
}


export default BEQModal;
