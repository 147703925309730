import React from 'react';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLocationDot } from '@fortawesome/pro-regular-svg-icons';

// componenets
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import { LoaderButton } from '../../../common/components';
import { SaveButton, Disposition } from '../components';
import EditModal from './EditModal';

const MEETING_TYPE_LABELS = {
  OnLine: 'Online',
  PhoneCall: 'Phone',
  InPerson_BeneficiaryLocation: 'At Beneficiary Location',
  InPerson_AgentOffice: 'At Agent Office',
};

function SOAInfo({ soaTimestamp, soaURL }) {
  return (
    <div className="mb-1">
      <b>SOA time:</b> {soaTimestamp ? DateTime.fromISO(soaTimestamp).toFormat('MMM D, h:mma') : '--'}
      &nbsp;&nbsp;|&nbsp;&nbsp;
      {soaURL ? (
        <a className="link-primary" rel="noreferrer" target="_blank" href={soaURL}>
          <u>View SOA</u>
        </a>
      ) : (
        <span className="text-muted">
          (No SOA Link)
        </span>
      )}
    </div>
  );
}

function MeetingLink({ meetingURL }) {
  return (
    <div className="mb-1">
      {meetingURL ? (
        <a className="link-primary" rel="noreferrer" target="_blank" href={meetingURL}>
          <u>Meeting Link</u>
        </a>
      ) : null}
    </div>
  );
}

function Time({ startTime, duration }) {
  return (
    <div className="mb-1">
      <b>
        <FontAwesomeIcon icon={faClock} className="me-2" />
      </b>
      {startTime ? DateTime.fromISO(startTime).toFormat('MMM D, h:mma ZZZZ') : '--'}
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <b>duration:</b> {duration || '--'}
    </div>
  );
}

function Location({ location }) {
  return (
    <div className="mb-1">
      <FontAwesomeIcon icon={faLocationDot} className="me-2" />
      {location || '--'}
    </div>
  );
}

/* eslint-disable no-unused-vars */
function AppointmentInfo() {
  const {
    data: shopperData = {},
    firstName,
  } = useSelector(store => store.shopper);
  const {
    appointment: {
      appointment_start_time: startTime,
      appointment_duration: duration = '00:00',
      appointment_type: type,
      appointment_location: location,
      appointment_meetingURL: meetingURL,
      SOA_timestamp: soaTimestamp,
      SOA_URL: soaURL,
    } = {},
  } = shopperData;

  const start = DateTime.fromISO(startTime || DateTime.now().toString());
  const calendarEntryTitle = `Medicare Appt w/ ${firstName}`;

  return type ? (
    <div className="border border-primaryFocusBorder shadow shadow-sm bg-primaryFocusBackground py-3 px-4">
      <div className="row">
        <div className="col">
          <h4>Appointment - <i>{type ? MEETING_TYPE_LABELS[type] : '--'}</i></h4>
        </div>
        <div className="d-flex col-md-2">
          <MeetingLink meetingURL={meetingURL} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Time startTime={startTime} duration={duration} />
          <Location location={location} />
          <SOAInfo soaTimestamp={soaTimestamp} soaURL={soaURL} />
        </div>

        <div className="d-flex col-md-4 justify-content-end align-items-end">
          <AddToCalendarButton
            name={calendarEntryTitle}
            options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
            location={location}
            startDate={start.toISODate()}
            endDate={start.toISODate()}
            startTime={start.toFormat('HH:mm')}
            endTime={start.plus({ hours: duration.slice(0, 2), minutes: duration.slice(3, 5) }).toFormat('HH:mm')}
            timezone={start.zoneName}
            trigger="click"
            label="Add to my Calendar"
            size="2"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="border border-grey-300 shadow shadow-sm bg-grey-100 py-3 px-4 h-100">
      <div className="row">
        <div className="col">
          <h4 className="text-muted"><i>No Appointment</i></h4>
        </div>
      </div>
    </div>
  );
}

function EditAppointmentInfo({
  showDisposition = true,
  showSaveButton = true,
  modalWidth,
}) {
  const { data: shopperData = {} } = useSelector(store => store.shopper);
  const { appointment: { appointment_type: type } = {} } = shopperData;
  const dispatch = useDispatch();
  return (
    <>
      <div className="row">
        <div className="col">
          <LoaderButton
            text={type ? 'Edit Appointment' : 'Create Appointment'}
            className={`btn ${type ? 'btn-outline-secondary' : 'btn-outline-primary'}`}
            onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'editAppointmentModal' })}
          />
        </div>
        {showSaveButton && (
          <div className="d-flex col justify-content-end">
            <SaveButton
              divClass="mt-0 gap-2"
              buttonClass="btn btn-primary mb-1"
              buttonStyle={{ width: '200px' }}
            />
          </div>
        )}
      </div>
      {showDisposition && (
        <div className="row">
          <Disposition cols="12" containerMargin="mt-0" />
        </div>
      )}
      <EditModal modalWidth={modalWidth} />
    </>
  );
}

export { AppointmentInfo, EditAppointmentInfo };
