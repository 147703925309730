import React from 'react';

function FormLabel({
  className,
  label,
  name,
  required,
}) {
  return (
    <label htmlFor={name} alt={label} className={['mb-2', className].join(' ')}>
      {label}
      {required && <span className="ms-1 fw-bold text-danger">*</span>}
    </label>
  );
}

export default FormLabel;
