/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export function pushTrackingEvent(eventName, agentUsername, shopperUsername) {
  const data = {
    event_name: eventName,
    agent_username: agentUsername,
    shopper_username: shopperUsername,
  };
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/utils/tracking/`,
    data,
  });
}
