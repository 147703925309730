/* eslint-disable import/prefer-default-export,react/jsx-props-no-spreading */
import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export function withRouterProps(Component) {
  return (props) => (
    <Component
      {...props}
      navigate={useNavigate()}
      location={useLocation()}
      params={useParams()}
    />
  );
}
