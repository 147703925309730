import React from 'react';

import { Radio } from '../components/inputs';

function Hospital({ pageTitle }) {
  return (
    <div>
      <div className="fw-bold">{pageTitle}</div>
      <div className="d-flex flex-column">
        <Radio name="hospital" id={1} labelClassName="fw-bold fs-4 text-secondary" />
      </div>
    </div>
  );
}

export default Hospital;
