import { store } from '../../store';
import { updateShopper, createShopper } from '../../actions/shopper';
import { showPopup } from '../../utils/popup';

export function isRequired({ name, schema, currentValues }) {
  const fieldSchema = schema[name] || {};
  if (fieldSchema.required) {
    return true;
  } else if (Array.isArray(fieldSchema.requiredConditions)) {
    let required = false;
    fieldSchema.requiredConditions.forEach(condition => {
      const [field, val] = condition.split('|');
      if (currentValues[field] === val) required = true;
    });
    return required;
  }
  return false;
}

// returns empty string if no errors, otherwise returns a message string
export function getFieldError({ name, schema, currentValues, newValue }) {
  let error = '';
  const value = newValue;
  const field = schema[name] || {};
  if (field.validationRegex) {
    const re = new RegExp(field.validationRegex);
    // re.test(val) will convert val to a string (e.g. if val is undefined
    // it will cast val to a string 'undefined'). If value is undefined
    // we should test an empty string.
    let testVal = '';
    if (value !== undefined) {
      testVal = value;
    }
    const isValid = re.test(testVal);
    if (!isValid) {
      if (field.validationError) {
        error = field.validationError;
      } else {
        error = 'Invalid value';
      }
    }
  }

  if (isRequired({ name, schema, currentValues })) {
    if (value === '' || value === undefined) {
      error = 'This field is required';
    }
  }

  return error;
}

export function getFormErrors({ schema, currentValues }) {
  const errors = {};
  Object.keys(schema).forEach(fieldName => {
    const { required } = schema[fieldName];
    const value = currentValues[fieldName];
    const msg = getFieldError({ name: fieldName, schema, currentValues, newValue: value });
    if (msg && required) errors[fieldName] = msg;
    if (msg && !required && (value !== '' && value !== undefined && value !== null)) errors[fieldName] = msg;
  });
  return errors;
}

export function isFieldValid({ name, schema, currentValues, validation }) {
  // if field has not been validated or is set to valid. Validation
  // status is kept in store.shopper.validation
  if (validation[name] === undefined || validation[name]) {
    return true;
  }

  const errMsg = getFieldError({ name, schema, currentValues });
  if (errMsg) {
    return false;
  }

  return true;
}

export function getValidClass({ name, schema, currentValues, validation, required, valid }) {
  let className = 'is-invalid';
  const value = currentValues[name];
  if (isFieldValid({ name, schema, currentValues, validation })) {
    className = '';
  }
  if (!required && (value === undefined && value === null && value === '') && (valid !== undefined)) {
    className = '';
  }
  return className;
}

export function validateField({ name, schema, currentValues, newValue, required }) {
  const errMsg = getFieldError({ name, schema, currentValues, newValue });
  // update store.shopper.validation
  if (required && (newValue !== undefined)) {
    store.dispatch({ type: 'UPDATE_SHOPPER_VALIDATION', payload: { [name]: !errMsg } });
    store.dispatch({ type: 'UPDATE_SHOPPER_ERROR', payload: { [name]: errMsg } });
  } else if (!required && (newValue === undefined || newValue === null || newValue === '')) {
    store.dispatch({ type: 'UPDATE_SHOPPER_VALIDATION', payload: { [name]: undefined } });
    store.dispatch({ type: 'UPDATE_SHOPPER_ERROR', payload: { [name]: '' } });
  } else if (!required) {
    store.dispatch({ type: 'UPDATE_SHOPPER_VALIDATION', payload: { [name]: !errMsg } });
    store.dispatch({ type: 'UPDATE_SHOPPER_ERROR', payload: { [name]: errMsg } });
  }
}

// setLoading arg is optional. username and shopper should be from store.shopper.username
// and store.shopper respectively.
export function handleSubmit(shopper, dispatch = () => {}, setLoading = () => {}) {
  return new Promise((resolve, reject) => {
    setLoading(true);
    const { schema, username } = shopper;
    const errors = getFormErrors({ schema, currentValues: shopper });
    if (Object.keys(errors).length) {
      dispatch({ type: 'UPDATE_SHOPPER', payload: { errors } });
      setLoading(false);
      reject();
    } else if (username) {
      updateShopper(shopper)
        .then(() => {
          showPopup('Success', 'Updated shopper!');
          setLoading(false);
          resolve();
        }).catch(() => {
          setLoading(false);
          reject();
        });
    } else {
      createShopper(shopper)
        .then((data) => {
          showPopup('Success', 'Created shopper!');
          setLoading(false);
          resolve(data);
        }).catch(() => {
          setLoading(false);
          reject();
        });
    }
  });
}
