import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';

// actions
import { getCalls } from '../../actions/call';

// components
import NavPage from '../nav/NavPage';
import LoaderComponent from '../common/LoaderComponent';
import OpenTelephony from '../common/OpenTelephony';
import { CallIcons } from './components';

function TableRow({ call }) {
  const date = moment(call.call_start).format('MM-DD-YYYY');
  const start = moment.tz(call.call_start, moment.tz.guess());
  const end = moment.tz(call.call_end, moment.tz.guess());

  return (
    <tr>
      <td>{date}</td>
      <td>{start.format('hh:mm A z')}</td>
      <td>{call.call_end ? end.format('hh:mm A z') : 'Call in progress'}</td>
      <td>
        <CallIcons call={call} />
      </td>
    </tr>
  );
}

function CallsTable({ calls }) {
  const callsExist = Boolean(calls.length);

  return callsExist ? (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Start time</th>
          <th>End time</th>
          <th>Call recording</th>
        </tr>
      </thead>

      <tbody>
        {calls.map(call => (
          <TableRow key={call.contact_id} call={call} />
        ))}
      </tbody>
    </table>
  ) : (
    <i>There are currently no calls associated with this record</i>
  );
}

function CallsPage() {
  const dispatch = useDispatch();
  const { calls } = useSelector(store => store.call);
  const { username: shopperUsername } = useSelector(store => store.shopper);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCalls(shopperUsername)
      .then((data) => {
        dispatch({ type: 'SET_CALL_RECORDS', calls: data });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <NavPage
      activeLink="/calls"
      showShopperInfobar
    >
      <div className="row pt-4 pb-5">
        <div className="col-12 ms-4 fw-bold fs-3">
          Call Records
        </div>

        <div className="col-md-6 ms-4 mb-2">
          <OpenTelephony />
        </div>
        <div className="col-md-6 w-100" />

        <div className="col-12 mt-3 mx-4">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CallsTable calls={calls} />
          )}
        </div>
      </div>
    </NavPage>
  );
}

export default CallsPage;
