/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';

import Checkbox from './Checkbox';
import { toCurrency, prettifyPhone } from '../../utils/prettify';

function PageOne({
  enrollment: {
    fields = {},
    submit_date,
  },
  agent = {},
}) {
  const {
    plan = {},

    first_name,
    middle_name,
    last_name,
    effective_date,
    birthdate,
    gender,
    mobile_phone,
    home_phone,
    street1,
    city,
    county_name,
    state,
    zipcode,

    medicare_id,

    othercoverage,
    othercoverage_group,
    othercoverage_name,
    othercoverage_num,

    esign_signature,
    poa_fname,
    poa_lname,
    poa_tel,
    poa_rel,

    carrier_agent_id,
  } = fields;

  const {
    IsSpecialNeedPlan,
    PartD_Suppressed,
    PlanTypeDesc,
    PlanName,
    TotalPlanPremium,
  } = plan;

  // Agent is coming from a parent's redux store and is not snake cased
  const {
    firstName: agent_fname,
    lastName: agent_lname,
    email: agent_email,
    npn: agent_npn,
  } = agent;

  function fmtMedicareID(id = '') {
    return (
      <span>
        {[...id.substring(0, 4)].map(c => (<span key={c} className="me-1 text-decoration-underline">{c}</span>))}
        <span className="me-1">-</span>
        {[...id.substring(4, 7)].map(c => (<span key={c} className="me-1 text-decoration-underline">{c}</span>))}
        <span className="me-1">-</span>
        {[...id.substring(7, 11)].map(c => (<span key={c} className="me-1 text-decoration-underline">{c}</span>))}
      </span>
    );
  }

  function ConditionalQuestions() {
    return (
      <>
        <div className="d-flex justify-content-center fw-bold border-bottom ps-1 bg-grey-300">
          Answer these important questions:
        </div>
        {PlanTypeDesc !== 'MA' && (
          <div className="d-flex flex-column border-bottom ps-1">
            <div className="d-flex">
              <div className="">
                Will you have other prescription drug coverage (like VA, TRICARE) in addition to {PlanName}?
              </div>
              <div className="d-flex w-25 mt-0 mb-auto align-items-center">
                <Checkbox value={othercoverage === true} />
                <span className="me-1">Yes</span>
                <Checkbox value={othercoverage === false} />
                <span className="me-1">No</span>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex flex-column w-100">
                <div className="">Name of other coverage:</div>
                <div className="">{othercoverage_name}</div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="">Member number for this coverage:</div>
                <div className="">{othercoverage_num}</div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="">Group number for this coverage:</div>
                <div className="">{othercoverage_group}</div>
              </div>
            </div>
            {IsSpecialNeedPlan && (
              <div className="d-flex">
                {/* (SNP/Insulin questions? can't find anything atm) */}
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  function MAMAPDDisclaimer() {
    return (
      <ul className="m-0 ps-4 border-bottom">
        {PlanTypeDesc === 'MA' && (
          <li className="">
            I must keep both Hospital (Part A) and Medical (Part B) to stay in {PlanName}.
          </li>
        )}
        <li className="">
          By joining this Medicare Advantage, I acknowledge that {PlanName} will share my information with Medicare, who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that authorize the collection of this information (see Privacy Act Statement below). Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
        </li>
        <li className="">
          I understand that I can be enrolled in only one MA plan at a time - and that enrollment in this plan will automatically end my enrollment in another MA plan (exceptions apply for MA PFFS, MA MSA plans).
        </li>
        {PlanTypeDesc === 'MA' && (
          <li className="">
            I understand that when my {PlanName} coverage begins, I must get all of my medical and prescription drug benefits from {PlanName}. Benefits and services provided by {PlanName} and contained in my {PlanName} “Evidence of Coverage” document (also known as a member contract or subscriber agreement) will be covered. Neither Medicare nor {PlanName} will pay for benefits or services that are not covered.
          </li>
        )}
        <li className="">
          The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false information on this form, I will be disenrolled from the plan
        </li>
        <li className="">
          I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means that I have read and understand the contents of this application. If signed by an authorized representative (as described above), this signature certifies that:
          <div className="d-flex flex-column">
            <div className="">1) This person is authorized under State law to complete this enrollment, and</div>
            <div className="">2) Documentation of this authority is available upon request by Medicare.</div>
          </div>
        </li>
      </ul>
    );
  }

  function PDPDisclaimer() {
    return (
      <ul className="m-0 ps-4 border-bottom">
        {!PartD_Suppressed && (
          <li className="">
            I must keep both Hospital (Part A) or Medical (Part B) to stay in {PlanName}.
          </li>
        )}
        <li className="">
          By joining this Medicare Prescription Drug Plan, I acknowledge that {PlanName} will share my information with Medicare, who may use it to track my enrollment, to make payments, and for other purposes allowed by Federal law that authorize the collection of this information (see Privacy Act Statement below). Your response to this form is voluntary. However, failure to respond may affect enrollment in the plan.
        </li>
        <li className="">
          I understand that I can be enrolled in only one Part D plan at a time - and that enrollment in this plan will automatically end my enrollment in another Part D plan.
        </li>
        <li className="">
          The information on this enrollment form is correct to the best of my knowledge. I understand that if I intentionally provide false information on this form, I will be disenrolled from the plan.
        </li>
        <li className="">
          I understand that my signature (or the signature of the person legally authorized to act on my behalf) on this application means that I have read and understand the contents of this application. If signed by an authorized representative (as described above), this signature certifies that:
          <div className="d-flex flex-column">
            <div className="">1) This person is authorized under State law to complete this enrollment, and</div>
            <div className="">2) Documentation of this authority is available upon request by Medicare.</div>
          </div>
        </li>
      </ul>
    );
  }

  function Signatures() {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className="d-flex w-50 border-end ps-1">
            <div className="fw-bold me-1">Signature:</div>
            {esign_signature}
          </div>
          <div className="d-flex w-50 ps-1">
            <div className="fw-bold me-1">
              Today's date:
            </div>
            {moment(submit_date).format('MM/DD/YYYY')}
          </div>
        </div>
        <div className="bg-grey-300 border-bottom border-top ps-1">
          If you're the authorized representative, sign above and fill out these fields:
        </div>
        <div className="d-flex border-bottom">
          <div className="w-50 border-end p-1 text-capitalize">Name: {poa_fname} {poa_lname}</div>
          <div className="w-50 p-1">Today's date: {poa_fname ? moment(submit_date).format('MM/DD/YYYY') : null}</div>
        </div>
        <div className="d-flex">
          <div className="w-50 border-end p-1">Phone number: {prettifyPhone(poa_tel)}</div>
          <div className="w-50 p-1">Relationship to enrollee: <span className="text-capitalize">{poa_rel}</span></div>
        </div>
      </div>
    );
  }

  function AgentInformation() {
    return (
      <div className="d-flex flex-column">
        <div className="bg-grey-300 border-bottom border-top ps-1">
          Please fill out these fields if you are a Medicare agent:
        </div>
        <div className="d-flex border-bottom">
          <div className="w-50 border-end p-1 text-capitalize">Name: {agent_fname} {agent_lname}</div>
          <div className="w-50 p-1">Writing ID: {carrier_agent_id}</div>
        </div>
        <div className="d-flex">
          <div className="w-50 p-1 border-end">Email: {agent_email}</div>
          <div className="w-50 p-1">NPN: <span className="text-capitalize">{agent_npn}</span></div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column w-100 border">
      <div className="d-flex justify-content-center fw-bold border-bottom p-1 ps-1 fs-4 bg-grey-300 sans-serif">
        Section 1 - All fields on this page are required (unless marked optional)
      </div>
      <div className="d-flex flex-column border-bottom ps-1">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className="fw-bold">Select the plan you want to join:</div>
            <div className="d-flex">
              <Checkbox value />
              <span className="me-1">{PlanName}</span>
              -
              <span className="ms-1">{toCurrency(TotalPlanPremium)} per month</span>
            </div>
          </div>
          <div className="me-1 mb-0 ms-auto mt-auto">
            Requested Eff. Date: {moment(effective_date).format('MM/DD/YYYY')}
          </div>
        </div>
      </div>
      <div className="d-flex border-bottom ps-1">
        <div className="w-100">FIRST name: {first_name}</div>
        <div className="w-100">LAST name: {last_name}</div>
        <div className="w-100">[Optional: Middle Initial]: {middle_name}</div>
      </div>
      <div className="d-flex border-bottom ps-1">
        <div className="d-flex flex-column w-100 border-end">
          <div className="">Birth date: (MM/DD/YYYY)</div>
          <div className="">{moment(birthdate).format('MM/DD/YYYY')}</div>
        </div>
        <div className="d-flex flex-column w-50 border-end ps-1">
          <div className="">Sex:</div>
          <div className="d-flex">
            <Checkbox value={gender === 'male'} />
            Male
            <Checkbox value={gender === 'female'} />
            Female
          </div>
        </div>
        <div className="d-flex flex-column w-100 ps-1">
          <div className="">Phone number:</div>
          <div className="">{prettifyPhone(mobile_phone || home_phone)}</div>
        </div>
      </div>
      <div className="d-flex border-bottom ps-1">
        Permanent Residence street address (Don’t enter a PO Box): <span className="text-capitalize">{street1}</span>
      </div>
      <div className="d-flex border-bottom ps-1">
        <div className="d-flex w-100 border-end">
          City: {city}
        </div>
        <div className="d-flex w-75 border-end ps-1">
          [Optional: County]: <span className="text-capitalize ms-1">{county_name}</span>
        </div>
        <div className="d-flex w-25 border-end ps-1">
          State: {state}
        </div>
        <div className="d-flex w-50 ps-1">
          ZIP Code: {zipcode}
        </div>
      </div>
      {/* ---------------------------------------- */}
      <div className="d-flex justify-content-center fw-bold border-bottom bg-grey-300">
        Your Medicare information:
      </div>
      <div className="d-flex border-bottom fw-bold position-relative">
        <div className="position-absolute ps-1">Medicare Number:</div>
        <div className="ms-auto me-auto">{fmtMedicareID(medicare_id)}</div>
      </div>
      {/* ---------------------------------------- */}
      <ConditionalQuestions />
      {/* ---------------------------------------- */}
      <div className="d-flex justify-content-center fw-bold border-bottom bg-grey-300">
        IMPORTANT: Read and sign below:
      </div>
      <div className="d-flex flex-column">
        {PlanTypeDesc === 'PDP' ? <PDPDisclaimer /> : <MAMAPDDisclaimer />}
        <Signatures />
      </div>
      {/* ---------------------------------------- */}
      <AgentInformation />
    </div>
  );
}
export default PageOne;
