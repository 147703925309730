/* eslint-disable */
export const medicareDisclaimer = `
<p class="fw-bold">Medicare Disclaimers<p>

<p>This service is searching for Humana Medicare Providers.</p>

<p>Many doctors have more than one office. However, not all of the doctors' offices may be in the plan's network. If you don't see an office address on this list, the doctor may not be in-network at that location. If you have questions, contact the plan's customer service department.</p>

<p>If searching for an organ or stem cell transplant provider, please contact us for more information.</p>

<p>Please note, any information listed under a provider's practice focus has been supplied by the provider and has not been validated.</p>

<p>This information is not a complete description of benefits. Contact the plan for more information. Limitations, copayments and restrictions may apply. Benefits, premiums and/or member cost-share may change on January 1 of each year.</p>

<p>The pharmacy network and provider network may change at any time.</p>

<p>With the exception of emergencies and urgent care situations, it may cost more for covered services received outside the network and out-of-network/non-contracted providers are under no obligation to treat members. Sometimes the selection of in-network providers is limited in certain geographic areas or in some specialties. If the network in your area doesn't offer the specialist you need, you may be allowed to go to a non-network provider at the in-network rate.</p>

<p>For a decision about whether we will cover an out-of-network service, we encourage you or your provider to ask us for a pre-service organization determination before you receive the service.</p>

<p>Please call our customer service number or see your Evidence of Coverage for more information, including the cost-sharing that applies to out-of-network services.</p>
`;
