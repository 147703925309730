import React from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';

import {
  EnrollmentStatus,
  VoiceSOAIndicator,
  CallIcons,
} from '../calls/components';
import EnrollmentExport from '../quotes/components/EnrollmentExport';

function TableRow({ call = {} }) {
  const start = call.call_start ? moment.tz(call.call_start, moment.tz.guess()) : null;
  const end = call.call_end ? moment.tz(call.call_end, moment.tz.guess()) : null;
  let duration = '--';
  if (start && end) {
    duration = moment.duration(end.diff(start)).humanize();
  }

  return (
    <tr>
      <td><FontAwesomeIcon size="sm" icon={faPhoneAlt} color="var(--bs-grey)" /></td>
      <td>{call.effective_date || '--'}</td>
      <td>{start.format('MM/DD/YYYY at hh:mm A z')}</td>
      <td>{duration}</td>
      <td>
        <CallIcons call={call} dashboard />
      </td>
      <td><VoiceSOAIndicator soas={call.voice_soas} /></td>
      <td>{call.plan_carrier || '--'}</td>
      <td><EnrollmentStatus status={call.enrollment_status} /></td>
      <td><EnrollmentExport quoteID={call.quote_id} disabled={!call.enrollment_status} /></td>
    </tr>
  );
}

function CallsTable({ calls }) {
  return (
    <table
      className="table dashboard-calls-table"
      style={{ borderCollapse: 'initial' }}
    >
      <thead>
        <tr>
          <th />
          <th>Effective date</th>
          <th>Start</th>
          <th>Duration</th>
          <th />
          <th>Voice SOA</th>
          <th>Enrollment carrier</th>
          <th>Enrollment status</th>
          <th>Enrollment details</th>
        </tr>
      </thead>

      <tbody>
        {calls.map(call => (
          <TableRow key={call.contact_id} call={call} />
        ))}
      </tbody>
    </table>
  );
}

function CallsDropdown({
  shopper: { calls },
}) {
  return (
    <tr>
      <td colSpan="12">
        <CallsTable calls={calls} />
      </td>
    </tr>
  );
}

export default CallsDropdown;
