import React from 'react';

const MmbCheckbox = ({
  value,
  checkedValue = true,
  controlId,
  name,
  label,
  onChange,
  disabled = false,
  className,
  checked = false,
  required = false,
  invalid = false,
  error = false,
}) => (
  <div className={`mb-2 ${className}`}>
    <input
      id={controlId}
      name={name}
      disabled={disabled}
      type="checkbox"
      onChange={onChange}
      className="form-check-input"
      value={checkedValue}
      checked={checked || value === checkedValue}
    />
    <label htmlFor={controlId} className="form-label">&nbsp; {label} {required && <span className="text-danger ps-1">*</span>} </label>
    {invalid && error && <div className="d-block invalid-feedback">{error}</div>}
  </div>
);


export default MmbCheckbox;
