import React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { handleChange, getFieldKey } from '../../../../utils/hra';

import FormLabel from './FormLabel';

// lifted from enrollment-portal (common candidate?)
// https://github.com/mymedicarebot/enrollment-portal/blob/dev/src/views/enroll/components/inputs/Checkbox.jsx
function Checkbox({
  cols = '12',
  className,
  label,
  labelClassName,
  name,
  unsetFieldsOnTrue = [],
  unsetFieldsOnFalse = [],
  onChangeCallback = () => {},
}) {
  const { fields, form, submittedFlag } = useSelector(store => store.hra);
  const customLabel = getFieldKey(form, name, 'label');
  let value = fields[name];
  // We want to mimic a plain checkbox, but HRA falsey is just an empty string.
  if (value === '') value = false;

  return (
    <div className={`col-md-${cols} ${className}`}>
      <div className="form-check cursor-pointer user-select-none">
        <input
          type="checkbox"
          checked={value}
          className="form-check-input"
          id={name}
          onChange={(e) => handleChange({
            value: e.target.checked,
            name,
            unsetFieldsOnTrue,
            unsetFieldsOnFalse,
            onChangeCallback,
          })}
          value={value}
          disabled={submittedFlag}
        />
        <FormLabel className={`form-check-label cursor-pointer ${labelClassName}`} name={name} label={label || customLabel} />
      </div>
    </div>
  );
}

export default Checkbox;
