/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../utils/hocs';

// Actions + Utils
import { getParam } from '../../utils/qs';

// Compoments
import NavPage from '../nav/NavPage';
import FooterNav from '../nav/footernav';
import SendTab from './send';
import HistoryTab from './history';

class SOA extends React.PureComponent {
  constructor(props) {
    super(props);
    const tab = getParam('tab');

    if (tab === 'history') {
      props.dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'history' });
    } else if (tab === 'send') {
      props.dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'send' });
    } else if (props.soaList && props.soaList.length > 0) {
      props.dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'history' });
    } else {
      props.dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'send' });
    }
  }

  tabClass = (tabName) => {
    const { tab } = this.props;
    return tab === tabName ? 'mmb-tab-selected' : '';
  };

  render() {
    const {
      dispatch,
      navigate,
      soaList,
      tab,
    } = this.props;
    const historyCount = soaList.length;
    return (
      <NavPage
        activeLink="/soa"
        showShopperInfobar
      >
        <div className="soa">
          <div className="mmb-header">
            Scope of Appointment
          </div>
          <div className="mmb-tab-container">
            <div
              role="button"
              tabIndex={0}
              className={`mmb-tab ${this.tabClass('send')}`}
              onClick={() => dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'send' })}
            >
              Create SOA
            </div>
            <div
              role="button"
              tabIndex={0}
              className={`mmb-tab ${this.tabClass('history')}`}
              onClick={() => dispatch({ type: 'SET_SOA_MODAL_TAB', payload: 'history' })}
            >
              History
              <span className="mmb-tab-count">
                {historyCount}
              </span>
            </div>
          </div>
          <div className="soa-body">
            {
              tab === 'send'
                ? <SendTab />
                : <HistoryTab />
            }
          </div>
          <FooterNav
            planOnClick={() => navigate('/plans/list')}
            nextOnClick={() => navigate('/rx')}
          />
        </div>
      </NavPage>
    );
  }
}

function mapStateToProps(store) {
  return {
    sendSoaModal: store.modal.sendSoaModal,
    tab: store.display.soaModalTab,
    soaList: store.shopper.soaList,
  };
}

export default connect(mapStateToProps)(withRouterProps(SOA));
