/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

function ModalHeader({
  closeModalAction,
  closeIcon,
  headerClasses,
  h2Classes,
  title,
}) {
  return (
    <div className={`p-3 ${headerClasses}`}>
      <h4 className={h2Classes}>
        {title}
        {closeIcon
          ? (
            <FontAwesomeIcon
              role="button"
              className="float-end"
              tabIndex={0}
              icon={faTimes}
              onClick={closeModalAction}
            />
          ) : null}
      </h4>
    </div>
  );
}

// NOTE(santeyio): this should replace the old close icon
function FloatingCloseIcon({ show, closeModalAction }) {
  return show && (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 9999,
        }}
      >
        <FontAwesomeIcon
          role="button"
          size="xl"
          tabIndex={0}
          icon={faTimes}
          onClick={closeModalAction}
        />
      </div>
    </div>
  );
}

function ModalBase({
  allowOverlayClickClose = false,
  children,
  closeModalAction = () => {},
  closeIcon = true,
  floatingCloseIcon = false,
  headerClasses = '',
  h2Classes = 'fw-bold mb-0',
  hideHeader = false,
  id,
  isOpen = false,
  // modalClasses = '',
  padding,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  style,
  title,
  width = '500px',
  staticWidth = undefined,
  staticHeight = undefined,
  zIndex,
}) {
  return (
    <div
      id={id}
      onClick={allowOverlayClickClose ? closeModalAction : () => {}}
      style={{
        opacity: isOpen ? '1' : '0',
        visibility: isOpen ? 'visible' : 'hidden',
        position: 'fixed',
        overflowY: 'auto',
        width: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: zIndex || 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.5s ease-out',
      }}
    >
      <div
        style={{
          display: 'block',
          background: 'white',
          width: staticWidth,
          maxWidth: (staticWidth ? undefined : width),
          height: staticHeight,
          maxHeight: (staticHeight ? undefined : '100%'),
          overflow: 'auto',
          ...style,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <FloatingCloseIcon show={floatingCloseIcon} closeModalAction={closeModalAction} />
        <div
          style={{
            paddingTop: paddingTop || padding || '10px',
            paddingBottom: paddingBottom || padding || '10px',
            paddingRight: paddingRight || padding || '20px',
            paddingLeft: paddingLeft || padding || '20px',
          }}
        >
          {!hideHeader && (
            <ModalHeader
              closeModalAction={closeModalAction}
              closeIcon={closeIcon}
              headerClasses={headerClasses}
              h2Classes={h2Classes}
              title={title}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

export default ModalBase;
