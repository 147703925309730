import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCheck } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import useOutsideClick from '../../hooks/useOutsideClick';

const MmbDropdown = ({
  className,
  value,
  options = [],
  optionKey = 'val',
  optionText = 'text',
  placeholder,
  header,
  onChange,
  onClick,
  disabled,
  leftCaret = false,
}) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setShow);

  const selectedText = () => {
    const selectedObj = options.find((el) => el[optionKey] === value);
    return selectedObj ? selectedObj[optionText] : (placeholder || 'Select');
  };

  const onHandleClick = (v, option) => {
    setShow(false);
    if (onClick) onClick(v, option);
    if (v !== value) onChange(v, option);
  };

  return (
    <div className={`dropdown${leftCaret ? ' dropdown-2' : ''} ${show ? ' show' : ''} ${className || ''}`} ref={wrapperRef}>
      <button
        className="btn border-grey dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setShow(!show)}
        disabled={disabled}
        title={selectedText()}
      >
        {selectedText()}
        <FontAwesomeIcon icon={leftCaret ? faCaretDown : faAngleDown} className="arrow-carret" size="lg" />
      </button>
      <div className={`dropdown-menu${show ? ' show' : ''}`}>
        {header && <h6 className="dropdown-header">{header}</h6>}
        {options.map((option) => (
          <button
            className={`dropdown-item${option[optionKey] === value ? ' active' : ''}`}
            type="button"
            onClick={() => onHandleClick(option[optionKey], option)}
            key={option[optionKey]}
            disabled={disabled}
            title={option[optionText]}
          >
            {leftCaret && option[optionKey] === value && <FontAwesomeIcon icon={faCheck} className="arrow-checked" />}
            {option[optionText]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MmbDropdown;
