/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import TextInput from './TextInput';

// actions
import { getCounties, getCities } from '../../../../actions/shopper';
import { validateField } from '../../utils';

function Zipcode({ disabled }) {
  const dispatch = useDispatch();
  const { shopper } = useSelector(store => store);
  const { zipcode } = shopper;

  async function handleChange(e) {
    const { value } = e.target;
    dispatch({ type: 'UPDATE_SHOPPER', payload: { zipcode: value } });
    // run validation before we get counties and cities so there isn't
    // a delay in validation getting run. It gets run again by defaultHandleChange
    // in the TextInput, but this is here to prevent the getCounties and getCities
    // to block the validation running.
    validateField({
      name: 'zipcode',
      schema: shopper.schema,
      currentValues: shopper,
      newValue: value,
      required: true,
    });
    if (value.length === 5) {
      try {
        dispatch({ type: 'SET_LOADER', payload: { zipLoading: true } });
        const counties = await getCounties(value);
        const cities = await getCities(value);
        dispatch({ type: 'UPDATE_SHOPPER', payload: { counties, cities } });
        dispatch({ type: 'SET_LOADER', payload: { zipLoading: false } });
      } catch (err) {
        // clear out counties/cities again so city/county's useEffects fires to clear loading message
        dispatch({ type: 'UPDATE_SHOPPER', payload: { counties: [], cities: [] } });
        dispatch({ type: 'SET_LOADER', payload: { zipLoading: false } });
        console.log(err);
      }
    } else {
      dispatch({
        type: 'UPDATE_SHOPPER',
        payload: {
          cities: [],
          counties: [],
          county: '',
          countyName: '',
          city: '',
        },
      });
    }
    return value;
  }

  useEffect(() => {
    if (zipcode.length === 5) {
      handleChange({ target: { value: zipcode } });
    }
  }, []);

  return (
    <TextInput
      name="zipcode"
      label="Zipcode"
      handleChange={handleChange}
      disabled={disabled}
      maxLength={5}
    />
  );
}

export default Zipcode;
