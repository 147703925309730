/* eslint-disable react/no-unknown-property */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { updateNotification } from '../../../actions/notifications';

// Components
import StandardModal from '../../common/StandardModal';

// Utilities
import { showPopup } from '../../../utils/popup';

class EditNotificationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      endDate: '',
      time: '',
      interval: 'no-repeat',
      intervalDates: {
        Sun: false,
        Mon: false,
        Tues: false,
        Wed: false,
        Thurs: false,
        Fri: false,
        Sat: false,
      },
    };
    this.close = this.close.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedNotification,
    } = this.props;

    if (prevProps.selectedNotification !== selectedNotification) {
      const startNotification = new Date(selectedNotification.start_date);
      const year = (startNotification.getFullYear());
      const month = (startNotification.getMonth() + 1);
      const date = (startNotification.getDate());
      const startDate = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;

      const endNotification = new Date(selectedNotification.end_date);
      const eYear = (endNotification.getFullYear());
      const eMonth = (endNotification.getMonth() + 1);
      const eDate = (endNotification.getDate());
      const endDate = `${eYear}-${eMonth < 10 ? `0${eMonth}` : eMonth}-${eDate < 10 ? `0${eDate}` : eDate}`;

      const hours = startNotification.getHours();
      const minutes = startNotification.getMinutes();
      const startTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;

      this.setState({
        date: startDate,
        endDate: selectedNotification.end_date ? endDate : '',
        time: startTime,
        interval: selectedNotification.interval,
        intervalDates: {
          Sun: selectedNotification.days.sunday,
          Mon: selectedNotification.days.monday,
          Tues: selectedNotification.days.tuesday,
          Wed: selectedNotification.days.wednesday,
          Thurs: selectedNotification.days.thursday,
          Fri: selectedNotification.days.friday,
          Sat: selectedNotification.days.saturday,
        },
      });
    }
  }

  close() {
    const { dispatch } = this.props;
    dispatch({ type: 'HIDE_MODAL', payload: 'editNotificationModal' });
  }

  render() {
    const {
      editNotificationModal,
      selectedNotification,
    } = this.props;

    const {
      date,
      endDate,
      interval,
      intervalDates,
      time,
    } = this.state;

    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

    return (
      <StandardModal
        isOpen={editNotificationModal}
        closeModalAction={this.close}
        title="Edit Notification"
        size="md"
      >
        <div className="px-3 pb-3">
          {selectedNotification.shopper
            ? (
              <div className="fw-bold mb-3">
                Shopper: {selectedNotification.shopper_name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')}
              </div>
            ) : null}
          <div className="align-items-center input-group">
            <label
              className="fw-bold"
              htmlFor="date"
            >
              Date:
            </label>
            <input
              className="mb-1 ms-2 form-control"
              name="date"
              onChange={(event) => this.setState({ date: event.target.value })}
              type="date"
              value={date}
            />
          </div>
          <div className="align-items-center input-group">
            <label
              className="fw-bold"
              htmlFor="time"
            >
              Time:
            </label>
            <input
              className="mb-1 ms-2 form-control"
              name="time"
              onChange={(event) => this.setState({ time: event.target.value })}
              type="time"
              value={time}
            />
          </div>
          <div className="align-items-center input-group">
            <label
              className="fw-bold"
              htmlFor="interval"
            >
              Interval:
            </label>
            <select
              className="mb-1 ms-2 form-control"
              name="interval"
              onChange={(event) => this.setState({ interval: event.target.value })}
              style={{ maxWidth: 'max-content' }}
              type="select"
              value={interval}
            >
              <option value="no-repeat">No Repeat Notifications</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly on {moment(date).format('ddd')}</option>
              <option value="weekdays">Every Weekday</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          {interval === 'custom'
            ? (
              <>
                <hr />
                <div className="align-items-center mb-2 input-group">
                  <label
                    className="fw-bold"
                    htmlFor="endDate"
                  >
                    End Date:
                  </label>
                  <input
                    className="ms-2"
                    onChange={(event) => this.setState({ endDate: event.target.value })}
                    name="endDate"
                    type="date"
                    value={endDate}
                  />
                </div>
                <div className="fw-bold mb-1">Custom Recurrence:</div>
                <div className="text-center">
                  {
                    days.map((dayOption) => (
                      <button
                        className="me-1 btn btn-primary"
                        onClick={() => {
                          this.setState((prevState) => ({
                            ...prevState,
                            intervalDates: {
                              ...prevState.intervalDates,
                              [dayOption]: !prevState.intervalDates[dayOption],
                            },
                          }));
                        }}
                        outline={!intervalDates[dayOption]}
                        type="button"
                        value={dayOption}
                      />
                    ))
                  }
                </div>
              </>
            ) : null}
        </div>
        <div className="px-3 pb-3 text-right">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (interval === 'custom') {
                if (Object.values(intervalDates).filter((d) => d).length === 0) {
                  showPopup('Error', 'Please select recurrence date(s).');
                  return;
                }

                if (!endDate) {
                  showPopup('Error', 'Please enter end date.');
                  return;
                }

                if (endDate < date) {
                  showPopup('Error', 'Please choose an end date after the start date.');
                  return;
                }
              }

              /* eslint-disable object-shorthand */
              const notificationObject = {
                start_date: (new Date(`${date} ${time}`).toISOString()),
                interval: interval,
                end_date: endDate ? (new Date(`${endDate} ${time}`).toISOString()) : null,
                days: {
                  sunday: intervalDates.Sun,
                  monday: intervalDates.Mon,
                  tuesday: intervalDates.Tues,
                  wednesday: intervalDates.Wed,
                  thursday: intervalDates.Thurs,
                  friday: intervalDates.Fri,
                  saturday: intervalDates.Sat,
                },
              };

              if (endDate && interval === 'custom') {
                if (endDate < date) {
                  showPopup('Error', 'Please choose an end date after the start date.');
                } else {
                  updateNotification(selectedNotification.id, notificationObject).then(() => {
                    this.close();
                  });
                }
              } else {
                updateNotification(selectedNotification.id, notificationObject).then(() => {
                  this.close();
                });
              }
            }}
            type="button"
          >
            Save
          </button>
          <button
            className="ms-1 btn btn-secondary"
            onClick={this.close}
            type="button"
          >
            Close
          </button>
        </div>
      </StandardModal>
    );
  }
}

function mapStateToProps(store) {
  return {
    editNotificationModal: store.modal.editNotificationModal,
    list: store.notifications.list,
    selectedNotification: store.notifications.selectedNotification,
  };
}

export default connect(mapStateToProps)(EditNotificationModal);
