import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useOutsideClick from '../../../../hooks/useOutsideClick';

import Caret from './Caret';
import CheckOption from './CheckOption';

function RadioOption({ name, label, onClick, checked }) {
  return (
    <span
      className="d-flex align-items-center"
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <input
        className="form-control-input me-3"
        type="radio"
        name={name}
        checked={checked}
        readOnly
      />
      <label htmlFor={name}>{label}</label>
    </span>
  );
}

function CarrierFilter({ planType = '' }) {
  const dispatch = useDispatch();
  const { carrierFilters = {} } = useSelector(store => store.plan);
  const [isOpen, setIsOpen] = useState(false);
  const filters = carrierFilters[planType] || [];

  // close dropdown if click outside of component
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const selectAll = () => {
    let payload = [...filters];
    payload = payload.map(([a]) => [a, true]);
    dispatch({ type: 'SET_CARRIER_FILTER_STATUS', payload, planType });
  };

  const deselectAll = () => {
    let payload = [...filters];
    payload = payload.map(([a]) => [a, false]);
    dispatch({ type: 'SET_CARRIER_FILTER_STATUS', payload, planType });
  };

  const setFilter = (name, checked) => {
    let payload = [...filters];
    payload = payload.map(([a, b]) => {
      if (a === name) {
        return [a, checked];
      }
      return [a, b];
    });
    dispatch({ type: 'SET_CARRIER_FILTER_STATUS', payload, planType });
  };

  const selectedCount = filters.filter(([, b]) => b === true).length;
  const allSelected = filters.filter(([, b]) => b === true).length === filters.length && filters.length;
  const allDeselected = filters.filter(([, b]) => b === false).length === filters.length && filters.length;

  return (
    <>
      <div
        className="form-control position-relative fs-5"
        role="button"
        tabIndex={0}
        style={{ width: '115px' }}
        ref={ref}
        onClick={() => setIsOpen(!isOpen)}
      >
        Carrier ({selectedCount})
        <div
          className={`planlist-tooltip-panel ${isOpen && 'open'}`}
          onClick={e => e.stopPropagation()}
          role="button"
          tabIndex={0}
        >
          <span className="text-nbgrey fs-6" style={{ cursor: 'default' }}>
            FILTER BY CARRIER
          </span>
          <RadioOption
            name="selectAll"
            label="Select all"
            onClick={selectAll}
            checked={allSelected}
          />
          <RadioOption
            name="deselectAll"
            label="Deselect all"
            onClick={deselectAll}
            checked={allDeselected}
          />
          <div className="w-75 my-2 align-self-center border-nbgrey border-bottom" />
          {filters.map(([name, checked]) => (
            <CheckOption
              onChange={() => setFilter(name, !checked)}
              checked={checked}
              label={name}
              id={`planlist-carrier-filter-${name}`}
              key={`carrierfilter-${name}`}
            />
          ))}
        </div>
      </div>
      <Caret isOpen={isOpen} />
    </>
  );
}

export default CarrierFilter;
