export function formatNumToPhone(phoneNumberString = '') {
  if (phoneNumberString) {
    const cleaned = phoneNumberString.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      // return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return cleaned;
  }
  return phoneNumberString;
}

export function formatPhoneToNum(num) {
  return num.replace(/\(|\)|-/g, '').replace(/ /g, '').substring(0, 10);
}
