import React, { useState } from 'react';

import {
  faDownload,
  faFileArrowDown,
  faFileXmark,
  faPlayCircle,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCheck,
  faCircleXmark,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// actions
import { forceDownload } from '../../actions/telephony';

export function PlayAudioButton({ audioSrc, onClick }) {
  return (
    <button
      type="button"
      className="btn btn-link p-0"
      title="Play call recording"
      disabled={!audioSrc}
      onClick={() => onClick()}
    >
      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
    </button>
  );
}

export function DownloadAudioButton({ audioSrc, filename }) {
  return (
    <button
      title="Download call recording"
      type="button"
      className="btn btn-link p-0 ms-4"
      onClick={() => forceDownload(audioSrc, filename)}
      disabled={!audioSrc}
    >
      <FontAwesomeIcon size="lg" icon={faDownload} />
    </button>
  );
}

export function DownloadTranscriptButton({ transcriptSrc, filename }) {
  const icon = transcriptSrc ? faFileArrowDown : faFileXmark;

  return (
    <button
      title="Download transcript"
      type="button"
      className="btn btn-link btn-pop p-0 ms-4"
      onClick={() => forceDownload(transcriptSrc, filename)}
      disabled={!transcriptSrc}
    >
      <FontAwesomeIcon size="lg" icon={icon} />
    </button>
  );
}

export function VoiceSOAIndicator({ soas }) {
  const soaExists = Boolean(soas.length);
  const icon = soaExists ? faCheck : faTimes;
  const color = soaExists ? 'var(--bs-success)' : 'grey';

  return (
    <FontAwesomeIcon
      size="lg"
      icon={icon}
      color={color}
    />
  );
}

export function EnrollmentStatus({ status }) {
  let text = 'Not Started';

  if (status === false) {
    text = 'Incomplete';
  } else if (status === true) {
    text = 'Submitted';
  }

  return text;
}

export function CallIcons({ call, dashboard = false }) {
  const [audioRow, setAudioRow] = useState({});
  const audioFilename = `${call.contact_id}.wav`;
  const transcriptFilename = `${call.contact_id}.txt`;

  if (call.contact_id === audioRow.contact_id) {
    return (
      <div
        className="d-flex justify-content-start align-items-center"
        style={{ height: dashboard ? null : '25px' }}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio controls style={{ height: dashboard ? '35px' : '30px' }}>
          <source src={call.recording_url} />
        </audio>
        <button
          type="button"
          className="btn ms-1"
          onClick={() => setAudioRow({})}
        >
          <FontAwesomeIcon icon={faCircleXmark} color="#d65656" size="lg" />
        </button>
      </div>
    );
  }
  return (
    <div className="d-flex">
      <PlayAudioButton audioSrc={call.recording_url} onClick={() => setAudioRow(call)} />
      <DownloadAudioButton audioSrc={call.recording_url} filename={audioFilename} />
      <DownloadTranscriptButton transcriptSrc={call.transcript_url} filename={transcriptFilename} />
    </div>
  );
}
