import axios from 'axios';

function getExportData(type) {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/export/${type}/`,
    headers: { Authorization: `Token ${token}` },
  })
    .then(({ data }) => data);
}

export function getAllShoppers() { return getExportData('shoppers'); }
export function getAllDrugs() { return getExportData('drugs'); }
export function getAllProviders() { return getExportData('providers'); }
export function getAllEnrollments() { return getExportData('enrollments'); }

export function getAllCalls() {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_MMB_API_URL}/api/agent/exportcallrecordings/`,
    headers: { Authorization: `Token ${token}` },
  })
    .then(({ data }) => data);
}
