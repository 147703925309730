import axios from 'axios';

// Returns a site_config value
export function loadConfig(key, site = null, access = 'agent', useDataSite = false) {
  const token = localStorage.getItem('SessionToken');
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_MMB_API_URL}/api/config/name/`,
      params: {
        name: key,
        access,
        use_data_site: useDataSite,
        ...(site ? { site } : []),
      },
      headers: { Authorization: `Token ${token}` },
    }).then(({ data }) => {
      resolve({ data });
    }).catch((err) => {
      if (err.response.status === 404) {
        console.warn(`site_config '${key}' does not exist or does not have the proper permission level`);
        resolve();
      }
      reject(err);
    });
  });
}

// NOTE(joeysapp): If you add an empty site_config with the name `${type}_config`, this allows bulk load-ins.
// e.g. hra_agent_form_category is added, now all site configs of category hra_agent_form can be pulled
export function loadConfigs(category, site = 'ALL_SITES_SITE', access = 'public') {
  const token = localStorage.getItem('SessionToken');
  return axios({
    method: 'get',
    headers: { Authorization: `Token ${token}` },
    url: `${process.env.REACT_APP_MMB_API_URL}/api/config/name/`,
    params: { name: category, site, access },
  }).then((res) => res.data);
}

