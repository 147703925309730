/* eslint-disable max-len, object-curly-newline, indent */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouterProps } from '../../../../utils/hocs';

const PRINT_DEBUG_INFO = false;

// Components
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryStack,
  VictoryTheme,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  LineSegment,
} from 'victory';

class DrugChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getPriceString = this.getPriceString.bind(this);
  }

  // todo(@joeysapp): Think of a better name and function signature
  // for this and put it in format utilities
  getPriceString(price, cents=true) {
    if (price > 999999) {
      return `$${(price/1000000.).toFixed(1)}m`;
    } else if (price > 99999) {
      return `$${(price/1000.).toFixed(0)}k`;
    } else if (price > 9999) {
      return `$${(price/1000.).toFixed(1)}k`;
    } else if (price > 999) {
      return `$${(price/1000.).toFixed(2)}k`;
    }

    if (cents) {
      price = `$${parseFloat(price).toFixed(2)}`;
    } else {
      price = `$${parseFloat(price).toFixed(0)}`
    }
    return price;
  }

  render() {
    const {
      effectiveDate,
      monthlyCosts,
      navigate,
      plan,
      selectedPharmacy,
      style,
    } = this.props;

    let hasError = false;
    let errorComponent = <div />;

    // -- y-Axis generation (vertical, cost)
    const clientDrugCosts = [];
    const clientPlanPremiumCosts = [];
    const clientTotalMonthlyCosts = [];

    let isZeroCostPlan = true;
    let maximumCostSeen = -Infinity;
    let minimumCostSeen = Infinity;

    let totalDrugCost = 0;
    let totalPlanPremiumCost = 0;

    let hasDrugCosts = false;
    let hasPlanPremiumCosts = false;

    // -- X-Axis generation (horizontal, time)
    const [ eYear, eMonth, day ] = effectiveDate.split('-');
    const effectiveDateObject = new Date(eYear, eMonth - 1, day, 0, 0);
    const effectiveMonthNumber = effectiveDateObject.getMonth();
    const effectiveYear = effectiveDateObject.getFullYear();

    const EOYObject = new Date(eYear+1, 11, 31, 0, 0);
    const EOYMonthNumber = EOYObject.getMonth();

    const currentDateObject = new Date();
    const currentMonthNumber = currentDateObject.getMonth();
    const currentYear = currentDateObject.getFullYear();

    const monthsToShow = EOYMonthNumber - effectiveMonthNumber + 1;
    // How many months before the effective date should be shown, and padding after 12
    const chartMonthPadding = 0;
    const chartEndMonthpadding = 0;

    if (effectiveDateObject < currentDateObject) {
      hasError = true;
      // This should be inaccessible, i.e. Agents should not access details with bad effective date
      errorComponent = (
        <div className="d-flex flex-column">
          <div>
          {`Please update the client's effective date to view their chart.`}
          </div>
          <div className="mt-2 ms-3 fw-bold">
            <span className="me-2">Effective date:</span>
            <span className="text-danger">
              {effectiveDateObject.toLocaleDateString()}
            </span>
          </div>
        </div>
      );
    }

    if (!monthlyCosts) {
      hasError = true;
      errorComponent = (
        <div className="d-flex flex-column">
          Something went wrong.
        </div>
      );
    }


    if (PRINT_DEBUG_INFO) { console.log('== plans/details/DrugChart -> Month/Cost population loop =='); }
    let costIndex = 0;
    let EOYPassed = false;
    let monthsTilNextYear = 12;
    for (let idx = chartMonthPadding + 1; idx <= monthsToShow + chartMonthPadding + chartEndMonthpadding; idx += 1) {
      // We start at idx = 1, so only add +11 here
      const properMonthIdx = ((effectiveMonthNumber - chartMonthPadding) + idx + 11) % 12;
      // if (!monthlyCosts[properMonthIdx%12]) {
      // } else {
        // This is our month number, however
        // const monthNumber = monthlyCosts[properMonthIdx%12].Month;
        const monthNumber = idx;

        // Our drug/premium costs start from 0, not monthNumber
        let drugCost = 0;
        let planPremiumCost = 0;

        // MonhtlyCosts will only be populated IFF the user has prescriptions, but
        // we want to display information for $0 cost prescriptions AND we want to
        // display the plan premium monthly cost if they haven't added prescriptions..
        if (monthlyCosts && monthlyCosts[costIndex]) {
          drugCost = parseFloat(monthlyCosts[costIndex].BeneficiaryPays.toFixed(2));
          planPremiumCost = parseFloat(monthlyCosts[costIndex].TotalPlanPremium.toFixed(2));
        } else {
          drugCost = 0;

          // We usually get this from monthlyCosts, but if they haven't added prescriptions,
          // then just take the premium from the plan. Foolproof solution. (depressed laughter)

          planPremiumCost = plan ? plan.monthlyPremium : 0;
        }
        costIndex += 1;

        // Scaling/Constraining/Fitting utility for chart
        totalDrugCost += drugCost;
        totalPlanPremiumCost += planPremiumCost;

        const totalMonthlyCost = drugCost + planPremiumCost;
        clientDrugCosts.push({
          monthNumber: idx,
          drugCost: EOYPassed ? 0 : drugCost,
        });
        clientPlanPremiumCosts.push({
          monthNumber: idx,
          planPremiumCost: EOYPassed ? 0 : planPremiumCost,
        });
        clientTotalMonthlyCosts.push({
          monthNumber: idx,
          totalMonthlyCost: EOYPassed ? 0 : totalMonthlyCost,
          totalSummationCost: EOYPassed ? 0 : totalDrugCost + totalPlanPremiumCost,
        });

        if (PRINT_DEBUG_INFO) { console.log(`\tidx=${idx}, monthNumber=${monthNumber}, drugCost=${drugCost}, planPremiumCost=${planPremiumCost}, totalMonthlyCost=${totalMonthlyCost}`); }

        if (drugCost > 0) {
          hasDrugCosts = true;
        }

        if (planPremiumCost > 0) {
          hasPlanPremiumCosts = true;
        }

        if (totalMonthlyCost > 0) {
          isZeroCostPlan = false;
          if (totalMonthlyCost < minimumCostSeen) {
            minimumCostSeen = totalMonthlyCost;
          }
          if (totalMonthlyCost > maximumCostSeen) {
            maximumCostSeen = totalMonthlyCost;
          }
        }
      // }
      if (properMonthIdx === EOYMonthNumber) {
        EOYPassed = true;
        monthsTilNextYear = properMonthIdx;
      }
    }

    if (PRINT_DEBUG_INFO) { console.log('== plans/details/DrugChart -> Month label creation loop =='); }

    const listOfMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // todo(@joeysapp): figure out domainPadding/padding/domain/range,
    // this is unprofessionally hacky (using extra x-axis values for padding)
    // and results in all our month indices being increased by one which are already starting at 1 
    let dateAxisValues = [0];
    let dateAxisLabels = [
      {
        month: '',
        year: '',
      },
    ];

    // Force our chart to show the Client's effective date with n months prepending it
    // Logic here to ensure starting next year dates are properly displayed
    let year = (effectiveMonthNumber - chartMonthPadding) < 0
      ? currentYear
      : effectiveYear;
    let increasedYear = false;
    // let chartStartMonthIdx = ((effectiveMonthNumber - chartMonthPadding) + 12) % 12;
    for (let idx = 0; idx < monthsToShow + chartMonthPadding; idx += 1) {
      // const monthIdx = (chartStartMonthIdx + idx) % 12;
      const monthIdx = ((effectiveMonthNumber - chartMonthPadding) + idx + 12) % 12;
      const month = listOfMonthNames[monthIdx];

      dateAxisValues.push(idx + 1);
      dateAxisLabels.push({
        month,
        year,
      });

      if (monthIdx == 11 && !increasedYear) {
        year += 1;
        increasedYear = true;
      }
    }

    // Get our ending x-axis ticks
    // for (let idx = 1; idx <= chartEndMonthpadding; idx += 1) {
    //   dateAxisValues.push(monthsToShow + chartMonthPadding + chartEndMonthpadding);
    //   dateAxisLabels.push({
    //     month: 'End of Year',
    //     year: '',
    //   });
    // }

    // Force only having 3 ticks
    if (maximumCostSeen < 0) maximumCostSeen = 15;
    let costAxisTicks = [
      maximumCostSeen * (1 / 3) * 1.1,
      maximumCostSeen * (2 / 3) * 1.1,
      maximumCostSeen * (3 / 3) * 1.1,
    ];

    // Round ticks to nearest 
    let dollarRounding = .01;
    if (maximumCostSeen > 100000) {
      dollarRounding = 10000;
    } else if (maximumCostSeen > 10000) {
      dollarRounding = 1000;
    } else if (maximumCostSeen > 1000) {
      dollarRounding = 100;
    } else if (maximumCostSeen > 100) {
      dollarRounding = 10;
    }
    // if (dollarRounding > 1) {
      costAxisTicks = costAxisTicks.map((t) => {
        return t > dollarRounding ? Math.ceil(t / dollarRounding) * dollarRounding : t
      });
    // }

    // Will be used for text vertical alignment in bars 
    const averageDrugBarHeight = totalPlanPremiumCost / (12 - chartMonthPadding);
    const averagePremiumBarHeight = totalDrugCost / (12 - chartMonthPadding);

    const victoryChartWidth = 1100;
    const victoryChartHeight = 400;
    const columnWidth = 50;

    const staticLabelSize = 15;
    const dynamicLabelSize = maximumCostSeen > 999 ? 13.5 : 15; 

    // In many cases our plan premium is a miniscule cost compared to
    // the drugs being purchased. Move the labels up in this case.
    const premiumPlanCostRatio = totalPlanPremiumCost / (totalDrugCost + totalPlanPremiumCost);
    const movePremiumPlanLabelUp = premiumPlanCostRatio < 0.25;

    if (PRINT_DEBUG_INFO) {
      console.log('dateAxisValues: ', dateAxisValues);
      console.log('dateAxisLabels: ', dateAxisLabels);
      console.log('\neffectiveDate: ', effectiveDate);
      console.log('effectiveDateObject: ', effectiveDateObject);

      console.log('monthlyCosts: ', monthlyCosts);
      console.log('\nclientTotalMonthlyCosts: ', clientTotalMonthlyCosts);
      console.log('clientDrugCosts: ', clientDrugCosts);
      console.log('clientPlanPremiumCosts: ', clientPlanPremiumCosts);

      console.log('\nmaximumCostSeen: ', maximumCostSeen);
      console.log('premiumPlanCostRatio: ', premiumPlanCostRatio);
      console.log('movePremiumPlanLabelUp: ', movePremiumPlanLabelUp);

      console.log('hasDrugCosts: ', hasDrugCosts);
      console.log('hasPlanPremiumCosts: ', hasPlanPremiumCosts);

      console.log('costAxisTicks: ', costAxisTicks);
      console.log('monthlyCosts: ', monthlyCosts);
      console.log('plan: ', plan);
    }

    // Documentation
    // * https://formidable.com/open-source/victory/docs/victory-chart/
    return hasError ?
      <div>{errorComponent}</div>
    : (
      <div className="plandetails-table" style={{ ...style }}>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          Breakdown of total monthly costs by medication costs and premium.
          {
            (!plan.plan.MonthlyCosts || plan.plan.MonthlyCosts.length === 0) && selectedPharmacy!== 99999999
              ? (
                <span className="ms-1">
                  <span
                    style={{
                      color: '#0058FF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      if (selectedPharmacy) {
                        navigate('/rx');
                      } else {
                        navigate('/rx/add/pharmacy');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    { selectedPharmacy ? 'Add medications' : 'Select a pharmacy' }
                  </span> to see estimated medication costs by month.
                </span>
              )
              : ''
          }
        </div>
        <VictoryChart
          // domainPadding={{ x: 250 }}
          style={{
            parent: {
              width: '85%',
              height: '350px',
            },
          }}
          theme={VictoryTheme.material}
          // y in domainPadding references to the dependent value (cost)
          // domain={{ x: [0, 13], y: [0, maximumCostSeen*1.25] }}
          // Our chart has a static ratio based on these
          width={victoryChartWidth}
          height={victoryChartHeight}

          // https://formidable.com/open-source/victory/guides/events/
          events={[{
            childName: ['clientDrugCost', 'clientPlanPremiumCost'],
            // The items we're listening to for hover-over
            target: ['data', 'labels'],
            eventHandlers: {
              onClick: () => {

              },
              onMouseOver: () => {
                return [{
                  // The items we're changing on above event
                  childName: ['clientDrugCost', 'clientPlanPremiumCost'],
                  target: 'data',
                  mutation: (props) => {
                    return {
                      style: {
                        ...props.style,
                        // fill: 'red',
                      }
                    };
                  }
                },
                {
                  childName: ['clientDrugCost', 'clientPlanPremiumCost'],
                  target: 'labels',
                  mutation: (props) => {
                    return {
                      style: {
                        ...props.style,
                        fontWeight: 700,
                        textDecoration: 'underline',
                      }
                    };
                  }
                }
                ];
              },
              onMouseOut: () => {
                return [{
                  childName: ['clientDrugCost', 'clientPlanPremiumCost'],
                  target: 'data',
                  mutation: () => {
                    return null;
                  }
                },
                {
                  childName: ['clientDrugCost', 'clientPlanPremiumCost'],
                  target: 'labels',
                  mutation: () => {
                    return null;
                  }
                }];
              }
            }
          }]}
        >
         {/* Cost Axis (Vertical axis) */}
          <VictoryAxis
            dependentAxis
            domain={[0, costAxisTicks[costAxisTicks.length - 1]]}
            gridComponent={
              <LineSegment
                style={{
                  stroke: '#CBCDCE',
                  strokeWidth: 2,
                  strokeDasharray: 5,
                }}
              />
            }
            style={{
              tickLabels: {
                fontSize: staticLabelSize,
                paddingLeft: 45,
              },
              ticks: {
                stroke: 'none',
              },
            }}
            tickValues={isZeroCostPlan ? [5, 10, 15, 25] : costAxisTicks}
            tickFormat={(v) => {
              // 83 -> 80, 903 -> 900, $12.33 -> $12, etc.
              const roundedValue = v > 10 ? (v/10.0).toFixed(0)*10.0 : v;
              return this.getPriceString(roundedValue, v < 10);
            }}
          />

          {/*
            Chart Unique Data Points
            * Effective date, 50% summed cost, 100% summed cost
          */}

          {/* Marking Effective Date (First available plan efficacy date to Client) */}
          <VictoryLine
            style={{
              data: {
                stroke: 'grey',
                strokeWidth: 2,
              },
              labels: {
                fontSize: staticLabelSize,
              },
            }}
            data={[
              { x: chartMonthPadding + 0.5, y: 0},
              {
                x: chartMonthPadding + 0.5,
                y: maximumCostSeen * (3.5 / 3),
                // y: clientTotalMonthlyCosts[0].totalMonthlyCost * (3.5 / 3),
              },
            ]}
            labels={({datum}) =>  {
              // Only display this label once
              const effectiveDateString = `Client effective date:\n${moment(effectiveDate).format('M/D/YYYY')}`;
              if (datum.y > 0) {
                return effectiveDateString;
              }
            }}
          />

          <VictoryLine
            style={{
              data: {
                stroke: 'grey',
                strokeWidth: 2,
              },
              labels: {
                fontSize: staticLabelSize,
              },
            }}
            data={[
              { x: (EOYMonthNumber - effectiveMonthNumber) + 1 + chartMonthPadding + 0.5, y: 0},
              {
                x: (EOYMonthNumber - effectiveMonthNumber) + 1 + chartMonthPadding + 0.5,
                y: (maximumCostSeen * (3.5 / 3) * (EOYMonthNumber - effectiveMonthNumber < 1 ? 1.2 : 1)),
                // y: clientTotalMonthlyCosts[0].totalMonthlyCost * (3.5 / 3),
              },
            ]}
            labels={({datum}) =>  {
              // Only display this label once
              const EOYTotalCostIdx = (EOYMonthNumber - effectiveMonthNumber);
              const totalCost = clientTotalMonthlyCosts[EOYTotalCostIdx] ? clientTotalMonthlyCosts[EOYTotalCostIdx].totalSummationCost : 0;
              if (datum.y > 0) {
                return `Month ${EOYTotalCostIdx+1}\nTotal cost to client:\n$${totalCost.toFixed(2)}`;
              }
            }}
          />

          {/* Data */}
          {/* Total monthly costs */}
          <VictoryBar
            data={clientTotalMonthlyCosts}
            x="monthNumber"
            y="totalMonthlyCost"
            style={{
              data: {
                fill: 'transparent',
                width: columnWidth,
              },
              labels: {
                fill: 'black',
                fontSize: dynamicLabelSize,
              }
            }}
            labels={({ datum }) => {
              let isZeroCostPlan = datum.totalMonthlyCost && datum.totalMonthlyCost > 0;
              let priceString = this.getPriceString(datum.totalMonthlyCost.toFixed(2), isZeroCostPlan);
              return priceString;
            }}
            labelComponent={<VictoryLabel dy={0}/>}
          />
          {/* Premium and Medication costs */}
          <VictoryStack
            className="plandetails-chart-label"
            style={{
              labels: {
                fill: 'black',
                fontSize: dynamicLabelSize,
              },
            }}
          >
            {/* Medication Costs */}
            {
              hasDrugCosts
                ? (
                  <VictoryBar
                    data={clientDrugCosts}
                    name="clientDrugCost"
                    // Keys used for our clientDrugCosts object
                    x="monthNumber"
                    y="drugCost"
                    style={{
                      data: {
                        fill: '#0D96CC',
                        width: columnWidth,
                        // stroke: '#0D96CC',
                        // strokeWidth: 1,
                      },
                      labels: {
                        fill: hasDrugCosts && !hasPlanPremiumCosts ? '#0D96CC' : 'transparent',
                        fontSize: dynamicLabelSize,
                      }
                    }}
                    labels={(data) => {
                      // All pricing is shown as a sum currently
                      // return data.drugCost > 0 ? `${this.getPriceString(data.drugCost.toFixed(2))}` : null;
                    }}
                    labelComponent={
                      <VictoryLabel
                        verticalAnchor={"middle"}
                        dy={(data) => {
                          const h = -2;
                          return h;
                        }}
                      />
                    }
                  />
                )
                : null
            }
            {/* Plan premium costs */}
            {
              hasPlanPremiumCosts
                ? (
                  <VictoryBar
                    data={clientPlanPremiumCosts}
                    name="clientPlanPremiumCost"
                    x="monthNumber"
                    y="planPremiumCost"
                    style={{
                      data: {
                        fill: hasPlanPremiumCosts ? '#305c6e' : 'invisible',
                        width: columnWidth,
                        // stroke: '#0D96CC',
                        // strokeWidth: 1,
                      },
                      labels: {
                        fill: hasPlanPremiumCosts && !hasDrugCosts ? '#305c6e' : 'transparent',
                        fontSize: dynamicLabelSize,
                        fontWeight: '700',
                        textShadow: '2px 2px 1px #0D96CC',
                      }
                    }}
                    labels={(data) => {
                      // return data.planPremiumCost > 0 ? `${this.getPriceString(data.planPremiumCost.toFixed(2))}` : null;
                    }}
                    labelComponent={
                      <VictoryLabel
                        verticalAnchor={"middle"}
                        dy={(data) => {
                          const h = -2;
                          return h;
                        }}
                      />
                    }
                  />
                )
                : null
            }
          </VictoryStack>
          {
            // Date Axis (Horizontal axis)
            // Placed at bottom so above data does not overlay it
          }
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: staticLabelSize,
                fill: (index) => {
                  const EOYIdx = (EOYMonthNumber - effectiveMonthNumber + 2);
                  const monthInPlan = index > chartMonthPadding && index < (EOYIdx + chartMonthPadding);
                  return monthInPlan ? 'black' : 'grey';
                },
                fontStyle: (index) => {
                  const EOYIdx = (EOYMonthNumber - effectiveMonthNumber + 2);
                  const monthInPlan = index > chartMonthPadding && index < (EOYIdx + chartMonthPadding);
                  return monthInPlan ? '' : 'italic';
                }
              },
              ticks: {
                stroke: 'none',
              },
              grid: {
                stroke: 'none',
              },
              labels: {
                fontSize: ({index}) => index > 3 ? 3 : staticLabelSize,
              }
            }}
            tickValues={dateAxisValues}
            tickFormat={(dateAxisValue) => {
              const month = dateAxisLabels[dateAxisValue].month;
              const year = dateAxisLabels[dateAxisValue].year;
              return `${month}`;
            }}
            // tickLabelComponent={
            //   <VictoryLabel
            //     style={
            //       {fill: this.props.text === '2021' },
            //     }
            //     // style={this.getDateTickLabelStyle(chartMonthPadding, chartEndMonthpadding, staticLabelSize)}
            //   />
            // }
          />

          <VictoryLegend
            // Centering things on this object is hard,
            // or it's super easy and I can't read documentation
            x={1100 * 0.5 - 250 * 0.5}
            y={400}
            width={400}
            orientation="horizontal"
            data={[
                {
                  name: 'Medication cost     ',
                  symbol: {
                    fill: '#0D96CC',
                    stroke: '#888A8D',
                    strokeWidth: 1,
                    type: 'square',
                    size: 10,
                    opacity: hasDrugCosts ? 1 : 0.5,
                  },
                  labels: {
                    opacity: hasDrugCosts ? 1 : 0.5,
                  },
                },
                {
                  name: 'Premium',
                  symbol: {
                    fill: '#305c6e',
                    stroke: '#888A8D',
                    strokeWidth: 1,
                    type: 'square',
                    size: 10,
                    opacity: hasPlanPremiumCosts ? 1 : 0.5,
                  },
                  labels: {
                    opacity: hasPlanPremiumCosts ? 1 : 0.5,
                  },
                }
            ]}
            // gutter={20}
            style={{
              border: {
                stroke: 'transparent'
              },
              labels: {
                fontSize: 15,
              },
            }}
          />
        </VictoryChart>
      </div>
    );
  }
}

DrugChart.defaultProps = {
  monthlyCosts: [],
  effectiveDate: false,
};


function mapStateToProps(store) {
  return {
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(withRouterProps(DrugChart));
