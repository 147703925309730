/* eslint-disable no-unused-expressions */
/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AWAIT_STATE, SUCCESS_STATE, FAIL_STATE } from '../../constants';

// Components
import {
  City,
  County,
  CurrentPlan,
  DateInput,
  DateOfBirth,
  Disposition,
  EffectiveDate,
  Phone,
  PlansAvailable,
  SaveButton,
  SubsidyLevel,
  SubsidyPercent,
  TextInput,
  Zipcode,
} from './components';
import Collapse from '../common/Collapse';

// Actions
import { loadMedicarePlans, mapCarriers } from '../../actions/plan';
import { useNetworkQueue } from '../../common/hooks';

function FormLayout() {
  const dispatch = useDispatch();
  const { currentPlanFeature } = useSelector(store => store.theme.features);
  const {
    shopper,
    shopper: { username, accountEmail },
    telephony: { meetType },
    theme: {
      features: {
        telephony: telephonyFeature,
        appointmentLeads: appointmentLeadsFeature,
      },
    },
  } = useSelector(store => store);
  const {
    medicareList = [],
    carrierFilters: {
      MA = [],
      MAPD = [],
      PDP =[],
    } } = useSelector(store => store.plan);
  const { medicareListLoaded, shopperDataLoaded } = useSelector(store => store.loaders);
  const { subsidyQuestion } = shopper;
  const hasMounted = useRef(false);

  const showSubsidyPercent = (subsidyQuestion === 'coinsurance');

  let formDisabled = true;
  if (!telephonyFeature) {
    formDisabled = false;
  } else if (telephonyFeature && ['inPerson', 'telephonicThreeWay'].includes(meetType)) {
    formDisabled = false;
  } if (telephonyFeature && meetType === 'telephonic' && username) {
    formDisabled = false;
  }

  function loadShopperPlans(alreadyMounted = false) {
    dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: AWAIT_STATE });
      useNetworkQueue(async () => {
        DEBUG && console.debug('Shopper.form.asyncFn() { await loadMedicarePlans() }');
        return await loadMedicarePlans(shopper);
      }).then((data) => {
        DEBUG && console.debug(`Shopper.form.asyncFn.then([${data.length} items)`);
        console.debug(`shopper.ed=${shopper.effectiveDate}, plan[0].PlanYear=${data[0].plan.PlanYear}`);
        dispatch({ type: 'SET_MEDICARE_PLAN_LIST', payload: data });
        dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: SUCCESS_STATE });
        if (alreadyMounted || (!PDP.length && !MA.length && !MAPD.length)) {
          const carriers = mapCarriers(data);
          dispatch({ type: 'SET_CARRIER_FILTER', payload: carriers });
        }
      }).catch((err) => {
        DEBUG && console.debug('Shopper.form.catch(err)', err);
        dispatch({ type: 'SET_MEDICARE_LOADED_STATE', payload: FAIL_STATE });
      }).finally(() => {
        DEBUG && console.debug('Shopper.form.finally');
      });
  }

  // Load in shopper plans for Current Plan feature on mount and on certain shopper field changes
  const DEBUG = false;
  useEffect(() => {
    // component already mounted and useEffect is triggered by change in dependencies
    if (hasMounted.current) {
      loadShopperPlans(true);
    } else {
      // useEffect is triggered by the initial mount
      hasMounted.current = true;
      loadShopperPlans();
    }
  }, [shopper.county, shopper.subsidyLevel, shopper.subsidyPercent, shopper.effectiveDate]);

  return (
    <div
      style={{ opacity: formDisabled ? '0.25' : '1', transition: 'all 0.25s ease' }}
    >
      <div className="row">
        <TextInput
          name="firstName"
          label="First Name"
          placeholder="e.g. John"
          disabled={formDisabled}
        />
        <TextInput
          name="lastName"
          label="Last Name"
          placeholder="e.g. Smith"
          disabled={formDisabled}
        />
        <TextInput
          name="leadID"
          label="Lead ID"
          disabled
        />
        <div className="col-md-3">
          <div className="row">
            <TextInput
              name="leadType"
              label="Lead Type"
              cols="6"
              onFalseyValue="--"
              disabled
            />
            <TextInput
              name="leadSource"
              label="Lead Source"
              cols="6"
              onFalseyValue="--"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row">
        <Phone
          name="homePhone"
          label="Home phone"
          type="phone"
          placeholder="e.g. (123) 456 - 7890"
          disabled={formDisabled}
        />
        <Phone
          name="mobilePhone"
          label="Mobile phone"
          type="phone"
          placeholder="e.g. (123) 456 - 7890"
          disabled={formDisabled}
        />
        <TextInput
          name="email"
          label="Email"
          placeholder="sample@email.com"
          disabled={formDisabled}
        />
        <div className="col-md-3">
          <div
            className="row"
            title={`account email ${accountEmail}`}
          >
            <TextInput
              name="hasShopperAccount"
              label="Shopper Portal"
              cols="6"
              onFalseyValue="--"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row">
        <Zipcode disabled={formDisabled} />
        <City disabled={formDisabled} />
        <County disabled={formDisabled} />
        <div className="col-md-3">
          <div className="row">
            <TextInput
              name="county"
              label="County FIPS"
              cols="6"
              disabled
            />
            <TextInput
              name="state"
              label="State"
              cols="6"
              disabled
            />
          </div>
        </div>
      </div>

      <div className="row">
        <TextInput
          name="address"
          label="Address Line 1"
        />
        <TextInput
          name="address2"
          label="Address Line 2"
        />
      </div>

      <div className="row">
        <EffectiveDate disabled={formDisabled} />
        <DateInput
          disabled
          name="previousEffectiveDate"
          label="Previous effective date"
        />
        <DateOfBirth disabled={formDisabled} />
        <div className="col-md-3">
          <div className="row">
            <PlansAvailable />
          </div>
        </div>
      </div>

      {/* subsidy selects */}
      <div className="row">
        <SubsidyLevel disabled={formDisabled} />
      </div>
      <Collapse isOpen={showSubsidyPercent}>
        <div className="row">
          <SubsidyPercent disabled={formDisabled} />
        </div>
      </Collapse>

      {/* current plan */}
      {currentPlanFeature && (
        <div className="row">
          <CurrentPlan disabled={formDisabled} />
        </div>
      )}

      {/* disposition select */}
      {!appointmentLeadsFeature && (
        <>
          <div className="row">
            <Disposition disabled={formDisabled} />
          </div>

          {/* submit button */}
          <div className="row">
            <div className="col-md-3 mb-4">
              <SaveButton disabled={formDisabled} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FormLayout;
