export default [
  { label: '12:00am', value: '0:00' },
  { label: '12:15am', value: '0:15' },
  { label: '12:30am', value: '0:30' },
  { label: '12:45am', value: '0:45' },
  { label: '1:00am', value: '1:00' },
  { label: '1:15am', value: '1:15' },
  { label: '1:30am', value: '1:30' },
  { label: '1:45am', value: '1:45' },
  { label: '2:00am', value: '2:00' },
  { label: '2:15am', value: '2:15' },
  { label: '2:30am', value: '2:30' },
  { label: '2:45am', value: '2:45' },
  { label: '3:00am', value: '3:00' },
  { label: '3:15am', value: '3:15' },
  { label: '3:30am', value: '3:30' },
  { label: '3:45am', value: '3:45' },
  { label: '4:00am', value: '4:00' },
  { label: '4:15am', value: '4:15' },
  { label: '4:30am', value: '4:30' },
  { label: '4:45am', value: '4:45' },
  { label: '5:00am', value: '5:00' },
  { label: '5:15am', value: '5:15' },
  { label: '5:30am', value: '5:30' },
  { label: '5:45am', value: '5:45' },
  { label: '6:00am', value: '6:00' },
  { label: '6:15am', value: '6:15' },
  { label: '6:30am', value: '6:30' },
  { label: '6:45am', value: '6:45' },
  { label: '7:00am', value: '7:00' },
  { label: '7:15am', value: '7:15' },
  { label: '7:30am', value: '7:30' },
  { label: '7:45am', value: '7:45' },
  { label: '8:00am', value: '8:00' },
  { label: '8:15am', value: '8:15' },
  { label: '8:30am', value: '8:30' },
  { label: '8:45am', value: '8:45' },
  { label: '9:00am', value: '9:00' },
  { label: '9:15am', value: '9:15' },
  { label: '9:30am', value: '9:30' },
  { label: '9:45am', value: '9:45' },
  { label: '10:00am', value: '10:00' },
  { label: '10:15am', value: '10:15' },
  { label: '10:30am', value: '10:30' },
  { label: '10:45am', value: '10:45' },
  { label: '11:00am', value: '11:00' },
  { label: '11:15am', value: '11:15' },
  { label: '11:30am', value: '11:30' },
  { label: '11:45am', value: '11:45' },
  { label: '12:00pm', value: '12:00' },
  { label: '12:15pm', value: '12:15' },
  { label: '12:30pm', value: '12:30' },
  { label: '12:45pm', value: '12:45' },
  { label: '1:00pm', value: '13:00' },
  { label: '1:15pm', value: '13:15' },
  { label: '1:30pm', value: '13:30' },
  { label: '1:45pm', value: '13:45' },
  { label: '2:00pm', value: '14:00' },
  { label: '2:15pm', value: '14:15' },
  { label: '2:30pm', value: '14:30' },
  { label: '2:45pm', value: '14:45' },
  { label: '3:00pm', value: '15:00' },
  { label: '3:15pm', value: '15:15' },
  { label: '3:30pm', value: '15:30' },
  { label: '3:45pm', value: '15:45' },
  { label: '4:00pm', value: '16:00' },
  { label: '4:15pm', value: '16:15' },
  { label: '4:30pm', value: '16:30' },
  { label: '4:45pm', value: '16:45' },
  { label: '5:00pm', value: '17:00' },
  { label: '5:15pm', value: '17:15' },
  { label: '5:30pm', value: '17:30' },
  { label: '5:45pm', value: '17:45' },
  { label: '6:00pm', value: '18:00' },
  { label: '6:15pm', value: '18:15' },
  { label: '6:30pm', value: '18:30' },
  { label: '6:45pm', value: '18:45' },
  { label: '7:00pm', value: '19:00' },
  { label: '7:15pm', value: '19:15' },
  { label: '7:30pm', value: '19:30' },
  { label: '7:45pm', value: '19:45' },
  { label: '8:00pm', value: '20:00' },
  { label: '8:15pm', value: '20:15' },
  { label: '8:30pm', value: '20:30' },
  { label: '8:45pm', value: '20:45' },
  { label: '9:00pm', value: '21:00' },
  { label: '9:15pm', value: '21:15' },
  { label: '9:30pm', value: '21:30' },
  { label: '9:45pm', value: '21:45' },
  { label: '10:00pm', value: '22:00' },
  { label: '10:15pm', value: '22:15' },
  { label: '10:30pm', value: '22:30' },
  { label: '10:45pm', value: '22:45' },
  { label: '11:00pm', value: '23:00' },
  { label: '11:15pm', value: '23:15' },
  { label: '11:30pm', value: '23:30' },
  { label: '11:45pm', value: '23:45' },
];
