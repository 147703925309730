/* eslint-disable import/prefer-default-export */
export function prettifyPhone(phoneNumberString) {
  const cleaned = (`${phoneNumberString}`)
    .replace(/^\+1/, '')
    .replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ` (${match[1]}) ${match[2]}-${match[3]}`;
  }
  return cleaned;
}

export function prettifyPartialPhone(num) {
  let returnValue;
  const target = num || '';
  const input = target.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    returnValue = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    returnValue = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    returnValue = `(${zip})`;
  }

  return returnValue;
}

export function stripPhone(num) {
  return num.replace(/\(|\)|-/g, '').replace(/ /g, '').substring(0, 10);
}

export function cap(string) {
  if (typeof string !== 'string') return '';
  return string
    .split(' ')
    .map(word => (word ? (word[0].toUpperCase() + word.substr(1)) : ''))
    .join(' ');
}

export function toCurrency(dollars) {
  const fmt = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });
  return fmt.format(dollars);
}

export function toUTC(date = new Date()) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}

export const toDateString = (string = '', options = {}) => {
  // eslint-disable-next-line no-param-reassign
  options = {
    key: true,
    readable: false,
    longform: false,
    utc: true,
    ...options,
  };
  let date = '';

  if (string) {
    date = new Date(string);
    if (!options.utc) date = toUTC(date); // inputted string was not stored as utc

    const year = new Intl.DateTimeFormat('en', {
      year: 'numeric',
    }).format(date);
    const month = new Intl.DateTimeFormat('en', {
      month: '2-digit',
    }).format(date);
    const day = new Intl.DateTimeFormat('en', {
      day: '2-digit',
    }).format(date);
    const hour = new Intl.DateTimeFormat('en', {
      hour: '2-digit',
      hour12: false,
    }).format(date);
    const minute = new Intl.DateTimeFormat('en', {
      minute: '2-digit',
    }).format(date).padStart(2, '0');

    if (options.readable) {
      date = `${date.toLocaleString('en', {
        month: 'long',
      })} ${year}`;
    } else if (options.longform) {
      date = `${year}-${month}-${day} at ${hour % 12}:${minute}${hour === '24' || hour < 12 ? 'am' : 'pm'}`;
    } else if (options.key) {
      // default case placed at end of if
      date = `${year}-${month}-${day}`;
    }
  }

  return date;
};

export function formatToPhone(num) {
  let returnValue;
  const target = num || '';
  const input = target.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    returnValue = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    returnValue = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    returnValue = `(${zip})`;
  }

  return returnValue;
}

export function formatPhoneToNum(num) {
  return num.replace(/\(|\)|-/g, '').replace(/ /g, '').substring(0, 10);
}
