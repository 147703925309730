import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumToPhone } from '../../utils/format-tel';
import { ModalBase } from '../../common/components';

function CallDetailsModal() {
  const dispatch = useDispatch();
  const { callDetails } = useSelector(store => store.modal);
  const { firstName, lastName } = useSelector(store => store.agent);
  const { callDetailsData = {} } = useSelector(store => store.threeWay);

  return (
    <ModalBase
      isOpen={callDetails}
      className="mt-6 call-details-modal"
      hideHeader="true"
      style={{ borderRadius: '1rem', minWidth: '325px', width: '40%' }}
      padding="0"
    >
      <div>
        <div className="modal-header" style={{ padding: '1rem 1rem' }}>
          <h5 className="modal-title">Call Details</h5>
        </div>
        <table className="table table-striped table-borderless m-0 border-top details">
          <tbody>
            <tr>
              <th>Call ID</th>
              <td>{callDetailsData.call_id}</td>
            </tr>
            <tr>
              <th>Client Name</th>
              <td className="text-capitalize">
                {`${callDetailsData.shopper_last_name}, ${callDetailsData.shopper_first_name}`}
              </td>
            </tr>
            <tr>
              <th>Client phone number</th>
              <td>{formatNumToPhone(callDetailsData.shopper_phone)}</td>
            </tr>
            <tr style={{ height: '2rem' }}>
              <th />
              <td />
            </tr>
            <tr>
              <th>Call Start</th>
              <td>{moment(callDetailsData.call_start).format('M/D/YY h:mm:ssa')}</td>
            </tr>
            <tr>
              <th>Call End</th>
              <td>{moment(callDetailsData.call_end).format('M/D/YY h:mm:ssa')}</td>
            </tr>
            <tr>
              <th>Call Duration</th>
              <td>
                {moment.unix(callDetailsData.call_duration).format('m[min] s[sec]')}
              </td>
            </tr>
            <tr style={{ height: '2rem' }}>
              <th />
              <td />
            </tr>
            <tr>
              <th>Agent NPN</th>
              <td>{callDetailsData.npn}</td>
            </tr>
            <tr>
              <th>Agent Name</th>
              <td className="text-capitalize">
                {`${lastName}, ${firstName}`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="modal-footer border-top" style={{ padding: '1rem 1rem' }}>
        <button type="button" className="btn btn-white" onClick={() => dispatch({ type: 'HIDE_MODAL', payload: 'callDetails' })}>Close</button>
      </div>
    </ModalBase>
  );
}

export default CallDetailsModal;
