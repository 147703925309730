import React from 'react';

const MmbInput = ({
  controlId,
  className,
  name,
  value,
  type = 'text',
  label,
  placeholder,
  hideLabel = false,
  invalid,
  error,
  append,
  onChange,
  onBlur,
  onKeyDown,
  onEnter,
  required = false,
  disabled = false,
  min,
  max,
  inputClassName,
}) => {
  const handleKeyDown = (e) => {
    if (onKeyDown) onKeyDown(e);
    if (e.key === 'Enter' && onEnter) {
      onEnter(e);
    }
  };
  const inputProps = {
    name,
    id: controlId,
    placeholder,
    value,
  };
  if (disabled) inputProps.disabled = true;

  const inputDom = type === 'textarea'
    ? (
      <textarea
        {...inputProps}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(e) => handleKeyDown(e)}
        className={`form-control ${inputClassName || ''}${invalid ? ' is-invalid' : ''}`}
      />
    )
    : (
      <input
        {...inputProps}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={(e) => handleKeyDown(e)}
        min={min}
        max={max}
        type={type}
        className={`form-control ${inputClassName || ''}${invalid ? ' is-invalid' : ''}`}
      />
    );
  return (
    <div className={`mb-3 ${className || ''}${disabled ? ' disabled' : ''}`}>
      {label
        ? (
          <label htmlFor={controlId} className="form-label" hidden={hideLabel}>
            {label}{required && <span className="text-danger fw-bold ps-1">*</span>}
          </label>
        ) : null}
      {append
        ? (
          <div className="input-group">
            {inputDom}
            {append}
          </div>
        )
        : inputDom}
      {invalid && error && <div className="d-block invalid-feedback">{error}</div>}
    </div>
  );
};

export default MmbInput;
