/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateField } from '../../utils';
import { setCookie } from '../../../../utils/cookie';

// components
import Select from './Select';

function County({ disabled }) {
  const dispatch = useDispatch();
  const [disabledMsg, setDisabledMsg] = useState('');
  const { shopper } = useSelector(store => store);
  const {
    counties = [],
    county: currentValue,
    schema = {},
  } = useSelector(store => store.shopper);
  const { zipLoading } = useSelector(store => store.loaders);
  const isDisabled = (counties.length <= 1) || zipLoading;

  const options = counties.map(county => ({ desc: county.countyName, value: county.countyName.toLowerCase() }));

  function updateCounty(fips, name, state) {
    dispatch({ type: 'UPDATE_SHOPPER', payload: { county: fips, countyName: name, state } });
    validateField({ name: 'countyName', schema, currentValues: shopper, newValue: name });
    // maintain the county in cookie state so it can be read by the softphone window
    setCookie('countyFips', fips);
  }

  function handleChange(e) {
    const { value } = e.target;
    const { county, countyName, state } = counties.filter(c => String(c.countyName.toLowerCase()) === String(value))[0];
    updateCounty(county, countyName.toLowerCase(), state);
  }

  useEffect(() => {
    // when a list of counties has not yet been loaded
    if (counties.length === 0) {
      if (!currentValue) {
        setDisabledMsg('(Please enter a valid zipcode)');
      }
    // auto select county if there is only one
    } else if (counties.length === 1) {
      setDisabledMsg('');
      const { countyName, county, state } = counties[0] || {};
      updateCounty(county, countyName, state);
    // set selected option to placeholder if more than 1 county
    } else if (counties.length > 1) {
      setDisabledMsg('');
      // unset county if we enter in a new zipcode that has multiple counties
      const currentCountyExistsInOptions = (counties.filter(c => c.county === currentValue).length > 0);
      if (!currentCountyExistsInOptions) updateCounty('', '', '');
    }
  }, [counties]);

  // set loading message when zipLoading = true
  useEffect(() => {
    if (zipLoading) setDisabledMsg('Loading...');
  }, [zipLoading]);

  if (counties.length > 1) {
    options.unshift({ desc: 'Select', value: '', disabled: true });
  }

  return (
    <Select
      name="countyName"
      label="County"
      handleChange={handleChange}
      disabled={zipLoading || disabled || isDisabled}
      disabledVal={disabledMsg}
      options={options}
    />
  );
}

export default County;
