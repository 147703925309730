import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../../utils/hocs';

// Components
import EnrollmentDisclaimer from '../../../common/EnrollmentDisclaimer';
import QuoteEnrollButton from '../../../common/QuoteEnrollButton';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCompareToggle = this.handleCompareToggle.bind(this);
  }

  handleCompareToggle(event, planId, isSelected) {
    const {
      dispatch,
      plan,
      shopper,
    } = this.props;

    if (!isSelected) {
      const { planType } = plan;
      dispatch({
        type: 'ADD_PLAN_COMPARE',
        payload: {
          planType,
          planId,
          zipcode: shopper.zipcode,
        },
      });
    } else {
      dispatch({
        type: 'REMOVE_PLAN_COMPARE',
        payload: { planId },
      });
    }
  }

  render() {
    const {
      dispatch,
      navigate,
      plan,
      planType,
      plansToCompare,
      shopper,
    } = this.props;

    const btnStyle = {
      width: '120px',
      height: '36px',
      fontWeight: '700',
      display: plan.planID ? 'block' : 'none',
    };

    const isSelected = plansToCompare.indexOf(plan.planID) !== -1 || shopper.currentPlan === plan.cpsID;
    const canCompare = ((shopper.currentPlan ? 1 : 0) + plansToCompare.length) < 3;

    return !plan.planID
      ? null
      : (
        <div className="planlist-recommended-footer">
          <div className="planlist-recommended-footer-buttons">
            <QuoteEnrollButton
              id={`planlist-select-${planType}`}
              className="mb-2"
              style={btnStyle}
              plan={plan}
              disabled={plan === {}}
            />
            <button
              id={`planlist-view-details-${planType}`}
              style={btnStyle}
              className="btn btn-outline-secondary fw-normal"
              disabled={plan === {}}
              onClick={() => {
                navigate('/plans/details');
                dispatch({
                  type: 'SET_PLAN_DETAILS',
                  payload: {
                    planId: plan.planID,
                    planType: plan.planType,
                    planCarrier: plan.carrier,
                    planName: plan.planName,
                  },
                });
              }}
              type="button"
            >
              Plan details
            </button>
          </div>
          <EnrollmentDisclaimer plan={plan} style={{ marginLeft: '15px' }} />
          <div
            className="d-flex align-items-center"
            style={{ height: '50px' }}
            title={!canCompare ? 'You cannot compare more than 3 plans at a time.' : 'Compare'}
          >
            <input
              className="form-control-input"
              onChange={(e) => {
                if (canCompare || isSelected) {
                  this.handleCompareToggle(e, plan.planID, isSelected);
                }
              }}
              id={`planlist-compare-button-${plan.planID}`}
              type="checkbox"
              checked={isSelected}
              disabled={!canCompare && !isSelected}
              style={{
                cursor: canCompare || isSelected ? 'pointer' : 'default',
                transform: 'scale(1.1)',
                marginLeft: '15px',
                marginRight: '8px',
                height: '100%',
              }}
            />
            <label
              className="form-control-label"
              htmlFor={`planlist-compare-button-${plan.planID}`}
              id="planlist-compare-button-label"
              style={{
                cursor: canCompare || isSelected ? 'pointer' : 'default',
                color: canCompare || isSelected ? 'black' : 'var(--bs-nbgrey)',
              }}
            >
              Compare
            </label>
          </div>
        </div>
      );
  }
}

Footer.defaultProps = {
  plan: {},
  planType: 'mapd',
};

const mapStateToProps = (state) => ({
  plansToCompare: state.compare.planIds,
  shopper: state.shopper,
});

export default connect(mapStateToProps)(withRouterProps(Footer));
