/* eslint-disable max-classes-per-file */
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import DoctorPopover from '../../../doctors/popover';
import useOutsideClick from '../../../../common/hooks/useOutsideClick';

function FirstThreeDoctors({ doctor, plan }) {
  let inNetwork = false;

  const { npi } = doctor;
  if (doctor.coveredPlans.includes(plan.cpsID)) {
    inNetwork = true;
  }

  return (
    <div key={`provider-coverage-${npi}`}>
      <span
        // className="provider-coverage-popover-in-network"
        title={inNetwork ? 'In-network' : 'Outside of network'}
        style={{
          display: 'inline-block',
          fontSize: '24px',
          width: '1.9rem',
          verticalAlign: 'top',
        }}
      >
        <FontAwesomeIcon
          icon={inNetwork ? faCheck : faTimes}
          style={{ color: inNetwork ? 'var(--bs-green)' : 'var(--bs-gray-500)' }}
        />
      </span>
      <div style={{ display: 'inline-block', maxWidth: '85%', verticalAlign: '1rem' }}>
        <div style={{ display: 'inline', verticalAlign: 'middle' }}>
          {doctor.name}
        </div>
        <div style={{ color: 'var(--bs-gray-600)' }}>
          {doctor.address}
        </div>
        <div className="pb-3" style={{ color: 'var(--bs-gray-600)' }}>
          {doctor.city}, {doctor.state} {doctor.zipcode}
        </div>
      </div>
    </div>
  );
}

function DoctorsList({
  plan,
  style,
  spanClassName = 'doctors-in-network',
  showHighlightBorder = true,
  showFullText = true,
}) {
  const navigate = useNavigate();
  const [ pct, setPct ] = useState(false); // pct = provider coverage tooltip
  const { doctorList } = useSelector(store => store.shopper);

  const providerCoverageRef = useRef(null);
  useOutsideClick(providerCoverageRef, setPct);

  const externalDivClassName = 'plancard-col-pricing-doctors';

  // Located on planlist/recommended and planlist cards themselves,
  // we only want the background on the latter
  const internalSpanClassName = `${spanClassName} ${showFullText ? 'plancard-doctors' : 'recommended-doctors'}`;

  return (
    <div
      className={externalDivClassName}
      style={{
        border: pct && showHighlightBorder ? '1px solid var(--bs-blue)' : '1px solid transparent',
        boxShadow: pct && showHighlightBorder ? '0 0.125rem 0.25rem rgb(0 0 0 / 32%)' : '',
        cursor: 'pointer',
        userSelect: 'none',
        ...style,
      }}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (doctorList.length <= 0) {
          navigate('/doctors');
        }
      }}
    >
      <span
        className="plancard-col-pricing-cell-top fw-bold pb-3"
        style={{ display: showFullText ? 'inline-block' : 'none' }}
      >
        {doctorList.length > 0 ? 'Doctors in-network' : 'No doctors added'}
      </span>
      {
        doctorList.length > 0
          ? (
            <>
              {
                doctorList.slice(0, 3).map(doctor => <FirstThreeDoctors doctor={doctor} plan={plan} key={doctor.id} />)
              }
              {
                doctorList.length > 3
                  ? (
                    <>
                      <span
                        className="mmb-link"
                        onClick={() => setPct(!pct)}
                        role="button"
                        tabIndex={0}
                        style={{ left: '1.9rem', position: 'relative' }}
                      >
                        See all doctors
                      </span>
                      <div
                        className="provider-coverage-popover-container"
                        role="tooltip"
                        style={{ display: pct ? 'inline-block' : 'none' }}
                        // Ref used to close tooltip when onBlur
                        ref={providerCoverageRef}
                      >
                        <div
                          className="provider-coverage-popover-exit"
                          onClick={() => setPct(false)}
                          role="button"
                          tabIndex={0}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                        <DoctorPopover
                          planCPS={plan.cpsID}
                          planName={plan.planName}
                        />
                      </div>
                    </>
                  )
                  : (null)
              }
            </>
          )
          : (
            <span
              className={`mmb-link ${internalSpanClassName}`}
              onClick={() => navigate('/doctors')}
              role="button"
              tabIndex={0}
              title="Add doctors"
              style={{ fontWeight: '700' }}
              // note(joeysapp): We want this as a data-url, shown
              // in the bottom-left of the browser window.
              // title={`${window.location.origin}/doctors/`}
            >
              Add doctors
            </span>
          )
        }
    </div>
  );
}

export default DoctorsList;
