/* eslint-disable*/
import React from 'react';

class LoaderEllipsis extends React.PureComponent {
  render() {
    const { className, color, style, text } = this.props;
    const spanStyle = { backgroundColor: color };

    return (
      <div className={`d-flex justify-content-center ${className}`} style={style}>
        <div>{text}</div>
        <div className="loading-indicator-ellipsis">
          <span id="ellipsis-1" style={spanStyle}/>
          <span id="ellipsis-2" style={spanStyle}/>
          <span id="ellipsis-3" style={spanStyle}/>
        </div>
      </div>
    );
  }
}

LoaderEllipsis.defaultProps = {
  text: 'Placeholder',
  className: '',
  color: 'black',
};

export default LoaderEllipsis;
