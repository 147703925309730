import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StandardModal from '../common/StandardModal';

function SoftphoneModal() {
  const dispatch = useDispatch();
  const { softphoneModal } = useSelector(store => store.modal);

  return (
    <StandardModal
      isOpen={softphoneModal}
      closeModalAction={() => dispatch({ type: 'HIDE_MODAL', payload: 'softphoneModal' })}
      width="400px"
      closeIcon={false}
      hideHeader
    >
      <div className="px-3 py-3">
        <div className="row">
          You must end your current call and close the softphone window to continue.
        </div>
        <div className="row mt-3">
          <div className="d-flex col justify-content-center">
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() => dispatch({ type: 'HIDE_MODAL', payload: 'softphoneModal' })}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </StandardModal>
  );
}

export default SoftphoneModal;
