/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileAlt, faPrint } from '@fortawesome/pro-solid-svg-icons';

function EnrollmentExport({
  quoteID,
  disabled,
  id,
  className,
  style,
}) {
  // <a> elements cannot be 'disabled', but setting their false/undefined works. react-dom asked for undefined.
  return (
    <div id={id || quoteID} className={`d-flex fs-4 ${className}`} style={{ maxWidth: '170px', ...style }}>
      <a
        className={[
          'd-flex me-1 w-50 justify-content-center',
          'badge text-secondary', 
          disabled ? 'pe-none opacity-50' : 'cursor-pointer',
        ].join(' ')}
        role="button"
        tabIndex={0}
        href={disabled ? undefined : `/quotes/export/${quoteID}`}
        onClick={() => {
          if (!disabled) {
            alert('Please select "Save to PDF" in the next window');
          }
        }}
        target="_blank"
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faFileAlt} className="me-1" />
        Save
      </a>
      <a
        className={[
          'd-flex me-1 w-50 justify-content-center',
          'badge text-secondary', 
          disabled ? 'pe-none opacity-50' : 'cursor-pointer',
        ].join(' ')}
        role="button"
        tabIndex={0}
        href={disabled ? undefined : `/quotes/export/${quoteID}`}
        target="_blank"
      >
        <FontAwesomeIcon icon={faPrint} className="me-1" />
        Print
      </a>
    </div>
  );
}
export default EnrollmentExport;
