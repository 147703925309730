import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import CloseButton from './CloseButton';

const px = (value) => `${value}px`;

class Guide extends React.PureComponent {
  hash = false;

  componentDidMount() {
    const { loadGuidebook, book } = this.props;
    loadGuidebook(book);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { setScrolling } = this.props;

    if (!this.hash) {
      setScrolling(true);
    }

    window.clearTimeout(this.hash);
    this.hash = window.setTimeout(() => {
      this.hash = false;
      setScrolling(false);
    }, 100);
  };

  handleEnter = () => {
    const { setHovering } = this.props;
    setHovering(true);
  };

  handleLeave = () => {
    const { setHovering } = this.props;
    setHovering(false);
  };

  render() {
    const {
      isOpen,
      guide,
      markers,
      close,
      isHovering,
    } = this.props;

    return (
      <div
        className="guide-wrap"
        data-open={isOpen}
        data-hovering={isHovering}
        onClick={close}
        role="button"
        tabIndex="-1"
      >
        <CloseButton side="tl" />

        {markers.map((marker) => (
          <div
            key={marker.i}
            className="guide-marker rounded"
            style={{
              width: px(marker.width),
              height: px(marker.height),
              left: px(marker.left),
              top: px(marker.top),
            }}
            onMouseEnter={this.handleEnter}
            onMouseLeave={this.handleLeave}
          >
            <div className="guide-icon rounded d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faQuestion} size="lg" />
            </div>
            <div
              className="guide-text text-white fw-bold lead p-2"
              data-side={marker.side}
            >
              {guide[marker.id][marker.i]}
            </div>
          </div>
        ))}

        <div className="guide-bg" />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isOpen: store.guide.isOpen,
  guide: store.guide.book,
  markers: store.guide.markers,
  isHovering: store.guide.isHovering,
});

const mapDispatchToProps = {
  loadGuidebook: (book) => ({ type: 'LOAD_GUIDEBOOK', book }),
  setScrolling: (isScrolling) => ({ type: 'SET_GUIDE_SCROLLING', isScrolling }),
  setHovering: (isHovering) => ({ type: 'SET_GUIDE_HOVERING', isHovering }),
  close: () => ({ type: 'CLOSE_GUIDE' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Guide);
