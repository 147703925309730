import React from 'react';
import { connect } from 'react-redux';
import { withRouterProps } from '../../utils/hocs';

// Actions
import { pushTrackingEvent } from '../../actions/tracking';
import { checkIfLoggedIn } from '../../actions/session';

class RouterLogic extends React.PureComponent {
  componentDidMount() {
    const { dispatch, navigate } = this.props;

    const requestUrl = new URL(document.location);
    const params = requestUrl.searchParams;
    const leadUsername = params.get('sun');
    const agentUsername = params.get('aun');

    // const requestedPath = requestUrl.pathname;
    let loginRedirectTo = '/mobile/dashboard';

    if (leadUsername && agentUsername) {
      dispatch({ type: 'SET_LEAD_UN', payload: leadUsername });
      dispatch({ type: 'SET_AGENT_UN', payload: agentUsername });
      pushTrackingEvent('sms_link_opened', agentUsername, leadUsername);
      loginRedirectTo = '/mobile/lead';
    }

    dispatch({ type: 'SET_MOBILE_LOGIN_REDIRECT_TO', payload: loginRedirectTo });

    // If the user does not have a drugList or it is empty, send them to add.
    // Use of setTimeout recommended here and solves the problem described in this issue:
    // https://github.com/remix-run/react-router/issues/7460#issuecomment-988642684
    setTimeout(() => {
      if (localStorage.getItem('SessionToken')) {
        checkIfLoggedIn().then(() => {
          navigate(loginRedirectTo);
        }).catch(() => {
          navigate('/mobile/login');
        });
      } else {
        navigate('/mobile/login');
      }
    }, 100);
  }

  render() {
    return (
      <div>
        Loading...
      </div>
    );
  }
}

export default connect()(withRouterProps(RouterLogic));
