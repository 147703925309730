const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_TRANSLATIONS': {
      const { hash, ...data } = action.payload;
      const languages = Object.keys(data);

      return {
        ...state,
        hash,
        languages,
        sets: data,
        setLanguage: languages[0],
      };
    }
    case 'SET_LANGUAGE':
      return {
        ...state,
        setLanguage: action.payload,
      };
    case 'CLEAR':
      return { ...initialState };
    default:
      return state;
  }
};
