import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { connect } from 'react-redux';
import { withRouterProps } from '../../../../utils/hocs';

// Utilities
import { formatDollarAmount } from '../../../../utils/general';

class PharmacyCoverage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pharmacyPrefixSelected: 'Pref',
    };
  }

  // Phase Codes:
  // In Use        Code        Label
  // -------------------------------
  //               0           Deductible
  //               1           Pre-initial coverage phase
  //  X            10          Initial coverage phase
  //  X            20          Coverage gap phase
  //               21          Post-initial coverage phase
  //  X            30          Catastrophic phase
  //               100         Unknown
  //
  // Currently matching healthcare.gov's display logic (i.e. do not show Deductible phase)
  getMedicationCost(tier, supply, phase, tierBenefits) {
    const { pharmacyPrefixSelected } = this.state;
    const prefix = pharmacyPrefixSelected;

    let returnVal = '-';
    tierBenefits.forEach((benefit) => {
      if (benefit.TierNum === tier && benefit.DaysSupply === supply && benefit.CoveragePhase === phase) {
        if (benefit[`${prefix}PaymentDesc`] === 'Copay') {
          // The case where there are 'Descriptions' returned, with keys e.g. MailPrefPaymentBenefitDescription
          // This will look like '$3.95 Copay or 5% Coinsurance, whichever is greater.'
          if (benefit[`${prefix}PaymentBenefitDescription`]) {
            returnVal = benefit[`${prefix}PaymentBenefitDescription`];
          } else {
            returnVal = `${formatDollarAmount(benefit[`${prefix}PaymentValue`])} Copay`;
          }
        } else if (benefit[`${prefix}PaymentDesc`] === 'CoInsurance') {
          if (benefit[`${prefix}PaymentBenefitDescription`]) {
            returnVal = benefit[`${prefix}PaymentBenefitDescription`];
          } else {
            returnVal = `${(benefit[`${prefix}PaymentValue`] * 100).toFixed(0)}% CoInsurance`;
          }
        } else if (benefit[`${prefix}PaymentDesc`] === 'None') {
          if (benefit[`${prefix}PaymentBenefitDescription`]) {
            returnVal = benefit[`${prefix}PaymentBenefitDescription`];
          } else {
            returnVal = '-';
          }
        }
      }
    });
    return HTMLReactParser(returnVal || '');
  }

  handleChange = (event) => {
    const prefixSelected = event.target.value;
    this.setState({
      pharmacyPrefixSelected: prefixSelected,
    });
  };

  render() {
    const {
      monthlyCosts,
      navigate,
      tierBenefits,
      partialCoverageGapTiers,
      planYear,
      selectedPharmacy,
      style,
    } = this.props;
    const { pharmacyPrefixSelected } = this.state;

    const pharmacyPrefixOptions = [
      {
        key: 'Pref',
        description: 'Preferred pharmacy',
      },
      {
        key: 'NonPref',
        description: 'Standard pharmacy',
      },
      {
        key: 'MailPref',
        description: 'Preferred mail order pharmacy',
      },
      {
        key: 'MailNonPref',
        description: 'Standard mail order pharmacy',
      },
    ];

    /* MYR-4883: Display coveerage gap footer iff plan has PartialCoverageGapTiers */
    const showCoverageGapFooter = partialCoverageGapTiers && partialCoverageGapTiers.length >= 1;
    const headerTitles = [
      { key: 'tier', description: 'Tier' },
      { key: 'supply', description: 'Supply' },
      { key: 'initial', description: 'Initial coverage phase' },
      { key: 'cat', description: 'Catastrophic phase' },
    ];
    if (Number(planYear) < 2025) {
      headerTitles.splice(3, 0, { key: 'gap', description: 'Coverage gap phase' });
    }
    const daysSupply = [30, 60, 90];
    const tiersWithLabels = {
      1: 'Not available',
      2: 'Not available',
      3: 'Not available',
      4: 'Not available',
      5: 'Not available',
      6: 'Not available',
    };

    // Retrieve appropriate Tier label
    tierBenefits.forEach((tier) => {
      if (tier.TierDescription) {
        tiersWithLabels[tier.TierNum] = tier.TierDescription;
      }
    });

    return (
      <div className="plandetails-table plandetails-medication-cost" style={{ ...style }}>
        {
          !monthlyCosts || monthlyCosts.length === 0
            ? (
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <span className="ms-1">
                  <span
                    style={{
                      color: '#0058FF',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (selectedPharmacy) {
                        navigate('/rx');
                      } else {
                        navigate('/rx/add/pharmacy');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    { selectedPharmacy ? 'Add medications' : 'Select a pharmacy' }
                  </span> to see medication cost by type of pharmacy.
                </span>
              </div>
            )
            : (
              <>
                {/* Pharmacy Type selection */}
                <div className="plandetails-pharmacy-select">
                  <label
                    className="plandetails-pharmacy-select-label"
                    htmlFor="statusFilter"
                  >
                    Pharmacy type:
                  </label>
                  <div>
                    <select
                      className="form-control plandetails-pharmacy-select-dropdown"
                      id="statusFilter"
                      name="select"
                      onChange={this.handleChange}
                      style={{
                        cursor: 'pointer',
                      }}
                      type="select"
                      value={pharmacyPrefixSelected}
                    >
                      {pharmacyPrefixOptions.map((option) => (
                        <option
                          key={option.key}
                          value={option.key}
                        >
                          {option.description}
                        </option>
                      ))}
                    </select>
                    <i
                      className="fa fa-chevron-down"
                      style={{
                        color: 'black',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '-25px',
                        pointerEvents: 'none',
                      }}
                    />
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      {headerTitles.map((title) => (
                        <th
                          className="plandetails-medication-cost-table-header"
                          key={title.key}
                        >
                          {title.description}
                          {
                            title.description === 'Coverage gap phase' && showCoverageGapFooter
                              ? (
                                <span
                                  id="coverage-url"
                                  className="text-link ms-1 fs-6"
                                  onClick={() => {
                                    // note(joeysapp): Using an anchor is unpredictable since
                                    // our tables may be collapsed/uncollapsed on click.
                                    window.scrollTo(0, document.body.scrollHeight);
                                  }}
                                  role="button"
                                  tabIndex={0}
                                >
                                  [1]
                                </span>
                              )
                              : null
                          }
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="plandetails-medication-cost-table-body">
                    {Object.keys(tiersWithLabels).map((tier) => (
                      tiersWithLabels[tier] === 'Not available'
                        ? null
                        : (
                          <tr
                            // Certain tiers are not available in plans; i.e. no tier 6
                            className={`${tiersWithLabels[tier] === 'Not available' ? 'disabled' : ''}`}
                            key={tier}
                          >
                            <td className="plandetails-medication-cost-label">
                              <b>Tier {tier}</b> - <i>{tiersWithLabels[tier]}</i>
                            </td>
                            <td className="plandetails-pharmacy-cell">
                              <div className="plandetails-medication-cost-list pharmacy">
                                {daysSupply.map((supply) => <div key={supply}>{supply} Days</div>)}
                              </div>
                            </td>
                            {/* Initial coverage phase */}
                            <td className="plandetails-pharmacy-cell">
                              <div className="plandetails-medication-cost-list pharmacy">
                                {daysSupply.map((supply) => (
                                  <div key={supply}>
                                    {this.getMedicationCost(Number(tier), supply, 10, tierBenefits)}
                                  </div>
                                ))}
                              </div>
                            </td>
                            {/* Coverage gap phase, only if plan year is <=2024 */}
                            {(Number(planYear) < 2025) ? (
                              <td className="plandetails-pharmacy-cell">
                                <div className="plandetails-medication-cost-list pharmacy">
                                  {daysSupply.map((supply) => (
                                    <div key={supply}>
                                      {this.getMedicationCost(Number(tier), supply, 20, tierBenefits)}
                                    </div>
                                  ))}
                                </div>
                              </td>
                            ) : null}
                            {/* Catastrophic phase */}
                            <td className="plandetails-pharmacy-cell">
                              <div className="plandetails-medication-cost-list pharmacy">
                                {daysSupply.map((supply) => (
                                  <div key={supply}>
                                    {this.getMedicationCost(Number(tier), supply, 30, tierBenefits)}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        )
                    ))}
                  </tbody>
                </table>
                {
                  showCoverageGapFooter
                    ? (
                      <div id="coverage-footnote" className="pt-3 pb-6">
                        <span className="text-link fw-bold me-1">[1]</span>
                        The above cost-sharing might only apply to some drugs in these tiers.
                        For all other drugs, you pay 25% for generic drugs and 25% for brand-name drugs.
                      </div>
                    )
                    : null
                }
              </>
            )
        }
      </div>
    );
  }
}

PharmacyCoverage.defaultProps = {
  tierBenefits: [],
  partialCoverageGapTiers: [],
  monthlyCosts: [],
  prefix: 'Pref', // possible values: 'Pref', 'NonPref', 'MailPref', 'MailNonPref'
};

function mapStateToProps(store) {
  return {
    selectedPharmacy: store.pharmacy.selectedPharmacy,
  };
}

export default connect(mapStateToProps)(withRouterProps(PharmacyCoverage));
