/* eslint-disable no-unused-vars,jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import moment from 'moment-timezone';

// utils
import { calculateTravelZips } from '../../common/utils/general';

// actions
import { updateAgent } from '../../actions/agent';

// components
import NavPage from '../nav/NavPage';
import {
  AppointmentTypes,
  AgentInfo,
  Licenses,
  Maps,
  Days,
} from '../../common/views/appointment-profiles';
import {
  getMeetingTypeOptions,
  removeEmptyAvailabilitySlots,
} from '../../common/views/appointment-profiles/utils';
import { LoaderButton } from '../../common/components';

const DEFAULT_RADIUS_OPTIONS = [
  { value: 5, label: '5 miles' },
  { value: 10, label: '10 miles' },
  { value: 20, label: '20 miles' },
  { value: 30, label: '30 miles' },
  { value: 40, label: '40 miles' },
  { value: 50, label: '50 miles' },
];

// check to see if profile has all required fields populated
function isProfileValid(profile) {
  let isValid = true;
  if (!profile.timezone) isValid = false;
  if (!profile.meetingTypes) isValid = false;
  if (profile.meetingTypes && profile.meetingTypes.in_person_office) {
    if (!profile.officeAddress) isValid = false;
    const { address, zip, state } = (profile.officeAddress || {});
    if (!address || !zip || !state) isValid = false;
  }
  if (!profile.pointsByMeetingType && !profile.travelZipsByMeetingType) isValid = false;

  // check to see that all active meeting types that are not allZip have travelZip arrays
  // with zipcodes
  const {
    meetingTypes: activeMeetingTypes = {},
    pointsByMeetingType = {},
    zipEntryTypes = {},
  } = profile;
  const meetingTypes = getMeetingTypeOptions();
  meetingTypes.forEach((type) => {
    const isActiveType = activeMeetingTypes[type.value];
    const points = (pointsByMeetingType[type.value] || []);
    if (isActiveType
      && !points.length
      && !type.allZips
      && (zipEntryTypes[type.value] !== 'manual')) isValid = false;
  });
  return isValid;
}

function AppointmentsProfile() {
  const dispatch = useDispatch();
  const meetingTypes = getMeetingTypeOptions();
  const [ saving, setSaving ] = useState(false);
  const [ profileChangedSinceSave, setProfileChangedSinceSave ] = useState(false);
  const {
    appointmentsProfile: dbProfile,
    acceptingAppointments = false,
    mobilePhone,
    mmbPermissions: {
      can_edit_appointments_profile: canEditProfile,
      can_edit_appointments_location: canEditLocation,
    },
  } = useSelector(store => store.agent);
  const { profile, awsConfig } = useSelector(store => store.appointments);
  const { appointmentRadiusOptions = DEFAULT_RADIUS_OPTIONS } = useSelector(store => store.theme);
  const requiredFieldsPopulated = isProfileValid(profile);

  function handleSave(aa) {
    setSaving(true);
    const travelZipsByMeetingType = {};
    const { meetingTypes: activeMeetingTypes = {} } = profile;
    meetingTypes.forEach((type) => {
      const travelZips = calculateTravelZips({
        profile,
        canEditLocation,
        meetingType: type.value,
      });
      if ((travelZips || []).length) travelZipsByMeetingType[type.value] = travelZips;
      if (!activeMeetingTypes[type.value]) travelZipsByMeetingType[type.value] = [];
      // if meeting type `allZips` is true, then we send leadscorz a null value
      // for travelZips of this meeting type which indicates to them that this
      // meeting type should use all zipcodes within the states the agent is licensed
      // in.
      if (type.allZips) travelZipsByMeetingType[type.value] = null;
    });
    const updatedAppointmentsFlag = ((aa === undefined) ? acceptingAppointments : aa);
    const cleanedAvailability = removeEmptyAvailabilitySlots(profile.availability);
    const data = {
      appointments_profile: {
        ...profile,
        availability: cleanedAvailability,
        travelZipsByMeetingType,
        lastSaved: moment.tz(moment(), moment.tz.guess()).toISOString(),
      },
      accepting_appointments: updatedAppointmentsFlag,
      mobile_phone: mobilePhone,
    };
    updateAgent(data)
      .then((d) => {
        setProfileChangedSinceSave(false);
        dispatch({ type: 'LOAD_AGENT', payload: d });
        dispatch({ type: 'SET_APPOINTMENTS', payload: { profile: d.appointmentsProfile } });
      }).finally(() => setSaving(false));
  }

  function handleSwitch() {
    setSaving(true);
    handleSave(!acceptingAppointments);
  }

  useEffect(() => {
    setProfileChangedSinceSave(true);
  }, [profile]);

  useEffect(() => {
    dispatch({ type: 'SET_APPOINTMENTS', payload: { profile: dbProfile } });
  }, []);

  const disableInputs = (
    !canEditProfile
    || (!acceptingAppointments && requiredFieldsPopulated && profile.lastSaved)
  );

  function setReduxProfileKeys(payload) {
    dispatch({ type: 'SET_APPOINTMENTS_PROFILE_KEYS', payload });
  }

  function setReduxAppointments(payload) {
    dispatch({ type: 'SET_APPOINTMENTS', payload });
  }

  function setReduxAgentMobilePhone(phone) {
    dispatch({ type: 'SET_AGENT', payload: { mobilePhone: phone } });
  }

  return (
    <NavPage>

      {!canEditProfile && (

        <div className="row">
          <div className="d-flex col justify-content-center">
            <div className="py-1 px-3 border border-warning bg-warning-bg shadow-sm mb-3">
              You do not have permission to edit your appointments profile.
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="d-flex col justify-content-center">
          <h1>
            Appointments Profile
            <button
              type="button"
              className="btn btn-link"
              onClick={() => dispatch({ type: 'SHOW_MODAL', payload: 'appointmentsTutorial' })}
            >
              (setup tutorial)
            </button>
          </h1>
        </div>
      </div>

      {(!requiredFieldsPopulated || !profile.lastSaved) && (
        <div className="row">
          <div className="col-md-6 offset-3">
            <div
              className="d-flex justify-content-center align-items-center border border-warning-border bg-warning-bg shadow-sm px-3 py-1"
            >
              Please fill out all required fields
              {!profile.lastSaved && ', then toggle the switch to begin receiving leads.'}
            </div>
          </div>
        </div>
      )}

      <div className="row justify-content-center">
        <div className="d-flex col-md-8 justify-content-between">

          <div style={{ position: 'relative' }} className="d-flex align-items-center">
            {saving && (
              <div
                className="spinner-border spinner-border-sm"
                role="status"
                style={{ position: 'absolute', left: '-22px' }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <label className="d-flex align-items-center">
              <Switch
                onChange={() => handleSwitch()}
                checked={acceptingAppointments}
                disabled={saving || !requiredFieldsPopulated || !canEditProfile}
              />
              <span className="ms-2">
                {acceptingAppointments ? (
                  <i>I am currently accepting leads</i>
                ) : (
                  <i>I am <b>not</b> currently accepting leads</i>
                )}
              </span>
            </label>
          </div>

          {profile.lastSaved && (
            <div>
              <LoaderButton
                type="button"
                className="btn btn-outline-success"
                text={!profileChangedSinceSave ? 'Saved' : 'Save'}
                disabled={!profileChangedSinceSave || !requiredFieldsPopulated || !canEditProfile}
                loadingText="Saving..."
                loading={saving}
                onClick={() => handleSave()}
              />
            </div>
          )}

        </div>
      </div>

      {/* using RTS permissions currently and not self specified
      <div className="row justify-content-center mt-2">
        <div
          className="col-md-8 p-3"
          style={{ border: 'solid 1px lightgrey' }}
        >
          <Licenses />
        </div>
      </div>
      */}

      <div className="row justify-content-center mt-2">
        <div
          className="col-md-8 p-3"
          style={{ border: 'solid 1px lightgrey' }}
        >
          <AgentInfo
            disabled={disableInputs}
            reduxProfile={profile}
            setReduxProfileKeys={(payload) => setReduxProfileKeys(payload)}
            reduxAgentMobilePhone={mobilePhone}
            setReduxAgentMobilePhone={(phone) => setReduxAgentMobilePhone(phone)}
          />
        </div>
      </div>

      <div className="row justify-content-center mt-2">
        <div
          className="col-md-8 p-3"
          style={{ border: 'solid 1px lightgrey' }}
        >
          <AppointmentTypes
            disabled={disableInputs}
            reduxProfile={profile}
            setReduxProfileKeys={(payload) => setReduxProfileKeys(payload)}
          />
        </div>
      </div>

      <div className="row justify-content-center mt-2">
        <div
          className="col-md-8 p-3"
          style={{ border: 'solid 1px lightgrey' }}
        >
          <Days
            disabled={disableInputs}
            setReduxProfileKeys={(payload) => setReduxProfileKeys(payload)}
            reduxProfile={profile}
          />
        </div>
      </div>

      <div className="row justify-content-center mt-2">
        <div
          className="col-md-8 p-3"
          style={{
            border: 'solid 1px lightgrey',
            marginBottom: '500px',
          }}
        >
          <Maps
            allowManualZipEntry={false}
            disabled={disableInputs}
            setReduxProfileKeys={(payload) => setReduxProfileKeys(payload)}
            reduxProfile={profile}
            canEditLocation={canEditLocation}
            setReduxAppointments={(payload) => setReduxAppointments(payload)}
            awsConfig={awsConfig}
            radiusOptions={appointmentRadiusOptions}
          />
        </div>
      </div>

    </NavPage>
  );
}

export default AppointmentsProfile;
