import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { ModalBase, YoutubeEmbed } from '../../common/components';

function TutorialModal() {
  const dispatch = useDispatch();
  const [ pauseFunc, setPauseFunc ] = useState();
  const { appointmentsTutorial: isOpen } = useSelector(store => store.modal);
  const {
    appointmentsProfileVideoURL = 'https://www.youtube.com/embed/QjSyQ-zt-Nc?si=0Zv5RN_Ey7gPC--b&rel=0&enablejsapi=1',
  } = useSelector(store => store.theme);

  useEffect(() => {
  }, []);

  function closeModal() {
    console.log('closeModal! pauseFunc: ', pauseFunc);
    pauseFunc();
    dispatch({ type: 'HIDE_MODAL', payload: 'appointmentsTutorial' });
  }

  return (
    <ModalBase
      isOpen={isOpen}
      closeModalAction={() => closeModal()}
      staticWidth="80%"
      closeIcon={false}
      padding="0px"
      hideHeader
      allowOverlayClickClose
    >
      <div className="row justify-content-center">
        <YoutubeEmbed
          embedURL={appointmentsProfileVideoURL}
          setPauseFunc={setPauseFunc}
          height="600px"
        />
      </div>
    </ModalBase>
  );
}

export default TutorialModal;

/* eslint-disable */
/*
        <iframe
          width="560"
          height="715"
          src="https://www.youtube.com/embed/QjSyQ-zt-Nc?si=0Zv5RN_Ey7gPC--b&rel=0&enablejsapi=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
*/
