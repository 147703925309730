// note(@joeysapp): Used for global validation intervals that will likely
//                  become unnecessary after switch to wss.

const initialState = {
  intervals: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_INTERVAL':
      return { ...state, intervals: [action.payload, ...state.intervals] };
    case 'CLEAR_INTERVALS':
      state.intervals.forEach((interval) => {
        window.clearInterval(interval);
      });
      return initialState;
    default:
      return state;
  }
}
