import React from 'react';
import FormInput from './FormInput';
import { formatPhoneToNum, formatToPhone } from '../../utils/general';
// import { handleOnlyNumber } from '../../utils/mixin';

const MmbPhone = (props) => {
  const propsBinding = {
    ...props,
    value: formatToPhone(props.value),
    // onKeyDown: (e) => handleOnlyNumber(e),
    onChange: (e) => {
      let val = e.target.value;
      if (val === '' || val === null) {
        val = '';
      } else {
        if (val.match(/\(\d{0,3}$/)) {
          val = val.substring(0, val.length - 1);
        }
        val = formatPhoneToNum(val);
      }
      if (props.onChange) {
        e.target.value = val;
        props.onChange(e);
      }
    },
  };

  return (
    <FormInput
      {...propsBinding}
    />
  );
};

export default MmbPhone;
