import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';

// Actions
import { checkIfLoggedIn } from '../../../actions/session';
import { loadShopper, updateShopper } from '../../../actions/shopper';
import { pushTrackingEvent } from '../../../actions/tracking';

// Components
import LoaderButton from '../../common/LoaderButton';
import MobileNavbar from '../nav/MobileNavbar';
// import Appointment from './Appointment';
import { AppointmentInfo, EditAppointmentInfo } from '../../shopper/appointments';

// Utilities
import { showPopup } from '../../../utils/popup';

function BackArrowIcon({ loading }) {
  return loading ? (
    <Spinner
      style={{
        border: '2px solid currentColor',
        borderRightColor: 'transparent',
        height: '18px',
        marginRight: '15px',
        width: '18px',
      }}
    />
  ) : (
    <FontAwesomeIcon
      icon={faArrowLeft}
      style={{
        fontSize: '23px',
        marginRight: '13px',
        height: 'auto',
      }}
    />
  );
}

function LoadingScreen() {
  return (
    <>
      <MobileNavbar />
      <div
        className="p-0"
        style={{
          display: 'flex',
          height: '90vh',
          maxWidth: '100%',
          marginTop: '8vh',
        }}
      >
        <Spinner
          color="primary"
          style={{
            display: 'block',
            margin: 'auto',
            width: '9rem',
            height: '9rem',
          }}
        />
      </div>
    </>
  );
}

function LeadQuickview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ dispositionLoading, setDispositionLoading ] = useState(false);
  const [ exitPageIntervalID, setExitPageIntervalID ] = useState();
  const [ exitPageLoading, setExitPageLoading ] = useState(false);
  const {
    shopper,
    leadStatus: { options: leadStatusOptions },
    mobile: {
      shopperUsername: leadUsername,
      agentUsername,
      isLoading,
    },
    theme: {
      features: { appointmentLeads: appointmentLeadsFeature },
    },
  } = useSelector(store => store);

  useEffect(() => {
    dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: true });
    checkIfLoggedIn().then(() => {
      loadShopper(leadUsername)
        .then((data) => {
          dispatch({ type: 'SET_SHOPPER', payload: data });
          dispatch({ type: 'SET_SHOPPER_QUOTES', payload: data.quotes }); // is this necessary...?
          dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: false });
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            navigate('/mobile/error/lead-not-owned');
          } else {
            navigate('/mobile/error');
          }
        });
    }).catch(() => {
      navigate('/mobile/login');
    });
  }, []);

  function saveDisposition() {
    setDispositionLoading(true);
    updateShopper(shopper, { mobile: true })
      .then((data) => {
        showPopup('Success', 'Shopper disposition has been updated.');
        setDispositionLoading(false);
        dispatch({ type: 'UPDATE_SHOPPER', payload: data });
        dispatch({ type: 'UPDATE_MOBILE_SHOPPER', payload: data });
        pushTrackingEvent(
          'disposition_update@mobile/lead',
          agentUsername,
          leadUsername,
        );
      }).catch(() => {
        showPopup('Error', 'There was a problem updating the Shopper.');
        setDispositionLoading(false);
      });
  }

  function exitPage() {
    if (dispositionLoading && !exitPageLoading) {
      const intervalID = window.setInterval(() => {
        exitPage();
      }, 100);
      setExitPageIntervalID(intervalID);
      setExitPageLoading(true);
    } else if (!dispositionLoading) {
      window.clearInterval(exitPageIntervalID);
      setExitPageIntervalID(false);
      setExitPageLoading(false);
      dispatch({ type: 'SET_MOBILE_IS_LOADING', payload: true });
      navigate('/mobile/dashboard');
    }
  }

  const lastUpdated = moment.tz(shopper.lastUpdated, moment.tz.guess()).format('MM/DD/YY HH:mm z');

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <>
      <MobileNavbar />
      <div className="mobile-container">
        <div
          className="mobile-page-navigation"
          onClick={() => exitPage()}
          role="button"
          tabIndex={0}
        >
          <BackArrowIcon loading={dispositionLoading} />
          <div>
            All Leads
          </div>
        </div>
        <div className="mobile-page-content">
          <div
            style={{
              lineHeight: '18px',
              marginTop: '20px',
            }}
          >
            Lead Name
          </div>
          <div className="mobile-lead-name mobile-font-large">
            {shopper.firstName} {shopper.lastName}
          </div>

          <div className="mobile-lead-telephone">
            <a
              type="button"
              className="btn btn-mobile btn-mobile-primary"
              href={`tel:${shopper.mobilePhone || shopper.homePhone}`}
              onClick={() => pushTrackingEvent('sms_lead_link_call_button_click', agentUsername, leadUsername)}
              style={{ width: '100%', height: '50px', lineHeight: '40px' }}
            >
              <FontAwesomeIcon
                icon={faPhoneAlt}
                className="me-2"
              />
              Call Lead
            </a>
          </div>

          {!appointmentLeadsFeature && (
            <div className="mobile-lead-details mobile-font-small">
              <div id="lead_type">
                Lead type: <b>{shopper.leadType}</b>
              </div>
              <div id="lead_id">
                Lead ID: <b>{shopper.leadID}</b>
              </div>
              <div id="lead_gender">
                Gender: <b>{shopper.gender}</b>
              </div>
              <div id="lead_location">
                Location: <b>{shopper.city}, {shopper.state}</b>
              </div>
            </div>
          )}

          {appointmentLeadsFeature && (
            <>
              <div className="mt-3">
                <AppointmentInfo />
              </div>
              <div className="mt-3">
                <EditAppointmentInfo
                  showDisposition={false}
                  showSaveButton={false}
                  modalWidth="100%"
                />
              </div>
            </>
          )}

          <div className="mobile-lead-disposition">
            <div
              style={{
                fontSize: '21px',
                marginTop: '25px',
              }}
            >
              <b>Disposition</b>
            </div>
            <div
              className="mobile-small-font"
            >
              Last Updated: {lastUpdated}
            </div>
          </div>
          <select
            className="form-select select-mobile mobile-small-font"
            style={{
              lineHeight: '50px',
              marginBottom: '12px',
              marginTop: '8px',
              paddingRight: '26px',
              width: '100%',
              textOverflow: 'ellipsis',
            }}
            value={shopper.status}
            onChange={
              (e) => dispatch({ type: 'UPDATE_SHOPPER_KEY', payload: { key: 'status', val: Number(e.target.value) } })
            }
          >
            {leadStatusOptions.map((status) => (
              <option value={status.value}>{status.description}</option>
            ))}
          </select>
          <i className="fa fa-chevron-down" style={{ marginTop: '-45px' }} />
          <LoaderButton
            className="btn btn-mobile btn-mobile-secondary"
            style={{
              width: '100%',
              webkitAppearance: 'button',
            }}
            text="Update Disposition"
            loadingText="Saving..."
            onClick={() => saveDisposition()}
            loading={dispositionLoading}
          />
        </div>
      </div>
    </>
  );
}

export default LeadQuickview;
