import React from 'react';
import { faAward } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { fmtField, calculateProviderCoverage, formatDollarAmount } from '../../utils/general';

class PlanInformation extends React.PureComponent {
  render() {
    const recPlan = this.props.recommendedPlan;
    const otherPlan = this.props.recommendedPlan || this.props.nextBestPlan;
    const { currentPlan } = this.props;
    let totalProjectedSavings = null;
    if (currentPlan && otherPlan) {
      totalProjectedSavings = formatDollarAmount(currentPlan.totalAnnualOOPC - otherPlan.totalAnnualOOPC, true, true);
    }
    const filler = <span className="text-fade">No Information Available</span>;

    const options = [
      {
        label: this.props.mainSectionTranslations['Monthly Premium Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Rx Deductible Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Plan Type Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Coverage Type Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Medications on Plan Formulary Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Doctors In-Network Text'],
        value1: filler,
        value2: filler,
      },
      {
        label: this.props.mainSectionTranslations['Estimated Annual OOPC Text'],
        value1: filler,
        value2: filler,
      },
    ];

    if (currentPlan) {
      options[0].value1 = fmtField(currentPlan.monthlyPremium, 'dollar');
      options[1].value1 = fmtField(currentPlan.deductible, 'dollar');
      options[2].value1 = currentPlan.planType || 'Data Not Available';
      options[3].value1 = currentPlan.drugsCovered || 'Data Not Available';
      options[4].value1 = currentPlan.coverageType || 'Data Not Available';
      options[5].value1 = calculateProviderCoverage(this.props.shopper.doctorList, this.props.doctorPlans, currentPlan.cpsID);
      options[6].value1 = currentPlan.totalAnnualOOPC ? fmtField(currentPlan.totalAnnualOOPC, 'dollar') : '$0.00';
    }
    if (otherPlan) {
      options[0].value2 = fmtField(otherPlan.monthlyPremium, 'dollar');
      options[1].value2 = fmtField(otherPlan.deductible, 'dollar');
      options[2].value2 = otherPlan.planType || 'Data Not Available';
      options[3].value2 = otherPlan.drugsCovered || 'Data Not Available';
      options[4].value2 = otherPlan.coverageType || 'Data Not Available';
      options[5].value2 = calculateProviderCoverage(this.props.shopper.doctorList, this.props.doctorPlans, otherPlan.cpsID);
      options[6].value2 = otherPlan.totalAnnualOOPC ? fmtField(otherPlan.totalAnnualOOPC, 'dollar') : '$0.00';
    }

    if (!this.props.physicianSearchStatus) {
      options.splice(5, 1);
    }
    const Ribon = <p className="mb-0 text-primaryFocus"><FontAwesomeIcon icon={faAward} className="fa-lg me-2" /> <span>Best fit plan</span></p>;

    return (
      <div>
        <table className="w-100 custom-table">
          <thead>
            <tr>
              <th />
              <th>
                {recPlan ? null : Ribon}
                <h5 className="fw-bold">{this.props.mainSectionTranslations['Current Plan Text']}</h5>
              </th>
              <th>
                {recPlan ? Ribon : null}
                <h5 className="fw-bold">{this.props.mainSectionTranslations['MPA Recommendation Plan Text']}</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={`${recPlan ? '' : 'border-right-primaryFocus'}`} />
              <td className={`${recPlan ? 'border-right-primaryFocus' : 'active plan-name'}`}>
                <div className="text-center py-2">
                  {currentPlan ? currentPlan.planName : this.props.mainSectionTranslations['No Current Plan Text']}
                </div>
              </td>
              <td className={`${recPlan ? 'active plan-name' : ''}`}>
                <div className="text-center py-2">{otherPlan ? otherPlan.planName : this.props.mainSectionTranslations['No Current Plan Text']}</div>
              </td>
            </tr>
            {options.map((detail) => (
              <tr>
                <td className={`${recPlan ? 'text-start' : 'border-right-primaryFocus text-start'}`}>{detail.label}</td>
                <td className={`${recPlan
                  ? 'border-right-primaryFocus'
                  : 'active'}`}
                >
                  {detail.value1}
                </td>
                <td className={`${recPlan ? 'active' : ''}`}>
                  {detail.value2}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={3} className="mpa-col-total">
                {this.props.mainSectionTranslations['Total Projected Savings Text']}&nbsp;
                <b>{totalProjectedSavings || this.props.mainSectionTranslations['No Data Available Text']}</b>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    doctorPlans: store.plan.doctorPlans,
  };
}
export default connect(mapStateToProps)(PlanInformation);
