import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

class Detail extends React.PureComponent {
  render() {
    const {
      callNotes,
      noteIndex,
      setView,
    } = this.props;
    let note = {};
    if (callNotes.length) {
      note = callNotes[noteIndex];
    }
    return (
      <div className="col" style={{ maxHeight: '60vh' }}>
        <div
          className="d-flex navbar-notes-row"
          style={{ border: '0', backgroundColor: 'white' }}
          role="button"
          tabIndex={-1}
          onClick={() => setView('summary')}
        >
          <div className="me-2 text-muted">
            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
          </div>
          <div className="ms-0 me-auto">Go back</div>
        </div>
        <div
          className="navbar-notes-row mt-3"
          style={{ overflow: 'scroll', height: '50vh' }}
        >
          <div className="col p-0 whitespace-break">
            <p>
              <b>{moment(note.created || '').format('M/DD/YYYY h:mma')}</b>
            </p>
            {(note.text || '').split('<br>').map(e => (<>{e}<br /></>))}
          </div>
        </div>
      </div>
    );
  }
}

Detail.defaultProps = {
  noteIndex: 0,
};

function mapStateToProps(store) {
  return {
    callNotes: store.shopper.callNotes,
  };
}

export default connect(mapStateToProps)(Detail);
