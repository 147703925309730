import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithTracker from '../common/WithTracker';

// Components
import PlanList from './list';
import PlanDetails from './details';
import PlanCompare from './compare';

export default function () {
  return (
    <WithTracker>
      <Routes>
        <Route path="" element={<PlanList />} />
        <Route path="list" element={<PlanList />} />
        <Route path="details" element={<PlanDetails />} />
        <Route path="details/standalone" element={<PlanDetails standalone />} />
        <Route path="compare" element={<PlanCompare />} />
      </Routes>
    </WithTracker>
  );
}
