const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_HRA_CONFIGS':
      return { ...state, configs: action.payload };
    case 'SET_HRA_QUOTE_ID':
      return { ...state, quoteID: action.payload };
    case 'SET_HRA_FIELD_VALUE':
      return { ...state, fields: { ...state.fields, [action.name]: action.value } };
    case 'SET_HRA_FIELD_VALUES':
      return { ...state, fields: { ...state.fields, ...action.payload } };
    case 'LOAD_HRA':
      // note(joeysapp): The enrollment portal clears out the fields, and then later validates->sets fields,
      //                 for now we'll assume we don't need validation logic like enrollment portal does.
      // https://github.com/mymedicarebot/consumer-portal/blob/dev/src/reducers/enrollment.js#L19
      // https://github.com/mymedicarebot/consumer-portal/blob/dev/src/views/enrollment/EnrollmentLoader.jsx#L135
      return { ...state, ...action.payload };
    case 'CLEAR_HRA':
      return { ...initialState, configs: state.configs };
    case 'SET_HRA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
