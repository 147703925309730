const initialState = {
  isOpen: false,
  markers: [],
  book: {},
  isScrolling: false,
  isHovering: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_GUIDEBOOK': {
      const book = { ...action.book };
      return { ...initialState, book };
    }
    case 'SET_GUIDE_SCROLLING':
      return { ...state, markers: [], isScrolling: action.isScrolling };
    case 'SET_GUIDE_HOVERING':
      return { ...state, isHovering: action.isHovering };
    case 'ADD_GUIDE_MARKER': {
      const marker = { ...action.marker };
      const markers = [...state.markers, marker];
      return { ...state, markers };
    }
    case 'OPEN_GUIDE':
      return {
        ...state,
        markers: [],
        isOpen: true,
        isHovering: false,
      };
    case 'CLOSE_GUIDE':
    case 'CLEAR': {
      const { book } = state;
      return { ...initialState, book };
    }
    default:
      return state;
  }
};
